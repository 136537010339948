import { ListItemAvatar, ListItemProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import ListItemBase from '../../components/ListItemBase'
import ListItemText from '../../components/ListItemTextBase'
import { PlugSchema } from '../../schemas/PlugSchema'
import PlugIcon from '../PlugIcon'

interface AnalyzeConnectionListItemProps {
  plug: PlugSchema
  selected?: boolean
  onClick?: ListItemProps['onClick']
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    cursor: 'pointer',
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5, 0),
      fontSize: theme.typography.pxToRem(12)
    },
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  },

  selectedItem: {
    border: `2px groove ${theme.palette.primary.main}`
  }
}))

const AnalyzeConnectionListItem: React.FC<PropsWithChildren<AnalyzeConnectionListItemProps>> = ({
  plug,
  selected,
  ...props
}) => {
  const classes = useStyles()

  return (
    <ListItemBase className={clsx(classes.root, { [classes.selectedItem]: selected })} {...props}>
      <ListItemAvatar>
        <PlugIcon type={plug.type} />
      </ListItemAvatar>
      <ListItemText primaryTypographyProps={{ noWrap: false }} primary={plug.name} />
    </ListItemBase>
  )
}

export default AnalyzeConnectionListItem
