import { forwardRef } from 'react'

import { Warning } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { RelatedAnalysisDependecy } from '../../pages/plugs/UpdatePlugDialog/components/Common/UpdateDialog'

interface WarningContainerProps {
  message: string
  relatedDependencies: RelatedAnalysisDependecy[] | string[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.spacing(5)
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  dependentItem: {
    fontWeight: theme.typography.fontWeightMedium
  }
}))

const maxRelated = 4

const WarningContainer = forwardRef<HTMLDivElement, WarningContainerProps>(
  ({ message, relatedDependencies = [] }, ref) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
      <>
        <Box className={classes.root}>
          <Box className={classes.messageContainer}>
            <Warning className={classes.warningIcon} fontSize="large" />
            <Typography color="textPrimary" component="p">
              {message}
            </Typography>
          </Box>
          <Divider />
          {relatedDependencies.slice(0, maxRelated).map((pn) => (
            <Typography className={classes.dependentItem} color="textPrimary">
              - {typeof pn === 'string' ? pn : `${pn.name} ${t('warningContainer.belongingTo')} ${pn.userFullName}`}
            </Typography>
          ))}
          {relatedDependencies.length >= maxRelated && (
            <Typography className={classes.dependentItem} color="textPrimary">
              {t('warningContainer.andMore')} {relatedDependencies.length - maxRelated}...
            </Typography>
          )}
        </Box>
      </>
    )
  }
)

export default WarningContainer
