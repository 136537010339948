import i18n from '../../../locales/i18next'
import { ComponentExtension, PivotGridComponent, PivotGridField } from '../types'

class CollapseAllExtension implements ComponentExtension {
  name: string
  icon: string
  text: string
  onItemClick?: () => void

  constructor(component: PivotGridComponent, field: PivotGridField) {
    this.name = 'CollapseAllExtension'
    this.icon = 'collapse'
    this.text = i18n.t('pivotGrid.extensions.collapseAll.text')
    this.onItemClick = () => collapseAll(field, component)
  }
}

const collapseAll = (field: PivotGridField, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  dataSource.collapseAll(field.index!)
}

export default CollapseAllExtension
