/* eslint-disable no-constant-condition */
import { Add } from '@mui/icons-material'
import TeamIcon from '@mui/icons-material/Group'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ContentBox from '../../components/ContentBox'
import Header from '../../components/Header'
import List from '../../components/List'
import Loading from '../../components/Loading'
import RootBox from '../../components/RootBox'
import TeamDialog from '../../components/TeamDialog'
import { TeamSchema } from '../../schemas/TeamSchema'
import teamService from '../../services/teamService'
import { normalyzeAndLowering } from '../../utils/stringUtils'

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const TeamsPage: React.FC<PropsWithChildren> = () => {
  const [teams, setTeams] = useState<TeamSchema[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [editTeam, setEditTeam] = useState<TeamSchema | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()
  const classes = useStyles()

  const getFilteredTeams = () => {
    if (!searchValue) {
      return teams.sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
    }
    return teams
      .filter((p) => normalyzeAndLowering(p.name).includes(normalyzeAndLowering(searchValue)))
      .sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
  }

  const reloadTeams = async () => {
    const response = await teamService.getTeams()
    setTeams(response.data)
  }

  useEffect(() => {
    ;(async () => {
      try {
        await reloadTeams()
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, t])

  const handleEditTeam = (team: TeamSchema) => {
    setEditTeam(team)
    setDialogOpen(true)
  }

  const handleCloseDialog = (refresh?: boolean) => {
    setDialogOpen(false)
    setEditTeam(null)
    if (refresh) reloadTeams()
  }

  return (
    <RootBox>
      <Header
        icon={TeamIcon}
        title={t('pageTeams.Title')}
        subtitle={t('pageTeams.Subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        <Stack
          alignItems="flex-end"
          paddingRight={4}
          sx={(theme) => ({
            bgcolor: theme.palette.background.light.default
          })}
        >
          <Button
            sx={{
              height: 40
            }}
            onClick={() => setDialogOpen(true)}
            startIcon={<Add />}
            label="Criar"
          />
        </Stack>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <List
            className={classes.list}
            items={getFilteredTeams()}
            itemIdKey="id"
            itemLabelKey="name"
            onEdit={handleEditTeam}
          />
        )}
      </ContentBox>
      <TeamDialog open={dialogOpen} team={editTeam} onClose={handleCloseDialog} />
    </RootBox>
  )
}

export default TeamsPage
