import { Checkbox, FormControlLabel, TextFieldProps } from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

type FormCheckboxProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T, any>
} & TextFieldProps

function FormCheckbox<T extends FieldValues>({ name, label, control, ...rest }: FormCheckboxProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => <FormControlLabel control={<Checkbox {...field} />} label={label} />}
    />
  )
}

export default FormCheckbox
