import { ArrowDropDown, Clear } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Autocomplete, Checkbox, Link, Paper, Stack, Typography, styled } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { userService } from '../../services'
import { StyledTextField } from '../signIn'

/*  
  SHOWED ON EMAIL CONFIRMATION LINK CLICK
*/

export const ResponsivePaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark.contrast,
  padding: theme.spacing(4)
}))
const CreateAccountPage: React.FC = () => {
  const { t } = useTranslation()

  const validationSchema = yup.object({
    firstName: yup.string().required().label(t('signup.form.firstName')),
    lastName: yup.string().required().label(t('signup.form.lastName')),
    companyName: yup.string().required().label(t('signup.form.companyName'))
  })
  const roleOptions = [
    { label: t('signup.role.option.analyst'), value: 'Analista' },
    { label: t('signup.role.option.coordinator'), value: 'Coordenador' },
    { label: t('signup.role.option.manager'), value: 'Gerente' },
    { label: t('signup.role.option.director'), value: 'Diretor' },
    { label: t('signup.role.option.ceo'), value: 'CEO/Presidente' },
    { label: t('signup.role.option.entrepreneur'), value: 'Empreendedor' },
    { label: t('signup.role.option.developer'), value: 'Desenvolvedor/Programador' },
    { label: t('signup.role.option.designer'), value: 'Designer' },
    { label: t('signup.role.option.salesperson'), value: 'Vendedor' },
    { label: t('signup.role.option.consultant'), value: 'Consultor' },
    { label: t('signup.role.option.assistant'), value: 'Assistente' },
    { label: t('signup.role.option.intern'), value: 'Estagiário' },
    { label: t('signup.role.option.other'), value: 'Outro' }
  ]
  const employeeCountOptions = [
    { label: t('signup.employeeCount.option.onlyMe'), value: 'Apenas eu' },
    { label: t('signup.employeeCount.option.2To5'), value: '2 a 5' },
    { label: t('signup.employeeCount.option.6To10'), value: '6 a 10' },
    { label: t('signup.employeeCount.option.11To25'), value: '11 a 25' },
    { label: t('signup.employeeCount.option.26To50'), value: '26 a 50' },
    { label: t('signup.employeeCount.option.51To200'), value: '51 a 200' },
    { label: t('signup.employeeCount.option.201To500'), value: '201 a 500' },
    { label: t('signup.employeeCount.option.501OrMore'), value: '501 ou mais' }
  ]

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const userId = searchParams.get('userId')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const auth = useAuth()
  const navigate = useNavigate()
  const [termsAccepted, setTermsAccepted] = useState(false)

  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false)
  const { getFieldProps, handleSubmit, submitForm, values, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      companyName: '',
      role: '',
      employeeCount: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoadingCreateAccount(true)
      localStorage.setItem('token', token!)
      try {
        await userService.update(userId!, {
          firstName: values.firstName,
          lastName: values.lastName,
          profileId: null
        })
        const { data } = await userService.createAccount(userId!, {
          companyName: values.companyName,
          userRole: values.role,
          employeeCount: values.employeeCount,
          planId: 999
        })
        await auth.accessAccount(data.accountId, token!)
        await auth.loadUserData()
        navigate(PATHS.HOME)
      } catch (err) {
        enqueueSnackbar('Error creating account', { variant: 'error' })
        setLoadingCreateAccount(false)
      }
    }
  })

  useEffect(() => {
    if (!token) {
      setError('Token is missing')
      return
    }
    if (!userId) {
      setError('User id is missing')
      return
    }
  }, [token, userId])

  const [loadingResendEmail, setLoadingResendEmail] = useState(false)
  const [emailResended, setEmailResended] = useState(false)
  const [error, setError] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const handleResendEmail = async () => {
    setLoadingResendEmail(true)
    try {
      if (!email) {
        setError('Email is missing')
      } else {
        await userService.resendEmailConfirmation({ email })
        setEmailResended(true)
      }
    } catch (err: any) {
      enqueueSnackbar('Error resending email', { variant: 'error' })
    }
    setLoadingResendEmail(false)
  }

  return (
    <Stack
      direction="column"
      minHeight="100vh"
      sx={{
        background: (theme) =>
          `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${theme.palette.background.light.contrast || '#FFF'}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
      }}
    >
      <ResponsivePaper elevation={5}>
        {!!error ? (
          <Stack spacing={2}>
            <Alert severity="error">
              <AlertTitle>Ops!</AlertTitle>
              {t('confirm.emailProblem"')}
            </Alert>
            <Typography textAlign="center" variant="h1">
              {t('confirm.tokenValidation')}
            </Typography>
            <Typography textAlign="center" variant="body1">
              {error}
            </Typography>
            <LoadingButton loading={loadingResendEmail} onClick={() => handleResendEmail()}>
              {t('confirm.resendEmail')}
            </LoadingButton>
          </Stack>
        ) : (
          <>
            <Stack spacing={4} color="#fff">
              {emailResended ? (
                <Typography variant="h1">{t('confirm.emailSent')}</Typography>
              ) : (
                <>
                  <Typography textAlign="center" variant="h3" color="#fff">
                    {t('confirm.fillInformationTitle')}
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <StyledTextField required label={t('signup.form.firstName')} {...getFieldProps('firstName')} />
                      <StyledTextField required label={t('signup.form.lastName')} {...getFieldProps('lastName')} />
                      <StyledTextField
                        required
                        label={t('signup.form.companyName')}
                        {...getFieldProps('companyName')}
                      />
                      <Autocomplete
                        value={values['role'] as any}
                        onChange={(event, newValue) => setFieldValue('role', newValue)}
                        renderInput={(props) => <StyledTextField {...props} label={t('signup.form.role')} />}
                        getOptionLabel={(option) =>
                          !!option ? roleOptions.find((x) => x.value === option)?.label ?? '' : ''
                        }
                        clearIcon={<Clear fontSize="small" htmlColor="#fff" />}
                        popupIcon={<ArrowDropDown fontSize="small" htmlColor="#fff" />}
                        options={roleOptions.map((x) => x.value)}
                      />
                      <Autocomplete
                        value={values['employeeCount'] as any}
                        onChange={(event, newValue) => setFieldValue('employeeCount', newValue)}
                        renderInput={(props) => <StyledTextField {...props} label={t('signup.form.employCount')} />}
                        getOptionLabel={(option) =>
                          !!option ? employeeCountOptions.find((x) => x.value === option)?.label ?? '' : ''
                        }
                        clearIcon={<Clear fontSize="small" htmlColor="#fff" />}
                        popupIcon={<ArrowDropDown fontSize="small" htmlColor="#fff" />}
                        options={employeeCountOptions.map((x) => x.value)}
                      />
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          required
                          checked={termsAccepted}
                          onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <Typography variant="body2" color="#fff">
                          Confirmo que li e concordo com os{' '}
                          <Link component="a" href="https://pluggerbi.com/termo-de-servico/" target="_blank">
                            Termos de Serviço
                          </Link>{' '}
                          e{' '}
                          <Link component="a" href="https://pluggerbi.com/politica-de-privacidade/" target="_blank">
                            Politica de Privacidade
                          </Link>
                        </Typography>
                      </Stack>
                      <LoadingButton
                        disabled={!termsAccepted}
                        loading={loadingCreateAccount}
                        onClick={() => submitForm()}
                        variant="contained"
                      >
                        {t('confirm.createAccountBtn')}
                      </LoadingButton>
                    </Stack>
                  </form>
                </>
              )}
            </Stack>
          </>
        )}
      </ResponsivePaper>
    </Stack>
  )
}

export default CreateAccountPage
