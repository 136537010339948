import { DashboardControlArgs, DashboardInitializedArgs, WorkingMode } from 'devexpress-dashboard'
import { AjaxRemoteService, DashboardControl, DashboardExport, Extensions } from 'devexpress-dashboard-react'
import { LegacyRef, forwardRef, memo } from 'react'
import { BASE_URL } from '../../constants/apiEndpoints'
import { useQuery } from '../../hooks/useQuery'

type DashboardControlWrapperProps = {
  onDashboardInitialized?: ((args: DashboardInitializedArgs) => void) | undefined
  onBeforeRender?: ((args: DashboardControlArgs) => void) | undefined
  dashboardId?: string | null
  allowExport?: boolean
  defaultWorkingMode?: WorkingMode
}

const DashboardControlWrapper = forwardRef<DashboardControl | undefined, DashboardControlWrapperProps>((props, ref) => {
  const getLocalToken = () => localStorage.getItem('token')
  const query = useQuery()

  const getHeaderAuth = () => {
    const headers = { Authorization: 'Bearer ' + getLocalToken() } as any
    const templateId = query.get('template')
    if (!!templateId) {
      headers.template = templateId
    }
    return headers
  }

  return (
    <DashboardControl
      onDashboardInitialized={props.onDashboardInitialized}
      ref={ref as LegacyRef<DashboardControl>}
      onBeforeRender={props.onBeforeRender}
      style={{ height: '100%' }}
      //className={classes.dashboardOverlayOverride}
      limitVisibleDataMode={'Designer'}
      dashboardId={props.dashboardId || undefined}
      showConfirmationOnBrowserClosing
      dataRequestOptions={{ itemDataLoadingMode: 'OnDemand', itemDataRequestMode: 'BatchRequests' }}
      endpoint={BASE_URL}
      defaultWorkingMode={props.defaultWorkingMode}
      defaultDashboardId={null}
      loadDefaultDashboard={false}
    >
      <AjaxRemoteService headers={getHeaderAuth()} />
      <Extensions>
        <DashboardExport allowExportDashboard={props.allowExport} allowExportDashboardItems={props.allowExport} />
      </Extensions>
    </DashboardControl>
  )
})

export default memo(DashboardControlWrapper, (prevProps, newProps) => {
  return (
    prevProps.dashboardId === newProps.dashboardId &&
    prevProps.onDashboardInitialized === newProps.onDashboardInitialized &&
    prevProps.onBeforeRender === newProps.onBeforeRender &&
    prevProps.allowExport === newProps.allowExport
  )
})
