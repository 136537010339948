import { ChangeSet, EditingState } from '@devexpress/dx-react-grid'
import { Grid, TableHeaderRow, TableInlineCellEditing, VirtualTable } from '@devexpress/dx-react-grid-material-ui'
import { Autocomplete, Paper, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProps } from '../../components/Form'
import TextField from '../../components/TextField'
import { ParameterSchema, UserParameterSchema } from '../../schemas/ParameterSchema'
import { parameterService, userService } from '../../services'
import AutocompleteTypeProvider from '../AutocompleteTypeProvider/AutocompleteTypeProvider'

interface ParameterFormProps extends Omit<FormProps<ParameterSchema>, 'initialValues' | 'onSubmit'> {
  toggleDialog(open: boolean): void
  reloadParameters(): Promise<void>
  updateId?: string
}

type UserParameterForm = UserParameterSchema & { name: string }
type ParsedUserParameter = Omit<ParameterSchema, 'userParameters'> & { userParameters: UserParameterForm[] }

const ParameterForm: React.FC<PropsWithChildren<ParameterFormProps>> = ({
  toggleDialog,
  reloadParameters,
  updateId,
  ...rest
}) => {
  const [parameter, setParameter] = useState<ParsedUserParameter>()
  const [userParameters, setUserParameters] = useState<UserParameterForm[]>([])
  const [parameterType, setParameterType] = useState<string>('Single')
  const initialParameterName = useRef<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({
    haveError: false,
    msg: ''
  })
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      try {
        const param: ParsedUserParameter = {
          id: '',
          name: '',
          userParameters: [],
          type: 'Single'
        }
        const users = await userService.getAll()
        param.userParameters = users.data
          .filter((x) => x.statusInAccount !== 'INACTIVE')
          ?.map((u) => ({
            userId: u.id,
            name: !!u.firstName ? u.firstName + ' ' + u.lastName : u.email,
            value: undefined
          }))
        if (updateId) {
          const response = await parameterService.get(updateId)
          const fetchedParam = response.data
          param.id = fetchedParam.id
          param.name = fetchedParam.name
          param.type = fetchedParam.type
          setParameterType(fetchedParam.type)
          initialParameterName.current = param.name
          param.userParameters.forEach((u) => {
            const fetchedUserParameters = fetchedParam.userParameters.filter((a) => a.userId == u.userId)
            if (fetchedUserParameters.length > 0) {
              u.value = fetchedUserParameters.map((x) => x.value) as any
            }
          })
        }
        setUserParameters(param.userParameters)
        setParameter(param)
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      } finally {
        setLoading(false)
      }
    })()
  }, [enqueueSnackbar, updateId, t])

  const filterParametrizedUsers = useCallback(
    (users: UserParameterSchema[]) => {
      if (parameterType === 'List') {
        const splitedUserParameters: UserParameterSchema[] = []
        for (const userParam of users.filter((x) => !!x.value && x.value.length > 0)) {
          ;(userParam.value as any).forEach((x: any) => {
            const currUser = { ...userParam }
            currUser.value = x
            splitedUserParameters.push(currUser)
          })
        }
        return splitedUserParameters
      }
      return users
        .filter((e) => !!e.value)
        .map((x) => {
          if (Array.isArray(x.value)) {
            return { ...x, value: x.value![0] }
          }
          return x
        })
    },
    [parameterType]
  )

  const handleCreate = useCallback(
    async (createParameter: Omit<ParameterSchema, 'id'>) => {
      if (!createParameter.name) {
        setError({
          haveError: true,
          msg: t('pageParameters.modal.validation.nameEmpty')
        })
        return
      } else {
        setError({
          haveError: false,
          msg: ''
        })
      }
      toggleDialog(false)
      try {
        createParameter.userParameters = filterParametrizedUsers(userParameters)
        createParameter.type = parameterType as any
        await parameterService.create(createParameter)
        enqueueSnackbar(t('pageParameters.modal.toast.success.create', { name: createParameter.name }), {
          variant: 'success'
        })
        reloadParameters()
      } catch (err: any) {
        console.error('error when try persist parameter', err)
        enqueueSnackbar(t('pageParameters.modal.toast.error.create'), { variant: 'error' })
      }
    },
    [toggleDialog, filterParametrizedUsers, userParameters, parameterType, enqueueSnackbar, t, reloadParameters]
  )

  const handleUpdate = useCallback(
    async (parameterId: string, parameter: ParameterSchema) => {
      toggleDialog(false)
      try {
        parameter.userParameters = filterParametrizedUsers(userParameters)
        await parameterService.update(parameterId, parameter)
        enqueueSnackbar(t('pageParameters.modal.toast.success.update', { name: parameter.name }), {
          variant: 'success'
        })
        reloadParameters()
      } catch (err: any) {
        console.error('error when try update parameter', err)
        enqueueSnackbar(t('pageParameters.modal.toast.error.update'), { variant: 'error' })
      }
    },
    [toggleDialog, filterParametrizedUsers, userParameters, enqueueSnackbar, t, reloadParameters]
  )
  const handleSubmit = useCallback(async () => {
    if (
      parameter!.name !== initialParameterName.current &&
      !(await parameterService.checkParameterNameAvailable(parameter!.name))
    ) {
      setError({
        haveError: true,
        msg: t('pageParameters.modal.validation.nameInUse')
      })
      return
    }
    if (updateId) {
      handleUpdate(updateId, parameter!)
      return
    }
    handleCreate(parameter!)
  }, [updateId, parameter, handleCreate, handleUpdate, t])

  useEffect(() => {
    if (rest.isSubmitting) {
      handleSubmit()
    }
  }, [rest.isSubmitting, handleSubmit])

  const getHeight = () => {
    const increment = 65
    const calculatedHeight = userParameters ? userParameters.length * increment : 160
    return `${calculatedHeight + 60}px`
  }

  const commitChanges = ({ changed }: ChangeSet) => {
    let changedRows: UserParameterForm[] = []
    const rows = userParameters
    if (changed) {
      changedRows = rows.map((row) => {
        const changedValue = changed[row.userId]
        if (changedValue) {
          if (parameterType === 'List') {
            changedValue.value.map((x: any) => String(x).trim())
          } else {
            changedValue.value = String(changedValue.value).trim()
          }
          return { ...row, ...changedValue }
        }
        return row
      })
    }
    setUserParameters(changedRows)
  }

  const handleParameterTypeChange = (e: any, newValue: any) => {
    if (newValue === 'Single') {
      if (window.confirm(t('pageParameters.modalCreate.parameters.confirm.alert.text')!)) {
        setUserParameters((prev) => {
          prev.forEach((x) => {
            if (!!x.value && x.value.length > 0) {
              x.value = x.value[0]
            }
          })
          return prev
        })
      } else {
        newValue = 'List'
      }
    }
    setParameterType(newValue)
  }

  return (
    <Stack spacing={1}>
      <TextField
        name="parameter-name"
        label={t('pageParameters.modalCreate.paramName')}
        error={error.haveError}
        helperText={error.haveError ? error.msg : ''}
        value={parameter?.name || ''}
        onChange={(e) =>
          setParameter((prev) => ({
            id: prev!.id,
            userParameters: prev!.userParameters,
            name: e.target.value,
            type: prev?.type || 'Single'
          }))
        }
      />
      <Autocomplete
        value={parameterType}
        disabled={!!updateId}
        onChange={handleParameterTypeChange}
        getOptionLabel={(e) => (e === 'Single' ? 'Single' : 'List')}
        options={['Single', 'List']}
        renderInput={(params) => <TextField {...params} label="Parameter Type" />}
      />
      <Paper style={{ height: 'auto', minWidth: '580px', width: '100%' }}>
        <Grid
          columns={[
            {
              name: 'name',
              title: t('pageParameters.modalCreate.userName')!
            },
            {
              name: 'value',
              title: t('pageParameters.modalCreate.paramValue')!
            }
          ]}
          rows={userParameters?.sortBy((x) => x.name)}
          getRowId={(rowData) => rowData.userId}
        >
          <AutocompleteTypeProvider multiple={parameterType === 'List'} for={['value']} />
          <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={[
              {
                columnName: 'name',
                editingEnabled: false
              }
            ]}
          />
          <VirtualTable height="auto" />
          <TableHeaderRow />
          <TableInlineCellEditing selectTextOnEditStart={false} />
        </Grid>
      </Paper>
    </Stack>
  )
}

export default ParameterForm
