import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import {
  AvailablePlugSchema,
  CreatePlugSchema,
  PlugFieldSchema,
  PlugSchema,
  PlugSchemaWithFields,
  UpdatePlugSchema
} from '../../schemas/PlugSchema'
import { format } from '../../utils/stringUtils'
import api from '../api'
import { AnalyticsAccount, AnalyticsAccountProperty } from '../google/googleAnalyticsService'

const getAllWithFields = (): Promise<AxiosResponse<PlugSchemaWithFields[]>> => {
  return api.get(ENDPOINTS.PLUGS_GET_ALL + '?includeFields=true')
}

const getAll = (): Promise<AxiosResponse<PlugSchema[]>> => {
  return api.get(ENDPOINTS.PLUGS_GET_ALL)
}

const getAvailables = (): Promise<AxiosResponse<AvailablePlugSchema[]>> => {
  return api.get(ENDPOINTS.GET_AVAILABLE_PLUGS)
}

const getAvailablePlugFields = (plugType: string): Promise<AxiosResponse<PlugFieldSchema[]>> => {
  return api.get(format(ENDPOINTS.GET_AVAILABLE_PLUG_FIELDS, plugType))
}

const getByName = (plugName: string): Promise<AxiosResponse<PlugSchema>> => {
  return api.get(format(ENDPOINTS.PLUGS_GET_BY_NAME, plugName))
}

const getById = (id: number): Promise<AxiosResponse<PlugSchema>> => {
  return api.get(format(ENDPOINTS.PLUGS_GET_BY_NAME, id))
}

const getFields = (plugId: number, fetchSchema?: boolean): Promise<AxiosResponse<PlugFieldSchema[]>> => {
  let resourceUri = format(ENDPOINTS.GET_PLUG_FIELDS, plugId)
  if (fetchSchema) {
    resourceUri = resourceUri + '?fetchSchema=' + fetchSchema
  }
  return api.get(resourceUri)
}

const getFieldDistinctValues = (plugId: number, fieldName: string): Promise<AxiosResponse<Array<any>>> => {
  return api.get(format(ENDPOINTS.GET_PLUG_FIELD_DISTINCT_VALUES, plugId, fieldName))
}

const create = (newPlug: CreatePlugSchema): Promise<AxiosResponse<PlugSchema>> => {
  return api.post(ENDPOINTS.PLUGS_CREATE, newPlug)
}

export type CreateCsvPlugRequest = {
  name: string
  fileUrl: string
  delimiter: string
  filter?: string
  fields: PlugFieldSchema[]
}

const createCsv = (newPlug: CreateCsvPlugRequest): Promise<AxiosResponse<PlugSchema>> => {
  return api.post(ENDPOINTS.PLUGS_CREATE_CSV, newPlug)
}

const update = (plugId: number, updatedPlug: UpdatePlugSchema): Promise<AxiosResponse<PlugSchema>> => {
  return api.put(format(ENDPOINTS.PLUGS_UPDATE, plugId), updatedPlug)
}

const updateData = (plugId: number): Promise<AxiosResponse<void>> => {
  return api.post(format(ENDPOINTS.PLUGS_UPDATE_DATA, plugId))
}

const _delete = (plugId: number): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.PLUGS_DELETE, plugId))
}

type PlugAnalysisResponse = {
  analysisId: string
  analysisName: string
  userOwner: {
    userOwnerId: string
    userOwnerEmail: string
    userOwnerFullName: string
  }
}

const getAnalysis = (plugId: number): Promise<AxiosResponse<PlugAnalysisResponse[]>> => {
  return api.get(format(ENDPOINTS.PLUGS_DELETE + '/analysis', plugId))
}

export type CreateGoogleAnalyticsPlugRequest = {
  name: string
  filter?: string
  fields: PlugFieldSchema[]
  account: AnalyticsAccount
  property: AnalyticsAccountProperty
  connectionId: number
}

const createGoogleAnalytics = (newPlug: CreateGoogleAnalyticsPlugRequest): Promise<AxiosResponse<PlugSchema>> => {
  return api.post('/plugs/google-analytics', newPlug)
}

export default {
  createGoogleAnalytics,
  getById,
  getAll,
  create,
  getFields,
  update,
  _delete,
  getByName,
  getFieldDistinctValues,
  getAvailables,
  updateData,
  getAvailablePlugFields,
  getAllWithFields,
  createCsv,
  getAnalysis
}
