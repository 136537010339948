import PivotGrid from 'devextreme-react/pivot-grid'
import { ContentReadyEvent, ContextMenuPreparingEvent } from 'devextreme/ui/pivot_grid'
import { memo } from 'react'
import { PivotGridConfig } from './types'

type PivotGridWrapperProps = {
  onContentReady: (e: ContentReadyEvent) => void
  ref: React.RefObject<PivotGrid>
  onContextMenuPreparing: (e: ContextMenuPreparingEvent) => void
  dataSource: any
  pivotGridConfig: PivotGridConfig
}

const PivotGridWrapper: React.FC<PivotGridWrapperProps> = ({
  onContentReady,
  ref,
  onContextMenuPreparing,
  dataSource,
  pivotGridConfig
}) => {
  return (
    <PivotGrid
      onContentReady={onContentReady}
      width="100%"
      style={{ flex: '1 1 auto' }}
      height="100%"
      ref={ref}
      id="pivot-grid-component"
      onContextMenuPreparing={onContextMenuPreparing}
      dataSource={dataSource as any}
      scrolling={{
        mode: 'virtual'
      }}
      {...pivotGridConfig}
    />
  )
}

export default memo(PivotGridWrapper, (prev, next) => {
  return (
    prev.dataSource === next.dataSource &&
    prev.pivotGridConfig === next.pivotGridConfig &&
    prev.ref === next.ref &&
    //prev.onContentReady === next.onContentReady &&
    prev.onContextMenuPreparing === next.onContextMenuPreparing
  )
})
