import { Add } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Swipeable from 'react-swipeable-views'
import * as yup from 'yup'
import Loading from '../../../../../components/Loading'
import SelectBox from '../../../../../components/SelectBox'
import TextField from '../../../../../components/TextField'
import MySqlConnectionForm from '../../../../../components/forms/MySqlConnectionForm'
import { ConnectionSchema, DatabaseConnectionType } from '../../../../../schemas/ConnectionSchema'
import { connectionService } from '../../../../../services'
import NavigationHeader, { NavigationHeaderProps } from '../NavigationHeader'

export type MySqlPlugFormValues = {
  connection: ConnectionSchema
  tableOrView: string
  plugName: string
}

type MySqlPlugFormProps = {
  onSubmit: (values: MySqlPlugFormValues) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(2, 0, 3)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

const validationSchema = yup.object({
  connection: yup.object().required(),
  plugName: yup.string().required()
})

const MySqlPlugForm: React.FC<PropsWithChildren<MySqlPlugFormProps>> = ({ onSubmit, navigationProps }) => {
  const [connections, setConnections] = useState<ConnectionSchema[]>([])
  const [tableAndViews, setTableAndViews] = useState<string[]>([])

  const [loadingFindConnection, setLoadingFindConnection] = useState(false)
  const [loadingFindTableView, setLoadingFindTableView] = useState(false)
  const [loadingCreateConnection, setLoadingCreateConnection] = useState(false)
  const [showCreateConnectionForm, setShowCreateConnectionForm] = useState(false)
  const { slideUpdateHeight } = useContext((Swipeable as any).SwipeableViewsContext) as any

  const [selectTableOrViewHelperText, setSelectTableOrViewHelperText] = useState<string | null>(null)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { values, setFieldValue, submitForm, errors } = useFormik({
    validateOnChange: false,
    initialValues: {} as MySqlPlugFormValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema
  })

  const handleValuesChange = (key: string, value: any) => {
    setFieldValue(key, value)
  }

  const handleCreateConnection = async (values: any) => {
    values.type = DatabaseConnectionType.MySql
    try {
      setLoadingCreateConnection(true)
      const response = await connectionService.create(values)

      setConnections((prev) => [...prev, response.data])
      handleValuesChange('selectedConnection', response.data)
      setShowCreateConnectionForm(false)

      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createConnection', { connectionName: values.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.createConnection'), { variant: 'error' })
    } finally {
      setLoadingCreateConnection(false)
    }
  }

  const handleOpenSelectConnection = () => {
    ;(async () => {
      try {
        setLoadingFindConnection(true)
        const connsResp = await connectionService.getAll()
        setConnections(connsResp.data.filter((x) => x.type === DatabaseConnectionType.MySql))
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindConnection(false)
      }
    })()
  }

  useEffect(() => {
    slideUpdateHeight()
  }, [showCreateConnectionForm, slideUpdateHeight])

  const handleOpenSelectTableOrView = () => {
    if (!values.connection) {
      setSelectTableOrViewHelperText(t('pagePlugs.modalCreate.connectionField.helperText'))
      return
    }
    ;(async () => {
      try {
        handleValuesChange('tableOrView', '')
        setSelectTableOrViewHelperText(null)
        setLoadingFindTableView(true)
        const connsResp = await connectionService.getTableAndViewNames(values.connection!.id)
        setTableAndViews(connsResp.data)
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const firstError = err.response?.data?.[0]
          console.error(firstError)
          enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
          return
        }
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.getTableViewNames'), { variant: 'error' })
      } finally {
        setLoadingFindTableView(false)
      }
    })()
  }

  const handleChangeTableOrView = async (_e: React.ChangeEvent<{}>, newValue: string | null | string[]) => {
    handleValuesChange('tableOrView', newValue)
  }

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() => submitForm()}
      />
      <Typography variant="h2" align="center" component="div" color="inherit">
        {t('pagePlugs.modalCreate.configureTitle')}
      </Typography>
      <Typography variant="subtitle1" align="center" component="p" color="inherit">
        {t('pagePlugs.modalCreate.configureSubtitle')}
      </Typography>
      {showCreateConnectionForm ? (
        <>
          <Loading forceAlign plugFill="#fff" loopFill="#fff" hidden={!loadingCreateConnection} />
          <MySqlConnectionForm
            onCancelClick={() => setShowCreateConnectionForm(false)}
            onSubmit={handleCreateConnection}
            variant="white"
            usePlaceholder
            hidden={loadingCreateConnection}
          />
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" className={classes.dialogFields}>
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.fullWidth)}
              options={connections}
              value={values.connection}
              onChange={(event, newValue) => handleValuesChange('connection', newValue)}
              getOptionLabel={(o) => o.name}
              loading={loadingFindConnection}
              onOpen={handleOpenSelectConnection}
              textFieldProps={{
                placeholder: t('pagePlugs.modalCreate.configureConnection')!,
                name: 'connection',
                error: !!errors['connection'],
                helperText: errors['connection'] as any
              }}
            />
            <IconButton onClick={() => setShowCreateConnectionForm(true)} color="inherit" size="large">
              <Add />
            </IconButton>
          </Box>
          <SelectBox
            className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
            options={tableAndViews}
            value={values.tableOrView}
            loading={loadingFindTableView}
            onChange={handleChangeTableOrView}
            onOpen={handleOpenSelectTableOrView}
            textFieldProps={{
              classes: {
                root: classes.inputHelperColorWhite
              },
              placeholder: t('pagePlugs.modalCreate.configureTable')!,
              name: 'tableOrView',
              error: !!errors['tableOrView'],
              helperText: selectTableOrViewHelperText || errors['tableOrView']
            }}
          />
          <TextField
            variant="outlined"
            fullWidth={false}
            value={values.plugName}
            onChange={(event) => handleValuesChange('plugName', event.target.value)}
            required
            className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
            placeholder={t('pagePlugs.modalCreate.configureName')!}
            error={!!errors['plugName']}
            helperText={errors['plugName']}
          />
        </>
      )}
    </>
  )
}

export default MySqlPlugForm
