import { AxiosResponse } from 'axios'
import moment from 'moment'
import React, { createContext, PropsWithChildren, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import { loadLocale } from '../../locales'
import api from '../../services/api'
import { ConfirmFormValues, ConfirmNewResponse, SignUp } from '../../services/authService'
import { accountService, authService, userService } from '../../services/index'
import windowUtils from '../../utils/windowUtils'
import { AccountInfo, AuthProviderProps, UserInfo } from './types'

export const AuthContext = createContext<AuthProviderProps>({} as AuthProviderProps)

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const [userInfo, setUserInfoState] = useState<UserInfo>()
  const [accountInfo, setAccountInfoState] = useState<AccountInfo>()

  const validateAuth = useCallback(() => {
    const token = localStorage.getItem('token')
    const localDateExpiration = localStorage.getItem('tokenExpiration')
    const momentLocalDateExpiration = moment(localDateExpiration)
    if (momentLocalDateExpiration.isValid()) {
      return !!token && momentLocalDateExpiration.isAfter(moment.now())
    }

    return false
  }, [])

  const validateAuthAsync = useCallback(async () => {
    await api.get(ENDPOINTS.ACCOUNTS_GET_USER_ACCOUNTS)
  }, [])

  const setUserInfo = useCallback((userInfo: UserInfo) => {
    setUserInfoState((prevInfo) => ({ ...prevInfo, ...userInfo }))
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  }, [])

  const setAccountInfo = useCallback((accountInfo: AccountInfo) => {
    setAccountInfoState((prevInfo) => ({ ...prevInfo, ...accountInfo }))
    localStorage.setItem('accountInfo', JSON.stringify(accountInfo))
  }, [])

  const login = async (email: string, password: string) => {
    const { data } = await authService.login(email, password)
    setToken(data.token, data.expiration)
  }
  const accessAccount = async (accountId: number, token?: string) => {
    const { data } = await authService.accessAccount(accountId, token)
    setToken(data.token, data.expiration)

    await loadUserData()
  }

  const signOut = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('accountInfo')
    navigate(PATHS.SIGNIN)
  }

  const getInfos = async () => {
    const userPromise = userService.getCurrentInfoV2()
    const accountPromise = accountService.getCurrentInfo()

    return await Promise.all([userPromise, accountPromise])
  }

  const setToken = (token: string, expiration: Date) => {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenExpiration', expiration as any)
  }

  const getUserInfo = useCallback(() => {
    if (userInfo) return userInfo

    const localUserInfoStringed = localStorage.getItem('userInfo')
    if (localUserInfoStringed) return JSON.parse(localUserInfoStringed)
  }, [userInfo])

  const getAccountInfo = useCallback(() => {
    if (accountInfo) return accountInfo

    const localAccountInfoStringed = localStorage.getItem('accountInfo')
    if (localAccountInfoStringed) return JSON.parse(localAccountInfoStringed)
  }, [accountInfo])

  const loadUserData = async () => {
    const [userInfoResponse, accountInfoResponse] = await getInfos()

    const userInfoData = userInfoResponse.data
    setUserInfo({
      userFirstName: userInfoData.firstName,
      userLastName: userInfoData.lastName,
      userId: userInfoData.id,
      imagePath: userInfoData.thumbImagePath,
      userEmail: userInfoData.email,
      profile: userInfoData.profile as any,
      team: userInfoData.team ?? undefined,
      locale: userInfoData.locale
    })

    const accountInfoData = accountInfoResponse.data
    setAccountInfo({
      accountId: accountInfoData.id,
      imagePath: accountInfoData.logoImage,
      deleteRequestDate: accountInfoData?.deleteRequestDate,
      ...accountInfoData
    })

    loadLocale(userInfoData.locale || windowUtils.getLanguage())
  }
  const loadOnlyUserData = async () => {
    const { data } = await userService.getCurrentInfoV2()

    setUserInfo({
      userFirstName: data.firstName,
      userLastName: data.lastName,
      userId: data.id,
      imagePath: data.thumbImagePath,
      userEmail: data.email,
      profile: data.profile as any,
      team: data.team ?? undefined,
      locale: data.locale
    })

    loadLocale(data.locale || windowUtils.getLanguage())
  }

  const signUp = (values: SignUp): Promise<AxiosResponse<void>> => {
    return authService.signUp(values)
  }

  const confirmNew = (values: ConfirmFormValues): Promise<AxiosResponse<ConfirmNewResponse>> => {
    return authService.confirmNew(values)
  }

  const confirm = (token: string): Promise<AxiosResponse<ConfirmNewResponse>> => {
    return authService.confirm(token)
  }

  const signInGoogle = async (token: string) => {
    const { data } = await authService.loginGoogle(token)
    setToken(data.token, data.expiration)
    return data.token
  }

  const getToken = () => {
    const token = localStorage.getItem('token')
    return token
  }

  const authDataContext = {
    validateAuth,
    validateAuthAsync,
    login,
    accessAccount,
    setUserInfo,
    setAccountInfo,
    getAccountInfo,
    getUserInfo,
    loadUserData,
    signOut,
    signUp,
    confirmNew,
    confirm,
    setToken,
    signInGoogle,
    getToken,
    loadOnlyUserData
  } as AuthProviderProps

  return <AuthContext.Provider value={authDataContext}>{children}</AuthContext.Provider>
}

export default AuthProvider
