import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent, DialogPropsWithTitle } from '../../components/Dialog'
import DraggableList from '../../components/DraggableList'
import SelectBox from '../../components/SelectBox'
import AddedAnalyzeToCollectionListItem from '../../pages/home/AddedAnalyzeToCollectionListItem'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'
import { CollectionAnalyzeSchema, CollectionSchema, UpdateCollectionSchema } from '../../schemas/CollectionSchema'
import collectionService from '../../services/collectionService'

interface AddToCollectionDialogProps extends Omit<DialogPropsWithTitle, 'onClose'> {
  analyze?: AnalyzeSchema
  onClose(refresh: boolean): void
}

const initialState = {
  collections: [],
  analysis: [],
  selectedCollection: null
}

const useStyles = makeStyles((theme) => ({
  newAnalyze: {
    boxShadow: `0px 1px 8px 3px ${theme.palette.primary.main}`
  }
}))

const AddToCollectionDialog: React.FC<PropsWithChildren<AddToCollectionDialogProps>> = ({
  onClose,
  open,
  analyze,
  ...rest
}) => {
  const [collections, setCollections] = useState<CollectionSchema[]>([])
  const [selectedCollection, setSelectedCollection] = useState<CollectionSchema | null>(null)
  const [collectionAnalysis, setCollectionAnalysis] = useState<CollectionAnalyzeSchema[]>([])
  const [loadingAnalysis, setLoadingAnalysis] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { t } = useTranslation()

  const reorder = (list: CollectionAnalyzeSchema[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const clearState = () => {
    setCollections(initialState.collections)
    setCollectionAnalysis(initialState.analysis)
    setSelectedCollection(initialState.selectedCollection)
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = reorder(collectionAnalysis, result.source.index, result.destination.index)

    setCollectionAnalysis(items)
  }

  const loadCollections = useCallback(async () => {
    try {
      const collectionsResp = await collectionService.getCollections()
      setCollections(collectionsResp.data)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pivotGrid.menuSettings.modalCollection.toast.error.collections'), { variant: 'error' })
    }
  }, [enqueueSnackbar, t])

  useEffect(() => {
    if (open) {
      loadCollections()
    }
  }, [loadCollections, open])

  const loadAnalysisByCollection = async (collectionId: string) => {
    try {
      setLoadingAnalysis(true)
      const { data } = await collectionService.getAnalysis(collectionId)
      const mergedWithAddIntention = analyze ? ([...data, analyze] as CollectionAnalyzeSchema[]) : data
      setCollectionAnalysis(mergedWithAddIntention)
    } catch (err: any) {
      console.error('Error while getting collection analysis, ', err)
      enqueueSnackbar(t('pivotGrid.menuSettings.modalCollection.toast.error.collectionAnalysis'), { variant: 'error' })
    }
    setLoadingAnalysis(false)
  }

  const handleSelectCollection = (event: React.ChangeEvent<{}>, value: string | CollectionSchema | null) => {
    if (value) {
      setSelectedCollection(value as CollectionSchema)
      loadAnalysisByCollection((value as CollectionSchema).id)
    }
  }

  const handleUpdateCollection = async () => {
    if (!selectedCollection) {
      return
    }
    try {
      setLoadingAdd(true)
      const updateCollection: UpdateCollectionSchema = {
        id: selectedCollection.id,
        name: selectedCollection.name,
        analyzeIds: collectionAnalysis.map((e) => e.id)
      }
      await collectionService.update(updateCollection.id, updateCollection)
      onClose(true)
      enqueueSnackbar(
        t('pivotGrid.menuSettings.modalCollection.toast.success.addAnalysis', { analysisName: analyze?.name }),
        { variant: 'success' }
      )
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pivotGrid.menuSettings.modalCollection.toast.error.addAnalysis'), { variant: 'error' })
    }
    setLoadingAdd(false)
  }

  const collectionHaveAnalyze = (collection: CollectionSchema) => {
    return collection.analysis.some((an) => an.id === analyze?.id)
  }

  const handleGetOptionLabel = (collection: CollectionSchema) => {
    if (collectionHaveAnalyze(collection)) {
      return collection.name + ' - added'
    }
    return collection.name
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      {...rest}
      TransitionProps={{
        onExited: clearState
      }}
    >
      <DialogContent dividers>
        <SelectBox
          textFieldProps={{
            name: 'select-collections',
            label: t('pivotGrid.menuSettings.modalCollection.Choose')
          }}
          noOptionsText={t('pivotGrid.menuSettings.modalCollection.ChooseReturn')}
          getOptionDisabled={collectionHaveAnalyze}
          multiple={false}
          value={selectedCollection}
          onChange={handleSelectCollection}
          options={collections}
          getOptionLabel={handleGetOptionLabel}
        />
        <Box mt={4} minHeight={350}>
          <DraggableList
            renderRow={(item) => (
              <AddedAnalyzeToCollectionListItem
                className={clsx({ [classes.newAnalyze]: item.id === analyze?.id })}
                item={item}
              />
            )}
            loading={loadingAnalysis}
            getItemId={(it) => it.id}
            onDragEnd={handleDragEnd}
            items={collectionAnalysis}
            noItemsText={t('pivotGrid.menuSettings.modalCollection.ChooseDescription')!}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          loading={loadingAdd}
          variant="contained"
          disabled={!selectedCollection}
          color="primary"
          label={t('pivotGrid.menuSettings.modalCollection.ButtonAdd')}
          onClick={handleUpdateCollection}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddToCollectionDialog
