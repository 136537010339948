import { LoadingButton } from '@mui/lab'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import PATHS from '../../constants/paths'
import { userService } from '../../services'

type NotVerifiedEmailProps = {
  email: string
  onNotMyEmailClick: () => void
  onGoBackToLoginClick: () => void
}
const NotVerifiedEmail: React.FC<NotVerifiedEmailProps> = ({ email, onNotMyEmailClick, onGoBackToLoginClick }) => {
  const [sended, setSended] = useState(false)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleResendEmail = async () => {
    setLoading(true)
    try {
      await userService.resendEmailConfirmation({ email })
      setSended(true)
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    setLoading(false)
  }
  return (
    <Paper>
      <Stack p={4} spacing={4}>
        {!sended ? (
          <>
            <Typography variant="h2">
              Parece que não conseguimos verificar seu email <br />
            </Typography>
            <Typography variant="h3" style={{ fontWeight: 'normal' }}>
              caso não tenha recebido tente reenviar o email.
            </Typography>

            <LoadingButton loading={loading} variant="text" onClick={() => handleResendEmail()}>
              Reenviar email
            </LoadingButton>
          </>
        ) : (
          <Stack spacing={2}>
            <Typography variant="body1">Enviamos um email para você, verifique sua caixa de entrada.</Typography>
            <Button onClick={onGoBackToLoginClick}>Já verifiquei meu email</Button>
          </Stack>
        )}
        <Typography variant="body2" textAlign="center" fontSize={12}>
          {email} -{' '}
          <Link to={PATHS.SIGNIN} onClick={onNotMyEmailClick}>
            Este não é meu email
          </Link>
        </Typography>
      </Stack>
    </Paper>
  )
}

export default NotVerifiedEmail
