import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { PlugFieldSchema } from '../../../../../../../schemas/PlugSchema'
import googleAnalyticsService, {
  AnalyticsCheckPropertiesCompatibleResponse
} from '../../../../../../../services/google/googleAnalyticsService'
import NavigationHeader, { NavigationHeaderProps } from '../../../NavigationHeader'

export type GoogleProperty = { name: string; type: string }

type GoogleAnalyticsPropertiesListProps = {
  connectionId: number
  property: string
  navigationProps?: Partial<NavigationHeaderProps>
  hideHeader?: boolean
  initialSelected?: { name: string; type: string }[]
  onChange?: (fields: PlugFieldSchema[]) => void
  selection?: PlugFieldSchema[] | null
}

// 'STRING' | 'NUMERIC' | 'DATE' | 'DATETIME' | 'BOOLEAN'

const metricsTypeToPlugFieldType = (metricType: string) => {
  const map = {
    TYPE_FLOAT: 'NUMERIC',
    TYPE_INTEGER: 'NUMERIC',
    TYPE_SECONDS: 'NUMERIC',
    TYPE_CURRENCY: 'NUMERIC',
    TYPE_MILLISECONDS: 'NUMERIC',
    TYPE_STANDARD: 'NUMERIC'
  } as any

  return map[metricType]
}

const GoogleAnalyticsPropertiesList: React.FC<PropsWithChildren<GoogleAnalyticsPropertiesListProps>> = ({
  connectionId,
  property,
  navigationProps,
  hideHeader,
  selection,
  onChange
}) => {
  const [properties, setProperties] = useState<AnalyticsCheckPropertiesCompatibleResponse>()
  const [checkedProperties, setCheckedProperties] = useState<{ name: string; type: string }[]>([
    {
      name: 'dateHour',
      type: 'Dimensions'
    }
  ])
  const { enqueueSnackbar } = useSnackbar()
  const [keysOpen, setKeysOpen] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!!selection) {
      setCheckedProperties(
        selection.map((x) => ({
          ...x,
          type: x.type === 'STRING' ? 'Dimensions' : 'Metrics'
        }))
      )
    }
  }, [selection])

  useEffect(() => {
    setLoading(true)
    const checkedDimension = checkedProperties.filter((x) => x.type === 'Dimensions')
    const checkedMetrics = checkedProperties.filter((x) => x.type !== 'Dimensions')
    googleAnalyticsService
      .checkCompatibleProperties(connectionId, {
        propertyId: property,
        dimensions: checkedDimension,
        metrics: checkedMetrics
      })
      .then((result) => setProperties(result.data))
      .catch((err: AxiosError) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false)
      })
  }, [checkedProperties, connectionId, enqueueSnackbar, property])

  const groupedUser = useMemo(() => {
    return !!properties
      ? [
          ...properties.dimensions.map((x) => ({
            uiName: x.uiName,
            description: x.description,
            category: x.category,
            type: 'string',
            apiName: x.apiName,
            isCompatible: x.isCompatible,
            propertyType: 'Dimensions'
          })),
          ...properties.metrics.map((x) => ({
            uiName: x.uiName,
            description: x.description,
            category: x.category,
            type: x.type,
            apiName: x.apiName,
            isCompatible: x.isCompatible,
            propertyType: 'Metrics'
          }))
        ].groupBy('category')
      : []
  }, [properties])

  const handleToggle = (value: string, type: string) => () => {
    const currentIndex = checkedProperties.findIndex((x) => x.name === value)
    const newChecked = [...checkedProperties]

    if (currentIndex === -1) {
      newChecked.push({ name: value, type: type })
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedProperties(newChecked)
    onChange?.(getCheckedPropertiesToPlugField(newChecked))
  }

  const dimensionsDisabled = checkedProperties.filter((x) => x.type === 'Dimensions').length > 8
  const metricsDisabled = checkedProperties.filter((x) => x.type !== 'Dimensions').length > 9

  const getCheckedPropertiesToPlugField = (checked?: { name: string; type: string }[]) => {
    if (!properties) {
      return []
    }
    const currentChecked = checked ?? checkedProperties
    return [
      ...properties.dimensions
        .filter((x) => currentChecked.map((x) => x.name).includes(x.apiName))
        .map(
          (x) =>
            ({
              label: x.uiName,
              name: x.apiName,
              type: 'STRING',
              isDimension: true
            } as PlugFieldSchema)
        ),
      ...properties.metrics
        .filter((x) => currentChecked.map((x) => x.name).includes(x.apiName))
        .map(
          (x) =>
            ({
              label: x.uiName,
              name: x.apiName,
              type: metricsTypeToPlugFieldType(x.type),
              isDimension: false
            } as PlugFieldSchema)
        )
    ]
  }

  return (
    <>
      {!hideHeader && (
        <NavigationHeader
          onClose={console.log}
          {...navigationProps}
          onBackClick={navigationProps?.onBackClick}
          onNextClick={() => navigationProps?.onNextClick?.(getCheckedPropertiesToPlugField())}
        />
      )}
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {!hideHeader && (
          <>
            <Typography variant="h2" align="center" component="div" color="inherit">
              {t('pagePlugs.modalCreate.configureTitle')}
            </Typography>
            <Typography variant="subtitle1" align="center" component="p" color="inherit">
              {t('pagePlugs.modalCreate.configureSubtitle')}
            </Typography>
          </>
        )}
        <Box width="100%" color="black">
          <List
            sx={{ maxWidth: '80%', bgcolor: 'background.paper', margin: 'auto', minHeight: window.innerHeight * 0.8 }}
            subheader={<ListSubheader component="div" id="nested-list-subheader" />}
          >
            {loading && <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%', zIndex: 999 }} />}
            {groupedUser
              .sortBy((x) => x.key)
              .map((x) => (
                <li key={x.key as any}>
                  <ul style={{ listStyle: 'none' }}>
                    <ListSubheader sx={{ color: 'GrayText', fontSize: '1.5rem', fontWeight: 'bold' }}>
                      <ListItemButton
                        onClick={() => {
                          keysOpen.includes(x.key as any)
                            ? setKeysOpen((prev) => {
                                const keyIndex = prev.findIndex((e) => e === (x.key as any))
                                prev.splice(keyIndex, 1)
                                return [...prev]
                              })
                            : setKeysOpen((prev) => [...prev, x.key as any])
                        }}
                      >
                        {x.key} {keysOpen.includes(x.key as any) ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </ListSubheader>
                    <Collapse in={keysOpen.includes(x.key as any)} timeout={250} unmountOnExit={false}>
                      {x.items.groupBy('propertyType').map((s) => (
                        <li key={x.key as any}>
                          <ul>
                            <ListSubheader sx={{ color: 'GrayText', fontSize: '1.5rem', fontWeight: 'bold' }}>
                              {s.key}
                            </ListSubheader>

                            {s.items.map((r) => (
                              <ListItemButton
                                key={r.apiName}
                                disabled={
                                  r.apiName === 'dateHour' ||
                                  (!(checkedProperties.findIndex((x) => x.name === r.apiName) !== -1) &&
                                    ((r.propertyType === 'Dimensions' && dimensionsDisabled) ||
                                      (r.propertyType === 'Metrics' && metricsDisabled) ||
                                      !r.isCompatible))
                                }
                                onClick={handleToggle(r.apiName, s.key as any)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checkedProperties.findIndex((x) => x.name === r.apiName) !== -1}
                                    disabled={r.apiName === 'dateHour'}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': r.apiName }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={r.uiName} secondary={r.description} />
                              </ListItemButton>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </Collapse>
                  </ul>
                </li>
              ))}
          </List>
        </Box>
      </Box>
    </>
  )
}

export default GoogleAnalyticsPropertiesList
