import React from 'react'

import { CircularProgress } from '@mui/material'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggingStyle,
  NotDraggingStyle
} from 'react-beautiful-dnd'

interface DraggableListProps<T> {
  onDragEnd(result: DropResult, provided: ResponderProvided): void
  items?: T[]
  getItemId?(item: T): string
  renderRow?(item: T): React.ReactNode
  noItemsText?: string
  loading?: boolean
}

const grid = 2

const getItemStyle = (draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none' as const,
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = () => ({
  padding: grid,
  width: '100%'
})

const DraggableList = <T extends { [key: string]: any }>({
  onDragEnd,
  getItemId,
  items,
  renderRow,
  noItemsText,
  loading
}: DraggableListProps<T>): React.ReactElement => {
  return (
    <div style={{ display: 'flex', overflow: 'unset' }}>
      {loading ? (
        <CircularProgress />
      ) : items && items.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                {items.map((item, index) => (
                  <Draggable
                    key={getItemId ? getItemId(item) : index}
                    draggableId={getItemId ? getItemId(item) : item.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(provided.draggableProps.style)}
                      >
                        {renderRow ? renderRow(item) : item.name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>{noItemsText || 'No items'}</div>
      )}
    </div>
  )
}

export default DraggableList
