export function format(string: string, ...params: (string | number | undefined)[]): string {
  return string
    .replace(/{(\d+)}/g, function (match, number) {
      return params[number] !== undefined ? params[number]?.toString() || '' : ''
    })
    .replace('//', '/')
}

export const normalyzeAndLowering = (text: string = ''): string => {
  return (text || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export const transformePascalCase = (text: string): string => {
  const splitedWords = text.split(' ')
  return splitedWords.reduce((acc, cur) => {
    return acc + ' ' + cur.charAt(0).toLocaleUpperCase() + cur.slice(1).toLocaleLowerCase()
  }, '')
}

export const formatToPlugFieldLabel = (text: string): string => {
  const splitedWords = text.replaceAll('_', ' ').split(' ')
  return splitedWords.reduce((acc, cur) => {
    return acc + ' ' + cur.charAt(0).toLocaleUpperCase() + cur.slice(1).toLocaleLowerCase()
  }, '')
}
