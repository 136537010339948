import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Swipeable from 'react-swipeable-views'
import { PlugFieldSchema, PlugSchema } from '../../../schemas/PlugSchema'
import PlugIcon from '../../PlugIcon'
import { SelectedPlugField } from './ListContainer'

type PlugFieldListItemProps = {
  plug: PlugSchema & { fields: PlugFieldSchema[] }
  checkedFields: SelectedPlugField[]
  plugChecked: boolean
  onPlugCheckChanges: (plugId: number, checked: boolean) => void
  onFieldCheckChanges: (plugId: number, fieldName: string, checked: boolean) => void
}

const PlugFieldListItem: React.FC<PlugFieldListItemProps> = ({
  plug,
  checkedFields,
  plugChecked,
  onFieldCheckChanges,
  onPlugCheckChanges
}) => {
  const [expansed, setExpansed] = useState(false)
  const { t } = useTranslation()
  const swipeableContext = useContext((Swipeable as any).SwipeableViewsContext) as any

  return (
    <>
      <ListItemButton disableRipple>
        <ListItemIcon>
          <Tooltip title={t('profileDialog.hint.denyPlug')}>
            <Checkbox
              edge="start"
              checked={plugChecked}
              indeterminate={!plugChecked && checkedFields.some((x) => x.plugId === plug.id)}
              tabIndex={-1}
              inputProps={{ 'aria-labelledby': plug.name }}
              onClick={() => onPlugCheckChanges(plug.id, !plugChecked)}
            />
          </Tooltip>
          <PlugIcon type={plug.type} style={{ marginRight: 10 }} />
        </ListItemIcon>
        <ListItemText
          primary={plug.name}
          secondary={
            <Typography variant="body2">
              {checkedFields
                .filter((x) => x.plugId === plug.id)
                .map((x) => x.plugFieldName)
                .join(', ')}
            </Typography>
          }
        />
        {expansed ? (
          <ExpandLess onClick={() => setExpansed(false)} />
        ) : (
          <ExpandMore onClick={() => setExpansed(true)} />
        )}
      </ListItemButton>
      <Collapse in={expansed} timeout="auto" onTransitionEnd={() => swipeableContext.slideUpdateHeight()}>
        <List component="div" disablePadding>
          {plug.fields.map((field) => (
            <ListItemButton
              key={`${plug.id}-${field.name}`}
              sx={{ pl: 4 }}
              disabled={plugChecked}
              onClick={() =>
                onFieldCheckChanges(
                  plug.id,
                  field.name,
                  !(checkedFields.findIndex((x) => x.plugId === plug.id && x.plugFieldName === field.name) !== -1)
                )
              }
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    checkedFields.findIndex((x) => x.plugId === plug.id && x.plugFieldName === field.name) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': field.label }}
                />
              </ListItemIcon>
              <ListItemText primary={field.label} secondary={field.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default PlugFieldListItem
