import React, { ReactNode } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { ContentBlock, ContentState, EditorBlock } from 'draft-js'

const useStyles = makeStyles({
  line: {
    position: 'relative',
    '&::before': {
      content: 'attr(data-line-number)',
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0.5
    }
  },
  lineText: {
    marginLeft: '2em'
  }
})

const LineNumberBlock = (props: { block: ContentBlock; contentState: ContentState }): ReactNode => {
  const { block, contentState } = props
  const classes = useStyles()
  const lineNumber =
    contentState
      .getBlockMap()
      .toList()
      .findIndex((item) => item?.getKey() === block.getKey()) + 1
  return (
    <div className={classes.line} data-line-number={lineNumber}>
      <div className={classes.lineText}>
        <EditorBlock {...props} />
      </div>
    </div>
  )
}

export default LineNumberBlock
