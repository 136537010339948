import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import billingService from '../../../services/billingService'
import useAuth from '../../useAuth'
import { useSnackbar } from 'notistack'

export const PaymentCanceledModal: React.FC<{ open: boolean }> = ({ open }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const { getAccountInfo } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const accountInfo = getAccountInfo()

  const [loading, setLoading] = useState(false)
  const handleRedirectToCheckoutRenewSubscription = async () => {
    setLoading(true)
    try {
      const { data } = await billingService.createCheckoutSession(accountInfo!.planId)
      window.location.href = data.url
    } catch (err) {
      // enqueueSnackbar(t('paymentProblemDialog.error'), { variant: 'error' })
      setLoading(false)
    }
  }

  return (
    <Dialog maxWidth="md" fullScreen={fullScreen} fullWidth open={open}>
      <DialogTitle>
        <Stack spacing={2} direction="row" alignItems="center">
          <img
            style={{
              width: 36,
              height: 'auto'
            }}
            src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/logo_simbolologo_simbolo.png"
            alt="Plugger BI logo"
          />
          <span>{t('paymentCanceledDialog.title')}</span>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <Typography variant="h6">{t('paymentCanceledDialog.subtitle')}</Typography>
          <Button
            sx={{ width: 320, marginX: 'auto!important', color: '#f6f6f6' }}
            fullWidth={false}
            variant="contained"
            color="success"
            onClick={handleRedirectToCheckoutRenewSubscription}
            endIcon={loading && <CircularProgress size={20} />}
            disabled={loading}
          >
            {t('paymentCanceledDialog.btn.renewSubscription')}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Link href="https://pluggerbi.com">{t('trialExpiredDialog.exit')}</Link>
      </DialogActions>
    </Dialog>
  )
}
