export enum DatabaseConnectionType {
  'MySql' = 'MySql',
  'MsSql' = 'MsSql',
  'Firebird' = 'Firebird',
  'Redshift' = 'Redshift'
}
export enum ApiConnectionType {
  'Movidesk' = 'Movidesk',
  'GoogleApis' = 'GoogleApis',
  'Facebook' = 'Facebook',
  'CsvFile' = 'CsvFile'
}

export const IsDatabaseConnectionSchema = (conn: ConnectionSchema): conn is DatabaseConnectionSchema => {
  return conn && typeof conn === 'object' && 'type' in conn && Object.keys(DatabaseConnectionType).includes(conn.type)
}

export interface BaseConnectionSchema {
  id: number
  name: string
  image: string
}

export interface DatabaseConnectionSchema extends BaseConnectionSchema {
  type: DatabaseConnectionType
  host: string
  database: string
  port?: number
  username: string
  password: string
}

export interface ApiConnectionSchema extends BaseConnectionSchema {
  type: ApiConnectionType
  apiSettings: any
}

export type ConnectionSchema = DatabaseConnectionSchema | ApiConnectionSchema
export type CreateConnectionSchema = Omit<ConnectionSchema, 'id' | 'image'>
export type UpdateConnectionSchema = Omit<ConnectionSchema, 'type'>
