import { ChangeSet } from '@devexpress/dx-react-grid'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../components/Table'
import LowerCaseStringTypeProvider from '../../components/Table/LowerCaseStringTypeProvider'
import { PlugFieldSchema } from '../../schemas/PlugSchema'

export type PlugFieldsGridProps = {
  loading?: boolean
  plugFields: PlugFieldSchema[]
  onCommitChanges: (changes: ChangeSet) => void
}

const PlugFieldsGrid: React.FC<PropsWithChildren<PlugFieldsGridProps>> = ({ loading, plugFields, onCommitChanges }) => {
  const tableHeight = plugFields.length * 54 + 60

  const { t } = useTranslation()
  return (
    <Table
      height={`${tableHeight}px`}
      selectionDisabled
      loading={loading}
      sorting={{
        defaultState: [
          {
            columnName: 'name',
            direction: 'asc'
          }
        ]
      }}
      getRowId={(r) => r.name}
      rows={plugFields}
      editingProps={{
        onCommitChanges: onCommitChanges,
        columnExtensions: [
          {
            columnName: 'name',
            editingEnabled: false
          },
          {
            columnName: 'type',
            editingEnabled: false
          },
          {
            columnName: 'status',
            editingEnabled: false
          }
        ]
      }}
      columns={[
        {
          name: 'name',
          title: t('pagePlugs.modalCreate.definitionsField')!,
          canEdit: false
        },
        {
          name: 'label',
          title: t('pagePlugs.modalCreate.definitionsField.friendly')!
        },
        {
          name: 'type',
          title: t('pagePlugs.modalCreate.definitionsField.data')!,
          canEdit: false
        }
      ]}
    >
      <LowerCaseStringTypeProvider for={['type']} />
    </Table>
  )
}

export default PlugFieldsGrid
