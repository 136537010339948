import { Box, CircularProgress, DialogActions } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogContent } from '../../components/Dialog'

interface PlugErrorRetryDialogProps {
  onClose?(): void
  open: boolean
  description: string
  onConfirm: () => void
  showLoading?: boolean
}

const useStyles = makeStyles((theme) => ({
  swipableViews: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 740
    }
  },
  errorFilterContainer: {
    color: theme.palette.error.main
  },
  doublePdTop: {
    paddingTop: theme.spacing(4)
  },
  plugInfoItem: {
    display: 'inline-flex',
    width: '100%',
    padding: theme.spacing(2, 0)
  },
  content: {
    height: '100%'
  }
}))

const PlugErrorRetryDialog: React.FC<PropsWithChildren<PlugErrorRetryDialogProps>> = ({
  open,
  onClose,
  description,
  showLoading,
  onConfirm
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Dialog
      minWidth={520}
      open={open}
      title={t('pagePlugs.modalErrorRetry.title')}
      description={t('pagePlugs.modalErrorRetry.subtitle')}
      onClose={onClose}
    >
      <DialogContent dividers className={classes.content}>
        <strong>{description}</strong>
      </DialogContent>

      <DialogActions sx={{ display: 'block' }}>
        <Box width="100%" display="block">
          {t('pagePlugs.modalErrorRetry.warningMsg')}
        </Box>
        <Button
          sx={{ position: 'relative', right: 20, left: 'calc(100% - 120px)' }}
          onClick={onConfirm}
          label={t('pagePlugs.modalErrorRetry.btnConfirm')}
          disabled={showLoading}
          endIcon={showLoading && <CircularProgress size="small" />}
        />
      </DialogActions>
    </Dialog>
  )
}

export default PlugErrorRetryDialog
