import { Box, BoxProps, TextFieldProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import CardSelectable, { CardSelectableProps } from '../../components/CardSelectable'
import SearchBox from '../../components/SearchBox'

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    display: 'grid',
    gap: (props: { gap?: string }) => (props.gap ? props.gap : theme.spacing(2)),
    padding: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center'
  },
  dynamicSize: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 320px))'
  },
  fixSize: {
    gridTemplateColumns: 'repeat(auto-fit, 170px)'
  }
}))

export type GridSelectableItemProps = Omit<CardSelectableProps, 'selected' | 'fallbackImage'>

interface GridSelectableProps<ValueType> extends BoxProps {
  searchEnabled?: boolean
  itemKey?: keyof ValueType
  items?: ValueType[]
  selectedItem?: ValueType
  fallbackImage?: string
  disableDynamicSize?: boolean
  CardComponent?: React.FunctionComponent<GridSelectableItemProps> | React.ComponentClass<GridSelectableItemProps>
  classes?: {
    searchContainer?: string
  }
  searchProps?: TextFieldProps
  gap?: string
  onChangeSelection?(newValue: ValueType): void
  onCardClick?: (value: ValueType) => void
  disableCredentials?: boolean
}

const GridSelectable = <T extends GridSelectableItemProps>({
  selectedItem,
  items,
  onChangeSelection,
  searchEnabled = true,
  itemKey,
  fallbackImage,
  disableDynamicSize,
  classes,
  searchProps,
  gap,
  CardComponent,
  className,
  onCardClick,
  disableCredentials,
  ...others
}: GridSelectableProps<T>): React.ReactElement => {
  const classesBase = useStyles({ gap })

  const handleSelect = (value: any) => {
    onChangeSelection?.(value)
  }

  const [searchValue, setSearchValue] = useState('')

  const filterBySearchValue = () => {
    if (!searchValue) return items

    return items?.filter((x) => (x as any).displayName?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
  }

  return (
    <Box {...others}>
      {searchEnabled && (
        <Box className={clsx(classesBase.searchContainer, classes?.searchContainer)}>
          <SearchBox {...searchProps} onChange={(val) => setSearchValue(val.target.value)} />
        </Box>
      )}
      <Box
        className={clsx(
          classesBase.container,
          {
            [classesBase.fixSize]: disableDynamicSize,
            [classesBase.dynamicSize]: !disableDynamicSize
          },
          className
        )}
      >
        {filterBySearchValue()?.map((item) =>
          CardComponent ? (
            <CardComponent
              fallbackImage={fallbackImage}
              onClick={() => (!!onCardClick ? onCardClick(item) : handleSelect(item))}
              selected={itemKey && item[itemKey] === selectedItem?.[itemKey]}
              disableCredentials={disableCredentials}
              {...item}
            />
          ) : (
            <CardSelectable
              disableCredentials={disableCredentials}
              fallbackImage={fallbackImage}
              onClick={() => handleSelect(item)}
              selected={itemKey && item[itemKey] === selectedItem?.[itemKey]}
              title={(item as any).displayName}
              {...item}
            />
          )
        )}
      </Box>
    </Box>
  )
}

export default GridSelectable
