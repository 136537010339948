import { AxiosResponse } from 'axios'
import { format } from '../../utils/stringUtils'
import api from '../api'

export type Property = {
  name: string
}

export type AnalyticsCheckPropertiesCompatible = {
  dimensions: Property[]
  metrics: Property[]
  propertyId: string
}

export type PropertySummary = {
  displayName: string
  property: string
}

export type AccountSummary = {
  displayName: string
  account: string
  propertySummaries: PropertySummary[]
}

export type Compatibility = 'COMPATIBLE' | 'INCOMPATIBLE'

export type AnalyticsCheckPropertiesCompatibleResponse = {
  dimensions: {
    apiName: string
    category: string
    description: string
    uiName: string
    isCompatible: boolean
  }[]
  metrics: {
    apiName: string
    category: string
    description: string
    uiName: string
    type: string
    isCompatible: boolean
  }[]
}

export type AnalyticsAccount = {
  id: string
  name: string
}

export type AnalyticsAccountProperty = {
  id: string
  name: string
}

export type PropertyMetadataItem = {
  apiName: string
  category: string
  description: string
  uiName: string
  type: string | null
}

export type PropertyMetadata = {
  name: string
  Dimensions: PropertyMetadataItem[]
  Metrics: PropertyMetadataItem[]
}

function listAccountSummaries(connectionId: number): Promise<AxiosResponse<AnalyticsAccount[]>> {
  return api.get(format('/google-apis/{0}/google-analytics/accounts', connectionId))
}
function listAccountProperties(
  connectionId: number,
  analyticsAccountId: string
): Promise<AxiosResponse<AnalyticsAccountProperty[]>> {
  return api.get(format('/google-apis/{0}/google-analytics/accounts/{1}/properties', connectionId, analyticsAccountId))
}
function getPropertyMetadata(
  connectionId: number,
  analyticsAccountId: string,
  propertyId: string
): Promise<AxiosResponse<PropertyMetadata>> {
  return api.get(
    format(
      '/google-apis/{0}/google-analytics/accounts/{1}/properties/{2}/metadata',
      connectionId,
      analyticsAccountId,
      propertyId
    )
  )
}

function checkCompatibleProperties(
  connectionId: number,
  propertyCheck: AnalyticsCheckPropertiesCompatible
): Promise<AxiosResponse<AnalyticsCheckPropertiesCompatibleResponse>> {
  return api.post(format('/google-apis/{0}/google-analytics/check-compatibility', connectionId), propertyCheck)
}

const serviceClient = {
  listAccountSummaries,
  checkCompatibleProperties,
  listAccountProperties,
  getPropertyMetadata
}

export default serviceClient
