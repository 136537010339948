import { DialogActions, DialogContent, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog } from '../../components/Dialog'

type AutoRotationDialogProps = {
  value: number
  open: boolean
  onClose: () => void
  onApply: (value: number) => void
}

const AutoRotationDialog: React.FC<AutoRotationDialogProps> = ({ open, onClose, value, onApply }) => {
  const [tempValue, setTempValue] = useState(value)
  const { t } = useTranslation()
  return (
    <Dialog
      title={t('collection.autoRotationDialog.title')}
      description={t('collection.autoRotationDialog.subtitle')}
      open={open}
      PaperProps={{ style: { display: 'inline-flex', flexDirection: 'column', overflow: 'unset' } }}
      onClose={() => onClose()}
    >
      <DialogContent dividers>
        <TextField
          fullWidth
          required
          type="number"
          name="autoRotationInterval"
          label={t('collection.autoRotationDialog.intervalLabel')}
          value={tempValue}
          onChange={(e) => setTempValue(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          label={t('collection.autoRotationDialog.closeBtn')}
          isDelete
          onClick={() => onClose()}
        />

        <Button
          variant="contained"
          color="primary"
          label={t('collection.autoRotationDialog.applyBtn')}
          onClick={() => onApply(tempValue * 1000)}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AutoRotationDialog
