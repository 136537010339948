import { Dialog as MuiDialog, DialogProps as MuiDialogProps, DialogTitle, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import DialogTitleContent from '../../components/DialogTitleContent'

export interface DialogPropsWithTitle extends MuiDialogProps {
  description: string
  title: string
  removeTitle?: false
}

export interface DialogPropsWithoutTitle extends MuiDialogProps {
  description?: string
  title?: string
  removeTitle: true
}

interface CommonDialogProps {
  minWidth?: string | number
}

export type DialogProps = (DialogPropsWithTitle | DialogPropsWithoutTitle) & CommonDialogProps

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: (props: { minWidth: number | string | undefined }) => props.minWidth
  }
}))

const Dialog: React.FC<PropsWithChildren<DialogProps>> = ({
  description,
  title,
  onClose,
  children,
  removeTitle,
  minWidth,
  ...others
}) => {
  const classes = useStyles({ minWidth })
  const fullScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  return (
    <MuiDialog
      fullScreen={fullScreen}
      classes={{ paper: clsx({ [classes.paper]: !fullScreen }) }}
      maxWidth="md"
      onClose={onClose}
      {...others}
    >
      {!removeTitle && (
        <DialogTitle>
          <DialogTitleContent title={title} description={description} onClose={onClose} />
        </DialogTitle>
      )}
      {children}
    </MuiDialog>
  )
}

export default Dialog
