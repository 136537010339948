import { ENDPOINTS } from '../../../constants/apiEndpoints'
import i18n from '../../../locales/i18next'
import { ValueOf } from '../../../utils/typeUtils'
import { ComponentExtension, PivotGridComponent, PivotGridField } from '../types'
import { summaryTypeOptions } from './AggregateExtension'

class NumberFormatExtension implements ComponentExtension {
  name = 'FormatNumberExtension'
  icon = 'detailslayout'
  text = i18n.t('pivotGrid.extensions.numberFormat.text')
  items: { text: string; items: { text: string; onItemClick: () => void; selected: boolean; disabled: boolean }[] }[]

  constructor(component: PivotGridComponent, field: PivotGridField) {
    const subOptions = [
      {
        text: i18n.t('pivotGrid.extensions.numberFormat.type'),
        icon: ENDPOINTS.PUBLIC_IMAGES + '/number-type.svg',
        items: [
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.general'),
            onItemClick: () => convertToGeneral(field, component),
            selected: field.format === CustomFormats.GENERAL,
            disabled: field.format === CustomFormats.GENERAL
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.numeric'),
            onItemClick: () => applyFormat(field, formats.NUMERIC, component),
            selected: (field.format as { type: string })?.type === formats.NUMERIC.type,
            disabled: (field.format as { type: string })?.type === formats.NUMERIC.type
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.currency'),
            onItemClick: () => applyFormat(field, formats.CURRENCY, component),
            selected: (field.format as { type: string })?.type === formats.CURRENCY.type,
            disabled: (field.format as { type: string })?.type === formats.CURRENCY.type
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.percent'),
            onItemClick: () => applyFormat(field, formats.PERCENT, component),
            selected: (field.format as { type: string })?.type === formats.PERCENT.type,
            disabled: (field.format as { type: string })?.type === formats.PERCENT.type
          }
        ]
      },
      {
        text: i18n.t('pivotGrid.extensions.numberFormat.unit'),
        icon: ENDPOINTS.PUBLIC_IMAGES + '/number_unit.svg',
        items: [
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.ones'),
            onItemClick: () => applyFormat(field, formats.ONES, component),
            selected: (field.format as { type: string })?.type === formats.ONES.type,
            disabled: (field.format as { type: string })?.type === formats.ONES.type
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.thousands'),
            onItemClick: () => applyFormat(field, formats.THOUSANDS, component),
            selected: (field.format as { type: string })?.type === formats.THOUSANDS.type,
            disabled: (field.format as { type: string })?.type === formats.THOUSANDS.type
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.millions'),
            onItemClick: () => applyFormat(field, formats.MILLIONS, component),
            selected: (field.format as { type: string })?.type === formats.MILLIONS.type,
            disabled: (field.format as { type: string })?.type === formats.MILLIONS.type
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.billions'),
            onItemClick: () => applyFormat(field, formats.BILLIONS, component),
            selected: (field.format as { type: string })?.type === formats.BILLIONS.type,
            disabled: (field.format as { type: string })?.type === formats.BILLIONS.type
          }
        ]
      },
      {
        text: i18n.t('pivotGrid.extensions.numberFormat.precision'),
        icon: ENDPOINTS.PUBLIC_IMAGES + '/decimal.svg',
        items: [
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.none'),
            onItemClick: () => applyPrecision(field, precisions.NONE, component),
            selected: (field.format as { precision: number })?.precision === precisions.NONE,
            disabled: (field.format as { precision: number })?.precision === precisions.NONE
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.one'),
            onItemClick: () => applyPrecision(field, precisions.ONE, component),
            selected: (field.format as { precision: number })?.precision === precisions.ONE,
            disabled: (field.format as { precision: number })?.precision === precisions.ONE
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.two'),
            onItemClick: () => applyPrecision(field, precisions.TWO, component),
            selected: (field.format as { precision: number })?.precision === precisions.TWO,
            disabled: (field.format as { precision: number })?.precision === precisions.TWO
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.three'),
            onItemClick: () => applyPrecision(field, precisions.THREE, component),
            selected: (field.format as { precision: number })?.precision === precisions.THREE,
            disabled: (field.format as { precision: number })?.precision === precisions.THREE
          },
          {
            text: i18n.t('pivotGrid.extensions.numberFormat.four'),
            onItemClick: () => applyPrecision(field, precisions.FOUR, component),
            selected: (field.format as { precision: number })?.precision === precisions.FOUR,
            disabled: (field.format as { precision: number })?.precision === precisions.FOUR
          }
        ]
      }
    ]

    this.items = subOptions
  }
}

type Formats = ValueOf<typeof formats>

const convertToGeneral = (field: PivotGridField, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  delete field.format
  field.customFormat = CustomFormats.GENERAL
  dataSource.field(field.dataField!, {
    format: field.format,
    summaryType: summaryTypeOptions.COUNT_DISTINCT,
    dataType: 'string'
  })
  const getFieldChooserPopup = component.getFieldChooserPopup()
  if (!getFieldChooserPopup.option('visible')) {
    dataSource.load()
  }
}
const applyFormat = (field: PivotGridField, format: Formats, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  const fieldChanges = {} as any
  if (field.format === CustomFormats.GENERAL) {
    fieldChanges.summaryType = summaryTypeOptions.SUM
    fieldChanges.dataType = 'number'
  }
  typeof field.format === 'object' ? (field.format = { ...field.format, type: format.type }) : (field.format = format)
  dataSource.field(field.dataField!, { format: field.format, ...fieldChanges })
  const getFieldChooserPopup = component.getFieldChooserPopup()
  if (!getFieldChooserPopup.option('visible')) {
    dataSource.load()
  }
}

const applyPrecision = (field: PivotGridField, precision: number, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  typeof field.format === 'object'
    ? (field.format = { ...field.format, precision: precision })
    : (field.format = { precision })
  dataSource.field(field.dataField!, { format: field.format })
  const getFieldChooserPopup = component.getFieldChooserPopup()
  if (!getFieldChooserPopup.option('visible')) {
    dataSource.load()
  }
}

export const CustomFormats = {
  GENERAL: 'general'
}

const formats = {
  NUMERIC: {
    type: 'fixedPoint' as const
  },
  ONES: {
    type: 'fixedPoint' as const
  },
  THOUSANDS: {
    type: 'thousands' as const
  },
  MILLIONS: {
    type: 'millions' as const
  },
  BILLIONS: {
    type: 'billions' as const
  },
  CURRENCY: {
    type: 'currency' as const
  },
  PERCENT: {
    type: 'percent' as const
  }
}

const precisions = {
  NONE: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4
}

export default NumberFormatExtension
