import Axios, { AxiosResponse } from 'axios'
import { UserParameters } from '../../components/forms/UserForm'
import { BASE_URL, ENDPOINTS } from '../../constants/apiEndpoints'
import { UserParameterSchema } from '../../schemas/ParameterSchema'
import { ProfileSchema } from '../../schemas/ProfileSchema'
import { TeamSchema } from '../../schemas/TeamSchema'
import { InviteUser, UserSchema } from '../../schemas/UserSchema'
import base64Utils from '../../utils/base64Utils'
import imageUtils from '../../utils/imageUtils'
import { format } from '../../utils/stringUtils'
import api from '../api'

type CreateUserRequest = {
  email: string
  password: string
}

const getAll = (): Promise<AxiosResponse<UserSchema[]>> => {
  return api.get(ENDPOINTS.USERS_GET_ALL)
}

const get = (id: string): Promise<AxiosResponse<UserSchema>> => {
  return api.get(format(ENDPOINTS.USERS_GET, id))
}

const getByEmail = (email: string): Promise<AxiosResponse<UserSchema>> => {
  return api.get(format(ENDPOINTS.USERS_GET, email))
}

const reactivate = (id: string): Promise<AxiosResponse> => {
  return api.patch(format(ENDPOINTS.USERS_REACTIVATE, id))
}

const getParameters = (id: string): Promise<AxiosResponse<UserParameterSchema[]>> => {
  return api.get(format(ENDPOINTS.USERS_GET_PARAMETERS, id))
}

const updateParameters = (id: string, parameters?: UserParameters[]): Promise<AxiosResponse<UserParameterSchema[]>> => {
  return api.put(format(ENDPOINTS.USERS_GET_PARAMETERS, id), parameters)
}

const getCurrentInfo = (): Promise<AxiosResponse<UserSchema>> => {
  return api.get(ENDPOINTS.USERS_GET_CURRENT_INFO)
}

export type UserInfo = {
  id: string
  email: string
  fullName: string
  firstName: string
  lastName: string
  locale?: string
  thumbImagePath?: string
  profile: ProfileSchema | null
  team: TeamSchema | null
}

const getCurrentInfoV2 = (): Promise<AxiosResponse<UserInfo>> => {
  return api.get('/users/info/v2')
}

const invite = (invitedUser: InviteUser): Promise<AxiosResponse<UserSchema>> => {
  return api.post(ENDPOINTS.USERS_INVITE, invitedUser)
}

const resendInvite = (userId: string): Promise<AxiosResponse<never>> => {
  return api.post(format(ENDPOINTS.USERS_RESEND_INVITE, userId))
}

type AuthResp = {
  token: string
  expiration: Date
}

const confirmInvite = (token: string): Promise<AxiosResponse<AuthResp>> => {
  return api.post(BASE_URL + ENDPOINTS.USERS_INVITE_CONFIRM, { token })
}

type UpdateUserReq = {
  firstName: string
  lastName: string
  teamId?: string | null
  profileId: number | null
  locale?: string | null
}

const update = async (userId: string, updateUserReq: UpdateUserReq): Promise<AxiosResponse> => {
  return api.put(BASE_URL + format(ENDPOINTS.USERS_UPDATE, userId), updateUserReq)
}

const updatePicture = async (userId: string, updatePictureReq: { picture?: string }): Promise<AxiosResponse> => {
  const formData = new FormData()

  if (!updatePictureReq.picture) {
    formData.append('picture', '')
  } else if (!base64Utils.isValidBase64(updatePictureReq.picture)) {
    formData.append('picture', await imageUtils.loadFromUrl(updatePictureReq.picture))
  } else {
    formData.append('picture', base64Utils.convertDataURIToBinary(updatePictureReq.picture))
  }

  const client = Axios.create({
    baseURL: BASE_URL
  })
  client.defaults.transformRequest = []
  client.defaults.headers.common = {
    'Content-Type': 'multipart/form-data'
  }
  return client.patch(BASE_URL + format(ENDPOINTS.USERS_UPDATE, userId), formData, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  })
}

const updatePassword = (userId: string, updatePasswordReq: { password: string }): Promise<AxiosResponse> => {
  return api.patch(format(ENDPOINTS.USERS_UPDATE + '/password', userId), updatePasswordReq)
}

const _delete = (userId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.USERS_DELETE, userId))
}

const removeAnalyze = (analyzeId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.USERS_ME_ANALYSIS_DELETE, analyzeId))
}

const removeCollection = (collectionId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.USERS_ME_COLLECTIONS_DELETE, collectionId))
}

const createUser = (createUserReq: CreateUserRequest): Promise<AxiosResponse<UserSchema>> => {
  return api.post(ENDPOINTS.USERS_GET_ALL, createUserReq)
}

const resendEmailConfirmation = (resendEmailConfimationReq: { email: string }): Promise<AxiosResponse<void>> => {
  return api.post('/users/resend-email-confirmation', resendEmailConfimationReq)
}

const confirmEmail = (confirmEmailReq: { token: string }): Promise<AxiosResponse<void>> => {
  return api.post('/users/confirm-email', confirmEmailReq)
}

export type ExternalLogin = {
  loginProvider: string
  providerKey: string
  providerDisplayName: string
  userId: string
  providerEmail: string
}

const listLogins = (): Promise<AxiosResponse<ExternalLogin[]>> => {
  return api.get('/users/me/external-logins')
}

type CreateAccountReq = {
  companyName: string
  userRole: string
  employeeCount: string
  planId: number
}

type CreateAccountRes = {
  accountId: number
}

const createAccount = (
  userId: string,
  createAccountReq: CreateAccountReq
): Promise<AxiosResponse<CreateAccountRes>> => {
  return api.post(format('/users/{0}/accounts', userId), createAccountReq)
}

const deleteAccount = (accountId: number): Promise<AxiosResponse> => {
  return api.delete(format('/users/me/accounts/{0}', accountId))
}

type AddExternalLoginReq = {
  token: string
  provider: 'Google' | 'Facebook'
}
type AddExternalLoginResp = {
  externalLoginId: string
  firstName: string
  lastName: string
}
const addExternalLogin = (req: AddExternalLoginReq): Promise<AxiosResponse<AddExternalLoginResp>> => {
  return api.post(ENDPOINTS.USERS_ADD_EXTERNAL_LOGIN, req)
}
type DeleteExternalLoginReq = {
  loginProvider: string
  providerKey: string
}
const deleteExternalLogin = (req: DeleteExternalLoginReq): Promise<AxiosResponse> => {
  return api.delete(ENDPOINTS.USERS_ADD_EXTERNAL_LOGIN + '/' + req.loginProvider + '/' + req.providerKey)
}

export default {
  createUser,
  getAll,
  removeCollection,
  get,
  invite,
  update,
  _delete,
  updatePassword,
  getCurrentInfo,
  listLogins,
  deleteAccount,
  confirmInvite,
  updatePicture,
  resendInvite,
  getParameters,
  updateParameters,
  removeAnalyze,
  reactivate,
  getByEmail,
  resendEmailConfirmation,
  confirmEmail,
  createAccount,
  addExternalLogin,
  deleteExternalLogin,
  getCurrentInfoV2
}
