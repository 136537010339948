import { AxiosResponse } from 'axios'
import React, { PropsWithChildren, useState } from 'react'
import { KeyedMutator } from 'swr'
import { ResponseAccountUsedCapabilitiesSchema } from '../../schemas/AccountSchema'
import { PlanSchema } from '../../schemas/PlanSchema'
import { useAccountInfo } from '../useAccountInfo/useAccountInfo'
import { usePaymentInfo } from '../usePaymentInfo/usePaymentInfo'
import { usePlanInfo } from '../usePlanInfo/usePlanInfo'
import { PaymentPresenter } from './components/PaymentPresenter'
import { Payment, PaymentStatus } from './payment'

export type PaymentContextValues = {
  paymentInfo: {
    data: Payment | undefined
    isLoading: boolean
    isError: boolean
    error: any
    revalidate: KeyedMutator<AxiosResponse<Payment, any>>
  }
  planInfo: {
    data: PlanSchema | undefined
    isLoading: boolean
    isError: boolean
    error: any
    revalidate: KeyedMutator<AxiosResponse<PlanSchema, any>>
  }
  accountInfo: {
    data: ResponseAccountUsedCapabilitiesSchema | undefined
    isLoading: boolean
    isError: boolean
    error: any
    revalidate: KeyedMutator<AxiosResponse<ResponseAccountUsedCapabilitiesSchema, any>>
  }
  showPresenter: boolean
  setShowPresenter: React.Dispatch<React.SetStateAction<boolean>>
  presenterStatus: PaymentStatus
  setPresenterStatus: React.Dispatch<React.SetStateAction<PaymentStatus>>
}

export const PaymentContext = React.createContext<PaymentContextValues | null>(null)

export const PaymentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const paymentInfo = usePaymentInfo()
  const planInfo = usePlanInfo()
  const accountInfo = useAccountInfo()

  const [showPresenter, setShowPresenter] = useState(false)
  const [presenterStatus, setPresenterStatus] = useState<PaymentStatus>('CANCELED')

  const contextValue = {
    paymentInfo,
    planInfo,
    accountInfo,
    showPresenter,
    setShowPresenter,
    presenterStatus,
    setPresenterStatus
  }

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
      <PaymentPresenter show={showPresenter} status={presenterStatus} />
    </PaymentContext.Provider>
  )
}
