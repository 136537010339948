import { Add } from '@mui/icons-material'
import ProfileIcon from '@mui/icons-material/AssignmentInd'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ContentBox from '../../components/ContentBox'
import Header from '../../components/Header'
import List from '../../components/List'
import Loading from '../../components/Loading'
import ProfileDialog from '../../components/ProfileDialog'
import RootBox from '../../components/RootBox'
import { ProfileSchema } from '../../schemas/ProfileSchema'
import profileService from '../../services/profileService'
import { normalyzeAndLowering } from '../../utils/stringUtils'

interface DialogStateOptions {
  open: boolean
  title: string
  description: string
  itemData?: ProfileSchema | undefined
  buttonLabel: string
}

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const ProfilesPage: React.FC = () => {
  const [profiles, setProfiles] = useState<ProfileSchema[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [dialogOptions, setDialogOptions] = useState<DialogStateOptions>({
    open: false,
    title: t('pageProfiles.modalCreate.title'),
    description: t('pageProfiles.modalCreate.subtitle'),
    itemData: undefined,
    buttonLabel: t('pageProfiles.modalCreate.buttonSave')
  })

  const classes = useStyles()

  const [searchValue, setSearchValue] = useState('')

  const getFilteredProfiles = () => {
    if (!searchValue) {
      return profiles.sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
    }
    return profiles
      .filter((p) => normalyzeAndLowering(p.name).includes(normalyzeAndLowering(searchValue)))
      .sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
  }

  const reloadProfiles = async () => {
    try {
      const response = await profileService.get()
      setProfiles(response.data)
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const response = await profileService.get()
        setProfiles(response.data)
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, t])

  const handleToggleDialog = ({ itemData = undefined, ...rest }: DialogStateOptions) => {
    setDialogOptions({ itemData, ...rest })
  }

  const handleEdit = (profile: ProfileSchema) => {
    handleToggleDialog({
      open: true,
      title: t('pageProfiles.modalEdit.title'),
      description: t('pageProfiles.modalEdit.subtitle'),
      itemData: profile,
      buttonLabel: t('pageProfiles.modalCreate.buttonUpdate')
    })
  }

  const handleCloseDialog = (refresh: boolean) => {
    setDialogOptions((prev) => ({ ...prev, itemData: undefined, open: false }))
    if (refresh) reloadProfiles()
  }

  return (
    <RootBox>
      <Header
        icon={ProfileIcon}
        title={t('pageProfiles.Title')}
        subtitle={t('pageProfiles.Subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        <Stack
          alignItems="flex-end"
          paddingRight={4}
          sx={(theme) => ({
            bgcolor: theme.palette.background.light.default
          })}
        >
          <Button
            sx={{
              height: 40
            }}
            onClick={() =>
              handleToggleDialog({
                open: true,
                title: t('pageProfiles.modalCreate.title'),
                description: t('pageProfiles.modalCreate.subtitle'),
                buttonLabel: t('Create')
              })
            }
            startIcon={<Add />}
            label="Criar"
          />
        </Stack>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <List
            className={classes.list}
            items={getFilteredProfiles()}
            itemIdKey="id"
            itemLabelKey="name"
            onEdit={handleEdit}
          />
        )}
      </ContentBox>
      <ProfileDialog
        onClose={handleCloseDialog}
        open={dialogOptions.open}
        profile={dialogOptions.itemData}
        title={dialogOptions.title}
        description={dialogOptions.description}
      />
    </RootBox>
  )
}

export default ProfilesPage
