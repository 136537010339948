import { Add } from '@mui/icons-material'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const NewAccountCard = () => {
  const navigate = useNavigate()

  return (
    <Card sx={{ height: '100%', cursor: 'pointer', minHeight: '280px' }} onClick={() => navigate('/new-account')}>
      <Box height="77.25%" display="flex" justifyContent="center" alignItems="center">
        <Add sx={{ fontSize: '6em' }} />
      </Box>
      <CardContent>
        <Typography align="center" variant="h6">
          {'Adicionar nova conta'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default NewAccountCard
