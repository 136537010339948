import { useContext } from 'react'
import { PlanLimitsContext } from '../../providers/PlanLimitsProvider/PlanLimitsContext'

const usePlanLimits = () => {
  const value = useContext(PlanLimitsContext)

  if (value === null) {
    throw new Error('usePlanLimits must be used within a PlanLimitsProvider')
  }

  return value
}

export default usePlanLimits
