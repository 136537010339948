import { FitScreen, Print, ZoomIn, ZoomOut } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5'
import { ENDPOINTS } from '../../constants/apiEndpoints'

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

type TypedArray =
  | Int8Array
  | Uint8Array
  | Uint8ClampedArray
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Float32Array
  | Float64Array

// function getWidth() {
//   return Math.max(
//     document.body.scrollWidth,
//     document.documentElement.scrollWidth,
//     document.body.offsetWidth,
//     document.documentElement.offsetWidth,
//     document.documentElement.clientWidth
//   )
// }

function getHeight() {
  // const height = Math.max(
  //   document.body.scrollHeight,
  //   document.documentElement.scrollHeight,
  //   document.body.offsetHeight,
  //   document.documentElement.offsetHeight,
  //   document.documentElement.clientHeight
  // )
  const height = window.innerHeight
  console.log('getHeight', height)
  return height
}

const Header = styled('div')(({ theme }) => ({
  width: '100%',
  maxHeight: 60,
  display: 'flex',

  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: '#3c3f48',
  color: theme.palette.common.white
}))
const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  minWidth: '100vw',
  // width: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.light.highAccent,
  display: 'flex',
  flexDirection: 'column'
}))
const ModuleNameTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  fontSize: '0.95rem'
}))

const ActionsContainer = styled(Box)(() => ({}))

const ImageNameContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}))

const useStyles = makeStyles((theme) => ({
  document: {
    boxShadow: theme.shadows[10],
    margin: 'auto',
    overflow: 'hidden'
  },
  icon: {
    color: theme.palette.common.white
  },
  page: {
    margin: '6px 0'
  }
}))

type DocumentType = InstanceType<typeof Document>

type DocumentProps = DocumentType['props']

interface ViewerViewProps extends DocumentProps {
  hidden?: boolean
  numPages?: number
  pageNumber?: number
  onPageChange?: (event: React.ChangeEvent<unknown>, value: number) => void
}

const ViewerView: React.FC<PropsWithChildren<ViewerViewProps>> = ({
  numPages,
  pageNumber = 1,
  onPageChange,
  onLoadSuccess,
  ...rest
}) => {
  const classes = useStyles()
  const lastZoomRef = useRef(window.screen.width / window.innerWidth)
  const [scale, setScale] = useState<number>(lastZoomRef.current + 1.2 < 1.2 ? 1.2 : lastZoomRef.current + 1.2)
  const [pageHeight, setPageHeight] = useState(getHeight())
  const dataRef = useRef<TypedArray>()

  useEffect(() => {
    const rootElement = window.document.getElementById('root')
    rootElement!.style.width = 'fit-content'

    return () => {
      rootElement!.style.width = 'unset'
    }
  }, [])

  useEffect(() => {
    const onResize = (e: UIEvent) => {
      const zoomPercentage = window.screen.width / window.innerWidth
      if (zoomPercentage > lastZoomRef.current) {
        handleZoomIn()
      } else {
        handleZoomOut()
      }
      lastZoomRef.current = zoomPercentage
      e.preventDefault()
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  // useEffect(() => {
  //   handleFitPageWidth()
  // }, [])

  // useEffect(() => {
  //   const handleResize = (e: UIEvent) => {
  //     console.log(e)
  //   }

  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  const handleZoomIn = () => {
    setScale((prev) => prev + 0.05)
  }

  const handleZoomOut = () => {
    setScale((prev) => prev - 0.05)
  }

  const handleFitPageWidth = () => {
    setScale(1.2)
  }

  const handlePrint = () => {
    if (dataRef.current) {
      const blob = new Blob([dataRef.current], { type: 'application/pdf' })
      window.open(URL.createObjectURL(blob))
    }
  }

  const handleLoadSuccessProxyeed: DocumentProps['onLoadSuccess'] = async (pdf) => {
    const data = await pdf.getData()
    dataRef.current = data
    onLoadSuccess?.(pdf)
  }

  useEffect(() => {
    setPageHeight(getHeight())
  }, [])

  return (
    <Root width={'100%'}>
      <Header>
        <ImageNameContainer>
          <img src={ENDPOINTS.PUBLIC_IMAGES + '/plugger-icon.png'} width={36} height={36} alt="Plugger Icon" />{' '}
          <ModuleNameTypography variant="caption">Document Viewer</ModuleNameTypography>
        </ImageNameContainer>
        <ActionsContainer>
          <IconButton onClick={handleZoomIn}>
            <ZoomIn className={classes.icon} />
          </IconButton>
          <IconButton>
            <ZoomOut className={classes.icon} onClick={handleZoomOut} />
          </IconButton>
          <IconButton>
            <FitScreen className={classes.icon} onClick={handleFitPageWidth} />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <Print className={classes.icon} />
          </IconButton>
        </ActionsContainer>
      </Header>

      <Document onLoadSuccess={handleLoadSuccessProxyeed} className={classes.document} {...rest}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            scale={scale}
            className={classes.page}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            height={pageHeight - 220}
          />
        ))}
      </Document>
    </Root>
  )
}

export default ViewerView
