import { PropsWithChildren } from 'react'

type UpgradePlanDialogProps = {
  open?: boolean
  onClose?: (reason: 'Exited' | 'EndFlow') => void
}

const UpgradePlanDialog: React.FC<PropsWithChildren<UpgradePlanDialogProps>> = ({ open = false, onClose }) => {
  // const [plans, setPlans] = useState<PlanSchema[]>([])
  // const [loading, setLoading] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()
  // const { t } = useTranslation()
  // const auth = useAuth()
  // const accountInfo = auth.getAccountInfo()
  // const [selectedPlanId, setSelectedPlanId] = useState(accountInfo!.planId)
  // const isOwner = auth.getUserInfo()?.userId === accountInfo?.userCreatorId
  // const navigate = useNavigate()

  // useEffect(() => {
  //   ;(async () => {
  //     if (open) {
  //       try {
  //         navigate('/pricing')
  //       } catch (err: any) {
  //         enqueueSnackbar(err.message, { variant: 'error' })
  //       }
  //     }
  //   })()
  // }, [enqueueSnackbar, navigate, open])

  // const handleChoosePlan = async () => {
  //   if (selectedPlanId !== accountInfo?.planId) {
  //     setLoading(true)
  //     try {
  //       const {
  //         data: { url }
  //       } = await billingService.createCheckoutSession(selectedPlanId)
  //       window.location = url
  //     } catch (err: any) {
  //       enqueueSnackbar(err.message, { variant: 'error' })
  //     }
  //   }
  // }

  return <></>
}

export default UpgradePlanDialog
