import { Power } from '@mui/icons-material'
import { Stack } from '@mui/material'
import MaterialList from '@mui/material/List'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckLimitButton } from '../../components/CheckLimitButton'
import ContentBox from '../../components/ContentBox'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import RootBox from '../../components/RootBox'
import UpgradePlanDialog from '../../components/UpgradePlanDialog/UpgradePlanDialog'
import { PlugSchema } from '../../schemas/PlugSchema'
import plugService from '../../services/plugService'
import { normalyzeAndLowering } from '../../utils/stringUtils'
import CreateNewPlugDialog from './CreateNewPlugDialog/CreateNewPlugDialogv2'
import PlugErrorRetryDialog from './PlugErrorRetryDialog'
import PlugListItem from './PlugListItem'
import UpdatePlugDialogV2 from './UpdatePlugDialog/UpdatePlugDialogV2'

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const PlugsPage: React.FC<PropsWithChildren> = () => {
  const [plugs, setPlugs] = useState<PlugSchema[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [editPlug, setEditPlug] = useState<PlugSchema | null>(null)
  const [focusedPlug, setFocusedPlug] = useState<PlugSchema | null>(null)
  const searchParams = new URLSearchParams(window.location.search)
  const openCreateModal = searchParams.get('openCreateModal')
  const [openNewDialog, setOpenNewDialog] = useState(openCreateModal === 'true')
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openErrorRetryDialog, setOpenErrorRetryDialog] = useState(false)
  const [loadingRetry, setLoadingRetry] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false)
  const classes = useStyles()

  const handleCloseCreateDialog = () => {
    setOpenNewDialog(false)
    reloadPlugs()
  }

  const handleOpenUpdateDialog = (currentEdit: PlugSchema) => {
    setEditPlug(currentEdit)
    setOpenUpdateDialog(true)
  }
  const handleOpenErrorRetryDialog = (currentEdit: PlugSchema) => {
    setFocusedPlug(currentEdit)
    setOpenErrorRetryDialog(true)
  }
  const handleCloseUpdateDialog = (reason: 'Cancelled' | 'Edited' | any) => {
    setOpenUpdateDialog(false)
    setEditPlug(null)
    if (reason === 'Edited' || reason === 'Submited') {
      reloadPlugs()
    }
  }
  const handleCloseErrorRetryDialog = () => {
    setOpenErrorRetryDialog(false)
    setFocusedPlug(null)
    reloadPlugs()
  }

  const reloadPlugs = async () => {
    const response = await plugService.getAll()
    setPlugs(response.data)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      reloadPlugs()
    }, 10000)

    return () => clearTimeout(timer)
  }, [])

  const handleErrorRetryConfirm = async () => {
    try {
      setLoadingRetry(true)
      setOpenErrorRetryDialog(false)
      await plugService.updateData(focusedPlug!.id)
      enqueueSnackbar(t('pagePlugs.toast.success.updatingInitialized'), { variant: 'success' })
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.toast.error.updatingInitialized', { msg: err.message }), { variant: 'error' })
    }
    setFocusedPlug(null)
    setTimeout(() => {
      setLoadingRetry(false)
      reloadPlugs()
    }, 3000)
  }

  useEffect(() => {
    ;(async () => {
      try {
        await reloadPlugs()
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.toast.error.loadingPlugs', { msg: err.message }), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, t])

  const getFilteredPlugs = () => {
    if (!searchValue) {
      return plugs.sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
    }
    return plugs
      .filter((p) => normalyzeAndLowering(p.name).includes(normalyzeAndLowering(searchValue)))
      .sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
  }

  return (
    <RootBox>
      <Header
        icon={Power}
        title="Plugs"
        subtitle={t('pagePlugs.header.subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        <Stack
          alignItems="flex-end"
          paddingRight={4}
          sx={(theme) => ({
            bgcolor: theme.palette.background.light.default
          })}
        >
          <CheckLimitButton planResource="plugs" onLimitAvailable={() => setOpenNewDialog(true)} />
        </Stack>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <MaterialList className={classes.list}>
            {getFilteredPlugs().map((item) => (
              <PlugListItem
                plug={item}
                onEdit={handleOpenUpdateDialog}
                onErrorRetry={handleOpenErrorRetryDialog}
                loadingRetry={loadingRetry}
              />
            ))}
          </MaterialList>
        )}
      </ContentBox>
      {openNewDialog && <CreateNewPlugDialog open onClose={handleCloseCreateDialog} />}
      {/* {editPlug && (
        <UpdatePlugDialog open={openUpdateDialog} onClose={() => handleCloseUpdateDialog('Submited')} plug={editPlug} />
      )} */}
      <UpdatePlugDialogV2
        open={openUpdateDialog}
        plug={editPlug}
        onClose={handleCloseUpdateDialog}
        plugId={editPlug?.id}
      />
      {focusedPlug && (
        <PlugErrorRetryDialog
          open={openErrorRetryDialog}
          onClose={handleCloseErrorRetryDialog}
          description={focusedPlug!.lastLog!.description}
          onConfirm={handleErrorRetryConfirm}
        />
      )}
      <UpgradePlanDialog open={openUpgradeDialog} onClose={() => setOpenUpgradeDialog(false)} />
    </RootBox>
  )
}

export default PlugsPage
