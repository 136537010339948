import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

interface StyleLoadingProps {
  plugFill?: React.CSSProperties['fill']
  loopFill?: React.CSSProperties['fill']
  centered?: boolean
  forceAlign?: boolean
}

interface LoadingProps extends StyleLoadingProps, React.SVGProps<SVGSVGElement> {
  size?: 'small' | 'medium' | 'large'
  hidden?: boolean
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: 'block',
    top: '40%'
  },
  forceAlign: {
    position: 'relative',
    margin: 'auto'
  },
  plug: {
    fill: (props: StyleLoadingProps) => props.plugFill || theme.palette.primary.main
  },
  loopNormal: {
    stroke: (props: StyleLoadingProps) => props.loopFill || theme.palette.primary.main,
    strokeWidth: '12',
    fill: 'none'
  },
  loopOffSet: {
    display: 'none'
  },
  hidden: {
    display: 'none'
  }
}))

const sizeDefinitions = {
  small: {
    width: '140px',
    height: '45px'
  },
  medium: {
    width: '200px',
    height: '80px'
  },
  large: {
    width: '260px',
    height: '120px'
  }
}

const Loading: React.FC<PropsWithChildren<LoadingProps>> = ({
  plugFill,
  loopFill,
  width,
  height,
  size = 'medium',
  centered = true,
  forceAlign = false,
  hidden,
  ...props
}) => {
  const classes = useStyles({ plugFill, loopFill })
  const { width: sizeWith, height: sizeHeight } = sizeDefinitions[size]
  return (
    <svg
      {...props}
      className={clsx({ [classes.centered]: centered, [classes.forceAlign]: forceAlign, [classes.hidden]: hidden })}
      version="1.1"
      id="preloader"
      x="0px"
      y="0px"
      width={width || sizeWith}
      height={height || sizeHeight}
      viewBox="0 0 240 120"
    >
      <path
        id="loop-normal"
        className={classes.loopNormal}
        d="M120.5,60.5L146.48,87.02c14.64,14.64,38.39,14.65,53.03,0s14.64-38.39,0-53.03s-38.39-14.65-53.03,0L120.5,60.5
      L94.52,87.02c-14.64,14.64-38.39,14.64-53.03,0c-14.64-14.64-14.64-38.39,0-53.03c14.65-14.64,38.39-14.65,53.03,0z"
      >
        <animate
          attributeName="stroke-dasharray"
          attributeType="XML"
          from="500, 50"
          to="450 50"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dashoffset"
          attributeType="XML"
          from="-40"
          to="-540"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
        />
      </path>

      <path
        id="loop-offset"
        className={classes.loopOffSet}
        d="M146.48,87.02c14.64,14.64,38.39,14.65,53.03,0s14.64-38.39,0-53.03s-38.39-14.65-53.03,0L120.5,60.5
      L94.52,87.02c-14.64,14.64-38.39,14.64-53.03,0c-14.64-14.64-14.64-38.39,0-53.03c14.65-14.64,38.39-14.65,53.03,0L120.5,60.5
      L146.48,87.02z"
      />

      <path id="socket" className={classes.plug} d="M7.5,0c0,8.28-6.72,15-15,15l0-30C0.78-15,7.5-8.28,7.5,0z" />

      <path
        id="plug"
        className={classes.plug}
        d="M0,9l15,0l0-5H0v-8.5l15,0l0-5H0V-15c-8.29,0-15,6.71-15,15c0,8.28,6.71,15,15,15V9z"
      />

      <animateMotion
        href="#plug"
        dur="2s"
        rotate="auto"
        repeatCount="indefinite"
        calcMode="linear"
        keyTimes="0;1"
        keySplines="0.42, 0, 0.58, 1"
      >
        <mpath href="#loop-normal" />
      </animateMotion>

      <animateMotion
        href="#socket"
        dur="2s"
        rotate="auto"
        repeatCount="indefinite"
        calcMode="linear"
        keyTimes="0;1"
        keySplines="0.42, 0, 0.58, 1"
      >
        <mpath href="#loop-offset" />
      </animateMotion>
    </svg>
  )
}

export default Loading
