import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { PlugFieldSchema, PlugFieldType } from '../../schemas/PlugSchema'
import { format } from '../../utils/stringUtils'
import api from '../api'

export function listSpreadSheets(connectionId: number): Promise<AxiosResponse<{name: string, id: string}[]>> {
  return api.get(format(ENDPOINTS.GOOGLE_API_LIST_SPREADSHEETS, connectionId))
}

export function getSheets(connectionId: number, spreadSheetId: string): Promise<AxiosResponse<{name: string, id: number}[]>> {
  return api.get(format(ENDPOINTS.GOOGLE_API_LIST_SHEETS, connectionId, spreadSheetId))
}

export function getSheetColumns(
  connectionId: number,
  spreadSheetId: string,
  sheetId: number
): Promise<AxiosResponse<{name: string, type: PlugFieldType}[]>> {
  return api.get(format(ENDPOINTS.GOOGLE_API_LIST_SHEETS_COLUMNS, connectionId, spreadSheetId, sheetId))
}

export type CreateGoogleSheetsPlugRequest = {
  name: string
  spreadsheetId: string
  sheetId: number,
  fields: PlugFieldSchema[]
  filter?: string
  connectionId: number
}

export function createGoogleSheetsPlug(req: CreateGoogleSheetsPlugRequest
): Promise<AxiosResponse<GoogleSheetsPlug>> {
  return api.post(ENDPOINTS.GOOGLE_API_CREATE_SHEETS_PLUG, req)
}

export type UpdateGoogleSheetsPlugRequest = {
  id: number
  name: string
  fields: PlugFieldSchema[]
  filter?: string
}

export function updateGoogleSheetsPlug(req: UpdateGoogleSheetsPlugRequest
): Promise<AxiosResponse<void>> {
  return api.patch(ENDPOINTS.GOOGLE_API_CREATE_SHEETS_PLUG + '/' + req.id, req)
}

export type GoogleSheetsPlug = {
  id: number
  name: string
  spreadsheetId: string
  sheetId: number,
  fields: PlugFieldSchema[]
  filter?: string
  connection: {
    id: number
    name: string
  }
}

export function getGoogleSheetPlug(googleSheetPlugId: number 
): Promise<AxiosResponse<GoogleSheetsPlug>> {
  return api.get(ENDPOINTS.GOOGLE_API_CREATE_SHEETS_PLUG  + '/' + googleSheetPlugId)
}
