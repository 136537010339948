import { CheckCircleOutlined, Edit } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import Avatar from 'react-avatar-edit'
import Loading from '../../components/Loading'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { staticService } from '../../services'
import base64Utils from '../../utils/base64Utils'

export type EditableAvatarProps = {
  width?: number
  height?: number
  preview: string | undefined
  useDataUri?: boolean
  onCrop?(base64: string | undefined, fileName: string | undefined): void
  alt?: string
  insideText?: string
  fallbackImage?: string
  disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatarContainer: {
    width: (props: { width: number; height: number }) => props.width,
    height: (props: { width: number; height: number }) => props.height,
    position: 'relative'
  },
  avatarContainerViewer: {
    width: (props: { width: number; height: number }) => props.height + 20,
    height: (props: { width: number; height: number }) => props.height,
    position: 'relative',
    '&:hover $editButton': {
      display: 'block'
    }
  },
  confirmButton: {
    width: 20,
    height: 20,
    position: 'absolute',
    zIndex: 999,
    cursor: 'pointer',
    right: '10px',
    top: '10px'
  },
  editButton: {
    width: 20,
    height: 20,
    color: theme.palette.text.primary,
    position: 'absolute',
    display: 'none',
    zIndex: 999,
    cursor: 'pointer',
    right: '10px',
    top: '10px'
  }
}))
const MAX_AVATAR_FILE_SIZE = 7340032 // 7MB

const EditableAvatar: React.FC<
  PropsWithChildren<EditableAvatarProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
> = ({
  width = 224,
  height = 126,
  preview,
  useDataUri,
  onCrop,
  alt,
  fallbackImage = ENDPOINTS.PUBLIC_IMAGES + '/default-user.svg',
  insideText = 'Click for up new Avatar',
  disabled,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [inEditMode, setInEditMode] = useState(false)
  const [tempCrop, setTempCrop] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [statePreview, setStatePreview] = useState<string | undefined>(preview)
  const classes = useStyles({ width, height })
  const theme = useTheme()

  const handleBeforeFileLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target.files?.[0].size || 0) > MAX_AVATAR_FILE_SIZE) {
      enqueueSnackbar('File is too big, max allowed size is 7MB', { variant: 'error' })
      event.target.value = ''
    } else setInEditMode(true)
  }

  const handleCrop = (base64: string) => {
    setTempCrop(base64)
  }

  const handleConfirmCrop = () => {
    onCrop?.(tempCrop, 'avt.png')
    setInEditMode(false)
  }

  const handleChangeToEdit = () => {
    setInEditMode(true)
  }

  const handleClose = () => setTempCrop(undefined)

  useEffect(() => {
    if (preview && !useDataUri && !inEditMode) {
      setLoading(true)
      staticService
        .get(preview)
        .then(async (response) => {
          const imgDataUri = await base64Utils.convertBlobToDataUri(response.data)
          setStatePreview(imgDataUri)
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar(`Something went wrong when get image ${err.message}`, { variant: 'error' })
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
      setStatePreview(preview)
    }
  }, [preview, enqueueSnackbar, useDataUri, inEditMode])

  return (
    <div className={classes.root} {...rest}>
      {inEditMode && !disabled ? (
        <div className={classes.avatarContainer}>
          <CheckCircleOutlined
            htmlColor={tempCrop ? 'white' : 'grey'}
            width={20}
            height={20}
            className={classes.confirmButton}
            onClick={handleConfirmCrop}
          />
          <Avatar
            width={width}
            height={height}
            onCrop={handleCrop}
            label={insideText}
            onClose={handleClose}
            labelStyle={{ color: theme.palette.text.primary, cursor: 'pointer' }}
            onBeforeFileLoad={handleBeforeFileLoad}
            src={statePreview}
          />
        </div>
      ) : (
        <div className={classes.avatarContainerViewer}>
          {statePreview && loading ? (
            <Loading />
          ) : (
            <>
              <Edit
                width={20}
                height={20}
                className={classes.editButton}
                onClick={handleChangeToEdit}
                style={disabled ? { display: 'none' } : undefined}
              />
              <img width={height} height={height} src={statePreview || fallbackImage} alt={alt} />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default EditableAvatar
