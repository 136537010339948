import { Box, BoxProps, CardMedia, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import SuccessCheckMark from '../../components/SuccessCheckMark'
import { staticService } from '../../services'
import base64Utils from '../../utils/base64Utils'

export interface CardSelectableProps {
  image: string
  title?: string
  info?: string
  infoIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  selected?: boolean
  fallbackImage?: string
  disableCredentials?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cardArea: {
    position: 'relative',
    zIndex: 1,
    width: '110%',
    paddingBottom: '140%',
    perspective: '1000px',
    '&:hover': {
      '& $media': {
        transform: 'rotateX(7deg) translateY(-6px)',
        '&::after': {
          transform: 'rotateX(7deg) translateY(-6px)'
        }
      },
      '&::before': {
        opacity: '0.6',
        transform: 'rotateX(7deg) translateY(-6px) scale(1.05)'
      }
    },
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: '5%',
      left: '5%',
      width: '90%',
      height: '90%',
      background: 'rgba(0, 0, 0, 0.5)',
      boxShadow: '0 6px 12px 12px rgba(0, 0, 0, 0.4)',
      willChange: 'opacity',
      transformOrigin: 'top center',
      transform: 'skewX(0.001deg)',
      transition: 'transform 0.35s ease-in-out, opacity 0.5s ease-in-out'
    }
  },
  media: {
    position: 'absolute',
    zIndex: 1,
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundSize: 'cover',
    perspectiveOrigin: '50% 50%',
    transformStyle: 'preserve-3d',
    transformOrigin: 'top center',
    willChange: 'transform',
    transform: 'skewX(0.001deg)',
    transition: 'transform 0.35s ease-in-out',
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      zIndex: 100,
      top: '0',
      left: '0',
      width: '100%',
      height: '120%',
      background:
        'linear-gradient(226deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.2) 42%, rgba(255, 255, 255, 0) 60%)',
      transform: 'translateY(-20%)',
      willChange: 'transform',
      transition: 'transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1)'
    }
  },
  info: {
    marginTop: theme.spacing(1)
  },
  successCheckMark: {
    position: 'absolute',
    right: 0,
    top: -50,
    zIndex: 200
  }
}))

const CardSelectable: React.FC<PropsWithChildren<CardSelectableProps & BoxProps>> = ({
  image,
  title,
  info,
  infoIcon: InfoIcon,
  selected,
  fallbackImage,
  disableCredentials,
  ...others
}) => {
  const classes = useStyles()

  const [imageSrc, setImageSrc] = useState<string | null>(null)

  useEffect(() => {
    if (disableCredentials) return
    if (image) {
      staticService.get(image).then(async (response) => {
        const imgDataUri = await base64Utils.convertBlobToDataUri(response.data)
        setImageSrc(imgDataUri)
      })
      return
    }
    setImageSrc(null)
  }, [disableCredentials, image])
  return (
    <Box position="relative" sx={{ cursor: 'pointer' }}>
      {selected && <SuccessCheckMark className={classes.successCheckMark} />}
      <Box className={classes.cardArea} {...others}>
        <CardMedia
          className={classes.media}
          image={(disableCredentials ? image : imageSrc) || fallbackImage}
          title={title}
        />
      </Box>
      <Typography sx={{ width: '110%' }} align="center" variant="h6">
        {title}
      </Typography>
    </Box>
  )
}

export default CardSelectable
