import { Edit } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Hidden,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MaterialListItem,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { ListItemProps } from '../../components/ListItem'
import { staticService, userService } from '../../services'
import base64Utils from '../../utils/base64Utils'
import randomUtils from '../../utils/randomUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
      margin: theme.spacing(0.5, 0),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  container: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  },
  avatar: {
    backgroundColor: (props: { randomColor: string }) => props.randomColor
  },
  secondaryItem: {
    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      top: 10
    }
  },
  textPrimaryContainer: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 48px)'
    }
  }
}))

const UserListItem: React.FC<PropsWithChildren<ListItemProps>> = ({
  itemId,
  primaryText,
  secondaryText,
  startIcon: StartIcon,
  endIcon: EndIcon = Edit,
  imageSrc,
  className,
  button,
  onEdit,
  classes,
  hideEditIcon,
  item,
  ...others
}) => {
  const memoColorByString = useMemo<string>(
    () => randomUtils.getColorByString(primaryText || item.email),
    [primaryText, item.email]
  )
  const classesDefault = useStyles({ randomColor: memoColorByString })
  const [imageSrcB64, setImageSrcB64] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isMountedRef = useRef(false)
  const { t } = useTranslation()
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (imageSrc)
      staticService.get(imageSrc).then(async (response) => {
        const imgDataUri = await base64Utils.convertBlobToDataUri(response.data)
        setImageSrcB64(imgDataUri)
        return
      })
    setImageSrcB64(null)
  }, [imageSrc])

  const resendInvite = async (userId: string) => {
    try {
      setLoading(true)
      await userService.resendInvite(userId)
      enqueueSnackbar(t('pageUsers.listItem.toast.success.inviteResend'), { variant: 'success' })
    } catch (err: any) {
      enqueueSnackbar(t('pageUsers.listItem.toast.error.inviteResend'), { variant: 'error' })
    }
    if (isMountedRef.current) {
      setLoading(false)
    }
  }

  return (
    <MaterialListItem
      button={button}
      classes={{ container: classesDefault.container, ...classes }}
      className={clsx(classesDefault.root, className)}
      key={itemId}
      style={
        mdDown
          ? {
              padding: item.statusInAccount === 'PENDING_ACCEPT_INVITE' ? '20px' : '12px'
            }
          : undefined
      }
      {...others}
    >
      <Hidden mdDown>
        <ListItemAvatar>
          {StartIcon ? (
            React.cloneElement(StartIcon as any)
          ) : (
            <Avatar
              alt={primaryText ?? item.email}
              src={imageSrcB64 || '/broken-image.jpg'}
              className={classesDefault.avatar}
            />
          )}
        </ListItemAvatar>
      </Hidden>
      <ListItemText
        primary={
          <Box className={classesDefault.textPrimaryContainer}>
            <Typography>{primaryText}</Typography>
            <Typography sx={{ wordBreak: 'break-all' }} color="textPrimary">
              {item.email}
            </Typography>
          </Box>
        }
        secondary={secondaryText}
      />
      <ListItemSecondaryAction style={{ textAlign: 'end' }} hidden={hideEditIcon}>
        <IconButton
          className={clsx({ [classesDefault.secondaryItem]: item.statusInAccount === 'PENDING_ACCEPT_INVITE' })}
          onClick={() => onEdit(itemId)}
          size="large"
        >
          <EndIcon />
        </IconButton>
        {item.statusInAccount === 'PENDING_ACCEPT_INVITE' && (
          <Typography color="textPrimary" style={{ position: 'relative', textOverflow: 'ellipsis' }}>
            <Hidden mdDown>{t('pageUsers.listItem.pendingAcceptInvite')}</Hidden>
            <Button
              variant="text"
              label={t('pageUsers.listItem.btnResendInvite')}
              loading={loading}
              onClick={() => resendInvite(item.id)}
            />
          </Typography>
        )}
        {item.statusInAccount === 'INACTIVE' && (
          <Typography color="textPrimary">{t('pageUsers.listItem.inactive')}</Typography>
        )}
      </ListItemSecondaryAction>
    </MaterialListItem>
  )
}

export default UserListItem
