import Axios, { AxiosResponse } from 'axios'
import { BASE_URL, ENDPOINTS } from '../../constants/apiEndpoints'
import {
  ResponseAccountSchema,
  ResponseAccountUsedCapabilitiesSchema,
  ResponseEmailOwnAccountsSchema,
  UpdateAccountSchema
} from '../../schemas/AccountSchema'
import { UserAccountStatus } from '../../schemas/UserSchema'
import base64Utils from '../../utils/base64Utils'
import { format } from '../../utils/stringUtils'
import api from '../api'

export interface GetUserAccountsResponse {
  id: number
  companyName: string
  logoImage: string
  registredAt: Date
  updatedAt: Date
  status: UserAccountStatus
  isFree: boolean
  termsAndPolicyAccepted: boolean
}

export type CreateAccountRequest = {
  companyName: string
  email: string
  password: string
  confirmPassword: string
  userId: string
  planId: number
}

export interface GetUserAccountsTokenResponse {
  userAccounts: GetUserAccountsResponse[]
  token: string
  expiration: Date
}

const getUserAccounts = (email: string, password: string): Promise<AxiosResponse<GetUserAccountsTokenResponse>> => {
  return api.post(ENDPOINTS.ACCOUNTS_GET_USER_ACCOUNTS, {
    email,
    password
  })
}

const deleteFreeAccount = (accountId: number) => {
  return api.delete(`/accounts/${accountId}`)
}

const getLoggedUserAccounts = (): Promise<AxiosResponse<GetUserAccountsResponse[]>> => {
  return api.get(ENDPOINTS.GET_LOGGED_USER_ACCOUNTS)
}

const extendTrial = (): Promise<AxiosResponse<void>> => {
  return api.post(ENDPOINTS.ACCOUNTS_EXTEND_TRIAL)
}

const getCurrentInfo = (): Promise<AxiosResponse<ResponseAccountSchema>> => {
  return api.get(ENDPOINTS.ACCOUNTS_GET_CURRENT_INFO)
}

const markToDelete = (accountId: number, password: string): Promise<AxiosResponse<void>> => {
  return api.post(format(ENDPOINTS.ACCOUNTS_UPDATE, accountId) + '/markToDelete', { password })
}

const getCurrentUsedCapabilities = (): Promise<AxiosResponse<ResponseAccountUsedCapabilitiesSchema>> => {
  return api.get(ENDPOINTS.ACCOUNTS_GET_CURRENT_USED_CAPABILITIES)
}

const checkEmailOwnAccounts = (email: string): Promise<AxiosResponse<ResponseEmailOwnAccountsSchema>> => {
  return api.get(format(ENDPOINTS.ACCOUNTS_CHECK_EMAIL_OWN_ACCOUNTS, email))
}

const createAccount = (createAccountReq: CreateAccountRequest): Promise<AxiosResponse<ResponseAccountSchema>> => {
  return api.post(ENDPOINTS.ACCOUNTS_CREATE, createAccountReq)
}

const setInitialPresentationShowed = (): Promise<AxiosResponse<void>> => {
  return api.post(`${ENDPOINTS.ACCOUNTS_CREATE}/current/initial-presentation-showed`)
}

const updateAccount = (updatedAccount: UpdateAccountSchema): Promise<AxiosResponse<ResponseAccountSchema>> => {
  const formData = new FormData()

  if (updatedAccount.logoImage) {
    const imageFile = base64Utils.convertDataURIToBinary(updatedAccount.logoImage)
    formData.append('logoImage', imageFile, updatedAccount.fileName)
  }

  formData.append('companyName', updatedAccount.companyName)
  formData.append('id', updatedAccount.id.toString())
  formData.append('timeZone', updatedAccount.timeZone.toString())

  const client = Axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  })
  client.defaults.transformRequest = []
  client.defaults.headers.common = {
    'Content-Type': 'multipart/form-data'
  }

  return client.put(format(ENDPOINTS.ACCOUNTS_UPDATE, updatedAccount.id), formData, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  })
}

export default {
  getUserAccounts,
  checkEmailOwnAccounts,
  getCurrentInfo,
  getCurrentUsedCapabilities,
  setInitialPresentationShowed,
  updateAccount,
  markToDelete,
  getLoggedUserAccounts,
  createAccount,
  deleteFreeAccount,
  extendTrial
}
