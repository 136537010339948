import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'

const NotFoundPage: React.FC = () => {
  return (
    <Box width="100vw" height="100vh" bgcolor={(theme) => theme.palette.primary.main}>
      <Stack textAlign="center" spacing={3} height="100vh">
        <Typography fontSize={(theme) => theme.typography.pxToRem(80)} color="white">
          404
        </Typography>
        <Typography fontSize={(theme) => theme.typography.pxToRem(80)} color="white">
          Ops...
          <br />
          Not Found
        </Typography>
        <Link component={RouterLink} sx={{ color: 'white' }} variant="h2" to={PATHS.HOME}>
          Home
        </Link>
        <Box display="flex">
          <Box flex="1 1 0" display="flex" justifyContent="flex-start">
            <img
              style={{ width: '90%', height: '40px' }}
              src={ENDPOINTS.PUBLIC_IMAGES + '/plug-left.png'}
              alt="plug icon left"
            />
          </Box>
          <Box flex="1 1 0" display="flex" justifyContent="flex-end">
            <img
              style={{ width: '90%', height: '40px' }}
              src={ENDPOINTS.PUBLIC_IMAGES + '/plug-right.png'}
              alt="plug icon right"
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default NotFoundPage
