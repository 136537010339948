import { Add } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Swipeable from 'react-swipeable-views'
import * as yup from 'yup'
import SelectBox from '../../../../../../components/SelectBox'
import TextField from '../../../../../../components/TextField'
import { ApiConnectionType, ConnectionSchema } from '../../../../../../schemas/ConnectionSchema'
import { connectionService } from '../../../../../../services'
import NavigationHeader, { NavigationHeaderProps } from '../../NavigationHeader'
import FacebookConnectionForm from '../FacebookConnectionForm'

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(3, 0, 0)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

export type FacebookAdAccountBusiness = {
  id: string
  name: string
}

export type FacebookAdAccountResponse = {
  id: string
  name: string
  business?: FacebookAdAccountBusiness
  timezone_offset_hours_utc: number
}

export type FacebookAdsPlugFormValues = {
  plugName: string
  connection: ConnectionSchema
  account: FacebookAdAccountBusiness
  adAccount: FacebookAdAccountResponse
}

type FacebookAdsPlugFormProps = {
  onSubmit: (values: FacebookAdsPlugFormValues) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const plugFormSchemaValidator = yup.object({
  plugName: yup.string().required(),
  account: yup.object().required(),
  adAccount: yup.object().required(),
  connection: yup.object().required()
})

const FacebookAdsPlugForm: React.FC<PropsWithChildren<FacebookAdsPlugFormProps>> = ({ navigationProps, onSubmit }) => {
  const classes = useStyles()
  const [connections, setConnections] = useState<ConnectionSchema[]>([])
  const [adAccounts, setAdAccounts] = useState<FacebookAdAccountResponse[]>([])

  const [loadingFindConnection, setLoadingFindConnection] = useState(false)

  const [loadingCreateConnection, setLoadingCreateConnection] = useState(false)
  const [showCreateConnectionForm, setShowCreateConnectionForm] = useState(false)
  const { slideUpdateHeight } = useContext((Swipeable as any).SwipeableViewsContext) as any

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { values, setFieldValue, submitForm, errors } = useFormik({
    validateOnChange: false,
    initialValues: {} as FacebookAdsPlugFormValues,
    onSubmit: onSubmit,
    validationSchema: plugFormSchemaValidator
  })

  const handleValuesChange = (key: string, value: any) => {
    setFieldValue(key, value)
  }

  const handleOpenSelectConnection = () => {
    ;(async () => {
      try {
        setLoadingFindConnection(true)
        const connsResp = await connectionService.getAll()
        setConnections(connsResp.data.filter((x) => x.type === ApiConnectionType.Facebook))
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindConnection(false)
      }
    })()
  }

  const handleChangeConnection = async (connection: any) => {
    const { data } = await connectionService.getById(connection.id, true)
    const con = data as any
    handleValuesChange('connection', con)
    try {
      const resp = await axios.get(
        `https://graph.facebook.com/v14.0/${con.apiSettings.userID}/adaccounts?access_token=${con.apiSettings.longLivedAccessToken}&fields=name,business,timezone_offset_hours_utc`
      )
      setAdAccounts(resp.data.data)
    } catch (err) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
    }
  }

  const handleCreateConnection = async (values: any) => {
    values.type = ApiConnectionType.Facebook
    try {
      setLoadingCreateConnection(true)
      const response = await connectionService.create(values)

      setConnections((prev) => [...prev, response.data])
      handleChangeConnection(values)
      setShowCreateConnectionForm(false)

      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createConnection', { connectionName: values.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.createConnection'), { variant: 'error' })
    } finally {
      setLoadingCreateConnection(false)
    }
  }

  useEffect(() => {
    slideUpdateHeight()
  }, [showCreateConnectionForm, slideUpdateHeight])

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() => submitForm()}
      />
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Typography variant="h2" align="center" component="div" color="inherit">
          {t('pagePlugs.modalCreate.configureTitle')}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p" color="inherit">
          {t('pagePlugs.modalCreate.configureSubtitle')}
        </Typography>
        {!showCreateConnectionForm ? (
          <>
            <Box display="flex" alignItems="center" className={classes.dialogFields}>
              <SelectBox
                className={clsx(classes.outlinedWhiteInput, classes.fullWidth)}
                options={connections}
                value={values.connection}
                onChange={(event, newValue) => handleChangeConnection(newValue)}
                getOptionLabel={(o) => o.name}
                loading={loadingFindConnection}
                onOpen={handleOpenSelectConnection}
                textFieldProps={{
                  placeholder: t('pagePlugs.modalCreate.configureConnection')!,
                  name: 'connection',
                  error: !!errors['connection'],
                  helperText: errors['connection'] as any
                }}
              />
              <IconButton onClick={() => setShowCreateConnectionForm(true)} color="inherit" size="large">
                <Add />
              </IconButton>
            </Box>
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              options={adAccounts
                .map((x) => {
                  return {
                    name: !!x.business ? x.business.name : x.name,
                    id: x.id,
                    groupId: !!x.business ? x.business.id : x.name
                  }
                })
                .groupBy('groupId')
                .map((e) => ({ name: e.items[0].name, id: e.items[0].id }))}
              value={values.account}
              onChange={(event, newValue) => handleValuesChange('account', newValue)}
              getOptionLabel={(o) => o.name}
              loading={loadingFindConnection}
              textFieldProps={{
                placeholder: t('pagePlugs.modalCreate.facebook.account')!,
                name: 'account',
                error: !!errors['account'],
                helperText: errors['account'] as any
              }}
            />
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              options={adAccounts
                .map((x) => {
                  return {
                    name: x.name,
                    id: x.id,
                    timezone_offset_hours_utc: x.timezone_offset_hours_utc,
                    groupId: !!x.business ? x.business.name : x.name
                  }
                })
                .filter((x) => x?.groupId === values?.account?.name)}
              value={values.adAccount as any}
              onChange={(event, newValue) => handleValuesChange('adAccount', newValue)}
              getOptionLabel={(o) => `${o?.name} (${o?.id})`}
              loading={loadingFindConnection}
              textFieldProps={{
                placeholder: t('pagePlugs.modalCreate.facebook.adAccount')!,
                name: 'adAccount',
                error: !!errors['adAccount'],
                helperText: errors['adAccount'] as any
              }}
            />
            <TextField
              variant="outlined"
              fullWidth={false}
              value={values.plugName}
              onChange={(event) => handleValuesChange('plugName', event.target.value)}
              required
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              placeholder={t('pagePlugs.modalCreate.configureName')!}
              error={!!errors['plugName']}
              helperText={errors['plugName']}
            />
          </>
        ) : (
          <FacebookConnectionForm
            onSubmit={handleCreateConnection}
            onCancelClick={() => setShowCreateConnectionForm(false)}
            variant="white"
            usePlaceholder
          />
        )}
      </Box>
    </>
  )
}

export default FacebookAdsPlugForm
