import {
  DashboardControl,
  IExtension,
  ItemWidgetOptionEventArgs,
  KeyEventType,
  SequenceAction,
  ViewerApiExtension
} from 'devexpress-dashboard/common'
import { CustomizeSectionsEventArgs, OptionsPanelExtension } from 'devexpress-dashboard/designer'
import * as Model from 'devexpress-dashboard/model'

export declare class CustomExtension implements IExtension {
  name: string
  start?(): void
  stop?(): void
  processKeyEvent?(keyEventType: KeyEventType, eventArgs: JQueryKeyEventObject): boolean
  designerToViewerAction?: SequenceAction | undefined
  viewerToDesignerAction?: SequenceAction | undefined

  constructor(dashboardControl: DashboardControl)
}

const ChartLegendFormattingExtension = (function () {
  // 1. Model
  const fontSizeLegendProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'fontSizeLegendProperty',
    defaultValue: 12,
    valueType: 'number'
  }

  const fontWeightLegendProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'FontWeightLegendProperty',
    defaultValue: 400,
    valueType: 'number'
  }
  const fontColorLegendProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'FontColorLegendProperty',
    defaultValue: '#767676',
    valueType: 'string'
  }
  const fontOpacityLegendProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'FontOpacityLegendProperty',
    defaultValue: 1,
    valueType: 'number'
  }

  Model.registerCustomProperty(fontSizeLegendProperty)
  Model.registerCustomProperty(fontWeightLegendProperty)
  Model.registerCustomProperty(fontColorLegendProperty)
  Model.registerCustomProperty(fontOpacityLegendProperty)

  // 2. Viewer
  function onItemWidgetOptionsPrepared(args: ItemWidgetOptionEventArgs) {
    let fontSize = args.dashboardItem.customProperties.getValue(fontSizeLegendProperty.propertyName)
    let fontWeight = args.dashboardItem.customProperties.getValue(fontWeightLegendProperty.propertyName)
    let fontColor = args.dashboardItem.customProperties.getValue(fontColorLegendProperty.propertyName)
    let fontOpacity = args.dashboardItem.customProperties.getValue(fontOpacityLegendProperty.propertyName)
    var legendOptions = (args.options as any)['legend']
    if (legendOptions) {
      legendOptions.font = {
        size: fontSize,
        weight: fontWeight,
        color: fontColor,
        opacity: fontOpacity
      }
    }
  }

  // 3. Designer
  function onCustomizeSections(args: CustomizeSectionsEventArgs) {
    args.addSection({
      title: 'Legenda - Fonte',
      items: [
        {
          dataField: fontSizeLegendProperty.propertyName,
          editorType: 'dxNumberBox',
          label: {
            text: 'Tamanho'
          },
          editorOptions: {
            showSpinButtons: true
          }
        },
        {
          dataField: fontWeightLegendProperty.propertyName,
          editorType: 'dxSelectBox',
          label: {
            text: 'Estilo'
          },
          editorOptions: {
            items: [
              { value: 400, displayValue: 'Normal' },
              { value: 700, displayValue: 'Negrito' },
              { value: 1000, displayValue: 'Negrito Extra' }
            ],
            displayExpr: 'displayValue',
            valueExpr: 'value'
          }
        },
        {
          dataField: fontColorLegendProperty.propertyName,
          editorType: 'dxColorBox',
          label: {
            text: 'Cor'
          },
          editorOptions: {
            opened: false
          }
        },
        {
          dataField: fontOpacityLegendProperty.propertyName,
          editorType: 'dxNumberBox',
          label: {
            text: 'Opacidade'
          },
          editorOptions: {
            max: 1,
            min: 0,
            format: '#0%',
            step: 0.01,
            showSpinButtons: true
          }
        }
      ]
    })
  }
  // 4. Event Subscription
  function ChartLegendFormattingExtension(this: IExtension, dashboardControl: DashboardControl) {
    this.name = 'ChartLegendFormattingExtension'
    this.start = function () {
      var viewerApiExtension = dashboardControl.findExtension('viewerApi') as ViewerApiExtension
      if (viewerApiExtension) {
        viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
      }
      var optionsPanelExtension = dashboardControl.findExtension('itemOptionsPanel') as OptionsPanelExtension
      if (optionsPanelExtension) {
        optionsPanelExtension.on('customizeSections', onCustomizeSections)
      }
    }
    this.stop = function () {
      var viewerApiExtension = dashboardControl.findExtension('viewerApi') as ViewerApiExtension
      if (viewerApiExtension) {
        viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
      }
      var optionsPanelExtension = dashboardControl.findExtension('itemOptionsPanel') as OptionsPanelExtension
      if (optionsPanelExtension) {
        optionsPanelExtension.off('customizeSections', onCustomizeSections)
      }
    }
  }
  return ChartLegendFormattingExtension as CustomExtension
})()

export default ChartLegendFormattingExtension
