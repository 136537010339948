import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Form, { FormProps } from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import SwitchField from '../../components/Form/SwitchField'
import { ProfileSchema } from '../../schemas/ProfileSchema'
import { PartialBy } from '../../utils/typeUtils'

export const ProfileSchemaValidation = Yup.object({
  name: Yup.string().required(),
  managingAccess: Yup.bool().optional(),
  managingAccount: Yup.bool().optional(),
  managingAnalyseAndCollection: Yup.bool().optional(),
  managingPlug: Yup.bool().optional(),
  canExportData: Yup.bool().optional()
})

type ProfileFormProps = PartialBy<FormProps<Partial<ProfileSchema>>, 'initialValues'>

const ProfileForm: React.FC<PropsWithChildren<ProfileFormProps>> = ({ initialValues, ...rest }) => {
  const { t } = useTranslation()
  return (
    <Form
      initialValues={
        initialValues || {
          name: '',
          managingAccess: false,
          managingAccount: false,
          managingAnalyseAndCollection: false,
          managingPlug: false,
          canExportData: false
        }
      }
      validationSchema={ProfileSchemaValidation}
      {...rest}
    >
      <OutlinedTextField required name="name" label={t('pageProfiles.modalEdit.name')} />
      <SwitchField
        name="managingAccess"
        label={t('pageProfiles.modalCreate.managingAccess')}
        helperText={t('pageProfiles.modalEdit.managingAccess.text')}
      />
      <SwitchField
        name="managingAccount"
        label={t('pageProfiles.modalCreate.managingAccount')}
        helperText={t('pageProfiles.modalEdit.managingAccount.text')}
      />
      <SwitchField
        name="managingAnalyseAndCollection"
        label={t('pageProfiles.modalCreate.managingAnalyseCollection')}
        helperText={t('pageProfiles.modalCreate.managingAnalyseCollection.text')}
      />
      <SwitchField
        name="managingPlug"
        label={t('pageProfiles.modalCreate.managingPlug')}
        helperText={t('pageProfiles.modalCreate.managingPlug.text')}
      />
      <SwitchField
        name="canExportData"
        label={t('pageProfiles.modalCreate.canExportData')}
        helperText={t('pageProfiles.modalCreate.canExportData.text')}
      />
    </Form>
  )
}

export default ProfileForm
