import {
  Box,
  Button,
  CircularProgress,
  Hidden,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography,
  styled,
  useMediaQuery
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import chroma from 'chroma-js'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import PluggerLogoTransp from '../../components/PluggerLogoTransp'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import { useLocaleDetector } from '../../hooks'
import useAuth from '../../hooks/useAuth'
import i18n from '../../locales/i18next'
import { authService, userService } from '../../services'

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100vh',
    overflow: 'auto',
    background: `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${
      theme.palette.background.light.contrast || '#FFF'
    }`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  },
  formContainer: {
    width: '60%',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.light.contrast,
    textAlign: 'center',
    margin: 'auto'
  },
  whiteColor: {
    color: theme.palette.common.white
  },
  routerLink: {
    color: '#39e991',
    '&:hover': {
      color: chroma('#39e991').darken().hex()
    }
  }
}))

const validationSchema = yup.object({
  email: yup.string().email().required().label('Email'),
  password: yup.string().min(6).required().label(i18n.t('common.labels.password'))
})

const StyledLogo = styled(PluggerLogoTransp)(({ theme }) => ({
  margin: 'auto'
}))

const FormPaper = styled(Paper)(({ theme }) => ({
  width: '80%',
  backgroundColor: '#393b41'
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    color: '#39e991',
    fontSize: '1.2rem'
  },
  '& .MuiInputLabel-root, .MuiInputLabel-root.Mui-focused': {
    color: '#39e991'
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: theme.palette.error.main
  },
  '& .MuiOutlinedInput-root': {
    color: '#FFF'
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#39e991',
    color: '#39e991'
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.error.main
  }
}))

const styles = {
  input: {
    WebkitBoxShadow: '0 0 0 1000px #393b41 inset',
    WebkitTextFillColor: '#FFF',
    caretColor: '#FFF'
  }
}

const SignUpPage: React.FC<PropsWithChildren> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [loading, setLoading] = React.useState(false)
  const [emailConflict, setEmailConflict] = React.useState(false)
  const [emailSentWithSuccess, setEmailSentWithSuccess] = React.useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const auth = useAuth()
  const userLocale = useLocaleDetector()

  const { getFieldProps, handleSubmit, errors, submitForm } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        ;(values as any).locale = userLocale
        const { data } = await userService.createUser(values)
        setEmailSentWithSuccess(true)
      } catch (err: any) {
        if (err.response?.status === 409) {
          setEmailConflict(true)
        } else {
          const message = err.response?.data?.message || err.message
          enqueueSnackbar(message, { variant: 'error' })
        }
      }
      setLoading(false)
    }
  })

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: false,
      context: 'signup',
      callback: (response: any) => {
        setLoading(true)

        if (response.credential) {
          authService
            .signUpGoogle(response.credential)
            .then(async ({ data }) => {
              const appToken = await auth.signInGoogle(response.credential)
              const searchParams = new URLSearchParams()
              searchParams.set('token', appToken)
              searchParams.set('email', data.email)
              searchParams.set('userId', data.id)
              searchParams.set('firstName', data.firstName)
              searchParams.set('lastName', data.lastName)
              navigate('/confirm?' + searchParams.toString())
            })
            .catch(async (err: any) => {
              if (err.response?.status === 409) {
                await auth.signInGoogle(response.credential)
                navigate('/new-account')
              } else {
                const message = err.response?.data?.message || err.message
                enqueueSnackbar(message, { variant: 'error' })
                setLoading(false)
              }
            })
        }
      }
    })
    google.accounts.id.renderButton(document.getElementById('google-btn')!, {
      type: 'standard',
      size: 'large',
      width: '400',
      text: 'continue_with',
      theme: 'outline'
    })
  }, [auth, enqueueSnackbar, navigate, t])

  return (
    <Box className={classes.box}>
      <Stack direction={isMdDown ? 'column' : 'row'} height="100%">
        <Stack flex={1} alignItems="center" spacing={4} justifyContent="space-between">
          <img
            style={{ margin: isMdDown ? 'none' : 'auto' }}
            width="80%"
            height="auto"
            src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/logo%20transp%20horizontal.png"
            alt="Plugger BI logo transp horizontal"
          />
          <Hidden mdDown>
            <img
              width="80%"
              height="auto"
              src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/signup-rocket.png"
              alt="rocket art"
            />
          </Hidden>
        </Stack>
        <Stack
          display="flex"
          alignItems="center"
          justifyContent={isMdDown ? 'unset' : 'center'}
          flex={isMdDown ? 3 : 1}
          p={4}
          spacing={4}
        >
          <Typography variant="h1" display="block" textAlign="center" color="#FFF">
            {t('signup.title')}
            <Typography variant="h3" display="block" color="#FFF">
              {t('signup.subtitle')}
            </Typography>
          </Typography>
          <FormPaper elevation={4} sx={{ width: isMdDown ? '100%' : '80%', maxWidth: 495 }}>
            {emailConflict ? (
              <Stack p={3} spacing={3} textAlign="center">
                <Typography textAlign="center" color="#fff">
                  {t('signup.emailConflict')}
                </Typography>
                <Button onClick={() => setEmailConflict(false)}>{t('signup.tryAnotherEmail')}</Button>
              </Stack>
            ) : (
              <>
                {emailSentWithSuccess ? (
                  <Stack p={3} spacing={3} textAlign="center">
                    <Typography textAlign="center" color="#fff">
                      {t('signup.emailSent')}
                    </Typography>
                    <Button onClick={() => navigate(PATHS.SIGNIN)}>{t('signup.inboxCheck')}</Button>
                  </Stack>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Stack padding={6} spacing={4.5}>
                      <div id="google-btn"></div>
                      <StyledTextField
                        fullWidth
                        id="email"
                        required
                        type="email"
                        label="Email"
                        error={!!errors['email']}
                        helperText={errors['email']}
                        {...getFieldProps('email')}
                        inputProps={{ style: styles.input }}
                      />
                      <StyledTextField
                        fullWidth
                        id="password"
                        required
                        type="password"
                        label={t('common.labels.password')}
                        error={!!errors['password']}
                        helperText={errors['password']}
                        {...getFieldProps('password')}
                        inputProps={{ style: styles.input }}
                      />
                      <Button
                        disabled={loading}
                        endIcon={<CircularProgress sx={{ visibility: !loading ? 'hidden' : 'visible' }} />}
                        sx={{
                          height: '44px'
                        }}
                        variant="contained"
                        onClick={() => submitForm()}
                      >
                        {t('signup.btn')}
                      </Button>
                    </Stack>
                  </form>
                )}
              </>
            )}
            <Link
              style={{ color: '#fff', marginLeft: 'auto', padding: '1em', width: 'fit-content', display: 'block' }}
              to={PATHS.SIGNIN}
            >
              {t('signup.goToSignin')}
            </Link>
          </FormPaper>
        </Stack>
      </Stack>
    </Box>
  )
}

export default SignUpPage
