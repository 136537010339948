import { Edit, Send } from '@mui/icons-material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import MoreIcon from '@mui/icons-material/MoreVert'
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SendingSchema } from '../../schemas/SendingSchema'
import randomUtils from '../../utils/randomUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5, 0),
      fontSize: theme.typography.pxToRem(8)
    }
  },
  container: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  },
  avatar: {
    backgroundColor: (props: { randomColor: string }) => props.randomColor
  }
}))

type SendingListItemProps = {
  primaryText: string
  onEditClick: (sending: SendingSchema) => void
  onSendClick: (sending: SendingSchema) => void
  onHistoryClick: (sending: SendingSchema) => void
  item: SendingSchema
}

const SendingListItem: React.FC<PropsWithChildren<SendingListItemProps>> = ({
  primaryText,
  onEditClick,
  onSendClick,
  onHistoryClick,
  item
}) => {
  const memoColorByString = useMemo<string>(() => randomUtils.getColorByString(primaryText), [primaryText])
  const classes = useStyles({ randomColor: memoColorByString })

  const isMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  return (
    <ListItem
      classes={{ container: classes.container }}
      className={classes.root}
      key={item.id}
      secondaryAction={
        isMdDown ? (
          <MobileActions
            onSendClick={() => onSendClick(item)}
            onEditClick={() => onEditClick(item)}
            onHistoryClick={() => onHistoryClick(item)}
          />
        ) : (
          <>
            <IconButton onClick={() => onSendClick(item)} size="large">
              <Send />
            </IconButton>
            <IconButton onClick={() => onHistoryClick(item)} size="large">
              <ManageSearchIcon />
            </IconButton>
            <IconButton onClick={() => onEditClick(item)} size="large">
              <Edit />
            </IconButton>
          </>
        )
      }
    >
      <ListItemAvatar>
        <Avatar alt={primaryText} src="/broken-image.jpg" className={classes.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography sx={{ wordBreak: 'break-all' }}>{primaryText}</Typography>}
        disableTypography
      />
    </ListItem>
  )
}

type SendingActionProps = {
  onSendClick(): void
  onHistoryClick(): void
  onEditClick(): void
}

const MobileActions: React.FC<SendingActionProps> = ({ onEditClick, onHistoryClick, onSendClick }) => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  return (
    <>
      <IconButton onClick={(e) => setAnchorMenu(e.currentTarget)} size="large">
        <MoreIcon />
      </IconButton>
      <Menu anchorEl={anchorMenu} open={!!anchorMenu} onClose={() => setAnchorMenu(null)}>
        <MenuItem onClick={onSendClick}>
          <ListItemIcon>
            <Send fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('pageSending.mobileMenu.Send')} />
        </MenuItem>
        <MenuItem onClick={onHistoryClick}>
          <ListItemIcon>
            <ManageSearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('pageSending.mobileMenu.History')} />
        </MenuItem>
        <MenuItem onClick={onEditClick}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('pageSending.mobileMenu.Edit')} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default SendingListItem
