import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import usePlanLimits from '../hooks/usePlanLimits/usePlanLimits'
import { PlanResource } from '../providers/PlanLimitsProvider/PlanLimitsContext'

type CheckLimitButtonProps = {
  planResource: PlanResource
  hidden?: boolean
  onLimitAvailable(): void
}

export const CheckLimitButton: React.FC<CheckLimitButtonProps> = ({ planResource, hidden, onLimitAvailable }) => {
  const { loadUsage, loading, checkHasAnalysisLimit, showLimitReachedModal } = usePlanLimits()

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await loadUsage()
    if (!checkHasAnalysisLimit()) {
      showLimitReachedModal(planResource)
      return
    }
    onLimitAvailable()
  }

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <LoadingButton
        variant="contained"
        loading={loading}
        sx={{
          height: 40
        }}
        onClick={handleClick}
        startIcon={<Add />}
      >
        Criar
      </LoadingButton>
    </div>
  )
}
