/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils'
// eslint-disable-next-line import/no-duplicates
import 'devexpress-reporting/dx-reportdesigner'
// eslint-disable-next-line import/no-duplicates
import { ActionId, JSReportDesigner } from 'devexpress-reporting/dx-reportdesigner'
import { ActionId as WebViewerActionId } from 'devexpress-reporting/dx-webdocumentviewer'
import ko from 'knockout'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import { useLocaleDetector } from '../../hooks'
import './ReportDesigner.css'

const requestOptions = {
  getDesignerModelAction: '/report-designer-setup',
  host: BASE_URL,
  invokeAction: 'DXXRDV'
}

interface ReportDesignerProps {
  reportUrl: string | number
  startInPreviewMode?: boolean
  allowExport: boolean
}

export interface ReportDesignerRef {
  designerControl: JSReportDesigner | undefined
  showPreview: () => void
  showDesign: () => void
}

const getAuthHeader = () => {
  return { Authorization: 'Bearer ' + localStorage.getItem('token') }
}

let showPreview: () => void
let showDesign: () => void
const ReportDesigner = React.forwardRef<ReportDesignerRef, ReportDesignerProps>(
  ({ reportUrl, startInPreviewMode = false, allowExport }, ref) => {
    const reportRef = useRef<HTMLDivElement>(null)
    const [designerControl, setDesignerControl] = React.useState<JSReportDesigner>()
    const isFirstMount = useRef(true)
    const navigate = useNavigate()
    const currentLocale = useLocaleDetector()

    useImperativeHandle(ref, () => ({
      designerControl: designerControl,
      showPreview,
      showDesign
    }))

    useEffect(() => {
      const koReportUrl = ko.observable(reportUrl)
      const exportEnabled = ko.observable(allowExport)
      const insideReportRef = reportRef.current
      ajaxSetup.ajaxSettings = { headers: getAuthHeader() }
      const callbacks = {
        CustomizeElements: function (s: any, e: any) {
          const menuButton = e.GetById('dxrd-menubutton-template')
          const index = e.Elements.indexOf(menuButton)
          e.Elements.splice(index, 1)
        },
        BeforeRender: (s: JSReportDesigner) => {
          setDesignerControl(s)
        },
        CustomizeLocalization: function (s: any, e: any) {
          e.LoadMessages(require(`../../locales/dx-analytics-core.${currentLocale}.json`))
          e.LoadMessages(require(`../../locales/dx-reporting.${currentLocale}.json`))
        },
        ReportOpened: (s: JSReportDesigner) => {
          if (isFirstMount.current && startInPreviewMode) {
            s.ShowPreview()
          }
          isFirstMount.current = false
        },
        ReportTabClosed: (s: any, e: any) => {
          if (s.designerModel.getTabs().length === 0) {
            navigate(PATHS.HOME)
          }
        },
        PreviewOnExport: (s: any, e: any) => {
          e.FormData['access_token'] = localStorage.getItem('token')
        },
        CustomizeMenuActions: (s: any, e: any) => {
          const fullScreenAct = e.GetById(ActionId.FullScreen)
          if (fullScreenAct) {
            fullScreenAct.visible = false
          }
          const previewAct = e.GetById(ActionId.Preview)
          if (previewAct) {
            var previewActionClick = previewAct.clickAction
            showPreview = function () {
              previewActionClick()
            }
            previewAct.clickAction = showPreview
            previewAct.visible = false
          }
        },
        PreviewCustomizeMenuActions: (s: any, e: any) => {
          const designAction = e.GetById(WebViewerActionId.Design)
          if (designAction) {
            var designActionClick = designAction.clickAction
            showDesign = function () {
              designActionClick()
            }
            designAction.clickAction = showDesign
            designAction.visible = false
          }
          const exportAction = e.GetById(WebViewerActionId.ExportTo)
          if (exportAction) {
            exportAction.visible = exportEnabled
          }
        }
      }
      ko.applyBindings(
        {
          reportUrl: koReportUrl,
          requestOptions: requestOptions,
          callbacks: callbacks,
          useAsynchronousExport: false
        },
        insideReportRef
      )
      return () => {
        ko.cleanNode(insideReportRef!)
      }
    }, [navigate, reportUrl, startInPreviewMode, currentLocale, allowExport])

    return <div ref={reportRef} data-bind="dxReportDesigner: $data"></div>
  }
)

export default ReportDesigner
