import { AxiosResponse } from 'axios'
import { Template } from '../../components/TemplateViewer/TemplateViewer'
import { AnalyzeType } from '../../schemas/AnalyzeSchema'
import { PlugType } from '../../schemas/PlugSchema'
import api from '../api'

const getByAnalyzeTypeAndPlugType = (
  plugType: PlugType,
  analyzeType: AnalyzeType
): Promise<AxiosResponse<Template[]>> => {
  return api.get(`/templates?plugType=${plugType}&analyzeType=${analyzeType}`)
}
const get = (name: string, plugType: PlugType, analyzeType: AnalyzeType): Promise<AxiosResponse<Template[]>> => {
  return api.get(`/templates?plugType=${plugType}&analyzeType=${analyzeType}&name=${name}`)
}

export default {
  getByAnalyzeTypeAndPlugType,
  get
}
