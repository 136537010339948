import { ArrowDropDown, Search } from '@mui/icons-material'
import {
  ClickAwayListener,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { IFieldProps } from 'devextreme-react/filter-builder'
import React, { PropsWithChildren, useRef, useState } from 'react'
import SelectBox from '../../components/SelectBox'
import { ParameterSchema } from '../../schemas/ParameterSchema'
import { enumToArray } from '../../utils/enumUtils'
import { ConditionValue, ConditionValueType } from './types'

export interface SelectBoxFilterFieldProps {
  data: {
    field: IFieldProps
    filterOperation: string
    setValue(value: any): void
    value: ConditionValue | ConditionValue[] | null
  }
  parameters: ParameterSchema[]
  fieldValues: Map<string, any[]>
  onSearchClick: (fieldName: string) => void
  loading?: boolean
  multiple?: boolean
  disableSearch?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 40
  },
  input: {
    fontSize: '19px',
    padding: '2px 7px 3px',
    height: 29,
    '& fieldset': {
      display: 'none',
      padding: 0,
      margin: 0
    }
  }
}))

const checkIsParamterSchema = (value: any): value is ParameterSchema => {
  return (value as ParameterSchema).name !== undefined
}

export function transformToValueDefinition(value: any, isMultiple?: boolean): ConditionValue | ConditionValue[] {
  if (isMultiple) {
    const values: ConditionValue[] = []
    for (const val of value) {
      values.push(transformToValueDefinition(val, false) as ConditionValue)
    }
    return values
  }
  if (value === undefined || value === null) return { value: '', type: ConditionValueType.values }
  if (checkIsParamterSchema(value)) {
    return {
      value: value.name,
      id: value.id,
      type: ConditionValueType.parameters
    }
  }
  if (typeof value === 'string') {
    return {
      value: value,
      type: ConditionValueType.values
    }
  }
  return value
}

export const SelectDisplayValueTypeAdorment: React.FC<
  PropsWithChildren<{
    selectedDisplayValue?: ConditionValueType
    onChange?(value: ConditionValueType): void
  }>
> = ({ selectedDisplayValue, onChange }) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, value: ConditionValueType) => {
    onChange?.(value || ConditionValueType.values)
    setAnchorEl(null)
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <InputAdornment position="end">
      <IconButton
        style={{ color: theme.palette.text.primary }}
        aria-label="toggle value display"
        onClick={handleOpenMenu}
        edge="end"
        size="large"
      >
        <ArrowDropDown />
      </IconButton>
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={Boolean(anchorEl)} id="menu-list-grow">
                  {enumToArray(ConditionValueType).map((option) => (
                    <MenuItem
                      key={option}
                      disabled={option === selectedDisplayValue}
                      selected={option === selectedDisplayValue}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </InputAdornment>
  )
}
const SelectBoxFilterField: React.FC<PropsWithChildren<SelectBoxFilterFieldProps>> = ({
  data: { value, setValue, field },
  parameters,
  fieldValues,
  onSearchClick,
  loading,
  disableSearch,
  multiple
}) => {
  const classes = useStyles()
  const [selectedDisplayOption, setSelectedDisplayOption] = useState<ConditionValueType>(ConditionValueType.values)
  const genName = useRef<string>('gen-name' + Math.random())

  const isFreeSolo = selectedDisplayOption === ConditionValueType.values

  const handleChange = (e: any, newValue: any) => {
    setValue(transformToValueDefinition(newValue, multiple))
  }

  return (
    <SelectBox
      fullWidth={false}
      className={classes.root}
      multiple={multiple}
      loading={loading}
      options={
        !isFreeSolo
          ? parameters?.map((val) => transformToValueDefinition(val, false) as ConditionValue) ?? []
          : fieldValues?.get(field.dataField!)?.map((x) => transformToValueDefinition(x, false) as ConditionValue) ?? []
      }
      getOptionLabel={(opt) => (opt as any)?.value || ''}
      freeSolo={isFreeSolo}
      size="small"
      disablePortal
      value={value}
      onChange={handleChange}
      onInputChange={isFreeSolo ? handleChange : undefined}
      textFieldProps={{
        InputProps: {
          className: classes.input,
          endAdornment: (
            <>
              {field.dataType === 'string' && !disableSearch && (
                <IconButton onClick={() => onSearchClick(field.dataField!)} size="large">
                  <Search />
                </IconButton>
              )}
              <SelectDisplayValueTypeAdorment
                selectedDisplayValue={selectedDisplayOption}
                onChange={(opt) => setSelectedDisplayOption(opt)}
              />
            </>
          )
        },
        placeholder: '<enter value>',
        name: genName.current,
        fullWidth: false
      }}
    />
  )
}

export default SelectBoxFilterField
