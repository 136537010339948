import { DialogActions as MuiDialogActions, DialogActionsProps as MuiDialogActionsProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

type DialogActionsProps = MuiDialogActionsProps

const useStyles = makeStyles(() => ({
  spaced: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

const DialogActions: React.FC<PropsWithChildren<DialogActionsProps>> = ({ children, className, ...rest }) => {
  const classes = useStyles()
  const moreThanOneChild = React.Children.toArray(children).length > 1
  return (
    <MuiDialogActions
      className={clsx({ [classes.spaced]: moreThanOneChild, [classes.flexEnd]: !moreThanOneChild }, className)}
      {...rest}
    >
      {children}
    </MuiDialogActions>
  )
}

export default DialogActions
