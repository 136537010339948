import { Close } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import AnalyzeIcon, { AnalyzeIconTypes } from '../../components/AnalyzeIcon'
import ListItem from '../../components/ListItem'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'

interface AddedAnalyzeToCollectionListItemProps {
  item: AnalyzeSchema
  onCloseClick?(item: AnalyzeSchema): void
  className?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: 0
  }
}))

const AddedAnalyzeToCollectionListItem: React.FC<PropsWithChildren<AddedAnalyzeToCollectionListItemProps>> = ({
  item,
  onCloseClick,
  className
}) => {
  const classes = useStyles()
  return (
    <ListItem
      className={clsx(classes.root, className)}
      ContainerComponent="div"
      primaryTextString={item.name}
      primaryText={item.name}
      startIcon={<AnalyzeIcon type={item.type as AnalyzeIconTypes} color="secondary" />}
      onEdit={() => onCloseClick?.(item)}
      itemId={item.id}
      endIcon={onCloseClick && Close}
    />
  )
}

export default AddedAnalyzeToCollectionListItem
