import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { AvailablePlugs } from '../../pages/plugs/AvailablePlugs'
import { PlugType } from '../../schemas/PlugSchema'

export interface PlugIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  type: PlugType
}

const useStyles = makeStyles({
  root: {
    width: '36px',
    height: '48px'
  }
})

const PlugIcon: React.FC<PropsWithChildren<PlugIconProps>> = ({ type, className, src, ...props }) => {
  const classes = useStyles()

  const plugImageSrc = AvailablePlugs.find((plug) => plug.type === type)?.image

  return <img src={plugImageSrc} alt={type} className={clsx(classes.root, className)} />
}

export default PlugIcon
