import { WordSelection } from './types'

export const getSelectionRange = (): Range | null => {
  const selection = window.getSelection()
  if (!selection || selection.rangeCount === 0) return null
  return selection.getRangeAt(0)
}

export const getSelectionCoords = (selectionRange: Range): { offsetLeft: number; offsetTop: number } => {
  const range = selectionRange.cloneRange()
  if (range.endOffset === 0) {
    range.setEnd(range.endContainer, 1)
  }
  const offsetTop = range.getBoundingClientRect().top + 6
  const offsetLeft = range.getBoundingClientRect().left + 6

  return { offsetLeft, offsetTop }
}

export const getWordBounds = (text: string, start: number): WordSelection => {
  if (text[start] === ' ') {
    // end of word
    start = start - 1
  }
  let backwardCharCount = 0
  let pos = start - 1
  while (text[pos] !== ' ' && text[pos] !== '(' && pos >= 0) {
    backwardCharCount++
    pos--
  }
  let forwardCharCount = 0
  pos = start
  while (text[pos] !== ' ' && text[pos] !== ')' && pos < text.length) {
    forwardCharCount++
    pos++
  }
  const beginIndex = start - backwardCharCount
  const endIndex = start + forwardCharCount
  return {
    word: text.slice(beginIndex, endIndex),
    begin: beginIndex,
    end: endIndex
  }
}

export const clearSelection = (): void => {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      // Chrome
      window.getSelection()?.empty()
    } else if (window.getSelection()?.removeAllRanges) {
      // Firefox
      window.getSelection()?.removeAllRanges()
    }
  }
}
