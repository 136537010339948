import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack
} from '@mui/material'
import Axios from 'axios'
import PivotGrid from 'devextreme-react/pivot-grid'
import saveAs from 'file-saver'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../components/Dialog'
import TextField from '../../../components/TextField'
import { BASE_URL, ENDPOINTS } from '../../../constants/apiEndpoints'
import i18n from '../../../locales/i18next'
import { exportPivotToBuffer } from '../dataSource'
import {
  ComponentExtensionOption,
  ComponentWithDialogExtension,
  DialogExtensionComponentProps,
  PageOrientation,
  PaperKind,
  PivotGridPdfExportOptions,
  ScaleMode
} from '../types'

type ExportToPdfDialogProps = DialogExtensionComponentProps & {
  pivotGrid: PivotGrid | null
}

export const id = 'ExportToPdfFileExtension'

class ExportToPdfFileExtension implements ComponentWithDialogExtension {
  name = id
  icon = 'pdffile'
  text = i18n.t('pivotGrid.extensions.exportToPdfFile.text')
  onItemClick: () => void
  showDialog: ((id: string) => void) | null
  static discriminator: string

  constructor(showDialogImp: (id: string) => void, eraseField: () => void) {
    this.showDialog = showDialogImp
    this.onItemClick = () => {
      eraseField()
      this.showDialog!(id)
    }
  }
  items?: ComponentExtensionOption[] | undefined
}

const defaultPdfExportOptions = {
  fileName: '',
  pageOrientation: PageOrientation.Default,
  paperKind: PaperKind.Letter
}

const getClient = () => {
  const client = Axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data',
      Accept: 'application/pdf'
    },
    transformRequest: [],
    transformResponse: []
  })
  client.defaults.transformRequest = []
  client.defaults.transformResponse = []
  client.defaults.headers.common = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/pdf'
  }

  return client
}

export const ExportToPdfDialog: React.FC<PropsWithChildren<ExportToPdfDialogProps>> = ({
  open,
  onClose,
  pivotGrid,
  field
}) => {
  const [pdfExportOptions, setPdfExportOptions] = useState<PivotGridPdfExportOptions>(defaultPdfExportOptions)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const resetState = () => {
    setPdfExportOptions(defaultPdfExportOptions)
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      setPdfExportOptions((prev) => ({
        ...prev,
        fileName: field?.caption || ''
      }))
    } else {
      resetState()
    }
  }, [field, open])

  const handleExport = async () => {
    const buffer = await exportPivotToBuffer(pdfExportOptions.fileName, pivotGrid!.instance)
    const client = getClient()

    const formData = new FormData()
    formData.append(
      'file',
      new Blob([buffer], { type: 'application/octet-stream' }),
      pdfExportOptions.fileName + '.xlsx'
    )
    for (let key in pdfExportOptions) {
      formData.append(`ExportOptions.${key}`, (pdfExportOptions as any)[key])
    }
    setLoading(true)
    client
      .post(BASE_URL + ENDPOINTS.EXPORT_PIVOT_GRID_TO_PDF, formData, {
        responseType: 'blob'
      })
      .then((response) => {
        saveAs(new Blob([response.data], { type: 'application/pdf' }), pdfExportOptions.fileName + '.pdf')
      })
      .catch((err) => {
        enqueueSnackbar('Error Download Pdf ' + err.message, { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  return (
    <Dialog
      onClose={onClose}
      removeTitle={false}
      open={open}
      title={t('pivotGrid.menuSettings.modalExportPdf.Title')}
      description={t('pivotGrid.menuSettings.modalExportPdf.subtitle')}
    >
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            sx={{ marginTop: '6px' }}
            label={t('pivotgrid.exportpdf.fileName.title')}
            onChange={(e) => setPdfExportOptions((prev) => ({ ...prev, fileName: e.target.value }))}
            value={pdfExportOptions.fileName}
          />
          <FormControl>
            <FormLabel id="page-layout-radio-label">{t('pivotgrid.exportpdf.pageOrientation.title')}</FormLabel>
            <RadioGroup
              aria-labelledby="page-layout-radio-group"
              value={pdfExportOptions.pageOrientation}
              onChange={(e) => setPdfExportOptions((prev) => ({ ...prev, pageOrientation: +e.target.value }))}
            >
              <FormControlLabel
                value={PageOrientation.Portrait}
                control={<Radio />}
                label={t('pivotgrid.exportpdf.pageOrientation.portrait')}
              />
              <FormControlLabel
                value={PageOrientation.Landscape}
                control={<Radio />}
                label={t('pivotgrid.exportpdf.pageOrientation.landscape')}
              />
              <FormControlLabel
                value={PageOrientation.Default}
                control={<Radio />}
                label={t('pivotgrid.exportpdf.pageOrientation.default')}
              />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>{t('pivotgrid.exportpdf.paperKind.title')}</InputLabel>
            <Select
              value={pdfExportOptions.paperKind}
              label={t('pivotgrid.exportpdf.paperKind.title')}
              onChange={(e) => setPdfExportOptions((prev) => ({ ...prev, paperKind: +e.target.value }))}
            >
              <MenuItem value={PaperKind.Letter}>{t('pivotgrid.exportpdf.paperKind.letter')}</MenuItem>
              <MenuItem value={PaperKind.Legal}>{t('pivotgrid.exportpdf.paperKind.legal')}</MenuItem>
              <MenuItem value={PaperKind.Executive}>{t('pivotgrid.exportpdf.paperKind.executive')}</MenuItem>
              <MenuItem value={PaperKind.A5}>{t('pivotgrid.exportpdf.paperKind.a5')}</MenuItem>
              <MenuItem value={PaperKind.A4}>{t('pivotgrid.exportpdf.paperKind.a4')}</MenuItem>
              <MenuItem value={PaperKind.A3}>{t('pivotgrid.exportpdf.paperKind.a3')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>{t('pivotgrid.exportpdf.scaleMode.title')}</InputLabel>
            <Select
              disabled={pdfExportOptions.pageOrientation === PageOrientation.Default}
              value={pdfExportOptions.scaleMode}
              label={t('pivotgrid.exportpdf.scaleMode.title')}
              onChange={(e) => setPdfExportOptions((prev) => ({ ...prev, scaleMode: +e.target.value }))}
            >
              <MenuItem value={ScaleMode.None}>{t('pivotgrid.exportpdf.scaleMode.none')}</MenuItem>
              <MenuItem value={ScaleMode.UseScaleFactor}>{t('pivotgrid.exportpdf.scaleMode.useScaleFactor')}</MenuItem>
              <MenuItem value={ScaleMode.AutoFitToPageWidth}>
                {t('pivotgrid.exportpdf.scaleMode.autoFitToPagesWidth')}
              </MenuItem>
            </Select>
          </FormControl>
          {pdfExportOptions.scaleMode === ScaleMode.UseScaleFactor && (
            <TextField
              type="number"
              defaultValue={1}
              disabled={pdfExportOptions.pageOrientation === PageOrientation.Default}
              label={t('pivotgrid.exportpdf.scaleFactor.title')}
              onChange={console.log}
              value={pdfExportOptions.scaleFactor}
            />
          )}
          {pdfExportOptions.scaleMode === ScaleMode.AutoFitToPageWidth && (
            <TextField
              defaultValue={1}
              type="number"
              disabled={pdfExportOptions.pageOrientation === PageOrientation.Default}
              label={t('pivotgrid.exportpdf.autoFitPageCount.title')}
              onChange={console.log}
              value={pdfExportOptions.autoFitPageCount}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetState} label={t('pivotGrid.menuSettings.exportpdf.btnReset')} color="inherit" />
        <Button
          loading={loading}
          onClick={handleExport}
          label={t('pivotGrid.menuSettings.exportpdf.btnExport')}
          color="primary"
          sx={{ marginLeft: 'auto!important' }}
        />
        <Button onClick={onClose} label={t('pivotGrid.menuSettings.exportpdf.btnCancel')} color="inherit" />
      </DialogActions>
    </Dialog>
  )
}

ExportToPdfFileExtension.discriminator = 'DialogExtension'

export default ExportToPdfFileExtension
