import { Autocomplete, AutocompleteProps, CircularProgress, TextFieldProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import TextField from '../../components/TextField'

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary
  }
}))

export interface SelectBoxProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  textFieldProps: TextFieldProps
  name?: string
}

const SelectBox = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  className,
  textFieldProps: { InputProps, ...restTextFieldProps },
  loading,
  ...props
}: SelectBoxProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const classes = useStyles()
  return (
    <Autocomplete
      loadingText="Loading..."
      loading={loading}
      className={className}
      classes={{ noOptions: classes.textPrimary, loading: classes.textPrimary }}
      {...props}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment,
            ...InputProps
          }}
          {...restTextFieldProps}
        />
      )}
    />
  )
}
export default SelectBox
