import { ExpandLess, ExpandMore, Lock } from '@mui/icons-material'
import { AppBar, Box, Button, Collapse, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import PluggerLogoTransp from '../../components/PluggerLogoTransp'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'

export const UnauthorizedPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const unauthorizedResources = (location?.state?.UnauthorizedResources as any[]) || []
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const getResourceDetails = (item: any) => {
    if (item.Resource.Type === 'Plug') {
      return `${t('pages.unauthorized.pluPrefix')} ${item.Resource.Name}`
    }
    return `${t('pages.unauthorized.pluFieldPrefix')} ${item.Resource.Name} - ${t('pages.unauthorized.pluPrefix')} ${
      item.RelatedResource.Name
    }`
  }

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar sx={{ bgcolor: '#605f5f' }} position="static">
        <PluggerLogoTransp
          src={ENDPOINTS.PUBLIC_IMAGES + '/logo_bg_transp.png'}
          style={{ width: 80, height: 'auto', padding: '0 0 0 17px' }}
        />
      </AppBar>
      <Stack p={2} alignItems="center" spacing={2}>
        <Typography fontWeight="bold" variant="h1" color="primary">
          <Lock fontSize="large" />
        </Typography>
        <Typography fontWeight="bold" variant="h2" color="primary">
          {t('pages.unauthorized.tittle.accessDenied')}
        </Typography>
        <Typography variant="caption" fontSize={18}>
          {t('pages.unauthorized.caption.dontHaveAccess')}
        </Typography>
        <Typography variant="caption" fontSize={18}>
          {t('pages.unauthorized.caption.contact')}
        </Typography>
        <Button variant="contained" onClick={() => navigate(PATHS.HOME)}>
          {t('pages.unauthorized.btn.goHome')}
        </Button>
        <Typography variant="caption" fontSize={14}>
          {t('pages.unauthorized.checkResources')}
        </Typography>
        <Box>
          <List>
            <ListItem onClick={() => setOpen((prev) => !prev)}>
              <ListItemText primary="Unauthorized Resources" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List>
                {unauthorizedResources?.map((item) => (
                  <ListItem>
                    <ListItemText primary={getResourceDetails(item)} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List>
        </Box>
      </Stack>
    </Box>
  )
}
