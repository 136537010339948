import i18n from '../../../locales/i18next'
import { ValueOf } from '../../../utils/typeUtils'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

class AggregateExtension implements ComponentExtension {
  name: string
  icon: string
  text: string
  items?: ComponentExtensionOption[] | undefined

  constructor(component: PivotGridComponent, field: PivotGridField) {
    this.name = 'AggregateExtension'
    this.icon = 'datafield'
    this.text = i18n.t('pivotgrid.contextMenu.Aggregation')

    const subOptions = [
      {
        text: i18n.t('pivotgrid.contextMenu.Aggregation.SUM'),
        onItemClick: () => transformDefaultSummaryTypes(field, summaryTypeOptions.SUM, component),
        disabled: field.summaryType === summaryTypeOptions.SUM || field.dataType !== 'number',
        selected: field.summaryType === summaryTypeOptions.SUM
      },
      {
        text: i18n.t('pivotgrid.contextMenu.Aggregation.MIN'),
        onItemClick: () => transformDefaultSummaryTypes(field, summaryTypeOptions.MIN, component),
        disabled: field.summaryType === summaryTypeOptions.MIN || field.dataType !== 'number',
        selected: field.summaryType === summaryTypeOptions.MIN
      },
      {
        text: i18n.t('pivotgrid.contextMenu.Aggregation.MAX'),
        onItemClick: () => transformDefaultSummaryTypes(field, summaryTypeOptions.MAX, component),
        disabled: field.summaryType === summaryTypeOptions.MAX || field.dataType !== 'number',
        selected: field.summaryType === summaryTypeOptions.MAX
      },
      {
        text: i18n.t('pivotgrid.contextMenu.Aggregation.AVG'),
        onItemClick: () => transformDefaultSummaryTypes(field, summaryTypeOptions.AVG, component),
        disabled: field.summaryType === summaryTypeOptions.AVG || field.dataType !== 'number',
        selected: field.summaryType === summaryTypeOptions.AVG
      },
      {
        text: i18n.t('pivotgrid.contextMenu.Aggregation.COUNT'),
        onItemClick: () => transformDefaultSummaryTypes(field, summaryTypeOptions.COUNT, component),
        disabled: field.summaryType === summaryTypeOptions.COUNT,
        selected: field.summaryType === summaryTypeOptions.COUNT
      },
      {
        text: i18n.t('pivotgrid.contextMenu.Aggregation.COUNT_DISTINCT'),
        onItemClick: () => transformDefaultSummaryTypes(field, summaryTypeOptions.COUNT_DISTINCT, component),
        disabled: field.summaryType === summaryTypeOptions.COUNT_DISTINCT,
        selected: field.summaryType === summaryTypeOptions.COUNT_DISTINCT
      }
    ]

    this.items = subOptions
  }
}

const transformDefaultSummaryTypes = (
  field: PivotGridField,
  summaryType: ValueOf<typeof summaryTypeOptions>,
  component: PivotGridComponent
) => {
  const dataSource = component.getDataSource()
  const fields = dataSource.fields()
  const newField = Object.assign({}, field)
  newField.summaryType = summaryType
  newField.name = `${field.name} ${summaryType}`
  newField.caption = `${field.caption} (${summaryType.toUpperCase()})`
  newField.areaIndex = field.areaIndex! + 1
  newField.index = fields.length
  newField.canBeRemoved = true
  fields.push(newField)
  dataSource.fields(fields)
  dataSource.load()
}

export const summaryTypeOptions = {
  SUM: 'sum',
  MIN: 'min',
  MAX: 'max',
  AVG: 'avg',
  COUNT: 'count',
  COUNT_DISTINCT: 'countDistinct'
}

export default AggregateExtension
