import { Box, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Button from '../../../../../components/Button'
import Form, { FormProps } from '../../../../../components/Form'
import OutlinedTextField from '../../../../../components/Form/OutlinedTextField'
import { DatabaseConnectionSchema } from '../../../../../schemas/ConnectionSchema'
import { GoogleScope } from '../../../../../services/google/types'
import { useGoogle } from '../../../../../services/google/useGoogle'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    minWidth: 350
  },
  gridItemSpan2: {
    gridColumn: '1 / span 2'
  },
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#d62b2b'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  whiteButton: {
    color: 'white'
  },
  selectAccountBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'white'
  }
}))

type GoogleConnectionFormProps = Partial<FormProps<Omit<DatabaseConnectionSchema, 'type'>>> & {
  variant?: 'white' | 'default'
  usePlaceholder?: boolean
  onCancelClick: () => void
  isUpdating?: boolean
  scopes: GoogleScope[]
}

const validationSchema = yup.object({
  name: yup.string().required()
})

const updateValidationSchema = yup.object({
  name: yup.string().required()
})

const defaultInitialValues = {
  name: ''
}

const GoogleConnectionForm: React.FC<PropsWithChildren<GoogleConnectionFormProps>> = ({
  className,
  variant = 'default',
  usePlaceholder,
  initialValues,
  isUpdating,
  onCancelClick,
  onSubmit,
  scopes,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { authorize, isAuthorized, isLoading, encryptedTokenResponse, userInfo, error } = useGoogle({
    scopes: scopes
  })

  const [submitError, setSubmitError] = useState<string | null>(null)

  const handleCreateConnection = async (values: any) => {
    if (!isAuthorized) {
      setSubmitError(t('pagePlugs.modalCreate.createConnection.error.notAuthorized'))
      return
    }
    if (error) {
      return
    }
    values.encryptedTokenResponse = encryptedTokenResponse
    onSubmit?.(values)
  }

  return (
    <Form
      isSubmitting={rest.isSubmitting}
      initialTouched
      className={clsx(classes.root, className)}
      initialValues={initialValues || defaultInitialValues}
      validationSchema={isUpdating ? updateValidationSchema : validationSchema}
      onSubmit={handleCreateConnection}
    >
      <OutlinedTextField
        required
        fullWidth
        className={clsx(classes.gridItemSpan2, { [classes.outlinedWhiteInput]: variant === 'white' })}
        name="name"
        label={!usePlaceholder ? 'Name' : undefined}
        placeholder={usePlaceholder ? 'Connection Name*' : undefined}
      />
      <Paper
        sx={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          height: 52,
          alignItems: 'center',
          bgcolor: 'white'
        }}
        elevation={3}
        className={classes.gridItemSpan2}
        onClick={() => authorize()}
      >
        <Box flex="0.4 1 0" display="flex" justifyContent="center" alignItems="center">
          <img
            style={{
              alignSelf: 'start',
              width: 28
            }}
            src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
            alt="google G"
          />
        </Box>
        <Typography flex="2 2 0" textAlign="center" color="GrayText">
          {isAuthorized && !isLoading ? userInfo.email : 'Sign In With Google'}
        </Typography>
      </Paper>
      {(error || submitError) && <Typography color="error">{error || submitError}</Typography>}
      <Box display="flex" justifyContent="space-between" className={classes.gridItemSpan2}>
        <Button
          className={classes.whiteButton}
          variant="text"
          label={t('pagePlugs.modalCreate.createConnection.btnCancel')}
          onClick={onCancelClick}
        />
        <Button
          type="submit"
          className={classes.whiteButton}
          variant="text"
          label={t('pagePlugs.modalCreate.createConnection.btnSave')}
        />
      </Box>
    </Form>
  )
}

export default GoogleConnectionForm
