import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { ConnectionSchema, CreateConnectionSchema, UpdateConnectionSchema } from '../../schemas/ConnectionSchema'
import { AvailableConnectionSchema, PlugFieldSchema } from '../../schemas/PlugSchema'
import { format } from '../../utils/stringUtils'
import api from '../api'
import { GoogleUserInfo } from '../google/types'

const getAll = (): Promise<AxiosResponse<ConnectionSchema[]>> => {
  return api.get(ENDPOINTS.CONNECTIONS_GET_ALL)
}

const getById = (id: number, includeSettings?: boolean): Promise<AxiosResponse<ConnectionSchema>> => {
  let url = ENDPOINTS.CONNECTIONS_GET_ALL + '/' + id.toString()
  if (includeSettings) {
    url += '?includeSettings=true'
  }
  return api.get(url)
}

const getTableAndViewNames = (connectionId: number): Promise<AxiosResponse<string[]>> => {
  return api.get(format(ENDPOINTS.CONNECTIONS_GET_TABLE_VIEW_NAMES, connectionId))
}

const getFields = (connectionId: number, tableOrView: string): Promise<AxiosResponse<PlugFieldSchema[]>> => {
  return api.post(ENDPOINTS.CONNECTIONS_DESCRIBE_FIELDS, { connectionId, tableOrView })
}

const create = (connection: CreateConnectionSchema): Promise<AxiosResponse<ConnectionSchema>> => {
  return api.post(ENDPOINTS.CONNECTIONS_CREATE, connection)
}

type CreateGoogleApisConnectionRequest = {
  name: string
  accessToken: string
  scopes: string[]
}

const createGoogleApisConnection = (req: CreateGoogleApisConnectionRequest) : Promise<AxiosResponse<ConnectionSchema>> => {
  return api.post(ENDPOINTS.CONNECTIONS_CREATE + '/google-apis', req)

}

const update = (connection: UpdateConnectionSchema): Promise<AxiosResponse<never>> => {
  return api.put(format(ENDPOINTS.CONNECTIONS_UPDATE, connection.id), connection)
}

const _delete = (connectionId: number): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.CONNECTIONS_DELETE, connectionId))
}

const getAvailables = (): Promise<AxiosResponse<AvailableConnectionSchema[]>> => {
  return api.get(ENDPOINTS.GET_AVAILABLE_CONNECTIONS)
}

const getGoogleUserInfo = (oAuth2Code: string) : Promise<AxiosResponse<GoogleUserInfo>> => {
  return api.get('/google-apis/user-info', { params: { oAuth2Code: oAuth2Code } })
}


export default { getAll, update, create, _delete, getTableAndViewNames, getFields, getAvailables, getById, createGoogleApisConnection, getGoogleUserInfo }
