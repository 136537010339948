import Draft from 'draft-js'

export enum KeywordType {
  Field = 'Field',
  Function = 'Function',
  Operator = 'Operator',
  Constant = 'Constant'
}

export enum KeywordGroup {
  Aggregate = 'Aggregate',
  Logic = 'Logic',
  NONE = 'none'
}

export interface TypeaheadState {
  offsetTop: number
  offsetLeft: number
  optionIndex: number
  word: string
  visible: boolean
}

export interface WordSelection {
  word: string
  begin: number
  end: number
}

export interface Keyword {
  name: string
  label?: string
  type: KeywordType
  description: string
  group: KeywordGroup
}

export type EditorRef = InstanceType<typeof Draft.Editor>
