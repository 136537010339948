import { Business } from '@mui/icons-material'
import { Tab, Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, Link as RouteLink, useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import RootBox from '../../components/RootBox'
import PATHS from '../../constants/paths'
import billingService from '../../services/billingService'

const useStyles = makeStyles((theme) => ({
  content: {
    // display: 'flex',
    // flexGrow: 3
  },
  tabs: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.light.default
  }
}))

const AccountPage: React.FC<PropsWithChildren> = () => {
  const classes = useStyles()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState<string>(location.pathname)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleRedirectToCustomerPortal = async () => {
    setLoading(true)
    try {
      const { data } = await billingService.createCustomerPortalSession()
      window.location.href = data.url
    } catch (err) {
      enqueueSnackbar(t('paymentProblemDialog.error'), { variant: 'error' })
    }
    setLoading(false)
  }

  return (
    <RootBox>
      <Header
        showFilterActions={false}
        hideSearchBar
        icon={Business}
        title={t('pageUserlogged.Settings.Title')}
        subtitle={t('pageUserlogged.Settings.Subtitle')}
      />
      <Box className={classes.content}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={(_e, value) => setSelectedTab(value)}
          indicatorColor="primary"
          variant="standard"
          scrollButtons="auto"
          centered
        >
          <Tab
            component={RouteLink}
            to={PATHS.ACCOUNT_GENERAL}
            value={PATHS.ACCOUNT_GENERAL}
            label={t('page.accounts.tab.general.label')}
          />
          <Tab
            component={RouteLink}
            to={PATHS.ACCOUNT_PLAN}
            value={PATHS.ACCOUNT_PLAN}
            label={t('page.accounts.tab.plan.label')}
          />
        </Tabs>
        <Outlet />
      </Box>
    </RootBox>
  )
}

export default AccountPage
