import PivotGrid from 'devextreme-react/pivot-grid'
import PivotGridFieldChooser from 'devextreme/ui/pivot_grid_field_chooser'
import TreeView from 'devextreme/ui/tree_view'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../components/Dialog'
import TextField from '../../../components/TextField'
import i18n from '../../../locales/i18next'
import { ComponentExtensionOption, ComponentWithDialogExtension, DialogExtensionComponentProps } from '../types'

export const id = 'RenameFieldExtension'

type RenameDialogProps = DialogExtensionComponentProps & {
  pivotGrid: PivotGrid | null
}

class RenameFieldExtension implements ComponentWithDialogExtension {
  name = id
  icon = 'rename'
  text = i18n.t('pivotGrid.extensions.rename.text')
  onItemClick: () => void
  showDialog: ((id: string) => void) | null
  static discriminator: string

  constructor(showDialogImp: (id: string) => void) {
    this.showDialog = showDialogImp
    this.onItemClick = () => {
      this.showDialog!(id)
    }
  }
  items?: ComponentExtensionOption[] | undefined
}
export const RenameFieldDialog: React.FC<PropsWithChildren<RenameDialogProps>> = ({
  open,
  dataSource,
  pivotGrid,
  field,
  onClose
}) => {
  const fieldCaptionProp = field?.caption
  const [fieldCaption, setFieldCaption] = useState(fieldCaptionProp)
  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      setFieldCaption(fieldCaptionProp)
    }
  }, [fieldCaptionProp, open])

  const handleChangeFieldCaptionInDataSource = () => {
    const dataSourceFields = dataSource!.fields()

    const fieldForUpd = dataSourceFields.find((x: any) => x.index === field!.index) as any
    const fieldForUpdIndex = dataSourceFields.findIndex((x: any) => x.index === field!.index)

    fieldForUpd!.caption = fieldCaption
    fieldForUpd!.renamed = true

    dataSourceFields.splice(fieldForUpdIndex, 1, fieldForUpd!)
    // skip load when field chooser are open
    if (field!.areaIndex !== null && field!.areaIndex !== undefined) {
      dataSource!.load()
    } else {
      const popupContent = pivotGrid!.instance.getFieldChooserPopup().content()
      const treeElem = popupContent.querySelector('.dx-treeview')
      if (treeElem) {
        const treeView = TreeView.getInstance(treeElem)
        const fieldChooser = PivotGridFieldChooser.getInstance(popupContent) as PivotGridFieldChooser
        if (treeView) {
          const items = treeView.option('items') as any[]
          const itemRenamedIndex = items.findIndex((x) => x.index === field!.index)
          items[itemRenamedIndex].key = field!.caption
          items[itemRenamedIndex].text = field!.caption
          items.splice(itemRenamedIndex, 1, items[itemRenamedIndex])
          treeView.option('items', items)
          const state = JSON.parse(JSON.stringify(fieldChooser.option('state')))
          fieldChooser.repaint()
          fieldChooser.option('state', state)
        }
      }
    }

    onClose()
  }

  return (
    <Dialog
      style={{ zIndex: 9999 }}
      onClose={onClose}
      removeTitle={false}
      open={open}
      title={t('pivotGrid.menuSettings.modalRename.Title')}
      description={t('pivotGrid.menuSettings.modalRename.subTitle')}
    >
      <DialogContent>
        <TextField
          style={{ marginTop: '6px' }}
          name="field-name"
          value={fieldCaption}
          label={t('pivotGrid.menuSettings.modalRename.label')}
          required
          onChange={(e) => setFieldCaption(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleChangeFieldCaptionInDataSource}
          label={t('pivotGrid.menuSettings.modalRename.btnSave')}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  )
}

RenameFieldExtension.discriminator = 'DialogExtension'

export default RenameFieldExtension
