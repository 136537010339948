import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}))

const RootBox: React.FC<PropsWithChildren<BoxProps>> = (props) => {
  const defaultClasses = useStyles()
  const { children, className, ...others } = props
  return (
    <Box className={clsx(defaultClasses.root, className)} {...others}>
      {children}
    </Box>
  )
}

export default RootBox
