import { ApiConnectionType, DatabaseConnectionType } from '../../schemas/ConnectionSchema'
import { PlugType } from '../../schemas/PlugSchema'
import { AvailableConnection } from '../connections/AvailableConnections'

export type AvailablePlug = {
  displayName: string
  type: PlugType
  image: string
  availableConnection?: AvailableConnection
}

export const AvailablePlugs: AvailablePlug[] = [
  {
    displayName: 'MySQL',
    type: PlugType.MySql,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fmysql.svg',
    availableConnection: {
      displayName: 'MySQL',
      type: DatabaseConnectionType.MySql,
      image:
        'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fmysql.svg'
    }
  },
  {
    displayName: 'SQL Server',
    type: PlugType.MsSql,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fsql-server.svg',
    availableConnection: {
      displayName: 'SQL Server',
      type: DatabaseConnectionType.MsSql,
      image:
        'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fsql-server.svg'
    }
  },
  {
    displayName: 'Firebird',
    type: PlugType.Firebird,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Ffirebird.jpg',
    availableConnection: {
      displayName: 'Firebird',
      type: DatabaseConnectionType.Firebird,
      image:
        'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Ffirebird.jpg'
    }
  },
  {
    displayName: 'Movidesk',
    type: PlugType.Movidesk,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fmovidesk.svg',
    availableConnection: {
      displayName: 'Movidesk',
      type: ApiConnectionType.Movidesk,
      image:
        'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fmovidesk.svg'
    }
  },
  {
    displayName: 'CSV File',
    type: PlugType.CsvFile,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fcsv-file.jpg'
  },
  {
    displayName: 'Google Sheets',
    type: PlugType.GoogleSheets,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fgoogle-sheets.svg'
  },
  {
    displayName: 'Google Analytics',
    type: PlugType.GoogleAnalytics,
    image:
      'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2FGAnalytics.svg'
  }
]
