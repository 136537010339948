import Axios, { AxiosResponse } from 'axios'

import { authInterceptor } from '../../services/api'

const api = Axios.create({
  transformRequest: [],
  transformResponse: []
})

api.interceptors.request.use(authInterceptor as any)

const get = (resourceUrl: string, authToken?: string): Promise<AxiosResponse<Blob>> => {
  if (!!authToken) {
    api.interceptors.request.clear()
    api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  }

  return api({
    url: resourceUrl,
    responseType: 'blob',
    method: 'GET'
  })
}

export default { get }
