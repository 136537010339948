import { Box, Fade, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import GridSelectable from '../../../../components/GridSelectable'
import { AvailablePlug } from '../../AvailablePlugs'
import NavigationHeader, { NavigationHeaderProps } from './NavigationHeader'

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  gridConnections: {
    width: '80%',
    margin: 'auto',
    padding: theme.spacing(5, 3, 3, 3),
    marginBottom: '80px'
  }
}))

type SelectPlugViewProps = {
  availablePlugs: AvailablePlug[]
  selected?: AvailablePlug
  onChangeSelection: (availablePlug: AvailablePlug) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const SelectPlugView: React.FC<PropsWithChildren<SelectPlugViewProps>> = ({
  availablePlugs,
  onChangeSelection,
  selected,
  navigationProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box>
      <NavigationHeader onClose={console.log} animate canAnimate={true} {...navigationProps} />
      <Fade timeout={{ enter: 1750, appear: 1750 }} in>
        <Box height="100%" color="white">
          <Typography variant="h2" align="center" component="div" color="inherit">
            {t('pagePlugs.modalCreate.title')}
          </Typography>
          <Typography variant="subtitle1" align="center" component="p" color="inherit">
            {t('pagePlugs.modalCreate.subTitle')}
          </Typography>
          <GridSelectable
            disableCredentials
            gap="35px"
            selectedItem={selected}
            onChangeSelection={(plug) => onChangeSelection(plug)}
            className={classes.gridConnections}
            searchProps={{
              className: classes.outlinedWhiteInput,
              variant: 'outlined'
            }}
            disableDynamicSize
            items={availablePlugs}
            itemKey="displayName"
          />
        </Box>
      </Fade>
    </Box>
  )
}

export default SelectPlugView
