import { Plugin } from '@devexpress/dx-react-core'
import {
  Column as DevColumn,
  EditingState,
  EditingStateProps,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  Sorting,
  SortingState
} from '@devexpress/dx-react-grid'
import {
  Table as DevTable,
  Grid,
  GridProps,
  TableHeaderRow,
  TableInlineCellEditing,
  TableSelection,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui'
import { Checkbox, CircularProgress, Paper, TableCell } from '@mui/material'
import { Stack } from '@mui/system'
import React, { PropsWithChildren } from 'react'

export type Column<T> = DevColumn & { canEdit?: boolean; getIsEditableByRow?: (val: T) => boolean }

export interface TableProps extends Omit<GridProps, 'columns'> {
  className?: string
  selectedIds?: React.ReactText[]
  onSelectionChange?(selection: React.ReactText[]): void
  disableEditing?: boolean
  editingProps?: EditingStateProps
  loading?: boolean
  selectionDisabled?: boolean
  height?: string
  classes?: {
    table?: string
  }
  rowComponent?:
    | React.ComponentClass<DevTable.DataRowProps, any>
    | React.FunctionComponent<DevTable.DataRowProps>
    | undefined
  sorting?: {
    defaultState?: Sorting[]
    sortingConfig?: IntegratedSorting.ColumnExtension[]
  }
  columns: ReadonlyArray<Column<this['rows'][0]>>
}

export interface SelectionCellProps extends TableSelection.CellProps {
  row: {} & {
    selection?: {
      disabled?: boolean
    }
  }
}

export interface InlineCellEditingProps extends TableInlineCellEditing.CellProps {
  row: {} & {
    inlineCellEditing?: {
      editingEnabled: boolean
    }
  }
}

const TableSelectionCellBase = ({ selected, onToggle, row, ...restProps }: SelectionCellProps) => {
  return (
    <TableCell padding="checkbox" {...restProps}>
      <Checkbox
        color="primary"
        checked={row.selection?.disabled ? false : selected}
        disabled={row.selection?.disabled || false}
        onClick={(e) => {
          e.stopPropagation()
          onToggle()
        }}
      />
    </TableCell>
  )
}

const TableHeaderSelectionCellBase = ({
  allSelected,
  someSelected,
  onToggle,
  ...restProps
}: TableSelection.HeaderCellProps) => {
  return (
    <TableCell padding="checkbox" {...restProps}>
      <Checkbox
        color="primary"
        checked={allSelected}
        indeterminate={someSelected}
        onClick={(e) => {
          e.stopPropagation()
          onToggle()
        }}
      />
    </TableCell>
  )
}

const TableCellComponentBase = ({ onClick, ...rest }: DevTable.DataCellProps & any) => {
  let { canEdit, getIsEditableByRow } = rest.column
  let { row } = rest
  canEdit ??= true

  if (canEdit) {
    if ((getIsEditableByRow && getIsEditableByRow(row)) || true)
      return <VirtualTable.Cell onClick={onClick} {...rest} />
  }
  return <VirtualTable.Cell {...rest} />
}

const Table: React.FC<PropsWithChildren<TableProps>> = ({
  columns,
  rows,
  className,
  selectedIds,
  onSelectionChange,
  editingProps,
  loading,
  classes,
  rowComponent,
  sorting,
  children,
  height,
  selectionDisabled,
  disableEditing,
  ...props
}) => {
  return (
    <Paper className={className}>
      {loading ? (
        <Stack justifyContent="center" alignItems="center" py={2}>
          <CircularProgress />
        </Stack>
      ) : (
        <Grid columns={columns} rows={rows} {...props}>
          {children}
          <SortingState defaultSorting={sorting?.defaultState} />
          <IntegratedSorting columnExtensions={sorting?.sortingConfig} />
          <VirtualTable
            height={height}
            cellComponent={TableCellComponentBase}
            rowComponent={rowComponent || VirtualTable.Row}
          />
          <TableHeaderRow />
          {!disableEditing && (
            <Plugin>
              <EditingState {...(editingProps as any)} />
              <TableInlineCellEditing
                cellComponent={(cel: any) => {
                  if (cel.column.getIsEditableByRow && !cel.column.getIsEditableByRow(cel.row)) {
                    return <TableInlineCellEditing.Cell {...cel} editingEnabled={false} children={null} />
                  }
                  return <TableInlineCellEditing.Cell {...cel} />
                }}
                startEditAction="click"
                selectTextOnEditStart
              />
            </Plugin>
          )}
          {!selectionDisabled && (
            <Plugin>
              <SelectionState selection={selectedIds} onSelectionChange={onSelectionChange} />
              <IntegratedSelection />

              <TableSelection
                headerCellComponent={TableHeaderSelectionCellBase}
                cellComponent={TableSelectionCellBase}
                showSelectAll
              />
            </Plugin>
          )}
        </Grid>
      )}
    </Paper>
  )
}

export default Table
