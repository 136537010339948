import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Button from '../../components/Button'
import DialogTitleContent from '../../components/DialogTitleContent'
import SelectBox from '../../components/SelectBox'
import WarningContainer from '../../components/WarningContainer'
import PATHS from '../../constants/paths'
import { AnalyzeType } from '../../schemas/AnalyzeSchema'
import { PlugSchema } from '../../schemas/PlugSchema'
import templateService from '../../services/templates/templateService'
import { Template, TemplateViewer } from '../TemplateViewer/TemplateViewer'

interface CreateAnalyzeDialogProps extends DialogProps {
  description: string
  plugs: PlugSchema[]
  selectedAnalyzeType: AnalyzeType
}

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 2)
  },
  fields: {
    margin: theme.spacing(2, 0)
  }
}))

const TypePathMapping = {
  [AnalyzeType.PIVOTGRID]: PATHS.PIVOT_GRID,
  [AnalyzeType.DASHBOARD]: PATHS.DASHBOARD,
  [AnalyzeType.REPORT]: PATHS.REPORT,
  [AnalyzeType.GEOANALYSE]: PATHS.GEO_ANALYSIS,
  [AnalyzeType.COLLECTION]: ''
}

const CreateAnalyzeDialog: React.FC<PropsWithChildren<CreateAnalyzeDialogProps>> = (props) => {
  const classes = useStyles()
  const { title, description, onClose, plugs, selectedAnalyzeType, ...other } = props
  const [selectedPlug, setSelectedPlug] = useState<PlugSchema | null>(null)
  const [validation, setValidation] = useState<{ error: boolean; message?: string }>({ error: false })
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setSelectedPlug(null)
    onClose?.(event, reason)
  }

  useEffect(() => {
    const fetchTemplates = async () => {
      if (!selectedAnalyzeType || !selectedPlug) {
        return
      }
      const { data } = await templateService.getByAnalyzeTypeAndPlugType(selectedPlug.type, selectedAnalyzeType)
      setTemplates(data.sortBy((x) => x.order))
    }
    fetchTemplates()
  }, [selectedAnalyzeType, selectedPlug])

  const handleOpenNewAnalyze = () => {
    if (selectedPlug) {
      let parametrizedPath = generatePath(TypePathMapping[selectedAnalyzeType], {
        id: selectedPlug.id
      })
      if (!!selectedTemplate) {
        parametrizedPath = parametrizedPath.concat(`?template=${selectedTemplate.name}`)
      }
      navigate(parametrizedPath, { state: { hashCode: uuidv4() } })
      return
    }
    setValidation({
      error: true,
      message: t('pageHome.body.modalCreate.form.requiredPlug')!
    })
    return
  }

  const doesNotHavePlug = plugs.length === 0

  return (
    <Dialog onClose={handleClose} {...other}>
      <DialogTitle>
        <DialogTitleContent title={title} description={description} onClose={handleClose} />
      </DialogTitle>
      <DialogContent dividers>
        {doesNotHavePlug ? (
          <Typography>
            {t('pageHome.newAnalyzeDialog.nonePlugs.CreateNew')}{' '}
            <Link to={`${PATHS.PLUGS}?openCreateModal=true`}>Criar aqui</Link>
          </Typography>
        ) : (
          <>
            <SelectBox
              textFieldProps={{
                name: 'analyze-plug',
                label: t('pageHome.body.modalCreate.analysis.chooseplug'),
                placeholder: t('pageHome.body.modalCreate.analysis.form.placeholder')!
              }}
              className={classes.fields}
              multiple={false}
              value={selectedPlug}
              onChange={(e, value) => setSelectedPlug(value as PlugSchema | null)}
              options={plugs}
              getOptionLabel={(p) => p.name}
            />
            {validation.error && <WarningContainer message={validation.message || ''} relatedDependencies={[]} />}
            <Grow
              mountOnEnter
              unmountOnExit
              timeout={{
                enter: 800,
                exit: 500,
                appear: 500
              }}
              in={!!selectedPlug && templates.length > 0}
            >
              <Box>
                <TemplateViewer
                  templates={templates}
                  selected={selectedTemplate}
                  onSelect={(t) => setSelectedTemplate(t)}
                />
              </Box>
            </Grow>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          sx={{ display: doesNotHavePlug ? 'none' : 'block' }}
          onClick={handleOpenNewAnalyze}
          variant="contained"
          color="primary"
          label={t('pageHome.body.modalCreate.analysis.buttonGo')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default CreateAnalyzeDialog
