import { useState } from 'react'

interface ErrorState {
  error: boolean
  description?: string
}

const useErrorState = (
  initialState?: ErrorState | (() => ErrorState) | null
): [ErrorState | null, React.Dispatch<React.SetStateAction<ErrorState | null>>] => {
  return useState<ErrorState | null>(initialState || null)
}

export default useErrorState
