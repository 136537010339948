import { Box, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import chroma from 'chroma-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import PluggerLogoTransp from '../../components/PluggerLogoTransp'
import TextField from '../../components/TextField'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useErrorState from '../../hooks/useErrorState'
import { authService } from '../../services/index'

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100vh',
    overflow: 'auto',
    background: `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${
      theme.palette.background.light?.contrast || '#FFF'
    }`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    color: theme.palette.common.black,
    backgroundColor: '#39e991',
    height: '45px',
    width: '45%',
    marginBottom: 7,
    '&:hover': {
      backgroundColor: chroma('#39e991').darken().hex()
    }
  },
  buttonSecondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    height: '45px',
    width: '45%',
    marginBottom: 7,
    '&:hover': {
      backgroundColor: chroma(theme.palette.secondary.main).darken().hex()
    }
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      width: '60%',
      margin: '0 auto'
    },
    width: '20%'
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.light.contrast,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  typography: {
    color: theme.palette.common.white,
    marginBottom: 12
  }
}))

const StyledTextField = withStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3, 0),
      '& label.Mui-focused': {
        color: '#39e991'
      },
      '& .MuiFormLabel-root': {
        color: '#39e991'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.common.white
        },
        '&:hover fieldset': {
          borderColor: '#39e991'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#39e991'
        }
      },
      '& .MuiOutlinedInput-input': {
        color: theme.palette.common.white
      },
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-text-fill-color': 'white',
        boxShadow: '0 0 0 30px #343a44 inset'
      }
    }
  })
)(TextField)

const ResetPasswordPage: React.FC = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const searchParams = new URLSearchParams(window.location.search)
  const token = searchParams.get('token')
  const navigate = useNavigate()
  const [error, setError] = useErrorState()
  const [password, setPassword] = useState('')
  const [sendBag, setSendBag] = useState<{ submitted: boolean; succeeded: boolean; message?: string }>({
    submitted: false,
    succeeded: false
  })

  const { t } = useTranslation()

  const theme = useTheme()

  const handleResetPassword = async () => {
    const validation = yup.string().required().min(6)
    const isValid = validation.isValidSync(password)
    if (!isValid) {
      setError({
        error: true,
        description: 'password must be at least 6 digits'
      })
      return
    }
    if (!token) {
      setError({
        error: true,
        description: 'Empty token'
      })
      return
    }
    setLoading(true)
    try {
      await authService.resetPassword(token, password)
      setSendBag({
        submitted: true,
        succeeded: true
      })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errResponse = err.response?.data[0]
        if (errResponse?.errorCode === 'TokenExpired') {
          setSendBag({
            submitted: true,
            succeeded: false,
            message: 'Token expirado'
          })
          setLoading(false)
          return
        }
      }
      console.warn(err)
      setSendBag({
        submitted: true,
        succeeded: false
      })
    }
    setLoading(false)
  }

  return (
    <Box className={classes.box}>
      <PluggerLogoTransp src={ENDPOINTS.PUBLIC_IMAGES + '/logo_bg_transp.png'} className={classes.logo} />
      {loading ? (
        <Loading
          plugFill={theme.palette.text.secondary}
          loopFill={theme.palette.text.secondary}
          width="200px"
          height="80px"
        />
      ) : (
        <Box width={430}>
          {sendBag.submitted ? (
            sendBag.succeeded ? (
              <Paper className={classes.paper}>
                <Typography className={classes.typography}>Your password has been changed</Typography>
                <Button
                  className={classes.button}
                  label="go to sign in"
                  onClick={() => navigate(PATHS.SIGNIN, { replace: true })}
                />
              </Paper>
            ) : (
              <Paper className={classes.paper}>
                <Typography className={classes.typography}>
                  {sendBag.message || 'Something went wrong on change password'}
                </Typography>
              </Paper>
            )
          ) : (
            <>
              <StyledTextField
                label="New Password"
                name="newPassword"
                error={error?.error}
                helperText={error?.description}
                value={password}
                type="password"
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Button
                  label={t('pages.resetPassword.btn.cancel')}
                  className={classes.buttonSecondary}
                  onClick={() => navigate(PATHS.SIGNIN)}
                />
                <Button
                  className={classes.button}
                  style={{ width: '50%' }}
                  label={t('pages.resetPassword.btn.change')}
                  onClick={handleResetPassword}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
export default ResetPasswordPage
