import { CheckCircle } from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import { Stack } from '@mui/system'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddToCollectionDialog from '../../components/AddToCollectionDialog'
import AnalyzeConnectionList from '../../components/AnalyzeConnectionList'
import AnalyzeIcon from '../../components/AnalyzeIcon'
import AnalyzeList from '../../components/AnalyzeList'
import Button from '../../components/Button'
import CreateAnalyzeDialog from '../../components/CreateAnalyzeDialog'
import DotsLoading from '../../components/DotsLoading'
import EditAnalyzeDialog from '../../components/EditAnalyzeDialog'
import Header from '../../components/Header'
import RootBox from '../../components/RootBox'
import ShareDialog from '../../components/ShareDialog'
import WarningContainer from '../../components/WarningContainer'
import { AVAILABLE_ANALYSIS_TYPES } from '../../constants/analyzeTypesIcon'
import { useInitialPresentation } from '../../hooks/useInitialPresentation'
import usePlanLimits from '../../hooks/usePlanLimits/usePlanLimits'
import { AnalyzeSchema, AnalyzeType, isAnalyzeSchema } from '../../schemas/AnalyzeSchema'
import { CollectionSchema } from '../../schemas/CollectionSchema'
import { PlugSchema } from '../../schemas/PlugSchema'
import collectionService from '../../services/collectionService'
import { analyzeService, plugService, staticService, userService } from '../../services/index'
import base64Utils from '../../utils/base64Utils'
import { normalyzeAndLowering, transformePascalCase } from '../../utils/stringUtils'
import { CreateAnalysisButton } from './CreateAnalysisButton'
import CreateOrEditCollectionDialog from './CreateOrEditCollectionDialog'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexGrow: 3,
    overflow: 'hidden'
  },
  sideFiltersContainer: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '25%',
    maxWidth: '25%',
    backgroundColor: theme.palette.background.light.highAccent
  },
  sideFiltersListContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.light.highAccent,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    maxHeight: '90%'
  },
  analysisContainer: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '75%',
    flexGrow: 3,
    flexShrink: 0,
    backgroundColor: theme.palette.background.light.accent
  },
  analysisListContainer: {
    maxHeight: '90%',
    overflow: 'auto',
    flexGrow: 1,
    backgroundColor: theme.palette.background.light.accent
  },
  analysisList: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1) + '!important'
    },
    padding: theme.spacing(1, 4)
  },
  tabs: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.light.default,
    flexGrow: 1
  },
  tab: {
    minWidth: 60
  },
  plugListItem: {
    padding: theme.spacing(1)
  }
}))

const Home: React.FC = () => {
  const classes = useStyles()
  const [triggeredAnalyze, setTriggeredAnalyze] = useState<AnalyzeSchema | null>(null)
  const [triggeredAnalyzeOrCollection, setTriggeredAnalyzeOrCollection] = useState<AnalyzeSchema | CollectionSchema>()
  const [triggeredCollection, setTriggeredCollection] = useState<CollectionSchema | null>(null)
  const [selectedAnalyzeType, setSelectedAnalyzeType] = useState<null | string>('ALL')
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false)
  const [createAnalyzeOpen, setCreateAnalyzeOpen] = React.useState(false)
  const [createOrEditCollectionOpen, setCreateOrEditCollectionOpen] = React.useState(false)
  const [shareAnalyzeOpen, setShareAnalyzeOpen] = React.useState(false)
  const [addToCollectionOpen, setAddToCollectionOpen] = React.useState(false)
  const [editAnalyzeOpen, setEditAnalyzeOpen] = React.useState(false)
  const [loadingPlugs, setLoadingPlugs] = useState(true)
  const [loadingAnalysis, setLoadingAnalysis] = useState(true)
  const [plugs, setPlugs] = useState<PlugSchema[]>([])
  const [analysisAndCollection, setAnalysisAndCollections] = useState<
    Omit<AnalyzeSchema, 'accountId' | 'canExportData'>[]
  >([])
  const { enqueueSnackbar } = useSnackbar()
  const [searchString, setSearchString] = useState('')
  const [selectedPlug, setSelectedPlug] = useState<PlugSchema | null>(null)
  const [createNewAnalyzeType, setCreateNewAnalyzeType] = useState<AnalyzeType>()
  const [createNewAnalyzeTitle, setCreateNewAnalyzeTitle] = useState<string>()
  const [openConfirmUnShare, setOpenConfirmUnShare] = useState(false)
  const [loadingRevokeShare, setLoadingRevokeShare] = useState(false)
  const { t } = useTranslation()
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { loadUsage, loading, checkHasAnalysisLimit, showLimitReachedModal } = usePlanLimits()
  const { DisplayElement, completePresentation } = useInitialPresentation(document.getElementById('speedDialHome'))
  const [showOrderSucces, setShowOrderSuccess] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const sessionId = queryParams.get('session_id')
    if (sessionId) {
      setShowOrderSuccess(true)
    }
  }, [])

  const speedDialActions = [
    {
      icon: <AnalyzeIcon color="secondary" type={AnalyzeType.PIVOTGRID} />,
      name: t('pageHome.body.actionButton.pivotGrid'),
      type: AnalyzeType.PIVOTGRID
    },
    {
      icon: <AnalyzeIcon color="secondary" type={AnalyzeType.DASHBOARD} />,
      name: t('pageHome.body.actionButton.dashboard'),
      type: AnalyzeType.DASHBOARD
    },
    {
      icon: <AnalyzeIcon color="secondary" type={AnalyzeType.GEOANALYSE} />,
      name: t('pageHome.body.actionButton.geo'),
      type: AnalyzeType.GEOANALYSE
    },
    {
      icon: <AnalyzeIcon color="secondary" type={AnalyzeType.REPORT} />,
      name: t('pageHome.body.actionButton.report'),
      type: AnalyzeType.REPORT
    },
    {
      icon: <AnalyzeIcon color="secondary" type={AnalyzeType.COLLECTION} />,
      name: t('pageHome.body.actionButton.collection'),
      type: AnalyzeType.COLLECTION
    }
  ]

  const handleChangeAnalyzeTabSelection = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setSelectedAnalyzeType(value)
  }

  const handleOpenCreateAnalyzeDialog = (actionName: string, actionType: AnalyzeType) => {
    if (actionType === AnalyzeType.COLLECTION) {
      setCreateOrEditCollectionOpen(true)
      setSpeedDialOpen(false)
      return
    }
    setCreateNewAnalyzeType(actionType)
    setCreateNewAnalyzeTitle(transformePascalCase(actionName))
    setCreateAnalyzeOpen(true)
    setSpeedDialOpen(false)
  }

  const handleCloseCreateAnalyzeDialog = () => {
    setCreateAnalyzeOpen(false)
  }
  const handleOpenShareAnalyzeDialog = (analyzeOrCollection: AnalyzeSchema | CollectionSchema) => {
    setTriggeredAnalyzeOrCollection(analyzeOrCollection)
    setShareAnalyzeOpen(true)
  }

  const handleCloseShareAnalyzeDialog = (refresh?: boolean) => {
    setShareAnalyzeOpen(false)
    if (refresh) {
      loadAnalysis()
    }
  }

  const handleOpenAddToCollectionDialog = (analyze: AnalyzeSchema) => {
    setTriggeredAnalyze(analyze)
    setAddToCollectionOpen(true)
  }

  const handleCloseAddToCollectionDialog = (refresh?: boolean) => {
    setAddToCollectionOpen(false)
    if (refresh) {
      loadAnalysis()
    }
  }
  const handleCloseCreateCollectionDialog = (refresh?: boolean) => {
    setCreateOrEditCollectionOpen(false)
    setTriggeredCollection(null)
    if (refresh) {
      loadAnalysis()
    }
  }

  const handleOpenEditAnalyze = (analyze: AnalyzeSchema) => {
    setTriggeredAnalyze(analyze)
    setEditAnalyzeOpen(true)
  }

  const handleOpenEditCollection = (collection: CollectionSchema) => {
    setTriggeredCollection(collection)
    setCreateOrEditCollectionOpen(true)
  }

  const handleOpenEditAnalyzeOrCollectionDialog = (analyzeOrCollection: AnalyzeSchema | CollectionSchema) => {
    isAnalyzeSchema(analyzeOrCollection)
      ? handleOpenEditAnalyze(analyzeOrCollection)
      : handleOpenEditCollection(analyzeOrCollection)
  }

  const handleCloseEditAnalyzeDialog = () => {
    setEditAnalyzeOpen(false)
    setTriggeredAnalyze(null)
    loadAnalysis()
  }

  const loadPlugImage = async (imgSrc: string) => {
    if (imgSrc) {
      try {
        const { data } = await staticService.get(imgSrc)
        const imgDataUri = await base64Utils.convertBlobToDataUri(data)
        return imgDataUri
      } catch (err) {
        console.error(err)
      }
    }
    return ''
  }

  const loadPlugs = useCallback(async () => {
    try {
      setLoadingPlugs(true)
      const respPlugs = await plugService.getAll()
      const plugsSorted = respPlugs.data?.sortBy((e) => e.name)
      for (const plug of plugsSorted) {
        if (plug.connection?.image) {
          plug.connection.image = await loadPlugImage(plug.connection.image)
        }
      }
      setPlugs(plugsSorted)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageHome.toast.error.plugs'), { variant: 'error' })
    }
    setLoadingPlugs(false)
  }, [enqueueSnackbar, t])

  const loadAnalysis = useCallback(async () => {
    try {
      setLoadingAnalysis(true)
      const respAnalysisPromise = analyzeService.getAnalysis()
      const respCollectionsPromise = collectionService.getCollections()

      const [respAnalysis, respCollections] = await Promise.all([respAnalysisPromise, respCollectionsPromise])
      const parsedCollections = respCollections.data.map((rc) => ({
        ...rc,
        type: AnalyzeType.COLLECTION,
        plugs: []
      }))
      setAnalysisAndCollections([...respAnalysis.data?.sortBy((e) => e.name), ...parsedCollections])
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageHome.toast.error.analysis'), { variant: 'error' })
    }
    setLoadingAnalysis(false)
  }, [enqueueSnackbar, t])

  useEffect(() => {
    loadPlugs()
    loadAnalysis()
  }, [loadPlugs, loadAnalysis])

  const handleHeaderSearchChange = (searchValue: string) => {
    setSearchString(searchValue)
  }

  const getFilteredPlugs = () => {
    if (!searchString) return plugs
    return plugs.filter((p) => p.name.includes(searchString))
  }

  const getFilteredAnalysisAndColls = () => {
    const hasSearch = !!searchString
    const hasPlugFilter = !!selectedPlug
    const hasTypeFilter = !!selectedAnalyzeType && selectedAnalyzeType !== 'ALL'
    return analysisAndCollection
      .filter((ac) => {
        let res = true
        if (selectedAnalyzeType === 'Collection') {
          res = res && ac.type === AnalyzeType.COLLECTION
          res = res && (!hasSearch ? res : normalyzeAndLowering(ac.name).includes(normalyzeAndLowering(searchString)))
        }
        res = res && (!hasPlugFilter ? res : ac.plugs.some((p) => p.name === selectedPlug!.name))
        res = res && (!hasTypeFilter ? res : ac.type === selectedAnalyzeType)
        res = res && (!hasSearch ? res : normalyzeAndLowering(ac.name).includes(normalyzeAndLowering(searchString)))

        return res
      })
      .sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
  }

  const handleUnShareClick = (analyze: AnalyzeSchema) => {
    setTriggeredAnalyze(analyze)
    setOpenConfirmUnShare(true)
  }

  const getAnalysisTranslations = (analysisType: string): string => {
    const transMap = {
      ALL: t('pageHome.menu.all'),
      'PIVOT GRID': t('pageHome.menu.pivotGrid'),
      DASHBOARD: t('pageHome.menu.dashboard'),
      REPORT: t('pageHome.menu.report'),
      'GEO ANALYZE': t('pageHome.menu.geoAnalyze'),
      COLLECTION: t('pageHome.menu.collection')
    } as any
    return transMap[analysisType]
  }

  const handleRevokeAnalyze = async () => {
    try {
      setLoadingRevokeShare(true)
      if (triggeredAnalyze?.type === AnalyzeType.COLLECTION) {
        await userService.removeCollection(triggeredAnalyze!.id)
        enqueueSnackbar(t('pageHome.toast.success.revokeCollection'), { variant: 'success' })
      } else {
        await userService.removeAnalyze(triggeredAnalyze!.id)
        enqueueSnackbar(t('pageHome.toast.success.revokeAnalysis'), { variant: 'success' })
      }
      loadAnalysis()
    } catch (err: any) {
      enqueueSnackbar(t('pageHome.toast.error.revokeAnalysis'), { variant: 'error' })
    }
    setLoadingRevokeShare(false)
    setOpenConfirmUnShare(false)
  }

  const handlePlugFilterChange = (selectedPlugFilter: PlugSchema) => {
    setSelectedPlug(selectedPlugFilter)
  }
  return (
    <RootBox>
      <DisplayElement />
      <Header
        showFilterActions={true}
        icon={HomeIcon}
        title={t('pageHome.header.title')}
        subtitle={t('pageHome.header.subtitle')}
        availableConnections={plugs}
        onChangeSearchValue={handleHeaderSearchChange}
        onChangeAnalyzeSelection={(analysisTp) => setSelectedAnalyzeType(analysisTp)}
        onChangeConnSelection={(plug) => setSelectedPlug(plug)}
      />
      <Box className={classes.content}>
        <Hidden lgDown>
          <Box className={classes.sideFiltersContainer}>
            <Tabs
              className={classes.tabs}
              value={0}
              indicatorColor="primary"
              variant="standard"
              scrollButtons="auto"
              textColor="primary"
            >
              <Tab label={t('pageHome.menu.plugs')} />
            </Tabs>
            <Box className={classes.sideFiltersListContainer}>
              {loadingPlugs ? (
                <DotsLoading />
              ) : (
                <AnalyzeConnectionList
                  className={classes.analysisList}
                  onSelectionChange={handlePlugFilterChange}
                  items={getFilteredPlugs()}
                />
              )}
            </Box>
          </Box>
        </Hidden>
        <Box className={classes.analysisContainer}>
          <Stack
            spacing={2}
            direction="row"
            width="100%"
            paddingRight={2}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.light.default
            })}
            alignItems="center"
          >
            <Hidden lgDown>
              <Tabs
                className={classes.tabs}
                value={selectedAnalyzeType}
                onChange={handleChangeAnalyzeTabSelection}
                indicatorColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
              >
                {Object.keys(AVAILABLE_ANALYSIS_TYPES).map((analyzeType) => (
                  <Tab
                    className={classes.tab}
                    label={getAnalysisTranslations(AVAILABLE_ANALYSIS_TYPES[analyzeType])}
                    value={analyzeType}
                  />
                ))}
              </Tabs>
            </Hidden>
            <CreateAnalysisButton
              onAnalysisSelected={(analysisType) =>
                handleOpenCreateAnalyzeDialog(
                  getAnalysisTranslations(analysisType.toString().replace('_', ' ')),
                  analysisType
                )
              }
            />
          </Stack>
          <Box className={classes.analysisListContainer}>
            {loadingAnalysis ? (
              <DotsLoading />
            ) : (
              <AnalyzeList
                onShareClick={handleOpenShareAnalyzeDialog}
                onAddCollecionClick={handleOpenAddToCollectionDialog}
                onEditClick={handleOpenEditAnalyzeOrCollectionDialog}
                items={getFilteredAnalysisAndColls()}
                onUnShareClick={handleUnShareClick}
                className={classes.analysisList}
              />
            )}
          </Box>
        </Box>
      </Box>
      <CreateAnalyzeDialog
        selectedAnalyzeType={createNewAnalyzeType || AnalyzeType.PIVOTGRID}
        plugs={plugs}
        title={createNewAnalyzeTitle}
        description={t('pageHome.body.modalCreate.analysis.subtitle')}
        maxWidth="md"
        onClose={handleCloseCreateAnalyzeDialog}
        open={createAnalyzeOpen}
      />

      <ShareDialog
        analyzeOrCollection={triggeredAnalyzeOrCollection}
        onClose={handleCloseShareAnalyzeDialog}
        open={shareAnalyzeOpen}
      />

      <AddToCollectionDialog
        analyze={triggeredAnalyze || undefined}
        title={t('pivotGrid.menuSettings.modalCollection.Title')}
        onClose={handleCloseAddToCollectionDialog}
        description={t('pivotGrid.menuSettings.modalCollection.Subtitle')}
        open={addToCollectionOpen}
      />
      <CreateOrEditCollectionDialog
        title={
          !triggeredCollection
            ? t('pageHome.body.modalCreate.Collection.titleCreate')
            : t('pageHome.body.modalEdit.Collection.title')
        }
        onClose={handleCloseCreateCollectionDialog}
        description={
          !triggeredCollection ? t('collection.modalSubtitle') : t('pageHome.body.modalEdit.Collection.subtitle')
        }
        open={createOrEditCollectionOpen}
        collection={triggeredCollection}
      />
      {triggeredAnalyze && (
        <EditAnalyzeDialog
          title={t('pageHome.body.modalEdit.analysis.title')!}
          description={t('pageHome.body.modalEdit.analysis.subtitle')}
          maxWidth="md"
          open={editAnalyzeOpen}
          onClose={handleCloseEditAnalyzeDialog}
          analyze={triggeredAnalyze}
        />
      )}
      <Dialog
        title={t('pageHome.body.modalShare.revoke.title')!}
        open={openConfirmUnShare}
        onClose={() => setOpenConfirmUnShare(false)}
      >
        <DialogContent>
          <WarningContainer
            message={t('pageHome.body.modalShare.revoke.warningMessage', { analysisName: triggeredAnalyze?.name })}
            relatedDependencies={[]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            isDelete
            label={t('pageHome.body.modalShare.revoke.confirmBtn')}
            loading={loadingRevokeShare}
            onClick={handleRevokeAnalyze}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={showOrderSucces} onClose={() => setShowOrderSuccess(false)}>
        <DialogTitle textAlign="center">
          <CheckCircle color="success" fontSize="large" />
          <Typography variant="h1">Parabéns!</Typography>
          <Typography variant="h2">Você deu um salto no seu Business Intelligence!</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary" variant="body1" textAlign="center">
            <p>
              Parabéns por fazer upgrade do seu plano! <br /> Mais recursos significam insights ainda mais poderosos.
            </p>
            <p>Estamos animados para ver o impacto positivo que você trará aos seus negócios.</p>
            <p>Qualquer dúvida, estamos aqui para ajudar!</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: 'auto' }}>
          <Button
            label="Fechar"
            onClick={() => {
              window.history.replaceState(null, '', '/')
              setShowOrderSuccess(false)
            }}
          />
        </DialogActions>
      </Dialog>
    </RootBox>
  )
}

export default Home
