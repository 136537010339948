import { List, ListProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source'
import React, { PropsWithChildren } from 'react'
import GroupedListItem from '../../components/GroupedListItem'
import { AnalyzeType, ShareType } from '../../schemas/AnalyzeSchema'

interface GroupedListProps extends Omit<ListProps, 'onChange'> {
  dataSource?: string | any[] | DataSource | DataSourceOptions
  itemsKey: string
  itemsLabelKey: string
  groupItemsKey: string
  groupLabelKey: string
  fallbackItemLabelKey: string
  selectedItems?: any[]
  onChange?(userId: string, shareType: ShareType): void
  itemType?: AnalyzeType
}

const useStyles = makeStyles(() => ({
  root: {}
}))

const GroupedList: React.FC<PropsWithChildren<GroupedListProps>> = ({
  dataSource,
  selectedItems,
  onChange,
  groupLabelKey,
  groupItemsKey,
  itemsKey,
  itemsLabelKey,
  itemType,
  fallbackItemLabelKey
}) => {
  const classes = useStyles()
  return (
    <List component="ol" aria-label="team-users analysis sharing list" className={classes.root}>
      {(dataSource as any[])
        .sortBy((x) => (x.name as string).toLocaleUpperCase())
        .map((item) => (
          <GroupedListItem
            fallbackItemLabelKey={fallbackItemLabelKey}
            selectedItems={selectedItems}
            label={item[groupLabelKey]}
            items={item[groupItemsKey]}
            itemsLabelKey={itemsLabelKey}
            itemsKey={itemsKey}
            onChange={onChange}
            itemType={itemType}
          />
        ))}
    </List>
  )
}

export default GroupedList
