import { AxiosResponse } from 'axios'

import api from '../api'

import { ENDPOINTS } from '../../constants/apiEndpoints'
import {
  CollectionAnalyzeSchema,
  CollectionSchema,
  CreateCollectionSchema,
  UpdateCollectionSchema
} from '../../schemas/CollectionSchema'
import { format } from '../../utils/stringUtils'

const getCollections = (): Promise<AxiosResponse<CollectionSchema[]>> => {
  return api.get(ENDPOINTS.GET_COLLECTIONS)
}

const getCollection = (id: string): Promise<AxiosResponse<CollectionSchema>> => {
  return api.get(format(ENDPOINTS.GET_COLLECTION, id))
}

const create = (collection: CreateCollectionSchema): Promise<AxiosResponse<CollectionSchema>> => {
  return api.post(ENDPOINTS.CREATE_COLLECTION, collection)
}

const getAnalysis = (collectionId: string): Promise<AxiosResponse<CollectionAnalyzeSchema[]>> => {
  return api.get(format(ENDPOINTS.GET_COLLECTION_ANALYSIS, collectionId))
}

const update = (collectionId: string, collection: UpdateCollectionSchema): Promise<AxiosResponse<CollectionSchema>> => {
  return api.put(format(ENDPOINTS.UPDATE_COLLECTION, collectionId), collection)
}

const _delete = (collectionId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.DELETE_COLLECTION, collectionId))
}

export default { getCollections, create, update, _delete, getAnalysis, getCollection }
