import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { SendingEmailSettings, SendingRecipient, SendingSchema } from '../../schemas/SendingSchema'
import { format } from '../../utils/stringUtils'
import { PartialBy } from '../../utils/typeUtils'
import api from '../api'


const get = (): Promise<AxiosResponse<SendingSchema[]>> => {
  return api.get(ENDPOINTS.GET_SENDINGS)
}

const _delete = (sendingId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.DELETE_SENDING, sendingId))
}

export type SendSendingRequest = {
  sendingId: string
  overriddenRecipients?: SendingRecipient[]
}

const send = (request: SendSendingRequest): Promise<AxiosResponse<void>> => {
  return api.post(ENDPOINTS.POST_SENDING_SEND, request)
}

export type SendingRecipientDispatch = {
  status: 'Pending'| 'Sent'|'Failed'| 'Accepted'| 'Relayed'| 'Suppressed'
  error: string | null
  createdDate: Date
  name: string
  emailAddress: string
}

export type SendingDispatch = {
  id: string
  sendingId: string
  trackingId: string
  requestId: string
  status: 'Pending'| 'Sent'|'Failed'| 'Accepted'| 'Relayed'| 'Suppressed'
  createdDate: Date
  updatedDate: Date
  error: string | null
  sendingRecipientsDispatches : SendingRecipientDispatch[]
}

const getDispatches = (
  sendingId: string,
  startDate: Date,
  endDate: Date
) : Promise<AxiosResponse<SendingDispatch[]>> => {
  const params = new URLSearchParams()
  params.append('startDate', startDate.toISOString())
  params.append('endDate', endDate.toISOString())
  return api.get(`${format(ENDPOINTS.GET_SENDING, sendingId)}/dispatches?${params.toString()}`)
}

export type CreateSendingRequest = Omit<SendingSchema, 'id'| 'createdDate' | 'updatedDate' | 'accountId' | 'userOwnerId' | 'emailSettings'> &{
  emailSettings : PartialBy<SendingEmailSettings, 'senderName'>
}

const create = (request: CreateSendingRequest): Promise<AxiosResponse<SendingSchema>> => {
  return api.post(ENDPOINTS.CREATE_SENDING, request)
}

export type UpdateSendingRequest = CreateSendingRequest & {id: string}


const update = (id: string, request: UpdateSendingRequest): Promise<AxiosResponse<void>> => {
  return api.put(format(ENDPOINTS.GET_SENDING, id), request)
}

export default { get, create, _delete, getDispatches, send, update }
