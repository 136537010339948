import { Close } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PlanSchema } from '../../schemas/PlanSchema'
import { PlanResource } from './PlanLimitsContext'

type ModalLimitReachedProps = {
  open: boolean
  onClose: () => void
  plan: PlanSchema
  reachedResource: PlanResource | null
}

const ModalLimitReached: React.FC<ModalLimitReachedProps> = ({ open, onClose, plan, reachedResource }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleUpgradeClick = () => {
    onClose()
    navigate('/pricing')
  }

  const getResourceQuantity = () => {
    switch (reachedResource) {
      case 'users':
        return plan.maxUsers
      case 'plugs':
        return plan.maxPlugs
      case 'analysis':
        return plan.maxAnalysis
      case 'databaseSize':
        return plan.maxDatabaseSize
      default:
        return ''
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        {t('modal.limitReached.title.text')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {t('modal.limitReached.content.text', {
          resourceQuantity: getResourceQuantity(),
          resource: t(`modal.limitReached.resource.${reachedResource}`),
          planName: plan.name
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('modal.limitReached.btn.cancel')}</Button>
        <Button onClick={handleUpgradeClick} variant="contained">
          {t('modal.limitReached.btn.upgrade')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalLimitReached
