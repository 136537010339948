import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, InputAdornment, Paper, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { createStyles, styled, withStyles } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import chroma from 'chroma-js'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import PluggerLogoTransp from '../../components/PluggerLogoTransp'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { useMovideskChat } from '../../hooks/useMovideskChat'
import { useReturnUrl } from '../../hooks/useReturnUrl'
import useWindowSize from '../../hooks/useWindowSize'
import { accountService, authService, userService } from '../../services/index'
import { TermsAndPolicyAcceptDialog } from '../selectAccount/TermsAndPolicyAcceptDialog'
import NotVerifiedEmail from './NotVerifiedEmail'

declare var dataLayer: any[]

const useStyles = makeStyles<Theme, { height: number }>((theme) => ({
  box: {
    height: (params) => params.height,
    overflow: 'auto',
    background: `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${
      theme.palette.background.light?.contrast || '#FFF'
    }`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: 220,
    margin: '0 auto!important'
  },
  button: {
    color: '#000 !important',
    backgroundColor: '#39e991 !important',
    '&:hover': {
      backgroundColor: chroma('#39e991').darken().hex()
    }
  },
  link: {
    cursor: 'pointer',
    color: '#39e991',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    textTransform: 'none',
    fontSize: '1rem'
  }
}))

export const StyledTextField = withStyles((theme) =>
  createStyles({
    root: {
      '& label.Mui-focused': {
        color: '#39e991'
      },
      '& .MuiFormLabel-root': {
        color: '#39e991'
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: theme.palette.error.main
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#39e991'
        },
        '&:hover fieldset': {
          borderColor: '#39e991'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#39e991'
        }
      },
      '& .MuiOutlinedInput-input': {
        color: theme.palette.common.white
      },
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-text-fill-color': 'white',
        boxShadow: '0 0 0 30px #343a44 inset'
      }
    }
  })
)(TextField)

const styles = {
  input: {
    WebkitBoxShadow: '0 0 0 1000px #393b41 inset',
    WebkitTextFillColor: '#FFF',
    caretColor: '#FFF'
  }
}

const DarkenPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark.contrast,
  padding: theme.breakpoints.down('md') ? theme.spacing(2) : theme.spacing(4)
}))

const SignIn: React.FC<PropsWithChildren> = () => {
  const [, windowHeight] = useWindowSize()
  const classes = useStyles({ height: windowHeight })
  const [loading, setLoading] = useState(false)
  const [loadingRecovery, setLoadingRecovery] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isNotVerifiedEmail, setIsNotVerifiedEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()
  const [showTermsAndPolicyAcceptDialog, setShowTermsAndPolicyAcceptDialog] = useState(false)
  const [accountAwaitingToAcceptTerms, setAccountAwaitingToAcceptTerms] = useState<any>(null)
  const { setUser } = useMovideskChat()

  const isMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const validationSchema = Yup.object({
    email: Yup.string().required().label('email'),
    password: Yup.string().min(6).required().label(t('common.labels.password'))
  })
  const { getReturnObj } = useReturnUrl()

  const handleGetAccountsOrSignIn = async ({ email, password }: { email: string; password: string }) => {
    setLoading(true)
    try {
      await auth.login(email, password)
      const { data } = await accountService.getLoggedUserAccounts()
      const { data: userInfo } = await userService.getCurrentInfoV2()
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      dataLayer.push({
        event: 'login',
        loginMethod: 'email',
        userId: userInfo.id,
        userEmail: userInfo.email,
        userFullName: userInfo.fullName
      })
      setUser({
        userEmail: userInfo.email,
        userFirstName: userInfo.firstName
      })
      const activeAccounts = data.filter((x) => x.status === 'ACTIVE' || x.status === 'NOT_PAID')
      const returnData = !!getReturnObj() ? { ...getReturnObj() } : null
      const hasReturnUrl = !!returnData?.accountId
      window.history.replaceState({}, document.title)

      if (activeAccounts.length === 1) {
        const activeAccount = activeAccounts[0]
        if (!activeAccount.termsAndPolicyAccepted) {
          setAccountAwaitingToAcceptTerms(activeAccount)
          setLoading(false)
          setShowTermsAndPolicyAcceptDialog(true)
          return
        }
        await auth.accessAccount(activeAccounts[0].id)
        navigate(!!hasReturnUrl ? returnData!.url! : PATHS.HOME)
      } else {
        if (!!hasReturnUrl) {
          await auth.accessAccount(returnData!.accountId!, localStorage.getItem('token')!)
          await auth.loadUserData()
          navigate(returnData!.url!)
        } else {
          auth.loadOnlyUserData()
          navigate(PATHS.SELECT_ACCOUNT, {
            state: {
              accounts: activeAccounts
            }
          })
        }
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.errorCode === 'NoActiveAccount') {
          setIsNotVerifiedEmail(true)
          setLoading(false)
          return
        }
      }
      console.warn(err)
      enqueueSnackbar(t('pages.signin.invalid.signinAttempt'), { variant: 'error' })
    }
    setLoading(false)
  }

  const { submitForm, values, validateField, errors, getFieldProps } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: handleGetAccountsOrSignIn
  })

  const handlePasswordRecovery = async (event: any) => {
    event.preventDefault()
    validateField('email')
    const emailErrors = errors['email']
    if (emailErrors) {
      return
    }
    setLoadingRecovery(true)
    const email = values['email']
    try {
      await authService.recoveryPassword(email!)
      enqueueSnackbar('A recovery email has been sent for you', { variant: 'success' })
    } catch (err: any) {
      console.warn(err)
      enqueueSnackbar(t('accessScreen.signin.msg.invalidEmail'), { variant: 'error' })
    }
    setLoadingRecovery(false)
  }

  const theme = useTheme()

  const auth = useAuth()

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: true,
      context: 'signin',
      auto_select: false,
      callback: async (response: any) => {
        setLoading(true)
        try {
          await auth.signInGoogle(response.credential)
          const { data } = await accountService.getLoggedUserAccounts()
          const { data: userInfo } = await userService.getCurrentInfoV2()
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          dataLayer.push({
            event: 'login',
            loginMethod: 'email',
            userId: userInfo.id,
            userEmail: userInfo.email,
            userFullName: userInfo.fullName
          })
          setUser({
            userEmail: userInfo.email,
            userFirstName: userInfo.firstName
          })
          const activeAndFreeAccounts = data.filter((x) => x.status === 'ACTIVE' || x.status === 'NOT_PAID')

          const returnData = !!getReturnObj() ? { ...getReturnObj() } : null
          const hasReturnUrl = !!returnData
          window.history.replaceState({}, document.title)

          if (activeAndFreeAccounts.length === 1) {
            const activeAccount = activeAndFreeAccounts[0]
            if (!activeAccount.termsAndPolicyAccepted) {
              setAccountAwaitingToAcceptTerms(activeAccount)
              setLoading(false)
              setShowTermsAndPolicyAcceptDialog(true)
              return
            }
            await auth.accessAccount(activeAndFreeAccounts[0].id)
            navigate(!!hasReturnUrl ? returnData!.url! : PATHS.HOME)
          } else {
            if (!!hasReturnUrl) {
              await auth.accessAccount(returnData!.accountId!, localStorage.getItem('token')!)
              navigate(returnData!.url!)
            } else {
              auth.loadOnlyUserData()
              navigate(PATHS.SELECT_ACCOUNT, {
                state: {
                  accounts: activeAndFreeAccounts
                }
              })
            }
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (err.response?.data?.errorCode === 'NoActiveAccount') {
              setIsNotVerifiedEmail(true)
              setLoading(false)
              return
            }
          }
          console.warn(err)
          enqueueSnackbar(t('pages.signin.invalid.signinAttempt'), { variant: 'error' })
        }
      }
    })
  }, [auth, enqueueSnackbar, getReturnObj, navigate, setUser, t])

  useEffect(() => {
    const googleBtnContainer = document.getElementById('google-btn-container')
    if (!googleBtnContainer) {
      return
    }
    google.accounts.id.renderButton(googleBtnContainer, {
      type: 'standard',
      width: isMdDown ? '300' : '400',
      size: 'medium',
      theme: 'outline'
    })
  }, [isMdDown, loading])

  return (
    <Box className={classes.box}>
      {isNotVerifiedEmail ? (
        <NotVerifiedEmail
          email={values.email}
          onNotMyEmailClick={() => setIsNotVerifiedEmail(false)}
          onGoBackToLoginClick={() => setIsNotVerifiedEmail(false)}
        />
      ) : (
        <>
          {loading ? (
            <Loading
              plugFill={theme.palette.text.secondary}
              loopFill={theme.palette.text.secondary}
              width="200px"
              height="80px"
            />
          ) : (
            <DarkenPaper>
              <Stack maxWidth={560} width="100%" spacing={3}>
                <PluggerLogoTransp src={ENDPOINTS.PUBLIC_IMAGES + '/logo escuro.svg'} className={classes.logo} />
                <Stack spacing={2}>
                  <div style={{ height: '44px' }} id="google-btn-container" />
                </Stack>
                <Typography color="#fff" textAlign="center">
                  {t('accessScreen.signin.or')}
                </Typography>
                <Stack spacing={2}>
                  <StyledTextField
                    fullWidth
                    size="small"
                    id="email"
                    required
                    type="email"
                    label="Email"
                    error={!!errors['email']}
                    helperText={errors['email']}
                    {...getFieldProps('email')}
                    inputProps={{ style: styles.input }}
                  />
                  <StyledTextField
                    fullWidth
                    size="small"
                    id="password"
                    required
                    label={t('common.labels.password')}
                    error={!!errors['password']}
                    helperText={errors['password']}
                    {...getFieldProps('password')}
                    //inputProps={{ style: styles.input }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment style={{ color: '#fff' }} position="end">
                          <IconButton
                            color="inherit"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    type={showPassword ? 'text' : 'password'}
                  />
                  <Button
                    onClick={submitForm}
                    className={classes.button}
                    type="submit"
                    label={t('accessScreen.signin.buttonSignin')}
                  />
                  <Box color="#39e991">
                    <Typography align="center" color="inherit" display="block">
                      <Button
                        onClick={handlePasswordRecovery}
                        variant="text"
                        className={classes.link}
                        color="inherit"
                        label={t('accessScreen.signin.linkForgot')}
                        loading={loadingRecovery}
                      />
                    </Typography>
                    <Typography align="center" color="inherit" display="block">
                      {t('pageSignin.dontHaveAccount')}{' '}
                      <a href="https://pluggerbi.com" className={classes.link} color="inherit">
                        {t('pageSignin.createHere')}
                      </a>
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </DarkenPaper>
          )}
        </>
      )}
      <TermsAndPolicyAcceptDialog
        open={showTermsAndPolicyAcceptDialog}
        onClose={() => setShowTermsAndPolicyAcceptDialog(false)}
        onConfirm={async () => {
          const returnData = !!getReturnObj() ? { ...getReturnObj() } : null
          const hasReturnUrl = !!returnData
          setShowTermsAndPolicyAcceptDialog(false)
          setLoading(true)
          await auth.accessAccount(accountAwaitingToAcceptTerms.id)
          navigate(!!hasReturnUrl ? returnData!.url! : PATHS.HOME)
        }}
      />
    </Box>
  )
}
export default SignIn
