import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Stack
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePayment } from '../usePayment'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../useAuth'
import { useState } from 'react'
import { accountService } from '../../../services'
import { useSnackbar } from 'notistack'
import { TrialExtended } from './TrialExtended'

const LinkButton = styled(Button)(({ theme, ...props }) => ({
  padding: 0,
  textTransform: 'none',
  textDecoration: 'underline',
  fontSize: 18,
  fontWeight: 400,
  backgroundColor: 'none!important'
}))

export const TrialEndModal: React.FC<{ open: boolean }> = ({ open }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const payment = usePayment()!
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getAccountInfo, loadUserData } = useAuth()
  const [loadingExtendTrial, setLoadingExtendTrial] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [trialExtended, setTrialExtended] = useState(false)

  const accountInfo = getAccountInfo()

  const handleExtendTrial = async () => {
    setLoadingExtendTrial(true)
    try {
      await accountService.extendTrial()
      setTrialExtended(true)
      loadUserData()
    } catch (err) {
      console.log(err)
      // enqueueSnackbar(t('trialExpiredDialog.extendTrialError'), { variant: 'error' })
    }
    setLoadingExtendTrial(false)
  }

  return (
    <Dialog maxWidth="md" fullScreen={fullScreen} fullWidth open={open}>
      <DialogTitle>
        <Stack spacing={2} direction="row" alignItems="center">
          <img
            style={{
              width: 36,
              height: 'auto'
            }}
            src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/logo_simbolologo_simbolo.png"
            alt="Plugger BI logo"
          />
          <span>{t('trialExpiredDialog.title')}</span>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {trialExtended ? (
          <TrialExtended />
        ) : (
          <Stack spacing={2}>
            <Typography variant="body1">{t('trialExpiredDialog.firstSub')}</Typography>
            <Button
              sx={{ width: 320, marginX: 'auto!important' }}
              fullWidth={false}
              variant="contained"
              onClick={() => {
                payment?.closePresenter()
                navigate('/pricing')
              }}
            >
              {t('trialExpiredDialog.selectPlan.btnLabel')}
            </Button>
            <Typography variant="body1">{t('trialExpiredDialog.secSub')}</Typography>
            <Paper
              variant="outlined"
              sx={{
                backgroundColor: '#F4F4F7',
                margin: '1em 3em!important',
                padding: 1,
                border: 'none'
              }}
            >
              <Stack spacing={1.2}>
                {!accountInfo?.isTrialExtended && (
                  <Typography fontSize={18} variant="body1">
                    <LinkButton
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      variant="text"
                      onClick={handleExtendTrial}
                      disabled={loadingExtendTrial}
                      endIcon={loadingExtendTrial && <CircularProgress size={20} />}
                    >
                      {t('trialExpiredDialog.restartTrialLink')}
                    </LinkButton>{' '}
                    - {t('trialExpiredDialog.restartDesc')}
                  </Typography>
                )}
                <Typography fontSize={18} variant="body1">
                  <Link
                    onClick={() => {
                      payment.closePresenter()
                      navigate('/feedback')
                    }}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    {' '}
                    {t('trialExpiredDialog.shareFeedback')}{' '}
                  </Link>
                  - {t('trialExpiredDialog.shareFeedbackDesc')}
                </Typography>
                <Typography fontSize={18} variant="body1">
                  <Link
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    {' '}
                    {t('trialExpiredDialog.closeYourAccount')}{' '}
                  </Link>{' '}
                  - {t('trialExpiredDialog.closeYourAccountDesc')}
                </Typography>
              </Stack>
            </Paper>
            <Typography variant="body1">{t('trialExpiredDialog.footerCaption1')}</Typography>
            <Typography variant="body1">{t('trialExpiredDialog.footerThanks')}</Typography>
            <Typography variant="body1">{t('trialExpiredDialog.footerPS')}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Link hidden={trialExtended} href="https://pluggerbi.com">
          {t('trialExpiredDialog.exit')}
        </Link>
      </DialogActions>
    </Dialog>
  )
}
