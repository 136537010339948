import { getIn } from 'formik'

import { FieldProps, FieldPropValues } from './types'

export interface FieldPropsWithName<TValues> extends Partial<FieldProps<TValues>> {
  name: string
}

export function customFieldComparable<V extends FieldPropValues, T extends FieldPropsWithName<V>>(
  prevProps: Readonly<T>,
  nextProps: Readonly<T>
): boolean {
  if (
    getIn(prevProps.values, prevProps.name) !== getIn(nextProps.values, nextProps.name) ||
    getIn(prevProps.errors, prevProps.name) !== getIn(nextProps.errors, nextProps.name) ||
    getIn(prevProps.touched, prevProps.name) !== getIn(nextProps.touched, nextProps.name) ||
    Object.keys(prevProps).length !== Object.keys(nextProps).length ||
    prevProps.isSubmitting !== nextProps.isSubmitting
  ) {
    return false
  }
  return true
}
