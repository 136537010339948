import { AxiosResponse } from 'axios'

import api from '../api'

import { ENDPOINTS } from '../../constants/apiEndpoints'
import {
  AnalyzeSchema,
  AnalyzeWithStateSchema,
  CreateAnalyzeSchema,
  PartialUpdateAnalyzeSchema
} from '../../schemas/AnalyzeSchema'
import { UserClaim } from '../../schemas/UserSchema'
import { format } from '../../utils/stringUtils'

const getAnalysis = (): Promise<AxiosResponse<AnalyzeSchema[]>> => {
  return api.get(ENDPOINTS.GET_ANALYSIS)
}

const getAnalyze = (id: string): Promise<AxiosResponse<AnalyzeSchema>> => {
  return api.get(format(ENDPOINTS.GET_ANALYZE, id))
}

const getAnalyzeAndState = (id: string): Promise<AxiosResponse<AnalyzeWithStateSchema>> => {
  return api.get(format(ENDPOINTS.GET_ANALYZE_WITH_STATE, id))
}

const create = (analyze: CreateAnalyzeSchema): Promise<AxiosResponse<AnalyzeSchema>> => {
  return api.post(ENDPOINTS.CREATE_ANALYZE, analyze)
}

const update = (analyzeId: string, analyze: AnalyzeSchema): Promise<AxiosResponse<AnalyzeSchema>> => {
  return api.put(format(ENDPOINTS.UPDATE_ANALYZE, analyzeId), analyze)
}
const partialUpdate = (analyzeId: string, analyze: PartialUpdateAnalyzeSchema): Promise<AxiosResponse<void>> => {
  return api.patch(format(ENDPOINTS.UPDATE_ANALYZE, analyzeId), analyze)
}

const _delete = (analyzerId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.DELETE_ANALYZE, analyzerId))
}

const getSharedUserClaims = (analyzerId: string): Promise<AxiosResponse<UserClaim[]>> => {
  return api.get(format(ENDPOINTS.GET_ANALYZE_SHARED_USERS, analyzerId))
}

const shareAndUnshare = (analyzerId: string, shares: UserClaim[]): Promise<AxiosResponse<never[]>> => {
  return api.post(format(ENDPOINTS.SHARE_UNSHARE_ANALYZE, analyzerId), shares)
}

const bulkCopy = (analyzerId: string, userIds: string[]): Promise<AxiosResponse<never[]>> => {
  return api.post(format(ENDPOINTS.BULK_COPY_ANALYSIS, analyzerId), userIds)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAnalyze,
  create,
  _delete,
  getAnalysis,
  update,
  partialUpdate,
  getSharedUserClaims,
  shareAndUnshare,
  getAnalyzeAndState,
  bulkCopy
}
