import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Form, { FormProps } from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import TableField from '../../components/Form/TableField'
import { TeamSchema, TeamUserSchema } from '../../schemas/TeamSchema'
import { teamService } from '../../services'
import { PartialBy } from '../../utils/typeUtils'

export const TeamSchemaValidation: yup.Schema<Pick<TeamSchema, 'name'> & { users: { id: string }[] }> = yup.object({
  name: yup.string().required(),
  users: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required()
      })
    )
    .notRequired()
}) as any

const useStyles = makeStyles({
  tableFieldRoot: {
    maxWidth: 590
  }
})

interface TeamFormProps extends PartialBy<FormProps<Partial<TeamSchema>>, 'initialValues'> {
  selectedUserIds?: React.ReactText[]
  onSelectionChange(selection: React.ReactText[]): void
}

const TeamForm: React.FC<PropsWithChildren<TeamFormProps>> = ({
  initialValues,
  selectedUserIds,
  onSelectionChange,
  ...rest
}) => {
  const classes = useStyles()
  const [assignableUsers, setAssignableUsers] = useState<TeamUserSchema[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const response = await teamService.getAssignableUsers(initialValues?.id)
        setAssignableUsers(response.data)
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, initialValues?.id, t])

  return (
    <Form
      initialValues={{ ...initialValues, users: assignableUsers } || { users: assignableUsers }}
      validationSchema={TeamSchemaValidation}
      {...rest}
    >
      <OutlinedTextField name="name" label={t('pageTeams.modalEdit.Name')} />
      <TableField
        height="auto"
        name="users"
        className={classes.tableFieldRoot}
        getRowId={(rowData) => rowData.id}
        loading={loading}
        selectedIds={selectedUserIds}
        onSelectionChange={onSelectionChange}
        editingProps={{
          columnExtensions: [
            {
              columnName: 'fullName',
              editingEnabled: false
            },
            {
              columnName: 'email',
              editingEnabled: false
            }
          ]
        }}
        columns={[
          {
            name: 'fullName',
            title: t('pageTeams.modalEdit.boxUserfull')!
          },
          {
            name: 'email',
            title: t('pageTeams.modalEdit.boxUserEmail')!
          }
        ]}
        rows={[]}
      />
    </Form>
  )
}

export default TeamForm
