import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { PaymentContext } from './PaymentContext'

export const usePayment = () => {
  const infos = useContext(PaymentContext)
  const { enqueueSnackbar } = useSnackbar()

  if (!infos) {
    return null
  }

  const { accountInfo, planInfo, paymentInfo, setShowPresenter, setPresenterStatus } = infos

  if (accountInfo.isError || planInfo.isError) {
    // enqueueSnackbar(`Error ${accountInfo.error || planInfo.error}`, { variant: 'error' })
    return null
  }

  const canCreateAnalysis = () => {
    if (accountInfo.isLoading || planInfo.isLoading) {
      return {
        canCreate: false,
        isLoading: true
      }
    }
    return {
      canCreate: planInfo.data!.maxAnalysis - accountInfo.data!.analysis > 0,
      isLoading: false
    }
  }

  const canCreatePlugs = () => {
    if (accountInfo.isLoading || planInfo.isLoading) {
      return {
        canCreate: false,
        isLoading: true
      }
    }
    return {
      canCreate: planInfo.data!.maxPlugs - accountInfo.data!.plugs > 0,
      isLoading: false
    }
  }

  const canCreateUsers = () => {
    if (accountInfo.isLoading || planInfo.isLoading) {
      return {
        canCreate: false,
        isLoading: true
      }
    }
    return {
      canCreate: planInfo.data!.maxUsers - accountInfo.data!.activeUsers > 0,
      isLoading: false
    }
  }

  const hasDatabaseSpace = () => {
    if (accountInfo.isLoading || planInfo.isLoading) {
      return {
        canCreate: false,
        isLoading: true
      }
    }
    return {
      canCreate: planInfo.data!.maxDatabaseSize - accountInfo.data!.databaseSize > 0,
      isLoading: false
    }
  }

  const validateTrial = () => {
    closePresenter()
    if (accountInfo.isLoading || paymentInfo.isLoading) {
      return {
        isLoading: true,
        isValid: false
      }
    }
    if (paymentInfo?.data?.status === 'TRIAL_END') {
      setShowPresenter(true)
      setPresenterStatus('TRIAL_END')
      return {
        isLoading: false,
        isValid: false
      }
    }
    if (paymentInfo?.data?.status === 'TRIALING' || paymentInfo?.data?.status === 'ACTIVE') {
      return {
        isLoading: false,
        isValid: true
      }
    }
    // ANY OTHER STATUS IS INVALID
    setShowPresenter(true)
    setPresenterStatus(paymentInfo?.data?.status || 'ACTIVE')
    return {
      isLoading: false,
      isValid: false
    }
  }

  const reloadInfos = () => {
    accountInfo.revalidate()
    planInfo.revalidate()
    paymentInfo.revalidate()
    setShowPresenter(false)
  }

  const closePresenter = () => {
    setShowPresenter(false)
  }

  return {
    canCreateAnalysis,
    canCreatePlugs,
    canCreateUsers,
    hasDatabaseSpace,
    validateTrial,
    closePresenter,
    reloadInfos
  }
}
