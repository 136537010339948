import TreeView from 'devextreme/ui/tree_view'
import i18n from '../../../locales/i18next'
import { ValueOf } from '../../../utils/typeUtils'
import { defaultFieldSorting } from '../dataSource'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

class ExtractDateExtension implements ComponentExtension {
  name = 'ExtractDateExtension'
  icon = 'detailslayout'
  text = i18n.t('pivotGrid.extensions.extractFromDate.text')
  items: ComponentExtensionOption[]

  constructor(component: PivotGridComponent, field: PivotGridField) {
    const subOptions = [
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.day'),
        onItemClick: () => extractDate(field, dateExtractionFormats.DAY, component),
        selected: field.format === dateExtractionFormats.DAY,
        disabled: field.format === dateExtractionFormats.DAY
      },
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.dayOfWeek'),
        onItemClick: () => extractDate(field, dateExtractionFormats.DAY_OF_WEEK, component),
        selected: field.format === dateExtractionFormats.DAY_OF_WEEK,
        disabled: field.format === dateExtractionFormats.DAY_OF_WEEK
      },
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.month'),
        onItemClick: () => extractDate(field, dateExtractionFormats.MONTH, component),
        selected: field.format === dateExtractionFormats.MONTH,
        disabled: field.format === dateExtractionFormats.MONTH
      },
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.monthAndYear'),
        onItemClick: () => extractDate(field, dateExtractionFormats.MONTH_AND_YEAR, component),
        selected: field.format === dateExtractionFormats.MONTH_AND_YEAR,
        disabled: field.format === dateExtractionFormats.MONTH_AND_YEAR
      },
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.quarter'),
        onItemClick: () => extractDate(field, dateExtractionFormats.QUARTER, component),
        selected: field.format === dateExtractionFormats.QUARTER,
        disabled: field.format === dateExtractionFormats.QUARTER
      },
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.semester'),
        onItemClick: () => extractDate(field, dateExtractionFormats.SEMESTER, component),
        selected: field.format === dateExtractionFormats.SEMESTER,
        disabled: field.format === dateExtractionFormats.SEMESTER
      },
      {
        text: i18n.t('pivotGrid.extensions.extractFromDate.year'),
        onItemClick: () => extractDate(field, dateExtractionFormats.YEAR, component),
        selected: field.format === dateExtractionFormats.YEAR,
        disabled: field.format === dateExtractionFormats.YEAR
      }
    ]

    this.items = subOptions
  }
}

type FormatExtraction = ValueOf<typeof dateExtractionFormats>

const extractDate = (field: PivotGridField, formatExtraction: FormatExtraction, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  const fields = dataSource.fields()
  const cloneField = { ...field }
  delete cloneField._initProperties
  cloneField.caption = `${field.caption} (${getDateSufixCaption(formatExtraction)})`
  cloneField.index = fields.length
  cloneField.format = {
    type: 'fixedPoint',
    precision: 0
  }
  cloneField.areaIndex = field.areaIndex! - 1
  cloneField.groupInterval = formatExtraction as any
  cloneField.canBeRemoved = true
  cloneField.renamed = true
  cloneField.sortingMethod = defaultFieldSorting
  fields.push(cloneField)
  dataSource.fields(fields)
  // skip load when field chooser are open
  if (field.areaIndex !== null && field.areaIndex !== undefined) {
    field.areaIndex += 1
    const fieldIndex = fields.findIndex((x) => (x as PivotGridField).index === field.index)
    fields.splice(fieldIndex, 1, field)
    const sortedFields = fields.sortBy((x) => x.caption)
    sortedFields.forEach((x, i) => {
      ;(x as PivotGridField).index = i
    })
    dataSource.fields(sortedFields)
    dataSource.load()
  } else {
    const treeElem = component.getFieldChooserPopup().content().querySelector('.dx-treeview')
    if (treeElem) {
      const treeView = TreeView.getInstance(treeElem)
      if (treeView) {
        const items = treeView.option('items') as any[]
        const newItem = {
          field: cloneField,
          key: cloneField.dataField + '-clone',
          selected: false,
          text: cloneField.caption
        }
        items.push(newItem)
        const sortedItems = items.sortBy((x) => x.text)
        sortedItems.forEach((x, i) => {
          ;(x as PivotGridField).index = i
        })

        treeView.option('items', sortedItems)
      }
    }
  }
}

const getDateSufixCaption = (formatExtraction: FormatExtraction) => {
  if (formatExtraction === dateExtractionFormats.MONTH_AND_YEAR) {
    return 'YEAR-MONTH'
  }
  return formatExtraction.toLocaleUpperCase()
}

const dateExtractionFormats = {
  DAY: 'day',
  DAY_OF_WEEK: 'dayOfWeek',
  MONTH: 'month',
  MONTH_AND_YEAR: 'monthAndYear',
  QUARTER: 'quarter',
  SEMESTER: 'semester',
  YEAR: 'year'
}

// const dateFormatFunction: { [K in FormatExtraction]: PivotGridField['format'] } = {
//   day: 'day',
//   dayOfWeek: 'dayOfWeek',
//   month: {
//     formatter: (value: number | Date) => {
//       const monthDate = new Date(value)
//       return monthDate.toLocaleString('default', { month: 'long' })
//     },
//     parser: (value: string) => {
//       return Number.parseInt(value)
//     }
//   },
//   quarter: 'quarter',
//   year: 'year'
// }

export default ExtractDateExtension
