import useSWR from 'swr'
import { planService } from '../../services'
import useAuth from '../useAuth'

export const usePlanInfo = () => {
  const { validateAuth, getAccountInfo } = useAuth()

  const accountInfo = validateAuth() ? getAccountInfo() : null

  const { data, isLoading, error, mutate } = useSWR(
    () => (validateAuth() ? null : null),
    () => planService.get(accountInfo?.planId || 0)
  )

  return {
    data: data?.data,
    isLoading,
    isError: !!error,
    error: error,
    revalidate: mutate
  }
}
