import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React from 'react'
import AnalyzeIcon from '../../components/AnalyzeIcon'
import useAuth from '../../hooks/useAuth'
import usePlanLimits from '../../hooks/usePlanLimits/usePlanLimits'
import { AnalyzeType } from '../../schemas/AnalyzeSchema'

type CreateAnalysisButtonProps = {
  onAnalysisSelected: (analysisType: AnalyzeType) => void
}

export const CreateAnalysisButton: React.FC<CreateAnalysisButtonProps> = ({ onAnalysisSelected }) => {
  const auth = useAuth()
  const { loadUsage, loading, checkHasAnalysisLimit, showLimitReachedModal } = usePlanLimits()

  const userInfo = auth.getUserInfo()
  const canSeeButton = userInfo?.profile.managingAnalyseAndCollection

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleAnalysisSelection = (analysisType: AnalyzeType) => {
    setAnchorEl(null)
    onAnalysisSelected(analysisType)
  }

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(null)
    const currentTarget = e.currentTarget
    await loadUsage()
    if (!checkHasAnalysisLimit()) {
      showLimitReachedModal('analysis')
      return
    }
    setAnchorEl(currentTarget)
  }

  return (
    <div style={{ display: canSeeButton ? 'block' : 'none' }}>
      <LoadingButton
        variant="contained"
        loading={loading}
        sx={{
          height: 40
        }}
        onClick={handleClick}
        startIcon={<Add />}
      >
        Criar
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={() => handleAnalysisSelection(AnalyzeType.PIVOTGRID)}>
          <ListItemIcon>
            <AnalyzeIcon className="" fontSize="small" type={AnalyzeType.PIVOTGRID} />
          </ListItemIcon>
          <ListItemText>PIVOT GRID</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAnalysisSelection(AnalyzeType.DASHBOARD)}>
          <ListItemIcon>
            <AnalyzeIcon className="" fontSize="small" type={AnalyzeType.DASHBOARD} />
          </ListItemIcon>
          <ListItemText>DASHBOARD</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAnalysisSelection(AnalyzeType.REPORT)}>
          <ListItemIcon>
            <AnalyzeIcon className="" fontSize="small" type={AnalyzeType.REPORT} />
          </ListItemIcon>
          <ListItemText>RELATÓRIO</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAnalysisSelection(AnalyzeType.GEOANALYSE)}>
          <ListItemIcon>
            <AnalyzeIcon className="" fontSize="small" type={AnalyzeType.GEOANALYSE} />
          </ListItemIcon>
          <ListItemText>GEO ANÁLISE</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAnalysisSelection(AnalyzeType.COLLECTION)}>
          <ListItemIcon>
            <AnalyzeIcon className="" fontSize="small" type={AnalyzeType.COLLECTION} />
          </ListItemIcon>
          <ListItemText>COLEÇÃO</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}
