export enum GoogleScope {
  DRIVE_RO = 'https://www.googleapis.com/auth/drive.readonly',
  SHEETS_RO = 'https://www.googleapis.com/auth/spreadsheets.readonly',
  ANALYTICS_RO = 'https://www.googleapis.com/auth/analytics.readonly',
  OPENID = 'openid',
  USERINFO_PROFILE = 'https://www.googleapis.com/auth/userinfo.profile',
  USERINFO_EMAIL = 'https://www.googleapis.com/auth/userinfo.email',
  ADS = 'https://www.googleapis.com/auth/adwords'
}

export const GoogleScopeDiscoveryDocMap: { [key in GoogleScope]: string } = {
  [GoogleScope.DRIVE_RO]: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest', // v3
  [GoogleScope.SHEETS_RO]: 'https://sheets.googleapis.com/$discovery/rest?version=v4', // v4
  [GoogleScope.ANALYTICS_RO]: 'https://analyticsdata.googleapis.com/$discovery/rest?version=v1beta', // v1beta
  [GoogleScope.OPENID]: 'https://iam.googleapis.com/$discovery/rest?version=v1', // v1
  [GoogleScope.USERINFO_EMAIL]: 'https://iam.googleapis.com/$discovery/rest?version=v1', // v1
  [GoogleScope.USERINFO_PROFILE]: 'https://iam.googleapis.com/$discovery/rest?version=v1', // v1
  [GoogleScope.ADS]: '' // v1
}

export type OAuth2CodeFlowTokenResponse = {
  accessToken: string

  tokenType: string

  expires_in?: number

  scope: string

  id_token: string

  issuedUtc: Date
}

export type GoogleUserInfo = {
  id: string,
  email: string,
  verified_email: boolean,
  name: string,
  given_name: string,
  family_name: string,
  picture: string,
  locale: string
}
