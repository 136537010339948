import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import TextField from '../../components/TextField'

interface SaveDialogProps {
  open: boolean
  onClose(): void
  onSave(name: string): void
}

const SaveDialog: React.FC<PropsWithChildren<SaveDialogProps>> = ({ open, onClose, onSave }) => {
  const [name, setName] = useState('')
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const clearState = () => {
    setName('')
  }

  const handleClose = () => {
    clearState()
    onClose()
  }

  const handleSave = () => {
    if (!name) {
      setError(true)
      return
    }
    onSave(name)
  }

  return (
    <Dialog
      title={t('pivotGrid.menuSettings.modalSave.Title')}
      description={t('pivotGrid.menuSettings.modalSave.Subtitle')}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <TextField
          style={{ marginTop: '6px' }}
          error={error}
          required
          helperText={error && 'Name is required'}
          label={t('pivotGrid.menuSettings.modalSave.Name')}
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button label={t('pivotGrid.menuSettings.modalSave.ButtonSave')} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

export default SaveDialog
