import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { ReactNode, useEffect } from 'react'
import { FieldPropValues } from './types'

export interface FormProps<TValues> {
  className?: string
  initialValues: TValues
  onSubmit(values: TValues): void | Promise<any>
  validationSchema?: any | (() => any)
  children?: ReactNode
  isSubmitting?: boolean
  initialTouched?: boolean
  hidden?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    height: 'auto',
    padding: '1rem',
    display: 'grid',
    gridTemplateColumns: 'minmax(150px, auto)',
    gap: '1rem'
  },
  hidden: {
    display: 'none!important'
  }
}))

const Form = <TValues extends FieldPropValues>({
  className,
  initialValues,
  onSubmit,
  validationSchema,
  children,
  initialTouched,
  hidden,
  isSubmitting = false
}: FormProps<TValues>): React.ReactElement => {
  const formik = useFormik({
    initialTouched: initialTouched
      ? (Object.keys(initialValues).map((k) => ({
          [k]: true
        })) as any)
      : undefined,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: onSubmit
  })
  const classes = useStyles()
  const { submitForm } = formik
  useEffect(() => {
    if (isSubmitting) submitForm()
  }, [submitForm, isSubmitting])

  return (
    <form onSubmit={formik.handleSubmit} className={clsx(classes.root, className, { [classes.hidden]: hidden })}>
      {React.Children.map(children, (ch) => {
        if (React.isValidElement(ch)) {
          return React.cloneElement(ch, { ...formik })
        }
        return ch
      })}
    </form>
  )
}

export default Form
