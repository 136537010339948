import { Info } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent, DialogPropsWithTitle } from '../../components/Dialog'
import DraggableList from '../../components/DraggableList'
import SelectBox from '../../components/SelectBox'
import TextField from '../../components/TextField'
import WarningContainer from '../../components/WarningContainer'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'
import { CollectionSchema, CreateCollectionSchema, UpdateCollectionSchema } from '../../schemas/CollectionSchema'
import { analyzeService } from '../../services'
import collectionService from '../../services/collectionService'
import AddedAnalyzeToCollectionListItem from './AddedAnalyzeToCollectionListItem'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateOrEditCollectionDialogProps extends Omit<DialogPropsWithTitle, 'onClose'> {
  onClose(refresh?: boolean): void
  collection: CollectionSchema | null
}

const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing(1, 0)
  }
}))

const reorder = (list: AnalyzeSchema[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const CreateOrEditCollectionDialog: React.FC<PropsWithChildren<CreateOrEditCollectionDialogProps>> = ({
  onClose,
  open,
  collection,
  ...rest
}) => {
  const [analysis, setAnalysis] = useState<AnalyzeSchema[]>([])
  const [selectedAnalyze, setSelectedAnalyze] = useState<AnalyzeSchema | null>(null)
  const [addedAnalysis, setAddedAnalysis] = useState<AnalyzeSchema[]>([])
  const [collectionName, setCollectionName] = useState<string>('')
  const [haveError, setHaveError] = useState(false)
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiDeletion, setLoadingApiDeletion] = useState(false)
  const { t } = useTranslation()

  const handleChangeCollectionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionName(event.target.value)
    if (!event.target.value) {
      setHaveError(true)
      return
    }
    setHaveError(false)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const items = reorder(addedAnalysis, result.source.index, result.destination.index)

    setAddedAnalysis(items)
  }

  const handleSelectAnalyze = (event: any, value: AnalyzeSchema | string | null) => {
    if (value) {
      setSelectedAnalyze(value as AnalyzeSchema)
      setAddedAnalysis((prev) => [...prev, value as AnalyzeSchema])
      setAnalysis((prev) => [...prev.filter((e) => e.id !== (value as AnalyzeSchema).id)])
      setSelectedAnalyze(null)
    }
  }

  const handleDeselectAnalyze = (item: AnalyzeSchema) => {
    setAddedAnalysis((prev) => [...prev.filter((e) => e.id !== item.id)])
    setAnalysis((prev) => [...prev, item])
  }

  const loadAnalysis = useCallback(async () => {
    try {
      const analysisResp = await analyzeService.getAnalysis()
      if (!collection) {
        setAnalysis(analysisResp.data)
        return
      }
      const analysisIntersection = analysisResp.data.filter((a) => !collection.analysis.some((e) => e.id === a.id))
      setAnalysis(analysisIntersection)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('geoAnalysis.modalCollection.toast.error.loadingAnalysis'), { variant: 'error' })
    }
  }, [collection, enqueueSnackbar, t])

  const exceptAddedAnalysis = useCallback(() => {
    if (collection) {
      setCollectionName(collection.name)
      if (collection.analysis.length > 0) {
        setAddedAnalysis(collection.analysis.sortBy((x) => x.ordination))
        setAnalysis((prev) => [...prev.filter((a) => !collection.analysis.some((e) => e.id === a.id))])
      }
    }
  }, [collection])

  const handleClose = (refresh?: boolean) => {
    onClose(refresh)
    clearAnalysisAndResetState()
  }

  const clearAnalysisAndResetState = useCallback(() => {
    setAnalysis([])
    setAddedAnalysis([])
    setCollectionName('')
    setHaveError(false)
    setSelectedAnalyze(null)
    setDeleteConfirmed(false)
  }, [])

  useEffect(() => {
    ;(async function wrapperAsync() {
      if (open) {
        await loadAnalysis()
        exceptAddedAnalysis()
      }
    })()
  }, [loadAnalysis, open, exceptAddedAnalysis])

  const handleCreateCollection = async () => {
    if (!collectionName) {
      setHaveError(true)
      return
    }
    try {
      setLoadingApi(true)
      const newCollection: CreateCollectionSchema = {
        name: collectionName,
        analyzeIds: addedAnalysis.map((e) => e.id)
      }
      await collectionService.create(newCollection)
      handleClose(true)
      enqueueSnackbar(
        t('geoAnalysis.modalCollection.toast.success.collectionCreated', { collectionName: collectionName }),
        { variant: 'success' }
      )
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('geoAnalysis.modalCollection.toast.error.creatingCollection'), { variant: 'error' })
    }
    setLoadingApi(false)
  }

  const handleUpdateCollection = async () => {
    if (!collectionName) {
      setHaveError(true)
      return
    }
    try {
      setLoadingApi(true)

      const updateCollection: UpdateCollectionSchema = {
        id: collection!.id,
        name: collectionName,
        analyzeIds: addedAnalysis.map((e) => e.id)
      }
      await collectionService.update(collection!.id, updateCollection)
      handleClose(true)
      enqueueSnackbar(
        t('geoAnalysis.modalCollection.toast.success.collectionUpdated', { collectionName: collectionName }),
        { variant: 'success' }
      )
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('geoAnalysis.modalCollection.toast.error.updatingCollection'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const handleDeleteCollection = async () => {
    if (!deleteConfirmed) {
      setDeleteConfirmed(true)
      return
    }
    try {
      setLoadingApiDeletion(true)

      await collectionService._delete(collection!.id)
      handleClose(true)
      enqueueSnackbar(
        t('geoAnalysis.modalCollection.toast.success.collectionDeleted', { collectionName: collectionName }),
        { variant: 'success' }
      )
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('geoAnalysis.modalCollection.toast.error.deleteCollection'), { variant: 'error' })
    }
    setLoadingApiDeletion(false)
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ style: { minHeight: '85%', display: 'inline-flex', flexDirection: 'column', overflow: 'unset' } }}
      onClose={() => handleClose()}
      {...rest}
    >
      <DialogContent dividers>
        <TextField
          required
          error={haveError}
          helperText={haveError ? t('pageHome.body.modalCreate.Collection.field.required.collectionName') : ''}
          className={classes.field}
          name="collection-name"
          label={t('pageHome.body.modalCreate.Collection.collectionName')}
          value={collectionName}
          onChange={handleChangeCollectionName}
        />
        <SelectBox
          multiple={false}
          clearOnBlur
          blurOnSelect
          clearOnEscape
          className={classes.field}
          options={analysis}
          getOptionLabel={(opt) => opt.name}
          onChange={handleSelectAnalyze}
          textFieldProps={{
            name: 'select-analysis',
            label: t('pageHome.body.modalCreate.Collection.chooseAnalysis'),
            placeholder: t('pageHome.body.modalCreate.Collection.field.analysis.placeholder')!
          }}
          value={selectedAnalyze}
          inputValue={selectedAnalyze?.name || ''}
        />
        <Box className={classes.field} style={{ display: 'inline-block', width: '100%', padding: '4px' }}>
          <Typography component="div" variant="subtitle1">
            {t('pageHome.body.modalCreate.Collection.title')}
          </Typography>
          <Typography style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Info fontSize="small" /> {t('pageHome.body.modalCreate.Collection.subtitleCreate.empty.reorder')}
          </Typography>
          <Divider />

          <DraggableList
            renderRow={(item) => <AddedAnalyzeToCollectionListItem item={item} onCloseClick={handleDeselectAnalyze} />}
            getItemId={(it) => it.id}
            onDragEnd={onDragEnd}
            items={addedAnalysis}
            noItemsText={t('pageHome.body.modalCreate.Collection.subtitleCreate.empty.addUsers')!}
          />
          {deleteConfirmed && (
            <WarningContainer
              message={t('pageHome.body.modalCreate.Collection.deleteWarning')}
              relatedDependencies={[]}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {collection && (
          <Button
            variant="contained"
            color="primary"
            label={
              deleteConfirmed
                ? t('pageHome.body.modalCreate.Collection.buttonConfirmDelete')
                : t('pageHome.body.modalCreate.Collection.buttonDelete')
            }
            isDelete
            loading={loadingApiDeletion}
            onClick={handleDeleteCollection}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          loading={loadingApi}
          label={
            collection
              ? t('pageHome.body.modalCreate.Collection.buttonUpdate')
              : t('pageHome.body.modalCreate.Collection.buttonCreate')
          }
          onClick={collection ? handleUpdateCollection : handleCreateCollection}
        />
      </DialogActions>
    </Dialog>
  )
}

export default CreateOrEditCollectionDialog
