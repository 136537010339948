const BASE64_MARKER = ';base64,'
const CONTENT_TYPE_BEGIN_MARKER = 'data:'
const CONTENT_TYPE_END_MARKER = ';'

function convertDataURIToBinary(dataURI: string): Blob {
  const contentTypeBeginIndex = dataURI.indexOf(CONTENT_TYPE_BEGIN_MARKER) + CONTENT_TYPE_BEGIN_MARKER.length
  const contentTypeEndIndex = dataURI.indexOf(CONTENT_TYPE_END_MARKER)
  const contentType = dataURI.substring(contentTypeBeginIndex, contentTypeEndIndex)

  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  const base64 = dataURI.substring(base64Index)
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  const byteArrays = []
  byteArrays.push(array)
  return new Blob(byteArrays, { type: contentType })
}

async function convertBlobToDataUri(blob: Blob): Promise<string> {
  const reader = new FileReader()
  await new Promise((resolve, reject) => {
    reader.onload = resolve
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
  return reader.result as string
}

function isValidBase64(value: string): boolean {
  if (!value) return false

  try {
    atob(value)
    return true
  } catch {
    return false
  }
}

export function utf8_to_b64(str: string) {
  return window.btoa(decodeURI(encodeURIComponent(str)))
}

export function b64_to_utf8(str: string) {
  return decodeURIComponent(encodeURI(window.atob(str)))
}

export function parseJwt(token: string) {
  if (!token) {
    return
  }
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export default { convertDataURIToBinary, convertBlobToDataUri, isValidBase64 }
