import i18next from '../locales/i18next'
import { PlanSupportFeature, PlanUpdateFrequency } from '../schemas/PlanSchema'
import { PlugSchema, PlugType } from '../schemas/PlugSchema'

export const getUpdateFrequencyFriendlyText = (updateFrequency: PlanUpdateFrequency): string => {
  switch (updateFrequency) {
    case 'NONE':
      return i18next.t('pageAccount.tabGeneral.label.manualUpdates')

    case '_15MIN':
      return i18next.t('pageAccount.tabGeneral.label.every15')

    case '_12H':
      return i18next.t('pageAccount.tabGeneral.label.every12hrs')

    case '_24H':
      return i18next.t('pageAccount.tabGeneral.label.every24hrs')

    default:
      return ' '
  }
}
export const getSupportFriendlyText = (supportFeature: PlanSupportFeature): string => {
  switch (supportFeature) {
    case 'EMAIL':
      return i18next.t('pageAccount.tabGeneral.label.supportEmail')

    case 'EMAIL_CHAT':
      return i18next.t('pageAccount.tabGeneral.label.supportEmailChat')

    case 'EMAIL_CHAT_PHONE':
      return i18next.t('pageAccount.tabGeneral.label.supportEmailChatPhone')

    default:
      return ' '
  }
}

export const isApiType = (plug: PlugSchema) => {
  return isPlugApiType(plug.type)
}

const plugDatabaseTypes = [
  PlugType.MsSql,
  PlugType.MySql,
  PlugType.Firebird,
  PlugType.Redshift
]

export const isPlugApiType = (plugType: PlugType) => {
  return !plugDatabaseTypes.includes(plugType)
}

export const enumToArray = <T extends object>(enumType: T): T[keyof T][] => {
  const keys = Object.keys(enumType).filter((k) => typeof enumType[k as keyof T] === 'string')
  const values = keys.map((k) => enumType[k as keyof T])
  return values
}
