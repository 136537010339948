import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid'
import { Autocomplete, Chip, Stack, TextField } from '@mui/material'
import { styled } from '@mui/system'
import React, { ComponentType } from 'react'

const ChipPreserveSpaces = styled(Chip)(() => ({
  '.MuiChip-label': {
    whiteSpace: 'pre'
  }
}))

const AutocompleteTypeFormatter: ComponentType<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return <TextField size="small" fullWidth variant="filled" />
  }

  if (Array.isArray(value)) {
    return (
      <Stack direction="row" spacing={1}>
        {value.map((x) => (
          <ChipPreserveSpaces label={x} />
        ))}
      </Stack>
    )
  }
  if (!!value) {
    return <Chip label={value} />
  }
  return <div></div>
}

const AutocompleteTypeEditor: ComponentType<DataTypeProvider.ValueEditorProps & { multiple?: boolean }> = ({
  value,
  onValueChange,
  multiple = true,
  autoFocus,
  onBlur,
  onFocus,
  disabled,
  column,
  row
}) => (
  <Autocomplete
    fullWidth
    disabled={disabled}
    options={[]}
    onBlur={onBlur}
    onFocus={onFocus}
    multiple={multiple}
    id={`autocomplete-${column.name}-${row.userId}`}
    value={Array.isArray(value) ? value : !!value ? (multiple ? [value] : value) : value}
    filterOptions={(options, params) => {
      if (!multiple) {
        return options
      }
      const { inputValue } = params

      if (inputValue !== '') {
        options.push({
          inputValue,
          title: `Add "${inputValue}"`,
          created: false
        })
      }

      return options
    }}
    getOptionLabel={(option) => {
      if (!multiple) {
        if (Array.isArray(option)) {
          return option[0] || ''
        }
        return option
      }
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option
      }
      // Add "xxx" option created dynamically
      if (option?.created) {
        return option.inputValue
      }

      return option.title
    }}
    renderTags={(value: readonly string[], getTagProps) => {
      return value.map((option: any, index: number) => {
        if (typeof option === 'string') {
          return <ChipPreserveSpaces label={option} {...getTagProps({ index })} />
        }
        return <ChipPreserveSpaces label={option.inputValue} {...getTagProps({ index })} />
      })
    }}
    onInputChange={!multiple ? (e, val) => onValueChange(val) : undefined}
    onChange={
      multiple
        ? (event, newValues) => {
            if (!newValues) {
              onValueChange(newValues)
              return
            }

            if (!multiple) {
              if (typeof newValues === 'string') {
                onValueChange(newValues)
                return
              }
              onValueChange(newValues.inputValue)
              return
            }
            const rawValues = newValues.map((x: any) => {
              if (typeof x === 'string') {
                return x
              }
              x.created = true
              return x.inputValue
            })
            onValueChange(rawValues)
          }
        : undefined
    }
    freeSolo
    renderInput={(params) => (
      <TextField {...params} sx={{ p: '8px 0' }} size="small" autoFocus={autoFocus} fullWidth variant="filled" />
    )}
  />
)

function resolveMultipleParam<T>(
  multipleParam: boolean | undefined | ((value: T) => boolean),
  deferredValue: T
): boolean {
  if (!multipleParam) {
    return false
  }
  if (typeof multipleParam === 'function') {
    return multipleParam(deferredValue)
  }
  return multipleParam
}

const AutocompleteTypeProvider: React.ComponentType<
  DataTypeProviderProps & { multiple?: boolean | undefined | ((value: any) => boolean) }
> = ({ multiple, ...props }) => (
  <DataTypeProvider
    formatterComponent={AutocompleteTypeFormatter}
    editorComponent={(edtProps) => (
      <AutocompleteTypeEditor {...edtProps} multiple={resolveMultipleParam(multiple, edtProps.row)} />
    )}
    {...props}
  />
)

export default AutocompleteTypeProvider
