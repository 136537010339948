import Axios, { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import api from '../api'

let apiClient = api

export type GeoAnalysisLoadDataParams = {
  latitude: string
  longitude: string
  plugId: number
  dimensions?: Array<string>
  values: Array<string>
  filter?: any
  insideValue?: string | undefined | null
  insideValueSummaryType?: string | undefined | null
}

export type GeoAnalysisLoadDataResponse = {
  lat: number
  lng: number
  values: any[]
}

if (process.env.NODE_ENV === 'development') {
  apiClient = Axios.create({
    baseURL: 'https://localhost:5003/api/v1',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [
      function (data) {
        if (data) return JSON.stringify(data)
        return null
      }
    ],
    transformResponse: [
      function (data, headers) {
        if (headers!['content-type'] && headers!['content-type'].includes('application/json'))
          if (data) return JSON.parse(data)
        return null
      }
    ]
  })

  apiClient.interceptors.request.use((req) => {
    const token = localStorage.getItem('token')
    if (token) {
      req.headers!['Authorization'] = 'Bearer ' + token
    }
    return req
  })
}

const loadData = (params: GeoAnalysisLoadDataParams): Promise<AxiosResponse<GeoAnalysisLoadDataResponse[]>> => {
  return apiClient.post(ENDPOINTS.LOAD_GEOANALYSIS_DATA, params)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { loadData }
