import { Box, Grid, Typography, useMediaQuery, useTheme, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const FeedbackPage: React.FC = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <Box position="absolute" display="flex" top={0} left={0} right={0} bottom={0}>
      <Grid container direction={matches ? 'column' : 'row'} wrap="nowrap" columns={{ xs: 3, md: 3, lg: 12 }}>
        <Grid display="flex" justifyContent="center" item xs={1} md={1} lg={6} bgcolor="#3c3f48">
          <a href="https://pluggerbi.com">
            <img
              style={{
                width: matches ? 150 : 500,
                height: matches ? 150 : 500
              }}
              src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/logo_bg_transp.png"
              alt="Plugger BI logo"
            />
          </a>
        </Grid>
        <Grid item xs={2} md={2} lg={6}>
          <Stack direction="column" height="100%" spacing={4}>
            <Typography
              sx={{
                textTransform: 'uppercase'
              }}
              padding={2}
              paddingBottom={0}
              textAlign="center"
              variant="h1"
            >
              {t('feedbackpage.matters')}
            </Typography>
            <iframe
              title="feedbackForm"
              src="https://intelectivo.movidesk.com/form/6982/"
              height="700"
              seamless
              style={{
                border: 'none'
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
