import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, Theme, ThemeProvider as TP } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import SnackBarProvider from '../src/providers/SnackBarProvider'
import App from './App'
import { PaymentProvider } from './hooks/usePayment/PaymentContext'
import AuthProvider from './providers/AuthProvider'
import PlanLimitsProvider from './providers/PlanLimitsProvider/PlanLimitsContext'
import lightTheme from './themes/app/light'
import './themes/devexpress'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Router>
    <CssBaseline />
    <SnackBarProvider>
      <StyledEngineProvider injectFirst>
        <TP theme={lightTheme}>
          <ThemeProvider theme={lightTheme}>
            <AuthProvider>
              <PaymentProvider>
                <PlanLimitsProvider>
                  <App />
                </PlanLimitsProvider>
              </PaymentProvider>
            </AuthProvider>
          </ThemeProvider>
        </TP>
      </StyledEngineProvider>
    </SnackBarProvider>
  </Router>
)

// eslint-disable-next-line no-console
//reportWebVitals(console.log)
