import { ResourceManager } from 'devexpress-dashboard'
import { locale as configDevexpress } from "devextreme/localization"
import moment from 'moment'
import 'moment/locale/pt-br'
import * as yup from 'yup'
import './devexpress'
import i18n, { loadI18n } from './i18next'

export const loadLocale = (locale: string) => {
  console.log('current locale', locale)
  try {
    configDevexpress(locale)
    if (locale === 'en') {
      ResourceManager.setLocalizationMessages({})
    } else {
      ResourceManager.setLocalizationMessages(require(`./dx-dashboard.${locale}.json`))
      ResourceManager.setLocalizationMessages(require(`./dx-analytics-core.${locale}.json`))
    }
  } catch (err) {}
  moment.locale(locale)

  yup.setLocale({
    mixed: {
      required: ({ label, path }) => i18n.t('validation.required', { field: label || path })!
    },
    string: {
      email: i18n.t('validation.email')!,
      length: ({ length, label }) => i18n.t('validation.length', { field: label, length: length }),
      min: ({ min, label }) => i18n.t('validation.min', { field: label, min }),
      max: ({ max, label }) => i18n.t('validation.max', { field: label, max })
    },
    number: {
      min: ({ min, label }) => i18n.t('validation.min', { field: label, min }),
      max: ({ max, label }) => i18n.t('validation.max', { field: label, max }),
      moreThan: ({ more, label }) => i18n.t('validation.moreThan', { field: label, more }),
      lessThan: ({ less, label }) => i18n.t('validation.lessThan', { field: label, less })
    }
  })
  return loadI18n(locale)
}
