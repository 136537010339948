import { PlugSchema } from './PlugSchema'
import { PlugClaimType } from './UserSchema'

export enum AnalyzeType {
  'PIVOTGRID' = 'PIVOT_GRID',
  'DASHBOARD' = 'DASHBOARD',
  'REPORT' = 'REPORT',
  'GEOANALYSE' = 'GEOANALYSE',
  'COLLECTION' = 'COLLECTION'
}

export interface AnalyzeUserOwner {
  id: string
  fullName: string
  email: string
}

export interface AnalyzeSchema {
  id: string
  name: string
  type: AnalyzeType
  plugs: Array<PlugSchema>
  userOwner: AnalyzeUserOwner
  claimType: PlugClaimType
  accountId: number
  canExportData: boolean
  claimIssuer: {
    id: string
    name: string
  }
}

export interface AnalyzeWithStateSchema extends AnalyzeSchema {
  state: string
}

export interface CreateAnalyzeSchema
  extends Omit<
    AnalyzeSchema,
    'id' | 'plugs' | 'userOwner' | 'claimType' | 'accountId' | 'canExportData' | 'claimIssuer'
  > {
  jsonState: any
  plugIds: Array<number>
}

export interface PartialUpdateAnalyzeSchema
  extends Omit<
    AnalyzeSchema,
    'plugs' | 'type' | 'name' | 'userOwner' | 'claimType' | 'accountId' | 'canExportData' | 'claimIssuer'
  > {
  id: string
  name?: string
  jsonState?: any
  plugIds?: Array<number>
}

export enum ShareType {
  Analysis_RO = 'http://schemas.PluggerBI.com/2021/03/analyzesid:RO',
  Analysis_RW = 'http://schemas.PluggerBI.com/2021/03/analyzesid:RW',
  Collection_RO = 'http://schemas.PluggerBI.com/2021/03/collectionsid:RO',
  Collection_RW = 'http://schemas.PluggerBI.com/2021/03/collectionsid:RW',
  NoShare = 'NoShare',
  Copy = 'Copy'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isAnalyzeSchema = (obj: any): obj is AnalyzeSchema => {
  return obj && typeof obj === 'object' && 'type' in obj && obj['type'] !== AnalyzeType.COLLECTION
}
