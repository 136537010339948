import { Add } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Swipeable from 'react-swipeable-views'
import * as yup from 'yup'
import SelectBox from '../../../../../../components/SelectBox'
import TextField from '../../../../../../components/TextField'
import { ApiConnectionType, ConnectionSchema } from '../../../../../../schemas/ConnectionSchema'
import { connectionService } from '../../../../../../services'
import googleAdsService from '../../../../../../services/google/googleAdsService'
import NavigationHeader, { NavigationHeaderProps } from '../../NavigationHeader'

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(3, 0, 0)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

export type GoogleAdsPlugFormValues = {
  plugName: string
  customerId: number
  connection: ConnectionSchema
}

type GoogleAdsPlugFormProps = {
  onSubmit: (values: GoogleAdsPlugFormValues) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const plugFormSchemaValidator = yup.object({
  plugName: yup.string().required(),
  customerId: yup
    .string()
    .required()
    .test(
      'customerId-is-valid',
      'the customer id is not valid',
      async (value, ctx) => (await googleAdsService.checkCustomerId(ctx.parent.connection.id, value || '')).data.isValid
    ),
  connection: yup.object().required()
})

const GoogleAdsPlugForm: React.FC<PropsWithChildren<GoogleAdsPlugFormProps>> = ({ navigationProps, onSubmit }) => {
  const classes = useStyles()
  const [connections, setConnections] = useState<ConnectionSchema[]>([])

  const [loadingFindConnection, setLoadingFindConnection] = useState(false)

  const [loadingCreateConnection, setLoadingCreateConnection] = useState(false)
  const [showCreateConnectionForm, setShowCreateConnectionForm] = useState(false)
  const { slideUpdateHeight } = useContext((Swipeable as any).SwipeableViewsContext) as any

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { values, setFieldValue, submitForm, errors, isValidating } = useFormik({
    validateOnChange: false,
    initialValues: {} as GoogleAdsPlugFormValues,
    onSubmit: onSubmit,
    validationSchema: plugFormSchemaValidator
  })

  const handleValuesChange = (key: string, value: any) => {
    setFieldValue(key, value)
  }

  const handleOpenSelectConnection = () => {
    ;(async () => {
      try {
        setLoadingFindConnection(true)
        const connsResp = await connectionService.getAll()
        setConnections(connsResp.data.filter((x) => x.type === ApiConnectionType.GoogleApis))
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindConnection(false)
      }
    })()
  }

  const handleCreateConnection = async (values: any) => {
    values.type = ApiConnectionType.GoogleApis
    try {
      setLoadingCreateConnection(true)
      const response = await connectionService.create(values)

      setConnections((prev) => [...prev, response.data])
      handleValuesChange('connection', response.data)
      setShowCreateConnectionForm(false)

      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createConnection', { connectionName: values.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.createConnection'), { variant: 'error' })
    } finally {
      setLoadingCreateConnection(false)
    }
  }

  useEffect(() => {
    slideUpdateHeight()
  }, [showCreateConnectionForm, slideUpdateHeight])

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() => submitForm()}
      />
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Typography variant="h2" align="center" component="div" color="inherit">
          {t('pagePlugs.modalCreate.configureTitle')}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p" color="inherit">
          {t('pagePlugs.modalCreate.configureSubtitle')}
        </Typography>
        {!showCreateConnectionForm ? (
          <>
            <Box display="flex" alignItems="center" className={classes.dialogFields}>
              <SelectBox
                className={clsx(classes.outlinedWhiteInput, classes.fullWidth)}
                options={connections}
                value={values.connection}
                onChange={(event, newValue) => handleValuesChange('connection', newValue)}
                getOptionLabel={(o) => o.name}
                loading={loadingFindConnection}
                onOpen={handleOpenSelectConnection}
                textFieldProps={{
                  placeholder: t('pagePlugs.modalCreate.configureConnection')!,
                  name: 'connection',
                  error: !!errors['connection'],
                  helperText: errors['connection'] as any
                }}
              />
              <IconButton onClick={() => setShowCreateConnectionForm(true)} color="inherit" size="large">
                <Add />
              </IconButton>
            </Box>
            <TextField
              variant="outlined"
              fullWidth={false}
              value={values.customerId}
              onChange={(event) => handleValuesChange('customerId', event.target.value)}
              required
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              placeholder="CustomerId"
              error={!!errors['customerId']}
              helperText={errors['customerId']}
              InputProps={{
                endAdornment: isValidating && <CircularProgress color="inherit" size={20} />
              }}
            />
            <TextField
              variant="outlined"
              fullWidth={false}
              value={values.plugName}
              onChange={(event) => handleValuesChange('plugName', event.target.value)}
              required
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              placeholder={t('pagePlugs.modalCreate.configureName')!}
              error={!!errors['plugName']}
              helperText={errors['plugName']}
            />
          </>
        ) : (
          <></>
          // <GoogleConnectionForm
          //   onSubmit={handleCreateConnection}
          //   onCancelClick={() => setShowCreateConnectionForm(false)}
          //   variant="white"
          //   usePlaceholder
          // />
        )}
      </Box>
    </>
  )
}

export default GoogleAdsPlugForm
