import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid'
import { Autocomplete, TextField } from '@mui/material'

const AutoCompleteTypeEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const { value, onValueChange, onBlur, onFocus } = props
  return (
    <Autocomplete
      fullWidth
      options={['STRING', 'NUMERIC', 'DATETIME', 'DATE']}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      onChange={(e, v) => onValueChange(v)}
      freeSolo
      renderInput={(params) => <TextField {...params} sx={{ p: '8px 0' }} size="small" fullWidth />}
    />
  )
}

export const AutoCompleteTypeProvider = (props: DataTypeProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => <TextField size="small" fullWidth value={value} />}
      editorComponent={(edtProps) => <AutoCompleteTypeEditor {...edtProps} />}
      {...props}
    />
  )
}
