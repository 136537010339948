import { Cancel, Edit } from '@mui/icons-material'
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import EasyEdit, { Types } from 'react-easy-edit'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EditableAvatar from '../../components/EditableAvatar'
import useAuth from '../../hooks/useAuth'
import { usePaymentInfo } from '../../hooks/usePaymentInfo/usePaymentInfo'
import { AuthenticatedAuthProviderProps } from '../../providers/AuthProvider/types'
import { ResponseAccountUsedCapabilitiesSchema } from '../../schemas/AccountSchema'
import { PlanSchema } from '../../schemas/PlanSchema'
import { accountService, planService, staticService } from '../../services/index'
import base64Utils from '../../utils/base64Utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  companyNameInput: {
    fontSize: '1.5rem'
  },
  editContainer: {
    flex: '1 0 0'
  },
  planContainer: {
    flex: '3 0 1',
    display: 'flex',
    justifyContent: 'space-between'
  },
  progressInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  progressInfo: {
    flex: '1 1 auto',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  iconLabelInfo: {
    flex: '1 1 0',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center'
  },
  iconInfo: {
    margin: theme.spacing(1)
  }
}))

export interface AccountPlanInfo {
  planInfo: PlanSchema
  accountInfo: ResponseAccountUsedCapabilitiesSchema
}

const generateUTCItems = () => {
  const utcItems = []
  for (let i = -12; i < 0; i++) {
    utcItems.push({
      label: 'UTC' + i,
      value: i
    })
  }
  utcItems.push({
    label: 'UTC+0',
    value: 0
  })
  for (let i = 1; i <= 12; i++) {
    utcItems.push({
      label: 'UTC+' + i,
      value: i
    })
  }
  return utcItems
}

const UTCS = generateUTCItems()

const General: React.FC = () => {
  const auth = useAuth() as AuthenticatedAuthProviderProps
  const classes = useStyles()
  const [accountPlanInfo, setAccountPlanInfo] = useState<AccountPlanInfo>({
    planInfo: {},
    accountInfo: {}
  } as AccountPlanInfo)
  const { enqueueSnackbar } = useSnackbar()
  const [selectedUTC, setSelectedUTC] = useState(UTCS.find((x) => x.value === 0))
  const { t } = useTranslation()
  const [confirmAccountDeleteDialogOpen, setConfirmAccountDeleteDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingInfos, setLoadingInfos] = useState(false)
  const navigate = useNavigate()
  const { data: paymentInfo } = usePaymentInfo()

  const loadData = useCallback(async () => {
    setLoadingInfos(true)
    try {
      const accountPromise = accountService.getCurrentUsedCapabilities()
      const planPromise = planService.get(auth.getAccountInfo().planId)

      setSelectedUTC(UTCS.find((x) => x.value === auth.getAccountInfo().timeZone))

      const [accountResponse, planResponse] = await Promise.all([accountPromise, planPromise])
      setAccountPlanInfo({
        accountInfo: accountResponse.data,
        planInfo: planResponse.data
      })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoadingInfos(false)
  }, [auth, enqueueSnackbar, t])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleUpdateAvatar = (base64: string, fileName: string) => {
    accountService
      .updateAccount({
        id: auth.getAccountInfo().accountId,
        companyName: auth.getAccountInfo().companyName,
        logoImage: base64,
        fileName,
        timeZone: selectedUTC!.value
      })
      .then(() => {
        enqueueSnackbar(t('pageAccount.tabGeneral.toast.success.saveImage'), { variant: 'success' })
        auth.loadUserData()
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      })
  }

  const handleUpdateCompanyName = async (companyName: string) => {
    let companyImage = null
    const imagePath = auth.getAccountInfo().imagePath
    if (imagePath) {
      const response = await staticService.get(imagePath)
      companyImage = await base64Utils.convertBlobToDataUri(response.data)
    }
    accountService
      .updateAccount({
        id: auth.getAccountInfo().accountId,
        companyName: companyName,
        logoImage: companyImage,
        fileName: 'anything.png',
        timeZone: selectedUTC!.value
      })
      .then(() => {
        enqueueSnackbar(t('pageAccount.tabGeneral.toast.success.saveCompanyName'), { variant: 'success' })
        auth.loadUserData()
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      })
  }

  const handleUpdateUtc = async (value: typeof selectedUTC) => {
    let prevValue = selectedUTC
    let companyImage = null
    const imagePath = auth.getAccountInfo().imagePath
    if (imagePath) {
      const response = await staticService.get(imagePath)
      companyImage = await base64Utils.convertBlobToDataUri(response.data)
    }
    setSelectedUTC(value)
    accountService
      .updateAccount({
        id: auth.getAccountInfo().accountId,
        companyName: auth.getAccountInfo().companyName,
        logoImage: companyImage,
        fileName: 'anything.png',
        timeZone: value!.value
      })
      .then(() => {
        enqueueSnackbar(t('pageAccount.tabGeneral.toast.success.timezone'), { variant: 'success' })
        auth.loadUserData()
      })
      .catch((err) => {
        setSelectedUTC(prevValue)
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      })
  }
  const handleMarkAccountToDelete = async (password: string) => {
    setLoading(true)
    const { accountId } = auth.getAccountInfo()!
    try {
      await accountService.markToDelete(accountId, password)
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const { data } = err.response!
        enqueueSnackbar(data[0].errorCode + ' - ' + data[0].errorMessage, { variant: 'error' })
      } else {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      console.error(err)
      setLoading(false)
      return
    }
    setLoading(false)
    setConfirmAccountDeleteDialogOpen(false)
    enqueueSnackbar(t('pageAccount.tabGeneral.toast.success.markAccountToDelete'), { variant: 'success' })
    auth.loadUserData()
  }

  return (
    <Box className={classes.root}>
      <Stack direction="column" spacing={2} alignItems="flex-start">
        <Stack direction="row" spacing={2}>
          <Stack direction="row" flex={'1 0 50%'}>
            <Typography>{t('pageAccount.tabGeneral.companyName')}</Typography>
            <Typography>:</Typography>
          </Stack>
          <EasyEdit
            type={Types.TEXT}
            value={auth.getAccountInfo().companyName}
            onSave={(value: any) => handleUpdateCompanyName(value)}
            saveButtonLabel={<Edit fontSize="small" />}
            cancelButtonLabel={<Cancel fontSize="small" />}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <Stack direction="row" flex={'1 0 50%'}>
            <Typography>{t('pageAccount.tabGeneral.image')}</Typography>
            <Typography>:</Typography>
          </Stack>
          <EditableAvatar onCrop={handleUpdateAvatar} preview={auth.getAccountInfo().imagePath} />
        </Stack>

        <Stack direction="row" spacing={2}>
          <Stack direction="row" flex={'1 0 50%'}>
            <Typography>{t('pageAccount.tabGeneral.label.timezone')}</Typography>
            <Typography>:</Typography>
          </Stack>
          <Autocomplete
            defaultValue={UTCS.find((x) => x.value === 0)}
            disableClearable
            value={selectedUTC}
            onChange={(e, value) => handleUpdateUtc(value)}
            options={UTCS}
            getOptionLabel={(opt) => opt.label}
            renderInput={(props) => <TextField {...props} sx={{ minWidth: 120 }} />}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

export default General
