import i18n from '../../../locales/i18next'
import { Keyword, KeywordGroup, KeywordType } from '../types'

const FUNCTION_TYPE = KeywordType.Function

const functions: Keyword[] = [
  {
    type: FUNCTION_TYPE,
    name: i18n.t('expressionBuilder.functions.sum.name'),
    description: i18n.t('expressionBuilder.functions.sum.description'),
    group: KeywordGroup.Aggregate
  },
  {
    type: FUNCTION_TYPE,
    name: i18n.t('expressionBuilder.functions.count.name'),
    description: i18n.t('expressionBuilder.functions.count.description'),
    group: KeywordGroup.Aggregate
  },
  {
    type: FUNCTION_TYPE,
    name: i18n.t('expressionBuilder.functions.min.name'),
    description: i18n.t('expressionBuilder.functions.min.description'),
    group: KeywordGroup.Aggregate
  },
  {
    type: FUNCTION_TYPE,
    name: i18n.t('expressionBuilder.functions.max.name'),
    description: i18n.t('expressionBuilder.functions.max.description'),
    group: KeywordGroup.Aggregate
  },
  {
    type: FUNCTION_TYPE,
    name: i18n.t('expressionBuilder.functions.avg.name'),
    description: i18n.t('expressionBuilder.functions.avg.description'),
    group: KeywordGroup.Aggregate
  }
]

export default functions
