import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent, DialogPropsWithTitle } from '../../components/Dialog'
import TeamForm from '../../components/forms/TeamForm'
import { CreateTeamSchema, TeamSchema } from '../../schemas/TeamSchema'
import { teamService } from '../../services'
import { PartialBy } from '../../utils/typeUtils'

interface TeamDialogProps extends Omit<PartialBy<DialogPropsWithTitle, 'title' | 'description'>, 'onClose'> {
  team?: TeamSchema | null
  onClose(refresh?: boolean): void
}

const TeamDialog: React.FC<PropsWithChildren<TeamDialogProps>> = ({ team, onClose, ...rest }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [deleteConfirmed, setDeleteConfimed] = useState(false)
  const [editTeam, setEditTeam] = useState(team)
  const [selectedUserIds, setSelectedUserIds] = useState<React.ReactText[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiDelete, setLoadingApiDelete] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setEditTeam(team)
    setSelectedUserIds(team?.users.map((a) => a.id) || [])
  }, [team])

  const handleCreate = async (createdTeam: CreateTeamSchema) => {
    try {
      setLoadingApi(true)
      createdTeam.users = createdTeam.users.filter((e) => selectedUserIds.includes(e.id))
      await teamService.create(createdTeam)
      handleClose(true)
      enqueueSnackbar(t('pageTeams.modalEdit.toast.success.create', { name: createdTeam.name }), { variant: 'success' })
    } catch (err: any) {
      console.error('error when try persist created team', err)
      enqueueSnackbar(t('pageTeams.modalEdit.toast.error.create'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const handleUpdate = async (updatedTeam: TeamSchema) => {
    try {
      setLoadingApi(true)
      updatedTeam.users = updatedTeam.users.filter((e) => selectedUserIds.includes(e.id))
      await teamService.update(updatedTeam)
      handleClose(true)
      enqueueSnackbar(t('pageTeams.modalEdit.toast.success.update', { name: updatedTeam.name }), { variant: 'success' })
    } catch (err: any) {
      console.error('error when try persist updated team', err)
      enqueueSnackbar(t('pageTeams.modalEdit.toast.error.update'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const handleDelete = async () => {
    try {
      if (!deleteConfirmed) {
        setDeleteConfimed(true)
        return
      }
      setLoadingApiDelete(true)
      await teamService._delete(editTeam!.id)
      handleClose(true)
      enqueueSnackbar(t('pageTeams.modalEdit.toast.success.delete', { name: editTeam?.name }), { variant: 'success' })
    } catch (err: any) {
      console.error('error when try delete team', err)
      enqueueSnackbar(t('pageTeams.modalEdit.toast.error.delete'), { variant: 'error' })
    }
    setLoadingApiDelete(false)
    setDeleteConfimed(false)
  }

  const handleDeferSubmitAction = (value: TeamSchema) => {
    if (!editTeam?.id) {
      handleCreate(value)
      return
    }
    handleUpdate(value)
    setTimeout(() => setSubmitting(false))
  }

  const handleClose = (refresh?: boolean) => {
    onClose(refresh)
    setEditTeam(null)
    setSubmitting(false)
    setDeleteConfimed(false)
  }

  return (
    <Dialog
      title={editTeam ? t('pageTeams.modalEdit.Title') : t('pageTeams.modalCreate.title')}
      description={editTeam ? t('pageTeams.modalEdit.Subtitle') : t('pageTeams.modalCreate.subTitle')}
      onClose={() => handleClose()}
      {...rest}
    >
      <DialogContent dividers>
        <TeamForm
          initialValues={editTeam || undefined}
          isSubmitting={isSubmitting}
          onSubmit={handleDeferSubmitAction}
          selectedUserIds={selectedUserIds}
          onSelectionChange={setSelectedUserIds}
        />
      </DialogContent>
      {!editTeam?.id ? (
        <DialogActions>
          <Button
            label={t('pageTeams.modalCreate.buttonCreate')}
            loading={loadingApi}
            onClick={() => setSubmitting(true)}
          />
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            isDelete
            label={
              !deleteConfirmed ? t('pageTeams.modalEdit.buttonDelete') : t('pageTeams.modalEdit.buttonDelete.confirm')
            }
            onClick={handleDelete}
            loading={loadingApiDelete}
          />
          <Button
            label={t('pageTeams.modalEdit.buttonUpdate')}
            loading={loadingApi}
            onClick={() => setSubmitting(true)}
          />
        </DialogActions>
      )}
    </Dialog>
  )
}

export default TeamDialog
