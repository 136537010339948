import { Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import FilterBuilder from '../../../../components/FilterBuilder'
import { PlugFieldSchema } from '../../../../schemas/PlugSchema'
import NavigationHeader, { NavigationHeaderProps } from './NavigationHeader'

type FilterViewProps = {
  fields: PlugFieldSchema[]
  value: any
  onChange: (value: any) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const FilterView: React.FC<PropsWithChildren<FilterViewProps>> = ({ fields, navigationProps, onChange, value }) => {
  const { t } = useTranslation()

  return (
    <>
      <NavigationHeader onClose={console.log} {...navigationProps} />
      <Typography variant="h2" align="center" component="div" color="inherit">
        {t('pagePlugs.modalCreate.filtersTitle')}
      </Typography>
      <Typography variant="subtitle1" align="center" component="p" color="inherit">
        {t('pagePlugs.modalCreate.filtersSubtitle')}
      </Typography>
      <FilterBuilder disableSearch width={800} height={530} fields={fields} value={value} onValueChanged={onChange} />
    </>
  )
}
export default FilterView
