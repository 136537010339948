import { SvgIconComponent } from '@mui/icons-material'
import AnalyseIcon from '@mui/icons-material/Assessment'
import PlugMaterialIcon from '@mui/icons-material/Power'
import {
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren, useState } from 'react'
import AnalyzeIcon, { AnalyzeIconTypes } from '../../components/AnalyzeIcon'
import FilterRemoveIcon from '../../components/FilterRemoveIcon'
import PlugIcon from '../../components/PlugIcon'
import SearchBox from '../../components/SearchBox'
import { AVAILABLE_ANALYSIS_TYPES } from '../../constants/analyzeTypesIcon'
import { isMobile } from '../../utils/mediaQueryUtils'

interface HeaderProps {
  icon: SvgIconComponent
  title: string
  subtitle: string
  classes?: {
    box?: string
    paper?: string
  }
  onChangeSearchValue?(searchValue: string): void
  onChangeConnSelection?(connectionSelected: any): void
  onChangeAnalyzeSelection?(analyzeSelected: any): void
  availableConnections?: any[]
  showFilterActions?: boolean
  hideSearchBar?: boolean
}

const useStyles = makeStyles((theme) => ({
  box: {
    flexGrow: 0,
    padding: theme.spacing(2, 2, 1),
    display: 'flex',
    backgroundColor: theme.palette.background.light.default,
    flexBasis: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(750)]: {
      flexWrap: 'wrap'
    }
  },
  captions: {
    flexGrow: 3,
    flexShrink: 0,
    flexBasis: '0',
    minWidth: '55%',
    [theme.breakpoints.down('xl')]: {
      '& h1': {
        fontSize: theme.typography.pxToRem(32)
      },
      '& h6': {
        fontSize: theme.typography.pxToRem(16)
      }
    }
  },
  paper: {
    width: '88px',
    height: '88px',
    padding: theme.spacing(2),
    flexGrow: 0,
    [theme.breakpoints.down('xl')]: {
      width: '68px',
      height: '68px'
    }
  },
  icon: {
    width: '100%',
    height: '100%'
  },
  paperContainer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    marginRight: '1rem',
    marginBottom: theme.spacing(4)
  },
  searchContainer: {
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1
    },
    flexGrow: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  filterIconsContainer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto'
  },
  menuItem: {
    width: '100%'
  }
}))

const Header: React.FC<PropsWithChildren<HeaderProps>> = ({
  icon: Icon,
  title,
  subtitle,
  onChangeSearchValue,
  onChangeConnSelection,
  onChangeAnalyzeSelection,
  availableConnections,
  classes,
  hideSearchBar,
  showFilterActions = false
}) => {
  const defaultClasses = useStyles()
  const [connFilterAnchorEl, setConnFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [analyzeFilterAnchorEl, setAnalyzeFilterAnchorEl] = useState<null | HTMLElement>(null)

  const [connSelected, setConnSelected] = useState<null | any>(null)
  const [analyzeSelected, setAnalyzeSelected] = useState<null | string>(null)

  const connMenuOpen = Boolean(connFilterAnchorEl)
  const analyzeMenuOpen = Boolean(analyzeFilterAnchorEl)

  const handleOpenConnMenu = (e: React.MouseEvent<HTMLElement>) => setConnFilterAnchorEl(e.currentTarget)
  const handleOpenAnalyzeMenu = (e: React.MouseEvent<HTMLElement>) => setAnalyzeFilterAnchorEl(e.currentTarget)

  const handleCloseConnMenu = () => setConnFilterAnchorEl(null)
  const handleCloseAnalyzeMenu = () => setAnalyzeFilterAnchorEl(null)

  const handleChangeConnSelection = (e: React.MouseEvent<HTMLElement>, index: number) => {
    if (availableConnections?.[index] === connSelected) {
      setConnSelected(null)
      onChangeConnSelection && onChangeConnSelection(null)
      handleCloseConnMenu()
      return
    }
    setConnSelected(availableConnections?.[index])
    onChangeConnSelection && onChangeConnSelection(availableConnections?.[index])
    handleCloseConnMenu()
  }
  const handleChangeAnalyzeSelection = (e: React.MouseEvent<HTMLElement>, analyzeType: string) => {
    setAnalyzeSelected(analyzeType)
    onChangeAnalyzeSelection && onChangeAnalyzeSelection(analyzeType)
    handleCloseAnalyzeMenu()
  }

  const handleSearchChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearchValue && onChangeSearchValue(e.target.value)
  }

  const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  return (
    <Box className={clsx(classes?.box, defaultClasses.box)}>
      <Box className={defaultClasses.paperContainer}>
        <Paper className={(classes?.paper, defaultClasses.paper)} elevation={3}>
          <Icon color="primary" className={defaultClasses.icon} />
        </Paper>
      </Box>
      <Box className={defaultClasses.captions}>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
      <Box className={defaultClasses.searchContainer}>
        {!hideSearchBar && <SearchBox autoFocus={false} autoComplete="none" onChange={handleSearchChangeValue} />}
        {showFilterActions && (
          <Box hidden={!isMobile() ? isMdUp : false}>
            <Box className={defaultClasses.filterIconsContainer}>
              <IconButton onClick={handleOpenConnMenu} size="large">
                <PlugMaterialIcon color={connSelected ? 'primary' : 'inherit'} />
              </IconButton>
              <IconButton onClick={handleOpenAnalyzeMenu} size="large">
                <AnalyseIcon color={analyzeSelected !== null && analyzeSelected !== 'ALL' ? 'primary' : 'inherit'} />
              </IconButton>
            </Box>
            <Menu anchorEl={connFilterAnchorEl} keepMounted open={connMenuOpen} onClose={handleCloseConnMenu}>
              {availableConnections?.map((conn, index) => (
                <MenuItem
                  onClick={(event) => handleChangeConnSelection(event, index)}
                  selected={conn.id === connSelected?.id}
                >
                  <ListItemIcon>
                    <PlugIcon src={conn.connection?.image} type={conn.type} />
                  </ListItemIcon>
                  <ListItemText primary={conn.name} />
                  <ListItemSecondaryAction hidden={!(conn.id === connSelected?.id)}>
                    <FilterRemoveIcon color="primary" />
                  </ListItemSecondaryAction>
                </MenuItem>
              ))}
            </Menu>
            <Menu anchorEl={analyzeFilterAnchorEl} keepMounted open={analyzeMenuOpen} onClose={handleCloseAnalyzeMenu}>
              {Object.keys(AVAILABLE_ANALYSIS_TYPES).map((analyzeType) => (
                <MenuItem
                  key={analyzeType}
                  onClick={(event) => handleChangeAnalyzeSelection(event, analyzeType)}
                  selected={analyzeType === analyzeSelected}
                >
                  <ListItemIcon>
                    <AnalyzeIcon type={analyzeType as AnalyzeIconTypes} color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={AVAILABLE_ANALYSIS_TYPES[analyzeType]} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Header
