import { Checkbox, CircularProgress, createFilterOptions, FormControlLabel } from '@mui/material'
import { styled } from '@mui/styles'
import axios from 'axios'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import TextField from '../../components/TextField'
import { SendingRecipient } from '../../schemas/SendingSchema'
import { userService } from '../../services'
import sendingService from '../../services/sendingService'
import { AutocompleteRecipients } from './sending-dialog/GeneralTab'

type SendingSendDialogProps = {
  onClose: () => void
  onClosed: () => void
  open: boolean
  sendingId: string
  onSubmitSuccess: () => void
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2, 0)
}))

const filter = createFilterOptions<SendingRecipient>()

const Send_Block_Delay_Duration_In_Seconds = 60

const SendingSendDialog: React.FC<PropsWithChildren<SendingSendDialogProps>> = ({
  onClose,
  onClosed,
  open,
  sendingId,
  onSubmitSuccess
}) => {
  const [loadingSending, setLoadingSending] = useState(false)
  const [selectedRecipients, setSelectedRecipients] = useState<SendingRecipient[]>([])
  const [error, setError] = useState<string>()

  const [sendToAllRecipients, setSendToAllRecipients] = useState(false)
  const [lastSendingDateTime, setLastSendingDateTime] = useState<moment.Moment | null>(null)
  const [timer, setTimer] = useState<number | null>(null)

  const { enqueueSnackbar } = useSnackbar()

  const [userRecipients, setUserRecipients] = useState<SendingRecipient[]>([])
  const [usersLoading, setUsersLoading] = useState(false)
  const { t } = useTranslation()

  const handleOpenRecipients = async () => {
    setUsersLoading(true)
    try {
      const { data } = await userService.getAll()
      setUserRecipients(
        data.map((x) => ({
          emailAddress: x.email,
          name: x.firstName + ' ' + x.lastName,
          userId: x.id
        }))
      )
    } catch (err) {
      alert(err)
    }
    setUsersLoading(false)
  }

  const handleSend = async () => {
    try {
      if (!sendToAllRecipients && selectedRecipients.length === 0) {
        setError(t('pageSending.sendDialog.error.noRecipients')!)
        return
      }
      setLoadingSending(true)
      await sendingService.send({
        sendingId: sendingId,
        overriddenRecipients: sendToAllRecipients ? undefined : selectedRecipients
      })
      enqueueSnackbar(t('pageSending.toast.sent'), { variant: 'success' })
      onSubmitSuccess()
    } catch (err: any) {
      let errMessage = err.message
      if (axios.isAxiosError(err)) {
        errMessage = err.response?.data?.[0].description ?? err.message
      }
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: errMessage }), { variant: 'error' })
    }
    setLastSend(moment(moment.now()))
    setLoadingSending(false)
  }

  const handleClearStateOnClosed = () => {
    setError('')
    setLoadingSending(false)
    setSelectedRecipients([])
    onClosed()
  }

  const setLastSend = (value: moment.Moment | null) => {
    setLastSendingDateTime(value)
    if (!value) {
      localStorage.removeItem('lastSendingDateTime')
      return
    }

    localStorage.setItem('lastSendingDateTime', value.toString())
  }

  useEffect(() => {
    const lastSendingDateTimeString = localStorage.getItem('lastSendingDateTime')
    if (!!lastSendingDateTimeString) {
      if (moment(lastSendingDateTimeString).isBefore(moment.now())) {
        setLastSend(null)
        return
      }
      setLastSendingDateTime(moment(lastSendingDateTimeString))
    }
  }, [])

  useEffect(() => {
    if (!!lastSendingDateTime) {
      const timer = setInterval(() => {
        const blockTime = moment(lastSendingDateTime)
          .add(Send_Block_Delay_Duration_In_Seconds, 'seconds')
          .diff(moment(moment.now()), 'seconds')

        setTimer(blockTime)
        if (blockTime === 0) {
          clearInterval(timer)
          setLastSend(null)
        }
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [lastSendingDateTime])

  return (
    <Dialog
      removeTitle={false}
      open={open}
      onClose={onClose}
      TransitionProps={{
        onExited: handleClearStateOnClosed
      }}
      title={t('pageSending.modalSend.title')}
      description={t('pageSending.modalSend.subTitle')}
    >
      <DialogContent>
        <AutocompleteRecipients
          sx={{ py: 2 }}
          disabled={sendToAllRecipients}
          value={selectedRecipients}
          onChange={(newValue) => setSelectedRecipients(newValue)}
          multiple
          limitTags={6}
          disableClearable
          onOpen={handleOpenRecipients}
          options={userRecipients}
          freeSolo
          isOptionEqualToValue={(option, value) => option.emailAddress === value.emailAddress}
          loading={usersLoading}
          renderInput={(params) => (
            <TextField
              name="recipients"
              {...params}
              required
              label={t('pageSending.modalCreate.recipients.title')}
              error={!!error}
              helperText={error}
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={sendToAllRecipients}
              onChange={(ev) => setSendToAllRecipients(ev.target.checked)}
              name="check-send-to-all-recipients"
              color="primary"
            />
          }
          label={t('pageSending.modalSend.checkAll')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loadingSending || !!timer}
          endIcon={timer ? <span>{timer}s</span> : loadingSending ? <CircularProgress size={20} /> : undefined}
          label={t('pageSending.modalSend.buttonSend')}
          loading={loadingSending}
          onClick={handleSend}
        />
      </DialogActions>
    </Dialog>
  )
}

export default SendingSendDialog
