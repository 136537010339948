import { PartialBy } from '../utils/typeUtils'

export interface UserProfileSchema {
  id: number
  name: string
  managingAnalyseAndCollection: boolean
  managingPlug: boolean
  managingAccess: boolean
  managingAccount: boolean
  isSystemDefault: boolean
  canExportData: boolean
}

export interface UserTeamSchema {
  id: string
  name: string
}

export type UserAccountStatus = 'INACTIVE' | 'ACTIVE' | 'PENDING_ACCEPT_INVITE' | 'NOT_PAID'

export interface UserSchema {
  id: string
  thumbImagePath?: string
  firstName: string
  lastName: string
  email: string
  profile: UserProfileSchema
  team?: UserTeamSchema
  statusInAccount: UserAccountStatus
  locale?: string
}

export interface InviteUser {
  email: string
  profileId: number
  teamId?: string
  culture: string
}

export interface ConfirmNewInviteUser {
  thumbImagePath?: string
  fullName: string
  password: string
}
export type UserEditableSchema = PartialBy<
  Omit<UserSchema, 'emailConfirmed'>,
  'firstName' | 'lastName' | 'email' | 'thumbImagePath' | 'profile' | 'team' | 'statusInAccount' | 'locale'
> & {
  removeAvatar?: boolean
  password?: string
}

export enum PlugClaimType {
  Analysis_RO = 'http://schemas.PluggerBI.com/2021/03/analyzesid:RO',
  Analysis_RW = 'http://schemas.PluggerBI.com/2021/03/analyzesid:RW',
  Collection_RO = 'http://schemas.PluggerBI.com/2021/03/collectionsid:RO',
  Collection_RW = 'http://schemas.PluggerBI.com/2021/03/collectionsid:RW'
}

export type UserClaim = {
  userId: string
  claimType: PlugClaimType
}
