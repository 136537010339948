import { AxiosResponse } from 'axios'

import api from '../api'

import { ENDPOINTS } from '../../constants/apiEndpoints'
import { ParameterSchema } from '../../schemas/ParameterSchema'
import { format } from '../../utils/stringUtils'

const getParameters = (includeUserValues?: boolean): Promise<AxiosResponse<ParameterSchema[]>> => {
  return api.get(
    includeUserValues
      ? ENDPOINTS.PARAMETERS_GET_ALL + `?includeUserValues=${includeUserValues}`
      : ENDPOINTS.PARAMETERS_GET_ALL
  )
}

const get = (id: string): Promise<AxiosResponse<ParameterSchema>> => {
  return api.get(format(ENDPOINTS.PARAMETERS_GET, id))
}

const checkParameterNameAvailable = (parameterName: string): Promise<AxiosResponse<boolean>> => {
  return api.get(format(ENDPOINTS.PARAMETERS_CHECK_NAME_AVAILABLE, parameterName))
}

const create = (parameter: Omit<ParameterSchema, 'id'>): Promise<AxiosResponse<ParameterSchema>> => {
  return api.post(ENDPOINTS.PARAMETERS_CREATE, parameter)
}

const update = (parameterId: string, parameter: ParameterSchema): Promise<AxiosResponse<ParameterSchema>> => {
  return api.put(format(ENDPOINTS.PARAMETERS_UPDATE, parameterId), parameter)
}

const _delete = (parameterId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.PARAMETERS_DELETE, parameterId))
}

export default { getParameters, create, _delete, get, update, checkParameterNameAvailable }
