import { PlugFieldSchema } from '../../../../../../schemas/PlugSchema'

export const movideskFields: Omit<PlugFieldSchema, 'label'>[] = [
  {
    name: 'action_count',
    type: 'NUMERIC'
  },
  {
    name: 'action_created_by',
    type: 'STRING'
  },
  {
    name: 'action_created_date',
    type: 'DATETIME'
  },
  {
    name: 'action_id',
    type: 'STRING'
  },
  {
    name: 'action_status',
    type: 'STRING'
  },
  {
    name: 'action_type',
    type: 'STRING'
  },
  {
    name: 'address',
    type: 'STRING'
  },
  {
    name: 'base_status',
    type: 'STRING'
  },
  {
    name: 'business_name',
    type: 'STRING'
  },
  {
    name: 'category',
    type: 'STRING'
  },
  {
    name: 'changed_date',
    type: 'DATETIME'
  },
  {
    name: 'chat_talk_time',
    type: 'NUMERIC'
  },
  {
    name: 'chat_waiting_time',
    type: 'NUMERIC'
  },
  {
    name: 'client_id',
    type: 'STRING'
  },
  {
    name: 'closed_in',
    type: 'DATETIME'
  },
  {
    name: 'contact_form',
    type: 'STRING'
  },
  {
    name: 'corporate_name',
    type: 'STRING'
  },
  {
    name: 'email',
    type: 'STRING'
  },
  {
    name: 'is_active',
    type: 'NUMERIC'
  },
  {
    name: 'justification',
    type: 'STRING'
  },
  {
    name: 'last_action_date',
    type: 'DATETIME'
  },
  {
    name: 'last_update',
    type: 'DATETIME'
  },
  {
    name: 'life_time_working_time',
    type: 'NUMERIC'
  },
  {
    name: 'origin',
    type: 'STRING'
  },
  {
    name: 'origin_email_account',
    type: 'STRING'
  },
  {
    name: 'owner_email',
    type: 'STRING'
  },
  {
    name: 'owner_name',
    type: 'STRING'
  },
  {
    name: 'owner_team',
    type: 'STRING'
  },
  {
    name: 'person_created_date',
    type: 'DATETIME'
  },
  {
    name: 'person_type',
    type: 'STRING'
  },
  {
    name: 'profile_type',
    type: 'STRING'
  },
  {
    name: 'reopened_in',
    type: 'DATE'
  },
  {
    name: 'resolved_in',
    type: 'DATE'
  },
  {
    name: 'resolved_in_first_call',
    type: 'NUMERIC'
  },
  {
    name: 'service_first_level',
    type: 'STRING'
  },
  {
    name: 'service_full',
    type: 'STRING'
  },
  {
    name: 'service_second_level',
    type: 'STRING'
  },
  {
    name: 'service_third_level',
    type: 'STRING'
  },
  {
    name: 'sla_agreement',
    type: 'STRING'
  },
  {
    name: 'sla_agreement_rule',
    type: 'STRING'
  },
  {
    name: 'sla_real_response_date',
    type: 'DATETIME'
  },
  {
    name: 'sla_response_date',
    type: 'DATETIME'
  },
  {
    name: 'sla_response_time',
    type: 'NUMERIC'
  },
  {
    name: 'sla_solution_changed_by_user',
    type: 'STRING'
  },
  {
    name: 'sla_solution_date',
    type: 'DATETIME'
  },
  {
    name: 'sla_solution_time',
    type: 'NUMERIC'
  },
  {
    name: 'status',
    type: 'STRING'
  },
  {
    name: 'stopped_time',
    type: 'NUMERIC'
  },
  {
    name: 'stopped_time_working_time',
    type: 'NUMERIC'
  },
  {
    name: 'subject',
    type: 'STRING'
  },
  {
    name: 'tags',
    type: 'STRING'
  },
  {
    name: 'ticket_created_date',
    type: 'DATETIME'
  },
  {
    name: 'ticket_id',
    type: 'STRING'
  },
  {
    name: 'time_accounted_time',
    type: 'NUMERIC'
  },
  {
    name: 'time_activity',
    type: 'STRING'
  },
  {
    name: 'time_date',
    type: 'DATETIME'
  },
  {
    name: 'time_id',
    type: 'STRING'
  },
  {
    name: 'time_period_end',
    type: 'DATETIME'
  },
  {
    name: 'time_period_start',
    type: 'DATETIME'
  },
  {
    name: 'time_work_type_name',
    type: 'STRING'
  },
  {
    name: 'type',
    type: 'STRING'
  },
  {
    name: 'urgency',
    type: 'STRING'
  },
  {
    name: 'user_name',
    type: 'STRING'
  }
]
