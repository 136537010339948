import { Button, Stack, Typography } from '@mui/material'
import { usePayment } from '../usePayment'
import { useTranslation } from 'react-i18next'

export const TrialExtended: React.FC = () => {
  const payment = usePayment()!
  const { t } = useTranslation()

  const handleContinue = () => {
    payment.closePresenter()
    payment.reloadInfos()
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h2" textAlign="center">
        {t('trialExpiredDialog.extendTrialSuccess')}
      </Typography>
      <Button
        onClick={handleContinue}
        sx={{ width: 320, marginX: 'auto!important' }}
        fullWidth={false}
        variant="contained"
      >
        {t('trialExpiredDialog.continueWork')}
      </Button>
    </Stack>
  )
}
