import { Close } from '@mui/icons-material'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren, useState } from 'react'
import { Motion, spring } from 'react-motion'
import { ABC_CURVE_POSITION } from '.'
import Table from '../../components/Table'
import NumberFormatTypeProvider from '../../components/Table/NumberFormatTypeProvider'
import useAuth from '../../hooks/useAuth'
import { PlugFieldSchema } from '../../schemas/PlugSchema'

type ClusteredMarkerValues = {
  [key: string]: string | number
}

type ClusteredMarkerProps = {
  lat: number
  lng: number
  values: Array<ClusteredMarkerValues>
  dimensionColumns: PlugFieldSchema[]
  valueColumns: PlugFieldSchema[]
  insideValue?: number | string
  curvePosition: ABC_CURVE_POSITION
  aMarkerColor: string
  bMarkerColor: string
  cMarkerColor: string
  simpleMarkerColor: string
}

type StyleProps = {
  tableWidth: number
  aMarkerColor: string
  bMarkerColor: string
  cMarkerColor: string
  simpleMarkerColor: string
}

const useStyles = makeStyles((theme) => ({
  infoWindow: {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: (props: StyleProps) => `${props.tableWidth}px`,
    zIndex: 100,
    height: '320px',
    display: 'flex',
    flexDirection: 'column'
  },
  table: {
    width: (props: StyleProps) => `${props.tableWidth}px`
  },
  closeBtn: {
    alignSelf: 'flex-end'
  },
  markerBox: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: '56px',
    height: '56px',
    boxShadow: theme.shadows[5],
    border: '2px solid #fff'
  },
  markerBoxA: {
    backgroundColor: (props: StyleProps) => props.aMarkerColor
  },
  markerBoxB: {
    backgroundColor: (props: StyleProps) => props.bMarkerColor
  },
  markerBoxC: {
    backgroundColor: (props: StyleProps) => props.cMarkerColor
  },
  markerInsideValue: {
    color: '#000',
    fontSize: '12px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1)
  }
}))

const ClusteredMarker: React.FC<PropsWithChildren<ClusteredMarkerProps>> = ({
  values,
  dimensionColumns,
  valueColumns,
  insideValue,
  curvePosition,
  aMarkerColor,
  bMarkerColor,
  cMarkerColor,
  simpleMarkerColor
}) => {
  const joinedColumns = [...dimensionColumns, ...valueColumns]
  const classes = useStyles({
    tableWidth: joinedColumns.length * 210,
    aMarkerColor,
    bMarkerColor,
    cMarkerColor,
    simpleMarkerColor
  })
  const [isHover, setIsHover] = useState(false)
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)
  const auth = useAuth()
  const userLocale = auth.getUserInfo()?.locale

  return (
    <>
      <Motion
        defaultStyle={{
          scale: 1
        }}
        style={{ scale: spring(isHover ? 1.2 : 1) }}
      >
        {({ scale }) => (
          <Box
            style={{
              transform: 'scale(' + scale + ')'
            }}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => setShowInfoTooltip(true)}
            className={clsx(classes.markerBox, {
              [classes.markerBoxA]: curvePosition === ABC_CURVE_POSITION.A,
              [classes.markerBoxB]: curvePosition === ABC_CURVE_POSITION.B,
              [classes.markerBoxC]: curvePosition === ABC_CURVE_POSITION.C
            })}
          >
            <Typography className={classes.markerInsideValue}>{insideValue}</Typography>
          </Box>
        )}
      </Motion>
      {showInfoTooltip && (
        <Paper className={classes.infoWindow}>
          <IconButton className={classes.closeBtn} onClick={() => setShowInfoTooltip(false)} size="large">
            <Close />
          </IconButton>
          <Table
            className={classes.table}
            height="320px"
            selectionDisabled
            editingProps={{ columnEditingEnabled: false, onCommitChanges: () => false }}
            rows={values}
            columns={joinedColumns.map((x) => ({
              name: x.name,
              title: x.label
            }))}
          >
            <NumberFormatTypeProvider for={valueColumns.map((x) => x.name)} locales={userLocale} options={{}} />
          </Table>
        </Paper>
      )}
    </>
  )
}

export default ClusteredMarker
