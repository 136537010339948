import { DashboardControl, ItemWidgetOptionEventArgs, ViewerApiExtension } from 'devexpress-dashboard'
import * as Model from 'devexpress-dashboard/model'
import { ISelectBoxOptions } from 'devextreme-react/select-box'

const comboBoxAutoFitWidthExtension: Model.CustomPropertyMetadata = {
  ownerType: Model.ComboBoxItem,
  propertyName: 'ComboBoxAutoFitWidthExtension',
  defaultValue: '',
  valueType: 'string'
}

Model.registerCustomProperty(comboBoxAutoFitWidthExtension)

function onItemWidgetOptionsPrepared(args: ItemWidgetOptionEventArgs) {
  if (args.dashboardItem instanceof Model.ComboBoxItem) {
    const comboBoxProps = args.options as ISelectBoxOptions
    comboBoxProps.onOpened = (e) => {
      const items = e.component.getDataSource().items()
      if (items.length === 0) return

      let padding = 5
      if (comboBoxProps.showSelectionControls) {
        padding = 10
      }
      const largestItem = items.reduce((a, b) => (a.text.length > b.text.length ? a : b))
      e.component.option('dropDownOptions.minWidth', `${largestItem.text.length + padding}ch`)
    }
  }
}

export class ComboBoxAutoFitWidthExtension {
  name = 'ComboBoxAutoFitWidthExtension'
  constructor(private dashboardControl: DashboardControl) {}

  start(): void {
    let viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as ViewerApiExtension
    if (viewerApiExtension) {
      viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
    }
  }

  stop(): void {
    let viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as ViewerApiExtension
    if (viewerApiExtension) {
      viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
    }
  }
}
