import { ChangeSet, EditingState } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableInlineCellEditing,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui'
import { Clear } from '@mui/icons-material'
import { IconButton, Paper, Tab, Tabs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import * as yup from 'yup'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent, DialogPropsWithTitle } from '../../components/Dialog'
import Form from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import SelectField from '../../components/Form/SelectField'
import WarningContainer from '../../components/WarningContainer'
import UserForm, { UserParameters, filterParametrizedUsers } from '../../components/forms/UserForm'
import useAuth from '../../hooks/useAuth'
import useErrorState from '../../hooks/useErrorState'
import { ProfileSchema } from '../../schemas/ProfileSchema'
import { TeamSchema } from '../../schemas/TeamSchema'
import { UserEditableSchema, UserProfileSchema, UserSchema, UserTeamSchema } from '../../schemas/UserSchema'
import { instanceOfAxiosError } from '../../services/api'
import { parameterService, profileService, teamService, userService } from '../../services/index'
import { PartialBy } from '../../utils/typeUtils'
import AutocompleteTypeProvider from '../AutocompleteTypeProvider/AutocompleteTypeProvider'

interface UserDialogProps extends Omit<DialogPropsWithTitle, 'onClose'> {
  user?: UserSchema
  onClose(refresh?: boolean): void
  inactiveUsers: UserSchema[]
}

const inviteValidationSchema = yup.object({
  email: yup.string().required().email(),
  profile: yup.object().nullable().required(),
  team: yup.object().optional().nullable()
})

const FocusableCell = ({
  onClick,
  ...restProps
}: Table.DataCellProps & {
  [x: string]: any
  className?: string | undefined
  style?: React.CSSProperties | undefined
}) => {
  if (restProps.column.name === 'name') {
    return <VirtualTable.Cell {...restProps} tabIndex={0} />
  }
  return <VirtualTable.Cell {...restProps} tabIndex={0} onClick={onClick} />
}

const useStyles = makeStyles((theme) => ({
  successBtn: {
    backgroundColor: theme.palette.success.main
  }
}))

const Root = (props: any) => {
  return <Grid.Root {...props} style={{ height: '100%' }} />
}

const UserDialog: React.FC<PropsWithChildren<UserDialogProps>> = ({ user, onClose, inactiveUsers, ...rest }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const auth = useAuth()
  const [profiles, setProfiles] = useState<ProfileSchema[]>([])
  const [teams, setTeams] = useState<TeamSchema[]>([])
  const [errorState, setErrorState] = useErrorState()
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiDelete, setLoadingApiDelete] = useState(false)
  const [loadingApiReactivate, setLoadingApiReactivate] = useState(false)
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)
  const [isReactivating, setIsReactivating] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [userParameters, setUserParameters] = useState<UserParameters[]>([])
  const parametersDirty = useRef(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const isSelf = auth.getUserInfo()?.userId === user?.id
  const allowEditPermissions = auth.getUserInfo()?.profile.managingAccess

  const handleClose = (refresh?: boolean) => {
    setIsReactivating(false)
    setSubmitting(false)
    onClose(refresh)
    setErrorState(null)
    setDeleteConfirmed(false)
    setUserParameters([])
    parametersDirty.current = false
    setActiveTab(0)
  }

  const handleInvite = async (invitedUser: { email: string; profile?: UserProfileSchema; team?: UserTeamSchema }) => {
    if (inactiveUsers.some((x) => x.email === invitedUser.email)) {
      if (!errorState?.error) {
        setErrorState({
          error: true,
          description: t('pageUsers.modalEdit.warningContainer.reactivateUser')!
        })
        setIsReactivating(true)
        return
      }
      handleReactivate(inactiveUsers.find((x) => x.email === invitedUser.email)!)
      return
    }
    try {
      setLoadingApi(true)
      const { data } = await userService.invite({
        email: invitedUser.email,
        profileId: invitedUser.profile!.id,
        teamId: invitedUser.team?.id,
        culture: auth.getUserInfo()?.locale || window.navigator.language
      })
      if (parametersDirty.current) {
        await userService.updateParameters(data.id, filterParametrizedUsers(userParameters))
      }
      handleClose(true)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.success.inviteUser', { email: invitedUser.email }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error('error when try invite to a new user', err)
      if (instanceOfAxiosError(err)) {
        setErrorState({
          error: true,
          description: err.response?.data?.[0].errorMessage
        })
      } else enqueueSnackbar(t('pageUsers.modalEdit.toast.error.inviteUser'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const handleDelete = async () => {
    if (!deleteConfirmed) {
      setDeleteConfirmed(true)
      return
    }
    try {
      setLoadingApiDelete(true)
      await userService._delete(user!.id)
      handleClose(true)
      enqueueSnackbar(
        user?.statusInAccount === 'ACTIVE'
          ? t('pageUsers.modalEdit.toast.success.inactivated')
          : t('pageUsers.modalEdit.toast.success.deleted'),
        {
          variant: 'success'
        }
      )
    } catch (err: any) {
      console.error('error when try persist delete user', err)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.error.deleted'), { variant: 'error' })
    }
    setLoadingApiDelete(false)
    setDeleteConfirmed(false)
  }
  const handleUpdate = async (
    updatedUser: PartialBy<Required<UserEditableSchema>, 'thumbImagePath'> & {
      userParameters?: UserParameters[]
      parametersDirty?: boolean
    }
  ) => {
    try {
      if (!updatedUser.thumbImagePath && !!user?.thumbImagePath) {
        updatedUser.removeAvatar = true
      }
      if (!isSelf) {
        updatedUser.removeAvatar = false
        updatedUser.thumbImagePath = undefined
      }
      const updatePromises = []
      updatePromises.push(
        userService.update(updatedUser.id, {
          firstName: updatedUser.firstName,
          lastName: updatedUser.lastName,
          teamId: updatedUser.team?.id || null,
          profileId: updatedUser.profile!.id
        })
      )
      if (updatedUser.parametersDirty) {
        updatePromises.push(userService.updateParameters(updatedUser.id, updatedUser.userParameters))
      }
      setLoadingApi(true)
      await Promise.all(updatePromises)
      handleClose(true)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.success.updated', { name: updatedUser.firstName }), {
        variant: 'success'
      })
      auth.loadUserData()
    } catch (err: any) {
      console.error('error when try persist update user', err)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.error.updated'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const loadParameters = useCallback(async () => {
    try {
      const { data } = await parameterService.getParameters()
      const userParams = data.map((p) => ({
        parameterId: p.id,
        name: p.name,
        value: p.type === 'List' ? [] : '',
        type: p.type
      }))
      setUserParameters(userParams)
    } catch (err: any) {
      enqueueSnackbar(t('pageUsers.modalEdit.toast.error.getUserParameters'), { variant: 'error' })
    }
  }, [enqueueSnackbar, t])

  const loadOptions = useCallback(async () => {
    try {
      const profilesPromise = profileService.get()
      const teamsPromise = teamService.getTeams()

      const [profilesResp, teamsResp] = await Promise.all([profilesPromise, teamsPromise])
      setProfiles(profilesResp.data)
      setTeams(teamsResp.data)
    } catch (err: any) {
      console.error('error while try get profiles and teams', err)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.error.getProfileAndTeams'), { variant: 'error' })
    }
  }, [enqueueSnackbar, t])

  useEffect(() => {
    loadOptions()
  }, [loadOptions, loadParameters])

  useEffect(() => {
    loadParameters()
  }, [loadParameters])

  const handleReturnName = (opt: any) => {
    return opt?.name || ''
  }
  const handleReturnSelected = (opt: any, value: any) => {
    return opt?.id === value?.id
  }

  const handleReactivate = async (inactiveUser: UserSchema) => {
    setLoadingApiReactivate(true)
    try {
      await userService.reactivate(inactiveUser.id)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.success.reactivated', { name: user?.firstName }), {
        variant: 'success'
      })
      handleClose(true)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageUsers.modalEdit.toast.error.reactivated', { msg: err.message }), { variant: 'error' })
    }
    setLoadingApiReactivate(false)
  }
  const clearUserParameterValue = (id: string) => {
    if (!userParameters) return

    userParameters.forEach((e) => {
      if (e.parameterId === id) {
        e.value = ''
      }
    })
    parametersDirty.current = true
    setUserParameters([...userParameters])
  }

  const commitChanges = ({ changed }: ChangeSet) => {
    let changedRows: UserParameters[] = []
    const rows = userParameters
    if (changed) {
      changedRows = rows.map((row) => {
        const changedValue = changed[row.name]
        if (changedValue) {
          if (row.type === 'List') {
            changedValue.value.map((x: any) => String(x).trim())
          } else {
            changedValue.value = String(changedValue.value).trim()
          }
          return { ...row, ...changedValue }
        }
        return row
      })
    }
    parametersDirty.current = true
    setUserParameters(changedRows)
  }

  const ClearParameterCell = ({
    children,
    ...restProps
  }: TableEditColumn.CellProps & {
    [x: string]: any
    className?: string | undefined
    style?: React.CSSProperties | undefined
  }) => {
    return (
      <TableEditColumn.Cell {...restProps}>
        {children}
        {restProps.row.value && (
          <IconButton
            color="primary"
            aria-label="clear parameter value"
            onClick={() => clearUserParameterValue(restProps.row.parameterId)}
            size="large"
          >
            <Clear />
          </IconButton>
        )}
      </TableEditColumn.Cell>
    )
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value)
  }

  const getHeight = () => {
    const increment = 55
    const calculatedHeight = userParameters.length > 0 ? userParameters.length * 55 : 160
    return `${calculatedHeight + increment}px`
  }

  return (
    <Dialog {...rest} fullWidth fullScreen onClose={() => handleClose(false)}>
      <DialogContent dividers>
        <>
          {user ? (
            <UserForm initialValues={{ ...user } as any} isSubmitting={isSubmitting} onSubmit={handleUpdate} />
          ) : (
            <>
              <Tabs centered indicatorColor="primary" value={activeTab} onChange={handleTabChange}>
                <Tab value={0} label={t('pageUsers.modalCreate.tabGeneral.title')} />
                {allowEditPermissions && <Tab value={1} label={t('pageUsers.modalEdit.tabParameters.parameter')} />}
              </Tabs>

              <SwipeableViews index={activeTab} animateHeight>
                <Form
                  validationSchema={inviteValidationSchema}
                  initialValues={{ email: '', profile: undefined }}
                  isSubmitting={isSubmitting}
                  onSubmit={handleInvite}
                >
                  <OutlinedTextField name="email" label={t('pageUsers.modalCreate.tabGeneral.email')} />
                  <SelectField
                    name="profile"
                    label={t('pageUsers.modalCreate.tabGeneral.profile')}
                    required
                    options={profiles}
                    getOptionLabel={handleReturnName}
                    getOptionSelected={handleReturnSelected}
                  />
                  <SelectField
                    name="team"
                    label={t('pageUsers.modalCreate.tabGeneral.team')}
                    options={teams}
                    getOptionLabel={handleReturnName}
                    getOptionSelected={handleReturnSelected}
                  />
                </Form>
                {allowEditPermissions && (
                  <Paper style={{ height: getHeight() }}>
                    <Grid
                      columns={[
                        {
                          name: 'name',
                          title: t('pageUsers.modal.parameterName')!
                        },
                        {
                          name: 'value',
                          title: t('pageParameters.modalCreate.paramValue')!
                        }
                      ]}
                      rows={userParameters}
                      getRowId={(rowData) => rowData.name}
                    >
                      <AutocompleteTypeProvider multiple={(x) => x.type === 'List'} for={['value']} />
                      <EditingState
                        onCommitChanges={commitChanges}
                        columnExtensions={[
                          {
                            columnName: 'name',
                            editingEnabled: false
                          }
                        ]}
                      />
                      <VirtualTable height="auto" />
                      <TableHeaderRow />
                      <TableInlineCellEditing selectTextOnEditStart={false} />
                    </Grid>
                  </Paper>
                )}
              </SwipeableViews>
            </>
          )}
          {errorState?.error && <WarningContainer message={errorState.description!} relatedDependencies={[]} />}
        </>
      </DialogContent>
      {user ? (
        <DialogActions>
          <Button
            label={
              !deleteConfirmed
                ? user.statusInAccount === 'PENDING_ACCEPT_INVITE'
                  ? t('pageUsers.modalEdit.buttonDelete')
                  : t('pageUsers.modalEdit.inactivate')
                : t('pageUsers.modalEdit.buttonDeleteConfirmation')
            }
            isDelete
            loading={loadingApiDelete}
            onClick={handleDelete}
          />

          <Button
            label={t('pageTeams.modalEdit.save')}
            loading={loadingApi}
            onClick={() => {
              setSubmitting(true)
              setTimeout(() => setSubmitting(false), 1)
            }}
          />
        </DialogActions>
      ) : (
        <DialogActions>
          {isReactivating ? (
            <Button
              label={t('pageTeams.modalEdit.reactivate')}
              loading={loadingApiReactivate}
              onClick={() => {
                setSubmitting(true)
                setTimeout(() => setSubmitting(false), 1)
              }}
            >
              )
            </Button>
          ) : (
            <Button
              label={t('pageUsers.modalCreate.buttonInvite')}
              loading={loadingApi}
              onClick={() => {
                setSubmitting(true)
                setTimeout(() => setSubmitting(false), 1)
              }}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default UserDialog
