import { ArrowBack } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const PricingPageHeader: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Stack direction="row" spacing={2} padding={2} alignItems="center" color="#39e790">
      <Button onClick={() => navigate(-1)} size="small" color="inherit" startIcon={<ArrowBack />}>
        {t('pricingPage.back')}
      </Button>
      <img
        style={{
          width: 36,
          height: 'auto'
        }}
        src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/plugger-icon.png"
        alt="Plugger BI logo"
      />
    </Stack>
  )
}
