import { FormIdsType } from '../components/FormController'
import { ConnectionSchema } from './ConnectionSchema'

export type EtlLogStatus = 'STARTED' | 'ERROR' | 'SUCCESS' | 'PARTIAL_SUCCESS' | 'PARTIAL_ERROR' | 'WAITING'

export type EtlLogSchema = {
  date: Date
  description: string
  status: EtlLogStatus
}

export enum PlugType {
  'Movidesk' = 'Movidesk',
  'MySql' = 'MySql',
  'MsSql' = 'MsSql',
  'Firebird' = 'Firebird',
  'Redshift' = 'Redshift',
  'GoogleSheets' = 'GoogleSheets',
  'GoogleAnalytics' = 'GoogleAnalytics',
  'GoogleAds' = 'GoogleAds',
  'FacebookAds' = 'FacebookAds',
  'CsvFile' = 'CsvFile'
}

export type SheetPlugApiSettings = {
  spreadsheet: {
    id: string
    name: string
  }
  sheet: {
    id: number
    name: string
  }
}

export interface PlugSchema {
  id: number
  name: string
  type: PlugType
  tableBinding: string
  connection?: ConnectionSchema
  lastLog: EtlLogSchema | null
  filter?: string | null
  apiSettings?: any
  useAcceleration?: boolean
  canUseAcceleration?: boolean
}

export type PlugSchemaWithFields = PlugSchema & {
  fields: PlugFieldSchema[]
}

export type PlugFieldType = 'STRING' | 'NUMERIC' | 'DATE' | 'DATETIME' | 'BOOLEAN'

export interface PlugFieldSchema {
  name: string
  label: string
  type: PlugFieldType
}

export type CreatePlugSchema = Omit<PlugSchema, 'id' | 'connection' | 'lastLog' | 'apiSettings'| 'canUseAcceleration' | 'tableBinding'> & {
  connectionId?: number
  fields: PlugFieldSchema[]
  customFields?: PlugFieldSchema[]
  apiSettings?: any
  tableBinding?: string
}

export type UpdatePlugSchema = Omit<PlugSchema, 'connection' | 'tableBinding' | 'lastLog' | 'type' | 'canUseAcceleration'> & {
  fields: PlugFieldSchema[]
  customFields?: Omit<PlugFieldSchema, 'type'>[]
  customFieldsChange?: boolean
}

export type AvailableConnectionSchema = {
  id: string
  type: FormIdsType
  image: string
}

export type AvailablePlugSchema = {
  id: string
  name: string
  status: string
  type: PlugType
  image: string
  displayName: string
  availableConnectionId: string
  availableConnection: AvailableConnectionSchema
}
