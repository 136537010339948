import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PivotGrid from 'devextreme-react/pivot-grid'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import SelectBox from '../../components/SelectBox'
import TextField from '../../components/TextField'
import { PivotGridConfig, PivotGridFontSize, setPivotGridConfigOption } from './types'

interface SettingsDialogProps {
  open: boolean
  onClose(): void
  setPivotConfig: setPivotGridConfigOption
  getPivotConfig: (option: keyof PivotGridConfig) => PivotGridConfig[keyof PivotGridConfig]
  pivotGrid: PivotGrid | null
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  blockFieldset: {
    border: 'none'
  },
  blockLegend: {
    borderBottom: '2px groove threedface',
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  blockLegendMoreMg: {
    borderBottom: '2px groove threedface',
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  checkControl: {
    margin: 0
  }
}))

interface FontSizeOpt {
  label: string
  value: PivotGridFontSize
}

const SettingsDialog: React.FC<PropsWithChildren<SettingsDialogProps>> = ({
  open,
  onClose,
  setPivotConfig,
  getPivotConfig,
  pivotGrid
}) => {
  const [showTitle, setShowTitle] = useState(false)
  const [onlyRelevantFilterValues, setOnlyRelevantFilterValues] = useState(false)
  const [title, setTitle] = useState('')
  const classes = useStyles()
  const { t } = useTranslation()

  const fontSizeOptions: Array<FontSizeOpt> = useMemo(
    () => [
      {
        label: t('pivotGrid.menuSettings.modalSettings.FontSize.Small'),
        value: PivotGridFontSize.Small
      },
      {
        label: t('pivotGrid.menuSettings.modalSettings.FontSize.Medium'),
        value: PivotGridFontSize.Medium
      },
      {
        label: t('pivotGrid.menuSettings.modalSettings.FontSize.Large'),
        value: PivotGridFontSize.Large
      },
      {
        label: t('pivotGrid.menuSettings.modalSettings.FontSize.Extra'),
        value: PivotGridFontSize.XLarge
      }
    ],
    [t]
  )
  const [fontSize, setFontSize] = useState<FontSizeOpt>(fontSizeOptions[1])

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (open) {
      const fontSizeCurrentValue = getPivotConfig('fontSize')
      const titleCurrentValue = getPivotConfig('title')
      const showTitleCurrentValue = getPivotConfig('showTitle')
      const pivotHeaderFilter = getPivotConfig('headerFilter')

      const enumFontSizeCurrentValue =
        fontSizeOptions.find((e) => e.value === fontSizeCurrentValue) || fontSizeOptions[1]

      setFontSize(enumFontSizeCurrentValue)
      setTitle(titleCurrentValue)
      setShowTitle(showTitleCurrentValue)
      setOnlyRelevantFilterValues(pivotHeaderFilter.showRelevantValues)
    }
  }, [getPivotConfig, open, fontSizeOptions])

  const handleSetConfig = () => {
    setPivotConfig('fontSize', fontSize.value)
    setPivotConfig('title', title)
    setPivotConfig('showTitle', showTitle)
    const pivotHeaderFilter = getPivotConfig('headerFilter')
    pivotHeaderFilter.showRelevantValues = onlyRelevantFilterValues
    setPivotConfig('headerFilter', pivotHeaderFilter)
    handleClose()
    pivotGrid?.instance.option('headerFilter', pivotHeaderFilter)
  }

  return (
    <Dialog
      title={t('pivotGrid.menuSettings.modalSettings.Title')}
      description={t('pivotGrid.menuSettings.modalSettings.Subtitle')}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box className={classes.root} component="form">
          <Box className={classes.blockFieldset} flex="1 0 0" component="fieldset">
            <Typography className={classes.blockLegend} component="legend">
              {t('pivotGrid.menuSettings.modalSettings.Appeareance')}
            </Typography>

            <SelectBox
              multiple={false}
              disableClearable
              onChange={(e, newValue) => setFontSize(newValue as FontSizeOpt)}
              value={fontSize}
              options={fontSizeOptions}
              getOptionLabel={(opt) => opt.label}
              textFieldProps={{
                label: t('pivotGrid.menuSettings.modalSettings.FontSize')
              }}
            />
          </Box>
          <Box className={classes.blockFieldset} flex="2 0 0" component="fieldset">
            <Typography className={classes.blockLegend} component="legend">
              {t('pivotGrid.menuSettings.modalSettings.Display')}
            </Typography>
            <TextField
              label={t('pivotGrid.menuSettings.modalSettings.TitleOpt')}
              value={title}
              onChange={(ev) => setTitle(ev.target.value)}
              disabled={!showTitle}
            />
            <FormControlLabel
              className={classes.checkControl}
              control={
                <Checkbox
                  checked={showTitle}
                  onChange={(ev) => setShowTitle(ev.target.checked)}
                  name="check-show-title"
                  color="primary"
                />
              }
              label={t('pivotGrid.menuSettings.modalSettings.ShowTitle')}
            />
          </Box>
          <Box className={classes.blockFieldset} flex="2 0 0" component="fieldset">
            <Typography className={classes.blockLegend} component="legend">
              Filtro
            </Typography>
            <FormControlLabel
              className={classes.checkControl}
              control={
                <Checkbox
                  checked={onlyRelevantFilterValues}
                  onChange={(ev) => setOnlyRelevantFilterValues(ev.target.checked)}
                  name="check-only-relative-filter-values"
                  color="primary"
                />
              }
              label={'Mostrar apenas valores significantes'}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button label={t('pivotGrid.menuSettings.modalSettings.Apply')} onClick={handleSetConfig} />
      </DialogActions>
    </Dialog>
  )
}

export default SettingsDialog
