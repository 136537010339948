import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, createContext, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import { ResponseAccountUsedCapabilitiesSchema } from '../../schemas/AccountSchema'
import { PlanSchema } from '../../schemas/PlanSchema'
import { accountService, planService } from '../../services'
import ModalLimitReached from './ModalLimitReached'

type PlanLimitsContextProps = {
  loadUsage: () => Promise<void>
  loading: boolean
  checkHasAnalysisLimit: () => boolean
  checkHasPlugLimit: () => boolean
  checkHasUserLimit: () => boolean
  showLimitReachedModal: (reachedResource: PlanResource) => void
  hideLimitReachedModal: () => void
}

export type PlanResource = 'users' | 'plugs' | 'analysis' | 'databaseSize'

export const PlanLimitsContext = createContext<PlanLimitsContextProps | null>(null)

const PlanLimitsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const accountCapabilitiesRef = useRef<ResponseAccountUsedCapabilitiesSchema | null>(null)
  const planInfoRef = useRef<PlanSchema | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { getAccountInfo } = useAuth()
  const [loading, setLoading] = useState(false)
  const accountInfo = getAccountInfo()
  const [reachedResource, setReachedResource] = useState<PlanResource | null>(null)

  const loadPlanInfo = useCallback(async () => {
    const { data } = await planService.get(accountInfo!.planId)
    planInfoRef.current = data
    return data
  }, [accountInfo])

  const loadAccountCapabilities = async () => {
    setLoading(true)
    try {
      await loadPlanInfo()
      const { data } = await accountService.getCurrentUsedCapabilities()
      accountCapabilitiesRef.current = data
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        enqueueSnackbar(
          t('pagePlugs.modalCreate.toast.error.generic', { msg: err.response?.data?.[0].errorMessage || err.message }),
          { variant: 'error' }
        )
      } else {
        enqueueSnackbar(err.message, { variant: 'error' })
      }
    }
    setLoading(false)
  }

  const checkHasAnalysisLimit = () => {
    const usedCapabilities = accountCapabilitiesRef.current
    const planInfo = planInfoRef.current
    if (usedCapabilities === null || planInfo === null) return false

    return usedCapabilities.analysis + 1 <= planInfo.maxAnalysis
  }

  const checkHasUserLimit = () => {
    const usedCapabilities = accountCapabilitiesRef.current
    const planInfo = planInfoRef.current
    if (usedCapabilities === null || planInfo === null) return false

    return usedCapabilities.activeUsers + 1 <= planInfo.maxUsers
  }

  const checkHasPlugLimit = () => {
    const usedCapabilities = accountCapabilitiesRef.current
    const planInfo = planInfoRef.current
    if (usedCapabilities === null || planInfo === null) return false

    return usedCapabilities.plugs + 1 <= planInfo.maxPlugs
  }

  const showLimitReachedModal = (reachedResource: PlanResource) => {
    setReachedResource(reachedResource)
    setModalOpen(true)
  }

  const hideLimitReachedModal = () => {
    setModalOpen(false)
    setReachedResource(null)
  }

  const contextValue = {
    loadUsage: loadAccountCapabilities,
    loading,
    checkHasAnalysisLimit,
    checkHasPlugLimit,
    checkHasUserLimit,
    showLimitReachedModal,
    hideLimitReachedModal
  }

  return (
    <PlanLimitsContext.Provider value={contextValue}>
      <>
        {children}
        {planInfoRef.current && (
          <ModalLimitReached
            open={modalOpen}
            onClose={hideLimitReachedModal}
            plan={planInfoRef.current!}
            reachedResource={reachedResource}
          />
        )}
      </>
    </PlanLimitsContext.Provider>
  )
}

export default PlanLimitsProvider
