import i18n from '../../../locales/i18next'
import { ComponentExtensionOption, ComponentWithDialogExtension } from '../types'

export const id = 'CustomFieldExtension'

class EditCustomFieldExtension implements ComponentWithDialogExtension {
  name = id
  icon = 'rowproperties'
  text = i18n.t('pivotGrid.extensions.editCustomField.text')
  onItemClick: () => void
  showDialog: ((id: string) => void) | null
  static discriminator: string

  constructor(showDialogImp: (id: string) => void) {
    this.showDialog = showDialogImp
    this.onItemClick = () => {
      this.showDialog!(id)
    }
  }
  items?: ComponentExtensionOption[] | undefined
}

EditCustomFieldExtension.discriminator = 'DialogExtension'

export default EditCustomFieldExtension
