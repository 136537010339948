import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { t } from 'i18next'
import { FC } from 'react'

type DeleteUnPaidAccountDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  loading: boolean
}

const DeleteUnPaidAccountDialog: FC<DeleteUnPaidAccountDialogProps> = ({ open, onClose, onSubmit, loading }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('pageAccount.tabGeneral.deleteAccountDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText color="GrayText">
          {t('pageAccount.tabGeneral.deleteAccountDialog.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t('pageAccount.tabGeneral.deleteAccountDialog.btnCancel')}
        </Button>
        <LoadingButton color="error" variant="contained" onClick={onSubmit} loading={loading}>
          {t('pageAccount.tabGeneral.deleteAccountDialog.btnDelete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUnPaidAccountDialog
