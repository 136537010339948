import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import windowUtils from '../../utils/windowUtils'

interface NumberFormatTypeProviderProps {
  for: string[]
  options?: Intl.NumberFormatOptions
  locales?: string | string[] | undefined
}

const NumberFormatFormatter = (props: DataTypeProvider.ValueFormatterProps & NumberFormatTypeProviderProps) => {
  return new Intl.NumberFormat(props?.locales || windowUtils.getLanguage(), props?.options || {}).format(
    props?.value || 0
  ) as any
}

const NumberFormatTypeProvider: React.FunctionComponent<NumberFormatTypeProviderProps> = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={(formatProps) => <NumberFormatFormatter {...formatProps} {...props} />}
      {...props}
    />
  )
}

export default NumberFormatTypeProvider
