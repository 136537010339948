import EyeIcon from '@mui/icons-material/Visibility'
import SlashEyeIcon from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import TextFieldBase, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: theme.palette.text.primary
  }
}))

interface TextFieldProps {
  iconColor?: React.CSSProperties['color']
}

const TextField: React.FC<PropsWithChildren<MuiTextFieldProps & TextFieldProps>> = ({
  label,
  value,
  onChange,
  type = 'text',
  iconColor = '#FFF',
  InputLabelProps,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const classes = useStyles()

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <TextFieldBase
      fullWidth
      InputLabelProps={{
        className: classes.inputLabel,
        ...InputLabelProps
      }}
      value={value}
      variant="outlined"
      onChange={onChange}
      label={label}
      type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
      InputProps={
        type === 'password'
          ? ({
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: iconColor }}
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <EyeIcon /> : <SlashEyeIcon />}
                  </IconButton>
                </InputAdornment>
              )
            } as Partial<OutlinedInputProps>)
          : ({} as Partial<OutlinedInputProps>)
      }
      {...props}
    ></TextFieldBase>
  )
}

export default TextField
