import UserIcon from '@mui/icons-material/Person'
import { List, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckLimitButton } from '../../components/CheckLimitButton'
import ContentBox from '../../components/ContentBox'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import RootBox from '../../components/RootBox'
import UserDialog from '../../components/UserDialog'
import usePlanLimits from '../../hooks/usePlanLimits/usePlanLimits'
import { UserSchema } from '../../schemas/UserSchema'
import userService from '../../services/userService'
import { normalyzeAndLowering } from '../../utils/stringUtils'
import UserListItem from './UserListItem'

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const UsersPage: React.FC<PropsWithChildren> = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [users, setUsers] = useState<UserSchema[]>([])
  const [editUser, setEditUser] = useState<UserSchema | undefined>()
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [searchValue, setSearchValue] = useState('')
  const [inactiveUsers, setInactiveUsers] = useState<UserSchema[]>([])
  const { t } = useTranslation()
  const { loadUsage, loading: loadingUsage, checkHasUserLimit, showLimitReachedModal } = usePlanLimits()
  const classes = useStyles()

  const getFilteredUsers = () => {
    if (!searchValue) {
      return users.sort((x, y) =>
        !!x.firstName + x.lastName
          ? ((x.firstName?.trim() || '') + (x.lastName?.trim() || ''))?.localeCompare(
              (y.firstName?.trim() || '') + (y.lastName?.trim() || '') || y.email?.trim() || ''
            )
          : x.email
              .trim()
              ?.localeCompare((y.firstName?.trim() || '') + x.lastName?.trim() || '' || y.email?.trim() || '')
      )
    }
    return users
      .filter(
        (p) =>
          normalyzeAndLowering(p.firstName + p.lastName).includes(normalyzeAndLowering(searchValue)) ||
          normalyzeAndLowering(p.email).includes(normalyzeAndLowering(searchValue))
      )
      .sort((x, y) =>
        !!x.firstName + x.lastName
          ? ((x.firstName?.trim() || '') + (x.lastName?.trim() || ''))?.localeCompare(
              (y.firstName?.trim() || '') + (y.lastName?.trim() || '') || y.email?.trim() || ''
            )
          : x.email
              .trim()
              ?.localeCompare((y.firstName?.trim() || '') + x.lastName?.trim() || '' || y.email?.trim() || '')
      )
  }

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await userService.getAll()
        const inactives = data.filter((x) => x.statusInAccount === 'INACTIVE')
        const notInactives = data.filter((x) => x.statusInAccount !== 'INACTIVE')
        setInactiveUsers(inactives)
        setUsers(notInactives)
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, t])

  const handleEdit = (user: UserSchema) => {
    setEditUser(user)
    setDialogOpen(true)
  }

  const reloadUsers = async () => {
    try {
      const { data } = await userService.getAll()
      const inactives = data.filter((x) => x.statusInAccount === 'INACTIVE')
      const notInactives = data.filter((x) => x.statusInAccount !== 'INACTIVE')
      setInactiveUsers(inactives)
      setUsers(notInactives)
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoading(false)
  }

  const handleClose = (refresh?: boolean) => {
    setDialogOpen(false)
    if (refresh) reloadUsers()
  }
  const getFullName = (firstName?: string, lastName?: string) => {
    if (firstName && lastName) return firstName + ' ' + lastName
    return ''
  }

  return (
    <RootBox>
      <Header
        icon={UserIcon}
        title={t('pageUsers.title')}
        subtitle={t('pageUsers.subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        <Stack
          alignItems="flex-end"
          paddingRight={4}
          sx={(theme) => ({
            bgcolor: theme.palette.background.light.default
          })}
        >
          <CheckLimitButton planResource="users" onLimitAvailable={() => setDialogOpen(true)} />
        </Stack>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <List className={classes.list}>
            {getFilteredUsers().map((user) => (
              <UserListItem
                primaryTextString={getFullName(user.firstName, user.lastName)}
                key={user.id}
                itemId={user.id}
                primaryText={getFullName(user.firstName, user.lastName)}
                //secondaryText={user.email}
                imageSrc={user.thumbImagePath}
                onEdit={() => handleEdit(user)}
                item={user}
              />
            ))}
          </List>
        )}
      </ContentBox>
      <UserDialog
        inactiveUsers={inactiveUsers}
        open={dialogOpen}
        onClose={handleClose}
        title={editUser ? t('pageUsers.modalEdit.title') : t('pageUsers.modalCreate.title')}
        description={editUser ? t('pageUsers.modalEdit.subtitle') : t('pageUsers.modalCreate.subtitle')}
        user={editUser}
      />
    </RootBox>
  )
}

export default UsersPage
