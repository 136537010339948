import { FormikContextType } from 'formik'
import React, { useMemo } from 'react'
import { FormProps } from '../../components/Form'
import { FieldPropValues } from '../../components/Form/types'
import FacebookConnectionForm from '../../pages/plugs/CreateNewPlugDialog/components/Facebook/FacebookConnectionForm'
import GoogleConnectionForm from '../../pages/plugs/CreateNewPlugDialog/components/Google/GoogleConnectionForm'
import { ApiConnectionType, DatabaseConnectionType } from '../../schemas/ConnectionSchema'
import { PlugType } from '../../schemas/PlugSchema'
import MovideskConnectionForm from '../forms/Movidesk/MovideskConnectionForm'
import MovideskPlugForm from '../forms/Movidesk/MovideskPlugForm'
import MySqlConnectionForm from '../forms/MySqlConnectionForm'

export const FormIds = { ...DatabaseConnectionType, ...ApiConnectionType, ...PlugType }

export type FormIdsType = keyof typeof FormIds

interface FormControllerProps<T> extends Omit<FormProps<T>, 'initialValues' | 'children'> {
  formId: FormIdsType
  initialValues?: T
  onSubmit(values: T): void | Promise<void>
  children?(value: FormikContextType<any>): React.ReactNode
  [key: string]: any
  isUpdating?: boolean // for different schema validation purpose
}

const forms: { [key in FormIdsType]: any } = {
  [FormIds.MySql]: MySqlConnectionForm,
  [FormIds.Firebird]: MySqlConnectionForm,
  [FormIds.Redshift]: MySqlConnectionForm,
  [FormIds.Movidesk]: MovideskConnectionForm,
  GoogleSheets: MovideskPlugForm,
  GoogleApis: GoogleConnectionForm,
  GoogleAnalytics: GoogleConnectionForm,
  GoogleAds: GoogleConnectionForm,
  MsSql: MySqlConnectionForm,
  FacebookAds: FacebookConnectionForm,
  Facebook: FacebookConnectionForm,
  CsvFile: MovideskPlugForm
}

const FormController = <T extends FieldPropValues>({
  formId,
  children,
  ...rest
}: FormControllerProps<T>): React.ReactElement => {
  const SelectedForm = useMemo(() => forms[formId], [formId])
  return React.createElement(SelectedForm, rest, children as any)
}

export default FormController
