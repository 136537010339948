import React, { useEffect, useState } from 'react'

import { Box, Divider } from '@mui/material'

import SelectBoxFilterField, { SelectBoxFilterFieldProps } from './SelectBoxFilterField'
import { ConditionValue, ConditionValueType } from './types'

const SelectBoxFilterFieldBetween = (props: SelectBoxFilterFieldProps): any => {
  const [firstValue, setFirstValue] = useState<ConditionValue>({ value: '', type: ConditionValueType.values })
  const [secondValue, setSecondValue] = useState<ConditionValue>({ value: '', type: ConditionValueType.values })

  const setValueCb = props.data.setValue
  useEffect(() => {
    setValueCb([firstValue, secondValue])
  }, [firstValue, secondValue, setValueCb])

  const handleChangeFirstValue = (value: ConditionValue) => {
    setFirstValue(value)
  }
  const handleChangeSecondValue = (value: ConditionValue) => {
    setSecondValue(value)
  }

  return (
    <Box display="inline-flex">
      <SelectBoxFilterField
        data={{
          value: firstValue,
          setValue: handleChangeFirstValue,
          field: props.data.field,
          filterOperation: props.data.filterOperation
        }}
        fieldValues={props.fieldValues}
        onSearchClick={props.onSearchClick}
        parameters={props.parameters}
      />
      <Divider style={{ width: 25, backgroundColor: 'black', alignSelf: 'center' }} />
      <SelectBoxFilterField
        data={{
          value: secondValue,
          setValue: handleChangeSecondValue,
          field: props.data.field,
          filterOperation: props.data.filterOperation
        }}
        fieldValues={props.fieldValues}
        onSearchClick={props.onSearchClick}
        parameters={props.parameters}
      />
    </Box>
  )
}

export default SelectBoxFilterFieldBetween
