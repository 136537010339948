import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { PlanSchema, PlanSummary } from '../../schemas/PlanSchema'
import { format } from '../../utils/stringUtils'
import api from '../api'

const get = (id: string | number, currency?: string): Promise<AxiosResponse<PlanSchema>> => {
  return api.get(format(ENDPOINTS.PLANS_GET, id))
}

const getSummary = (id: string | number, currency?: string): Promise<AxiosResponse<PlanSummary>> => {
  return api.get(format(ENDPOINTS.PLANS_GET_SUMMARY, id))
}

const list = (currency?: string): Promise<AxiosResponse<PlanSchema[]>> => {
  return api.get(ENDPOINTS.PLANS_LIST)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { get, list, getSummary }
