import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'

import { PlugFieldSchema } from '../../schemas/PlugSchema'
import api from '../api'

type DetectColumnsRequest = {
  fileUrl: string
  delimiter: string
}
export type CsvColumnMetadata = {
  columnName: string
  columnType: string
}

export type DetectColumnsResponse = {
  columnsMetadata: PlugFieldSchema[]
}

export type CsvFilePlug = {
  id: number
  name: string
  fileUrl: string
  delimiter: string
  fields: PlugFieldSchema[]
  filter?: string
}

export type UpdateCsvPlugRequest = {
  id: number
  name: string
  fields: PlugFieldSchema[]
  filter?: string
}

const services = {
  detectColumns: async (request: DetectColumnsRequest): Promise<AxiosResponse<DetectColumnsResponse>> => {
    return await api.post(ENDPOINTS.CSV_DETECT_COLUMNS, request)
  },
  getCsvFilePlug: async (id: number): Promise<AxiosResponse<CsvFilePlug>> => {
    return await api.get(`${ENDPOINTS.CSV_PLUG}/${id}`)
  },
  updateCsvFilePlug: async (request: UpdateCsvPlugRequest): Promise<AxiosResponse<void>> => {
    return await api.put(`${ENDPOINTS.CSV_PLUG}/${request.id}`, request)
  }
}

export default services
