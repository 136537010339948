import { Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useState } from 'react'
import SuccessCheckMark from '../../components/SuccessCheckMark'
import { GeoAnalysisType } from './GeoAnalysisTypeDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 1),
    cursor: 'pointer',
    maxWidth: 320,
    maxHeight: 390
  },
  header: {
    minHeight: 100,
    minWidth: 300
  },
  media: {
    paddingTop: '55.5%',
    height: 'auto',
    backgroundSize: 'contain'
  }
}))

type GeoAnalysisCardProps = {
  id: GeoAnalysisType
  image: string
  title: string
  description: string
  selected?: boolean
  onSelect: (id: GeoAnalysisType) => void
}

const GeoAnalysisCard: React.FC<PropsWithChildren<GeoAnalysisCardProps>> = ({
  id,
  description,
  image,
  title,
  selected,
  onSelect
}) => {
  const classes = useStyles()

  const [elevation, setElevation] = useState(6)

  const handleMouseOverCard = () => {
    setElevation(16)
  }
  const handleMouseLeaveCard = () => {
    setElevation(6)
  }

  return (
    <Card
      key={id}
      elevation={elevation}
      className={classes.root}
      onMouseOver={handleMouseOverCard}
      onMouseOut={handleMouseLeaveCard}
      onClick={() => onSelect(id)}
    >
      <CardHeader className={classes.header} title={title} action={selected && <SuccessCheckMark />} />
      <CardMedia className={classes.media} image={image} title={title} />
      <CardContent>
        <Typography variant="body2" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default GeoAnalysisCard
