import { Add, SettingsEthernet } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ContentBox from '../../components/ContentBox'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import Header from '../../components/Header'
import List from '../../components/List'
import Loading from '../../components/Loading'
import RootBox from '../../components/RootBox'
import WarningContainer from '../../components/WarningContainer'
import ParameterForm from '../../components/forms/ParameterForm'
import { ParameterSchema } from '../../schemas/ParameterSchema'
import { Error, instanceOfAxiosError } from '../../services/api'
import parameterService from '../../services/parameterService'
import { normalyzeAndLowering } from '../../utils/stringUtils'
import { RelatedAnalysisDependecy } from '../plugs/UpdatePlugDialog/components/Common/UpdateDialog'

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const ParametersPage: React.FC<PropsWithChildren> = () => {
  const [parameters, setParameters] = useState<ParameterSchema[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [deleteErrorBag, setDeleteErrorBag] = useState<{
    message: string
    dependents: RelatedAnalysisDependecy[]
  } | null>(null)
  const { t } = useTranslation()
  const [dialogOptions, setDialogOptions] = useState<{
    open: boolean
    title: string
    description: string
    updateId?: string
    buttonLabel: string
  }>({
    open: false,
    title: t('pageParameters.modalCreate.title'),
    description: t('pageParameters.modalCreate.subtitle'),
    buttonLabel: t('pageParameters.modalCreate.buttonCreate')
  })
  const [searchValue, setSearchValue] = useState('')
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiDelete, setLoadingApiDelete] = useState(false)
  const classes = useStyles()

  const getFilteredParameters = () => {
    if (!searchValue) {
      return parameters.sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
    }
    return parameters
      .filter((p) => normalyzeAndLowering(p.name).includes(normalyzeAndLowering(searchValue)))
      .sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
  }

  const reloadParameters = useCallback(async () => {
    const response = await parameterService.getParameters()
    setParameters(response.data)
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const response = await parameterService.getParameters()
        setParameters(response.data)
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, t])

  const handleCloseDialog = useCallback(() => {
    setDialogOptions((prev) => ({
      ...prev,
      open: false
    }))
    setLoadingApi(false)
    setIsSubmittingForm(false)
    setDeleteConfirmed(false)
    setDeleteErrorBag(null)
  }, [])

  const handleCreateParameter = () => {
    setDialogOptions({
      open: true,
      title: t('pageParameters.modalCreate.title'),
      description: t('pageParameters.modalCreate.subtitle'),
      buttonLabel: t('pageParameters.modalCreate.buttonCreate')
    })
  }

  const handleEditParameter = (parameter: ParameterSchema) => {
    setDialogOptions({
      open: true,
      title: t('pageParameters.modalEdit.title'),
      description: t('pageParameters.modalEdit.subtitle'),
      updateId: parameter.id,
      buttonLabel: t('pageParameters.modalEdit.btnSave')
    })
  }

  const handleDeleteParameter = async () => {
    if (!deleteConfirmed) {
      setDeleteConfirmed(true)
      return
    }
    try {
      setLoadingApiDelete(true)
      await parameterService._delete(dialogOptions.updateId as string)
      reloadParameters()
      handleCloseDialog()
      enqueueSnackbar('Parameter has been deleted', { variant: 'success' })
    } catch (err: any) {
      console.error('error when try delete parameter', err)
      if (instanceOfAxiosError(err)) {
        const parameterDeleteError = err.response?.data as
          | {
              errors: Error[]
              dependents: RelatedAnalysisDependecy[]
            }
          | undefined

        setDeleteErrorBag({
          message: parameterDeleteError!.errors[0].errorMessage,
          dependents: parameterDeleteError!.dependents
        })
      } else {
        console.error('erro when try delete parameter {0}', err)
        enqueueSnackbar(t('pageParameter.modal.toast.error.delete', { msg: err.message }), { variant: 'error' })
      }
    }
    setLoadingApiDelete(false)
  }

  const handleSubmit = () => {
    setIsSubmittingForm(true)
    setLoadingApi(true)
    setTimeout(() => setIsSubmittingForm(false))
  }

  return (
    <RootBox>
      <Header
        icon={SettingsEthernet}
        title={t('pageParameters.Title')}
        subtitle={t('pageParameters.Subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        <Stack
          alignItems="flex-end"
          paddingRight={4}
          sx={(theme) => ({
            bgcolor: theme.palette.background.light.default
          })}
        >
          <Button
            sx={{
              height: 40
            }}
            onClick={() => handleCreateParameter()}
            startIcon={<Add />}
            label="Criar"
          />
        </Stack>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <List
            className={classes.list}
            items={getFilteredParameters()}
            itemIdKey="id"
            itemLabelKey="name"
            onEdit={handleEditParameter}
          />
        )}
      </ContentBox>
      <Dialog
        open={dialogOptions.open}
        title={dialogOptions.title}
        description={dialogOptions.description}
        onClose={handleCloseDialog}
      >
        <DialogContent dividers>
          <ParameterForm
            isSubmitting={isSubmittingForm}
            toggleDialog={handleCloseDialog}
            reloadParameters={reloadParameters}
            updateId={dialogOptions.updateId}
          />
          {deleteErrorBag && (
            <WarningContainer message={deleteErrorBag?.message} relatedDependencies={deleteErrorBag?.dependents} />
          )}
        </DialogContent>
        <DialogActions>
          {dialogOptions.updateId && (
            <Button
              onClick={handleDeleteParameter}
              loading={loadingApiDelete}
              label={
                deleteConfirmed ? t('pageParameters.modal.buttonCofirmDelete') : t('pageParameters.modal.buttonDelete')
              }
              isDelete
            />
          )}
          <Button onClick={handleSubmit} loading={loadingApi} label={dialogOptions.buttonLabel} />
        </DialogActions>
      </Dialog>
    </RootBox>
  )
}

export default ParametersPage
