import { Add } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import SelectBox from '../../../../../../components/SelectBox'
import TextField from '../../../../../../components/TextField'
import { ApiConnectionType, ConnectionSchema } from '../../../../../../schemas/ConnectionSchema'
import { connectionService } from '../../../../../../services'
import googleAnalyticsService, {
  AnalyticsAccount,
  AnalyticsAccountProperty
} from '../../../../../../services/google/googleAnalyticsService'
import { GoogleScope } from '../../../../../../services/google/types'
import NavigationHeader, { NavigationHeaderProps } from '../../NavigationHeader'
import GoogleConnectionForm from '../GoogleConnectionForm'

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(3, 0, 0)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

export type GoogleAnalyticsPlugFormValues = {
  plugName: string
  property: AnalyticsAccountProperty
  account: AnalyticsAccount
  connection: ConnectionSchema
}

type GoogleAnalyticsPlugFormProps = {
  onSubmit: (values: GoogleAnalyticsPlugFormValues) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const plugFormSchemaValidator = yup.object({
  plugName: yup.string().required(),
  property: yup.object().required(),
  account: yup.object().required(),
  connection: yup.object().required()
})

const GoogleAnalyticsPlugForm: React.FC<PropsWithChildren<GoogleAnalyticsPlugFormProps>> = ({
  navigationProps,
  onSubmit
}) => {
  const classes = useStyles()
  const [connections, setConnections] = useState<ConnectionSchema[]>([])
  const [accounts, setAccounts] = useState<AnalyticsAccount[]>([])
  const [properties, setProperties] = useState<AnalyticsAccountProperty[]>([])

  const [loadingFindConnection, setLoadingFindConnection] = useState(false)
  const [loadingFindAccount, setLoadingFindAccount] = useState(false)

  const [loadingCreateConnection, setLoadingCreateConnection] = useState(false)
  const [showCreateConnectionForm, setShowCreateConnectionForm] = useState(false)
  const { values, setFieldValue, submitForm, errors } = useFormik({
    validateOnChange: false,
    initialValues: {} as GoogleAnalyticsPlugFormValues,
    onSubmit: onSubmit,
    validationSchema: plugFormSchemaValidator
  })

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const handleValuesChange = (key: string, value: any) => {
    setFieldValue(key, value)
  }

  const handleOpenSelectConnection = () => {
    ;(async () => {
      try {
        setLoadingFindConnection(true)
        const connsResp = await connectionService.getAll()
        setConnections(connsResp.data.filter((x) => x.type === ApiConnectionType.GoogleApis))
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindConnection(false)
      }
    })()
  }

  const handleOpenSelectAccount = async () => {
    ;(async () => {
      try {
        setLoadingFindAccount(true)
        const { data } = await googleAnalyticsService.listAccountSummaries((values!.connection as any).id)
        setAccounts(data)
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindAccount(false)
      }
    })()
  }
  const handleOpenSelectProperty = async () => {
    ;(async () => {
      try {
        setLoadingFindAccount(true)
        const { data } = await googleAnalyticsService.listAccountProperties(
          (values!.connection as any).id,
          values.account.id
        )
        setProperties(data)
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindAccount(false)
      }
    })()
  }

  const handleCreateConnection = async (values: any) => {
    values.type = ApiConnectionType.GoogleApis
    try {
      setLoadingCreateConnection(true)
      const response = await connectionService.createGoogleApisConnection(values)

      setConnections((prev) => [...prev, response.data])
      handleValuesChange('connection', response.data)
      setShowCreateConnectionForm(false)

      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createConnection', { connectionName: values.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.createConnection'), { variant: 'error' })
    } finally {
      setLoadingCreateConnection(false)
    }
  }

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() => submitForm()}
      />
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Typography variant="h2" align="center" component="div" color="inherit">
          {t('pagePlugs.modalCreate.configureTitle')}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p" color="inherit">
          {t('pagePlugs.modalCreate.configureSubtitle')}
        </Typography>
        {!showCreateConnectionForm ? (
          <>
            <Box display="flex" alignItems="center" className={classes.dialogFields}>
              <SelectBox
                className={clsx(classes.outlinedWhiteInput, classes.fullWidth)}
                options={connections}
                value={values.connection}
                onChange={(event, newValue) => handleValuesChange('connection', newValue)}
                getOptionLabel={(o) => o.name}
                loading={loadingFindConnection}
                onOpen={handleOpenSelectConnection}
                textFieldProps={{
                  placeholder: t('pagePlugs.modalCreate.configureConnection')!,
                  name: 'connection',
                  error: !!errors['connection'],
                  helperText: errors['connection'] as any
                }}
              />
              <IconButton onClick={() => setShowCreateConnectionForm(true)} color="inherit" size="large">
                <Add />
              </IconButton>
            </Box>
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              options={accounts}
              loading={loadingFindAccount}
              value={values.account}
              onChange={(event, newValue) => handleValuesChange('account', newValue)}
              getOptionLabel={(o) => o.name}
              onOpen={handleOpenSelectAccount}
              textFieldProps={{
                placeholder: 'Account',
                name: 'account',
                error: !!errors['account'],
                helperText: errors['account'] as any
              }}
            />
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              options={properties}
              value={values.property}
              onChange={(event, newValue) => handleValuesChange('property', newValue)}
              getOptionLabel={(o) => o.name}
              onOpen={handleOpenSelectProperty}
              textFieldProps={{
                placeholder: 'Property',
                name: 'property',
                error: !!errors['property'],
                helperText: errors['property'] as any
              }}
            />
            <TextField
              variant="outlined"
              fullWidth={false}
              value={values.plugName}
              onChange={(event) => handleValuesChange('plugName', event.target.value)}
              required
              className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
              placeholder={t('pagePlugs.modalCreate.configureName')!}
              error={!!errors['plugName']}
              helperText={errors['plugName']}
            />
          </>
        ) : (
          <GoogleConnectionForm
            scopes={[GoogleScope.ANALYTICS_RO]}
            onSubmit={handleCreateConnection}
            onCancelClick={() => setShowCreateConnectionForm(false)}
            variant="white"
            usePlaceholder
          />
        )}
      </Box>
    </>
  )
}

export default GoogleAnalyticsPlugForm
