import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { PricingPageFooter } from './components/PricingPageFooter'
import { PricingPageHeader } from './components/PricingPageHeader'

function PricingPage() {
  const { t } = useTranslation()
  const { getAccountInfo, getUserInfo } = useAuth()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const accountIdParam = params.get('accountId')

  const accountInfo = getAccountInfo()
  const userInfo = getUserInfo()

  const accountId = accountIdParam || accountInfo?.accountId

  if (accountInfo === null) {
    alert('error test')
  }

  return (
    <Stack position="absolute" top={0} left={0} right={0} bottom={0} bgcolor="rgb(42, 44, 50)">
      <PricingPageHeader />
      <Stack spacing={1} paddingY={1} bgcolor="rgb(60, 63, 72)" flexGrow={1} textAlign="center">
        <Typography variant="h1" color="#39e790">
          {t('pricingPage.title')}
        </Typography>
        <Typography variant="h2" color="#39e790">
          {t('pricingPage.subtitle')}
        </Typography>
        <stripe-pricing-table
          pricing-table-id="prctbl_1MACdnH6DHjFP4kh1V52ovEX"
          publishable-key="pk_live_51M2h9dH6DHjFP4kh5DjxxsmyXW6vRbK79jgwlX4W4YDSYNOqj5wL85D7emWwGBLujHSE4WblTfFLJ1Ht4Ch8rp7300BgIoPZOI"
          client-reference-id={accountId}
          customer-email={userInfo?.userEmail}
        ></stripe-pricing-table>
      </Stack>
      <PricingPageFooter />
    </Stack>
  )
}

export default PricingPage
