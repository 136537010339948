import { PaymentStatus } from '../payment'
import { TrialEndModal } from './TrialEndModal'
import { PaymentProblemModal } from './PaymentProblemModal'
import { PaymentCanceledModal } from './PaymentCanceledModal'

export type PaymentPresenterProps = {
  show?: boolean
  status: PaymentStatus
}

const notPaymentProblemStatus = ['TRIAL_END', 'TRIALING', 'ACTIVE', 'CANCELED']

export const PaymentPresenter: React.FC<PaymentPresenterProps> = ({ status, show }) => {
  // const { validateAuth } = useAuth()

  // // USER NOT LOGGED OR SESSION EXPIRED
  // if (!matchSignIn && !validateAuth()) {
  //   navigate(PATHS.SIGNIN, {
  //     replace: true,
  //     state: {
  //       returnUrl: location.pathname // TODO CHECK INCLUDE URL PARAMS
  //     }
  //   })
  // }

  return (
    <>
      <TrialEndModal open={!!show && status === 'TRIAL_END'} />
      <PaymentProblemModal open={!!show && !notPaymentProblemStatus.includes(status)} />
      <PaymentCanceledModal open={!!show && status === 'CANCELED'} />
    </>
  )
}
