import { DashboardControl, ItemWidgetOptionEventArgs, ViewerApiExtension } from 'devexpress-dashboard'
import * as Model from 'devexpress-dashboard/model'
import { formatNumber } from 'devextreme/localization'
import { dxPivotGridOptions } from 'devextreme/ui/pivot_grid'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'

const pivotGridFillEmptyCellsWithZeroExtension: Model.CustomPropertyMetadata = {
  ownerType: Model.PivotItem,
  propertyName: 'PivotGridFillEmptyCellsWithZeroExtension',
  defaultValue: '',
  valueType: 'string'
}

Model.registerCustomProperty(pivotGridFillEmptyCellsWithZeroExtension)

function onItemWidgetOptionsPrepared(args: ItemWidgetOptionEventArgs) {
  if (args.dashboardItem instanceof Model.PivotItem) {
    const dataSource = (args.options as dxPivotGridOptions).dataSource as PivotGridDataSource
    const fields = dataSource.fields()
    fields.forEach(f => {
      f.calculateSummaryValue = (e) => {
        if(!e.value() || e.value().trim() === ''){
          return args.itemData.getMeasures().find(x => x.id === e.field(f.area!).dataField!)?.format(0)
        }
        return e.value()
      }
    })
  }
}

export class PivotGridFillEmptyCellsWithZeroExtension {
  name = 'PivotGridFillEmptyCellsWithZeroExtension'
  constructor(private dashboardControl: DashboardControl) {}

  start(): void {
    let viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as ViewerApiExtension
    if (viewerApiExtension) {
      viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
    }
  }

  stop(): void {
    let viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as ViewerApiExtension
    if (viewerApiExtension) {
      viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
    }
  }
}
