import { Box, Card, CardActionArea, CardContent, CardMedia, Slide, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { ResponseAccountSchema } from '../../schemas/AccountSchema'
import staticService from '../../services/staticService'
import base64Utils from '../../utils/base64Utils'

export interface AccountCardProps {
  account: ResponseAccountSchema
  handleAccessAccount: () => void
  handleCompleteAccountRegister: () => void
  handleCancelAccountRegister: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 170,
    maxWidth: 320,
    '&:hover': {
      boxShadow: theme.shadows[16]
    }
  },
  media: {
    height: 0,
    paddingTop: '70.25%',
    backgroundSize: 'auto'
  },
  info: {
    marginTop: theme.spacing(1)
  },
  hoverLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#27a69994',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'rgba(255,255,255, 0.87)'
  },
  twoOptionsHoverLayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#27a69994',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    color: 'rgba(255,255,255, 0.87)'
  },
  completeAccountCreation: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '85%'
  },
  cancelAccountCreation: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25%',
    background: theme.palette.error.main
  }
}))

const AccountCardAccessOverlay: React.FC<{ hovered: boolean; onClick: () => void }> = ({ hovered, onClick }) => {
  const classes = useStyles()
  return (
    <Slide direction="up" in={hovered} timeout={500} onClick={onClick}>
      <Box className={classes.hoverLayer}>
        <Typography variant="h4">Acessar</Typography>
      </Box>
    </Slide>
  )
}
const AccountCardNotPaidOverlay: React.FC<{
  hovered: boolean
  onCompleteClick: () => void
  onCancelClick: () => void
}> = ({ hovered, onCancelClick, onCompleteClick }) => {
  const classes = useStyles()
  return (
    <Slide direction="up" in={hovered} timeout={500}>
      <Box className={classes.twoOptionsHoverLayer}>
        <Box className={classes.completeAccountCreation} onClick={onCompleteClick}>
          <Typography variant="h4">Concluir Cadastro</Typography>
        </Box>
        <Box className={classes.cancelAccountCreation} onClick={onCancelClick}>
          <Typography variant="h4">Cancelar</Typography>
        </Box>
      </Box>
    </Slide>
  )
}

const fallbackImage = ENDPOINTS.PUBLIC_IMAGES + '/default-corporate-image.jpg'

const AccountCard: React.FC<PropsWithChildren<AccountCardProps>> = ({
  account,
  handleAccessAccount,
  handleCancelAccountRegister,
  handleCompleteAccountRegister
}) => {
  const classes = useStyles()
  const [hovered, setHovered] = useState(false)
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  useEffect(() => {
    if (account.logoImage) {
      staticService.get(account.logoImage, localStorage.getItem('token')!).then(async (response) => {
        const imgDataUri = await base64Utils.convertBlobToDataUri(response.data)
        setImageSrc(imgDataUri)
      })
      return
    }
    setImageSrc(null)
  }, [account.logoImage])

  return (
    <Card onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={classes.root}>
      <CardActionArea>
        {account.status === 'NOT_PAID' ? (
          <AccountCardNotPaidOverlay
            hovered={hovered}
            onCompleteClick={handleCompleteAccountRegister}
            onCancelClick={handleCancelAccountRegister}
          />
        ) : (
          <AccountCardAccessOverlay hovered={hovered} onClick={handleAccessAccount} />
        )}
        <CardMedia className={classes.media} image={imageSrc || fallbackImage} title={account.companyName} />
        <CardContent>
          <Typography align="center" variant="h6">
            {account.companyName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default AccountCard
