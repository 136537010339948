import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import GridSelectable from '../../components/GridSelectable'
import Loading from '../../components/Loading'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { ResponseAccountSchema } from '../../schemas/AccountSchema'
import { accountService } from '../../services'
import AccountCard from './AccountCard'
import DeleteUnPaidAccountDialog from './DeleteUnPaidAccountDialog'
import NewAccountCard from './NewAccountCard'
import { TermsAndPolicyAcceptDialog } from './TermsAndPolicyAcceptDialog'

const useStyles = makeStyles((theme) => ({
  box: {
    height: '100vh',
    overflow: 'auto',
    background: `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${
      theme.palette.background.light?.contrast || '#FFF'
    }`,
    backgroundSize: 'auto',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  content: {
    width: '80%'
  },
  container: {
    display: 'grid',
    gap: theme.spacing(2),
    padding: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center'
  },
  dynamicSize: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 320px))'
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  navButton: {
    color: 'rgba(255,255,255, 0.87)'
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 16px)'
  },
  gridSelectable: {
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 260px))'
      //height: '300px'
    },
    padding: '38px'
  }
}))

const CustomTypography = withStyles((theme) => ({
  root: {
    margin: theme.spacing(8, 0),
    color: 'rgba(255,255,255, 0.87)'
  }
}))(Typography)

const SelectAccountPage: React.FC<PropsWithChildren> = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const auth = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [openConfirmAccountDeleteDialog, setOpenConfirmAccountDeleteDialog] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)
  const [loadingDeleteUnpaid, setLoadingDeleteUnpaid] = useState(false)
  const [openTermsAndPolicyDialog, setOpenTermsAndPolicyDialog] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<ResponseAccountSchema | null>(null)
  //const payment = usePayment()

  const handleBackToSignIn = () => {
    navigate(PATHS.SIGNIN)
  }

  const handleAccessClick = (account: ResponseAccountSchema) => {
    setSelectedAccount(account)

    if (!account.termsAndPolicyAccepted) {
      setOpenTermsAndPolicyDialog(true)
      return
    }
    handleAccessAccount(account)
  }

  const handleAccessAccount = async (account: ResponseAccountSchema) => {
    try {
      setLoading(true)
      await auth.accessAccount(account!.id, localStorage.getItem('token')!)
      await auth.loadUserData()
      navigate(PATHS.HOME)
      // payment?.reloadInfos()
    } catch (err: any) {
      setLoading(false)
      enqueueSnackbar(t('selectAccount.page.toast.error.failAccess'), { variant: 'error' })
    }
  }

  const handleAccountComplete = (accountId: number) => {
    navigate('/pricing?accountId=' + accountId)
  }

  const handleDeleteNoPaidAccount = async () => {
    setLoadingDeleteUnpaid(true)
    try {
      if (!selectedAccountId) return
      await accountService.deleteFreeAccount(selectedAccountId)
      const accountIndex = location.state.accounts.findIndex((x: ResponseAccountSchema) => x.id === selectedAccountId)
      location.state.accounts.splice(accountIndex, 1)
      setOpenConfirmAccountDeleteDialog(false)
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoadingDeleteUnpaid(false)
  }

  return (
    <Box className={classes.box}>
      <Box className={classes.content}>
        {loading ? (
          <Box className={classes.loadingBox}>
            <Loading width="300px" height="120px" />
          </Box>
        ) : (
          <>
            <Box className={classes.navigationContainer}>
              <Button
                onClick={handleBackToSignIn}
                size="large"
                className={classes.navButton}
                variant="text"
                startIcon={<ArrowBack />}
              >
                {t('selectAccount.page.upLeft')}
              </Button>
            </Box>
            <CustomTypography variant="h1" align="center">
              {t('selectAccount.page.title')}
              <Typography variant="h6">{t('selectAccount.page.subtitle')}</Typography>
            </CustomTypography>
            <Box className={clsx(classes.container, classes.dynamicSize, GridSelectable)}>
              <NewAccountCard />
              {location.state.accounts
                .sortBy((x: ResponseAccountSchema) => x.companyName)
                .map((account: ResponseAccountSchema) => (
                  <AccountCard
                    account={account}
                    key={account.id}
                    handleAccessAccount={() => handleAccessClick(account)}
                    handleCancelAccountRegister={() => {
                      setSelectedAccountId(account.id)
                      setOpenConfirmAccountDeleteDialog(true)
                    }}
                    handleCompleteAccountRegister={() => handleAccountComplete(account.id)}
                  />
                ))}
            </Box>
          </>
        )}
      </Box>
      <DeleteUnPaidAccountDialog
        open={openConfirmAccountDeleteDialog}
        onSubmit={() => handleDeleteNoPaidAccount()}
        onClose={() => {
          setOpenConfirmAccountDeleteDialog(false)
          setSelectedAccountId(null)
        }}
        loading={loadingDeleteUnpaid}
      />
      <TermsAndPolicyAcceptDialog
        open={openTermsAndPolicyDialog}
        onClose={() => setOpenTermsAndPolicyDialog(false)}
        onConfirm={() => handleAccessAccount(selectedAccount!)}
      />
    </Box>
  )
}

export default SelectAccountPage
