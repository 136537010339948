import PivotGrid, { IPivotGridOptions } from 'devextreme-react/pivot-grid'
import { dxElement } from 'devextreme/core/element'
import dxPivotGrid, { dxPivotGridPivotGridCell } from 'devextreme/ui/pivot_grid'
import { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source'

import { RemoteDataSource } from './dataSource'

import { PlugFieldType } from '../../schemas/PlugSchema'
import { CustomFormats } from './extensions/NumberFormatExtension'

export type PivotGridComponent = dxPivotGrid

export interface PivotGridField extends PivotGridDataSourceField {
  defaultFormat: PivotGridDataSourceField['format']
  plugType: PlugFieldType
  canBeRemoved?: boolean
  index?: number
  renamed?: boolean
  customFormat?: typeof CustomFormats[keyof typeof CustomFormats]
  _initProperties?: PivotGridField
  customFieldExpression?: string
}

export type ComponentExtensionOption = {
  icon?: string | any
  text: string
  onItemClick?: (e: OnPrepareContextMenuEventProps) => void
  selected?: boolean | undefined
  disabled?: boolean | undefined
  items?: ComponentExtensionOption[]
}

export interface ComponentExtension {
  name: string
  icon: string | any
  text: string
  items?: Array<ComponentExtensionOption>
  onItemClick?(e: OnPrepareContextMenuEventProps): void
}

export interface ComponentWithDialogExtension extends ComponentExtension {
  showDialog: ((id: string) => void) | null
}

export type DialogExtensionComponentProps = {
  dataSource?: InstanceType<typeof RemoteDataSource>
  field?: PivotGridField
  open: boolean
  onClose(): void
}

export type OnPrepareContextMenuEventProps = {
  component?: dxPivotGrid
  element?: dxElement
  model?: any
  items?: Array<any>
  area?: string
  cell?: dxPivotGridPivotGridCell
  cellElement?: dxElement
  columnIndex?: number
  rowIndex?: number
  dataFields?: Array<PivotGridDataSourceField>
  rowFields?: Array<PivotGridDataSourceField>
  columnFields?: Array<PivotGridDataSourceField>
  field?: PivotGridField
}

export type ExtensionAreas = 'column' | 'data' | 'filter' | 'row'

export type ExtensionOption = {
  areas: Array<ExtensionAreas>
  extension: new (...args: any[]) => ComponentExtension
  condition?(field: PivotGridField, pivotGrid: dxPivotGrid): boolean
}

export enum PivotGridFontSize {
  Small = '10px',
  Medium = '13px',
  Large = '16px',
  XLarge = '22px'
}

export interface PivotGridConfig extends Omit<IPivotGridOptions, 'dataSource'> {
  fontSize: PivotGridFontSize // default is medium
  title?: string
  showTitle: boolean
  export: {}
}

export interface RemoteDataSourceProps {
  loadFields(): Promise<void>
  getMergedState(): any
  plugId: number
  analyzeId?: string
}

export const PageOrientation = {
  Default: 0,
  Portrait: 1,
  Landscape: 2
}

export const PaperKind = {
  Letter: 1,
  Legal: 5,
  Executive: 7,
  A5: 11,
  A4: 9,
  A3: 8
}

export const ScaleMode = {
  None: 0,
  UseScaleFactor: 1,
  AutoFitToPageWidth: 2
}

export type PivotGridPdfExportOptions = {
  fileName: string
  pageOrientation?: number
  paperKind?: number
  scaleMode?: number
  scaleFactor?: number
  autoFitPageCount?: number
}

export type setPivotGridConfigOption = <T extends keyof PivotGridConfig>(option: T, value: PivotGridConfig[T]) => void
