import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React, { PropsWithChildren } from 'react'
import { ENDPOINTS } from '../../constants/apiEndpoints'

const FilterRemoveIcon: React.FC<PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon {...props}>
      <img src={ENDPOINTS.PUBLIC_IMAGES + '/filter-remove.svg'} alt="filter remove icon" />
    </SvgIcon>
  )
}

export default FilterRemoveIcon
