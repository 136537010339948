import { yupResolver } from '@hookform/resolvers/yup'
import { Email, Person } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteProps,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions
} from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import AnalyzeIcon from '../../../components/AnalyzeIcon'
import { AnalyzeSchema } from '../../../schemas/AnalyzeSchema'
import { SendingRecipient } from '../../../schemas/SendingSchema'
import { userService } from '../../../services'
import FormTextField from './FormTextField'

type GeneralTabProps = {
  formProps: UseFormReturn<
    {
      recipients:
        | {
            userId?: string | undefined | null
            name: string
            emailAddress: string
          }[]
      analysisId: string
      name: string
      subject: string
      senderName?: string | undefined
      exportFormat: string
    },
    any,
    undefined
  >
  analysis: AnalyzeSchema[]
  analysisLoading: boolean
}

const availableAnalysisTypeToExport = ['DASHBOARD', 'REPORT']

const GeneralTab: React.FC<GeneralTabProps> = ({ formProps, analysis, analysisLoading }: GeneralTabProps) => {
  const { control, handleSubmit } = formProps
  const onSubmit = (data: any) => console.log(data)

  const [userRecipients, setUserRecipients] = useState<SendingRecipient[]>([])
  const [usersLoading, setUsersLoading] = useState(false)
  const { t } = useTranslation()

  const handleOpenRecipients = async () => {
    setUsersLoading(true)
    try {
      const { data } = await userService.getAll()
      setUserRecipients(
        data
          .filter((x) => x.statusInAccount === 'ACTIVE')
          .map((x) => ({
            emailAddress: x.email,
            name: x.firstName + ' ' + x.lastName,
            userId: x.id
          }))
      )
    } catch (err) {
      alert(err)
    }
    setUsersLoading(false)
  }

  const exportOptionsTranslations = {
    PDF: t('pageSending.modalCreate.tabExportOptions.exportOptions.pdf'),
    EXCEL: t('pageSending.modalCreate.tabExportOptions.exportOptions.excel'),
    IMAGE: t('pageSending.modalCreate.tabExportOptions.exportOptions.image')
  } as any

  return (
    <Stack component="form" p={2} onSubmit={handleSubmit(onSubmit)} spacing={2}>
      <FormTextField required name="name" label={t('pageSending.modalCreate.tabGeneral.name')} control={control} />
      <FormTextField
        required
        name="subject"
        label={t('pageSending.modalCreate.tabEmailOptions.subject')}
        control={control}
      />
      <FormTextField
        placeholder="Plugger BI"
        name="senderName"
        label={t('pageSending.modalCreate.tabEmailOptions.senderName')}
        control={control}
      />
      <Controller
        name="analysisId"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            options={analysis.map((x) => x.id)}
            onChange={(e, newValue) => field.onChange(newValue)}
            loading={analysisLoading}
            loadingText={t('common.loading')}
            noOptionsText={t('common.noOptions')}
            getOptionLabel={(option) => {
              const analyze = analysis.find((x) => x.id === option)
              return analyze?.name || ''
            }}
            getOptionDisabled={(option) => {
              const analyze = analysis.find((x) => x.id === option)
              if (!analyze) {
                return false
              }
              return !availableAnalysisTypeToExport.includes(analyze.type)
            }}
            renderOption={(props, option) => {
              const analyze = analysis.find((x) => x.id === option)
              if (!analyze) {
                return null
              }
              return (
                <ListItem {...props}>
                  <ListItemIcon>
                    <AnalyzeIcon showTooltip type={analyze.type} />
                  </ListItemIcon>
                  <ListItemText primary={analyze.name} />
                </ListItem>
              )
            }}
            renderInput={(params) => (
              <TextField
                required
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabExportOptions.analysis')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name="exportFormat"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            options={['PDF', 'EXCEL', 'IMAGE']}
            getOptionLabel={(option) => exportOptionsTranslations[option]}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                required
                label={t('pageSending.modalCreate.tabExportOptions.export')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name="recipients"
        control={control}
        render={({ field, fieldState }) => (
          <AutocompleteRecipients
            {...field}
            multiple
            limitTags={6}
            disableClearable
            onOpen={handleOpenRecipients}
            options={userRecipients}
            freeSolo
            isOptionEqualToValue={(option, value) => option.emailAddress === value.emailAddress}
            loading={usersLoading}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                required
                label={t('pageSending.modalCreate.recipients.title')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </Stack>
  )
}

const filter = createFilterOptions<SendingRecipient>({
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: 'any',
  stringify: (option) => option.emailAddress
})

export function AutocompleteRecipients(
  props: Omit<AutocompleteProps<SendingRecipient, true, true, true>, 'onChange'> & {
    onChange: (newValue: SendingRecipient[]) => void
  }
) {
  const { t } = useTranslation()
  const manualRecipientSchemaValidation = yup.object({
    name: yup.string().max(100).required(),
    emailAddress: yup
      .string()
      .email()
      .required()
      .test('already-added', t('pageSending.modalAddRecipient.emailAlreadyAdded')!, (value) => {
        const recipients = (props.value || []) as SendingRecipient[]
        return !recipients.some((x) => x.emailAddress === value)
      })
  })

  const [open, toggleOpen] = React.useState(false)
  const { control, reset, setValue, trigger, getValues } = useForm({
    resolver: yupResolver(manualRecipientSchemaValidation)
  })

  const handleClose = () => {
    reset()
    toggleOpen(false)
  }

  const onSubmit = ({ name, emailAddress }: { name: string; emailAddress: string }) => {
    const recipients = (props.value || []) as SendingRecipient[]
    recipients.push({ name, emailAddress, userId: '' })
    props.onChange(recipients)

    handleClose()
  }

  return (
    <>
      <Autocomplete
        {...props}
        onChange={(event, newValue) => {
          const lastValue = newValue.slice(-1)[0] as any
          if (!lastValue) {
            return props.onChange(newValue as SendingRecipient[])
          }
          if (typeof lastValue === 'string') {
            setTimeout(() => {
              toggleOpen(true)
              setValue('emailAddress', lastValue)
            })
          } else if ('value' in lastValue) {
            setTimeout(() => {
              toggleOpen(true)
              setValue('emailAddress', lastValue.value)
            })
          } else {
            props.onChange(newValue as SendingRecipient[])
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params) as any[]

          if (params.inputValue !== '') {
            filtered.push({ value: params.inputValue, name: `Add "${params.inputValue}"` })
          }

          return filtered
        }}
        getOptionLabel={(option) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          return option.name
        }}
        loadingText={t('common.loading')}
        noOptionsText={t('common.noOptions')}
        renderTags={(values, getTagProps) =>
          values.map((r, index) => (
            <Tooltip
              key={index}
              disableFocusListener
              disableInteractive
              enterDelay={500}
              title={
                <Typography variant="subtitle2">
                  {r.emailAddress}
                  <Typography variant="subtitle2">
                    {!!r.userId
                      ? t('pageSending.tabGeneral.recipients.isAccountUser')!
                      : t('pageSending.tabGeneral.recipients.notIsAccountUser')}
                  </Typography>
                </Typography>
              }
            >
              <Chip icon={!!r.userId ? <Person /> : <Email />} {...getTagProps({ index })} label={r.name} />
            </Tooltip>
          ))
        }
      />
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>{t('pageSending.tabGeneral.modalAddRecipient.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('pageSending.tabGeneral.modalAddRecipient.subtitle')}</DialogContentText>
            <Stack direction="column" spacing={2}>
              <FormTextField
                name="emailAddress"
                label={t('pageSending.tabGeneral.modalAddRecipient.recipientEmail')}
                control={control}
                autoFocus
                required
                margin="dense"
                variant="standard"
              />
              <FormTextField
                name="name"
                label={t('pageSending.tabGeneral.modalAddRecipient.recipientName')}
                control={control}
                autoFocus
                required
                margin="dense"
                variant="standard"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('pageSending.tabGeneral.modalAddRecipient.btnCancel')}</Button>
            <Button
              onClick={async (e) => {
                e.preventDefault()
                const isValid = await trigger()
                if (isValid) {
                  onSubmit(getValues())
                }
              }}
              type="submit"
            >
              {t('pageSending.tabGeneral.modalAddRecipient.btnAdd')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default GeneralTab
