import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../components/Dialog'
import TextField from '../../../components/TextField'

interface SaveDialogProps {
  open: boolean
  onClose(): void
  onClickSave(analysisName: string): void
  title?: string
  baseName?: string
}

const SaveDialog: React.FC<PropsWithChildren<SaveDialogProps>> = ({ open, onClose, onClickSave, title, baseName }) => {
  const [name, setName] = useState('')
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (open && baseName) setName(baseName)
  }, [baseName, open])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const clearState = () => {
    setName('')
  }

  const handleClose = () => {
    clearState()
    onClose()
  }

  const handleSave = () => {
    if (!name) {
      setError(true)
      return
    }
    onClickSave(name)
    onClose()
    clearState()
  }

  return (
    <Dialog
      title={t('dashboard.menuSaveCopy.TitleName')}
      description={t('dashboard.menuSaveCopy.Subtitle')}
      open={open}
      onClose={handleClose}
    >
      <DialogContent style={{ padding: '8px' }}>
        <TextField
          error={error}
          required
          helperText={error && t('report.menuSaveCopy.modalName.validation')}
          label={t('report.menuSaveCopy.modalName')}
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button label={t('report.menuSaveCopy.modalButtonSave')} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

export default SaveDialog
