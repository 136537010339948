import { AccountBox } from '@mui/icons-material'
import { Tab, Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, Link as RouteLink, useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import RootBox from '../../components/RootBox'
import PATHS from '../../constants/paths'

const useStyles = makeStyles((theme) => ({
  content: {
    // display: 'flex',
    // flexGrow: 3
  },
  tabs: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.light.default
  }
}))

const SignedInUserPage: React.FC<PropsWithChildren> = () => {
  const classes = useStyles()
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState<string>(location.pathname)
  const { t } = useTranslation()

  return (
    <RootBox>
      <Header
        showFilterActions={false}
        hideSearchBar
        icon={AccountBox}
        title={t('pageUserlogged.Settings.title')}
        subtitle={t('pageUserlogged.Settings.subtitle')}
      />
      <Box className={classes.content}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={(_e, value) => setSelectedTab(value)}
          indicatorColor="primary"
          variant="standard"
          scrollButtons="auto"
          centered
        >
          <Tab
            component={RouteLink}
            to={PATHS.SIGNED_IN_USER}
            value={PATHS.SIGNED_IN_USER}
            label={t('pageUserlogged.Settings.tabMyUser')}
          />
          <Tab
            component={RouteLink}
            to={PATHS.ACCOUNT_SECURITY}
            value={PATHS.ACCOUNT_SECURITY}
            label={t('pageUserlogged.Settings.tabSecurity')}
          />
        </Tabs>

        <Outlet />
      </Box>
    </RootBox>
  )
}

export default SignedInUserPage
