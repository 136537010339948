/* eslint-disable no-extend-native */
const getPropertyByPathArray = (obj: any, keyArray: string[]) => {
  function accessObj(obj: any, key: string) {
    return obj[key]
  }
  let deepObj = obj
  for (const key of keyArray) {
    deepObj = accessObj(deepObj, key)
  }
  return deepObj
}

const groupBy = <T extends any>(array: T[], groupkey: string[]): Array<any> => {
  return array.reduce((acc, it) => {
    ;(acc[getPropertyByPathArray(it, groupkey)] = acc[getPropertyByPathArray(it, groupkey)] || []).push(it)
    return acc
  }, [] as any[any])
}

export interface GroupedArray<T> {
  key: T[keyof T]
  items: [T]
}

declare global {
  interface Array<T> {
    groupBy(this: T[], key: keyof T): Array<GroupedArray<T>>
    sortBy(this: T[], action: (it: T) => any, order?: 'desc' | 'asc'): Array<T>
  }
}

if (!Array.prototype.groupBy) {
  Array.prototype.groupBy = function <T>(this: T[], key: keyof T) {
    const groupArray: Array<GroupedArray<any>> = []
    this.reduce(function (prev, cur) {
      const group = prev.find((e: any) => e.key === cur[key])
      if (!group) {
        prev.push({
          key: cur[key],
          items: [cur]
        })
      } else {
        group.items.push(cur)
      }
      return prev
    }, groupArray)
    return groupArray
  }
}
if (!Array.prototype.sortBy) {
  Array.prototype.sortBy = function <T>(this: T[], action: (it: T) => any, order: 'desc' | 'asc' = 'asc') {
    return this.sort((x, y) => {
      if (action(x) > action(y)) {
        return order === 'asc' ? 1 : -1
      }
      if (action(x) < action(y)) {
        return order === 'asc' ? -1 : 1
      }
      return 0
    })
  }
}
export default { groupBy }
