function getLanguage(): string {
  let language
  if (window.navigator.languages) {
    language = window.navigator.languages[0]
  } else {
    language = window.navigator.language
  }
  return language
}

function openFullscreen(): void {
  document.documentElement.requestFullscreen()
}

function closeFullscreen(): void {
  const docEl = document as any
  if (docEl.exitFullscreen) {
    docEl.exitFullscreen()
  } else if (docEl.webkitExitFullscreen) {
    /* Safari */
    docEl.webkitExitFullscreen()
  } else if (docEl.msExitFullscreen) {
    /* IE11 */
    docEl.msExitFullscreen()
  }
}

function isFullScreen(): boolean {
  return !!document.fullscreenElement
}

export default { getLanguage, openFullscreen, closeFullscreen, isFullScreen }
