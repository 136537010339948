import { Close, Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material'
import { Stack } from '@mui/system'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { userService } from '../../services'
import { ExternalLogin } from '../../services/userService'

const loginIconsMap: { [key: string]: string } = {
  Google: 'https://www.google.com.br/images/branding/googleg/1x/googleg_standard_color_128dp.png'
}

type LoginListItemProps = {
  login: ExternalLogin
  onLoginDeleteClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, login: ExternalLogin) => void
}

const LoginListItem: React.FC<LoginListItemProps> = ({ login, onLoginDeleteClick }) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={(e) => onLoginDeleteClick?.(e, login)}>
          <Delete />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar src={loginIconsMap[login.loginProvider]} alt="Google Icon" />
      </ListItemAvatar>
      <ListItemText primary={login.providerEmail} />
    </ListItem>
  )
}

type ModalDeleteLoginProps = {
  open: boolean
  onCancelClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  handleDelete?: () => Promise<void>
}

const ModalDeleteLogin: React.FC<ModalDeleteLoginProps> = ({ open, onCancelClick, handleDelete }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handleDeleteClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setLoading(true)
    await handleDelete?.()
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={onCancelClick}>
      <DialogTitle>{t('pageUserlogged.Logins.deleteLogin')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('pageUserLogger.Logins.ConfirmDeleteText')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>{t('pageUserlogged.Logins.cancelBtn')}</Button>
        <LoadingButton loading={loading} onClick={handleDeleteClick}>
          {t('pageUserlogged.Logins.deleteBtn')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

type ModalAddLoginProps = {
  open: boolean
  onCancelClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onSuccessAddLogin?: () => void
  onErrorAddLogin?: () => void
}

const ModalAddLogin: React.FC<ModalAddLoginProps> = ({ open, onCancelClick, onErrorAddLogin, onSuccessAddLogin }) => {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const googleBtnContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: true,
      context: 'signin',
      auto_select: false,
      callback: async (response: any) => {
        setLoading(true)
        try {
          await userService.addExternalLogin({
            provider: 'Google',
            token: response.credential
          })
        } catch (err: any) {
          console.warn(err)
          if (err.response?.status === 409) {
            enqueueSnackbar(t('loginProvider.loginAlreadyInUse'), { variant: 'error' })
          } else {
            enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
          }
          setLoading(false)
          onErrorAddLogin?.()
          return
        }
        setLoading(false)
        onSuccessAddLogin?.()
      }
    })
  }, [enqueueSnackbar, onErrorAddLogin, onSuccessAddLogin, t])

  useEffect(() => {
    const googleBtnContainer = googleBtnContainerRef.current
    if (googleBtnContainer === null) return
    google.accounts.id.renderButton(googleBtnContainer, {
      type: 'standard',
      width: '400',
      size: 'medium',
      theme: 'outline'
    })
  }, [open])

  return (
    <Dialog open={open} onClose={onCancelClick} keepMounted>
      <DialogTitle>
        {t('pageUserlogged.Logins.addLogin')}
        <IconButton
          aria-label="close"
          onClick={onCancelClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} minWidth={400} minHeight={52}>
          {loading ? (
            <Typography>{t('pageUserlogged.Logins.loadingText.creatingLogin')}</Typography>
          ) : (
            <div ref={googleBtnContainerRef} style={{ height: '44px' }} id="google-modal-btn-container" />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const SignedUserLogins = () => {
  const [logins, setLogins] = useState<ExternalLogin[]>([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectedLoginDelete, setSelectedLoginDelete] = useState<ExternalLogin | null>(null)
  const [openAddLoginModal, setOpenAddLoginModal] = useState(false)

  const fetchLogins = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await userService.listLogins()
      setLogins(data)
    } catch (err: any) {
      console.log(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoading(false)
  }, [enqueueSnackbar, t])

  useEffect(() => {
    fetchLogins()
  }, [fetchLogins])

  const handleLoginDeleteClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, login: ExternalLogin) => {
    e.preventDefault()
    setSelectedLoginDelete(login)
  }

  const handleLoginDelete = async () => {
    if (selectedLoginDelete === null) return
    try {
      await userService.deleteExternalLogin({
        loginProvider: selectedLoginDelete.loginProvider,
        providerKey: selectedLoginDelete.providerKey
      })
      setLogins(
        logins.filter(
          (l) =>
            l.loginProvider !== selectedLoginDelete.loginProvider && l.providerKey !== selectedLoginDelete.providerKey
        )
      )
      enqueueSnackbar(t('pageUserlogged.Logins.deleteSuccess'), { variant: 'success' })
      setSelectedLoginDelete(null)
    } catch (err: any) {
      console.log(err)
      if (axios.isAxiosError(err)) {
        const respString = err.response?.request.response
        const resp = JSON.parse(respString || '{}')
        const errors = resp.errors
        if (!!errors['CannotRemoveLastLoginWhenPasswordNotDefined']) {
          enqueueSnackbar(t('pageUserlogged.Logins.deleteError.lastLogin'), { variant: 'error' })
          return
        }
      }
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }

  return (
    <>
      <Stack>
        <Button variant="contained" color="primary" fullWidth onClick={() => setOpenAddLoginModal(true)}>
          {t('pageUserlogged.Logins.addLogin')}
        </Button>
        <List>
          {loading ? (
            <Loading />
          ) : logins.length === 0 ? (
            <div>{t('pageUserlogged.Logins.noLogins')}</div>
          ) : (
            logins.map((login) => <LoginListItem login={login} onLoginDeleteClick={handleLoginDeleteClick} />)
          )}
        </List>
      </Stack>
      <ModalDeleteLogin
        open={!!selectedLoginDelete}
        onCancelClick={() => setSelectedLoginDelete(null)}
        handleDelete={handleLoginDelete}
      />
      <ModalAddLogin
        open={openAddLoginModal}
        onCancelClick={() => setOpenAddLoginModal(false)}
        onSuccessAddLogin={() => {
          setOpenAddLoginModal(false)
          fetchLogins()
        }}
        onErrorAddLogin={() => {
          setOpenAddLoginModal(false)
        }}
      />
    </>
  )
}

function Loading() {
  const { t } = useTranslation()

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography color={'#808080'}>{t('pageUserlogged.Logins.loadingText')}</Typography>
    </Stack>
  )
}

export default SignedUserLogins
