import { Box, styled, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import Loading from '../../components/Loading'

const Root = styled(Box)(({ theme }) => ({
  width: `calc(100vw - ${theme.spacing(1)})`,
  height: `calc(100vh - ${theme.spacing(1)})`,
  backgroundColor: theme.palette.background.light.contrast,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  color: '#ffffffc1'
}))

const DocumentLoading: React.FC<PropsWithChildren> = () => {
  return (
    <Root>
      <StyledTypography variant="h1">Loading The Document...</StyledTypography>
      <StyledTypography variant="h2">Please Wait.</StyledTypography>
      <Loading width="200px" height="80px" />
    </Root>
  )
}

export default DocumentLoading
