import { Close } from '@mui/icons-material'
import { IconButton, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren } from 'react'
import Table from '../../components/Table'
import NumberFormatTypeProvider from '../../components/Table/NumberFormatTypeProvider'
import useAuth from '../../hooks/useAuth'
import { PlugFieldSchema } from '../../schemas/PlugSchema'

type StyleProps = {
  tableWidth: number
}

const useStyles = makeStyles(() => ({
  infoWindow: {
    position: 'absolute',
    width: (props: StyleProps) => `${props.tableWidth}px`,
    zIndex: 100000,
    height: '320px',
    display: 'flex',
    flexDirection: 'column'
  },
  table: {
    width: (props: StyleProps) => `${props.tableWidth}px`
  },
  closeBtn: {
    alignSelf: 'flex-end'
  },
  marker: {
    color: '#39e991'
  }
}))

type MarkerValues = {
  [key: string]: string | number
}

interface ToolTipContentProps {
  values: Array<MarkerValues>
  dimensionColumns: PlugFieldSchema[]
  valueColumns: PlugFieldSchema[]
}

const ToolTipContent: React.FC<PropsWithChildren<ToolTipContentProps>> = ({
  dimensionColumns,
  valueColumns,
  values
}) => {
  const joinedColumns = [...dimensionColumns, ...valueColumns]
  const classes = useStyles({ tableWidth: joinedColumns.length * 210 })
  const auth = useAuth()
  const userLocale = auth.getUserInfo()?.locale

  return (
    <Paper className={classes.infoWindow}>
      <IconButton className={classes.closeBtn} onClick={() => console.log('aadsas')} size="large">
        <Close />
      </IconButton>
      <Table
        className={classes.table}
        height="320px"
        selectionDisabled
        editingProps={{ columnEditingEnabled: false, onCommitChanges: () => false }}
        rows={values}
        columns={joinedColumns.map((x) => ({
          name: x.name,
          title: x.label
        }))}
      >
        <NumberFormatTypeProvider for={valueColumns.map((x) => x.name)} locales={userLocale} options={{}} />
      </Table>
    </Paper>
  )
}

export default ToolTipContent
