import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import DialogTitleContent from '../../components/DialogTitleContent'
import GroupedList from '../../components/GroupedList'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { AnalyzeSchema, AnalyzeType, ShareType } from '../../schemas/AnalyzeSchema'
import { CollectionSchema } from '../../schemas/CollectionSchema'
import { UserSchema } from '../../schemas/UserSchema'
import { analyzeService, userService } from '../../services/index'

interface ShareDialogProps {
  analyzeOrCollection?: AnalyzeSchema | CollectionSchema
  onClose(refresh?: boolean): void
  open: boolean
}

type UserSchemaWithClaim = UserSchema & {
  claimType?: ShareType
}

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 2)
  }
}))

interface GroupedUser {
  name: string
  users: UserSchemaWithClaim[]
}

function isAnalyze(
  analyzeOrCollection: AnalyzeSchema | CollectionSchema | undefined
): analyzeOrCollection is AnalyzeSchema {
  return !!analyzeOrCollection && 'type' in analyzeOrCollection && analyzeOrCollection.type !== AnalyzeType.COLLECTION
}

const ShareDialog: React.FC<PropsWithChildren<ShareDialogProps>> = ({ analyzeOrCollection, onClose, open }) => {
  const classes = useStyles()
  const [groupedUsers, setGroupedUsers] = useState<GroupedUser[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const { getUserInfo } = useAuth()
  const [loadingShareUnshare, setLoadingShareUnshare] = useState(false)
  const currentUser = useRef<UserSchemaWithClaim>()
  const { t } = useTranslation()

  const resetState = () => {
    setGroupedUsers([])
  }

  const getUsersGroupedByTeam = useCallback(async () => {
    if (analyzeOrCollection && open) {
      try {
        const sharedUserClaimsPromise = analyzeService.getSharedUserClaims(analyzeOrCollection?.id)
        const usersPromise = userService.getAll()

        const [respSharedUserClaims, respUsers] = await Promise.all([sharedUserClaimsPromise, usersPromise])
        currentUser.current = respUsers.data.find((u) => u.id === getUserInfo()!.userId)
        currentUser.current!.claimType = respSharedUserClaims.data.find((x) => x.userId == currentUser.current!.id)!
          .claimType as any
        const groupedUsersByTeam = respUsers.data
          .filter(
            (u) =>
              u.id !== getUserInfo()!.userId &&
              u.statusInAccount !== 'INACTIVE' &&
              u.id !== analyzeOrCollection?.userOwner.id
          )
          .reduce((acc, it) => {
            const teamGroupName = it.team?.name || 'No Team'
            let teamGroup = acc.find((e) => e.name === teamGroupName)
            if (!teamGroup) {
              const createdTeam = {
                name: teamGroupName,
                users: [] as UserSchemaWithClaim[]
              }
              acc.push(createdTeam)
              teamGroup = createdTeam
            }
            const userClaim = respSharedUserClaims.data.find((x) => x.userId == it.id)
            ;(it as UserSchemaWithClaim).claimType = userClaim
              ? (userClaim.claimType as unknown as ShareType)
              : undefined
            ;(it as any).fullName = !!it.firstName ? `${it.firstName} ${it.lastName}` : it.email
            teamGroup.users.push(it as UserSchemaWithClaim)

            return acc
          }, [] as GroupedUser[])
        setGroupedUsers(groupedUsersByTeam)
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pageHome.body.modalShare.toast.errorSharedUsers'), { variant: 'error' })
      }
    }
  }, [analyzeOrCollection, enqueueSnackbar, getUserInfo, open, t])

  useEffect(() => {
    getUsersGroupedByTeam()
  }, [getUsersGroupedByTeam])

  const handleBulkCoping = async (
    copies: {
      userId: string
      claimType: ShareType | undefined
    }[]
  ) => {
    try {
      await analyzeService.bulkCopy(
        analyzeOrCollection!.id,
        copies.map((x) => x.userId)
      )
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageHome.body.modalShare.toast.errorSaveACopy', { msg: err.message }), {
        variant: 'error'
      })
    }
  }

  const handleShareUnShareAnalyze = async () => {
    setLoadingShareUnshare(true)
    try {
      const options = groupedUsers
        .map((x) =>
          x.users.map((x) => ({
            userId: x.id,
            claimType: x.claimType
          }))
        )
        .flat()
      const shares = options.filter((x) => !!x.claimType && x.claimType !== 'Copy' && x.claimType !== 'NoShare')
      shares.push({
        userId: currentUser.current!.id,
        claimType: currentUser.current!.claimType
      })
      const copy = options.filter((x) => x.claimType === 'Copy')
      if (copy.length > 0) handleBulkCoping(copy)

      await analyzeService.shareAndUnshare(analyzeOrCollection!.id, shares as any)
      enqueueSnackbar(
        isAnalyze(analyzeOrCollection)
          ? t('pageHome.body.modalShare.toast.successShareAnalysis')
          : t('pageHome.body.modalShare.toast.successShareCollection'),
        {
          variant: 'success'
        }
      )
      onClose(true)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageHome.body.modalShare.toast.errorShare'), { variant: 'error' })
    }
    setLoadingShareUnshare(false)
  }

  const handleChange = (userId: string, shareType: ShareType) => {
    groupedUsers.forEach((x) =>
      x.users.forEach((u) => {
        if (u.id === userId) {
          u.claimType = shareType
        }
      })
    )
    setGroupedUsers([...groupedUsers])
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      fullScreen
      TransitionProps={{
        onExited: resetState
      }}
      sx={{
        zIndex: 1202
      }}
    >
      <DialogTitle>
        <DialogTitleContent
          title={
            isAnalyze(analyzeOrCollection)
              ? t('pageHome.body.modalShare.title.analysis')!
              : t('pageHome.body.modalShare.title.collection')!
          }
          description={t('pageHome.body.modalShare.subtitle')!}
          onClose={() => onClose(false)}
        />
      </DialogTitle>
      <DialogContent dividers>
        {groupedUsers.length === 0 ? (
          <Box>
            <Typography variant="body2" component="p">
              {t('pageHome.body.modalShare.listUsers.empty')}{' '}
              <Link to={PATHS.USERS}>{t('pageHome.body.modalShare.listUsers.hereLink')}</Link>
            </Typography>
          </Box>
        ) : (
          <GroupedList
            onChange={handleChange}
            groupLabelKey="name"
            itemsKey="id"
            fallbackItemLabelKey="email"
            groupItemsKey="users"
            itemsLabelKey="fullName"
            dataSource={groupedUsers}
            itemType={isAnalyze(analyzeOrCollection) ? analyzeOrCollection.type : AnalyzeType.COLLECTION}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          loading={loadingShareUnshare}
          variant="contained"
          color="primary"
          label={t('pageHome.body.modalShare.buttonSave')}
          onClick={handleShareUnShareAnalyze}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ShareDialog
