import { Box, Stack, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import EditableAvatar from '../../components/EditableAvatar'
import Form from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import SelectField from '../../components/Form/SelectField'
import PATHS from '../../constants/paths'
import { useLocaleDetector } from '../../hooks'
import useAuth from '../../hooks/useAuth'
import { ProfileSchema } from '../../schemas/ProfileSchema'
import { TeamSchema } from '../../schemas/TeamSchema'
import { UserEditableSchema } from '../../schemas/UserSchema'
import { accountService, profileService, teamService, userService } from '../../services/index'
import locales from '../../utils/locale_list_en.json'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    width: '100%',

    justifyContent: 'center'
  },
  formButtonWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    width: '90%',
    display: 'flex',
    flexDirection: 'column'
  },
  saveBtn: {
    width: theme.spacing(12),
    alignSelf: 'flex-end',
    marginRight: '1rem'
  }
}))

const SignedInUserPage: React.FC<PropsWithChildren> = () => {
  const auth = useAuth()
  const classes = useStyles()
  const [isSubmitting, setSubmitting] = useState(false)
  const [profiles, setProfiles] = useState<ProfileSchema[]>([])
  const [teams, setTeams] = useState<TeamSchema[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const locale = useLocaleDetector()

  const canEditProfileAndTeam = auth.getUserInfo()?.profile.managingAccess || false
  const userInfo = auth.getUserInfo()!
  const [thumbCrop, setThumbCrop] = useState<string | undefined>(userInfo?.imagePath)
  const signedInUserData: UserEditableSchema = {
    id: userInfo.userId,
    email: userInfo.userEmail,
    firstName: userInfo.userFirstName,
    lastName: userInfo.userLastName,
    profile: userInfo.profile,
    team: userInfo?.team,
    thumbImagePath: userInfo?.imagePath,
    statusInAccount: 'ACTIVE',
    locale: userInfo.locale || locale
  }

  const triggerSubmit = () => {
    setSubmitting(true)
    setTimeout(() => setSubmitting(false), 1)
  }

  const handleSubmit = async (values: UserEditableSchema) => {
    values.thumbImagePath = thumbCrop
    try {
      if (!values.thumbImagePath && !!signedInUserData?.thumbImagePath) {
        await userService.updatePicture(values.id!, { picture: values.thumbImagePath })
      }
      if (!canEditProfileAndTeam) {
        delete values.profile
        delete values.team
      }
      await userService.update(values.id!, {
        firstName: values.firstName!,
        lastName: values.lastName!,
        profileId: values.profile!.id,
        locale: values.locale,
        teamId: values.team?.id
      })
      enqueueSnackbar(t('pageUserlogged.Settings.toast.success.updateInfos', { name: values.firstName }), {
        variant: 'success'
      })
      auth.loadUserData()
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageUserlogged.Settings.toast.error.updateInfos'), { variant: 'error' })
    }
    setSubmitting(false)
  }

  const handleImageCrop = (base64: string | undefined) => {
    setThumbCrop(base64)
  }

  const loadOptions = useCallback(async () => {
    try {
      const profilesPromise = profileService.get()
      const teamsPromise = teamService.getTeams()

      const [profilesResp, teamsResp] = await Promise.all([profilesPromise, teamsPromise])
      setProfiles(profilesResp.data)
      setTeams(teamsResp.data)
    } catch (err: any) {
      console.error('error while try get profiles and teams', err)
      enqueueSnackbar(t('pageUserlogged.Settings.toast.error.loadProfileAndTeams'), { variant: 'error' })
    }
  }, [enqueueSnackbar, t])

  useEffect(() => {
    loadOptions()
  }, [loadOptions])
  const handleManageAccounts = async () => {
    try {
      const { data } = await accountService.getLoggedUserAccounts()
      navigate(PATHS.SELECT_ACCOUNT, {
        state: {
          accounts: data
        }
      })
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formButtonWrapper}>
        <Form isSubmitting={isSubmitting} onSubmit={handleSubmit} initialValues={{ ...signedInUserData }}>
          <EditableAvatar alt="user thumb" preview={thumbCrop} onCrop={handleImageCrop} />
          <OutlinedTextField disabled name="email" label={t('pageUserlogged.Settings.tabMyUser.Email')} required />
          <OutlinedTextField name="firstName" label={t('pageUserlogged.Settings.tabMyUser.FirstName')} required />
          <OutlinedTextField name="lastName" label={t('pageUserlogged.Settings.tabMyUser.LastName')} required />
          <SelectField
            disabled={!canEditProfileAndTeam}
            name="profile"
            label={t('pageUserlogged.Settings.tabMyUser.Profile')}
            required
            options={profiles}
            getOptionLabel={(opt) => opt.name}
            getOptionSelected={(opt) => opt.name}
          />
          <SelectField
            disabled={!canEditProfileAndTeam}
            name="team"
            label={t('pageUserlogged.Settings.tabMyUser.Team')}
            options={teams}
            getOptionLabel={(opt) => opt?.name || ''}
            getOptionSelected={(opt, value) => opt.id === value.id}
          />
          <SelectField
            label={t('pageUserlogged.Settings.tabMyUser.RegionFormat')}
            name="locale"
            options={locales.map((l) => l.value)}
            getOptionLabel={(opt) => locales.find((l) => l.value === opt)?.label || ''}
          />
          <Typography fontSize="14px">{t('myUser.defaultLanguageHelpText')}</Typography>
        </Form>
        <Stack direction="row" justifyContent="space-between" pb={1.5}>
          <Button color="primary" label={t('btn.managerAccounts')} onClick={() => handleManageAccounts()} />
          <Button color="primary" label={t('pageUserlogged.Settings.tabMyUser.ButtonSave')} onClick={triggerSubmit} />
        </Stack>
      </Box>
    </Box>
  )
}

export default SignedInUserPage
