import Axios, { AxiosResponse } from 'axios'

import api from '../api'

import { BASE_URL, ENDPOINTS } from '../../constants/apiEndpoints'
import { UserSchema } from '../../schemas/UserSchema'
import base64Utils from '../../utils/base64Utils'

interface SignInResponse {
  token: string
  expiration: Date
}

export interface SignUp {
  companyName: string
  email: string
  planId: number | string
}

export interface ConfirmFormValues {
  thumbImage?: string
  fullName: string
  password: string
  token?: string
}

const isAuthenticated = async (): Promise<AxiosResponse> => {
  return api.get(ENDPOINTS.VALIDATE_AUTHENTICATION)
}

const login = (email: string, password: string): Promise<AxiosResponse<SignInResponse>> => {
  return api.post(ENDPOINTS.LOGIN, {
    email,
    password
  })
}

const loginGoogle = (token: string): Promise<AxiosResponse<SignInResponse>> => {
  return api.post(ENDPOINTS.SIGN_IN_GOOGLE, {
    token
  })
}
const accessAccount = (accountId: number, token?: string): Promise<AxiosResponse<SignInResponse>> => {
  if(!token){
    token = localStorage.getItem('token') || ""
  }
  return api.post(ENDPOINTS.ACCESS_ACCOUNT, {
    token,
    accountId
  })
}

const signUpGoogle = (token: string): Promise<AxiosResponse<UserSchema>> => {
  return api.post(ENDPOINTS.SIGN_UP_GOOGLE, {
    token
  })
}

export interface ConfirmNewResponse {
  email: string
  accountId: number
}

const confirmNew = (values: ConfirmFormValues): Promise<AxiosResponse<ConfirmNewResponse>> => {
  const formData = new FormData()

  formData.append('fullName', values.fullName)
  formData.append('password', values.password)
  formData.append('token', values.token!)
  if (values.thumbImage) {
    const thumbFile = base64Utils.convertDataURIToBinary(values.thumbImage)
    formData.append('thumbImageFile', thumbFile)
  }
  const client = Axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  })
  client.defaults.transformRequest = []
  return client.post(BASE_URL + ENDPOINTS.AUTH_CONFIRM_NEW, formData, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  })
}

const confirm = (token: string): Promise<AxiosResponse<ConfirmNewResponse>> => {
  return api.post(BASE_URL + ENDPOINTS.AUTH_CONFIRM, { token })
}

const signUp = (values: SignUp): Promise<AxiosResponse<void>> => {
  return api.post(ENDPOINTS.SIGN_UP, values)
}

const recoveryPassword = (email: string): Promise<AxiosResponse<void>> => {
  return api.post(ENDPOINTS.RECOVERY_PASSWORD, email)
}

const resetPassword = (token: string, newPassword: string): Promise<AxiosResponse<void>> => {
  return api.post(ENDPOINTS.RESET_PASSWORD, { token, newPassword })
}

export default { login,accessAccount, isAuthenticated, signUp, confirmNew, confirm, recoveryPassword, resetPassword, signUpGoogle,loginGoogle }
