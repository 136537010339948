import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { PlugFieldSchema, PlugType } from '../../../../../../schemas/PlugSchema'
import movideskPlugService, {
  CreateMovideskPlugRequest
} from '../../../../../../services/plugService/movideskPlugService'
import { FlowComponentBaseProps } from '../../../types'
import FieldsGrid from '../../FieldsGrid'
import FilterView from '../../FilterView'
import MovideskTicketsPlugForm, { MovideskTicketsPlugFormValues } from './MovideskTicketsPlugForm'
import MovideskPersonsCustomFieldList from './components/MovideskPersonsCustomFieldList'
import MovideskTicketsCustomFieldList from './components/MovideskTicketsCustomFieldList'
import { movideskFields } from './movideskFields'

const MovideskTicketsFlow: React.FC<PropsWithChildren<FlowComponentBaseProps>> = ({ plug, onBackClick, onClose }) => {
  const [index, setIndex] = useState(0)
  const [plugFormValues, setPlugFormValues] = useState<MovideskTicketsPlugFormValues | null>(null)
  const [selectedTicketsCustomFieldsLocal, setSelectedTicketsCustomFields] = useState<PlugFieldSchema[]>([])
  const [selectedPersonsCustomFieldsLocal, setSelectedPersonsCustomFields] = useState<PlugFieldSchema[]>([])
  const [fields, setFields] = useState<PlugFieldSchema[]>([])
  const [filterValue, setFilterValue] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const goNextPage = () => {
    swiper?.slideNext()
  }

  const goBackPage = () => {
    swiper?.slidePrev()
  }

  const handleSubmitPlugForm = (values: MovideskTicketsPlugFormValues) => {
    setPlugFormValues(values)
    goNextPage()
  }

  const handleGoPersonCustomFields = async (selectedCustomFields: PlugFieldSchema[]) => {
    setSelectedTicketsCustomFields(selectedCustomFields)
    goNextPage()
  }
  const handleGoFields = async (selectedCustomFields: PlugFieldSchema[]) => {
    setSelectedPersonsCustomFields(selectedCustomFields)
    goNextPage()
    const parsedFields = movideskFields.map<PlugFieldSchema>((field) => ({
      name: field.name,
      type: field.type,
      label: t(`movidesk.field.${field.name}`)
    }))
    setFields([...parsedFields, ...selectedTicketsCustomFieldsLocal, ...selectedCustomFields])
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    if (changedFields) {
      const prevFields = [...fields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setFields(prevFields)
    }
  }

  const handleClose = () => {
    onClose('Exited')
  }

  const handleCreatePlug = async () => {
    const newPlug: CreateMovideskPlugRequest = {
      name: plugFormValues!.plugName,
      connectionId: plugFormValues!.connection.id,
      filter: filterValue,
      fields: fields,
      ticketsCustomFields: selectedTicketsCustomFieldsLocal,
      personCustomFields: selectedPersonsCustomFieldsLocal,
      startFetchDate: plugFormValues!.startFetchDate.toDate()
    }
    try {
      await movideskPlugService.createMovideskPlug(newPlug)
      onClose('EndFlow')
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createPlug', { name: newPlug.name }), {
        variant: 'success'
      })
      dataLayer.push({
        event: 'on_plug_created',
        plugType: PlugType.Movidesk
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        if (firstError?.code === 'Name already exists') {
          enqueueSnackbar('Já existe um plug com esse nome', { variant: 'error' })
          return
        }
        enqueueSnackbar(`${firstError?.code} : ${firstError?.description}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  return (
    <Box color="white">
      <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal">
        <SwiperSlide>
          <MovideskTicketsPlugForm
            navigationProps={{ onNextClick: goNextPage, onClose: handleClose, onBackClick: onBackClick }}
            onSubmit={handleSubmitPlugForm}
          />
        </SwiperSlide>
        <SwiperSlide>
          {plugFormValues && (
            <MovideskTicketsCustomFieldList
              connectionId={plugFormValues.connection.id}
              navigationProps={{
                onBackClick: goBackPage,
                onNextClick: handleGoPersonCustomFields,
                onClose: handleClose
              }}
            />
          )}
        </SwiperSlide>
        <SwiperSlide>
          {plugFormValues && (
            <MovideskPersonsCustomFieldList
              connectionId={plugFormValues.connection.id}
              navigationProps={{
                onBackClick: goBackPage,
                onNextClick: handleGoFields,
                onClose: handleClose
              }}
            />
          )}
        </SwiperSlide>
        <SwiperSlide>
          <FieldsGrid
            fields={fields}
            onChange={handleFieldChanges}
            navigationProps={{
              onBackClick: goBackPage,
              onNextClick: goNextPage,
              onClose: handleClose
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Box
            color="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <FilterView
              fields={fields}
              value={filterValue}
              onChange={(val) => setFilterValue(val)}
              navigationProps={{
                onBackClick: goBackPage,
                onClose: handleClose,
                onNextClick: handleCreatePlug,
                nextDisabled: true
              }}
            />
          </Box>
        </SwiperSlide>
      </Swiper>
    </Box>
  )
}

export default MovideskTicketsFlow
