import { ListItemText, ListItemTextProps, TypographyTypeMap } from '@mui/material'
import { OverrideProps } from '@mui/material/OverridableComponent'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

const defaultPrimaryTypographyProps:
  | OverrideProps<
      TypographyTypeMap<
        {
          component?: 'span' | undefined
        },
        'span'
      >,
      'span'
    >
  | undefined = {
  display: 'block',
  noWrap: false
}

const useStyles = makeStyles({
  root: {
    wordBreak: 'break-word'
  }
})

const ListItemTextBase: React.FC<PropsWithChildren<ListItemTextProps>> = (props) => {
  const classes = useStyles()
  const { primaryTypographyProps, className, ...others } = props
  return (
    <ListItemText
      className={clsx(classes.root, className)}
      primaryTypographyProps={{ ...defaultPrimaryTypographyProps, ...primaryTypographyProps }}
      {...others}
    />
  )
}

export default ListItemTextBase
