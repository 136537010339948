
import { dxFilterBuilderField } from 'devextreme/ui/filter_builder'
import { SelectBoxFilterFieldProps } from './SelectBoxFilterField'

export interface CustomOperationPropsBase {
  name: string
  caption: string
  icon: string
  hasValue?: boolean
  editorComponent?: React.FunctionComponent<SelectBoxFilterFieldProps>
  calculateFilterExpression(filterValue: ConditionValue, field: dxFilterBuilderField): string
}

export interface FilterBuilderCondition {
  [0]: string
  [1]: string
  [2]: ConditionValue
}
export enum ConditionValueType {
  'values' = 'values',
  'parameters' = 'parameters'
}

export type FilterBuilderValue = [FilterBuilderCondition | FilterBuilderValue | 'and' | 'or' | 'string']

export interface ConditionValue {
  id?: string
  value: string
  type: ConditionValueType
}
