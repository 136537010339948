import { Autocomplete, List, ListItem, ListItemProps, ListItemText } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '../../components/TextField'
import { AnalyzeType, ShareType } from '../../schemas/AnalyzeSchema'

interface NestedListProps extends Omit<ListItemProps, 'button' | 'component' | 'onChange'> {
  items: any[]
  itemsKey: string
  itemsLabelKey: string
  selectedItems?: { [key in string | number]: any }[]
  fallbackItemLabelKey: string
  onChange?(userId: string, shareType: ShareType): void
  itemType?: AnalyzeType
}

const useStyles = makeStyles((theme) => ({
  nestedList: {
    paddingLeft: theme.spacing(4)
  },
  selectBoxTextField: {
    [theme.breakpoints.down('md')]: {
      minWidth: 120
    },
    minWidth: 260
  }
}))

const NestedList: React.FC<PropsWithChildren<NestedListProps>> = ({
  items,
  itemsLabelKey,
  onChange,
  fallbackItemLabelKey,
  itemType
}) => {
  const { t } = useTranslation()

  const analysisSelectOptions: { key: string; value: ShareType }[] = [
    {
      key: t('pageHome.body.modalShare.comboBox.noShared'),
      value: ShareType.NoShare
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readOnly'),
      value: ShareType.Analysis_RO
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readWrite'),
      value: ShareType.Analysis_RW
    },
    {
      key: t('pageHome.body.modalShare.comboBox.copy'),
      value: ShareType.Copy
    }
  ]
  const collectionSelectOptions: { key: string; value: ShareType }[] = [
    {
      key: t('pageHome.body.modalShare.comboBox.noShared'),
      value: ShareType.NoShare
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readOnly'),
      value: ShareType.Collection_RO
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readWrite'),
      value: ShareType.Collection_RW
    }
  ]

  const getOptions = (itemType?: AnalyzeType) => {
    if (itemType === AnalyzeType.COLLECTION) return collectionSelectOptions

    return analysisSelectOptions
  }

  const classes = useStyles()
  const options = getOptions(itemType)
  return (
    <List component="div">
      {items
        .sortBy((x) => x.fullName)
        .map((ni) => (
          <ListItem className={classes.nestedList}>
            <ListItemText
              primary={ni[itemsLabelKey] || ni[fallbackItemLabelKey]}
              sx={{ wordBreak: 'break-all' }}
              primaryTypographyProps={{
                textOverflow: 'ellipsis'
              }}
            />
            <Autocomplete
              onKeyPress={(e) => e.preventDefault()}
              disableClearable
              value={ni.claimType || options[0].value}
              onChange={(e, value) => onChange?.(ni.id, value.value)}
              options={options}
              isOptionEqualToValue={(opt, val) => opt.value === ni.claimType}
              getOptionLabel={(opt) => options.find((x) => x.value === (opt?.value || opt))?.key || ''}
              renderInput={(props) => <TextField {...props} className={classes.selectBoxTextField} />}
            />
          </ListItem>
        ))}
    </List>
  )
}

export default NestedList
