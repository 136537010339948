import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles'

//import createBreakpoints from '@mui/material/styles/createBreakpoints'
// #39e991
// @ts-ignore
let theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'light',
      text: {
        primary: '#707070',
        secondary: '#27a699'
      },
      background: {
        paper: '#f6f6f6',
        light: {
          default: '#f6f6f6',
          accent: '#f2f2f2',
          highAccent: '#e9e9e9',
          contrast: '#3c3f48'
        },
        dark: {
          default: '#f6f6f6',
          accent: '#f2f2f2',
          highAccent: '#e9e9e9',
          contrast: '#3c3f48'
        }
      },
      primary: {
        main: '#27a699',
        light: '#27a699',
        contrastText: '#FFF'
      },
      secondary: {
        main: '#5c81e5'
      },
      success: {
        main: '#3ab93a'
      },
      warning: {
        main: '#ff9800'
      },
      error: {
        main: '#f44336'
      }
    },
    typography: {
      h1: {
        fontSize: '3rem',
        color: '#707070'
      },
      h2: {
        fontSize: '2rem',
        color: '#707070'
      },
      h3: {
        fontSize: '1.5rem',
        color: '#707070'
      },
      subtitle1: {
        color: '#707070'
      }
    }
  })
)

theme = responsiveFontSizes(theme)

export default theme
