import { LocationOn, PermPhoneMsg, Update } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button as MaterialButton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import ProgressInfo from '../../components/ProgressInfo'
import useAuth from '../../hooks/useAuth'
import { accountService, planService } from '../../services'
import billingService from '../../services/billingService'
import { getSupportFriendlyText, getUpdateFrequencyFriendlyText } from '../../utils/enumUtils'
import { AccountPlanInfo } from './General'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  companyNameInput: {
    fontSize: '1.5rem'
  },
  editContainer: {
    flex: '1 0 0'
  },
  planContainer: {
    padding: theme.spacing(2),
    flex: '3 0 1',
    display: 'flex',
    justifyContent: 'space-between'
  },
  progressInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  progressInfo: {
    flex: '1 1 auto',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  iconLabelInfo: {
    flex: '1 1 0',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center'
  },
  iconInfo: {
    margin: theme.spacing(1)
  }
}))

const PlanInfo: React.FC = () => {
  const classes = useStyles()

  const [accountPlanInfo, setAccountPlanInfo] = useState<AccountPlanInfo>({
    planInfo: {},
    accountInfo: {}
  } as AccountPlanInfo)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [loadingInfos, setLoadingInfos] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()
  const planInfo = accountPlanInfo.planInfo
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTextFieldValue, setDialogTextFieldValue] = useState('')
  const [notEqualExcludeText, setNotEqualExcludeText] = useState(false)

  const isFreePlan = planInfo.id === 999
  const isNotLegacy = !!accountPlanInfo?.planInfo?.id && accountPlanInfo.planInfo.id !== 2

  const loadData = useCallback(async () => {
    setLoadingInfos(true)
    try {
      const accountPromise = accountService.getCurrentUsedCapabilities()
      const planPromise = planService.get(auth.getAccountInfo()!.planId)

      const [accountResponse, planResponse] = await Promise.all([accountPromise, planPromise])
      setAccountPlanInfo({
        accountInfo: accountResponse.data,
        planInfo: planResponse.data
      })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoadingInfos(false)
  }, [auth, enqueueSnackbar, t])

  useEffect(() => {
    loadData()
  }, [loadData])

  const goToCustomerPortal = async () => {
    try {
      const { data } = await billingService.createCustomerPortalSession()
      window.location.href = data.url
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }

  const handleChangePlan = async () => {
    if (isFreePlan) {
      navigate('/pricing')
      return
    }

    goToCustomerPortal()
  }

  const handleDeleteAccountBtnClick = async () => {
    if (isFreePlan) {
      setDialogOpen(true)
      return
    }
    goToCustomerPortal()
  }

  const handleCloseDialog = () => {
    setDialogTextFieldValue('')
    setNotEqualExcludeText(false)
    setDialogOpen(false)
  }

  const DELETE_CONFIRMATION_TEXT = t('pageAccount.tabGeneral.label.deleteConfirmationText')
  const handleDeleteFreeAccount = async () => {
    if (dialogTextFieldValue.toLowerCase() !== DELETE_CONFIRMATION_TEXT.toLocaleLowerCase()) {
      setNotEqualExcludeText(true)
      return
    }
    try {
      await accountService.deleteFreeAccount(auth.getAccountInfo()!.accountId)
      handleCloseDialog()
      auth.signOut()
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }

  return (
    <>
      <Box className={classes.planContainer}>
        <Box>
          <Typography variant="h2" component="h3">
            {accountPlanInfo.planInfo.name}
          </Typography>
        </Box>
        <Stack spacing={2} direction="row">
          {isNotLegacy && (
            <Button
              onClick={() => handleChangePlan()}
              label={t('pageAccount.tabGeneral.label.changePlan')}
              color="primary"
              variant="contained"
            />
          )}
        </Stack>
      </Box>
      <Divider />
      <Box className={classes.progressInfoContainer}>
        <Box display="flex" flexWrap="wrap" flex="1 1 0" width="95%">
          <ProgressInfo
            className={classes.progressInfo}
            title={t('pageAccount.tabGeneral.label.plugs')!}
            value={accountPlanInfo.accountInfo.plugs}
            maxValue={accountPlanInfo.planInfo.maxPlugs}
            loading={loadingInfos}
          />
          <ProgressInfo
            className={classes.progressInfo}
            title={t('pageAccount.tabGeneral.label.activeUsers')!}
            value={accountPlanInfo.accountInfo.activeUsers}
            maxValue={accountPlanInfo.planInfo.maxUsers}
            loading={loadingInfos}
          />
          <ProgressInfo
            className={classes.progressInfo}
            title={t('pageAccount.tabGeneral.label.analysis')!}
            value={accountPlanInfo.accountInfo.analysis}
            maxValue={accountPlanInfo.planInfo.maxAnalysis}
            loading={loadingInfos}
          />
          <ProgressInfo
            className={classes.progressInfo}
            title={t('pageAccount.tabGeneral.label.databaseSize')!}
            value={accountPlanInfo.accountInfo.databaseSize}
            maxValue={accountPlanInfo.planInfo.maxDatabaseSize}
            sufix="MB"
            loading={loadingInfos}
          />
        </Box>
        <Box flex="1 1 0">
          <Box className={classes.iconLabelInfo}>
            <Update fontSize="large" className={classes.iconInfo} />
            <Typography variant="h5" component="span" color="textPrimary">
              {getUpdateFrequencyFriendlyText(accountPlanInfo.planInfo.updateFrequency)}
            </Typography>
          </Box>
          <Box className={classes.iconLabelInfo}>
            <PermPhoneMsg fontSize="large" className={classes.iconInfo} />
            <Typography variant="h5" component="span" color="textPrimary">
              {getSupportFriendlyText(accountPlanInfo.planInfo.supportFeature)}
            </Typography>
          </Box>
          {accountPlanInfo.planInfo.useGeoData && (
            <Box className={classes.iconLabelInfo}>
              <LocationOn fontSize="large" className={classes.iconInfo} />
              <Typography variant="h5" component="span" color="textPrimary">
                {t('pageAccount.tabGeneral.label.geographicData')}
              </Typography>
            </Box>
          )}
        </Box>
        {isNotLegacy && (
          <MaterialButton
            color="error"
            variant="text"
            sx={{
              alignSelf: 'flex-end',
              margin: 2
            }}
            onClick={handleDeleteAccountBtnClick}
          >
            {t('pageAccount.tabGeneral.label.deleteAccountBtn')}
          </MaterialButton>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle color="error">{t('pageAccount.tabGeneral.label.deleteAccountBtn')}</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            {t('pages.accounts.confirmationExcludeDialog.info')} <br />
            {t('pageAccount.tabGeneral.label.deleteConfirmationText')}
          </DialogContentText>
          <TextField
            id="delete-account-confirmation"
            autoFocus
            margin="dense"
            fullWidth
            variant="standard"
            value={dialogTextFieldValue}
            error={notEqualExcludeText}
            helperText={
              notEqualExcludeText && `Please type "${t('pageAccount.tabGeneral.label.deleteConfirmationText')}"`
            }
            onChange={(e) => setDialogTextFieldValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleCloseDialog}>{t('pageAccount.tabGeneral.label.cancelBtn')}</MaterialButton>
          <MaterialButton color="error" onClick={handleDeleteFreeAccount}>
            {t('pageAccount.tabGeneral.label.confirmBtn')}
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PlanInfo
