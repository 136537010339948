import { Typography, Stack } from '@mui/material'

export const PricingPageFooter: React.FC = () => {
  return (
    <Stack
      direction="row"
      spacing={1}
      padding={1}
      alignItems="center"
      justifyContent="flex-end"
      color="#39e790"
      bgcolor="rgb(42, 44, 50)"
    >
      <Typography fontSize={13}>© Plugger BI 2023</Typography>
    </Stack>
  )
}
