import { Close, Search } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const SearchBox: React.FC<PropsWithChildren<TextFieldProps>> = ({ value, InputProps, onChange, ...rest }) => {
  const [localValue, setLocaValue] = useState(value || '')
  const { t } = useTranslation()

  useEffect(() => {
    setLocaValue(value as any)
  }, [value])

  const handleClearInput = useCallback(() => {
    setLocaValue('')
    onChange?.({
      target: {
        value: ''
      }
    } as any)
  }, [onChange])

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocaValue(e.target.value)
      onChange?.(e)
    },
    [onChange]
  )

  return (
    <TextField
      value={localValue}
      onChange={handleChangeValue}
      variant="standard"
      placeholder={t('navBody.search')!}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClearInput} disabled={!localValue} size="large">
              <Close fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps
      }}
      {...rest}
    />
  )
}

export default SearchBox
