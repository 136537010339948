const API_PATH = '/api/v1'
let HOST_URL = process.env.REACT_APP_USE_LOCAL_NET_IP === 'true' ? 'https://192.168.0.114:5001' : 'https://127.0.0.1:5001'
if (process.env.NODE_ENV !== 'development') {
  HOST_URL = process.env.REACT_APP_SERVICE_APIS_URL || `empty - ${process.env.NODE_ENV}`
}
const BASE_URL = HOST_URL + API_PATH

const ENDPOINTS = {
  GOOGLE_AUTH2_CALLBACK: '/google-auth2-callback',
  SIGN_UP_GOOGLE: '/sign-up-google',
  SIGN_IN_GOOGLE: '/sign-in-google',
  GOOGLE_API_CREATE_SHEETS_PLUG:'/plugs/google-sheets',
  GOOGLE_API_LIST_SPREADSHEETS: '/google-apis/{0}/spreadsheets', // connectionId
  GOOGLE_API_LIST_SHEETS: '/google-apis/{0}/spreadsheets/{1}/sheets', // connection and spreadsheetId
  GOOGLE_API_LIST_SHEETS_COLUMNS: '/google-apis/{0}/spreadsheets/{1}/sheets/{2}/columns', // connection and spreadsheetId and sheetId
  GOOGLE_SERVICES_ADS_CHECK_CUSTOMER_ID: '/google-services/ads/{0}/checkCustomerId',
  GOOGLE_SERVICES_ANALYTICS_PROPERTIES_COMPATIBLE: '/google-services/analytics/{0}/compatible-properties',
  GET_SENDINGS: '/sendings',
  CSV_PLUG:"/plugs/csv",
  GET_SENDING_EMAIL_DETAILS: '/sendings/{0}/details',
  GET_AVAILABLE_PLUGS: '/plugs-availables',
  GET_AVAILABLE_PLUG_FIELDS: '/plugs-availables/{0}/fields',
  GET_AVAILABLE_CONNECTIONS: '/connections-available',
  GET_SENDING_EVENTS: '/sendings/{0}/recipient-events',
  CREATE_SENDING: '/sendings',
  GET_SENDING: '/sendings/{0}',
  POST_SENDING_SEND_PREVIEW: '/sendings/{0}/send/{1}',
  POST_SENDING_SEND: '/sendings/send',
  PATCH_SENDING: '/sendings/{0}',
  DELETE_SENDING: '/sendings/{0}',
  GET_COLLECTIONS: '/collections',
  GET_COLLECTION_ANALYSIS: '/collections/{0}/analysis',
  CREATE_COLLECTION: '/collections',
  GET_COLLECTION: '/collections/{0}',
  UPDATE_COLLECTION: '/collections/{0}',
  DELETE_COLLECTION: '/collections/{0}',
  GET_ANALYZE_WITH_STATE: '/analysis/{0}?includeState=true',
  GET_ANALYZE: '/analysis/{0}',
  GET_ANALYSIS: '/analysis',
  CREATE_ANALYZE: '/analysis',
  UPDATE_ANALYZE: '/analysis/{0}',
  BULK_COPY_ANALYSIS: '/analysis/{0}/copy',
  GET_ANALYZE_SHARED_USERS: '/analysis/{0}/shared',
  SHARE_UNSHARE_ANALYZE: '/analysis/{0}/shared',
  DELETE_ANALYZE: '/analysis/{0}',
  LOGIN: '/login',
  ACCESS_ACCOUNT: '/access-account',
  VALIDATE_AUTHENTICATION: '/validate-authentication',
  PLUGS_GET_ALL: '/plugs',
  PLUGS_GET_BY_NAME: '/plugs/{0}',
  PLUGS_CREATE: '/plugs',
  PLUGS_CREATE_CSV: '/plugs/csv',
  PLUGS_UPDATE: '/plugs/{0}',
  PLUGS_UPDATE_DATA: '/plugs/{0}/update-data',
  PLUGS_DELETE: '/plugs/{0}',
  GET_PLUG_FIELDS: '/plugs/{0}/fields',
  GET_MOVIDESK_TICKETS_CUSTOM_FIELDS: '/plugs/{0}/movidesk/tickets/custom-fields',
  GET_MOVIDESK_PERSONS_CUSTOM_FIELDS: '/plugs/{0}/movidesk/persons/custom-fields',
  GET_PLUG_FIELD_DISTINCT_VALUES: '/plugs/{0}/fields/{1}',
  LOAD_PIVOT_GRID: '/pivot-grid/{0}', // plugId
  EXPORT_PIVOT_GRID_TO_PDF: '/pivot-grid/export/pdf',
  PROFILES_GET_ALL: '/profiles',
  PROFILES_GET_PARAMETERS: '/profiles/{0}/parameters',
  PROFILES_CREATE: '/profiles',
  PROFILES_UPDATE: '/profiles/{0}',
  PROFILES_DELETE: '/profiles/{0}',
  PROFILES_PLUG_DENIES: '/profiles/{0}/plug-denies',
  PROFILES_PLUG_FIELD_DENIES: '/profiles/{0}/plug-field-denies',
  PROFILES_PLUGS_FIELDS: '/profiles/plugs',
  CONNECTIONS_GET_ALL: '/connections',
  CONNECTIONS_GET: '/connection/{0}',
  CONNECTIONS_CREATE: '/connections',
  CONNECTIONS_UPDATE: '/connections/{0}',
  CONNECTIONS_DELETE: '/connections/{0}',
  CONNECTIONS_GET_TABLE_VIEW_NAMES: '/connections/describe/tables-views/{0}',
  CONNECTIONS_DESCRIBE_FIELDS: '/connections/describe/fields',
  TEAMS_GET_ALL: '/teams',
  TEAMS_CREATE: '/teams',
  TEAMS_UPDATE: '/teams/{0}',
  TEAMS_DELETE: '/teams/{0}',
  TEAMS_GET_ASSIGNABLE_USERS: '/teams/{0}/assignable-users', // teamId
  USERS_GET_ALL: '/users',
  USERS_GET: '/users/{0}',
  USERS_GET_PARAMETERS: '/users/{0}/parameters',
  USERS_GET_CURRENT_INFO: '/users/info',
  USERS_INVITE: '/users/invite',
  USERS_INVITE_CONFIRM_NEW: '/confirm-invite-new',
  USERS_INVITE_CONFIRM: '/confirm-invite',
  USERS_UPDATE: '/users/{0}',
  USERS_REACTIVATE: '/users/{0}/reactivate',
  USERS_DELETE: '/users/{0}',
  USERS_ME_ANALYSIS_DELETE: '/users/me/analysis/{0}',
  USERS_ME_COLLECTIONS_DELETE: '/users/me/collections/{0}',
  USERS_VALIDATE_EMAIL: '/users/validate-email',
  USERS_RESEND_INVITE: '/users/invite/resend/{0}',
  USERS_ADD_EXTERNAL_LOGIN: '/users/me/external-logins',
  DASHBOARD_API: '/dashboard',
  ACCOUNTS_GET_USER_ACCOUNTS: '/user-accounts',
  GET_LOGGED_USER_ACCOUNTS: 'users/me/accounts',
  SIGN_UP: '/sign-up',
  ACCOUNTS_CREATE: '/accounts',
  ACCOUNTS_EXTEND_TRIAL: '/my-account/extend-trial',
  ACCOUNTS_UPDATE: '/accounts/{0}',
  ACCOUNTS_GET_CURRENT_INFO: '/accounts/info',
  ACCOUNTS_GET_CURRENT_USED_CAPABILITIES: '/accounts/used-capabilities',
  ACCOUNTS_CHECK_EMAIL_OWN_ACCOUNTS: '/own-accounts/{0}',
  PLANS_GET: '/plans/{0}',
  PLANS_GET_SUMMARY: '/plans/{0}/summary',
  PLANS_LIST: '/plans',
  STATIC_IMAGES: '/static/images/{0}',
  PARAMETERS_GET_ALL: '/parameters',
  PARAMETERS_GET: '/parameters/{0}',
  PARAMETERS_CREATE: '/parameters',
  PARAMETERS_DELETE: '/parameters/{0}',
  PARAMETERS_UPDATE: '/parameters/{0}',
  PARAMETERS_CHECK_NAME_AVAILABLE: '/parameters/name-available/{0}',
  AUTH_CONFIRM_NEW: '/confirm-new',
  AUTH_CONFIRM: '/confirm',
  LOAD_GEOANALYSIS_DATA: '/geo-analysis',
  RECOVERY_PASSWORD: '/recovery-password',
  RESET_PASSWORD: '/reset-password',
  PUBLIC_IMAGES: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o',
  BILLING_CREATE_CHECKOUT_SESSION: '/billing/checkout-session',
  BILLING_CREATE_CUSTOMER_PORTAL_SESSION: '/billing/create-customer-portal-session',
  BILLING_SUBSCRIPTION: '/billing/subscription',
  CSV_DETECT_COLUMNS: '/csv/detect-columns'
}

export { API_PATH, BASE_URL, ENDPOINTS, HOST_URL }

