import Box, { BoxProps } from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { ENDPOINTS } from '../../constants/apiEndpoints'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '290px',
    maxHeight: '480px',
    height: 'auto',
    width: '100%',
    display: 'block'
  },
  img: {
    width: '100%',
    padding: '12px',
    height: 'auto'
  }
}))

const PluggerLogoTransp: React.FC<PropsWithChildren<BoxProps & { src?: string }>> = ({ className, src, ...props }) => {
  const classes = useStyles()
  return (
    <Box className={clsx(className, classes.root)} {...props}>
      <img
        className={classes.img}
        src={src || ENDPOINTS.PUBLIC_IMAGES + '/logo escuro horiz.svg'}
        alt="Logo Plugger BI"
      />
    </Box>
  )
}

export default PluggerLogoTransp
