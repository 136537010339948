import { Visibility, VisibilityOff } from '@mui/icons-material'
import React from 'react'
import i18n from '../../../locales/i18next'
import { getStringedHtmlElement } from '../../../utils/renderUtils'
import { RemoteDataSource } from '../dataSource'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent } from '../types'

const visibilityIconElement = getStringedHtmlElement(<Visibility />)
const visibilityOffIconElement = getStringedHtmlElement(<VisibilityOff />)

class HighestLevelSummariesVisibilityExtension implements ComponentExtension {
  name = 'HighLevelGrandTotalsVisibilityExtension'
  icon = 'verticalalignbottom'
  text = i18n.t('pivotGrid.extensions.highestSummary.text')
  items: ComponentExtensionOption[]

  constructor(component: PivotGridComponent) {
    const showColumnGrandTotals = component.option('showColumnGrandTotals')
    const showColumnTotals = component.option('showColumnTotals')

    const showRowGrandTotals = component.option('showRowGrandTotals')
    const showRowTotals = component.option('showRowTotals')
    const subOptions = [
      {
        text: i18n.t('pivotGrid.extensions.highestSummary.columnSummaries'),
        icon: 'columnfield',
        items: [
          {
            text: i18n.t('pivotGrid.extensions.highestSummary.grandTotals'),
            items: [
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.show'),
                icon: visibilityIconElement,
                onItemClick: () => toggleColumnGrandTotalVisibility(component, true),
                selected: showColumnGrandTotals,
                disabled: showColumnGrandTotals
              },
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.hide'),
                icon: visibilityOffIconElement,
                onItemClick: () => toggleColumnGrandTotalVisibility(component, false),
                selected: !showColumnGrandTotals,
                disabled: !showColumnGrandTotals
              }
            ]
          },
          {
            text: i18n.t('pivotGrid.extensions.highestSummary.totals'),
            items: [
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.show'),
                icon: visibilityIconElement,
                onItemClick: () => toggleColumnTotalVisibility(component, true),
                selected: showColumnTotals,
                disabled: showColumnTotals
              },
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.hide'),
                icon: visibilityOffIconElement,
                onItemClick: () => toggleColumnTotalVisibility(component, false),
                selected: !showColumnTotals,
                disabled: !showColumnTotals
              }
            ]
          }
        ]
      },
      {
        text: i18n.t('pivotGrid.extensions.highestSummary.rowSummaries'),
        icon: 'rowfield',
        items: [
          {
            text: i18n.t('pivotGrid.extensions.highestSummary.grandTotals'),
            items: [
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.show'),
                icon: visibilityIconElement,
                onItemClick: () => toggleRowGrandTotalVisibility(component, true),
                selected: showRowGrandTotals,
                disabled: showRowGrandTotals
              },
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.hide'),
                icon: visibilityOffIconElement,
                onItemClick: () => toggleRowGrandTotalVisibility(component, false),
                selected: !showRowGrandTotals,
                disabled: !showRowGrandTotals
              }
            ]
          },
          {
            text: i18n.t('pivotGrid.extensions.highestSummary.totals'),
            items: [
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.show'),
                icon: visibilityIconElement,
                onItemClick: () => toggleRowTotalVisibility(component, true),
                selected: showRowTotals,
                disabled: showRowTotals
              },
              {
                text: i18n.t('pivotGrid.extensions.highestSummary.hide'),
                icon: visibilityOffIconElement,
                onItemClick: () => toggleRowTotalVisibility(component, false),
                selected: !showRowTotals,
                disabled: !showRowTotals
              }
            ]
          }
        ]
      }
    ]

    this.items = subOptions
  }
}

const toggleRowGrandTotalVisibility = (component: PivotGridComponent, show: boolean) => {
  ;(component.getDataSource() as RemoteDataSource).setConfigOption('showRowGrandTotals', show)
  component.option('showRowGrandTotals', show)
  component.repaint()
}
const toggleColumnGrandTotalVisibility = (component: PivotGridComponent, show: boolean) => {
  ;(component.getDataSource() as RemoteDataSource).setConfigOption('showColumnGrandTotals', show)
  component.option('showColumnGrandTotals', show)
  component.repaint()
}
const toggleRowTotalVisibility = (component: PivotGridComponent, show: boolean) => {
  ;(component.getDataSource() as RemoteDataSource).setConfigOption('showRowTotals', show)
  component.option('showRowTotals', show)
  component.repaint()
}
const toggleColumnTotalVisibility = (component: PivotGridComponent, show: boolean) => {
  ;(component.getDataSource() as RemoteDataSource).setConfigOption('showColumnTotals', show)
  component.option('showColumnTotals', show)
  component.repaint()
}

export default HighestLevelSummariesVisibilityExtension
