import { Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import NavMenu from '../../components/NavMenu'
import { useMovideskChat } from '../../hooks/useMovideskChat'
import useWindowSize from '../../hooks/useWindowSize'

const xLargerWidth = 340
const mdWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: ({ viewportHeight }: { viewportHeight: number; navWidth: number }) => viewportHeight
  },
  content: {
    width: ({ navWidth }: { navWidth: number }) => `calc(100% - ${navWidth}px)`,
    flexGrow: 1
  }
}))

const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
  const navWidth = isXl ? xLargerWidth : mdWidth
  const [, windowHeight] = useWindowSize()
  const classes = useStyles({ navWidth, viewportHeight: windowHeight })
  const { show } = useMovideskChat()

  useEffect(() => {
    show()
  }, [show])

  return (
    <>
      <div className={classes.root}>
        <NavMenu width={navWidth} />
        <main className={classes.content}>{<Outlet />}</main>
      </div>
    </>
  )
}

export default AppLayout
