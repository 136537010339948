import { Alert, AlertColor } from '@mui/material'
import { SnackbarProvider, VariantType } from 'notistack'
import React, { useRef, PropsWithChildren } from 'react'

const variantToSeverity = (variant?: VariantType): AlertColor => {
  if (!variant || variant === 'default') return 'success'
  return variant
}

const ConfiguredSnackBarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const snackBarRef = useRef<SnackbarProvider>(null)

  const dismiss = (key?: string | number) => {
    snackBarRef.current?.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ref={snackBarRef}
      domRoot={document.getElementById('notification')!}
      content={(key, message) => (
        <Alert
          variant="filled"
          key={key}
          severity={variantToSeverity(
            ((snackBarRef.current?.state as any).snacks as any[]).find((x: any) => x.key === key).variant
          )}
          onClose={() => dismiss(key)}
        >
          {message}
        </Alert>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

export default ConfiguredSnackBarProvider
