import { ContentState, EditorState } from 'draft-js'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../components/Dialog'
import ExpressionBuilder from '../../../components/ExpressionBuilder'
import TextField from '../../../components/TextField'
import i18n from '../../../locales/i18next'
import { defaultFieldSorting } from '../dataSource'
import {
  ComponentExtensionOption,
  ComponentWithDialogExtension,
  DialogExtensionComponentProps,
  PivotGridField
} from '../types'

export const id = 'CustomFieldExtension'

class CustomFieldExtension implements ComponentWithDialogExtension {
  name = id
  icon = 'rowproperties'
  text = i18n.t('pivotGrid.extensions.customField.text')
  onItemClick: () => void
  showDialog: ((id: string) => void) | null
  static discriminator: string

  constructor(showDialogImp: (id: string) => void, eraseField: () => void) {
    this.showDialog = showDialogImp
    this.onItemClick = () => {
      eraseField()
      this.showDialog!(id)
    }
  }
  items?: ComponentExtensionOption[] | undefined
}

export const CustomFieldDialog: React.FC<PropsWithChildren<DialogExtensionComponentProps>> = ({
  open,
  onClose,
  dataSource,
  field
}) => {
  const [expression, setExpression] = useState<EditorState>()
  const [fieldName, setFieldName] = useState<string>(field?.caption || '')
  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      setFieldName(field?.caption || '')
      const defaultState = field?.customFieldExpression
        ? EditorState.createWithContent(ContentState.createFromText(field.customFieldExpression))
        : EditorState.createEmpty()
      setExpression(defaultState)
    }
  }, [field, open])

  const fields = dataSource?.fields() || []

  const handleCreateCustomField = () => {
    const customField: PivotGridField = {
      allowExpandAll: true,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      caption: fieldName,
      dataField: fieldName, // hack for use function to calculate value field used here not interest but is necessary exists in datasource
      expanded: false,
      filterType: 'exclude',
      defaultFormat: '',
      renamed: true,
      plugType: 'NUMERIC',
      summaryType: 'sum',
      canBeRemoved: true,
      dataType: 'number',
      headerFilter: {
        allowSearch: true
      },
      format: {
        type: 'fixedPoint',
        precision: 2
      },
      // showTotals: true,
      // showGrandTotals: true,
      // wordWrapEnabled: true,
      width: 50,
      sortingMethod: defaultFieldSorting,
      customFieldExpression: expression?.getCurrentContent().getPlainText()
    }
    // customField.calculateSummaryValue = (e: any) =>
    //   prepareCustomFieldFormulaSummaryCalcullation(customField.customFormula!)
    const sortedAreaFields = dataSource?.getAreaFields('data', false).sortBy((x) => x.areaIndex)
    customField.areaIndex = sortedAreaFields ? sortedAreaFields[sortedAreaFields.length - 1].areaIndex! + 1 : 0
    customField.area = 'data'
    const currentFields = dataSource?.fields()
    currentFields?.push(customField)
    dataSource?.fields(currentFields!)
    dataSource?.load()
    onClose()
  }

  const handleUpdateCustomField = () => {
    field!.customFieldExpression = expression?.getCurrentContent().getPlainText()
    field!.caption = fieldName
    const currentFields = dataSource?.fields()
    const currentFieldIndex = currentFields!.findIndex((x) => (x as PivotGridField).index === field?.index)
    currentFields!.splice(currentFieldIndex, 1, field!)
    dataSource?.fields(currentFields!)
    dataSource?.load()
    onClose()
  }
  const handleChangeFieldName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldName(e.target.value)
  }

  const handleChangeExpression = (editorState: EditorState) => {
    setExpression(editorState)
  }

  return (
    <Dialog
      onClose={onClose}
      removeTitle={false}
      open={open}
      title={t('pivotGrid.menuSettings.modalCustomField.Title')}
      description={t('pivotGrid.menuSettings.modalCustomField.subTitle')}
    >
      <DialogContent>
        <TextField
          style={{ marginTop: '6px' }}
          fullWidth={false}
          label={t('pivotGrid.menuSettings.modalCustomField.label')}
          onChange={handleChangeFieldName}
          value={fieldName}
        />
        <ExpressionBuilder value={expression} onChange={handleChangeExpression} fields={fields} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={field ? handleUpdateCustomField : handleCreateCustomField}
          label={t('pivotGrid.menuSettings.modalCustomField.btnApply')}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  )
}

CustomFieldExtension.discriminator = 'DialogExtension'

export default CustomFieldExtension
