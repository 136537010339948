import { ChangeSet } from '@devexpress/dx-react-grid'
import { SettingsInputAntenna } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Button from '../../../../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../../../../components/Dialog'
import FilterBuilder from '../../../../../../components/FilterBuilder'
import WarningContainer from '../../../../../../components/WarningContainer'
import { PlugFieldSchema } from '../../../../../../schemas/PlugSchema'
import { plugService } from '../../../../../../services'
import {
  GoogleSheetsPlug,
  getGoogleSheetPlug,
  getSheetColumns,
  getSheets,
  listSpreadSheets,
  updateGoogleSheetsPlug
} from '../../../../../../services/google/googleSpreadSheetsService'
import PlugFieldsGrid from '../../../../PlugFieldsGrid'
import { RelatedAnalysisDependecy } from '../../Common/UpdateDialog'

type UpdatePlugDialogPropsBase = {
  plugId?: number
  open?: boolean
  onClose?: (reason: 'Cancelled' | 'Edited') => void
}

const GoogleSheetsUpdateDialog: React.FC<PropsWithChildren<UpdatePlugDialogPropsBase>> = ({
  plugId,
  open,
  onClose
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [tabIndex, setTabIndex] = useState(0)
  const [deleteErrorBag, setDeleteErrorBag] = useState<{
    message: string
    dependents: RelatedAnalysisDependecy[]
  } | null>(null)
  const dltBtnText = t('pagePlugs.modalEdit.buttonDelete')
  const [deleteButtonText, setDeleteButtonText] = useState(dltBtnText)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingFields, setLoadingFields] = useState(false)
  const [isLoadingPlug, setIsLoadingPlug] = useState(true)
  const [isLoadingSpreadsheet, setIsLoadingSpreadsheet] = useState(false)
  const [isLoadingSheet, setIsLoadingSheet] = useState(false)
  const [spreadsheet, setSpreadsheet] = useState<{ id: string; name: string } | null>(null)
  const [sheet, setSheet] = useState<{ id: number; name: string } | null>(null)
  const fieldsTabDirty = useRef(false)
  const filterDirty = useRef(false)
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)
  const [storedFilterValue, setStoredFilterValue] = useState<any>(null)

  const { setError, setValue, control, getValues, trigger, watch, getFieldState } = useForm<GoogleSheetsPlug>({
    values: {
      id: 0,
      name: '',
      sheetId: 0,
      spreadsheetId: '',
      filter: '',
      fields: [],
      connection: {
        id: 0,
        name: ''
      }
    }
  })

  useEffect(() => {
    async function loadPlug() {
      if (!plugId) {
        return
      }
      setIsLoadingPlug(true)
      setIsLoadingSpreadsheet(true)
      setIsLoadingSheet(true)
      try {
        const { data: plugResponse } = await getGoogleSheetPlug(plugId)
        setValue('id', plugResponse.id)
        setValue('name', plugResponse.name)
        setValue('spreadsheetId', plugResponse.spreadsheetId)
        setValue('sheetId', plugResponse.sheetId)
        setValue('connection', plugResponse.connection)
        setStoredFilterValue(plugResponse.filter)
        setIsLoadingPlug(false)

        const { data: spreadsheetsResponse } = await listSpreadSheets(plugResponse.connection.id)
        const selectedSpreadsheet = spreadsheetsResponse.find((x) => x.id === plugResponse.spreadsheetId)
        setIsLoadingSpreadsheet(false)

        if (!selectedSpreadsheet) {
          setError('spreadsheetId', { message: 'Planilha não encontrada' })
          return
        }
        setSpreadsheet(selectedSpreadsheet)

        const { data: sheetsResponse } = await getSheets(plugResponse.connection.id, plugResponse.spreadsheetId)
        const selectedSheet = sheetsResponse.find((x) => x.id === plugResponse.sheetId)
        setIsLoadingSheet(false)
        if (!selectedSheet) {
          setError('sheetId', { message: 'Folha não encontrada' })
          return
        }
        setSheet(selectedSheet)
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' })
        setIsLoadingPlug(false)
        setIsLoadingSheet(false)
        setIsLoadingSpreadsheet(false)
      }
    }

    loadPlug()
  }, [enqueueSnackbar, plugId, setError, setValue])

  const resetState = () => {
    setTabIndex(0)
    setDeleteErrorBag(null)
    setDeleteButtonText(dltBtnText)
    setLoadingDelete(false)
    setLoadingUpdate(false)
  }

  const handleOpenAccessFieldsTab = () => {
    const { connection, sheetId, spreadsheetId } = getValues()
    if (!connection.id || !spreadsheetId) return
    const loadFields = async () => {
      if (plugId) {
        try {
          setLoadingFields(true)
          const { data: plugFieldsResponse } = await plugService.getFields(plugId, false)
          const { data: sheetColumns } = await getSheetColumns(connection.id, spreadsheetId, sheetId)
          const mergedFields: PlugFieldSchema[] = []
          for (const sheetColumn of sheetColumns) {
            const plugField = plugFieldsResponse.find((x) => x.name === sheetColumn.name)
            mergedFields.push({
              name: sheetColumn.name,
              type: sheetColumn.type,
              label: plugField?.label || sheetColumn.name
            })
          }
          setValue('fields', mergedFields)
        } catch (err: any) {
          enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.loadingFields'), { variant: 'error' })
        }
        setLoadingFields(false)
      }
    }

    return loadFields()
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    const plugFields = watch('fields')
    if (changedFields) {
      const prevFields = [...plugFields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setValue('fields', prevFields)
    }
  }

  const handleEnsureDeleteConfirmed = () => {
    if (deleteButtonText === t('pagePlugs.modalEdit.buttonDelete')) {
      setDeleteButtonText(t('pagePlugs.modalEdit.buttonConfirmDelete')!)
      return false
    }
    return true
  }

  const handleDeletePlug = async () => {
    if (!handleEnsureDeleteConfirmed()) return
    try {
      setLoadingDelete(true)
      await plugService._delete(plugId!)
      onClose?.('Edited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugDelete', { name: watch('name') }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      if (axios.isAxiosError(err)) {
        const plugDeleteError = err.response?.data as
          | {
              errors: Error[]
              dependents: RelatedAnalysisDependecy[]
            }
          | undefined

        const message = plugDeleteError?.errors[0].message || t('pagePlugs.modalEdit.toast.error.plugDelete')!

        setDeleteErrorBag({
          message: message,
          dependents: plugDeleteError?.dependents || []
        })
      } else {
        enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugDelete'), { variant: 'error' })
      }
    }
    setLoadingDelete(false)
  }
  async function handleUpdatePlug() {
    const isValid = await trigger()
    if (!isValid) return
    try {
      setLoadingUpdate(true)
      if (!fieldsTabDirty.current) {
        await handleOpenAccessFieldsTab()
        fieldsTabDirty.current = true
      }
      if (!filterDirty.current) {
        setValue('filter', !!storedFilterValue ? JSON.parse(storedFilterValue) : '')
      }
      const { id, name, fields, filter } = getValues()
      await updateGoogleSheetsPlug({
        id,
        name,
        fields,
        filter
      })
      onClose?.('Edited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugUpdate', { name: name }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugUpdate'), { variant: 'error' })
    }
    setLoadingUpdate(false)
  }

  const handleChange = async (tabIndex: number) => {
    setTabIndex(tabIndex)
    swiper?.slideTo(tabIndex)
    if ((tabIndex === 1 || tabIndex === 2) && !fieldsTabDirty.current) {
      await handleOpenAccessFieldsTab()
      fieldsTabDirty.current = true
    }
    if (tabIndex === 2) {
      setValue('filter', !!storedFilterValue ? JSON.parse(storedFilterValue) : '')
      filterDirty.current = true
    }
  }

  return (
    <Dialog
      open={!!open}
      title={t('pagePlugs.modalEdit.title')}
      description={t('pagePlugs.modalEdit.subtitle')}
      onClose={() => onClose?.('Cancelled')}
      fullWidth
      maxWidth="xl"
      TransitionProps={{
        onExited: resetState
      }}
      fullScreen
    >
      <DialogContent dividers>
        <Tabs indicatorColor="primary" centered value={tabIndex} onChange={(e, val) => handleChange(val)}>
          <Tab label={t('pagePlugs.modalEdit.buttonGeneral')} value={0} />
          <Tab label={t('pagePlugs.modalEdit.buttonFields')} value={1} />
          <Tab label={t('pagePlugs.modalEdit.buttonFilter')} value={2} />
        </Tabs>
        <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal" simulateTouch={false}>
          <SwiperSlide>
            <Stack padding={2} spacing={3}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      endAdornment: isLoadingPlug ? (
                        <InputAdornment position="end">
                          <CircularProgress color="primary" size={20} />
                        </InputAdornment>
                      ) : undefined
                    }}
                    label={t('pagePlugs.modalEdit.name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    disabled={isLoadingPlug}
                  />
                )}
              />
              <Controller
                name="spreadsheetId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      value: spreadsheet?.name || '',
                      endAdornment: isLoadingSpreadsheet ? (
                        <InputAdornment position="end">
                          <CircularProgress color="primary" size={20} />
                        </InputAdornment>
                      ) : undefined
                    }}
                    label={t('pagePlugs.modalEdit.googleSheets.spreadsheet')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    disabled
                  />
                )}
              />
              <Controller
                name="sheetId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      value: sheet?.name || '',
                      endAdornment: isLoadingSheet ? (
                        <InputAdornment position="end">
                          <CircularProgress color="primary" size={20} />
                        </InputAdornment>
                      ) : undefined
                    }}
                    label={t('pagePlugs.modalEdit.googleSheets.sheet')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    disabled
                  />
                )}
              />
              <Box display="flex">
                <Tooltip title={t('modal.icon.tooltip.connection')}>
                  <SettingsInputAntenna style={{ marginRight: '12px' }} />
                </Tooltip>
                <Typography color="textPrimary">{getValues('connection.name')}</Typography>
              </Box>
            </Stack>
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
          <SwiperSlide>
            <PlugFieldsGrid
              loading={loadingFields}
              plugFields={getValues('fields')}
              onCommitChanges={handleFieldChanges}
            />
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
          <SwiperSlide>
            <Box p={2} height="auto">
              <Typography>Filter</Typography>
              <Divider />

              <FilterBuilder
                value={watch('filter')}
                onValueChanged={(val) => setValue('filter', val)}
                fields={getValues('fields')}
                plugId={plugId}
              />

              {getFieldState('filter').error && 'Error: ' + getFieldState('filter').error}
            </Box>
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
        </Swiper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeletePlug} label={deleteButtonText} isDelete loading={loadingDelete} />
        <Button
          style={{ marginLeft: 'auto' }}
          isDelete
          onClick={() => onClose?.('Cancelled')}
          label={t('pagePlugs.modalEdit.cancel')}
        />
        <Button
          type="submit"
          onClick={handleUpdatePlug}
          label={t('pagePlugs.modalEdit.buttonSave')}
          loading={loadingUpdate}
        />
      </DialogActions>
    </Dialog>
  )
}

export default GoogleSheetsUpdateDialog
