import { EditorState, Modifier } from 'draft-js'
import { Keyword, KeywordType } from './types'
import { getWordBounds } from './utils'

export default function addHelperItem(editorState: EditorState, helperItem: Keyword): EditorState {
  const isFunctionType = helperItem.type === KeywordType.Function
  const contentStateWithEntity = editorState.getCurrentContent().createEntity(helperItem.type, 'MUTABLE', {
    helperItem
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

  const currentSelectionState = editorState.getSelection()
  const { begin, end } = getWordBounds(
    editorState.getCurrentContent().getBlockForKey(currentSelectionState.getAnchorKey()).getText(),
    currentSelectionState.getAnchorOffset()
  )

  // get selection of the @mention search text
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: end
  })

  let mentionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    mentionTextSelection,
    isFunctionType ? `${helperItem.name}` : `SUM([${helperItem.name}]) `,
    undefined, // no inline style needed
    entityKey
  )

  // If the mention is inserted at the end, a space is appended right after for
  // a smooth writing experience.
  const blockKey = mentionTextSelection.getAnchorKey()
  const blockSize = editorState.getCurrentContent().getBlockForKey(blockKey).getLength()
  if (blockSize === end) {
    mentionReplacedContent = Modifier.insertText(
      mentionReplacedContent,
      mentionReplacedContent.getSelectionAfter(),
      isFunctionType ? '( , )' : ''
    )
  }
  const selectionAfter = mentionReplacedContent.getSelectionAfter()
  const decreassedSelection = selectionAfter.merge({
    anchorOffset: selectionAfter.getAnchorOffset() - 4,
    focusOffset: selectionAfter.getAnchorOffset() - 4
  })
  const newEditorState = EditorState.push(editorState, mentionReplacedContent, 'insert-fragment')
  return EditorState.forceSelection(newEditorState, isFunctionType ? decreassedSelection : selectionAfter)
}
