import { Paper, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import Joyride from 'react-joyride'
import { accountService } from '../services'
import useAuth from './useAuth'

export const useInitialPresentation = (elementInFocus: HTMLElement | null) => {
  const [run, setRun] = useState(true)
  const { getAccountInfo, setAccountInfo } = useAuth()

  const accountInfo = getAccountInfo()

  const canShowInitialPresentation = !accountInfo ? false : !accountInfo.initialPresentationShowed

  const completePresentation = () => {
    if (!canShowInitialPresentation) {
      return
    }
    setRun(false)
    setInitialPresentationShowed()
  }

  const setInitialPresentationShowed = async () => {
    try {
      await accountService.setInitialPresentationShowed()
      setAccountInfo({ ...accountInfo!, initialPresentationShowed: true })
    } catch (err) {
      console.error(err)
    }
  }

  const DisplayElement: React.FC = () => {
    if (!elementInFocus) {
      return null
    }

    return (
      <Joyride
        run={canShowInitialPresentation && run}
        steps={[
          {
            content: <></>,
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            spotlightClicks: true,
            tooltipComponent: () => (
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3"> Comece aqui!</Typography>
              </Paper>
            ),
            styles: {
              options: {
                zIndex: 10000
              }
            },
            target: elementInFocus
          }
        ]}
      />
    )
  }
  return useMemo(() => ({ DisplayElement, completePresentation }), [elementInFocus, canShowInitialPresentation, run])
}
