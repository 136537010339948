import { ExitToApp, Help, MoreVert, Person, Settings } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  TabProps,
  Tabs
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import PluggerLogo from '../../components/PluggerLogoTransp'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import routes from '../../routes'
import { staticService } from '../../services/index'
import base64Utils from '../../utils/base64Utils'

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: (props: NavMenuProps) => props.width,
      flexShrink: 0
    }
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.light.contrast,
    width: (props: NavMenuProps) => props.width
  },
  menuButtonChevron: {
    alignSelf: 'flex-end'
  },
  miniDrawerPaper: {
    width: '90px',
    backgroundColor: theme.palette.background.light.contrast
  },
  miniDrawerOpen: {
    width: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  miniDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden'
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      width: '45%'
    },
    width: '80%',
    alignSelf: 'center'
  },
  hide: {
    display: 'none'
  },
  routeIcon: {
    color: theme.palette.common.white
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  divider: {
    backgroundColor: theme.palette.text.primary
  },
  customItemOpenDrawer: {
    width: '85%',
    margin: '0 auto'
  },
  tab: {
    color: theme.palette.common.white,
    opacity: 1,
    padding: 0,
    maxWidth: 'unset',
    [theme.breakpoints.down('xl')]: {
      minHeight: 32
    }
  },
  tabs: {
    flexGrow: 1,
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0.5, 0),
      fontSize: theme.typography.pxToRem(10)
    }
  },
  tabsFlexContainer: {
    height: '100%'
  },
  tabIndicator: {
    left: 25,
    width: 5
  },
  listItemTextTypography: {
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  tabIndicatorMini: {
    left: 10,
    width: 5
  },
  tabItemMiniCollapsed: {
    width: '60px',
    margin: 0
  },
  tabItemExpansed: {
    width: '90%',
    marginLeft: 'auto'
  },
  routeIconInactive: {
    color: '#fff',
    opacity: 0.38
  },
  tabSpace: {
    flex: '10 0 0'
  },
  userMenuPaper: {
    backgroundColor: theme.palette.background.light.contrast
  },
  userMenuItem: {
    color: theme.palette.common.white
  }
}))

type MapWithoutGet = new <K, V>(entries?: ReadonlyArray<readonly [K, V]> | null) => {
  [P in Exclude<keyof Map<K, V>, 'get'>]: Map<K, V>[P]
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const MapWithoutGet: MapWithoutGet = Map

class CustomMap<T, K> extends MapWithoutGet<T, K> {
  get(key: string) {
    const ent = new Map(this.entries())
    for (const [mapKey, value] of ent as any) {
      if ((mapKey as unknown as string[]).includes(key)) return value
    }
    return undefined
  }
}

const pathProfileOptionPermissionMapping = new CustomMap([
  [[PATHS.PLUGS, PATHS.CONNECTIONS], 'managingPlug'],
  [[PATHS.USERS, PATHS.TEAMS, PATHS.PROFILES, PATHS.PARAMETERS, PATHS.SENDING], 'managingAccess'],
  [[PATHS.ACCOUNT_GENERAL, PATHS.ACCOUNT_BILLING_SETTINGS, PATHS.ACCOUNT_BILLING_HISTORY], 'managingAccount']
])

const TabItem = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      textTransform: 'none',
      opacity: 1
    }
  })
)((props: ListItemProps & any) => <ListItem {...({ ...props } as any)} />)

interface NavMenuProps {
  width: CSSProperties['width']
}

const CustomRouterLink = (props: TabProps<any, { component: typeof RouterLink }>) => {
  return <Tab {...props} />
}
const CustomRouterOvr = (props: TabProps<typeof Box, { component: typeof Box }>) => {
  return <Tab {...props} />
}

const TabItemMenu: React.FC<
  PropsWithChildren<{
    text: string
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
    imagePath?: string
    inactive?: boolean
    EndIcon?: React.FunctionComponent<{ className?: string }>
  }>
> = ({ text, onClick, imagePath, EndIcon, inactive, ...props }) => {
  const classes = useStyles({ width: 0 })
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  useEffect(() => {
    if (imagePath)
      staticService.get(imagePath).then(async (response) => {
        const imgDataUri = await base64Utils.convertBlobToDataUri(response.data)
        setImageSrc(imgDataUri)
        return
      })
    setImageSrc(null)
  }, [imagePath])

  return (
    <TabItem
      className={classes.tabItemExpansed}
      disableRipple
      disableTouchRipple
      ContainerComponent="div"
      alignItems="center"
    >
      <ListItemIcon>
        <Avatar className={classes.avatar} src={imageSrc || '/broken.jpg'} />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ noWrap: true }} primary={text} />
      <ListItemSecondaryAction>
        <IconButton onClick={onClick} size="large" disabled={inactive}>
          {EndIcon ? (
            <EndIcon className={inactive ? classes.routeIconInactive : classes.routeIcon} />
          ) : (
            <MoreVert className={classes.routeIcon} />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </TabItem>
  )
}

const NavMenu: React.FC<PropsWithChildren<NavMenuProps>> = (props) => {
  const classes = useStyles({ width: props.width })
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const haveOptionPermission = (path: string): boolean => {
    const profilePermission = pathProfileOptionPermissionMapping.get(path)
    if (!profilePermission) {
      return true
    }
    const userProfile = auth.getUserInfo()?.profile
    if (!userProfile) {
      return false
    }
    return userProfile[profilePermission as keyof typeof userProfile] as boolean
  }

  const handleToggleDrawer = () => {
    setOpen((isOpen) => !isOpen)
  }

  const handleOpenUserMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }
  const handleSignOut = () => {
    auth.signOut()
  }
  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  return (
    <nav className={classes.drawer}>
      <Hidden implementation="js" mdUp>
        <Drawer
          open={true}
          variant="permanent"
          className={clsx(classes.miniDrawerPaper, {
            [classes.miniDrawerOpen]: open,
            [classes.miniDrawerClose]: !open
          })}
          classes={{
            paper: clsx(classes.miniDrawerPaper, {
              [classes.miniDrawerOpen]: open,
              [classes.miniDrawerClose]: !open
            })
          }}
          ModalProps={{ keepMounted: true }}
        >
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleToggleDrawer}
            className={clsx(classes.menuButtonChevron, {
              [classes.hide]: !open
            })}
            size="large"
          >
            <CloseIcon className={classes.routeIcon} />
          </IconButton>
          <PluggerLogo className={clsx(classes.logo, { [classes.hide]: !open })} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggleDrawer}
            className={clsx({
              [classes.hide]: open
            })}
            size="large"
          >
            <MenuIcon className={classes.routeIcon} />
          </IconButton>
          <Divider className={classes.divider} />
          <Tabs
            orientation="vertical"
            variant="scrollable"
            classes={{
              flexContainer: classes.tabsFlexContainer
            }}
            className={classes.tabs}
            value={location.pathname}
            indicatorColor="primary"
            TabIndicatorProps={{ className: clsx({ [classes.tabIndicator]: open, [classes.tabIndicatorMini]: !open }) }}
          >
            {routes
              .filter((r) => r.isNavigated)
              .map((route) => {
                const RouteIcon = route.icon as React.FunctionComponent<React.SVGProps<SVGSVGElement>>
                return (
                  <CustomRouterLink
                    key={route.key}
                    className={classes.tab}
                    component={RouterLink}
                    disabled={!haveOptionPermission(route.path)}
                    to={route.path}
                    value={route.path}
                    label={
                      <TabItem
                        className={clsx({ [classes.tabItemMiniCollapsed]: !open, [classes.tabItemExpansed]: open })}
                        disableRipple
                        disableTouchRipple
                        button
                        disabled={!haveOptionPermission(route.path)}
                      >
                        <ListItemIcon>
                          <RouteIcon className={classes.routeIcon} />
                        </ListItemIcon>
                        <ListItemText primary={open ? t(route.labelKey!) : ''} />
                      </TabItem>
                    }
                  />
                )
              })}
            <div className={classes.tabSpace}></div>
            <Divider className={classes.divider} />

            <CustomRouterOvr
              key={PATHS.ACCOUNT_GENERAL}
              className={classes.tab}
              component={Box}
              value={PATHS.ACCOUNT_GENERAL + `?acc=VYX${auth.getAccountInfo()?.accountId}G`}
              onClick={handleOpenUserMenu as any}
              label={
                <TabItem
                  className={clsx({ [classes.tabItemMiniCollapsed]: !open, [classes.tabItemExpansed]: open })}
                  disableRipple
                  disableTouchRipple
                  button
                >
                  <ListItemIcon>
                    <Avatar sx={{ width: 30, height: 30 }} src={auth.getUserInfo()?.imagePath} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      open
                        ? (auth.getUserInfo()?.userFirstName || '') + ' ' + auth.getUserInfo()?.userLastName || ''
                        : ''
                    }
                  />
                </TabItem>
              }
            />
            {/* <CustomRouterLink
              key={PATHS.ACCOUNT_GENERAL}
              className={classes.tab}
              component={RouterLink}
              disabled={!haveOptionPermission(PATHS.ACCOUNT_GENERAL)}
              to={PATHS.ACCOUNT_GENERAL}
              value={PATHS.ACCOUNT_GENERAL}
              label={
                <TabItem
                  className={clsx({ [classes.tabItemMiniCollapsed]: !open, [classes.tabItemExpansed]: open })}
                  disableRipple
                  disableTouchRipple
                  button
                  disabled={!haveOptionPermission(PATHS.ACCOUNT_GENERAL)}
                >
                  <ListItemIcon>
                    <Settings className={classes.routeIcon} />
                  </ListItemIcon>
                  <ListItemText primary={open ? auth.getAccountInfo()?.companyName : ''} />
                </TabItem>
              }
            /> */}
          </Tabs>
        </Drawer>
      </Hidden>
      <Hidden implementation="js" mdDown>
        <Drawer
          open={true}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <PluggerLogo className={classes.logo} />
          <Divider className={classes.divider} />

          <Tabs
            variant="scrollable"
            classes={{
              flexContainer: classes.tabsFlexContainer
            }}
            className={classes.tabs}
            orientation="vertical"
            value={location.pathname.match(/^\/[^/]*/)?.[0] || '/'}
            indicatorColor="primary"
            TabIndicatorProps={{ className: classes.tabIndicator }}
          >
            {routes
              .filter((r) => r.isNavigated)
              .map((route) => {
                const RouteIcon = route.icon as React.FunctionComponent<React.SVGProps<SVGSVGElement>>
                return (
                  <CustomRouterLink
                    key={route.key}
                    className={classes.tab}
                    component={RouterLink}
                    disabled={!haveOptionPermission(route.path)}
                    to={route.path}
                    value={route.path}
                    label={
                      <TabItem
                        className={classes.tabItemExpansed}
                        disableRipple
                        disableTouchRipple
                        button
                        alignItems="center"
                        disabled={!haveOptionPermission(route.path)}
                      >
                        <ListItemIcon>
                          <RouteIcon className={classes.routeIcon} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ className: classes.listItemTextTypography }}
                          primary={t(route.labelKey!)}
                        />
                      </TabItem>
                    }
                  />
                )
              })}
            <div className={classes.tabSpace}></div>
            <Divider className={classes.divider} />

            <Tab
              value="/my-user"
              component={TabItemMenu as any}
              text={(auth.getUserInfo()?.userFirstName || '') + ' ' + auth.getUserInfo()?.userLastName || ''}
              onClick={handleOpenUserMenu as any}
              imagePath={auth.getUserInfo()?.imagePath}
            />
            <Tab
              value="/account"
              component={TabItemMenu as any}
              text={auth.getAccountInfo()?.companyName || ''}
              inactive={!haveOptionPermission(PATHS.ACCOUNT_GENERAL)}
              onClick={() => navigate(PATHS.ACCOUNT_GENERAL + `?acc=VYX${auth.getAccountInfo()?.accountId}G`)}
              EndIcon={Settings}
              imagePath={auth.getAccountInfo()?.imagePath}
            />
          </Tabs>
        </Drawer>
      </Hidden>
      <Menu
        id="nav-float-menu"
        classes={{
          paper: classes.userMenuPaper
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          className={classes.userMenuItem}
          classes={{
            root: classes.listItemTextTypography
          }}
          onClick={() => handleCloseUserMenu()}
          component={RouterLink as any}
          to={PATHS.SIGNED_IN_USER}
        >
          <ListItemIcon className={classes.routeIcon}>
            <Person fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('pageUserlogged.MyAccount')} />
        </MenuItem>
        <MenuItem
          className={classes.userMenuItem}
          classes={{
            root: classes.listItemTextTypography
          }}
          onClick={() => {
            window.open('https://intelectivo.movidesk.com/kb', '_blank')?.focus()
          }}
          component={RouterLink as any}
          to="/"
        >
          <ListItemIcon className={classes.routeIcon}>
            <Help fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('pageUserlogged.Help')} />
        </MenuItem>
        <MenuItem className={classes.userMenuItem} onClick={handleSignOut}>
          <ListItemIcon className={classes.routeIcon}>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('pageUserlogged.SignOut')} />
        </MenuItem>
      </Menu>
    </nav>
  )
}

export default NavMenu
