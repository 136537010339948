import { DashboardControl, DashboardStateChangedArgs } from 'devexpress-dashboard'
import * as Model from 'devexpress-dashboard/model'

const dashboardStateCustomProperty: Model.CustomPropertyMetadata = {
  ownerType: Model.DashboardState,
  propertyName: 'DashboardState',
  defaultValue: '',
  valueType: 'string'
}

Model.registerCustomProperty(dashboardStateCustomProperty)

function onDashboardStateChanged(args: DashboardStateChangedArgs) {
  const customProperties = args.dashboard.customProperties
  customProperties.setValue(dashboardStateCustomProperty.propertyName, args.stateString)
}

export class DashboardStateCustomPropertyExtension {
  name = 'DashboardState'
  constructor(private dashboardControl: DashboardControl) {}

  start(): void {
    this.dashboardControl.on('dashboardStateChanged', onDashboardStateChanged)
  }

  stop(): void {
    this.dashboardControl.off('dashboardStateChanged', onDashboardStateChanged)
  }
}
