import { SvgIconComponent } from '@mui/icons-material'
import { Box, ListItemClassKey, List as MaterialList, ListProps as MaterialListProps, Typography } from '@mui/material'
import React from 'react'
import { DragDropContext, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd'
import ListItem from '../../components/ListItem'

type PropertiesOfType<TObj, TResult> = { [K in keyof TObj]: TObj[K] extends TResult ? K : never }[keyof TObj]

interface ListPropsBase<T extends { [Key in K]: string }, K extends PropertiesOfType<T, string>>
  extends MaterialListProps {
  items: Array<T>
  hideEditIcon?: boolean
  listItemClasses?: Partial<Record<ListItemClassKey, string>>
  ItemComponent?: React.FunctionComponent<any>
  itemEndIcon?: SvgIconComponent
  itemStartIcon?: SvgIconComponent | ((it: T) => JSX.Element)
  onEdit?(item: { [key in K]: string } | { [key in keyof T]: any } | undefined): void
  itemIdKey: keyof T
  itemLabelKey: K
  itemCaptionKey?: K
  itemEndTextKey?: K
  itemImageKey?: keyof T
}

const isSvgIconComponent = (value: any): value is SvgIconComponent => {
  return value && typeof value === 'object'
}

type CommonListProps<T extends { [Key in K]: string }, K extends PropertiesOfType<T, string>> = ListPropsBase<T, K> & {
  enableReorder?: false
}

type DraggableListProps<T extends { [Key in K]: string }, K extends PropertiesOfType<T, string>> = Omit<
  ListPropsBase<T, K>,
  'onDragEnd'
> & {
  enableReorder: true
  onDragEnd(result: DropResult, provided: ResponderProvided): void
}

const List = <T extends { [Key in K]: string }, K extends PropertiesOfType<T, string>>({
  enableReorder,
  onDragEnd,
  ...rest
}: DraggableListProps<T, K> | CommonListProps<T, K>): React.ReactElement => {
  return (
    <Box sx={{ maxHeight: '100%', overflow: 'auto', maxWidth: '100%', overflowX: 'hidden' }}>
      {enableReorder ? (
        <DragDropContext onDragEnd={onDragEnd as (result: DropResult, provided: ResponderProvided) => void}>
          <Droppable droppableId="droppable-list">
            {(provided) => <ListRoot ref={provided.innerRef} {...rest} {...provided.droppableProps} />}
          </Droppable>
        </DragDropContext>
      ) : (
        <ListRoot {...rest} />
      )}
    </Box>
  )
}

const ListRoot = <T extends { [Key in K]: string }, K extends PropertiesOfType<T, string>>({
  itemIdKey,
  itemLabelKey,
  itemCaptionKey,
  itemEndTextKey,
  items,
  onEdit,
  itemStartIcon: ItemStartIcon,
  itemEndIcon,
  itemImageKey,
  ItemComponent = ListItem,
  className,
  hideEditIcon,
  listItemClasses,
  ...others
}: ListPropsBase<T, K>) => {
  const handleClickEndIcon = (itemId: T[keyof T]) => {
    onEdit?.(items.find((it) => it[itemIdKey] === itemId))
  }
  return (
    <MaterialList {...others} className={className}>
      {items.map((item) => (
        <ItemComponent
          classes={listItemClasses}
          hideEditIcon={hideEditIcon}
          itemId={item[itemIdKey]}
          primaryTextString={item[itemLabelKey]}
          primaryText={
            <Typography sx={{ wordBreak: 'break-all' }} maxWidth="calc(100% - 52px)">
              {item[itemLabelKey]}
            </Typography>
          }
          secondaryText={itemCaptionKey && item[itemCaptionKey]}
          endText={itemEndTextKey && item[itemEndTextKey]}
          startIcon={ItemStartIcon && (isSvgIconComponent(ItemStartIcon) ? ItemStartIcon : ItemStartIcon(item))}
          endIcon={itemEndIcon}
          onEdit={handleClickEndIcon}
          imageSrc={itemImageKey && item[itemImageKey]}
          item={item}
        />
      ))}
    </MaterialList>
  )
}

export default List
