import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import moment from 'moment'
import { BASE_URL } from '../constants/apiEndpoints'
import PATHS from '../constants/paths'
import i18n from '../locales/i18next'
import snackbarUtils from '../utils/SnackbarUtils'
Axios.defaults.headers.post = {
  'content-Type': 'application/json'
}

Axios.defaults.transformRequest = [
  function (data) {
    const prevDateFormat = Date.prototype.toJSON
    Date.prototype.toJSON = function () {
      return moment(this).format()
    }
    if (data) {
      const stringfiedJson = JSON.stringify(data)
      Date.prototype.toJSON = prevDateFormat
      return stringfiedJson
    }
    Date.prototype.toJSON = prevDateFormat
    return null
  }
]

Axios.defaults.transformResponse = [
  function (data, headers) {
    if (headers!['content-type'] && headers!['content-type'].includes('application/json'))
      if (data) return JSON.parse(data)
    return null
  }
]

export const authInterceptor = (req: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem('token')
  if (token) {
    req.headers!['Authorization'] = 'Bearer ' + token
  }
  return req
}

const api = Axios.create({
  baseURL: BASE_URL,
  headers: {
    post: {
      'content-Type': 'application/json'
    },
    put: {
      'content-Type': 'application/json'
    },
    patch: {
      'content-Type': 'application/json'
    }
  }
})

api.interceptors.request.use(authInterceptor as any)
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.code === 'ERR_NETWORK'){
      snackbarUtils.toast(i18n.t('common.error.network'),  'error')
      return Promise.reject(error)
    }
    console.log('fetch error ', error)
    if (error?.response?.status === 401 || error?.status === 401) {
      if(window.location.pathname === PATHS.SIGNIN) {
        return Promise.reject(error)
      }
      window.location.replace(PATHS.SIGNIN)
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default api

export interface Error {
  errorCode: string
  errorMessage: string
  propertyName: string
  severity: string
}

export interface ApiBadRequest {
  errorCode: string
  errorMessage: string
  propertyName: string
  severity: string
}

export const instanceOfAxiosError = (object: AxiosError): object is AxiosError<Error[] | undefined> => {
  return object.isAxiosError
}
