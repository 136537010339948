import { AxiosResponse } from 'axios'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { format } from '../../utils/stringUtils'
import api from '../api'

type CheckCustomerIdResponse = {
  isValid: boolean
}

function checkCustomerId(connectionId: number, customerId: string): Promise<AxiosResponse<CheckCustomerIdResponse>> {
  return api.post(format(ENDPOINTS.GOOGLE_SERVICES_ADS_CHECK_CUSTOMER_ID, connectionId), { customerId: customerId })
}

const serviceClient = {
  checkCustomerId
}

export default serviceClient
