import { ListProps } from '@mui/material'
import React, { PropsWithChildren, memo } from 'react'
import AnalyzeListItem from '../../components/AnalyzeListItem'
import List from '../../components/List'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'

interface AnalyzeListProps extends ListProps {
  onShareClick?(itemData: any): void
  onAddCollecionClick?(itemData: any): void
  onEditClick?(itemData: any): void
  onUnShareClick?(itemData: any): void
  items: Omit<AnalyzeSchema, 'accountId' | 'canExportData'>[]
}

const AnalyzeList: React.FC<PropsWithChildren<AnalyzeListProps>> = ({
  onShareClick,
  onAddCollecionClick,
  onEditClick,
  onUnShareClick,
  items,
  ...props
}) => {
  return (
    <List
      items={items}
      itemIdKey="id"
      itemLabelKey="name"
      ItemComponent={(params) => (
        <AnalyzeListItem
          key={params.item[params.itemId]}
          onShareClick={onShareClick}
          onAddCollecionClick={onAddCollecionClick}
          onEditClick={onEditClick}
          onUnShareClick={onUnShareClick}
          item={params.item}
        />
      )}
      {...props}
    />
  )
}

export default memo(AnalyzeList, (prevProps, nextProps) => {
  return prevProps.items === nextProps.items
})
