import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Form, { FormProps } from '../../../components/Form'
import OutlinedTextField from '../../../components/Form/OutlinedTextField'
import { DatabaseConnectionSchema } from '../../../schemas/ConnectionSchema'
import Button from '../../Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    minWidth: 350,
    minHeight: 520
  },
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#d62b2b'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  whiteButton: {
    color: 'white'
  }
}))

type MovideskConnectionFormProps = Partial<FormProps<Omit<DatabaseConnectionSchema, 'type'>>> & {
  variant?: 'white' | 'default'
  usePlaceholder?: boolean
  onCancelClick: () => void
  isUpdating?: boolean
}

const validationSchema = yup.object({
  name: yup.string().required(),
  apiSettings: yup.object({
    token: yup.string().required()
  })
})

const updateValidationSchema = yup.object({
  name: yup.string().required(),
  apiSettings: yup.object({
    token: yup.string().optional()
  })
})

const defaultInitialValues = {
  name: '',
  apiSettings: {
    token: ''
  }
}

const MySqlConnectionForm: React.FC<PropsWithChildren<MovideskConnectionFormProps>> = ({
  className,
  variant = 'default',
  usePlaceholder,
  initialValues,
  isUpdating,
  onCancelClick,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box justifyContent="center" alignItems="center" width="100%" display="flex">
      <Form
        isSubmitting={false}
        initialTouched
        className={clsx(classes.root, className)}
        initialValues={initialValues || defaultInitialValues}
        validationSchema={isUpdating ? updateValidationSchema : validationSchema}
        onSubmit={console.log}
        {...rest}
      >
        <OutlinedTextField
          required
          fullWidth
          className={clsx({ [classes.outlinedWhiteInput]: variant === 'white' })}
          name="name"
          label={!usePlaceholder ? 'Name' : undefined}
          placeholder={usePlaceholder ? 'Connection Name*' : undefined}
        />
        <OutlinedTextField
          required
          fullWidth
          className={clsx({ [classes.outlinedWhiteInput]: variant === 'white' })}
          name="apiSettings.token"
          label={!usePlaceholder ? 'Token' : undefined}
          placeholder={usePlaceholder ? 'Token*' : undefined}
        />
        <Box display="flex" justifyContent="space-between">
          <Button
            className={classes.whiteButton}
            variant="text"
            label={t('pagePlugs.modalCreate.createConnection.btnCancel')}
            onClick={onCancelClick}
          />
          <Button
            type="submit"
            className={classes.whiteButton}
            variant="text"
            label={t('pagePlugs.modalCreate.createConnection.btnSave')}
          />
        </Box>
      </Form>
    </Box>
  )
}

export default MySqlConnectionForm
