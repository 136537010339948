import { ChangeSet } from '@devexpress/dx-react-grid'
import { SettingsInputAntenna, TableChart } from '@mui/icons-material'
import { Box, Divider, FormControlLabel, Stack, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Button from '../../../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../../../components/Dialog'
import FilterBuilder from '../../../../../components/FilterBuilder'
import TextField from '../../../../../components/TextField'
import WarningContainer from '../../../../../components/WarningContainer'
import { DatabaseConnectionType } from '../../../../../schemas/ConnectionSchema'
import { PlugFieldSchema, PlugSchema, PlugType } from '../../../../../schemas/PlugSchema'
import { plugService } from '../../../../../services'
import PlugFieldsGrid from '../../../PlugFieldsGrid'
import { UpdatePlugDialogPropsBase } from '../../UpdatePlugDialogV2'

type FormValues = Omit<PlugSchema, 'filter'> & { fields: PlugFieldSchema[]; filter?: any }

export type RelatedAnalysisDependecy = {
  name: string
  userFullName: string
}

const defaultPlugValues: FormValues = {
  id: 0,
  name: '',
  connection: {
    name: '',
    id: 0,
    host: '',
    database: '',
    password: '',
    username: '',
    type: DatabaseConnectionType.MySql,
    image: ''
  },
  lastLog: null,
  type: PlugType.MySql,
  tableBinding: '',
  fields: [],
  filter: undefined
}

const CommonUpdateDialog: React.FC<PropsWithChildren<UpdatePlugDialogPropsBase>> = ({ plug, open, onClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [tabIndex, setTabIndex] = useState(0)
  const [deleteErrorBag, setDeleteErrorBag] = useState<{
    message: string
    dependents: RelatedAnalysisDependecy[]
  } | null>(null)
  const dltBtnText = t('pagePlugs.modalEdit.buttonDelete')
  const [deleteButtonText, setDeleteButtonText] = useState(dltBtnText)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingFields, setLoadingFields] = useState(false)
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const { values, errors, setFieldValue, setValues, submitForm } = useFormik({
    initialValues: !!plug ? { ...plug, fields: [] as PlugFieldSchema[] } : defaultPlugValues,
    onSubmit: handleUpdatePlug
  })

  const resetState = () => {
    setTabIndex(0)
    setDeleteErrorBag(null)
    setDeleteButtonText(dltBtnText)
    setLoadingDelete(false)
    setLoadingUpdate(false)
    setValues(defaultPlugValues)
  }

  const handleOnEnter = () => {
    const loadFields = async () => {
      if (plug) {
        try {
          setLoadingFields(true)
          await setValues(!!plug ? { ...plug, fields: [] as PlugFieldSchema[], filter: undefined } : defaultPlugValues)
          const { data } = await plugService.getFields(plug.id, true)
          await setFieldValue('fields', data)
          await setFieldValue('filter', plug.filter)
        } catch (err: any) {
          enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.loadingFields'), { variant: 'error' })
        }
        setLoadingFields(false)
      }
    }

    loadFields()
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    const plugFields = values['fields']
    if (changedFields) {
      const prevFields = [...plugFields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setFieldValue('fields', prevFields)
    }
  }

  const handleEnsureDeleteConfirmed = () => {
    if (deleteButtonText === t('pagePlugs.modalEdit.buttonDelete')) {
      setDeleteButtonText(t('pagePlugs.modalEdit.buttonConfirmDelete')!)
      return false
    }
    return true
  }

  const handleDeletePlug = async () => {
    if (!handleEnsureDeleteConfirmed()) return
    try {
      setLoadingDelete(true)
      var { data: plugAnalysis } = await plugService.getAnalysis(plug!.id)
      if (plugAnalysis.length > 0) {
        setDeleteErrorBag({
          message: t('pagePlugs.modalEdit.toast.error.plugDelete'),
          dependents: plugAnalysis.map((x) => ({
            name: x.analysisName,
            userFullName: x.userOwner.userOwnerFullName
          }))
        })
        setLoadingDelete(false)
        return
      }
      await plugService._delete(plug!.id)
      onClose?.('Submited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugDelete', { name: plug!.name }), { variant: 'success' })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugDelete'), { variant: 'error' })
    }
    setLoadingDelete(false)
  }
  async function handleUpdatePlug(formValues: FormValues) {
    try {
      setLoadingUpdate(true)
      await plugService.update(formValues.id, formValues)
      onClose?.('Submited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugUpdate', { name: formValues.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugUpdate'), { variant: 'error' })
    }
    setLoadingUpdate(false)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
    swiper?.slideTo(newValue)
  }

  return (
    <Dialog
      open={!!open}
      title={t('pagePlugs.modalEdit.title')}
      description={t('pagePlugs.modalEdit.subtitle')}
      onClose={() => onClose?.('Exited')}
      fullWidth
      maxWidth="xl"
      TransitionProps={{
        onEnter: handleOnEnter,
        onExited: resetState
      }}
      fullScreen
    >
      <DialogContent dividers>
        <Tabs indicatorColor="primary" centered value={tabIndex} onChange={handleTabChange}>
          <Tab label={t('pagePlugs.modalEdit.buttonGeneral')} value={0} />
          <Tab label={t('pagePlugs.modalEdit.buttonFields')} value={1} />
          <Tab label={t('pagePlugs.modalEdit.buttonFilter')} value={2} />
        </Tabs>
        <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal" simulateTouch={false}>
          <SwiperSlide>
            <Stack padding={2} spacing={3}>
              <TextField
                label={t('pagePlugs.modalEdit.name')}
                required
                value={values?.name || ''}
                error={Boolean(errors['name'])}
                helperText={errors['name']}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />
              <Stack spacing={2}>
                <Box display="flex">
                  <Tooltip title={t('modal.icon.tooltip.connection')}>
                    <SettingsInputAntenna style={{ marginRight: '12px' }} />
                  </Tooltip>
                  <Typography color="textPrimary">{values.connection?.name}</Typography>
                </Box>
                <Box display="flex">
                  <Tooltip title={t('modal.icon.tooltip.tableBinding')}>
                    <TableChart style={{ marginRight: '12px' }} />
                  </Tooltip>
                  <Typography color="textPrimary">{values.tableBinding}</Typography>
                </Box>
                {!!plug?.canUseAcceleration && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.useAcceleration}
                        onChange={(e) => setFieldValue('useAcceleration', e.target.checked)}
                      />
                    }
                    label={t('pagePlugs.dialog.useAccelerationLabel')}
                  />
                )}
              </Stack>
            </Stack>
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <PlugFieldsGrid loading={loadingFields} plugFields={values.fields} onCommitChanges={handleFieldChanges} />
              {deleteErrorBag && (
                <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
              )}
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box p={2} height="auto">
              <Typography>Filter</Typography>
              <Divider />
              <FilterBuilder
                value={values.filter}
                onValueChanged={(val) => setFieldValue('filter', val)}
                fields={values.fields}
                plugId={values.id}
              />

              {errors['filter'] && 'Error: ' + errors['filter']}
              {deleteErrorBag && (
                <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
              )}
            </Box>
          </SwiperSlide>
        </Swiper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeletePlug} label={deleteButtonText} isDelete loading={loadingDelete} />
        <Button
          style={{ marginLeft: 'auto' }}
          isDelete
          onClick={() => onClose?.('Exited')}
          label={t('pagePlugs.modalEdit.cancel')}
        />
        <Button
          type="submit"
          onClick={submitForm}
          label={t('pagePlugs.modalEdit.buttonSave')}
          loading={loadingUpdate}
        />
      </DialogActions>
    </Dialog>
  )
}

export default CommonUpdateDialog
