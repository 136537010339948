import billingService from '../../services/billingService'
import useAuth from '../useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Payment } from '../usePayment/payment'

export const usePaymentInfo = () => {
  const [loading, setLoading] = useState(false)
  const [subscription, setSubscription] = useState<Payment>()
  const [error, setError] = useState<any>()
  const auth = useAuth()

  const loadingPayment = useCallback(async () => {
    if (!auth.validateAuth()) {
      return
    }
    setLoading(true)
    try {
      const { data } = await billingService.getSubscription()
      setSubscription(data)
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }, [auth])

  useEffect(() => {
    loadingPayment()
  }, [loadingPayment])

  return {
    data: subscription,
    isLoading: loading,
    isError: !!error,
    error: error,
    revalidate: loadingPayment as any
  }
}
