import i18n from '../../../locales/i18next'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

class RemoveFieldExtension implements ComponentExtension {
  name = 'RemoveFieldExtension'
  icon = 'trash'
  text = i18n.t('pivotGrid.extensions.removeField.text')
  items?: ComponentExtensionOption[] | undefined
  onItemClick: () => void

  constructor(component: PivotGridComponent, field: PivotGridField) {
    this.onItemClick = () => removeField(field, component)
  }
}

const removeField = (field: PivotGridField, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  const fields = dataSource.fields() as PivotGridField[]
  const fieldIndex = fields.findIndex((x) => x.index === field.index)
  fields.splice(fieldIndex, 1)
  dataSource.fields(fields)
  dataSource.load()
}

export default RemoveFieldExtension
