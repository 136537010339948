import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import { CreatePlugSchema, PlugFieldSchema, PlugType } from '../../../../../schemas/PlugSchema'
import { connectionService, plugService } from '../../../../../services'
import { FlowComponentBaseProps } from '../../types'
import FieldsGrid from '../FieldsGrid'
import FilterView from '../FilterView'
import MySqlPlugForm, { MySqlPlugFormValues } from './MySqlPlugForm'

const MySqlFlow: React.FC<PropsWithChildren<FlowComponentBaseProps>> = ({ plug, onBackClick, onClose }) => {
  const [index, setIndex] = useState(0)
  const [plugFormValues, setPlugFormValues] = useState<MySqlPlugFormValues | null>(null)
  const [fields, setFields] = useState<PlugFieldSchema[]>([])
  const [filterValue, setFilterValue] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [loadingFetchFields, setLoadingFetchFields] = useState(false)

  const goNextPage = () => {
    setIndex((prev) => prev + 1)
  }

  const goBackPage = () => {
    setIndex((prev) => prev - 1)
  }

  const handleFetchFields = async (values: MySqlPlugFormValues) => {
    try {
      setLoadingFetchFields(true)

      const response = await connectionService.getFields(values!.connection!.id, values!.tableOrView)
      setFields(response.data)
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.getFields'), { variant: 'error' })
    } finally {
      setLoadingFetchFields(false)
    }
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    if (changedFields) {
      const prevFields = [...fields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setFields(prevFields)
    }
  }

  const handleClose = () => {
    onClose('Exited')
  }

  const handleCreatePlug = async () => {
    const newPlug: CreatePlugSchema = {
      connectionId: plugFormValues!.connection.id,
      fields: fields,
      name: plugFormValues!.plugName,
      tableBinding: plugFormValues!.tableOrView,
      filter: filterValue,
      type: PlugType.MySql
    }
    try {
      await plugService.create(newPlug)
      onClose('EndFlow')
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createPlug', { name: newPlug.name }), {
        variant: 'success'
      })
      dataLayer.push({
        event: 'on_plug_created',
        plugType: newPlug.type
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        if (firstError?.errorMessage === 'Table already in use') {
          enqueueSnackbar(t('plug.error.tableAlreadyInUse', { tableName: newPlug.tableBinding }), { variant: 'error' })
          return
        }
        if (firstError?.errorMessage === 'Plug name already in use') {
          enqueueSnackbar(t('plug.error.plugNameAlreadyInUse', { plugName: newPlug.name }), { variant: 'error' })
          return
        }

        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleSubmitPlugForm = (values: MySqlPlugFormValues) => {
    setPlugFormValues(values)
    goNextPage()
    handleFetchFields(values)
  }

  return (
    <Box>
      <SwipeableViews index={index} animateHeight>
        <Box
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <MySqlPlugForm
            onSubmit={handleSubmitPlugForm}
            navigationProps={{ onNextClick: goNextPage, onClose: handleClose, onBackClick: onBackClick }}
          />
        </Box>
        <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <FieldsGrid
            loading={loadingFetchFields}
            fields={fields}
            onChange={handleFieldChanges}
            navigationProps={{
              onBackClick: goBackPage,
              onNextClick: goNextPage,
              onClose: handleClose
            }}
          />
        </Box>
        <Box
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <FilterView
            fields={fields}
            value={filterValue}
            onChange={(val) => setFilterValue(val)}
            navigationProps={{
              onBackClick: goBackPage,
              onClose: handleClose,
              onNextClick: handleCreatePlug,
              nextDisabled: true
            }}
          />
        </Box>
      </SwipeableViews>
    </Box>
  )
}

export default MySqlFlow
