import {
  DashboardControl,
  IExtension,
  ItemWidgetOptionEventArgs,
  ViewerApiExtension
} from 'devexpress-dashboard/common'
import { BindingPanelExtension, CustomizeDataItemContainerSectionsEventArgs } from 'devexpress-dashboard/designer'
import * as Model from 'devexpress-dashboard/model'
import { CustomExtension } from './ChartLegentFormattingExtension'

const ChartPointLabelFormattingExtension = (() => {
  const fontSizeProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartSeries,
    propertyName: 'FontSizeProperty',
    defaultValue: 14,
    valueType: 'number'
  }
  const fontWeightProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartSeries,
    propertyName: 'FontWeightProperty',
    defaultValue: 400,
    valueType: 'number'
  }
  const fontColorProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartSeries,
    propertyName: 'FontColorProperty',
    defaultValue: '#FFFFFF',
    valueType: 'string'
  }
  const fontOpacityProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartSeries,
    propertyName: 'FontOpacityProperty',
    defaultValue: 1,
    valueType: 'number'
  }
  const backgroundProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartSeries,
    propertyName: 'backgroundProperty',
    defaultValue: true,
    valueType: 'boolean'
  }

  Model.registerCustomProperty(fontSizeProperty)
  Model.registerCustomProperty(fontWeightProperty)
  Model.registerCustomProperty(fontColorProperty)
  Model.registerCustomProperty(fontOpacityProperty)
  Model.registerCustomProperty(backgroundProperty)

  // 2. Viewer
  function onItemWidgetOptionsPrepared(args: ItemWidgetOptionEventArgs) {
    if (args.dashboardItem instanceof Model.ChartItem) {
      var seriesOptionArray = (args.options as any)['series'] || []
      seriesOptionArray.forEach(function (seriesOption: any) {
        var series = args.chartContext!.getDashboardItemSeries(seriesOption)
        if (series) {
          if (!seriesOption.label) {
            seriesOption.label = {
              font: {}
            }
          }
          let fontSize = series.customProperties.getValue(fontSizeProperty.propertyName)
          let fontWeight = series.customProperties.getValue(fontWeightProperty.propertyName)
          let fontColor = series.customProperties.getValue(fontColorProperty.propertyName)
          let fontOpacity = series.customProperties.getValue(fontOpacityProperty.propertyName)
          let background = series.customProperties.getValue(backgroundProperty.propertyName)
          seriesOption.label.font = seriesOption.label.font || {}
          seriesOption.label.font.size = fontSize
          seriesOption.label.font.weight = fontWeight
          seriesOption.label.font.color = fontColor
          seriesOption.label.font.opacity = fontOpacity
          seriesOption.label.backgroundColor = background ? undefined : 'none'
        }
      })
    }
  }

  // 3. Designer
  function onCustomizeSections(args: CustomizeDataItemContainerSectionsEventArgs) {
    var series = args.dataItemContainer
    if (series instanceof Model.ChartSeries) {
      args.addSection({
        title: 'Fonte',
        items: [
          {
            dataField: fontSizeProperty.propertyName,
            editorType: 'dxNumberBox',
            label: {
              text: 'Tamanho'
            },
            editorOptions: {
              showSpinButtons: true
            }
          },
          {
            dataField: fontWeightProperty.propertyName,
            editorType: 'dxSelectBox',
            label: {
              text: 'Estilo'
            },
            editorOptions: {
              items: [
                { value: 400, displayValue: 'Normal' },
                { value: 700, displayValue: 'Negrito' },
                { value: 1000, displayValue: 'Negrito Extra' }
              ],
              displayExpr: 'displayValue',
              valueExpr: 'value'
            }
          },
          {
            dataField: fontColorProperty.propertyName,
            editorType: 'dxColorBox',
            label: {
              text: 'Cor'
            },
            editorOptions: {
              opened: false
            }
          },
          {
            dataField: fontOpacityProperty.propertyName,
            editorType: 'dxNumberBox',
            label: {
              text: 'Opacidade'
            },
            editorOptions: {
              max: 1,
              min: 0,
              format: '#0%',
              step: 0.01,
              showSpinButtons: true
            }
          },
          {
            dataField: backgroundProperty.propertyName,
            editorType: 'dxCheckBox',
            label: {
              text: 'Fundo'
            },
            editorOptions: {}
          }
        ]
      })
    }
  }

  // 4. Event Subscription
  function ChartPointLabelFormattingExtension(this: IExtension, dashboardControl: DashboardControl) {
    this.name = 'ChartPointLabelLegendFormattingExtension'
    this.start = function () {
      let viewerApiExtension = dashboardControl.findExtension('viewerApi') as ViewerApiExtension
      if (viewerApiExtension) {
        viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
      }
      let bindingPanelExtension = dashboardControl.findExtension('itemBindingPanel') as BindingPanelExtension
      if (bindingPanelExtension) {
        bindingPanelExtension.on('customizeDataItemContainerSections', onCustomizeSections)
      }
    }
    this.stop = function () {
      let viewerApiExtension = dashboardControl.findExtension('viewerApi') as ViewerApiExtension
      if (viewerApiExtension) {
        viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
      }
      let bindingPanelExtension = dashboardControl.findExtension('itemBindingPanel') as BindingPanelExtension
      if (bindingPanelExtension) {
        bindingPanelExtension.off('customizeDataItemContainerSections', onCustomizeSections)
      }
    }
  }
  return ChartPointLabelFormattingExtension as CustomExtension
})()

export default ChartPointLabelFormattingExtension
