import { CircularProgress } from '@mui/material'
import MuiButton, { ButtonProps as ButtonBaseProps } from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

interface ButtonProps extends ButtonBaseProps {
  label: string
  isDelete?: boolean
  loading?: boolean
}

const useStyles = makeStyles((theme) => ({
  delete: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  label,
  variant = 'contained',
  color = 'primary',
  isDelete,
  loading,
  ...props
}) => {
  const classes = useStyles()
  return (
    <MuiButton
      className={clsx({ [classes.delete]: isDelete }, props.className)}
      color={color}
      variant={variant}
      endIcon={loading && <CircularProgress color="inherit" size={20} />}
      disabled={loading}
      {...props}
    >
      {label}
    </MuiButton>
  )
}

export default Button
