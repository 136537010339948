import { getDefaultKeyBinding } from 'draft-js'

export enum keyBindingFunctionKey {
  OPEN_SUGGESTIONS = 'open_suggestions',
  CLOSE_SUGGESTIONS = 'close_suggestions',
  SUGGESTIONS_MOVE_DOWN = 'suggestions_move_down',
  SUGGESTIONS_MOVE_UP = 'suggestions_move_up',
  SUGGESTIONS_SELECT = 'suggestions_select'
}

export const keyBindingFn =
  (suggestionsAreOpen: boolean) =>
  (
    e: React.KeyboardEvent<{}> & { code: string }
  ): (keyBindingFunctionKey | ReturnType<typeof getDefaultKeyBinding>) | null => {
    if (e.ctrlKey && e.key === ' ' && !suggestionsAreOpen) {
      return keyBindingFunctionKey.OPEN_SUGGESTIONS
    }
    if (e.key === 'Escape' && suggestionsAreOpen) {
      return keyBindingFunctionKey.CLOSE_SUGGESTIONS
    }
    if (e.key === 'ArrowDown' && suggestionsAreOpen) {
      return keyBindingFunctionKey.SUGGESTIONS_MOVE_DOWN
    }
    if (e.key === 'ArrowUp' && suggestionsAreOpen) {
      return keyBindingFunctionKey.SUGGESTIONS_MOVE_UP
    }
    if (e.key === 'Enter' && suggestionsAreOpen) {
      return keyBindingFunctionKey.SUGGESTIONS_SELECT
    }
    return getDefaultKeyBinding(e)
  }
