import { Warning } from '@mui/icons-material'
import { Box, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Button from '../../../../../components/Button'
import Form, { FormProps } from '../../../../../components/Form'
import OutlinedTextField from '../../../../../components/Form/OutlinedTextField'
import { ENDPOINTS } from '../../../../../constants/apiEndpoints'
import { DatabaseConnectionSchema } from '../../../../../schemas/ConnectionSchema'
import { connectionService } from '../../../../../services'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    minWidth: 350
  },
  gridItemSpan2: {
    gridColumn: '1 / span 2'
  },
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#d62b2b'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  whiteButton: {
    color: 'white'
  },
  selectAccountBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'white'
  }
}))

type FacebookConnectionFormProps = Partial<FormProps<Omit<DatabaseConnectionSchema, 'type'>>> & {
  variant?: 'white' | 'default'
  usePlaceholder?: boolean
  onCancelClick: () => void
  isUpdating?: boolean
}

const validationSchema = yup.object({
  name: yup.string().required()
})

const updateValidationSchema = yup.object({
  name: yup.string().required()
})

const defaultInitialValues = {
  name: ''
}

type FacebookMeResponse = {
  id: string
  name: string
}

const scopes = 'email,public_profile,ads_read,business_management,pages_show_list'

const FacebookConnectionForm: React.FC<PropsWithChildren<FacebookConnectionFormProps>> = ({
  className,
  variant = 'default',
  usePlaceholder,
  initialValues,
  isUpdating,
  onCancelClick,
  onSubmit,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selectedAccount, setSelectedAccount] = useState<FacebookMeResponse | null>(null)
  const [authResponse, setAuthResponse] = useState<fb.AuthResponse | null>(null)
  const [errorMsg, setErrorMsg] = useState('')

  const { enqueueSnackbar } = useSnackbar()

  const handleCreateConnection = async (values: any) => {
    values.apiSettings = { ...authResponse, account: selectedAccount, scopes }
    if (!authResponse) {
      enqueueSnackbar('At least one account must be selected', { variant: 'error' })
      return
    }
    onSubmit?.(values)
  }

  const handleLogin = () => {
    FB.login(
      function (response) {
        console.log(response)
        if (response.status !== 'connected') {
          enqueueSnackbar('You must concede permissions for create your connection')
          return
        }
        FB.api('me', (resp: any) => {
          setSelectedAccount(resp)
        })
        setAuthResponse(response.authResponse)
      },
      { scope: scopes }
    )
  }

  useEffect(() => {
    ;(async () => {
      if (isUpdating) {
        const { data } = await connectionService.getById(initialValues!.id, true)
        const dataAny = data as any
        setAuthResponse(dataAny.apiSettings)
        const { data: me } = await axios.get(
          `https://graph.facebook.com/v14.0/me?access_token=${dataAny.apiSettings.LongLivedAccessToken}`
        )
        setSelectedAccount(me)
      }
    })()
  }, [initialValues, isUpdating])

  const handleCancel = () => {
    setSelectedAccount(null)
    setAuthResponse(null)
    onCancelClick()
  }

  return (
    <Form
      initialTouched
      className={clsx(classes.root, className)}
      initialValues={initialValues || defaultInitialValues}
      validationSchema={isUpdating ? updateValidationSchema : validationSchema}
      isSubmitting={rest.isSubmitting}
      onSubmit={handleCreateConnection}
    >
      <OutlinedTextField
        required
        fullWidth
        className={clsx(classes.gridItemSpan2, { [classes.outlinedWhiteInput]: variant === 'white' })}
        name="name"
        label={!usePlaceholder ? 'Name' : undefined}
        placeholder={usePlaceholder ? 'Connection Name*' : undefined}
      />
      <Paper
        sx={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          height: 52,
          alignItems: 'center',
          bgcolor: 'white'
        }}
        elevation={3}
        className={classes.gridItemSpan2}
        onClick={handleLogin}
      >
        <Box flex="0.4 1 0" display="flex" justifyContent="center" alignItems="center">
          <img
            style={{
              alignSelf: 'start',
              width: 28
            }}
            src={ENDPOINTS.PUBLIC_IMAGES + '/f_logo_RGB-Blue_58.png'}
            alt="facebook f logo"
          />
        </Box>
        <Typography flex="2 2 0" textAlign="center" color="GrayText">
          {!selectedAccount ? 'Sign In With Facebook' : selectedAccount.name}
        </Typography>
      </Paper>
      {!!errorMsg && (
        <Box>
          <Warning color="error" />
          <Typography color="error">Status: {errorMsg}</Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" className={classes.gridItemSpan2}>
        <Button
          className={classes.whiteButton}
          variant="text"
          label={t('pagePlugs.modalCreate.createConnection.btnCancel')}
          onClick={handleCancel}
        />
        <Button
          type="submit"
          className={classes.whiteButton}
          variant="text"
          label={t('pagePlugs.modalCreate.createConnection.btnSave')}
        />
      </Box>
    </Form>
  )
}

export default FacebookConnectionForm
