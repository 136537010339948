/* eslint-disable @typescript-eslint/no-unused-vars */
import { OutlinedTextFieldProps as MuiOutlinedTextFieldProps, TextField as MuiTextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { memo } from 'react'
import { FieldProps, FieldPropValues } from './types'
import { customFieldComparable } from './utils'

interface OutlinedTextFieldProps<TValues>
  extends Omit<MuiOutlinedTextFieldProps, 'variant'>,
    Partial<FieldProps<TValues>> {
  name: string
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary
  }
}))

const accessByString = function (o: any, s: string) {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  var a = s.split('.')
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

const OutlinedTextField = <Value extends FieldPropValues>({
  values,
  handleChange,
  handleBlur,
  touched,
  required,
  errors,
  name,
  placeholder,
  label,
  className,
  initialErrors,
  initialStatus,
  initialTouched,
  initialValues,
  ...rest
}: OutlinedTextFieldProps<Value>): React.ReactElement => {
  const classes = useStyles()
  const displayError = errors && touched?.[name] && Boolean(errors[name])
  return (
    <MuiTextField
      className={className}
      InputLabelProps={{ className: classes.label }}
      fullWidth
      required={required}
      placeholder={placeholder}
      variant="outlined"
      id={name}
      name={name}
      label={label}
      value={accessByString(values, name)}
      onChange={handleChange}
      onBlur={handleBlur}
      error={displayError}
      helperText={displayError && !!errors && (errors[name] as any)}
      {...rest}
    />
  )
}

export default memo(OutlinedTextField, customFieldComparable)
