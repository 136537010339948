import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren } from 'react'

interface DialogTitleContentProps {
  title?: string
  description?: string
  onClose?(
    event: {} | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ): void
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(0, 6)
  },
  title: {
    fontSize: '1.5rem'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2)
  },
  description: {}
}))

const DialogTitleContent: React.FC<PropsWithChildren<DialogTitleContentProps>> = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.headerContainer}>
      <Typography className={classes.title} variant="h2" align="center">
        {props.title}
      </Typography>
      <Typography className={classes.description} variant="subtitle1" align="center">
        {props.description}
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose} size="large">
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default DialogTitleContent
