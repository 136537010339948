import { Theme, useMediaQuery } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import FormController from '../../components/FormController'
import WarningContainer from '../../components/WarningContainer'
import { ConnectionSchema } from '../../schemas/ConnectionSchema'
import { connectionService } from '../../services'
import { instanceOfAxiosError } from '../../services/api'

interface UpdateConnectionDialogProps {
  onClose?(refresh?: boolean): void
  open: boolean
  connection: ConnectionSchema
}

const updateValidationSchema = yup.object({
  name: yup.string().required(),
  host: yup.string().required(),
  database: yup.string().required(),
  port: yup.number().min(0).max(65535).optional().nullable(),
  username: yup.string().required(),
  password: yup.string().min(6).optional().nullable()
})

const UpdateConnectionDialog: React.FC<PropsWithChildren<UpdateConnectionDialogProps>> = ({
  open,
  onClose,
  connection
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [deleteErrorBag, setDeleteErrorBag] = useState<{ message: string; plugNames: string[] } | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiDelete, setLoadingApiDelete] = useState(false)
  const { t } = useTranslation()
  const dltBtnText = t('pageConnections.modalEdit.buttonDelete')
  const [deleteButtonText, setDeleteButtonText] = useState(dltBtnText)
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const handleConnectionUpdate = async (upConnection: any) => {
    try {
      setLoadingApi(true)
      upConnection.id = connection.id
      await connectionService.update(upConnection)
      onClose?.(true)
      enqueueSnackbar(t('pageConnections.modalEdit.toast.success.update'), { variant: 'success' })
    } catch (err: any) {
      console.error(err)
      if (instanceOfAxiosError(err)) {
        enqueueSnackbar(err.response?.data?.[0].errorMessage || err.message, { variant: 'error' })
      } else enqueueSnackbar(t('pageConnections.modalEdit.toast.error.update'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const handleConnectionDelete = async () => {
    try {
      if (!handleEnsureDeleteConfirmed()) return
      setLoadingApiDelete(true)
      await connectionService._delete(connection.id)
      onClose?.(true)
      enqueueSnackbar(t('pageConnections.modalEdit.toast.success.delete', { name: connection.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      if (instanceOfAxiosError(err)) {
        const connectionDeleteError = err.response?.data as
          | {
              errors: Error[]
              dependentPlugs: string[]
            }
          | undefined

        setDeleteErrorBag({
          message: connectionDeleteError!.errors[0]?.message || 'unknow error',
          plugNames: connectionDeleteError!.dependentPlugs
        })
      } else enqueueSnackbar(t('pageConnections.modalEdit.toast.error.delete'), { variant: 'error' })
    }
    setLoadingApiDelete(false)
  }

  const handleEnsureDeleteConfirmed = () => {
    if (deleteButtonText === t('pageConnections.modalEdit.buttonDelete')) {
      setDeleteButtonText(t('pageConnections.modalEdit.buttonConfirmDelete')!)
      return false
    }
    return true
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    setTimeout(() => setIsSubmitting(false))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen
      open={open}
      title={t('pageConnections.modalEdit.title')}
      description={t('pageConnections.modalEdit.subtitle')}
      onClose={() => onClose?.()}
    >
      <DialogContent dividers>
        <FormController
          isSubmitting={isSubmitting}
          formId={connection.type}
          initialValues={connection}
          onSubmit={handleConnectionUpdate}
          isUpdating
          validationSchema={updateValidationSchema}
        />
        {deleteErrorBag && (
          <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.plugNames} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConnectionDelete} label={deleteButtonText} isDelete loading={loadingApiDelete} />
        <Button
          style={{ marginLeft: 'auto' }}
          isDelete
          onClick={() => onClose?.()}
          label={t('pagePlugs.modalEdit.cancel')}
        />
        <Button onClick={handleSubmit} label={t('pageConnections.modalEdit.buttonSave')} loading={loadingApi} />
      </DialogActions>
    </Dialog>
  )
}

export default UpdateConnectionDialog
