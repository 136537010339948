import React, { PropsWithChildren, useState } from 'react'
import { Document } from 'react-pdf/dist/esm/entry.webpack'
import { useLocation } from 'react-router-dom'
import DocumentError from './DocumentError'
import DocumentLoading from './DocumentLoading'
import ViewerView from './ViewerView'

type DocumentType = InstanceType<typeof Document>

type DocumentProps = DocumentType['props']

const ViewerContainer: React.FC<PropsWithChildren> = () => {
  const [error, setError] = useState<Error>()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [currentPage, setCurrentPage] = useState(1)
  const [numPages, setNumPages] = useState<number>(0)

  const handleLoadSuccess: DocumentProps['onLoadSuccess'] = (pdf) => {
    setNumPages(pdf.numPages)
  }

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
  }

  const handleLoadError = (error: Error) => {
    setError(error)
  }

  return (
    <ViewerView
      file={searchParams.get('file')}
      onLoadSuccess={handleLoadSuccess}
      onLoadError={handleLoadError}
      numPages={numPages}
      pageNumber={currentPage}
      onPageChange={handlePageChange}
      loading={<DocumentLoading />}
      error={<DocumentError {...error} />}
    />
  )
}

export default ViewerContainer
