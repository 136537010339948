import axios from "axios"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../api"
import { GoogleScope, GoogleUserInfo } from "./types"

const CLIENT_ID = '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com'

export type useGoogleProps = {
    scopes?: GoogleScope[]
}

export type useGoogleResponseAuthorized = {
    isLoading: false
    isAuthorized: true
    userInfo: GoogleUserInfo
    encryptedTokenResponse: string
}

export type useGoogleResponseNotAuthorized = {
    isLoading: boolean
    isAuthorized: false
    userInfo: null
    encryptedTokenResponse: null
}
export type useGoogleResponseLoading = {
    isLoading: true
    isAuthorized: boolean
    userInfo: null
    encryptedTokenResponse: null
}

export type useGoogleResponse = {
    authorize: () => void
    error: string | null
} & (useGoogleResponseAuthorized | useGoogleResponseNotAuthorized | useGoogleResponseLoading)


export type AuthorizeResponse = {
    encryptedTokenResponse: string
    userInfo: GoogleUserInfo
}

const commonScopes = [GoogleScope.USERINFO_EMAIL, GoogleScope.USERINFO_PROFILE, GoogleScope.OPENID]

export function useGoogle({scopes = []}: useGoogleProps): useGoogleResponse{

    const [authorizeResponse, setAuthorizeResponse] = useState<AuthorizeResponse | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation()

    const codeClient = useMemo(() => google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: [...commonScopes, ...scopes].join(' '), // email openid and profile also included
        ux_mode: 'popup',
        callback: async (response) => {
            try{
                const authorizedScopes = response.scope.split(' ')
                const missingScopes = scopes.filter(scope => !authorizedScopes.includes(scope))
                
                if(missingScopes.length > 0){
                    setError(t('useGoogle.missingScopes', {missingScopes: missingScopes.join(', ')}) )
                    setIsLoading(false)
                    return
                }

                const { data } = await api.post('/google-apis/authorize', {oAuth2Code: response.code})
                setAuthorizeResponse(data)
                setError(null)
            }catch(err){
                let error;
                if(axios.isAxiosError(err)){
                    error = err.message
                }else{
                    error = (err as Error).message
                }
                setError(error)
            }
            setIsLoading(false)
        },
        error_callback(error) {
            if(error.type === 'popup_closed')
            {
                setError(null)
            }
            else
            {
                setError(error.message)
            }
            setIsLoading(false)            
        },
    }), [scopes, t])

    const authorize = useCallback(() => {
        setIsLoading(true)
        codeClient.requestCode()
    }  ,[codeClient])

    const isAuthorized = !!authorizeResponse

    return {
        authorize: authorize,
        isLoading,
        userInfo: (isAuthorized && !isLoading ) ? authorizeResponse.userInfo : null,
        isAuthorized: isAuthorized,
        error,
        encryptedTokenResponse: (isAuthorized && !isLoading ) ? authorizeResponse.encryptedTokenResponse : null
    } as useGoogleResponse
}