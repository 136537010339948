import React, { PropsWithChildren, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AppLayout from './components/AppLayout'
import PATHS from './constants/paths'
import { useLocaleDetector } from './hooks'
import useAuth from './hooks/useAuth'
import { useCurrentPath } from './hooks/useCurrentPath'
import { useMovideskChat } from './hooks/useMovideskChat'
import { loadLocale } from './locales'
import routes from './routes'

const RequireAuth: any = (props: any) => {
  const auth = useAuth()
  const currentPath = useCurrentPath()

  useEffect(() => {
    if (auth.validateAuth()) {
      const userInfo = auth.getUserInfo()
      const accountInfo = auth.getAccountInfo()
      const userFullName = userInfo?.userFirstName + ' ' + userInfo?.userLastName

      dataLayer.push({
        event: 'on_navigate_authenticated',
        userId: userInfo?.userId,
        userEmail: userInfo?.userEmail,
        userFullName: userFullName,
        accountName: accountInfo?.companyName,
        accountId: accountInfo?.accountId
      })
    }
  }, [auth])

  return auth.validateAuth() ? (
    props.children
  ) : (
    <Navigate
      to={{ pathname: PATHS.SIGNIN }}
      state={{
        returnInfo: {
          url: currentPath,
          accountId: auth.getAccountInfo()?.accountId
        }
      }}
    />
  )
}

const NoLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { hide } = useMovideskChat()

  useEffect(() => {
    hide()
  }, [hide])

  return <>{children}</>
}

const App: React.FC<PropsWithChildren> = () => {
  const locale = useLocaleDetector()

  useEffect(() => {
    loadLocale(locale)
  }, [locale])

  const appRoutes = routes.filter((r) => r.layout === 'AppLayout')
  const withoutLayoutRoutes = routes.filter((r) => !r.layout)

  return (
    <Routes>
      {withoutLayoutRoutes.map((route) => (
        <Route
          key={route.path}
          element={
            <NoLayout>
              {route.isPrivate ? (
                <RequireAuth>
                  <route.component />
                </RequireAuth>
              ) : (
                <route.component />
              )}
            </NoLayout>
          }
          path={route.path}
        >
          {route.childrens}
        </Route>
      ))}

      <Route element={<AppLayout />}>
        {appRoutes.map((route) => (
          <Route
            key={route.path}
            element={
              route.isPrivate ? (
                <RequireAuth>
                  <route.component />
                </RequireAuth>
              ) : (
                <route.component />
              )
            }
            path={route.path}
          >
            {route.childrens}
          </Route>
        ))}
      </Route>
    </Routes>
  )
}

export default App
