import React, { memo } from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { FieldProps, FieldPropValues } from './types'
import { customFieldComparable } from './utils'

import SelectBox, { SelectBoxProps } from '../../components/SelectBox'

interface SelectFieldProps<
  TValues,
  T,
  M extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<SelectBoxProps<T, M, DisableClearable, FreeSolo>, 'textFieldProps'>,
    Partial<Omit<FieldProps<TValues>, 'values'>> {
  name: string
  required?: boolean
  label: string
  values?: { [key: string]: any }
  getOptionLabel(opt: any): string
  getOptionSelected?(opt: any, value: any): boolean
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary
  }
}))

const SelectField = <
  Value extends FieldPropValues,
  T,
  M extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  values,
  handleBlur,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  touched,
  required,
  errors,
  name,
  placeholder,
  label,
  className,
  options,
  setFieldValue,
  ...rest
}: SelectFieldProps<Value, T, M, DisableClearable, FreeSolo>): React.ReactElement => {
  const classes = useStyles()
  const displayError = errors && Boolean(errors[name])
  const handleChange = (e: React.ChangeEvent<any>, value: any) => {
    setFieldValue?.(name, value)
  }
  return (
    <SelectBox
      options={options}
      className={className}
      textFieldProps={{
        className: classes.label,
        required: required,
        name: name,
        label: label,
        error: displayError,
        helperText: (displayError && errors && (errors[name] as any)) || ''
      }}
      placeholder={placeholder}
      id={name}
      value={values?.[name] || null}
      onBlur={handleBlur}
      onChange={handleChange}
      {...rest}
    />
  )
}
export default memo(SelectField, (prev, next) => {
  const baseCompare = customFieldComparable(prev, next)
  return baseCompare && JSON.stringify(prev.options) === JSON.stringify(next.options)
})
