import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import { PlugFieldSchema, PlugFieldType, PlugType } from '../../../../../schemas/PlugSchema'
import { plugService } from '../../../../../services'
import csvService from '../../../../../services/csvService'
import { CreateCsvPlugRequest } from '../../../../../services/plugService'
import { FlowComponentBaseProps } from '../../types'
import FieldsGrid from '../FieldsGrid'
import FilterView from '../FilterView'
import CsvFilePlugForm, { CsvFilePlugFormValues } from './CsvFilePlugForm'

const CsvFileFlow: React.FC<PropsWithChildren<FlowComponentBaseProps>> = ({ plug, onBackClick, onClose }) => {
  const [index, setIndex] = useState(0)
  const [plugFormValues, setPlugFormValues] = useState<CsvFilePlugFormValues | null>(null)
  const [fields, setFields] = useState<PlugFieldSchema[]>([])
  const [filterValue, setFilterValue] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [loadingFetchFields, setLoadingFetchFields] = useState(false)

  const goNextPage = () => {
    setIndex((prev) => prev + 1)
  }

  const goBackPage = () => {
    setIndex((prev) => prev - 1)
  }

  const getPlugFieldType = (type: string): PlugFieldType => {
    switch (type) {
      case 'Number':
        return 'NUMERIC'
      case 'DateOnly':
      case 'TimeOnly':
      case 'DateTime':
        return 'DATE'
      case 'Boolean':
        return 'BOOLEAN'
      case 'String':
      default:
        return 'STRING'
    }
  }

  const handleFetchFields = async (values: CsvFilePlugFormValues) => {
    try {
      setLoadingFetchFields(true)
      const response = await csvService.detectColumns(values)
      setFields(
        response.data.columnsMetadata.map((e) => ({
          type: e.type,
          label: e.label,
          name: e.name
        }))
      )
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]?.message || err.request?.response || err.message
        console.error(firstError)
        enqueueSnackbar(firstError, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.getFields'), { variant: 'error' })
    } finally {
      setLoadingFetchFields(false)
    }
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    if (changedFields) {
      const prevFields = [...fields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setFields(prevFields)
    }
  }

  const handleClose = () => {
    onClose('Exited')
  }

  const handleCreatePlug = async () => {
    const newPlug: CreateCsvPlugRequest = {
      name: plugFormValues!.plugName,
      fileUrl: plugFormValues!.fileUrl,
      delimiter: plugFormValues!.delimiter,
      fields: fields,
      filter: filterValue
    }
    try {
      await plugService.createCsv(newPlug)
      onClose('EndFlow')
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createPlug', { name: newPlug.name }), {
        variant: 'success'
      })
      dataLayer.push({
        event: 'on_plug_created',
        plugType: PlugType.CsvFile
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleSubmitPlugForm = (values: CsvFilePlugFormValues) => {
    setPlugFormValues(values)
    goNextPage()
    handleFetchFields(values)
  }

  return (
    <Box>
      <SwipeableViews index={index} animateHeight>
        <Box
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <CsvFilePlugForm
            onSubmit={handleSubmitPlugForm}
            navigationProps={{ onNextClick: goNextPage, onClose: handleClose, onBackClick: onBackClick }}
          />
        </Box>
        <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <FieldsGrid
            loading={loadingFetchFields}
            fields={fields}
            onChange={handleFieldChanges}
            navigationProps={{
              onBackClick: goBackPage,
              onNextClick: goNextPage,
              onClose: handleClose
            }}
          />
        </Box>
        <Box
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <FilterView
            fields={fields}
            value={filterValue}
            onChange={(val) => setFilterValue(val)}
            navigationProps={{
              onBackClick: goBackPage,
              onClose: handleClose,
              onNextClick: handleCreatePlug,
              nextDisabled: true
            }}
          />
        </Box>
      </SwipeableViews>
    </Box>
  )
}

export default CsvFileFlow
