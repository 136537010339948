import { useContext } from 'react'

import { AuthContext } from '../../providers/AuthProvider'
import { AuthProviderProps } from '../../providers/AuthProvider/types'

const useAuth = (): AuthProviderProps => {
  return useContext(AuthContext)
}

export default useAuth
