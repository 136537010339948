import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'
import useAuth from '../useAuth'
import { useReturnUrl } from '../useReturnUrl'

export const useCheckAnotherAccount = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const accountInfo = auth.getAccountInfo()!
  const { generateReturnUrl } = useReturnUrl()

  const check = useCallback(
    async (analysis: AnalyzeSchema) => {
      if (accountInfo.accountId !== analysis.accountId) {
        const returnUrl = generateReturnUrl(analysis.accountId)
        navigate(returnUrl)
      }
    },
    [accountInfo.accountId, generateReturnUrl, navigate]
  )

  return { check }
}
