import i18n from '../../../locales/i18next'
import { Keyword, KeywordGroup, KeywordType } from '../types'


const Type = KeywordType.Operator

const operators: Keyword[] = [
  {
    name: '+',
    description: i18n.t('expressionBuilder.operators.plus.description'),
    group: KeywordGroup.NONE,
    type: Type
  },
  {
    name: '-',
    description: i18n.t('expressionBuilder.operators.minus.description'),
    group: KeywordGroup.NONE,
    type: Type
  },
  {
    name: '*',
    description: i18n.t('expressionBuilder.operators.multiply.description'),
    group: KeywordGroup.NONE,
    type: Type
  },
  {
    name: '/',
    description: i18n.t('expressionBuilder.operators.divide.description'),
    group: KeywordGroup.NONE,
    type: Type
  }
  // {
  //   name: '%',
  //   description: 'modulus',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '==',
  //   description: 'equals',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '!=',
  //   description: 'not equals',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '>',
  //   description: 'larger',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '<',
  //   description: 'smaller',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '>=',
  //   description: 'larger or equals',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '<=',
  //   description: 'smaller or equals',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: 'and',
  //   description: 'and',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: 'or',
  //   description: 'or',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // },
  // {
  //   name: '? :',
  //   description: 'Conditional expression',
  //   group: KeywordGroup.NONE,
  //   type: Type
  // }
]

export default operators
