import React from 'react'

import { DataTypeProvider } from '@devexpress/dx-react-grid'

interface LowerCaseStringTypeProviderProps {
  for: string[]
}

const LowerCaseStringFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => value.toLocaleLowerCase()

const LowerCaseStringTypeProvider: React.FunctionComponent<LowerCaseStringTypeProviderProps> = (props) => {
  return <DataTypeProvider formatterComponent={LowerCaseStringFormatter} {...props} />
}

export default LowerCaseStringTypeProvider
