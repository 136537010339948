import { AxiosResponse } from 'axios'
import { IFieldChooserProps } from 'devextreme-react/pivot-grid'
import CustomStore from 'devextreme/data/custom_store'
import { exportPivotGrid } from 'devextreme/excel_exporter'
import dxPivotGrid, { dxPivotGridSummaryCell, ExportingEvent } from 'devextreme/ui/pivot_grid'
import PivotGridDataSource, {
  PivotGridDataSourceField,
  PivotGridDataSourceOptions
} from 'devextreme/ui/pivot_grid/data_source'
import { Buffer, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { AnalyzeSchema, AnalyzeType } from '../../schemas/AnalyzeSchema'
import { PlugFieldSchema, PlugFieldType } from '../../schemas/PlugSchema'
import api from '../../services/api'
import { analyzeService, plugService } from '../../services/index'
import { format } from '../../utils/stringUtils'
import { getCustomSummaryFunction, isCustomSummary } from './extensions/FunctionsExtension'
import {
  PivotGridConfig,
  PivotGridField,
  PivotGridFontSize,
  RemoteDataSourceProps,
  setPivotGridConfigOption
} from './types'

const combineFieldState = (plugFields: PlugFieldSchema[], stateFields: PivotGridField[]): PivotGridField[] => {
  plugFields.forEach((pf) => {
    pf.name = pf.name.trim()
    pf.label = pf.label.trim()
  })
  for (const stateField of stateFields) {
    delete stateField._initProperties
    stateField.name = stateField.name?.trim()
    stateField.sortingMethod = defaultFieldSorting
    stateField.caption = stateField.caption?.trim()
    const plugField = plugFields.find((pf) => pf.name === stateField.dataField)
    if (stateField.customFieldExpression) {
      continue
    }
    if (plugField) {
      // field was not removed
      const plugFieldType = convertFieldType(plugField.type)
      if (!stateField.renamed) {
        stateField.caption = plugField.label
      }
      if (!!stateField.customFormat) {
        continue
      }
      if (plugFieldType !== stateField.dataType) {
        // field type was changed
        stateField.dataType = plugFieldType
        stateField.summaryType = getDefaultSummaryType(plugField.type)
        stateField.format = getDefaultFormatByType(plugField.type)
      }
    } else {
      stateFields.splice(stateFields.indexOf(stateField), 1)
    }
  }
  const notContainedPlugFields = plugFields.filter((pf) => !stateFields.some((sf) => sf.dataField === pf.name))
  return [...stateFields, ...prepareFieldsConfiguration(notContainedPlugFields)]
}

const fieldChooserOpts: IFieldChooserProps = {
  enabled: true,
  allowSearch: true,
  applyChangesMode: 'onDemand'
}

const findNestedChildrenExists = (rows: any[], currentLevel = 0, deepLevel: number): boolean => {
  let children = rows.filter((x) => x.children).map((x) => x.children)
  if (children.length === 0 || currentLevel + 1 > deepLevel) {
    return currentLevel === deepLevel
  }
  return findNestedChildrenExists(children, currentLevel + 1, deepLevel)
}

export const exportPivotToBuffer = async (fileName: string | undefined, component: dxPivotGrid): Promise<Buffer> => {
  const workbook = new Workbook()
  const desired = fileName?.replace(/[^\w\s]/gi, '')
  const worksheet = workbook.addWorksheet(desired)

  await exportPivotGrid({
    component: component,
    worksheet,
    topLeftCell: { row: 1, column: 1 }
  })
  //add caption of last datafield on rows as row header
  const headerRow = worksheet.getRow(1)

  const rowAreaFields = component.getDataSource().getAreaFields('row', true)
  worksheet.unMergeCells('A:1')
  const data = component.getDataSource().getData()

  for (let i = 1; i <= rowAreaFields.length; i++) {
    const isExpansedField = findNestedChildrenExists(data.rows, 1, i)
    if (!isExpansedField) {
      continue
    }
    let headerRowCell = headerRow.getCell(i)
    let rowFieldCaption = rowAreaFields[i - 1]?.caption || ''

    headerRowCell.value = rowFieldCaption
  }

  return workbook.xlsx.writeBuffer()
}

const handleExporting = (e: ExportingEvent): void => {
  let dataAreaFields = e.component.getDataSource().getAreaFields('data', false)
  const clonedOriginalAreaFields = dataAreaFields.map((x) => ({ ...x }))
  for (let dataAreaField of dataAreaFields) {
    dataAreaField.dataType = 'number'
  }
  exportPivotToBuffer(e.fileName, e.component)
    .then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), e.fileName + '.xlsx')
    })
    .then(() => {
      for (let dataAreaField of dataAreaFields) {
        dataAreaField.dataType = clonedOriginalAreaFields.find(
          (x) => x.dataField === dataAreaField.dataField && x.areaIndex === dataAreaField.areaIndex
        )?.dataType
      }
    })

  e.cancel = true
}

const defaultPivotGridConfig: PivotGridConfig = {
  allowExpandAll: true,
  allowFiltering: true,
  allowSorting: true,
  allowSortingBySummary: true,
  export: {
    enabled: true,
    fileName: 'New Pivot Grid'
  },
  onExporting: handleExporting,
  fieldChooser: fieldChooserOpts,
  fieldPanel: {
    allowFieldDragging: true,
    showColumnFields: true,
    showDataFields: true,
    showFilterFields: true,
    showRowFields: true,
    visible: true
  },
  headerFilter: {
    allowSearch: true,
    showRelevantValues: true
  },
  // showColumnGrandTotals: true,
  // showColumnTotals: true,
  // showRowGrandTotals: true,
  // showRowTotals: true,
  wordWrapEnabled: false,
  fontSize: PivotGridFontSize.Medium,
  showTitle: false
}

export class RemoteDataSource extends PivotGridDataSource implements RemoteDataSourceProps {
  plugId: number
  pivotGridConfig: PivotGridConfig = defaultPivotGridConfig
  analyzeId?: string
  constructor(plugId: number, options: PivotGridDataSourceOptions, analyzeId?: string) {
    super(options)
    this.plugId = plugId
    this.analyzeId = analyzeId
  }
  getMergedState(): any {
    const stateFields = this.fields()
    const stateBase = this.state()
    stateBase.fields = stateFields
    stateBase.pivotGridConfig = this.pivotGridConfig
    return stateBase
  }
  async loadFields(): Promise<void> {
    const fields = await plugService.getFields(this.plugId!, true)
    super.fields(prepareFieldsConfiguration(fields.data))
  }
  async loadState(state?: { fields: PivotGridField[]; pivotGridConfig: PivotGridConfig }): Promise<void> {
    if (!state) {
      await this.loadFields()
      return
    }
    const plugFieldsResp = await plugService.getFields(this.plugId, true)
    configureFieldsFunctions(state.fields)
    const combinedFields = combineFieldState(plugFieldsResp.data, state.fields)
    this.pivotGridConfig = state.pivotGridConfig
    this.pivotGridConfig.onExporting = handleExporting
    state.fields = combinedFields
    super.fields(combinedFields)
    super.state(state)
  }

  async saveNew(analyzeName: string): Promise<AxiosResponse<AnalyzeSchema>> {
    return await analyzeService.create({
      name: analyzeName,
      type: AnalyzeType.PIVOTGRID,
      jsonState: this.getMergedState(),
      plugIds: [this.plugId!]
    })
  }
  async save(analyzeId: string): Promise<AxiosResponse<void>> {
    return await analyzeService.partialUpdate(analyzeId as string, {
      id: analyzeId,
      jsonState: this.getMergedState()
    })
  }
  setConfigOption: setPivotGridConfigOption = (option, value) => {
    this.pivotGridConfig = { ...this.pivotGridConfig, [option]: value }
  }
}

const convertFieldType = (type: PlugFieldType): PivotGridDataSourceField['dataType'] => {
  switch (type) {
    case 'BOOLEAN':
    case 'NUMERIC':
      return 'number'

    case 'DATE':
    case 'DATETIME':
      return 'date'

    case 'STRING':
      return 'string'

    default:
      return 'string'
  }
}

const getDefaultFormatByType = (type: PlugFieldType): PivotGridDataSourceField['format'] => {
  switch (type) {
    case 'BOOLEAN':
    case 'NUMERIC':
      return {
        type: 'fixedPoint',
        precision: 2
      }

    case 'DATE':
      return 'shortDate'
    case 'DATETIME':
      return 'shortDateShortTime'

    case 'STRING':
      return

    default:
      return
  }
}

const getDefaultSummaryType = (type: PlugFieldType): PivotGridDataSourceField['summaryType'] => {
  switch (type) {
    case 'BOOLEAN':
    case 'NUMERIC':
      return 'sum'

    case 'DATE':
    case 'DATETIME':
      return 'countDistinct'

    case 'STRING':
      return 'countDistinct'

    default:
      return 'countDistinct'
  }
}

export const defaultFieldSorting = (a: any, b: any): number => {
  if (!Number.isNaN(a)) {
    return a.value > b.value ? 1 : a.value < b.value ? -1 : 0
  }
  const c = Intl.Collator()
  return c.compare(a.value, b.value)
}
// used for new and saved pivotgrid's state
const prepareFieldsConfiguration = (fields: PlugFieldSchema[]): PivotGridField[] => {
  const preparedFields: PivotGridField[] = []
  for (const field of fields) {
    const defaultFormat = getDefaultFormatByType(field.type)
    const preparedField: PivotGridField = {
      allowExpandAll: true,
      showTotals: true,
      showGrandTotals: true,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      caption: field.label,
      dataField: field.name,
      dataType: convertFieldType(field.type),
      expanded: false,
      filterType: 'exclude',
      defaultFormat: defaultFormat,
      format: defaultFormat,
      plugType: field.type,
      headerFilter: {
        allowSearch: true
      },
      summaryType: getDefaultSummaryType(field.type),
      // showTotals: true,
      // showGrandTotals: true,
      wordWrapEnabled: false,
      width: 50,
      sortingMethod: defaultFieldSorting
    }
    preparedFields.push(preparedField)
  }
  return preparedFields
}

const handleConfigureCustomField = (x: any, customFields: PivotGridField[]) => {
  const customField = customFields.find((f) => f.dataField === x.selector) as PivotGridField
  if (customField) {
    x.expression = customField.customFieldExpression
  }
}

export const createDataSource = (
  plugId: number,
  analyzeId?: string,
  onError?: (error: any) => void
): InstanceType<typeof RemoteDataSource> => {
  const dataSource = new RemoteDataSource(
    plugId,
    {
      store: new CustomStore({
        load: async (loadOptions: any) => {
          const opts = {
            take: loadOptions.take,
            skip: loadOptions.skip,
            group: loadOptions.group,
            filter: loadOptions.filter,
            totalSummary: loadOptions.totalSummary,
            groupSummary: loadOptions.groupSummary
          }
          const customFields = dataSource
            .fields()
            .filter((x) => !!(x as PivotGridField).customFieldExpression) as PivotGridField[]
          if (customFields.length > 0) {
            loadOptions.group.forEach((x: any) => handleConfigureCustomField(x, customFields))
            loadOptions.groupSummary.forEach((x: any) => handleConfigureCustomField(x, customFields))
            loadOptions.totalSummary.forEach((x: any) => handleConfigureCustomField(x, customFields))
          }
          const response = await api.post(format(ENDPOINTS.LOAD_PIVOT_GRID, analyzeId ?? plugId), opts)
          return response.data
        },
        errorHandler: onError
      }),
      retrieveFields: false,
      remoteOperations: true
    },
    analyzeId
  )
  return dataSource
}
function configureFieldsFunctions(stateFields: PivotGridDataSourceField[]) {
  for (const field of stateFields) {
    if (isCustomSummary(field.summaryDisplayMode)) {
      field.calculateSummaryValue = getCustomSummaryFunction(field.summaryDisplayMode, null) as any
      continue
    }
    if (field.summaryDisplayMode === 'absoluteVariation') {
      // @ts-ignore
      field.calculateSummaryValue = (summaryCell: dxPivotGridSummaryCell) => {
        if (!summaryCell.prev('column')) return null
        let prevValue = summaryCell.prev('column').value() || 0
        let curValue = summaryCell.value() || 0
        return curValue - prevValue
      }
    }
  }
}

function configureSummaryFunctions(summaryType?: PivotGridField['summaryDisplayMode']) {
  if (isCustomSummary(summaryType)) {
    return getCustomSummaryFunction(summaryType, null)
  }
}
