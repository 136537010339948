import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { ENDPOINTS } from '../constants/apiEndpoints'
import fallbacken from './fallback-en.json'

export const loadI18n = (locale?: string) => {
  return i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      backend: {
        loadPath: ENDPOINTS.PUBLIC_IMAGES + `/locales/${locale}.json`,
        crossDomain: true
      },
      lng: locale,
      keySeparator: false,
      fallbackLng: 'en',
      partialBundledLanguages: true,
      resources: {
        en: {
          translation: fallbacken
        }
      },
      initImmediate: false,
      defaultNS: 'translation',
      load: 'languageOnly',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        format: (value, format, lng) => {
          const dateOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }

          if (format === 'date') return new Intl.DateTimeFormat(lng, dateOptions).format(new Date(value))
          if (format === 'currency') return new Intl.NumberFormat(lng, { style: 'currency' }).format(value)
          if (format === 'number') return new Intl.NumberFormat(lng).format(parseInt(value))
          return value
        },
        escapeValue: false
      }
    })
}

loadI18n()

export default i18n
