import { ChangeSet } from '@devexpress/dx-react-grid'
import { SettingsInputAntenna } from '@mui/icons-material'
import { Box, Divider, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import Button from '../../../../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../../../../components/Dialog'
import FilterBuilder from '../../../../../../components/FilterBuilder'
import TextField from '../../../../../../components/TextField'
import WarningContainer from '../../../../../../components/WarningContainer'
import { ApiConnectionType } from '../../../../../../schemas/ConnectionSchema'
import { PlugFieldSchema, PlugSchema, PlugType } from '../../../../../../schemas/PlugSchema'
import { plugService } from '../../../../../../services'
import GoogleAnalyticsPropertiesList from '../../../../CreateNewPlugDialog/components/Google/GoogleAnalyticsFlow/components/GoogleAnalyticsPropertiesList'
import PlugFieldsGrid from '../../../../PlugFieldsGrid'
import { UpdatePlugDialogPropsBase } from '../../../UpdatePlugDialogV2'
import { RelatedAnalysisDependecy } from '../../Common/UpdateDialog'

type FormValues = PlugSchema & { fields: PlugFieldSchema[] }

const defaultPlugValues: FormValues = {
  id: 0,
  name: '',
  connection: {
    name: '',
    id: 0,
    type: ApiConnectionType.GoogleApis,
    apiSettings: null,
    image: ''
  },
  lastLog: null,
  type: PlugType.GoogleAnalytics,
  tableBinding: '',
  fields: []
}

const GoogleAnalyticsUpdateDialog: React.FC<PropsWithChildren<UpdatePlugDialogPropsBase>> = ({
  plug,
  open,
  onClose
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [tabIndex, setTabIndex] = useState(0)
  const [deleteErrorBag, setDeleteErrorBag] = useState<{
    message: string
    dependents: RelatedAnalysisDependecy[]
  } | null>(null)
  const dltBtnText = t('pagePlugs.modalEdit.buttonDelete')
  const [deleteButtonText, setDeleteButtonText] = useState(dltBtnText)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingFields, setLoadingFields] = useState(false)

  const { values, errors, setFieldValue, setValues, submitForm } = useFormik({
    initialValues: !!plug ? { ...plug, fields: [] as PlugFieldSchema[] } : defaultPlugValues,
    onSubmit: handleUpdatePlug
  })

  const resetState = () => {
    setTabIndex(0)
    setDeleteErrorBag(null)
    setDeleteButtonText(dltBtnText)
    setLoadingDelete(false)
    setLoadingUpdate(false)
    setValues(defaultPlugValues)
  }

  const handleOnEnter = async () => {
    const loadFields = async () => {
      if (plug) {
        try {
          const { data } = await plugService.getFields(plug.id, true)
          setFieldValue('fields', data)
        } catch (err: any) {
          enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.loadingFields'), { variant: 'error' })
        }
      }
    }

    setValues(!!plug ? { ...plug, fields: [] as PlugFieldSchema[] } : defaultPlugValues)
    setLoadingFields(true)

    loadFields()

    setLoadingFields(false)
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    const plugFields = values['fields']
    if (changedFields) {
      const prevFields = [...plugFields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setFieldValue('fields', prevFields)
    }
  }

  const handleEnsureDeleteConfirmed = () => {
    if (deleteButtonText === t('pagePlugs.modalEdit.buttonDelete')) {
      setDeleteButtonText(t('pagePlugs.modalEdit.buttonConfirmDelete')!)
      return false
    }
    return true
  }

  const handleDeletePlug = async () => {
    if (!handleEnsureDeleteConfirmed()) return
    try {
      setLoadingDelete(true)
      await plugService._delete(plug!.id)
      onClose?.('Submited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugDelete', { name: plug!.name }), { variant: 'success' })
    } catch (err: any) {
      console.error(err)
      if (axios.isAxiosError(err)) {
        const plugDeleteError = err.response?.data as
          | {
              errors: Error[]
              dependents: RelatedAnalysisDependecy[]
            }
          | undefined

        setDeleteErrorBag({
          message: (plugDeleteError!.errors[0] as any).errorMessage,
          dependents: plugDeleteError!.dependents
        })
      } else {
        enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugDelete'), { variant: 'error' })
      }
    }
    setLoadingDelete(false)
  }

  async function handleUpdatePlug(formValues: FormValues) {
    try {
      setLoadingUpdate(true)
      await plugService.update(formValues.id, formValues)
      onClose?.('Submited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugUpdate', { name: formValues.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugUpdate'), { variant: 'error' })
    }
    setLoadingUpdate(false)
  }

  const combinedProperties = [...values?.apiSettings?.dimensions, ...values.apiSettings.metrics]

  const handleChangeProperties = (properties: PlugFieldSchema[]) => {
    const dimensions = properties.filter((x) => (x as any).isDimension)
    const metrics = properties.filter((x) => !(x as any).isDimension)

    setFieldValue('apiSettings.dimensions', dimensions)
    setFieldValue('apiSettings.metrics', metrics)
    const currentFields = values.fields
    for (const property of properties) {
      const existentField = currentFields.find((x) => x.name === property.name)
      if (!!existentField) {
        property.label = existentField.label
        continue
      }
    }
    setFieldValue('fields', properties)
  }

  return (
    <Dialog
      open={!!open}
      title={t('pagePlugs.modalEdit.title')}
      description={t('pagePlugs.modalEdit.subtitle')}
      onClose={() => onClose?.('Exited')}
      fullWidth
      maxWidth="xl"
      TransitionProps={{
        onEnter: handleOnEnter,
        onExited: resetState
      }}
      fullScreen
    >
      <DialogContent dividers>
        <Tabs indicatorColor="primary" centered value={tabIndex} onChange={(e, val) => setTabIndex(val)}>
          <Tab label={t('pagePlugs.modalEdit.buttonGeneral')} value={0} />
          <Tab label={t('pagePlugs.modalEdit.buttonFields')} value={1} />
          <Tab label={t('pagePlugs.modalEdit.buttonProperties')} value={2} />
          <Tab label={t('pagePlugs.modalEdit.buttonFilter')} value={3} />
        </Tabs>
        <SwipeableViews index={tabIndex} animateHeight>
          <Stack padding={2} spacing={3}>
            <TextField
              label={t('pagePlugs.modalEdit.name')}
              required
              value={values?.name || ''}
              error={Boolean(errors['name'])}
              helperText={errors['name']}
              onChange={(e) => setFieldValue('name', e.target.value)}
            />
            <TextField
              label={t('pagePlugs.modalEdit.googleAnalytics.account')}
              required
              disabled
              name="apiSettings.googleAnalytics.account"
              value={values?.apiSettings?.account?.displayName || ''}
            />
            <TextField
              label={t('pagePlugs.modalEdit.googleAnalytics.property')}
              required
              disabled
              name="apiSettings.spreadsheet"
              value={values?.apiSettings?.property?.displayName || ''}
            />
            <Box display="flex">
              <Tooltip title={t('modal.icon.tooltip.connection')}>
                <SettingsInputAntenna style={{ marginRight: '12px' }} />
              </Tooltip>
              <Typography color="textPrimary">{values.connection?.name}</Typography>
            </Box>
          </Stack>
          <Box>
            <PlugFieldsGrid loading={loadingFields} plugFields={values.fields} onCommitChanges={handleFieldChanges} />
          </Box>
          <GoogleAnalyticsPropertiesList
            connectionId={values.connection!.id}
            property={values.apiSettings.property.property}
            hideHeader
            selection={combinedProperties}
            onChange={handleChangeProperties}
          />
          <Box p={2} height="auto">
            <Typography>Filter</Typography>
            <Divider />

            <FilterBuilder
              value={values.filter}
              onValueChanged={(val) => setFieldValue('filter', val)}
              fields={values.fields}
              plugId={values.id}
            />

            {errors['filter'] && 'Error: ' + errors['filter']}
          </Box>
        </SwipeableViews>
        {deleteErrorBag && (
          <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeletePlug} label={deleteButtonText} isDelete loading={loadingDelete} />
        <Button
          style={{ marginLeft: 'auto' }}
          isDelete
          onClick={() => onClose?.('Exited')}
          label={t('pagePlugs.modalEdit.cancel')}
        />
        <Button
          type="submit"
          onClick={submitForm}
          label={t('pagePlugs.modalEdit.buttonSave')}
          loading={loadingUpdate}
        />
      </DialogActions>
    </Dialog>
  )
}

export default GoogleAnalyticsUpdateDialog
