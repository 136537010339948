import React, { useCallback } from 'react'

import { ChangeSet, EditingStateProps } from '@devexpress/dx-react-grid'
import { getIn } from 'formik'

import { FieldProps, FieldPropValues } from './types'

import Table, { TableProps } from '../../components/Table'

type TableOmitedCommitChanges = Omit<TableProps, 'editingProps'> & {
  editingProps: Omit<EditingStateProps, 'onCommitChanges'>
}

interface TableField<TValues> extends TableOmitedCommitChanges, Partial<FieldProps<TValues>> {
  name: string
}

const TableField = <Value extends FieldPropValues>({
  values,
  setFieldValue,
  name,
  columns,
  rows,
  editingProps,
  getRowId,
  ...rest
}: TableField<Value>): React.ReactElement => {
  const fieldValues = getIn(values, name)

  const handleChanges = useCallback(
    (changes: ChangeSet) => {
      const changedFields = changes.changed
      if (changedFields) {
        const prevFields = [...fieldValues]
        for (const rowId in changedFields) {
          const getIdFunction = getRowId || ((val: any) => val.id)
          const changedRowIndex = prevFields.findIndex((e) => getIdFunction(e) === rowId)
          prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
        }
        setFieldValue?.(name, prevFields)
      }
    },
    [name, fieldValues, setFieldValue, getRowId]
  )

  return (
    <Table
      editingProps={{ onCommitChanges: handleChanges, ...editingProps }}
      columns={columns}
      rows={(values && values[name]?.length > 0 && values[name]) || rows}
      getRowId={getRowId}
      {...rest}
    />
  )
}

export default TableField
