import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlugFieldSchema, PlugFieldType } from '../../../../../../../schemas/PlugSchema'
import movideskPlugService from '../../../../../../../services/plugService/movideskPlugService'
import NavigationHeader, { NavigationHeaderProps } from '../../../NavigationHeader'
import MovideskCustomFieldGrid from './MovideskCustomFieldGrid'

type MovideskCustomFieldListProps = {
  connectionId: number
  navigationProps?: Partial<NavigationHeaderProps>
}

const MovideskPersonsCustomFieldList: React.FC<PropsWithChildren<MovideskCustomFieldListProps>> = ({
  connectionId,
  navigationProps
}) => {
  const [customFields, setCustomFields] = useState<{ name: string; type: PlugFieldType; label: string }[]>([])
  const [selectedCustomFieldIds, setSelectedCustomFieldIds] = useState<React.ReactText[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const loadMovideskCustomFields = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await movideskPlugService.getPersonCustomFields(connectionId, {
        getFromConnection: true,
        fetchExternal: true
      })
      setCustomFields(
        data.map(
          (x) =>
            ({
              label: x.name,
              name: x.name,
              type: x.type || 'STRING'
            } as PlugFieldSchema)
        )
      )
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoading(false)
  }, [connectionId, enqueueSnackbar, t])

  useEffect(() => {
    loadMovideskCustomFields()
  }, [loadMovideskCustomFields])

  const handleCustomFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    if (changedFields) {
      const prevFields = [...customFields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setCustomFields(prevFields)
    }
  }

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() =>
          navigationProps?.onNextClick?.(customFields.filter((x) => selectedCustomFieldIds.some((e) => e === x.name)))
        }
      />
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h2" align="center" component="div" color="inherit">
          {t('pagePlugs.modalCreate.personsCustomFieldsTitle')}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p" color="inherit">
          {t('pagePlugs.modalCreate.personsCustomFieldsSubTitle')}
        </Typography>
        <MovideskCustomFieldGrid
          fields={customFields}
          onChange={handleCustomFieldChanges}
          onSelectionChange={(selection) => setSelectedCustomFieldIds(selection)}
          selection={selectedCustomFieldIds}
          loading={loading}
        />
      </Box>
    </>
  )
}

export default MovideskPersonsCustomFieldList
