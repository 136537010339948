import { ArrowCircleDown, CheckCircle, Edit, Error, HourglassBottom } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  IconButton,
  LinearProgress,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MaterialListItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { Theme } from '@mui/system'
import moment from 'moment'
import React, { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PlugIcon from '../../components/PlugIcon'
import { EtlLogSchema, PlugSchema } from '../../schemas/PlugSchema'
import { isApiType } from '../../utils/enumUtils'
import randomUtils from '../../utils/randomUtils'

type PlugListItemProps = {
  plug: PlugSchema
  onEdit: (plug: PlugSchema) => void
  onErrorRetry: (plug: PlugSchema) => void
  loadingRetry?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  container: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  }
}))

const StyledAlert = styled(Alert)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(0.2)
  },
  maxHeight: 80,
  width: 300,
  [theme.breakpoints.down('sm')]: {
    width: 150
  }
}))

const PlugListItemStatusUpdating: React.FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  if (mdDown) {
    return <ArrowCircleDown color="info" />
  }

  return (
    <StyledAlert severity="info">
      <AlertTitle>{t('pagePlugs.plugListItem.statusUpdating')}</AlertTitle>
      <LinearProgress />
    </StyledAlert>
  )
}
const PlugListItemStatusSuccess: React.FC<PropsWithChildren<{ lastUpdateDate: Date }>> = ({ lastUpdateDate }) => {
  const { t } = useTranslation()
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  if (mdDown) {
    return <CheckCircle color="success" />
  }
  return (
    <StyledAlert severity="success">
      <AlertTitle>{t('pagePlugs.plugListItem.statusUpToDate')}</AlertTitle>
      {t('pagePlugs.plugListItem.lastUpdated')} {moment(lastUpdateDate).utcOffset(0, true).from(moment().utc())}
    </StyledAlert>
  )
}

const PlugListItemStatusError: React.FC<
  PropsWithChildren<{
    lastUpdateDate: Date
    showLoading?: boolean
    description: string
    onRetry: () => void
  }>
> = ({ lastUpdateDate, description, showLoading, onRetry }) => {
  const { t } = useTranslation()

  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  if (mdDown) {
    return <Error color="error" />
  }

  return (
    <Stack direction="row" spacing={1} alignContent="center" alignItems="center" px={2}>
      <Tooltip placement="top" title={description}>
        <StyledAlert
          action={
            <LoadingButton loading={showLoading} onClick={onRetry} color="inherit" size="small">
              {t('pagePlugs.plugListItem.btnRetry')}
            </LoadingButton>
          }
          severity="error"
        >
          <AlertTitle> {t('pagePlugs.plugListItem.statusStopped')}</AlertTitle>
          {t('pagePlugs.plugListItem.statusStopped')} {moment(lastUpdateDate).utcOffset(0, true).from(moment().utc())}
        </StyledAlert>
      </Tooltip>
    </Stack>
  )
}

const PlugListItemStatus: React.FC<
  PropsWithChildren<{ etlLog: EtlLogSchema; showLoading?: boolean; onRetry: () => void }>
> = ({ etlLog, onRetry, showLoading }) => {
  switch (etlLog.status) {
    case 'STARTED':
    case 'PARTIAL_SUCCESS':
      return <PlugListItemStatusUpdating />
    case 'SUCCESS':
      return <PlugListItemStatusSuccess lastUpdateDate={etlLog.date} />
    case 'WAITING':
      return <PlugListItemStatusWaiting />
    case 'ERROR':
    case 'PARTIAL_ERROR':
      return (
        <PlugListItemStatusError
          lastUpdateDate={etlLog.date}
          description={etlLog.description}
          onRetry={onRetry}
          showLoading={showLoading}
        />
      )
  }
}

const PlugListItemStatusWaiting = () => {
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  if (mdDown) {
    return <HourglassBottom color="info" />
  }
  return (
    <StyledAlert severity="info">
      <AlertTitle>Em espera</AlertTitle>
    </StyledAlert>
  )
}

const PlugListItem: React.FC<PropsWithChildren<PlugListItemProps>> = ({
  plug,
  onEdit,
  onErrorRetry,
  loadingRetry,
  ...others
}) => {
  const memoColorByString = useMemo<string>(() => randomUtils.getColorByString(plug.name), [plug.name])
  const classesDefault = useStyles({ randomColor: memoColorByString })

  return (
    <MaterialListItem
      classes={{ container: classesDefault.container }}
      className={classesDefault.root}
      key={plug.id}
      {...others}
    >
      <ListItemAvatar>
        <PlugIcon type={plug.type} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography sx={{ wordBreak: 'break-all' }} maxWidth="calc(100% - 52px)">
            {plug.name}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        {isApiType(plug) ? (
          <Stack direction="row" spacing={2} alignItems="center">
            {!!plug.lastLog ? (
              <PlugListItemStatus etlLog={plug.lastLog} onRetry={() => onErrorRetry(plug)} showLoading={loadingRetry} />
            ) : (
              <PlugListItemStatusWaiting />
            )}
            <IconButton onClick={() => onEdit(plug)} size="large">
              <Edit />
            </IconButton>
          </Stack>
        ) : (
          <IconButton onClick={() => onEdit(plug)} size="large">
            <Edit />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </MaterialListItem>
  )
}

export default PlugListItem
