import { Home, Menu, Replay } from '@mui/icons-material'
import {
  Box,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Theme,
  darken,
  useMediaQuery
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'

export interface AnalyzeSwipeableDrawerOptionProps {
  label: string
  icon: React.ReactElement
  action(): void
  disabled?: boolean
}

interface AnalyzeSwipeableDrawerProps extends Partial<SwipeableDrawerProps> {
  analyzeTypeName: string
  analyzeName: string
  options: AnalyzeSwipeableDrawerOptionProps[]
  showBackButton?: boolean
  onBackButtonClick?: () => void
  onHomeButtonClick?: () => void
}

const drawerExpansedWidth = 220
const miniDrawerFactorSpacingSm = 5
const miniDrawerFactorSpacingUpSm = 7

const useStyles = makeStyles<Theme, { height: number }>((theme) => ({
  drawer: {
    width: drawerExpansedWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden'
  },
  drawerOpen: {
    width: drawerExpansedWidth,
    height: 'inherit',
    position: 'absolute',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    border: 'none'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(miniDrawerFactorSpacingSm),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(miniDrawerFactorSpacingUpSm)
    },
    border: 'none'
  },
  drawerContainer: {
    flex: '0 0 auto',
    overflowX: 'hidden',
    zIndex: 1502,
    height: (ars) => ars.height,
    width: theme.spacing(miniDrawerFactorSpacingSm),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(miniDrawerFactorSpacingUpSm)
    }
  },
  drawerContentRoot: {
    backgroundColor: theme.palette.background.light.contrast,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
      backgroundColor: theme.palette.background.light.contrast
      //border: '1px solid #cacaca'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: darken(theme.palette.background.light.contrast!, 0.2)
    }
  },
  listItem: {
    [theme.breakpoints.down('xl')]: {
      minHeight: 32
    },
    color: theme.palette.common.white
  },
  listItemLogo: {
    paddingLeft: theme.spacing(1)
  },
  listItemAlignBot: {},
  listItemTextTypography: {
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  backToCollectionButton: {},
  listItemIcon: {
    color: theme.palette.common.white
  },
  divider: {
    backgroundColor: theme.palette.text.primary
  }
}))

const AnalyzeSwipeableDrawer: React.FC<PropsWithChildren<AnalyzeSwipeableDrawerProps>> = ({
  analyzeName,
  analyzeTypeName,
  options,
  showBackButton,
  onHomeButtonClick,
  onBackButtonClick,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [height, setHeight] = useState(window.innerHeight)
  const classes = useStyles({ height })

  useEffect(() => {
    let isMounted = true
    const boxReference = boxRef.current
    let timerId: any
    const handleMouseEnterDelay = () => {
      timerId = setTimeout(() => {
        if (isMounted) setOpen(true)
      }, 400)
    }

    const handleMouseLeave = () => {
      clearTimeout(timerId)
      if (isMounted) setOpen(false)
    }

    boxReference?.addEventListener('mouseenter', handleMouseEnterDelay)
    boxReference?.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      isMounted = false
      boxReference?.removeEventListener('mouseenter', handleMouseEnterDelay)
      boxReference?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [boxRef])

  useEffect(() => {
    const resizeOnWindowResize = () => {
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', resizeOnWindowResize)

    return () => window.removeEventListener('resize', resizeOnWindowResize)
  }, [])

  return (
    <>
      <Hidden mdUp>
        <SpeedDial
          id="analysis-menu"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon icon={<Menu />} />}
          ariaLabel="analysis menu"
          data-open={open}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={(e, reason) => {
            if (reason === 'toggle') {
              setOpen(false)
            }
          }}
        >
          {options.map((opt) => (
            <SpeedDialAction
              key={opt.label}
              icon={opt.icon as any}
              tooltipTitle={opt.label}
              onClick={() => opt.action()}
            />
          ))}
          {mdDown && (
            <SpeedDialAction icon={<Home />} key="Home" tooltipTitle="Home" onClick={() => navigate(PATHS.HOME)} />
          )}
        </SpeedDial>
      </Hidden>

      <Box ref={boxRef} className={classes.drawerContainer} {...rest} hidden={mdDown || rest.hidden}>
        <SwipeableDrawer
          variant="permanent"
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <>
            <Box className={classes.drawerContentRoot}>
              <Box>
                <ListItem component="div" className={clsx(classes.listItem, classes.listItemLogo)}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <img
                      src={ENDPOINTS.PUBLIC_IMAGES + '/plugger-icon.png'}
                      width={36}
                      height={36}
                      alt="plugger icon"
                    />
                  </ListItemIcon>
                  <ListItemText primary={analyzeTypeName} />
                </ListItem>
                <Divider className={classes.divider} />
                <ListItem component="span" className={classes.listItem}>
                  <ListItemText
                    primaryTypographyProps={{ noWrap: true }}
                    primary={analyzeName}
                    style={{ visibility: open ? 'visible' : 'hidden' }}
                  />
                </ListItem>
                <List>
                  {options.map((It, index) => (
                    <ListItem
                      className={classes.listItem}
                      button
                      key={index}
                      onClick={It.action}
                      disabled={It.disabled}
                    >
                      <ListItemIcon className={classes.listItemIcon}>{It.icon as any}</ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ className: classes.listItemTextTypography }}
                        primary={It.label}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <List>
                {showBackButton && (
                  <ListItem
                    className={clsx(classes.listItem, classes.backToCollectionButton)}
                    button
                    onClick={() => onBackButtonClick?.()}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Replay />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ className: classes.listItemTextTypography }}
                      primary="Back to collection"
                    />
                  </ListItem>
                )}
                <ListItem
                  className={clsx(classes.listItem, classes.listItemAlignBot)}
                  button
                  onClick={() => (onHomeButtonClick ? onHomeButtonClick() : navigate(PATHS.HOME))}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ className: classes.listItemTextTypography }} primary="Home" />
                </ListItem>
              </List>
            </Box>
          </>
        </SwipeableDrawer>
      </Box>
    </>
  )
}

export default AnalyzeSwipeableDrawer
