import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { CircularProgress, IconButton, InputAdornment, Stack, TextFieldProps, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { userService } from '../../services'
import { ResponsivePaper } from '../confirm'
import { StyledTextField } from '../signIn'

export const PasswordTextField: React.FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  return (
    <StyledTextField
      InputProps={{
        endAdornment: (
          <InputAdornment color="#fff" position="end">
            <IconButton
              sx={{ color: '#fff' }}
              color="inherit"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  )
}

const ConfirmInvitePage: React.FC = () => {
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const isNew = searchParams.get('isNew') === 'True'
  const token = searchParams.get('token')
  const userId = searchParams.get('userId')
  const [error, setError] = useState<string | null>(null)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const validationSchema = yup.object({
    firstName: yup.string().required().label(t('signup.form.firstName')),
    lastName: yup.string().required().label(t('signup.form.lastName')),
    password: yup.string().required().min(6).label(t('common.labels.password')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('validation.passwordsMustMatch')!)
      .label(t('common.labels.confirmPassword'))
  })

  const { handleSubmit, submitForm, handleChange, handleBlur, touched, errors, values } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingUpdateUser(true)
      try {
        await confirmInvite(token!)
        await userService.update(userId!, {
          firstName: values.firstName,
          lastName: values.lastName,
          profileId: null
        })
        await userService.updatePassword(userId!, {
          password: values.password
        })
        await auth.loadUserData()
        navigate(PATHS.HOME)
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
        setLoadingUpdateUser(false)
      }
    }
  })

  const confirmInvite = useCallback(
    async (token: string) => {
      setLoadingConfirm(true)
      try {
        const { data } = await userService.confirmInvite(token)
        auth.setToken(data.token, data.expiration)
        await auth.loadUserData()

        navigate(PATHS.HOME)
      } catch (err) {
        setError('Token inválido ou expirado')
      }
      setLoadingConfirm(false)
    },
    [navigate]
  )

  useEffect(() => {
    if (!token) {
      setError('Token is required')
      return
    }
    if (isNew) {
      return
    }

    confirmInvite(token)
  }, [confirmInvite, isNew, token])

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: false,
      context: 'signin',
      callback: async (response: any) => {
        setLoadingUpdateUser(true)
        try {
          const { data } = await userService.confirmInvite(token!)
          auth.setToken(data.token, data.expiration)
          const { data: externalLoginResp } = await userService.addExternalLogin({
            token: response.credential,
            provider: 'Google'
          })
          await userService.update(userId!, {
            firstName: externalLoginResp.firstName,
            lastName: externalLoginResp.lastName,
            profileId: null,
            teamId: null
          })
          await auth.loadUserData()
          navigate(PATHS.HOME)
        } catch (err: any) {
          if (err.response?.status === 409) {
            enqueueSnackbar(t('loginProvider.loginAlreadyInUse'), { variant: 'error' })
          } else {
            const message = err.response?.data?.message || err.message
            enqueueSnackbar(message, { variant: 'error' })
          }
          setLoadingUpdateUser(false)
        }
      }
    })
  }, [
    navigate,
    token,
    t,
    userId,
    values.firstName,
    values.lastName,
    values.password,
    confirmInvite,
    auth,
    enqueueSnackbar
  ])

  useEffect(() => {
    const googleBtnContainer = document.getElementById('google-btn-container')
    if (!googleBtnContainer) {
      return
    }
    google.accounts.id.renderButton(googleBtnContainer, {
      type: 'standard',
      width: '400',
      text: 'continue_with',
      theme: 'outline'
    })
  }, [])

  return (
    <Stack
      direction="column"
      minHeight="100vh"
      sx={{
        background: (theme) =>
          `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${theme.palette.background.light.contrast || '#FFF'}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
      }}
    >
      <ResponsivePaper sx={{ color: '#fff' }} elevation={5}>
        {loadingConfirm ? (
          <Typography color="inherit" variant="h2" textAlign="center">
            {t('confirmInvite.title')}
            <CircularProgress />
            <Typography color="inherit" variant="subtitle2">
              {t('confirmInvite.notClosePage')}
            </Typography>
          </Typography>
        ) : !!error ? (
          <Typography variant="h3" color="error" textAlign="center">
            Err: {error}
          </Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Stack spacing={2}>
                <div style={{ height: '44px' }} id="google-btn-container" />
              </Stack>
              <Typography color="inherit" variant="h3" maxWidth={400} textAlign="center">
                {t('accessScreen.signin.or')}
              </Typography>
              <Stack spacing={2}>
                <Typography>{t('confirmInvite.completeInfos')}</Typography>
                <StyledTextField
                  id="firstName"
                  name="firstName"
                  required
                  label={t('signup.form.firstName')}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <StyledTextField
                  id="lastName"
                  name="lastName"
                  required
                  label={t('signup.form.lastName')}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
                <PasswordTextField
                  id="password"
                  name="password"
                  required
                  label={t('common.labels.password')}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
                <PasswordTextField
                  id="confirmPassword"
                  name="confirmPassword"
                  required
                  label={t('common.labels.confirmPassword')}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <LoadingButton loading={loadingUpdateUser} variant="contained" onClick={() => submitForm()}>
                  {t('confirmInvite.btnComplete')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        )}
      </ResponsivePaper>
    </Stack>
  )
}
export default ConfirmInvitePage
