import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as Swipeable from 'react-swipeable-views'
import * as yup from 'yup'
import SelectBox from '../../../../../components/SelectBox'
import TextField from '../../../../../components/TextField'
import api from '../../../../../services/api'
import NavigationHeader, { NavigationHeaderProps } from '../NavigationHeader'

export type CsvFilePlugFormValues = {
  fileUrl: string
  delimiter: string
  plugName: string
}

type CsvFilePlugFormProps = {
  onSubmit: (values: CsvFilePlugFormValues) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(2, 0, 3)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

const validationSchema = yup.object({
  fileUrl: yup
    .string()
    .required()
    .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/gm)
    .test('fileUrl', 'arquivo inválido', async (value, context) => {
      try {
        const { headers } = await api.get(`/cors?url=${value}`)
        if (headers['content-type'] !== 'text/csv') {
          return context.createError({
            message: 'arquivo inválido',
            path: 'fileUrl'
          })
        }
      } catch (err: any) {
        return context.createError({
          message: 'Arquivo Inválido, ' + err.message,
          path: 'fileUrl'
        })
      }
      return true
    }),
  delimiter: yup.string().nullable().required(),
  plugName: yup.string().required()
})

const CsvFilePlugForm: React.FC<PropsWithChildren<CsvFilePlugFormProps>> = ({ onSubmit, navigationProps }) => {
  const { slideUpdateHeight } = useContext((Swipeable as any).SwipeableViewsContext) as any

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { values, setFieldValue, submitForm, errors } = useFormik({
    validateOnChange: false,
    initialValues: {} as CsvFilePlugFormValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema
  })

  const handleValuesChange = (key: string, value: any) => {
    setFieldValue(key, value)
  }

  const delimiterOptions = {
    ',': { displayName: t('pagePlugs.modalCreate.csv.comma') },
    ';': { displayName: t('pagePlugs.modalCreate.csv.semicolon') },
    '\t': { displayName: t('pagePlugs.modalCreate.csv.tab') }
  }

  useEffect(() => {
    slideUpdateHeight()
  }, [])

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() => submitForm()}
      />
      <Typography variant="h2" align="center" component="div" color="inherit">
        {t('pagePlugs.modalCreate.configureTitle')}
      </Typography>
      <Typography variant="subtitle1" align="center" component="p" color="inherit">
        Informe o caminho HTTP onde o arquivo está hospedado. Selecione qual delimitador é utilizado no arquivo e dê um
        nome para seu novo plug.
      </Typography>

      <TextField
        variant="outlined"
        fullWidth={false}
        value={values.fileUrl}
        onChange={(event) => handleValuesChange('fileUrl', event.target.value)}
        required
        className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
        placeholder={t('pagePlugs.modalCreate.csv.fileUrl')!}
        error={!!errors['fileUrl']}
        helperText={errors['fileUrl']}
      />
      <SelectBox
        className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
        options={Object.keys(delimiterOptions)}
        value={values.delimiter}
        onChange={(event, newValue) => handleValuesChange('delimiter', newValue)}
        getOptionLabel={(o: any) => (delimiterOptions as any)[o as any].displayName}
        textFieldProps={{
          placeholder: t('pagePlugs.modalCreate.csv.delimiter')!,
          name: 'connection',
          error: !!errors['delimiter'],
          helperText: errors['delimiter'] as any
        }}
      />
      <TextField
        variant="outlined"
        fullWidth={false}
        value={values.plugName}
        onChange={(event) => handleValuesChange('plugName', event.target.value)}
        required
        className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
        placeholder={t('pagePlugs.modalCreate.configureName')!}
        error={!!errors['plugName']}
        helperText={errors['plugName']}
      />
    </>
  )
}

export default CsvFilePlugForm
