import { Box } from '@mui/material'
import React, { PropsWithChildren, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Dialog } from '../../../components/Dialog'
import { PlugType } from '../../../schemas/PlugSchema'
import { AvailablePlug, AvailablePlugs } from '../AvailablePlugs'
import CsvFileFlow from './components/CsvFileFlow/CsvFileFlow'
import SlideTransition from './components/DialogTransition'
import FacebookAdsFlow from './components/Facebook/FacebookAdsFlow/FacebookAdsFlow'
import FirebirdFlow from './components/FirebirdFlow/FirebirdFlow'
import GoogleAdsFlow from './components/Google/GoogleAdsFlow/GoogleAdsFlow'
import GoogleAnalyticsFlow from './components/Google/GoogleAnalyticsFlow/GoogleAnalyticsFlow'
import GoogleSheetsFlow from './components/Google/GoogleSheetsFlow/GoogleSheetsFlow'
import MovideskTicketsFlow from './components/Movidesk/MovideskTicketsFlow/MovideskTicketsFlow'
import MsSqlFlow from './components/MsSqlFlow/MsSqlFlow'
import MySqlFlow from './components/MySqlFlow/MySqlFlow'
import RedshiftFlow from './components/RedshiftFlow/RedshiftFlow'
import SelectPlugView from './components/SelectPlugView'

type CreateNewPlugDialogProps = {
  open?: boolean
  onClose?: (reason: 'EndFlow' | 'Exited') => void
}

const plugFlows: { [key in keyof typeof PlugType]: React.FC<PropsWithChildren<any>> } = {
  GoogleAnalytics: GoogleAnalyticsFlow,
  Firebird: FirebirdFlow,
  GoogleSheets: GoogleSheetsFlow,
  MySql: MySqlFlow,
  Redshift: RedshiftFlow,
  Movidesk: MovideskTicketsFlow,
  GoogleAds: GoogleAdsFlow,
  MsSql: MsSqlFlow,
  FacebookAds: FacebookAdsFlow,
  CsvFile: CsvFileFlow
}

const CreateNewPlugDialog: React.FC<PropsWithChildren<CreateNewPlugDialogProps>> = ({ open = false, onClose }) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const [selectedAvailablePlug, setSelectedAvailablePlug] = useState<AvailablePlug>()

  const handleNextFirstStep = async () => {
    if (!selectedAvailablePlug) {
      return
    }

    swiper?.slideNext()
  }

  const FlowComponent = selectedAvailablePlug && plugFlows[selectedAvailablePlug.type]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={SlideTransition as any}
      removeTitle
      scroll="paper"
    >
      <Box
        sx={{
          width: '100%',
          background: 'linear-gradient(210deg, rgb(31 218 200) 10%, rgb(4 142 128) 94%)',
          height: '100%',
          overflowX: 'hidden'
        }}
      >
        <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal">
          <SwiperSlide>
            <SelectPlugView
              availablePlugs={AvailablePlugs}
              onChangeSelection={(val) => setSelectedAvailablePlug(val)}
              selected={selectedAvailablePlug}
              navigationProps={{ onNextClick: handleNextFirstStep, onClose: onClose }}
            />
          </SwiperSlide>
          <SwiperSlide>
            {FlowComponent && (
              <FlowComponent plug={selectedAvailablePlug} onBackClick={() => swiper?.slidePrev()} onClose={onClose} />
            )}
          </SwiperSlide>
        </Swiper>
      </Box>
    </Dialog>
  )
}

export default CreateNewPlugDialog
