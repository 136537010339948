import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { PlugFieldSchema, PlugType } from '../../../../../../schemas/PlugSchema'
import {
  CreateGoogleSheetsPlugRequest,
  createGoogleSheetsPlug,
  getSheetColumns
} from '../../../../../../services/google/googleSpreadSheetsService'
import { formatToPlugFieldLabel } from '../../../../../../utils/stringUtils'
import { FlowComponentBaseProps } from '../../../types'
import FieldsGrid from '../../FieldsGrid'
import FilterView from '../../FilterView'
import GoogleSheetsPlugForm, { GoogleSheetsPlugFormValues } from './GoogleSheetsPlugForm'

const GoogleSheetsFlow: React.FC<PropsWithChildren<FlowComponentBaseProps>> = ({ plug, onBackClick, onClose }) => {
  const [plugFormValues, setPlugFormValues] = useState<GoogleSheetsPlugFormValues | null>(null)
  const [fields, setFields] = useState<PlugFieldSchema[]>([])
  const [filterValue, setFilterValue] = useState<any>(null)
  const [loadingFields, setLoadingFields] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const goNextPage = () => {
    swiper?.slideNext()
  }

  const goBackPage = () => {
    swiper?.slidePrev()
  }

  const getFields = async (values: GoogleSheetsPlugFormValues) => {
    try {
      setLoadingFields(true)
      const { data } = await getSheetColumns(values!.connection!.id, values!.spreadsheet.id, values!.sheet.id)
      setFields(data.map((f) => ({ ...f, label: formatToPlugFieldLabel(f.name) })))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${err.message}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.getFields'), { variant: 'error' })
    } finally {
      setLoadingFields(false)
    }
  }

  const handleSubmitPlugForm = (values: GoogleSheetsPlugFormValues) => {
    setPlugFormValues(values)
    goNextPage()
    getFields(values)
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    if (changedFields) {
      const prevFields = [...fields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setFields(prevFields)
    }
  }

  const handleClose = () => {
    onClose('Exited')
  }

  const handleCreatePlug = async () => {
    const newPlug: CreateGoogleSheetsPlugRequest = {
      connectionId: plugFormValues!.connection.id,
      fields: fields,
      name: plugFormValues!.plugName,
      filter: filterValue,
      sheetId: plugFormValues!.sheet.id,
      spreadsheetId: plugFormValues!.spreadsheet.id
    }
    try {
      await createGoogleSheetsPlug(newPlug)
      onClose('EndFlow')
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createPlug', { name: newPlug.name }), {
        variant: 'success'
      })
      dataLayer.push({
        event: 'on_plug_created',
        plugType: PlugType.GoogleSheets
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  return (
    <Box>
      <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal">
        <SwiperSlide>
          <Box
            color="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <GoogleSheetsPlugForm
              navigationProps={{ onNextClick: goNextPage, onClose: handleClose, onBackClick: onBackClick }}
              onSubmit={handleSubmitPlugForm}
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <FieldsGrid
              loading={loadingFields}
              fields={fields}
              onChange={handleFieldChanges}
              navigationProps={{
                onBackClick: goBackPage,
                onNextClick: goNextPage,
                onClose: handleClose
              }}
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            color="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <FilterView
              fields={fields}
              value={filterValue}
              onChange={(val) => setFilterValue(val)}
              navigationProps={{
                onBackClick: goBackPage,
                onClose: handleClose,
                onNextClick: handleCreatePlug,
                nextDisabled: true
              }}
            />
          </Box>
        </SwiperSlide>
      </Swiper>
    </Box>
  )
}

export default GoogleSheetsFlow
