import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import TextField from '../../components/TextField'
import { RemoteDataSource } from './dataSource'

interface SaveACopyDialogProps {
  open: boolean
  onClose(): void
  baseName?: string
  dataSource: InstanceType<typeof RemoteDataSource> | undefined
}

const SaveACopyDialog: React.FC<PropsWithChildren<SaveACopyDialogProps>> = ({
  open,
  onClose,
  dataSource,
  baseName
}) => {
  const [name, setName] = useState('')
  const [error, setError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    setName(`${baseName} - copy`)
  }, [open, baseName])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const clearState = () => {
    setName('')
  }

  const handleClose = () => {
    clearState()
    onClose()
  }

  const handleSave = () => {
    if (!name) {
      setError(true)
      return
    }
    dataSource
      ?.saveNew(name)
      .then(() => {
        enqueueSnackbar(t('pivotGrid.menuSettings.modalSaveCopy.toast.success.save', { name: name }), {
          variant: 'success'
        })
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar(t('pivotGrid.menuSettings.modalSaveCopy.toast.error.save'), { variant: 'error' })
      })

    handleClose()
  }

  return (
    <Dialog
      title={t('pivotGrid.menuSettings.modalSaveCopy.Title')}
      description={t('pivotGrid.menuSettings.modalSaveCopy.Subtitle')}
      open={open}
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogContent>
        <TextField
          error={error}
          required
          helperText={error && 'Name is required'}
          label={t('pivotGrid.menuSettings.modalSaveCopy.Name')}
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button label={t('pivotGrid.menuSettings.modalSaveCopy.ButtonSave')} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  )
}

export default SaveACopyDialog
