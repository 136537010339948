import { DashboardControl, ItemWidgetOptionEventArgs, ViewerApiExtension } from 'devexpress-dashboard'
import * as Model from 'devexpress-dashboard/model'

const comboBoxPlaceholderExtension: Model.CustomPropertyMetadata = {
  ownerType: Model.PivotItem,
  propertyName: 'ComboBoxPlaceholderExtension',
  defaultValue: '',
  valueType: 'string'
}

Model.registerCustomProperty(comboBoxPlaceholderExtension)

function onItemWidgetOptionsPrepared(args: ItemWidgetOptionEventArgs) {
  if (args.dashboardItem instanceof Model.ComboBoxItem) {
    if(!args.dashboardItem.showCaption()){
        (args.options as any).placeholder = args.dashboardItem._caption
    }
  }
}

export class ComboBoxPlaceholderExtension {
  name = 'ComboBoxPlaceholderExtension'
  constructor(private dashboardControl: DashboardControl) {}

  start(): void {
    let viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as ViewerApiExtension
    if (viewerApiExtension) {
      viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
    }
  }

  stop(): void {
    let viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as ViewerApiExtension
    if (viewerApiExtension) {
      viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
    }
  }
}
