import { ChangeSet, EditingState, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui'
import { Box, CircularProgress, Paper } from '@mui/material'
import { PropsWithChildren, ReactText } from 'react'
import { useTranslation } from 'react-i18next'
import { PlugFieldSchema } from '../../../../../../../schemas/PlugSchema'
import { AutoCompleteTypeProvider } from '../../../AutoCompleteTypeProvider'

type MovideskCustomFieldGridProps = {
  fields: PlugFieldSchema[]
  loading?: boolean
  onChange: (changes: ChangeSet) => void
  selection: ReactText[]
  onSelectionChange: (selection: ReactText[]) => void
}

const MovideskCustomFieldGrid: React.FC<PropsWithChildren<MovideskCustomFieldGridProps>> = ({
  fields,
  loading,
  onChange,
  selection,
  onSelectionChange
}) => {
  const { t } = useTranslation()
  return (
    <Paper>
      <Grid
        rows={fields}
        columns={[
          {
            name: 'name',
            title: t('pagePlugs.modalCreate.customFields.gridColumn.name')!
          },
          {
            name: 'label',
            title: t('pagePlugs.modalCreate.customFields.gridColumn.friendlyName')!
          },
          {
            name: 'type',
            title: t('pagePlugs.modalCreate.customFields.gridColumn.type')!
          }
        ]}
        getRowId={(x) => x.name}
      >
        <AutoCompleteTypeProvider for={['type']} />
        <EditingState
          columnExtensions={[
            {
              columnName: 'name',
              editingEnabled: false
            }
          ]}
          onCommitChanges={onChange}
        />
        <Table
          noDataCellComponent={(props) =>
            loading ? (
              <Box component="td" colSpan={4} textAlign="center">
                <CircularProgress sx={{ margin: 'auto' }} />
              </Box>
            ) : (
              <Table.NoDataCell {...props} />
            )
          }
        />
        <TableHeaderRow />
        <SelectionState selection={selection} onSelectionChange={onSelectionChange} />
        <IntegratedSelection />

        <TableSelection showSelectAll />
        <TableInlineCellEditing startEditAction="click" selectTextOnEditStart />
      </Grid>
    </Paper>
  )
}

export default MovideskCustomFieldGrid
