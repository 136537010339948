import { Edit } from '@mui/icons-material'
import HeaderConnectionIcon from '@mui/icons-material/SettingsInputAntenna'
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConnectionIcon from '../../components/ConnectionIcon'
import ContentBox from '../../components/ContentBox'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import RootBox from '../../components/RootBox'
import { ConnectionSchema } from '../../schemas/ConnectionSchema'
import { connectionService } from '../../services/index'
import { normalyzeAndLowering } from '../../utils/stringUtils'
import UpdateConnectionDialog from './UpdateConnectionDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(1.5),
      margin: theme.spacing(0.5, 0),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  container: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  },
  primaryText: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '120px'
    }
  },
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const ConnectionItemStartIcon: React.FC<ConnectionSchema> = (connection: ConnectionSchema) => {
  return <ConnectionIcon type={connection.type} />
}

const ConnectionsPage: React.FC = () => {
  const [editConnection, setEditConnection] = useState<ConnectionSchema | null>(null)
  const [connections, setConnections] = useState<ConnectionSchema[]>([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()
  const classes = useStyles()

  const getFilteredConnections = useCallback(() => {
    if (!searchValue) {
      return connections.sort((x, y) => x.name?.trim().localeCompare(y.name?.trim()))
    }
    const filteredConnections = connections
      .filter((p) => normalyzeAndLowering(p?.name).includes(normalyzeAndLowering(searchValue)))
      .sort((x, y) => x.name?.trim().localeCompare(y.name?.trim()))

    return filteredConnections
  }, [connections, searchValue])

  const handleOpenEditDialog = (editConnection: ConnectionSchema) => {
    setEditConnection(editConnection)
  }

  const reloadConnections = async () => {
    try {
      setLoading(true)
      const respConnections = await connectionService.getAll()
      setConnections(respConnections.data)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageConnections.toast.error.loadingConnections'), { variant: 'error' })
    }
    setLoading(false)
  }

  const handleCloseEditDialog = (refresh?: boolean) => {
    setEditConnection(null)
    refresh && reloadConnections()
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const respConnections = await connectionService.getAll()
        setConnections(respConnections.data)
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pageConnections.toast.error.loadingConnections'), { variant: 'error' })
      }
      setLoading(false)
    })()
  }, [enqueueSnackbar, t])

  return (
    <RootBox>
      <Header
        icon={HeaderConnectionIcon}
        title={t('pageConnections.title')}
        subtitle={t('pageConnections.subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <List className={classes.list}>
            {getFilteredConnections().map((item) => (
              <ListItem key={item.id} classes={{ container: classes.container }} className={classes.root}>
                <ListItemAvatar>
                  <ConnectionItemStartIcon {...item} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography sx={{ wordBreak: 'break-all' }} maxWidth="calc(100% - 52px)">
                      {item.name}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleOpenEditDialog(item)} size="large">
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </ContentBox>
      {editConnection && <UpdateConnectionDialog open onClose={handleCloseEditDialog} connection={editConnection} />}
    </RootBox>
  )
}

export default ConnectionsPage
