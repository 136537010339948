import { ArrowBack, ArrowForward, Close } from '@mui/icons-material'
import { Box, IconButton, Slide } from '@mui/material'
import { t } from 'i18next'
import { PropsWithChildren } from 'react'
import Button from '../../../../components/Button'
import { DialogActions } from '../../../../components/Dialog'
import { ENDPOINTS } from '../../../../constants/apiEndpoints'

export type NavigationHeaderProps = {
  canAnimate?: boolean
  animate?: boolean
  onBackClick?: () => void
  onNextClick?: (values?: any) => void
  onClose: (reason: 'EndFlow' | 'Exited') => void
  backDisabled?: boolean
  nextDisabled?: boolean
}

const NavigationHeader: React.FC<PropsWithChildren<NavigationHeaderProps>> = ({
  animate = false,
  canAnimate = false,
  onClose,
  backDisabled,
  nextDisabled,
  onBackClick,
  onNextClick
}) => {
  return (
    <Box p={2} sx={{ overflowX: 'hidden' }}>
      <DialogActions>
        <IconButton onClick={() => onClose('Exited')} size="large">
          <Close htmlColor="white" />
        </IconButton>
        <Box>
          <Button
            style={{ color: 'white' }}
            startIcon={<ArrowBack />}
            variant="text"
            disabled={backDisabled}
            onClick={onBackClick}
            label={t('pagePlugs.modalCreate.tabPrev')}
          />
          <Button
            style={{ color: 'white' }}
            endIcon={!nextDisabled ? <ArrowForward /> : null}
            variant="text"
            onClick={onNextClick}
            label={!nextDisabled ? t('pagePlugs.modalCreate.tabNext') : t('pagePlugs.modalCreate.tabSave')}
          />
        </Box>
      </DialogActions>
      <Box width="100%" flex="0.2 0 0" height="90%" display="flex" flexDirection="column">
        {animate ? (
          <Box display="flex">
            <Box flex="1 1 0">
              <Slide timeout={{ enter: 1500, appear: 1600 }} direction="right" in={canAnimate}>
                <img
                  style={{ width: '100%', height: 'auto', opacity: 0.9 }}
                  src={ENDPOINTS.PUBLIC_IMAGES + '/plug-left.png'}
                  alt="plug icon left"
                />
              </Slide>
            </Box>
            <Box flex="1 1 0">
              <Slide timeout={{ enter: 1500, appear: 1600 }} direction="left" in={canAnimate}>
                <img
                  style={{ width: '100%', height: 'auto', opacity: 0.9 }}
                  src={ENDPOINTS.PUBLIC_IMAGES + '/plug-right.png'}
                  alt="plug icon right"
                />
              </Slide>
            </Box>
          </Box>
        ) : (
          <Box display="flex">
            <Box flex="1 1 0">
              <img
                style={{ width: '100%', height: 'auto', opacity: 0.9 }}
                src={ENDPOINTS.PUBLIC_IMAGES + '/plug-left.png'}
                alt="plug icon left"
              />
            </Box>
            <Box flex="1 1 0">
              <img
                style={{ width: '100%', height: 'auto', opacity: 0.9 }}
                src={ENDPOINTS.PUBLIC_IMAGES + '/plug-right.png'}
                alt="plug icon right"
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default NavigationHeader
