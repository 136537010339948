import { List, ListSubheader } from '@mui/material'
import { PlugFieldSchema, PlugSchema } from '../../../schemas/PlugSchema'
import PlugFieldListItem from './PlugFieldListItem'

type ProfilePlugFieldDenyListProps = {
  plugs: (PlugSchema & { fields: PlugFieldSchema[] })[]
  selectedPlugIds: number[]
  selectedPlugFields: SelectedPlugField[]
  onPlugIdsSelectionChange: (selectedPlugIds: number[]) => void
  onPlugFieldsSelectionChange: (selectedPlugFields: SelectedPlugField[]) => void
}

export type SelectedPlugField = {
  plugId: number
  plugFieldName: string
}

const ListContainer: React.FC<ProfilePlugFieldDenyListProps> = ({
  plugs,
  selectedPlugFields,
  selectedPlugIds,
  onPlugFieldsSelectionChange,
  onPlugIdsSelectionChange
}) => {
  const handlePlugCheckChange = (plugId: number, checked: boolean) => {
    const newChecked = [...selectedPlugIds]
    if (!checked) {
      const indexOfPlugId = newChecked.indexOf(plugId)
      newChecked.splice(indexOfPlugId, 1)
    } else {
      newChecked.push(plugId)
    }
    onPlugIdsSelectionChange(newChecked)
  }

  const handleFieldCheckChange = (plugId: number, fieldName: string, checked: boolean) => {
    const newChecked = [...selectedPlugFields]
    if (!checked) {
      const indexOfField = selectedPlugFields.findIndex((x) => x.plugId === plugId && x.plugFieldName === fieldName)
      newChecked.splice(indexOfField, 1)
    } else {
      newChecked.push({ plugId, plugFieldName: fieldName })
    }
    onPlugFieldsSelectionChange(newChecked)
  }

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Plugs
        </ListSubheader>
      }
    >
      {plugs.map((plug) => (
        <PlugFieldListItem
          key={plug.id}
          plug={plug}
          checkedFields={selectedPlugFields}
          onFieldCheckChanges={handleFieldCheckChange}
          onPlugCheckChanges={handlePlugCheckChange}
          plugChecked={selectedPlugIds.includes(plug.id)}
        />
      ))}
    </List>
  )
}

export default ListContainer
