import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Lock } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'swiper/css'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import * as yup from 'yup'
import { ConfirmDeleteButton } from '../../../components/ConfirmDeleteButton/ConfirmDeleteButton'
import DialogTitleContent from '../../../components/DialogTitleContent'
import i18n from '../../../locales/i18next'
import { AnalyzeSchema } from '../../../schemas/AnalyzeSchema'
import { ExportFormat, SendingSchema } from '../../../schemas/SendingSchema'
import { analyzeService } from '../../../services'
import sendingService, { CreateSendingRequest } from '../../../services/sendingService'
import AdvancedExportOptionsTab from './AdvancedExportOptionsTab'
import GeneralTab from './GeneralTab'

type SendingDialogProps = {
  open?: boolean
  onClose?: (refresh?: boolean) => void
  sending?: SendingSchema | null
}

const generalSchemaValidation = yup.object({
  name: yup.string().max(100).required().label(i18n.t('pageSending.modalCreate.tabGeneral.name')),
  subject: yup.string().max(255).required().label(i18n.t('pageSending.modalCreate.tabEmailOptions.subject')),
  senderName: yup.string().max(100).label(i18n.t('pageSending.modalCreate.tabEmailOptions.senderName')),
  analysisId: yup.string().required().label(i18n.t('pageSending.modalCreate.tabExportOptions.analysis')),
  exportFormat: yup.string().required().label(i18n.t('pageSending.modalCreate.tabExportOptions.export')),
  recipients: yup
    .array()
    .of(
      yup
        .object({
          emailAddress: yup.string().email().required(),
          name: yup.string().required(),
          userId: yup.string().nullable().optional()
        })
        .required()
    )
    .required()
    .min(1)
    .label(i18n.t('pageSending.modalCreate.recipients.title'))
})

const advancedDashboardFormValidation = yup.object().shape({
  dashboardExcelExportOptions: yup.object().shape({
    excelFormatType: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.excelFormatType')),
    includeFilters: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includeFilters')),
    includeParameters: yup
      .boolean()
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includeParameters')),
    includePosition: yup
      .string()
      .when(['includeFilters', 'includeParameters'], {
        is: (includeFilters: boolean, includeParameters: boolean) => includeFilters || includeParameters,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      })
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includePosition'))
  }),
  dashboardImageExportOptions: yup.object().shape({
    imageFormat: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.imageFormat')),
    showTitle: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.showTitle')),
    title: yup
      .string()
      .when('showTitle', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      })
      .nullable()
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.title')),
    includeFilters: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includeFilters')),
    includeParameters: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includeParameters'))
  }),
  dashboardPdfExportOptions: yup.object().shape({
    pageLayout: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.pageLayout')),
    size: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.size')),
    showTitle: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.showTitle')),
    title: yup
      .string()
      .when('showTitle', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      })
      .nullable()
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.title')),
    scaleMode: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.scaleMode')),
    scaleFactor: yup
      .number()
      .when('scaleMode', {
        is: 'USE_SCALE_FACTOR',
        then: (schema) => schema.required().min(1),
        otherwise: (schema) => schema.optional()
      })
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.scaleFactor')),
    fitToPageCount: yup
      .number()
      .when('scaleMode', {
        is: 'AUTO_FIT_TO_PAGE_WIDTH',
        then: (schema) => schema.required().min(1),
        otherwise: (schema) => schema.optional()
      })
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.fitToPageCount')),
    includeFilters: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includeFilters')),
    includeParameters: yup
      .boolean()
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includeParameters')),
    includePosition: yup
      .string()
      .when(['includeFilters', 'includeParameters'], {
        is: (includeFilters: boolean, includeParameters: boolean) => includeFilters || includeParameters,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      })
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.includePosition'))
  })
})

const advancedReportFormValidation = yup.object().shape({
  reportExcelExportOptions: yup.object().shape({
    excelFormatType: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.excelFormatType')),
    fitToPrintedPageHeight: yup
      .boolean()
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.fitToPrintedPageHeight')),
    fitToPrintedPageWidth: yup
      .boolean()
      .label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.fitToPrintedPageWidth')),
    ignoreErrors: yup.boolean().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.ignoreErrors'))
  }),
  reportImageExportOptions: yup.object().shape({
    imageFormat: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.imageFormat'))
  }),
  reportPdfExportOptions: yup.object().shape({
    imageQuality: yup.string().label(i18n.t('pageSending.modalCreate.tabAdvancedExportOptions.imageQuality'))
  })
})

const SendingDialog: React.FC<SendingDialogProps> = ({ open, onClose, sending }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loadingDelete, setLoadingDelete] = useState(false)

  const generalForm = useForm({
    defaultValues: {
      recipients: [] as any
    },
    resolver: yupResolver(generalSchemaValidation)
  })

  const [analysis, setAnalysis] = useState<AnalyzeSchema[]>([])
  const [analysisLoading, setAnalysisLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setAnalysisLoading(true)
      try {
        const { data } = await analyzeService.getAnalysis()
        setAnalysis(data)
      } catch (err) {
        alert(err)
      }
      setAnalysisLoading(false)
    })()
  }, [])

  const selectedAnalysisType = analysis.find((x) => x.id === generalForm.watch('analysisId'))?.type
  const advancedSchemaValidation = () => {
    switch (selectedAnalysisType) {
      case 'DASHBOARD':
        return advancedDashboardFormValidation
      case 'REPORT':
        return advancedReportFormValidation
      default:
        return yup.object()
    }
  }

  const advancedForm = useForm<any>({
    resolver: yupResolver(advancedSchemaValidation())
  })

  useEffect(() => {
    if (!!sending) {
      generalForm.setValue('name', sending.name)
      generalForm.setValue('subject', sending.emailSettings.subject)
      generalForm.setValue('senderName', sending.emailSettings.senderName)
      generalForm.setValue('analysisId', sending.analysisSettings.analysisId)
      generalForm.setValue('exportFormat', sending.analysisSettings.exportFormat.toUpperCase())
      generalForm.setValue('recipients', sending.emailSettings.recipients)

      sending.analysisSettings.dashboardExcelExportOptions &&
        advancedForm.setValue('dashboardExcelExportOptions', sending.analysisSettings.dashboardExcelExportOptions)
      sending.analysisSettings.dashboardImageExportOptions &&
        advancedForm.setValue('dashboardImageExportOptions', sending.analysisSettings.dashboardImageExportOptions)
      sending.analysisSettings.dashboardPdfExportOptions &&
        advancedForm.setValue('dashboardPdfExportOptions', sending.analysisSettings.dashboardPdfExportOptions)

      sending.analysisSettings.reportExcelExportOptions &&
        advancedForm.setValue('reportExcelExportOptions', sending.analysisSettings.reportExcelExportOptions)
      sending.analysisSettings.reportImageExportOptions &&
        advancedForm.setValue('reportImageExportOptions', sending.analysisSettings.reportImageExportOptions)
      sending.analysisSettings.reportPdfExportOptions &&
        advancedForm.setValue('reportPdfExportOptions', sending.analysisSettings.reportPdfExportOptions)
    } else {
      generalForm.reset()
      advancedForm.reset()
    }
  }, [advancedForm, generalForm, sending])

  const handleSubmit = async () => {
    const generalFormIsValid = await generalForm.trigger()
    if (!generalFormIsValid) {
      setActiveTab(0)
      swiper?.slideTo(0)
      return
    }
    const advancedFormIsValid = await advancedForm.trigger()
    if (!advancedFormIsValid) {
      setActiveTab(1)
      swiper?.slideTo(1)
      return
    }

    const generalValues = generalForm.getValues()
    const advancedOptions = advancedForm.getValues() as any

    type GeneralValuesType = typeof generalValues
    type AdvancedOptionsType = typeof advancedOptions

    const getAnalysisSettings = (generalValues: GeneralValuesType, advancedOptions: AdvancedOptionsType) => {
      switch (selectedAnalysisType) {
        case 'DASHBOARD': {
          switch (generalValues.exportFormat) {
            case 'Excel': {
              return {
                dashboardExcelExportOptions: advancedOptions.dashboardExcelExportOptions || null,
                dashboardImageExportOptions: null,
                dashboardPdfExportOptions: null,
                reportExcelExportOptions: null,
                reportImageExportOptions: null,
                reportPdfExportOptions: null
              }
            }
            case 'Image': {
              return {
                dashboardExcelExportOptions: null,
                dashboardImageExportOptions: advancedOptions.dashboardImageExportOptions || null,
                dashboardPdfExportOptions: null,
                reportExcelExportOptions: null,
                reportImageExportOptions: null,
                reportPdfExportOptions: null
              }
            }
            case 'PDF': {
              return {
                dashboardExcelExportOptions: null,
                dashboardImageExportOptions: null,
                dashboardPdfExportOptions: advancedOptions.dashboardPdfExportOptions || null,
                reportExcelExportOptions: null,
                reportImageExportOptions: null,
                reportPdfExportOptions: null
              }
            }
            default:
              throw new Error('Invalid export format')
          }
        }
        case 'REPORT': {
          switch (generalValues.exportFormat) {
            case 'Excel': {
              return {
                dashboardExcelExportOptions: null,
                dashboardImageExportOptions: null,
                dashboardPdfExportOptions: null,
                reportExcelExportOptions: advancedOptions.reportExcelExportOptions || null,
                reportImageExportOptions: null,
                reportPdfExportOptions: null
              }
            }
            case 'Image': {
              return {
                dashboardExcelExportOptions: null,
                dashboardImageExportOptions: null,
                dashboardPdfExportOptions: null,
                reportExcelExportOptions: null,
                reportImageExportOptions: advancedOptions.reportImageExportOptions || null,
                reportPdfExportOptions: null
              }
            }
            case 'PDF': {
              return {
                dashboardExcelExportOptions: null,
                dashboardImageExportOptions: null,
                dashboardPdfExportOptions: null,
                reportExcelExportOptions: null,
                reportImageExportOptions: null,
                reportPdfExportOptions: advancedOptions.reportPdfExportOptions || null
              }
            }
            default:
              throw new Error('Invalid export format')
          }
        }
        default:
          throw new Error('Invalid analysis type')
      }
    }

    const createRequest: CreateSendingRequest = {
      name: generalValues.name,
      emailSettings: {
        subject: generalValues.subject,
        senderName: generalValues.senderName,
        recipients: generalValues.recipients
      },
      scheduleSettings: null,
      analysisSettings: {
        analysisId: generalValues.analysisId,
        exportFormat: generalValues.exportFormat as ExportFormat,
        ...getAnalysisSettings(generalValues, advancedOptions)
      }
    }

    const createSending = async () => {
      await sendingService.create(createRequest)
      enqueueSnackbar(t('pageSending.toast.created'), { variant: 'success' })
    }
    const updateSending = async () => {
      await sendingService.update(sending!.id, { id: sending!.id, ...createRequest })
      enqueueSnackbar(t('pageSending.toast.hasBeenUpdated'), { variant: 'success' })
    }

    try {
      if (!sending) {
        await createSending()
      } else {
        await updateSending()
      }
      handleClose(true)
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleDelete = async () => {
    setLoadingDelete(true)
    try {
      await sendingService._delete(sending!.id)
      handleClose(true)
      enqueueSnackbar(t('pageSending.toast.hasBeenDeleted'), { variant: 'success' })
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoadingDelete(false)
  }

  const handleClose = (refresh?: boolean) => {
    generalForm.reset()
    advancedForm.reset()
    setActiveTab(0)
    onClose?.(refresh)
  }

  return (
    <Dialog open={!!open} fullWidth fullScreen>
      <DialogTitle>
        <DialogTitleContent
          title={t('pageSending.modalCreate.title')!}
          description={t('pageSending.modalCreate.subTitle')!}
          onClose={() => handleClose()}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          indicatorColor="primary"
          value={activeTab}
          centered
          onChange={(e, value) => {
            setActiveTab(value)
            swiper?.slideTo(value)
          }}
          sx={{ pb: 2 }}
        >
          <Tab value={0} label={t('pageSending.modalCreate.tabGeneral.title')} />
          <Tab value={1} label={t('pageSending.modalCreate.tabAdvancedExportOptions')} />
          <Tooltip title={t('pageSending.modalCreate.tabSchedule.tooltip.comingSoon')}>
            <span>
              <Tab
                value={2}
                label={t('pageSending.modalCreate.tabSchedule.title')}
                disabled
                icon={<Lock />}
                iconPosition="end"
              />
            </span>
          </Tooltip>
        </Tabs>
        <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal">
          <SwiperSlide>
            <GeneralTab formProps={generalForm} analysis={analysis} analysisLoading={analysisLoading} />
          </SwiperSlide>
          <SwiperSlide>
            <AdvancedExportOptionsTab
              analysisType={selectedAnalysisType!}
              exportFormat={generalForm.getValues()?.exportFormat as ExportFormat}
              formProps={advancedForm}
            />
          </SwiperSlide>
          <SwiperSlide>schedule</SwiperSlide>
        </Swiper>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ConfirmDeleteButton
          sx={{ visibility: !sending ? 'hidden' : 'visible' }}
          loading={loadingDelete}
          color="error"
          variant="contained"
          onConfirm={handleDelete}
        >
          {t('pageSending.modalCreate.buttonDelete')}
        </ConfirmDeleteButton>
        <Button variant="contained" onClick={handleSubmit}>
          {t('pageSending.modalCreate.btnSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendingDialog
