import { FilterList, LibraryBooks, Map as MapIcon, Save, Share } from '@mui/icons-material'
import { Backdrop, Box, CircularProgress } from '@mui/material'
import GoogleMap from 'google-map-react'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import useSupercluster from 'use-supercluster'
import AddToCollectionDialog from '../../components/AddToCollectionDialog'
import AnalyzeSwipeableDrawer, { AnalyzeSwipeableDrawerOptionProps } from '../../components/AnalyzeSwipeableDrawer'
import ShareDialog from '../../components/ShareDialog'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { useCheckAnotherAccount } from '../../hooks/useCheckAnotherAccount'
import useUserGeoPosition from '../../hooks/useUserGeoPosition'
import SaveDialog from '../../pages/dashboard/extensions/SaveDialog'
import { AnalyzeSchema, AnalyzeType } from '../../schemas/AnalyzeSchema'
import { PlugFieldSchema } from '../../schemas/PlugSchema'
import { PlugClaimType } from '../../schemas/UserSchema'
import geoAnalysisService, {
  GeoAnalysisLoadDataParams,
  GeoAnalysisLoadDataResponse
} from '../../services/geoAnalysisService'
import { analyzeService } from '../../services/index'
import windowUtils from '../../utils/windowUtils'
import ClusteredMarker from './ClusteredMarker'
import GeoAnalysisTypeDialog, { GeoAnalysisType } from './GeoAnalysisTypeDialog'
import GeoFilterDialog from './GeoFilterDialog'
import GoogleMapExtended from './Map'

export enum ABC_CURVE_POSITION {
  'A',
  'B',
  'C'
}

export interface GeoAnalysisConfig {
  type: GeoAnalysisType | null
  latitude: PlugFieldSchema | null
  longitude: PlugFieldSchema | null
  dimensions: PlugFieldSchema[]
  values: PlugFieldSchema[]
  insideValue: PlugFieldSchema | null
  weightValue: PlugFieldSchema | null
  weightBy: string | null
  simpleMarkerColor: string | null
  aMarkerColor: string | null
  bMarkerColor: string | null
  cMarkerColor: string | null
}

interface GeoAnalysisPageProps {
  fromCollection?: boolean
  hideMenu?: boolean
  onBackButtonClick?: () => void
}

const A_BREAKPOINT = 70
const B_BREAKPOINT = 20
const C_BREAKPOINT = 10

const GeoAnalysisPage: React.FC<PropsWithChildren<GeoAnalysisPageProps>> = ({
  fromCollection,
  hideMenu,
  onBackButtonClick
}) => {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [saveACopyDialogOpen, setSaveACopyDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [addToCollDialogOpen, setAddToCollDialogOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const [markers, setMarkers] = useState<GeoAnalysisLoadDataResponse[] | any>([])
  const { id, collectionId } = useParams<{ id: any; collectionId: any }>()
  const { enqueueSnackbar } = useSnackbar()
  const [loadingData, setLoadingData] = useState(false)
  const [showBackdropLoading, setShowBackdropLoading] = useState(false)
  const [heatMapData, setHeatMapData] = useState<GoogleMap.Heatmap | undefined>()
  const [center, setCenter] = useState<GoogleMap.Coords | undefined>()
  const [geoLocation, setGeoLocation] = useUserGeoPosition()
  const [bounds, setBounds] = useState<number[] | null>(null)
  const [zoom, setZoom] = useState(2)
  const auth = useAuth()
  const userLocale = auth.getUserInfo()?.locale
  const [filterValue, setFilterValue] = useState<any>()
  const [analysisOrPlugId, setAnalysisOrPlugId] = useState<any>(id)
  const [plugId, setPlugId] = useState<number>(0)
  const [insideValueTotal, setInsideValueTotal] = useState<number>(0)
  const [analysis, setAnalysis] = useState<AnalyzeSchema>()
  const navigate = useNavigate()
  const isModalFirstOpen = useRef(true)
  const { t } = useTranslation()
  const [analysisConfig, setAnalysisConfig] = useState<GeoAnalysisConfig>({
    dimensions: [],
    insideValue: null,
    latitude: null,
    longitude: null,
    type: null,
    values: [],
    weightValue: null,
    weightBy: 'point',
    simpleMarkerColor: '#00b92a',
    aMarkerColor: '#5fff57',
    bMarkerColor: '#57b6ff',
    cMarkerColor: '#ffce73'
  })

  useEffect(() => {
    setAnalysisOrPlugId(id)
  }, [id])

  const userInfo = auth.getUserInfo()

  const isNew = !isNaN(Number(analysisOrPlugId))
  const isOwner = userInfo?.userId === analysis?.userOwner.id
  const canEdit = isOwner || analysis?.claimType === PlugClaimType.Analysis_RW
  const userProfile = userInfo?.profile

  const isFirstLoadRef = useRef(true)
  const { check } = useCheckAnotherAccount()

  useEffect(() => {
    if (!geoLocation) {
      navigator.geolocation.getCurrentPosition(setGeoLocation)
    }
  }, [geoLocation, setGeoLocation])

  const swipableDrawerOptions: AnalyzeSwipeableDrawerOptionProps[] = [
    {
      label: t('geoAnalysis.menuSave'),
      icon: <Save />,
      disabled: !isNew && !canEdit,
      action: async () => {
        if (isNew) {
          setSaveDialogOpen(true)
          return
        }
        try {
          await analyzeService.partialUpdate(analysisOrPlugId as string, {
            id: analysisOrPlugId,
            jsonState: JSON.stringify(getState())
          })
          enqueueSnackbar(t('geoAnalysis.toast.success.save', { name: analysis?.name }), { variant: 'success' })
        } catch (err: any) {
          enqueueSnackbar(t('geoAnalysis.toast.error.save', { msg: err.message }), { variant: 'error' })
        }
      }
    },
    {
      icon: (
        <img style={{ filter: 'invert(1)' }} src={ENDPOINTS.PUBLIC_IMAGES + '/save-as-icon.svg'} alt="save as icon" />
      ),
      label: t('geoAnalysis.menuSaveACopy'),
      disabled: !userProfile?.managingAnalyseAndCollection,
      action: () => setSaveACopyDialogOpen(true)
    },
    {
      icon: <Share />,
      label: t('geoAnalysis.menuShare'),
      disabled: isNew || !isOwner,
      action: () => setShareDialogOpen(true)
    },
    {
      icon: <LibraryBooks />,
      label: t('geoAnalysis.menuAddToCollection'),
      disabled: isNew,
      action: () => setAddToCollDialogOpen(true)
    },
    {
      label: t('geoAnalysis.analysisType'),
      icon: <MapIcon />,
      action: () => setDialogOpen(true)
    },
    {
      label: t('geoAnalysis.filter'),
      icon: <FilterList />,
      action: () => setFilterDialogOpen(true)
    }
  ]

  const getState = () => {
    return {
      analysisConfig,
      mapConfig: {
        zoom,
        bounds,
        filterValue,
        center
      }
    }
  }

  const setState = useCallback((state: ReturnType<typeof getState>) => {
    setBounds(state.mapConfig.bounds)
    setZoom(state.mapConfig.zoom)
    setCenter(state.mapConfig.center)
    if (state.mapConfig.filterValue) setFilterValue(state.mapConfig.filterValue)
  }, [])

  const groupByKeys = (values: Array<any>, dimensionkeys: Array<any>, valueKeys: Array<any>) => {
    const result = [
      ...values
        .reduce((r, o) => {
          const key = dimensionkeys.reduce((prev, cur) => (prev += ` - ${o[cur]}`), [])

          const item =
            r.get(key) ||
            Object.assign(
              {},
              o,
              valueKeys.reduce((p, c) => {
                p[c] = 0
                return p
              }, {})
            )
          for (const valueKey of valueKeys) {
            item[valueKey] += o[valueKey]
          }
          return r.set(key, item)
        }, new Map())
        .values()
    ]
    return result
  }

  const { clusters, supercluster } = useSupercluster({
    points: markers,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 20
    }
  })

  const getGroupedValuesFromCluster = useCallback(
    (cluster: any): any => {
      const allValues = []

      if (cluster.properties.cluster) {
        const childrenClusters = supercluster.getChildren(cluster.properties.cluster_id)
        for (const childCluster of childrenClusters) {
          const nextGroupedValues = getGroupedValuesFromCluster(childCluster)
          allValues.push(...nextGroupedValues)
        }
        return groupByKeys(
          allValues,
          analysisConfig.dimensions.map((x) => x.name),
          analysisConfig.values.map((x) => x.name)
        )
      }
      return cluster.properties.values
    },
    [analysisConfig.dimensions, analysisConfig.values, supercluster]
  )

  const loadGeoData = useCallback(
    async (value: NonNullable<GeoAnalysisConfig>) => {
      const valuesToSearch = [...value.values]
      if (value.insideValue) {
        valuesToSearch.push(value.insideValue)
      }
      try {
        const loadParams: GeoAnalysisLoadDataParams = {
          latitude: value.latitude!.name,
          longitude: value.longitude!.name,
          dimensions: value.dimensions?.map((x) => x.name) || [],
          values:
            valuesToSearch && valuesToSearch.length > 0
              ? valuesToSearch.map((x) => x.name)
              : value.weightValue
              ? [value.weightValue.name]
              : [],
          plugId: plugId || plugIdRef.current,
          filter: filterRef.current || filterValue
        }
        const { data } = await geoAnalysisService.loadData(loadParams)
        return data.filter((x) => x.lat !== null && x.lng !== null)
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('geoAnalysis.toast.error.loadData', { msg: err.message }), { variant: 'error' })
      }
      return []
    },
    [enqueueSnackbar, plugId, filterValue, t]
  )

  const configureMarkers = useCallback(
    async (value: GeoAnalysisConfig) => {
      setLoadingData(true)
      const markersData = await loadGeoData(value)
      setMarkers(markersData)

      setLoadingData(false)
      setDialogOpen(false)
    },
    [loadGeoData]
  )

  const configureHeatMap = useCallback(
    async (value: GeoAnalysisConfig) => {
      setLoadingData(true)
      const markersData = await loadGeoData(value)
      const data = markersData.map((x) => ({
        lat: x.lat,
        lng: x.lng,
        weight:
          value.weightBy !== 'point'
            ? x.values.map((x) => x[value.weightValue!.name]).reduce((a: number, b: number) => a + b)
            : 1
      }))
      const heatMapDataConfig = {
        positions: data,
        options: {
          radius: 20,
          opacity: 1
        }
      }

      setHeatMapData(heatMapDataConfig)

      setLoadingData(false)
      setDialogOpen(false)
    },
    [loadGeoData]
  )

  const configureClusteredMarkers = useCallback(
    async (value: GeoAnalysisConfig) => {
      setLoadingData(true)
      const markersData = await loadGeoData(value)
      setMarkers(
        markersData.map((marker) => ({
          type: 'Feature',
          properties: { cluster: false, values: marker.values },
          geometry: {
            type: 'Point',
            coordinates: [marker.lng, marker.lat]
          }
        }))
      )
      const insideTotal = markersData
        .map((x) => x.values)
        .reduce((prev, cur) => {
          prev += cur.reduce((subPrev, subCur) => {
            subPrev += subCur[value.insideValue!.name as any]
            return subPrev
          }, 0)
          return prev
        }, 0)
      setInsideValueTotal(insideTotal)
      setLoadingData(false)
      setDialogOpen(false)
    },
    [loadGeoData]
  )

  const handleDialogSubmit = useCallback(
    (value: GeoAnalysisConfig) => {
      setAnalysisConfig(value)
      setMarkers([])
      setHeatMapData(undefined)
      isModalFirstOpen.current = false
      switch (value.type) {
        case GeoAnalysisType.Markers: {
          configureMarkers(value)
          break
        }
        case GeoAnalysisType.MarkersClustered: {
          configureClusteredMarkers(value)
          break
        }
        case GeoAnalysisType.HeatMap: {
          configureHeatMap(value)
          break
        }
        default:
          return
      }
    },
    [configureClusteredMarkers, configureHeatMap, configureMarkers]
  )

  const plugIdRef = useRef(0)

  const loadGeoAnalysis = useCallback(async () => {
    if (isNew) {
      setPlugId(analysisOrPlugId)
      setDialogOpen(true)
      return
    }
    try {
      setShowBackdropLoading(true)
      const { data } = await analyzeService.getAnalyzeAndState(analysisOrPlugId)
      const pgId = data.plugs[0].id
      setPlugId(pgId)
      plugIdRef.current = pgId
      await check(data)
      setAnalysis(data)
      const parsedState = JSON.parse(data.state)
      filterRef.current = parsedState.mapConfig.filterValue
      setState(parsedState)
      handleDialogSubmit(parsedState.analysisConfig)
    } catch (err: any) {
      console.error(err)
      if (err.status === 403 || err.response?.status === 403) {
        if (fromCollection) {
          const generatedPath = generatePath('/collection/:collectionId/unauthorized', {
            collectionId: collectionId!
          })
          navigate(generatedPath, {
            state: err.response?.data
          })
          return
        }
        navigate(PATHS.UNAUTHORIZED, {
          state: err.response?.data
        })
        return
      } else if (err.status === 403 || err.response?.status === 403) {
        navigate('not-found')
        return
      }
      enqueueSnackbar(t('geoAnalysis.toast.error.loadAnalysis', { msg: err.message }), { variant: 'error' })
    }
    setShowBackdropLoading(false)
  }, [
    analysisOrPlugId,
    collectionId,
    enqueueSnackbar,
    fromCollection,
    handleDialogSubmit,
    isNew,
    navigate,
    setState,
    t,
    check
  ])

  useEffect(() => {
    if (isFirstLoadRef.current) {
      loadGeoAnalysis()
      isFirstLoadRef.current = false
    } else {
      isFirstLoadRef.current = true
    }
  }, [loadGeoAnalysis])

  const insideValueField = analysisConfig?.insideValue

  const getSummaryValuesFromCluster = useCallback(
    (cluster: any): number => {
      let summaryValue = 0

      if (cluster.properties.cluster) {
        const childrenClusters = supercluster.getChildren(cluster.properties.cluster_id)
        for (const childCluster of childrenClusters) {
          const nextSummaryValue = getSummaryValuesFromCluster(childCluster)
          summaryValue += nextSummaryValue
        }
        return summaryValue
      }
      if (insideValueField)
        summaryValue += cluster.properties.values
          .map((x: any) => x[insideValueField!.name as any])
          .reduce((a: number, b: number) => a + b)
      return summaryValue
    },
    [insideValueField, supercluster]
  )

  const intlFormat = useCallback(
    (num: number) => {
      return new Intl.NumberFormat(userLocale || windowUtils.getLanguage()).format(Math.round(num * 10) / 10)
    },
    [userLocale]
  )

  const makeFriendly = useCallback(
    (num: number) => {
      if (num >= 1000000000) {
        return intlFormat(num / 1000000000) + 'B'
      }
      if (num >= 1000000) return intlFormat(num / 1000000) + 'M'
      if (num >= 1000) return intlFormat(num / 1000) + 'k'
      return intlFormat(num)
    },
    [intlFormat]
  )

  const filterRef = useRef()

  const handleApplyFilter = (value: any) => {
    setFilterValue(value)
    filterRef.current = value
    handleDialogSubmit(analysisConfig)
  }

  const handleSaveNew = async (name: string) => {
    try {
      const resp = await analyzeService.create({
        name: name || analysis!.name,
        plugIds: [id],
        type: AnalyzeType.GEOANALYSE,
        jsonState: JSON.stringify(getState())
      })
      window.history.replaceState(null, '', generatePath(PATHS.GEO_ANALYSIS, { id: resp.data.id }))
      setAnalysisOrPlugId(resp.data.id)
      setAnalysis(resp.data)
      enqueueSnackbar(t('geoAnalysis.toast.success.save', { name: analysis?.name }), { variant: 'success' })
    } catch (err: any) {
      enqueueSnackbar(t('geoAnalysis.toast.error.save', { msg: err.message }), { variant: 'error' })
    }
    setSaveDialogOpen(false)
  }
  const handleSaveACopy = async (name: string) => {
    try {
      const resp = await analyzeService.create({
        name: name || analysis!.name,
        plugIds: [plugId],
        type: AnalyzeType.GEOANALYSE,
        jsonState: JSON.stringify(getState())
      })
      window.history.replaceState(null, '', generatePath(PATHS.GEO_ANALYSIS, { id: resp.data.id }))
      enqueueSnackbar(t('geoAnalysis.toast.success.saveCopy', { name: resp.data.name }), { variant: 'success' })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('geoAnalysis.toast.error.saveCopy', { msg: err.message }), { variant: 'error' })
    }
    setSaveACopyDialogOpen(false)
  }

  const markersRendered = useMemo(() => {
    if (analysisConfig.type === GeoAnalysisType.MarkersClustered) {
      clusters.forEach((x) => {
        x.totalInsideValue = getSummaryValuesFromCluster(x)
      })
      return clusters
        .sort((a, b) => a.totalInsideValue - b.totalInsideValue)
        .map((cluster) => {
          clusters.reduce((a, b) => {
            b.accumullatedPercentage = (b.totalInsideValue / insideValueTotal) * 100 + (a?.accumullatedPercentage || 0)
            return b
          }, 0)

          const [longitude, latitude] = cluster.geometry.coordinates
          const { cluster: isCluster } = cluster.properties

          // if (isCluster) {
          const groupedValues = getGroupedValuesFromCluster(cluster)
          return (
            <ClusteredMarker
              values={[...groupedValues]}
              dimensionColumns={analysisConfig.dimensions}
              valueColumns={analysisConfig.values}
              lat={latitude}
              lng={longitude}
              curvePosition={
                cluster.accumullatedPercentage >= A_BREAKPOINT
                  ? ABC_CURVE_POSITION.A
                  : cluster.accumullatedPercentage <= B_BREAKPOINT && cluster.accumullatedPercentage > C_BREAKPOINT
                  ? ABC_CURVE_POSITION.B
                  : ABC_CURVE_POSITION.C
              }
              aMarkerColor={analysisConfig.aMarkerColor!}
              bMarkerColor={analysisConfig.bMarkerColor!}
              cMarkerColor={analysisConfig.cMarkerColor!}
              simpleMarkerColor={analysisConfig.simpleMarkerColor || ''}
              insideValue={makeFriendly(cluster.totalInsideValue)}
            />
          )
          // }

          // return (
          //   <Marker
          //     key={`${longitude} - ${latitude}`}
          //     lat={latitude}
          //     lng={longitude}
          //     values={cluster.properties.values}
          //     dimensionColumns={analysisConfig.dimensions}
          //     valueColumns={analysisConfig.values}
          //     color={
          //       (analysisConfig.type === GeoAnalysisType.MarkersClustered
          //         ? analysisConfig.cMarkerColor
          //         : analysisConfig.simpleMarkerColor) || '#00b92a'
          //     }
          //   />
          // )
        })
    } else {
      return markers.map((marker: any, index: number) => {
        const { lat, lng } = marker
        return {
          id: index,
          lat: lat,
          lng: lng,
          dimensionColumns: analysisConfig.dimensions,
          valueColumns: analysisConfig.values,
          color: analysisConfig.simpleMarkerColor || '#00b92a',
          ...marker
        }
      })
    }
  }, [
    analysisConfig.aMarkerColor,
    analysisConfig.bMarkerColor,
    analysisConfig.cMarkerColor,
    analysisConfig.dimensions,
    analysisConfig.simpleMarkerColor,
    analysisConfig.type,
    analysisConfig.values,
    clusters,
    getGroupedValuesFromCluster,
    getSummaryValuesFromCluster,
    insideValueTotal,
    makeFriendly,
    markers
  ])

  return (
    <Box display="flex" width="100vw" height="100vh">
      <AnalyzeSwipeableDrawer
        hidden={hideMenu}
        showBackButton={fromCollection}
        onBackButtonClick={onBackButtonClick}
        analyzeName={analysis?.name || 'New Geo Analysis'}
        analyzeTypeName="Geo Analysis"
        options={swipableDrawerOptions}
      />
      <Box style={{ height: '100vh', width: '100%' }}>
        {analysisConfig.type === GeoAnalysisType.Markers ? (
          <GoogleMapExtended markers={markersRendered} />
        ) : (
          <GoogleMap
            defaultCenter={{
              lat: 10.99835602,
              lng: 77.01502627
            }}
            defaultZoom={2}
            center={isNew ? geoLocation || center : center}
            zoom={zoom}
            onChange={({ zoom, bounds, center }) => {
              setCenter(center)
              setZoom(zoom)
              setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
            }}
            heatmap={{
              positions: heatMapData?.positions || [],
              options: heatMapData?.options || { radius: 20, opacity: 1 }
            }}
            bootstrapURLKeys={{
              key: process.env.NODE_ENV !== 'development' ? 'AIzaSyDI0rkYEuiHy3Fm-M4ypg2-riAJGaXkD3g' : '',
              libraries: ['visualization']
            }}
          >
            {markersRendered}
          </GoogleMap>
        )}
      </Box>
      <GeoAnalysisTypeDialog
        geoAnalysisConfig={analysisConfig}
        plugId={plugId}
        onSubmit={handleDialogSubmit}
        open={dialogOpen}
        onClose={() => {
          if (isNew && isModalFirstOpen.current) {
            navigate(PATHS.HOME)
            return
          }
          setDialogOpen(false)
        }}
        loading={loadingData}
      />
      <GeoFilterDialog
        onConfirmChanges={handleApplyFilter}
        value={filterRef.current || filterValue}
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        plugId={plugId}
      />
      <SaveDialog
        title={t('geoAnalysis.modalSave.title')!}
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        onClickSave={(name) => handleSaveNew(name)}
      />
      <SaveDialog
        title={t('geoAnalysis.modalSaveCopyTitle')!}
        baseName={analysis?.name + ' - copy'}
        open={saveACopyDialogOpen}
        onClose={() => setSaveACopyDialogOpen(false)}
        onClickSave={(name) => handleSaveACopy(name)}
      />
      <ShareDialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)} analyzeOrCollection={analysis} />
      <AddToCollectionDialog
        title={t('geoAnalysis.modalCollectionTitleName')}
        onClose={() => setAddToCollDialogOpen(false)}
        description={t('geoAnalysis.modalCollectionSubtitle')}
        open={addToCollDialogOpen}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }} open={loadingData}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default GeoAnalysisPage
