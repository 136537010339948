import { Stack, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Template } from 'devextreme-react/core/template'
import FilterBuilderDevEx, { CustomOperation, IFilterBuilderOptions } from 'devextreme-react/filter-builder'
import { dxFilterBuilderField } from 'devextreme/ui/filter_builder'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, memo, useCallback, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ParameterSchema } from '../../schemas/ParameterSchema'
import { PlugFieldSchema } from '../../schemas/PlugSchema'
import { parameterService, plugService } from '../../services'
import { SelectBoxFilterFieldTagged } from './AnyOfOperationProps'
import BetweenOperationComponent from './BetweenOperationProps'
import isBlankOperationProps from './IsBlankOperationProps'
import isNotBlankOperationProps from './IsNotBlankOperationProps'
import SelectBoxFilterField from './SelectBoxFilterField'
import { ConditionValue, FilterBuilderValue } from './types'

interface FilterBuilderProps extends IFilterBuilderOptions {
  fields: PlugFieldSchema[]
  logicalOperators?: ('and' | 'or' | 'notAnd' | 'notOr')[]
  value: any
  onValueChanged?(value: any): void
  plugId?: number
  disableSearch?: boolean
}

export type PlugFieldStatusRem = PlugFieldSchema & {
  status: 'removed'
  selection?: {
    disabled?: boolean
  }
  inlineCellEditing?: {
    editingEnabled: boolean
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 999999,
    backgroundColor: 'inherit',
    '& .dx-filterbuilder-group': {
      '& .dx-filterbuilder-text': {
        '&.dx-filterbuilder-group-operation': {
          backgroundColor: 'rgba(244, 67, 54, 1)'
        },
        '&.dx-filterbuilder-item-field': {
          backgroundColor: 'rgba(39,166,153,1)'
        },
        '&.dx-filterbuilder-item-operation': {
          backgroundColor: 'rgba(76,175,80,1)'
        },
        '&.dx-filterbuilder-item-value': {
          backgroundColor: '#fff',
          fontSize: theme.typography.fontSize + 5
        },
        '& .dx-filterbuilder-item-value-text': {
          padding: '2px 7px 3px',
          backgroundColor: '#fff',
          color: theme.palette.text.primary,
          '&:focus': {
            color: theme.palette.text.primary,
            backgroundColor: '#fff'
          }
        }
      },
      '& .dx-icon-plus': {
        color: 'rgb(75,255,42)'
      },
      '& .dx-icon-remove': {
        color: 'rgba(244, 67, 54, 1)'
      },
      '& .dx-texteditor-input': {
        color: theme.palette.text.primary,
        padding: '6px 7px 6px 7px',
        fontSize: theme.typography.fontSize + 5
      },
      '& .dx-texteditor': {
        '&.dx-editor-underlined': {
          '&::after': {
            borderBottom: 'none'
          },
          '&.dx-state-hover': {
            '&::after': {
              borderBottom: 'none'
            }
          },
          '&.dx-state-focused': {
            '&::before': {
              border: 'none'
            }
          }
        }
      }
    },
    '&.dx-filterbuilder': {
      '& .dx-filterbuilder-group': {
        '& .dx-filterbuilder-text': {
          color: '#fff',
          fontSize: theme.typography.fontSize + 5
        }
      }
    },
    '& .dx-filterbuilder-action-icon': {
      fontSize: 25
    }
  },
  parameterFieldType: {
    '& .dx-filterbuilder-group': {
      '& .dx-filterbuilder-text': {
        '&.dx-filterbuilder-item-value': {
          backgroundColor: '#e47913'
        },
        '& .dx-filterbuilder-item-value-text': {
          backgroundColor: '#e47913',
          color: '#fff',
          '&:focus': {
            color: '#fff',
            backgroundColor: '#e47913'
          }
        },
        '& input': {
          color: '#fff'
        }
      }
    }
  }
}))

const supportedGroupOperations = ['and', 'or']
const supportedDataTypeFieldOperations = {
  string: ['contains', 'notcontains', 'startswith', 'endswith', '=', '<>', 'isblank', 'isnotblank', 'anyof'],
  numeric: ['=', '<>', '<', '>', '<=', '>=', 'between', 'isblank', 'isnotblank', 'anyof'],
  date: ['=', '<>', '<', '>', '<=', '>=', 'between', 'isblank', 'isnotblank', 'anyof'],
  datetime: ['=', '<>', '<', '>', '<=', '>=', 'between', 'isblank', 'isnotblank', 'anyof'],
  boolean: ['=', '<>', 'isblank', 'isnotblank'],
  object: ['isblank', 'isnotblank']
} as { [key: string]: string[] }

const isValidOperation = (value?: string) => {
  return value
    ? Object.values(supportedDataTypeFieldOperations)
        .reduce((acc, cur) => {
          for (const op of cur) {
            if (!acc.includes(op)) {
              acc.push(op)
            }
          }
          return acc
        }, [])
        .includes(value)
    : false
}

const isConditionalValue = (value: any): value is ConditionValue => {
  return value && typeof value === 'object' && 'type' in value
}

export const getFilterValueFields = (filterValue: FilterBuilderValue | undefined, fields: string[] = []): void => {
  if (!filterValue) return

  for (const segment of filterValue) {
    // nested condition
    if (Array.isArray(segment)) {
      getFilterValueFields(segment, fields)
      continue
    }
    if (segment === 'and' || segment === 'or' || isConditionalValue(segment) || isValidOperation(segment as string)) {
      continue
    }
    fields.push(segment as string)
  }
}

export const detectInvalidFilterFields = (
  filterValue: FilterBuilderValue | undefined,
  invalidFields: PlugFieldStatusRem[]
): string[] => {
  const inUseFields: string[] = []
  getFilterValueFields(filterValue, inUseFields)
  return inUseFields.filter((f) => invalidFields.some((ivf) => ivf.name === f))
}

const sortByCaption = (a: dxFilterBuilderField, b: dxFilterBuilderField) => {
  return (a.caption as string | undefined)?.localeCompare(b.caption!) || 0
}

const FilterBuilder: React.FC<PropsWithChildren<FilterBuilderProps>> = ({
  fields,
  logicalOperators,
  value,
  onValueChanged,
  ...rest
}) => {
  const [parsedFields, setParsedFields] = useState<dxFilterBuilderField[]>([])
  const [localValue, setLocalValue] = useState(value)
  const classes = useStyles()
  const [fieldValuesMap, setFieldValuesMap] = useState(new Map())
  const [parameters, setParameters] = useState<ParameterSchema[]>([])
  const [editorLoading, setEditoLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const { enqueueSnackbar } = useSnackbar()
  const filterBuilderRef = useRef<FilterBuilderDevEx>(null)

  const loadParameters = useCallback(async () => {
    try {
      const respParameters = await parameterService.getParameters()
      setParameters(respParameters.data)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar('Something went wrong when get parameters', { variant: 'error' })
    }
  }, [enqueueSnackbar])

  const handleSearchClick = async (fieldName: string) => {
    setEditoLoading(true)
    try {
      const { data } = await plugService.getFieldDistinctValues(rest.plugId!, fieldName)
      setFieldValuesMap((prev) => prev.set(fieldName, data))
    } catch (err: any) {
      enqueueSnackbar(`Something went wrong when get values for field ${fieldName}`, { variant: 'error' })
    }
    setEditoLoading(false)
  }

  const handleFieldValidations = useCallback(
    (value: any) => {
      try {
        filterBuilderRef.current?.instance.option('value', value)
        return true
      } catch (err: any) {
        setError(err.__details)
        filterBuilderRef.current?.instance.option('value', [])
        setLocalValue([])
        onValueChanged?.([])
        return false
      }
    },
    [onValueChanged]
  )

  useEffect(() => {
    loadParameters()
    const newParsedFields: dxFilterBuilderField[] = []
    const handleCustomizeText = (it: any) => {
      return it.value.value
    }
    for (const field of fields) {
      newParsedFields.push({
        dataField: field.name!,
        caption: field.label,
        dataType: field.type.toLowerCase() as any,
        filterOperations: supportedDataTypeFieldOperations[field.type.toLowerCase()],
        editorTemplate: 'selectBox',
        customizeText: handleCustomizeText
      })
    }
    setParsedFields(newParsedFields.sort(sortByCaption))
  }, [fields, value, loadParameters, handleFieldValidations])

  useEffect(() => {
    setTimeout(() => setLocalValue(handleFieldValidations(value) ? value : []), 100)
  }, [handleFieldValidations, value])

  const handleLocalValueChanged = (value: any) => {
    if (onValueChanged) {
      onValueChanged(value)
      return
    }
    setLocalValue(value)
  }

  return (
    <Stack>
      <ErrorBoundary FallbackComponent={(err) => <div>{err.error}</div>}>
        <FilterBuilderDevEx
          height="auto"
          style={{
            minHeight: 250
          }}
          ref={filterBuilderRef}
          className={clsx(classes.root, { [classes.parameterFieldType]: false })}
          fields={parsedFields as any}
          value={parsedFields.length > 0 ? localValue : ''}
          onValueChange={handleLocalValueChanged}
          bindingOptions={{ plugId: rest.plugId }}
          groupOperations={logicalOperators || (supportedGroupOperations as any)}
          {...rest}
        >
          <CustomOperation {...isBlankOperationProps} />
          <CustomOperation {...isNotBlankOperationProps} />
          <CustomOperation
            name="between"
            caption="Between"
            icon="range"
            editorComponent={(props) => (
              <BetweenOperationComponent
                {...props}
                disableSearch={rest.disableSearch}
                fieldValues={fieldValuesMap}
                parameters={parameters.filter((x) => x.type === 'Single')}
                loading={editorLoading}
                onSearchClick={handleSearchClick}
              />
            )}
          />
          <CustomOperation
            name="anyof"
            caption="Is on the list"
            icon="bulletlist"
            editorComponent={(props) => (
              <SelectBoxFilterFieldTagged
                {...props}
                disableSearch={rest.disableSearch}
                fieldValues={fieldValuesMap}
                parameters={parameters?.filter((x) => x.type === 'List')}
                loading={editorLoading}
                onSearchClick={handleSearchClick}
              />
            )}
          />
          <Template
            name="selectBox"
            component={(props: any) => (
              <SelectBoxFilterField
                {...props}
                disableSearch={rest.disableSearch}
                fieldValues={fieldValuesMap}
                parameters={parameters.filter((x) => x.type === 'Single')}
                loading={editorLoading}
                onSearchClick={handleSearchClick}
              />
            )}
          />
        </FilterBuilderDevEx>
      </ErrorBoundary>
      {error && <Typography color="error">{error}</Typography>}
    </Stack>
  )
}

export default memo(FilterBuilder, (prev: any, next: any) => {
  return JSON.stringify(prev.value) === JSON.stringify(next.value) && prev.fields === next.fields
})
