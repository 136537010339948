import { Email, ExpandLess, ExpandMore } from '@mui/icons-material'
import SendIcon from '@mui/icons-material/Send'
import {
  Autocomplete,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import { Stack } from '@mui/system'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DialogTitleContent from '../../../components/DialogTitleContent'
import sendingService, { SendingDispatch, SendingRecipientDispatch } from '../../../services/sendingService'
import { GroupedArray } from '../../../utils/arrayUtils'

export type SentHistoryDialogProps = {
  open?: boolean
  sendingId?: string | null
  onClose: () => void
}

export function SentHistoryDialog({ open, sendingId, onClose }: SentHistoryDialogProps) {
  const { t } = useTranslation()

  const dateRanges = [
    {
      label: t('sending.dateRanges.lastMinute'),
      startDate: () => moment().subtract(1, 'minute').utc().toDate(),
      endDate: () => moment().utc().toDate()
    },
    {
      label: t('sending.dateRanges.last5Minutes'),
      startDate: () => moment().subtract(5, 'minute').utc().toDate(),
      endDate: () => moment().utc().toDate()
    },
    {
      label: t('sending.dateRanges.last15Minutes'),
      startDate: () => moment().subtract(15, 'minute').utc().toDate(),
      endDate: () => moment().utc().toDate()
    },
    {
      label: t('sending.dateRanges.lastHour'),
      startDate: () => moment().subtract(1, 'hour').utc().toDate(),
      endDate: () => moment().utc().toDate()
    },
    {
      label: t('sending.dateRanges.today'),
      startDate: () => moment().startOf('day').utc().toDate(),
      endDate: () => moment().endOf('day').utc().toDate()
    },
    {
      label: t('sending.dateRanges.yesterday'),
      startDate: () => moment().subtract(1, 'day').startOf('day').utc().toDate(),
      endDate: () => moment().subtract(1, 'day').endOf('day').utc().toDate()
    },
    {
      label: t('sending.dateRanges.thisWeek'),
      startDate: () => moment().startOf('week').utc().toDate(),
      endDate: () => moment().endOf('week').utc().toDate()
    }
  ]
  const [dispatches, setDispatches] = useState<SendingDispatch[]>([])
  const [preDefinedRange, setPreDefinedRange] = useState<(typeof dateRanges)[0]>(dateRanges[0])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (!sendingId) {
      return
    }
    const startDate = preDefinedRange.startDate()
    const endDate = preDefinedRange.endDate()

    const loadDispatches = async () => {
      setLoading(true)
      try {
        const { data } = await sendingService.getDispatches(sendingId, startDate, endDate)
        setDispatches(data)
      } catch (err) {
        alert(err)
      }
      setLoading(false)
    }

    loadDispatches()
  }, [preDefinedRange, sendingId])

  const handleClose = () => {
    setPreDefinedRange(dateRanges[0])
    setDispatches([])
    onClose()
  }

  const preparedDispatches = _.reverse(_.sortBy(dispatches.groupBy('requestId'), (x) => x.items[0].createdDate))

  return (
    <Dialog open={!!open}>
      <DialogTitle>
        <DialogTitleContent
          title={t('sending.historyDialog.title')!}
          description={t('sending.historyDialog.subtitle')!}
          onClose={handleClose}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Autocomplete
            value={preDefinedRange}
            disableClearable
            disabled={loading}
            options={dateRanges}
            onChange={(e, newValue) => setPreDefinedRange(newValue)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('sending.historyDialog.searchIn')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <TextField
            label={t('sending.historyDialog.search')}
            type="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <List>
            {!loading && preparedDispatches.length === 0 ? (
              <Typography>{t('sending.historyDialog.noDispatches')}</Typography>
            ) : (
              preparedDispatches.map((dispatch) => (
                <DispatchListItem key={dispatch.key as string} dispatch={dispatch} filter={searchValue} />
              ))
            )}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export type DispatchListItemProps = {
  dispatch: GroupedArray<SendingDispatch>
  filter?: string
}

function DispatchListItem({ dispatch, filter }: DispatchListItemProps) {
  const [open, setOpen] = useState(false)

  const minCreatedDate = _.minBy(dispatch.items, (x) => x.createdDate)!.createdDate

  const stringMinCreatedDate = moment.utc(minCreatedDate).local().calendar()

  const recipientDispatches = dispatch.items.flatMap((x) => x.sendingRecipientsDispatches)

  const filteredRecipients = !filter
    ? recipientDispatches
    : recipientDispatches.filter((x) => x.emailAddress.toLocaleUpperCase().includes(filter.toLocaleUpperCase()))

  const recipientDispatchesSorted = _.sortBy(filteredRecipients, ['emailAddress', 'createdDate'])

  const groupedRecipientsByEmail = _.toPairs(_.groupBy(recipientDispatchesSorted, (x) => x.emailAddress))

  return (
    <>
      <ListItemButton onClick={() => setOpen((prev) => !prev)}>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary={stringMinCreatedDate} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {_.sortBy(groupedRecipientsByEmail, ['emailAddress', 'createdDate']).map((rd, i) => (
            <RecipientDispatchItem key={i} recipientDispatch={rd} />
          ))}
        </List>
      </Collapse>
    </>
  )
}
type RecipientDispatchItemProps = {
  recipientDispatch: [string, SendingRecipientDispatch[]]
}

const StatusSummaryMapping = {
  Pending: 'Pending',
  Sent: 'Pending',
  Accepted: 'Pending',
  Relayed: 'Sent',
  Failed: 'Failed',
  Suppressed: 'Failed'
}

function RecipientDispatchItem({ recipientDispatch }: RecipientDispatchItemProps) {
  const recipientEmailAddress = recipientDispatch[0]
  const recipientDispatches = recipientDispatch[1]
  const { t } = useTranslation()

  const lastRecipientDispatch = _.last(_.orderBy(recipientDispatches, 'createdDate', 'asc'))!
  const localCreatedDate = moment.utc(lastRecipientDispatch.createdDate).local().calendar()
  const summarizedStatus = StatusSummaryMapping[lastRecipientDispatch.status]
  return (
    <ListItem
      sx={{ pl: 4 }}
      secondaryAction={<Typography>{t(`sending.historyDialog.status.${summarizedStatus}`)}</Typography>}
    >
      <ListItemIcon>
        <Email />
      </ListItemIcon>
      <ListItemText
        primary={recipientEmailAddress}
        secondary={
          <>
            <Typography color={(th) => th.palette.text.primary}>
              {lastRecipientDispatch.name} <br />
              <Typography variant="caption">{localCreatedDate}</Typography>
            </Typography>
            {lastRecipientDispatch.error && (
              <Typography color="error" variant="caption">
                Error: {lastRecipientDispatch.error}
              </Typography>
            )}
          </>
        }
      />
    </ListItem>
  )
}
