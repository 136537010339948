import { Error } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, useTheme } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { DialogActions } from '../../components/Dialog'
import DialogTitleContent from '../../components/DialogTitleContent'
import TextField from '../../components/TextField'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'
import { analyzeService } from '../../services'

interface EditAnalyzeDialogProps extends DialogProps {
  description: string
  analyze: AnalyzeSchema | null
  onClose(): void
}

const EditAnalyzeDialog: React.FC<PropsWithChildren<EditAnalyzeDialogProps>> = ({
  title,
  description,
  onClose,
  analyze,
  ...other
}) => {
  const [editableAnalyze, setEditableAnalyze] = useState(analyze)
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const [loadingApiUpd, setLoadingApiUpd] = useState(false)
  const [loadingApiDelete, setLoadingApiDelete] = useState(false)
  const { t } = useTranslation()
  const deleteBtnString = t('pageHome.body.modalEdit.analysis.buttonDelete')
  const [deleteButtonLabel, setDeleteButtonLabel] = useState(deleteBtnString)

  const deleteIsConfirmed = !(deleteButtonLabel === deleteBtnString)

  useEffect(() => {
    setEditableAnalyze(analyze)
    return () => {
      setEditableAnalyze(null)
      setDeleteButtonLabel(deleteBtnString)
    }
  }, [analyze, deleteBtnString])

  const handleUpdateAnalyze = async () => {
    try {
      setLoadingApiUpd(true)
      await analyzeService.update(editableAnalyze!.id, editableAnalyze!)
      enqueueSnackbar(t('pageHome.body.modalEdit.toast.success.analysisUpdated'), { variant: 'success' })
      onClose()
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pageHome.body.modalEdit.toast.error.analysisUpdated'), { variant: 'error' })
    }
    setLoadingApiUpd(false)
  }

  const handleDeleteAnalyze = async () => {
    try {
      if (!deleteIsConfirmed) {
        setDeleteButtonLabel(t('pageHome.body.modalEdit.analysis.buttonConfirmDelete')!)
        return
      }
      setLoadingApiDelete(true)
      await analyzeService._delete(editableAnalyze!.id)
      enqueueSnackbar(t('pageHome.body.modalEdit.toast.success.analysisDeleted'), { variant: 'success' })
      onClose()
    } catch (err: any) {
      console.error(err)
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        enqueueSnackbar(
          t('pageHome.body.modalEdit.toast.error.analysisDeleted.sending.dependencyBlock', {
            sendings: (err.response.data.sendings as string[]).join(',')
          }),
          { variant: 'error' }
        )
      } else {
        enqueueSnackbar(t('pageHome.body.modalEdit.toast.error.analysisDeleted'), { variant: 'error' })
      }
    }
    setLoadingApiDelete(false)
  }

  const handleChangeEditableAnalyzeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableAnalyze((prev) => ({ ...prev!, name: event.target.value }))
  }

  return (
    <Dialog onClose={onClose} {...other}>
      <DialogTitle>
        <DialogTitleContent title={title} description={description} onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers>
        <Box justifyContent="center" display="flex">
          <TextField
            defaultValue={editableAnalyze?.name || ''}
            label={t('pageHome.body.modalEdit.analysis.name')}
            onChange={handleChangeEditableAnalyzeName}
          />
        </Box>
        {deleteIsConfirmed && (
          <Box
            display="inline-flex"
            maxWidth="55ch"
            alignItems="center"
            color={theme.palette.error.main}
            marginTop="12px"
          >
            <Error style={{ marginRight: '6px' }} /> {t('pageHome.body.modalEdit.deleteWarningMessage')}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          label={deleteButtonLabel}
          loading={loadingApiDelete}
          isDelete
          onClick={handleDeleteAnalyze}
        />
        <Button
          variant="contained"
          color="primary"
          label={t('pageHome.body.modalEdit.analysis.buttonSave')}
          loading={loadingApiUpd}
          onClick={handleUpdateAnalyze}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EditAnalyzeDialog
