import * as pdfjsLib from 'pdfjs-dist'
import * as PdfjsViewer from 'pdfjs-dist/web/pdf_viewer'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

pdfjsLib.GlobalWorkerOptions.workerSrc = '../../../../node_modules/pdfjs-dist/build/pdf.worker.entry.js'

const Viewer = () => {
  const location = useLocation()
  const pdfViewerRef = useRef<PdfjsViewer.PDFViewer | null>(null)
  const eventBusRef = useRef<PdfjsViewer.EventBus | null>(null)
  const pdfViewerContainerDivHtmlElementRef = useRef<HTMLDivElement>(null)
  const pdfViewerDivHtmlElementRef = useRef<HTMLDivElement>(null)

  const initialize = () => {
    const eventBus = new PdfjsViewer.EventBus()
    eventBusRef.current = eventBus

    const pdfViewer = new PdfjsViewer.PDFViewer({
      eventBus,
      container: pdfViewerContainerDivHtmlElementRef.current!,
      viewer: pdfViewerDivHtmlElementRef.current!,
      l10n: new PdfjsViewer.GenericL10n('en'),
      linkService: new PdfjsViewer.SimpleLinkService()
    })
    pdfViewerRef.current = pdfViewer
  }

  useEffect(() => {
    initialize()
    loadPdf()
  }, [])

  const loadPdf = async () => {
    const searchParams = new URLSearchParams(location.search)
    const file = searchParams.get('file')
    if (!!file) {
      try {
        const doc = await pdfjsLib.getDocument(file).promise
        pdfViewerRef.current?.setDocument(doc)
      } catch (err) {
        alert(JSON.stringify(err))
      }
    }
  }

  return (
    <div style={{ position: 'absolute' }}>
      <div style={{ position: 'absolute' }} ref={pdfViewerContainerDivHtmlElementRef}>
        <div ref={pdfViewerDivHtmlElementRef}></div>
      </div>
    </div>
  )
}

export default Viewer
