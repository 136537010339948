import React, { PropsWithChildren, useEffect, useState } from 'react'

interface DotsLoadingProps {
  dots?: number
  color?: string
}

const DotsLoading: React.FC<PropsWithChildren<DotsLoadingProps>> = ({ dots: maxDots = 3, color }) => {
  const [dots, setDots] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (dots === maxDots ? 1 : prev + 1))
    }, 300)

    return () => clearInterval(interval)
  })

  return <span color={color}>{'.'.repeat(dots)}</span>
}

export default DotsLoading
