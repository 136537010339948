import { AxiosResponse } from 'axios'

import api from '../api'

import { ENDPOINTS } from '../../constants/apiEndpoints'
import { PlugSchemaWithFields } from '../../schemas/PlugSchema'
import { DeniePlug, DeniePlugField, ProfileParameter, ProfileSchema } from '../../schemas/ProfileSchema'
import { format } from '../../utils/stringUtils'

const get = (): Promise<AxiosResponse<ProfileSchema[]>> => {
  return api.get(ENDPOINTS.PROFILES_GET_ALL)
}
const getPlugWithFields = (): Promise<AxiosResponse<PlugSchemaWithFields[]>> => {
  return api.get(ENDPOINTS.PROFILES_PLUGS_FIELDS)
}
const getPlugDenieds = (profileId: number): Promise<AxiosResponse<DeniePlug[]>> => {
  return api.get(format(ENDPOINTS.PROFILES_PLUG_DENIES, profileId))
}
const getPlugFieldDenieds = (profileId: number): Promise<AxiosResponse<DeniePlugField[]>> => {
  return api.get(format(ENDPOINTS.PROFILES_PLUG_FIELD_DENIES, profileId))
}

const updatePlugFieldDenieds = (profileId: number, plugFieldDenies: DeniePlugField[]): Promise<AxiosResponse<void>> => {
  return api.post(format(ENDPOINTS.PROFILES_PLUG_FIELD_DENIES, profileId), plugFieldDenies)
}
const updatePlugDenieds = (profileId: number, plugDenies: DeniePlug[]): Promise<AxiosResponse<void>> => {
  return api.post(format(ENDPOINTS.PROFILES_PLUG_DENIES, profileId), plugDenies)
}

const create = (profile: Omit<ProfileSchema, 'isSystemDefault' | 'id'>): Promise<AxiosResponse<ProfileSchema>> => {
  return api.post(ENDPOINTS.PROFILES_CREATE, profile)
}

const update = (profile: Omit<ProfileSchema, 'isSystemDefault'>): Promise<AxiosResponse<never>> => {
  return api.put(format(ENDPOINTS.PROFILES_UPDATE, profile.id), profile)
}

const _delete = (profileId: number): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.PROFILES_DELETE, profileId))
}

const getParameters = (profileId: number): Promise<AxiosResponse<ProfileParameter[]>> => {
  return api.get(format(ENDPOINTS.PROFILES_GET_PARAMETERS, profileId))
}
const updateParameters = (
  profileId: number,
  parameters: ProfileParameter[]
): Promise<AxiosResponse<ProfileParameter[]>> => {
  return api.put(format(ENDPOINTS.PROFILES_GET_PARAMETERS, profileId), parameters)
}

export default {
  get,
  update,
  create,
  _delete,
  getPlugDenieds,
  getPlugFieldDenieds,
  updatePlugDenieds,
  updatePlugFieldDenieds,
  getPlugWithFields,
  getParameters,
  updateParameters
}
