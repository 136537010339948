import { Cancel } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import AddToCollectionIcon from '@mui/icons-material/LibraryAdd'
import MoreIcon from '@mui/icons-material/MoreVert'
import ShareIcon from '@mui/icons-material/Share'
import {
  Box,
  Hidden,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import AnalyzeIcon, { AnalyzeIconTypes } from '../../components/AnalyzeIcon'
import ListItemText from '../../components/ListItemTextBase'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { AnalyzeSchema, AnalyzeType } from '../../schemas/AnalyzeSchema'
import { PlugClaimType } from '../../schemas/UserSchema'

interface AnalyzeListItemProps extends ListItemButtonProps {
  item: AnalyzeSchema
  showActions?: boolean
  onShareClick?(itemData: any): void
  onAddCollecionClick?(itemData: any): void
  onEditClick?(itemData: any): void
  onUnShareClick?(itemData: any): void
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    cursor: 'pointer',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  transform: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  },
  sharedByText: {
    fontSize: theme.typography.pxToRem(13),
    padding: theme.spacing(0.5, 0, 0.5, 0)
  },
  primaryText: {
    fontSize: theme.typography.pxToRem(16)
  },
  sharedTextContainer: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '220px'
    }
  }
}))

const TypePathMapping = {
  [AnalyzeType.PIVOTGRID]: PATHS.PIVOT_GRID,
  [AnalyzeType.DASHBOARD]: PATHS.DASHBOARD,
  [AnalyzeType.REPORT]: PATHS.REPORT,
  [AnalyzeType.GEOANALYSE]: PATHS.GEO_ANALYSIS,
  [AnalyzeType.COLLECTION]: PATHS.COLLECTION_VIEW
}

const AnalyzeListItem: React.FC<PropsWithChildren<AnalyzeListItemProps>> = ({
  item,
  onShareClick,
  onAddCollecionClick,
  onUnShareClick,
  onEditClick,
  showActions = true,
  ...props
}) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null)
  const moreMenuOpen = Boolean(moreMenuAnchorEl)
  const navigate = useNavigate()
  const auth = useAuth()
  const classes = useStyles()
  const { t } = useTranslation()

  const userInfo = auth.getUserInfo()

  const handleCloseMoreMenu = () => setMoreMenuAnchorEl(null)

  const handleOpenMoreMenu = (e: React.MouseEvent<HTMLElement>) => setMoreMenuAnchorEl(e.currentTarget)

  const handleEventDelegateWithItemValue = (delegate?: Function) => delegate?.(item)

  const handleOpenAnalyze = () => {
    if (item.type !== AnalyzeType.COLLECTION) {
      navigate(generatePath(TypePathMapping[item.type], { id: item.id }), { state: { hashCode: uuidv4() } })
      return
    }
    navigate(generatePath(TypePathMapping[item.type], { collectionId: item.id }))
  }

  const isOwner = userInfo?.userId === item.userOwner.id

  const canShare = () => {
    if (!userInfo) {
      return false
    }
    return item.claimType === PlugClaimType.Analysis_RW || item.claimType === PlugClaimType.Collection_RW || isOwner
  }

  const isShared = () => {
    if (!userInfo) {
      return false
    }
    return !isOwner
  }

  return (
    <ListItem
      className={classes.transform}
      secondaryAction={
        <>
          <Hidden lgDown>
            <Box display="flex" justifyContent="flex-end">
              {canShare() && (
                <IconButton onClick={() => handleEventDelegateWithItemValue(onShareClick)} size="large">
                  <ShareIcon />
                </IconButton>
              )}
              {item.type !== AnalyzeType.COLLECTION && (
                <IconButton onClick={() => handleEventDelegateWithItemValue(onAddCollecionClick)} size="large">
                  <AddToCollectionIcon />
                </IconButton>
              )}
              {isOwner && (
                <IconButton onClick={() => handleEventDelegateWithItemValue(onEditClick)} size="large">
                  <EditIcon />
                </IconButton>
              )}
              {isShared() && (
                <Tooltip title={t('pageHome.revokeShare')} aria-label={t('pageHome.revokeShare')!}>
                  <IconButton onClick={() => handleEventDelegateWithItemValue(onUnShareClick)} size="large">
                    <Cancel />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexDirection="column">
              <IconButton onClick={handleOpenMoreMenu} size="large">
                <MoreIcon />
              </IconButton>
              <Menu anchorEl={moreMenuAnchorEl} keepMounted open={moreMenuOpen} onClose={handleCloseMoreMenu}>
                <MenuItem onClick={() => handleEventDelegateWithItemValue(onShareClick)}>
                  <ListItemIcon>
                    <ShareIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={t('pageHome.menuOfAnalysisListItem.share')} />
                </MenuItem>
                {isOwner && (
                  <MenuItem onClick={() => handleEventDelegateWithItemValue(onEditClick)}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('pageHome.menuOfAnalysisListItem.edit')} />
                  </MenuItem>
                )}
                {isShared() && (
                  <MenuItem onClick={() => handleEventDelegateWithItemValue(onUnShareClick)}>
                    <ListItemIcon>
                      <Cancel fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('pageHome.revokeShare')} />
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Hidden>
        </>
      }
      disablePadding
    >
      <ListItemButton
        className={classes.root}
        classes={{
          root: classes.root
        }}
        role={undefined}
        dense
        onClick={handleOpenAnalyze}
        disableRipple
        disableTouchRipple
        focusRipple={false}
        style={{ backgroundColor: '#FFF' }}
      >
        <ListItemAvatar>
          <AnalyzeIcon type={item.type as AnalyzeIconTypes} color="secondary" />
        </ListItemAvatar>
        <ListItemText
          classes={{
            primary: classes.primaryText
          }}
          primary={item.name}
          secondary={
            <>
              {isShared() && (
                <Typography noWrap color="textPrimary" className={classes.sharedByText}>
                  {t('pageHome.sharedBy')} {item.userOwner.fullName}
                </Typography>
              )}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}

export default AnalyzeListItem
