import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { ApiConnectionType, DatabaseConnectionType } from '../../schemas/ConnectionSchema'

export interface ConnectionIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  type: DatabaseConnectionType | ApiConnectionType
}

const useStyles = makeStyles({
  root: {
    width: '36px',
    height: '48px'
  }
})

type ConnectionType = keyof typeof DatabaseConnectionType | keyof typeof ApiConnectionType

const ConnectionIconImageMap: Record<ConnectionType, string> = {
  MySql: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fmysql.jpg',
  MsSql:
    'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fsql-server.jpg',
  Firebird:
    'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Ffirebird.jpg',
  Redshift:
    'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fredshift.jpg',
  Movidesk:
    'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fmovidesk.jpg',
  GoogleApis:
    'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Fgoogle-apis.jpg',
  Facebook:
    'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/connection-images%2Ffacebook.jpg',
  CsvFile: 'csv-file.svg'
}

const ConnectionIcon: React.FC<PropsWithChildren<ConnectionIconProps>> = ({ type, className, src, ...props }) => {
  const classes = useStyles()

  const imagePath = ConnectionIconImageMap[type as ConnectionType]

  return <img src={imagePath} alt={type} className={clsx(classes.root, className)} />
}

export default ConnectionIcon
