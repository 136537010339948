import { Add } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Stack } from '@mui/system'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import moment, { Moment } from 'moment'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Loading from '../../../../../../components/Loading'
import SelectBox from '../../../../../../components/SelectBox'
import TextField from '../../../../../../components/TextField'
import MovideskConnectionForm from '../../../../../../components/forms/Movidesk/MovideskConnectionForm'
import { ApiConnectionType, ConnectionSchema } from '../../../../../../schemas/ConnectionSchema'
import { connectionService } from '../../../../../../services'
import NavigationHeader, { NavigationHeaderProps } from '../../NavigationHeader'

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(3, 0, 0)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

export type MovideskTicketsPlugFormValues = {
  connection: ConnectionSchema
  plugName: string
  startFetchDate: Moment
}

type MovideskTicketsPlugFormProps = {
  onSubmit: (values: MovideskTicketsPlugFormValues) => void
  navigationProps?: Partial<NavigationHeaderProps>
}

const validationSchema = yup.object({
  connection: yup.object().required(),
  plugName: yup.string().required()
})

const today = moment()
const last2years = moment().subtract(2, 'years')

const MovideskTicketsPlugForm: React.FC<PropsWithChildren<MovideskTicketsPlugFormProps>> = ({
  onSubmit,
  navigationProps
}) => {
  const classes = useStyles()
  const [connections, setConnections] = useState<ConnectionSchema[]>([])

  const [loadingFindConnection, setLoadingFindConnection] = useState(false)

  const [loadingCreateConnection, setLoadingCreateConnection] = useState(false)
  const [showCreateConnectionForm, setShowCreateConnectionForm] = useState(false)
  const { values, setFieldValue, submitForm, errors } = useFormik({
    validateOnChange: false,
    initialValues: {
      startFetchDate: today
    } as MovideskTicketsPlugFormValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema
  })

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const handleValuesChange = (key: string, value: any) => {
    setFieldValue(key, value)
  }

  const handleCreateConnection = async (values: any) => {
    values.type = ApiConnectionType.Movidesk
    try {
      setLoadingCreateConnection(true)
      const response = await connectionService.create(values)

      setConnections((prev) => [...prev, response.data])
      handleValuesChange('selectedConnection', response.data)
      setShowCreateConnectionForm(false)

      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createConnection', { connectionName: values.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.createConnection'), { variant: 'error' })
    } finally {
      setLoadingCreateConnection(false)
    }
  }

  const handleOpenSelectConnection = () => {
    ;(async () => {
      try {
        setLoadingFindConnection(true)
        const connsResp = await connectionService.getAll()
        setConnections(connsResp.data.filter((x) => x.type === ApiConnectionType.Movidesk))
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindConnection(false)
      }
    })()
  }

  useEffect(() => {
    handleValuesChange('tableOrView', 'Tickets')
  }, [])

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={() => submitForm()}
      />
      <Typography variant="h2" align="center" component="div" color="inherit">
        {t('pagePlugs.modalCreate.configureTitle')}
      </Typography>
      <Typography variant="subtitle1" align="center" component="p" color="inherit">
        {t('pagePlugs.modalCreate.configureSubtitle')}
      </Typography>
      {showCreateConnectionForm ? (
        <>
          {loadingCreateConnection ? (
            <Loading forceAlign plugFill="#fff" loopFill="#fff" />
          ) : (
            <MovideskConnectionForm
              usePlaceholder
              onCancelClick={() => setShowCreateConnectionForm(false)}
              onSubmit={handleCreateConnection}
              variant="white"
            />
          )}
        </>
      ) : (
        <Stack width="100%" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" className={classes.dialogFields}>
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.fullWidth)}
              options={connections}
              value={values.connection}
              onChange={(event, newValue) => handleValuesChange('connection', newValue)}
              getOptionLabel={(o) => o.name}
              loading={loadingFindConnection}
              onOpen={handleOpenSelectConnection}
              textFieldProps={{
                placeholder: t('pagePlugs.modalCreate.configureConnection')!,
                name: 'connection',
                error: !!errors['connection'],
                helperText: errors['connection'] as any
              }}
            />
            <IconButton onClick={() => setShowCreateConnectionForm(true)} color="inherit" size="large">
              <Add />
            </IconButton>
          </Box>
          <TextField
            variant="outlined"
            fullWidth={false}
            value={values.plugName}
            onChange={(event) => handleValuesChange('plugName', event.target.value)}
            required
            className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
            placeholder={t('pagePlugs.modalCreate.configureName')!}
            error={!!errors['plugName']}
            helperText={errors['plugName']}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disableFuture
              minDate={last2years}
              value={values.startFetchDate}
              onChange={(value) => handleValuesChange('startFetchDate', value)}
              slotProps={{
                textField: {
                  className: clsx(classes.outlinedWhiteInput, classes.dialogFields),
                  placeholder: 'start fetch date'
                }
              }}
            />
          </LocalizationProvider>
        </Stack>
      )}
    </>
  )
}
export default MovideskTicketsPlugForm
