import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box, CircularProgress, Divider, InputAdornment, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import Button from '../../../../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../../../../components/Dialog'
import FilterBuilder from '../../../../../components/FilterBuilder'
import WarningContainer from '../../../../../components/WarningContainer'
import { PlugFieldSchema, PlugFieldType } from '../../../../../schemas/PlugSchema'
import { plugService } from '../../../../../services'
import csvService, { CsvFilePlug } from '../../../../../services/csvService'
import PlugFieldsGrid from '../../../PlugFieldsGrid'
import { RelatedAnalysisDependecy } from '../Common/UpdateDialog'

type UpdatePlugDialogPropsBase = {
  plugId?: number
  open?: boolean
  onClose?: (reason: 'Cancelled' | 'Edited') => void
}

const getPlugFieldType = (type: string): PlugFieldType => {
  switch (type) {
    case 'Number':
      return 'NUMERIC'
    case 'DateOnly':
    case 'TimeOnly':
    case 'DateTime':
      return 'DATE'
    case 'Boolean':
      return 'BOOLEAN'
    case 'String':
    default:
      return 'STRING'
  }
}

const CsvFileUpdateDialog: React.FC<PropsWithChildren<UpdatePlugDialogPropsBase>> = ({ plugId, open, onClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [tabIndex, setTabIndex] = useState(0)
  const [deleteErrorBag, setDeleteErrorBag] = useState<{
    message: string
    dependents: RelatedAnalysisDependecy[]
  } | null>(null)
  const dltBtnText = t('pagePlugs.modalEdit.buttonDelete')
  const [deleteButtonText, setDeleteButtonText] = useState(dltBtnText)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingFields, setLoadingFields] = useState(false)
  const [isLoadingPlug, setIsLoadingPlug] = useState(true)
  const fieldsTabDirty = useRef(false)
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const { setError, setValue, control, getValues, trigger, watch, getFieldState } = useForm<CsvFilePlug>({
    values: {
      id: 0,
      name: '',
      filter: '',
      fields: [],
      delimiter: '',
      fileUrl: ''
    }
  })

  useEffect(() => {
    async function loadPlug() {
      if (!plugId) {
        return
      }
      setIsLoadingPlug(true)

      try {
        const { data: plugResponse } = await csvService.getCsvFilePlug(plugId)
        setValue('id', plugResponse.id)
        setValue('name', plugResponse.name)
        setValue('fileUrl', plugResponse.fileUrl)
        setValue('delimiter', plugResponse.delimiter)
        setValue('filter', plugResponse.filter)
        setIsLoadingPlug(false)
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' })
        setIsLoadingPlug(false)
      }
    }

    loadPlug()
  }, [enqueueSnackbar, plugId, setError, setValue])

  const resetState = () => {
    setTabIndex(0)
    setDeleteErrorBag(null)
    setDeleteButtonText(dltBtnText)
    setLoadingDelete(false)
    setLoadingUpdate(false)
  }

  const handleOpenAccessFieldsTab = () => {
    const { fileUrl, delimiter } = getValues()
    if (!fileUrl || !delimiter) return
    const loadFields = async () => {
      if (plugId) {
        try {
          setLoadingFields(true)
          const { data: plugFieldsResponse } = await plugService.getFields(plugId, false)
          const { data: columns } = await csvService.detectColumns({ fileUrl, delimiter })
          const mergedFields: PlugFieldSchema[] = []
          for (const column of columns.columnsMetadata) {
            const plugField = plugFieldsResponse.find((x) => x.name === column.name)
            mergedFields.push({
              name: column.name,
              type: column.type,
              label: plugField?.label || column.label
            })
          }
          setValue('fields', mergedFields)
        } catch (err: any) {
          enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.loadingFields'), { variant: 'error' })
        }
        setLoadingFields(false)
      }
    }

    loadFields()
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    const plugFields = watch('fields')
    if (changedFields) {
      const prevFields = [...plugFields]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setValue('fields', prevFields)
    }
  }

  const handleEnsureDeleteConfirmed = () => {
    if (deleteButtonText === t('pagePlugs.modalEdit.buttonDelete')) {
      setDeleteButtonText(t('pagePlugs.modalEdit.buttonConfirmDelete')!)
      return false
    }
    return true
  }

  const handleDeletePlug = async () => {
    if (!handleEnsureDeleteConfirmed()) return
    try {
      setLoadingDelete(true)
      await plugService._delete(plugId!)
      onClose?.('Edited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugDelete', { name: watch('name') }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      if (axios.isAxiosError(err)) {
        const plugDeleteError = err.response?.data as
          | {
              errors: Error[]
              dependents: RelatedAnalysisDependecy[]
            }
          | undefined

        const message = plugDeleteError?.errors[0].message || t('pagePlugs.modalEdit.toast.error.plugDelete')!

        setDeleteErrorBag({
          message: message,
          dependents: plugDeleteError?.dependents || []
        })
      } else {
        enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugDelete'), { variant: 'error' })
      }
    }
    setLoadingDelete(false)
  }
  async function handleUpdatePlug() {
    const isValid = await trigger()
    if (!isValid) return
    try {
      setLoadingUpdate(true)
      const { id, name, fields, filter } = getValues()
      await csvService.updateCsvFilePlug({
        id,
        name,
        fields,
        filter
      })
      onClose?.('Edited')
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.success.plugUpdate', { name: name }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalEdit.toast.error.plugUpdate'), { variant: 'error' })
    }
    setLoadingUpdate(false)
  }

  const handleChange = (tabIndex: number) => {
    setTabIndex(tabIndex)
    swiper?.slideTo(tabIndex)
    if (tabIndex === 1 && !fieldsTabDirty.current) {
      handleOpenAccessFieldsTab()
      fieldsTabDirty.current = true
    }
  }

  return (
    <Dialog
      open={!!open}
      title={t('pagePlugs.modalEdit.title')}
      description={t('pagePlugs.modalEdit.subtitle')}
      onClose={() => onClose?.('Cancelled')}
      fullWidth
      maxWidth="xl"
      TransitionProps={{
        onExited: resetState
      }}
      fullScreen
    >
      <DialogContent dividers>
        <Tabs indicatorColor="primary" centered value={tabIndex} onChange={(e, val) => handleChange(val)}>
          <Tab label={t('pagePlugs.modalEdit.buttonGeneral')} value={0} />
          <Tab label={t('pagePlugs.modalEdit.buttonFields')} value={1} />
          <Tab label={t('pagePlugs.modalEdit.buttonFilter')} value={2} />
        </Tabs>
        <Swiper onSwiper={setSwiper} allowTouchMove={false} direction="horizontal" simulateTouch={false}>
          <SwiperSlide>
            <Stack padding={2} spacing={3}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      endAdornment: isLoadingPlug ? (
                        <InputAdornment position="end">
                          <CircularProgress color="primary" size={20} />
                        </InputAdornment>
                      ) : undefined
                    }}
                    label={t('pagePlugs.modalEdit.name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    disabled={isLoadingPlug}
                  />
                )}
              />
              <Controller
                name="fileUrl"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      endAdornment: isLoadingPlug ? (
                        <InputAdornment position="end">
                          <CircularProgress color="primary" size={20} />
                        </InputAdornment>
                      ) : undefined
                    }}
                    label={t('pagePlugs.modalEdit.csv.fileUrl')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    disabled
                  />
                )}
              />
              <Controller
                name="delimiter"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputProps={{
                      value: field.value || '',
                      endAdornment: isLoadingPlug ? (
                        <InputAdornment position="end">
                          <CircularProgress color="primary" size={20} />
                        </InputAdornment>
                      ) : undefined
                    }}
                    label={t('pagePlugs.modalEdit.csv.delimiter')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    disabled
                  />
                )}
              />
            </Stack>
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
          <SwiperSlide>
            <PlugFieldsGrid
              loading={loadingFields}
              plugFields={getValues('fields')}
              onCommitChanges={handleFieldChanges}
            />
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
          <SwiperSlide>
            <Box p={2} height="auto">
              <Typography>Filter</Typography>
              <Divider />

              <FilterBuilder
                value={watch('filter')}
                onValueChanged={(val) => setValue('filter', val)}
                fields={[]}
                plugId={plugId}
              />

              {getFieldState('filter').error && 'Error: ' + getFieldState('filter').error}
            </Box>
            {deleteErrorBag && (
              <WarningContainer message={deleteErrorBag.message} relatedDependencies={deleteErrorBag.dependents} />
            )}
          </SwiperSlide>
        </Swiper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeletePlug} label={deleteButtonText} isDelete loading={loadingDelete} />
        <Button
          style={{ marginLeft: 'auto' }}
          isDelete
          onClick={() => onClose?.('Cancelled')}
          label={t('pagePlugs.modalEdit.cancel')}
        />
        <Button
          type="submit"
          onClick={handleUpdatePlug}
          label={t('pagePlugs.modalEdit.buttonSave')}
          loading={loadingUpdate}
        />
      </DialogActions>
    </Dialog>
  )
}

export default CsvFileUpdateDialog
