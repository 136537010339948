import { ListProps } from '@mui/material'
import React, { PropsWithChildren, memo, useState } from 'react'
import AnalyzeConnectionListItem from '../../components/AnalyzeConnectionListItem'
import List from '../../components/List'
import { PlugSchema } from '../../schemas/PlugSchema'

interface AnalyzeConnectionListProps extends ListProps {
  onSelectionChange?(connectionSelected?: any): void
  items: PlugSchema[]
}

const AnalyzeConnectionList: React.FC<PropsWithChildren<AnalyzeConnectionListProps>> = ({
  items,
  onSelectionChange,
  ...rest
}) => {
  const [selected, setSelected] = useState<any | null>(null)

  const handleSetSelected = (itemData: any) => {
    if (itemData.id === selected?.id) {
      setSelected(null)
      onSelectionChange?.(null)
      return
    }
    setSelected(itemData)
    onSelectionChange?.(itemData)
  }

  return (
    <List
      items={items}
      itemIdKey="id"
      itemLabelKey="name"
      ItemComponent={(params) => {
        return (
          <AnalyzeConnectionListItem
            key={params.item.id}
            plug={params.item}
            selected={params.item === selected}
            onClick={() => handleSetSelected(params.item)}
          />
        )
      }}
      {...rest}
    />
  )
}

export default memo(AnalyzeConnectionList, (prevProps, nextProps) => {
  return prevProps.items === nextProps.items
})
