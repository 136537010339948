import { CustomOperationPropsBase } from './types'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function calculateFilterExpression(_: any, field: any): any {
  return [field.dataField, 'is null']
}

const IsNotBlankOperationProps: CustomOperationPropsBase = {
  name: 'isnotblank',
  caption: 'Is not blank',
  icon: 'isnotblank',
  hasValue: false,
  calculateFilterExpression: calculateFilterExpression
}

export default IsNotBlankOperationProps
