import { ENDPOINTS } from '../../../constants/apiEndpoints'
import i18n from '../../../locales/i18next'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent } from '../types'

enum TotalsPriorOption {
  COLUMNS = 'columns',
  ROWS = 'rows',
  BOTH = 'both',
  NONE = 'none'
}

class ShowTotalsPriorExtension implements ComponentExtension {
  name = 'ShowTotalsPriorExtension'
  icon = ENDPOINTS.PUBLIC_IMAGES + '/totals_prior_icon.svg'
  text = i18n.t('pivotGrid.extensions.showTotalsPrior.text')
  items?: ComponentExtensionOption[] | undefined

  constructor(component: PivotGridComponent) {
    const selectedTotals = component.option('showTotalsPrior') as TotalsPriorOption
    const subOptions = [
      {
        text: i18n.t('pivotGrid.extensions.showTotalsPrior.rows'),
        icon: 'rowfield',
        onItemClick: () => toggleTotalsPrior(component, TotalsPriorOption.ROWS),
        selected: selectedTotals === TotalsPriorOption.ROWS,
        disabled: selectedTotals === TotalsPriorOption.ROWS
      },
      {
        text: i18n.t('pivotGrid.extensions.showTotalsPrior.columns'),
        icon: 'columnfield',
        onItemClick: () => toggleTotalsPrior(component, TotalsPriorOption.COLUMNS),
        selected: selectedTotals === TotalsPriorOption.COLUMNS,
        disabled: selectedTotals === TotalsPriorOption.COLUMNS
      },
      {
        text: i18n.t('pivotGrid.extensions.showTotalsPrior.both'),
        icon: 'smalliconslayout',
        onItemClick: () => toggleTotalsPrior(component, TotalsPriorOption.BOTH),
        selected: selectedTotals === TotalsPriorOption.BOTH,
        disabled: selectedTotals === TotalsPriorOption.BOTH
      },
      {
        text: i18n.t('pivotGrid.extensions.showTotalsPrior.none'),
        icon: 'clear',
        onItemClick: () => toggleTotalsPrior(component, TotalsPriorOption.NONE),
        selected: selectedTotals === TotalsPriorOption.NONE,
        disabled: selectedTotals === TotalsPriorOption.NONE
      }
    ]
    this.items = subOptions
  }
}

const toggleTotalsPrior = (component: PivotGridComponent, totalsPrior: TotalsPriorOption) => {
  component.option('showTotalsPrior', totalsPrior)
}

export default ShowTotalsPriorExtension
