import i18n from '../../../locales/i18next'
import { Keyword, KeywordGroup, KeywordType } from '../types'

const type = KeywordType.Constant

const constants: Keyword[] = [
  {
    name: i18n.t('expressionBuilder.constants.true.name'),
    description: i18n.t('expressionBuilder.constants.true.description'),
    type: type,
    group: KeywordGroup.NONE
  },
  {
    name: i18n.t('expressionBuilder.constants.false.name'),
    description: i18n.t('expressionBuilder.constants.false.description'),
    type: type,
    group: KeywordGroup.NONE
  },
  {
    name: i18n.t('expressionBuilder.constants.pi.name'),
    description: i18n.t('expressionBuilder.constants.pi.description'),
    type: type,
    group: KeywordGroup.NONE
  },
  {
    name: i18n.t('expressionBuilder.constants.e.name'),
    description: i18n.t('expressionBuilder.constants.e.description'),
    type: type,
    group: KeywordGroup.NONE
  },
  {
    name: i18n.t('expressionBuilder.constants.nan.name'),
    description: i18n.t('expressionBuilder.constants.nan.description'),
    type: type,
    group: KeywordGroup.NONE
  },
  {
    name: i18n.t('expressionBuilder.constants.null.name'),
    description: i18n.t('expressionBuilder.constants.null.description'),
    type: type,
    group: KeywordGroup.NONE
  },
  {
    name: i18n.t('expressionBuilder.constants.infinity.name'),
    description: i18n.t('expressionBuilder.constants.infinity.description'),
    type: type,
    group: KeywordGroup.NONE
  }
]

export default constants
