import { Search } from '@mui/icons-material'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import {
  SelectBoxFilterFieldProps,
  SelectDisplayValueTypeAdorment,
  transformToValueDefinition
} from './SelectBoxFilterField'
import { ConditionValue, ConditionValueType } from './types'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 40
  },
  input: {
    fontSize: '19px',
    padding: '2px 7px 3px',
    height: 29,
    '& fieldset': {
      display: 'none',
      padding: 0,
      margin: 0
    }
  }
}))

export const SelectBoxFilterFieldTagged = ({
  parameters,
  fieldValues,
  disableSearch,
  ...props
}: SelectBoxFilterFieldProps): any => {
  const classes = useStyles()

  const [selectedDisplayOption, setSelectedDisplayOption] = useState<ConditionValueType>(ConditionValueType.values)
  const [localValues, setLocalValues] = useState<any>(props.data.value)

  useEffect(() => {
    if (
      props.data.value &&
      !Array.isArray(props.data.value) &&
      props.data.value?.type === ConditionValueType.parameters
    ) {
      setSelectedDisplayOption(ConditionValueType.parameters)
    }
  }, [props.data.value])

  const setValueCb = props.data.setValue
  const value = props.data.value

  const multiple = selectedDisplayOption === ConditionValueType.values

  const handleChange = (e: any, newValue: any) => {
    const values: any[] = []
    if (multiple) {
      for (const newVal of newValue) {
        if (newVal in { value }) {
          values.push(newVal)
          continue
        }
        values.push(transformToValueDefinition(newVal, false))
      }
      setLocalValues(values)
      setValueCb(values)
      return
    }
    const valueDefinition = transformToValueDefinition(newValue, multiple)
    setLocalValues(valueDefinition)
    setValueCb(valueDefinition)
  }
  const isFreeSolo = selectedDisplayOption === ConditionValueType.values

  return (
    <Autocomplete
      value={multiple ? (Array.isArray(localValues) ? localValues : !!localValues ? [localValues] : []) : localValues}
      onChange={handleChange}
      fullWidth={false}
      className={classes.root}
      multiple={multiple}
      loading={props.loading}
      getOptionLabel={(opt) => (opt as any)?.value || ''}
      freeSolo={isFreeSolo}
      size="small"
      disablePortal
      options={
        !isFreeSolo
          ? parameters?.map((val) => transformToValueDefinition(val, false) as ConditionValue) ?? []
          : fieldValues
              ?.get(props.data.field.dataField!)
              ?.map((x) => transformToValueDefinition(x, false) as ConditionValue) ?? []
      }
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: 80 }}
          {...params}
          variant="outlined"
          placeholder="<enter a value>"
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            endAdornment: (
              <>
                {props.data.field.dataType === 'string' && !disableSearch && (
                  <IconButton onClick={() => props?.onSearchClick(props.data.field.dataField!)} size="large">
                    <Search />
                  </IconButton>
                )}
                <SelectDisplayValueTypeAdorment
                  selectedDisplayValue={selectedDisplayOption}
                  onChange={(opt) => setSelectedDisplayOption(opt)}
                />
              </>
            )
          }}
        />
      )}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const calculateAnyOfFilterExpression = (filterValue: any, field: any) => {
  return (
    filterValue &&
    filterValue.length &&
    Array.prototype.concat
      .apply(
        [],
        filterValue.map(function (value: any) {
          return [[field.dataField, '=', value], 'or']
        })
      )
      .slice(0, -1)
  )
}

// const AnyOfOperationProps: CustomOperationPropsBase = {
//   name: 'anyof',
//   caption: 'Is any of',
//   icon: 'check',
//   editorComponent: SelectBoxFilterFieldTagged,
//   calculateFilterExpression: calculateFilterExpression
// }
