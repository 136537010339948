import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React, { PropsWithChildren } from 'react'

interface RadioOptionHeatMapProps {
  value: 'value' | 'point' | string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: 'value' | 'point' | string) => void
}

const RadioOptionHeatMap: React.FC<PropsWithChildren<RadioOptionHeatMapProps>> = ({ value, onChange }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">How to consider the weight of each point</FormLabel>
      <RadioGroup row aria-label="weight" name="weight" defaultValue="point" value={value} onChange={onChange}>
        <FormControlLabel value="point" control={<Radio color="primary" />} label="By point" labelPlacement="bottom" />
        <FormControlLabel
          value="value"
          control={<Radio color="primary" />}
          label="By Value (uses Value Plug Field)"
          labelPlacement="bottom"
        />
      </RadioGroup>
    </FormControl>
  )
}

export default RadioOptionHeatMap
