import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  linearProgressClasses,
  styled
} from '@mui/material'
import { Stack } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import * as yup from 'yup'
import PluggerLogoTransp from '../../components/PluggerLogoTransp'
import useAuth from '../../hooks/useAuth'
import initAnimation from './animation'
import './index.css'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '100%',
  backgroundColor: '#101418',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#101418'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '10px',
    backgroundColor: '#39e991'
  }
}))

const alternativePrimaryColorTheme = createTheme({
  palette: {
    primary: {
      main: '#39e991'
    }
  }
})

const createUserSchema = yup.object({
  firstName: yup.string().required('Nome é obrigatório').label('Nome'),
  lastName: yup.string().required('Sobrenome é obrigatório').label('Sobrenome'),
  email: yup.string().email('email inválido').required('email é obrigatório'),
  password: yup.string().required('Senha é obrigatória').min(6, 'Senha deve ter no mínimo 6 caracteres').label('Senha')
})

const createCompanySchema = yup.object({
  companyName: yup.string().required('Nome da empresa é obrigatório').label('Nome'),
  role: yup.string(),
  employeeCount: yup.string()
})

type CreateUserFormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
}

type CreateCompanyFormValues = {
  companyName: string
  role?: string
  employeeCount?: string
}

const slidesCount = 6

export const SignUpPage = () => {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [slide, setSlide] = useState(1)
  const [showPassword, setShowPassword] = useState(false)
  const { control, handleSubmit } = useForm<CreateUserFormValues>({
    resolver: yupResolver(createUserSchema)
  })
  const { control: createCompanyControl, handleSubmit: handleCreateCompanySubmit } = useForm<CreateCompanyFormValues>({
    resolver: yupResolver(createCompanySchema)
  })

  useEffect(() => {
    initAnimation()
  }, [])

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: '355793896683-uqn4thce4p36d2ia6ib7apu7loh63u5b.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: false,
      context: 'signup',
      callback: (response) => {
        console.log(response)
        // setLoading(true)

        // if (response.credential) {
        //   authService
        //     .signUpGoogle(response.credential)
        //     .then(async ({ data }) => {
        //       const appToken = await auth.signInGoogle(response.credential)
        //       const searchParams = new URLSearchParams()
        //       searchParams.set('token', appToken)
        //       searchParams.set('email', data.email)
        //       searchParams.set('userId', data.id)
        //       searchParams.set('firstName', data.firstName)
        //       searchParams.set('lastName', data.lastName)
        //       navigate('/confirm?' + searchParams.toString())
        //     })
        //     .catch(async (err: any) => {
        //       if (err.response?.status === 409) {
        //         await auth.signInGoogle(response.credential)
        //         navigate('/new-account')
        //       } else {
        //         const message = err.response?.data?.message || err.message
        //         enqueueSnackbar(message, { variant: 'error' })
        //         setLoading(false)
        //       }
        //     })
        //}
      }
    })
    google.accounts.id.renderButton(document.getElementById('google-btn')!, {
      type: 'standard',
      size: 'large',
      width: '480',
      text: 'continue_with',
      theme: 'filled_black'
    })
  }, [auth, enqueueSnackbar, navigate, t])

  const slideNext = () => {
    setSlide((prev) => prev + 1)
    swiper?.slideNext()
  }
  const slidePrev = () => {
    setSlide((prev) => prev - 1)
    swiper?.slidePrev()
  }

  const handleCreateUser = (formValues: CreateUserFormValues) => {
    alert(JSON.stringify(formValues))
    swiper?.slideNext()
  }

  return (
    <ThemeProvider theme={alternativePrimaryColorTheme}>
      <div
        id="large-header"
        className="large-header"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <canvas style={{ display: 'block' }} id="demo-canvas"></canvas>
        <Paper
          elevation={4}
          sx={{
            maxWidth: 480,
            position: 'absolute',
            backgroundColor: '#101418',
            display: 'flex',
            borderRadius: '10px',
            maxHeight: '100%',
            overflowX: 'hidden',
            overflowY: 'auto'
          }}
        >
          <Stack spacing={4} alignItems="center" padding={4} color="#ffffff" height="100%">
            <PluggerLogoTransp
              src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grpzzbel76av/b/Public/o/logo%20transp%20horizontal.svg"
              style={{ marginBottom: 12 }}
            />
            <BorderLinearProgress variant="determinate" value={(slide / slidesCount) * 100} />
            <Swiper style={{ maxWidth: 400, maxHeight: '100%' }} allowTouchMove={false} onSwiper={setSwiper}>
              <SwiperSlide>
                <Stack spacing={6}>
                  <Typography color="#ffffff" fontSize="1.25rem">
                    Agora precisamos identifica-lo. É rápido, fácil e gratuito.
                  </Typography>
                  <Stack spacing={2}>
                    <div id="google-btn"></div>
                    <span style={{ color: '#fff', textAlign: 'center' }}> Ou </span>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: {
                              color: '#ffffff'
                            }
                          }}
                          required
                          fullWidth
                          label="Nome"
                          variant="outlined"
                          focused
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: {
                              color: '#ffffff'
                            }
                          }}
                          required
                          fullWidth
                          label="Sobrenome"
                          variant="outlined"
                          focused
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: {
                              color: '#ffffff'
                            }
                          }}
                          required
                          type="email"
                          fullWidth
                          label="Email"
                          variant="outlined"
                          focused
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          inputProps={{
                            style: {
                              color: '#ffffff'
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ color: '#ffffff' }}
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword((prev) => !prev)}
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          required
                          fullWidth
                          focused
                          label="Senha"
                          type={showPassword ? 'text' : 'password'}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <LoadingButton onClick={handleSubmit(handleCreateUser)} loading={loading}>
                      Criar
                    </LoadingButton>
                  </Stack>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing={2} maxHeight="calc(100% - 32px)" overflow="auto">
                  <Typography color="#ffffff" fontSize="1.25rem">
                    Qual o nome da empresa?
                  </Typography>
                  <TextField
                    inputProps={{
                      style: {
                        color: '#ffffff'
                      }
                    }}
                    fullWidth
                    variant="outlined"
                    focused
                  />
                  <Button onClick={slideNext}>Próximo</Button>
                  <Button onClick={slidePrev}> prev test</Button>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing={2}>
                  <Typography color="#ffffff" fontSize="1.25rem">
                    Qual sua função na empresa?
                  </Typography>
                  <Stack overflow="auto">
                    <Button>Analista </Button>
                    <Button>Coordenador</Button>
                    <Button>Gerente</Button>
                    <Button>Diretor(a)</Button>
                    <Button>CEO/Presidente</Button>
                    <Button>Empresário(a)</Button>
                    <Button>Desenvolvedor/Programador</Button>
                    <Button>Designer</Button>
                    <Button>Vendedor(a)</Button>
                    <Button>Consultor(a)</Button>
                    <Button>Assistente</Button>
                    <Button>Estagiário(a)</Button>
                    <Button>Outro</Button>
                    <Button onClick={slidePrev}> prev test</Button>
                  </Stack>
                </Stack>
              </SwiperSlide>
              <SwiperSlide>
                <Stack spacing={2} maxHeight="calc(100% - 32px)" overflow="auto">
                  <Typography color="#ffffff" fontSize="1.25rem">
                    Quantos functionários trabalham na sua empresa?
                  </Typography>
                  <Button> Somente eu </Button>
                  <Button> 2 a 5</Button>
                  <Button> 10 a 50</Button>
                  <Button> 50 ou mais</Button>
                  <Button onClick={slidePrev}> prev test</Button>
                </Stack>
              </SwiperSlide>
            </Swiper>
          </Stack>
        </Paper>
      </div>
    </ThemeProvider>
  )
}
