import { CustomOperationPropsBase } from './types'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function calculateFilterExpression(_: any, field: any): any {
  return [field.dataField, 'is null']
}

const IsBlankOperationProps: CustomOperationPropsBase = {
  name: 'isblank',
  caption: 'Is blank',
  icon: 'isblank',
  hasValue: false,
  calculateFilterExpression: calculateFilterExpression
}

export default IsBlankOperationProps
