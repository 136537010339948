import { Box, styled, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'

const Root = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: 'calc(100vh - 56px)',
  backgroundColor: theme.palette.background.light.contrast,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  color: '#ffffffc1',
  overflowWrap: 'break-word',
  maxWidth: '90%'
}))

const ErrorTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  color: theme.palette.error.main
}))

const DocumentError: React.FC<PropsWithChildren<Partial<Error>>> = (props) => {
  return (
    <Root>
      <ErrorTypography color="red" variant="h1">
        Error
      </ErrorTypography>
      <StyledTypography variant="h3">Message:</StyledTypography>
      <StyledTypography variant="body1">
        {props.message || props.name || props.stack || JSON.stringify(props)}
      </StyledTypography>
    </Root>
  )
}

export default DocumentError
