import ListItem, { ListItemProps, ListItemTypeMap } from '@mui/material/ListItem'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(3),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  container: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  }
}))

const ListItemBase: React.FC<PropsWithChildren<ListItemProps>> = ({ children, className, classes, ...props }) => {
  const defaultClasses = useStyles()
  const castedProps = { ...props } as ListItemTypeMap<{ button?: true }, 'li'>
  const { root, container, ...otherClasses } = classes || {}
  return (
    <ListItem
      classes={{
        container: clsx(defaultClasses.container, container),
        root: clsx(defaultClasses.root, className, root),
        ...otherClasses
      }}
      {...castedProps}
    >
      {children}
    </ListItem>
  )
}

export default ListItemBase
