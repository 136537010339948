import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type ConfirmDeleteButtonProps = {
  onConfirm: () => void
} & Omit<LoadingButtonProps, 'onClick'>

export function ConfirmDeleteButton({ onConfirm, ...rest }: ConfirmDeleteButtonProps) {
  const [confirmed, setConfirmed] = useState(false)
  const { t } = useTranslation()

  const handleConfirmOrDelete = () => {
    if (!confirmed) {
      setConfirmed(true)
      return
    }
    onConfirm()
  }

  return (
    <LoadingButton variant="contained" color="error" onClick={handleConfirmOrDelete} {...rest}>
      {!confirmed ? t('common.btn.delete') : t('common.btn.confirm.delete')}
    </LoadingButton>
  )
}
