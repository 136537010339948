import { Box, BoxProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 3,
    flexShrink: 0,
    backgroundColor: theme.palette.background.light.accent,
    height: '75%',
    overflow: 'auto'
  }
}))

const ContentBox: React.FC<PropsWithChildren<BoxProps>> = (props) => {
  const defaultClasses = useStyles()
  const { children, className, ...others } = props
  return (
    <Box className={clsx(defaultClasses.root, className)} {...others}>
      {children}
    </Box>
  )
}

export default ContentBox
