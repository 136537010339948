import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../../../components/Table'
import LowerCaseStringTypeProvider from '../../../../components/Table/LowerCaseStringTypeProvider'
import { PlugFieldSchema } from '../../../../schemas/PlugSchema'
import NavigationHeader, { NavigationHeaderProps } from './NavigationHeader'

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    backgroundColor: 'white',
    boxShadow: 'none',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
    width: '100%',
    minHeight: '500px'
  },
  tableBody: {
    backgroundColor: 'white',
    height: '200px!important',
    minHeight: '500px'
  }
}))

type FieldsGridProps = {
  fields: PlugFieldSchema[]
  onChange: (changes: ChangeSet) => void
  loading?: boolean
  navigationProps?: Partial<NavigationHeaderProps>
}

const FieldsGrid: React.FC<PropsWithChildren<FieldsGridProps>> = ({ navigationProps, fields, loading, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <NavigationHeader
        onClose={console.log}
        {...navigationProps}
        onBackClick={navigationProps?.onBackClick}
        onNextClick={navigationProps?.onNextClick}
      />
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h2" align="center" component="div" color="inherit">
          {t('pagePlugs.modalCreate.definitionsTitle')}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p" color="inherit">
          {t('pagePlugs.modalCreate.definitionsSubtitle')}
        </Typography>
        <Table
          classes={{
            table: classes.tableBody
          }}
          selectionDisabled
          className={classes.tableRoot}
          loading={loading}
          getRowId={(r) => r.name}
          rows={fields}
          editingProps={{
            onCommitChanges: onChange,
            columnExtensions: [
              {
                columnName: 'name',
                editingEnabled: false
              },
              {
                columnName: 'type',
                editingEnabled: false
              }
            ]
          }}
          columns={[
            {
              name: 'name',
              title: t('pagePlugs.modalCreate.definitionsField')!,
              canEdit: false
            },
            {
              name: 'label',
              title: t('pagePlugs.modalCreate.definitionsField.friendly')!
            },
            {
              name: 'type',
              title: t('pagePlugs.modalCreate.definitionsField.data')!,
              canEdit: false
            }
          ]}
        >
          <LowerCaseStringTypeProvider for={['type']} />
        </Table>
      </Box>
    </>
  )
}

export default FieldsGrid
