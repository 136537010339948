import AllIcon from '@mui/icons-material/ArtTrack'
import DashboardIcon from '@mui/icons-material/Assessment'
import ReportIcon from '@mui/icons-material/Description'
import CollectionIcon from '@mui/icons-material/LibraryBooks'
import GeoIcon from '@mui/icons-material/LocationOn'
import PivotGridIcon from '@mui/icons-material/PivotTableChart'

export const AVAILABLE_ANALYSIS_TYPES: { [key: string]: string } = {
  ALL: 'ALL',
  PIVOT_GRID: 'PIVOT GRID',
  DASHBOARD: 'DASHBOARD',
  REPORT: 'REPORT',
  GEOANALYSE: 'GEO ANALYZE',
  COLLECTION: 'COLLECTION'
}

const ANALYZE_TYPES_ICON = {
  ALL: AllIcon,
  PIVOT_GRID: PivotGridIcon,
  DASHBOARD: DashboardIcon,
  REPORT: ReportIcon,
  GEOANALYSE: GeoIcon,
  COLLECTION: CollectionIcon
}

export default ANALYZE_TYPES_ICON
