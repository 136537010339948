import { PropsWithChildren } from 'react'
import { PlugSchema, PlugType } from '../../../schemas/PlugSchema'
import CommonUpdateDialog from './components/Common/UpdateDialog'
import CsvFileUpdateDialog from './components/CsvFile/UpdateDialog'
import FacebookAdsUpdateDialog from './components/Facebook/FacebookAds/UpdateDialog'
import GoogleAdsUpdateDialog from './components/Google/GoogleAdsFlow/UpdateDialog'
import GoogleAnalyticsUpdateDialog from './components/Google/GoogleAnalytics/UpdateDialog'
import GoogleSheetsUpdateDialog from './components/Google/GoogleSheets/UpdateDialog'

const PlugTypeDialogMap = {
  [PlugType.GoogleAds]: GoogleAdsUpdateDialog,
  [PlugType.MySql]: CommonUpdateDialog,
  [PlugType.MsSql]: CommonUpdateDialog,
  [PlugType.Firebird]: CommonUpdateDialog,
  [PlugType.Redshift]: CommonUpdateDialog,
  [PlugType.FacebookAds]: FacebookAdsUpdateDialog,
  [PlugType.GoogleSheets]: GoogleSheetsUpdateDialog,
  [PlugType.Movidesk]: CommonUpdateDialog,
  [PlugType.GoogleAnalytics]: GoogleAnalyticsUpdateDialog,
  [PlugType.CsvFile]: CsvFileUpdateDialog
} as any

const UpdatePlugDialog: React.FC<PropsWithChildren<UpdatePlugDialogPropsBase>> = (props) => {
  const plugType = props.plug?.type || PlugType.MySql
  const DialogComponent = PlugTypeDialogMap[plugType as any] || CommonUpdateDialog
  return <DialogComponent {...props} />
}

export default UpdatePlugDialog

export type UpdatePlugDialogPropsBase = {
  open?: boolean
  plug?: PlugSchema | null
  plugId?: number
  onClose?: (reason: 'Exited' | 'Submited' | 'Edited' | 'Cancelled') => void
}
