import { Tooltip } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { makeStyles } from '@mui/styles'
import React, { PropsWithChildren } from 'react'
import analyzeTypesIcon from '../../constants/analyzeTypesIcon'

export type AnalyzeIconTypes = keyof typeof analyzeTypesIcon

interface AnalyzeIconProps extends SvgIconProps {
  type: AnalyzeIconTypes
  showTooltip?: boolean
}

const useStyles = makeStyles({
  root: {
    width: '36px',
    height: '48px'
  }
})

const typeToTitleMap: any = {
  PIVOT_GRID: 'Pivot Grid',
  DASHBOARD: 'Dashboard',
  GEOANALYSE: 'Geoanalysis',
  REPORT: 'Report'
}

const AnalyzeIcon: React.FC<PropsWithChildren<AnalyzeIconProps>> = ({ type, showTooltip, ...props }) => {
  const classes = useStyles()
  const CurrentIcon = analyzeTypesIcon[type]
  return (
    <>
      {!!showTooltip ? (
        <Tooltip title={typeToTitleMap[type]}>
          <CurrentIcon color="secondary" className={classes.root} {...props} />
        </Tooltip>
      ) : (
        <CurrentIcon color="secondary" className={classes.root} {...props} />
      )}
    </>
  )
}

export default AnalyzeIcon
