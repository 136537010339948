import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { FreeMode, Thumbs } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import './styles.css'

type TemplateViewerProps = {
  templates: Template[]
  selected: Template | null
  onSelect: (template: Template | null) => void
}

export type Template = {
  name: string
  plugType: string
  analyzeType: string
  thumbnailUrl: string
  content: string
  order: number
}

export const TemplateViewer: React.FC<TemplateViewerProps> = ({ templates, onSelect, selected }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  useEffect(() => {
    if (!!selected) {
      const index = templates.findIndex((template) => template.name === selected.name)
      swiper?.slideTo(index)
      setActiveIndex(index)
    } else {
      onSelect(templates[0])
    }
  }, [onSelect, selected, swiper, templates])

  useEffect(() => {
    swiper?.on('slideChange', (swipe: SwiperClass) => {
      setActiveIndex(swipe.activeIndex)
      onSelect(templates[swipe.activeIndex])
    })
  }, [onSelect, swiper, templates])

  return (
    <Stack spacing={1}>
      <Typography variant="h5">Comece com um modelo</Typography>
      <Swiper
        breakpointsBase="container"
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        breakpoints={{
          default: {
            slidesPerView: 2
          },
          0: {
            slidesPerView: 2
          },
          450: {
            slidesPerView: 4
          }
        }}
        freeMode={true}
        watchSlidesProgress
        modules={[FreeMode, Thumbs]}
        style={{
          height: '20%',
          boxSizing: 'border-box',
          padding: '10px 0'
        }}
      >
        {templates.map((template, i) => (
          <SwiperSlide
            style={{
              textAlign: 'center',
              fontSize: '18px',
              background: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            key={template.name}
          >
            <TemplateThumbnail template={template} isSelected={activeIndex === i} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        style={{
          height: '80%',
          width: '100%'
        }}
        onSwiper={setSwiper}
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        className="mySwiper2"
      >
        {templates.map((template) => (
          <SwiperSlide
            style={{
              textAlign: 'center',
              fontSize: '18px',
              background: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            key={template.name}
          >
            <img
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              src={template.thumbnailUrl}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  )
}

const TemplateThumbnail: React.FC<{ template: Template; isSelected: boolean }> = ({ template, isSelected }) => {
  return (
    <Stack
      spacing={2}
      sx={(theme) => ({
        border: isSelected ? `5px solid ${theme.palette.primary.main}` : 'none'
      })}
    >
      <img
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        src={template.thumbnailUrl}
      />
      <Typography>{template.name}</Typography>
    </Stack>
  )
}
