import { ArrowDropDown, Clear } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Checkbox, Link, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { accountService, userService } from '../../services'
import { ResponsivePaper } from '../confirm'
import { StyledTextField } from '../signIn'

const CreateNewAccountPage = () => {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const userInfo = auth.getUserInfo()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const validationSchema = yup.object({
    companyName: yup.string().required().label(t('signup.form.companyName'))
  })
  const { handleSubmit, values, getFieldProps, setFieldValue, submitForm } = useFormik({
    initialValues: {
      companyName: '',
      role: '',
      employeeCount: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const { data: userAccounts } = await accountService.getLoggedUserAccounts()
        const activeAndFreeAccounts = userAccounts.filter((x) => x.status === 'ACTIVE' || x.isFree)
        const planId = activeAndFreeAccounts.length === 0 ? 999 : 1

        const { data } = await userService.createAccount(userInfo!.userId!, {
          companyName: values.companyName,
          userRole: values.role,
          employeeCount: values.employeeCount,
          planId: planId
        })
        if (activeAndFreeAccounts.length > 0) {
          navigate('/pricing?accountId=' + data.accountId)
          return
        }
        await auth.accessAccount(data.accountId, auth.getToken()!)
        navigate(PATHS.HOME)
      } catch (err) {
        enqueueSnackbar(t('page.createNewAccount.error.unknown'), { variant: 'error' })
        setLoading(false)
      }
    }
  })

  const roleOptions = [
    { label: t('signup.role.option.analyst'), value: 'Analista' },
    { label: t('signup.role.option.coordinator'), value: 'Coordenador' },
    { label: t('signup.role.option.manager'), value: 'Gerente' },
    { label: t('signup.role.option.director'), value: 'Diretor' },
    { label: t('signup.role.option.ceo'), value: 'CEO/Presidente' },
    { label: t('signup.role.option.entrepreneur'), value: 'Empreendedor' },
    { label: t('signup.role.option.developer'), value: 'Desenvolvedor/Programador' },
    { label: t('signup.role.option.designer'), value: 'Designer' },
    { label: t('signup.role.option.salesperson'), value: 'Vendedor' },
    { label: t('signup.role.option.consultant'), value: 'Consultor' },
    { label: t('signup.role.option.assistant'), value: 'Assistente' },
    { label: t('signup.role.option.intern'), value: 'Estagiário' },
    { label: t('signup.role.option.other'), value: 'Outro' }
  ]
  const employeeCountOptions = [
    { label: t('signup.employeeCount.option.onlyMe'), value: 'Apenas eu' },
    { label: t('signup.employeeCount.option.2To5'), value: '2 a 5' },
    { label: t('signup.employeeCount.option.6To10'), value: '6 a 10' },
    { label: t('signup.employeeCount.option.11To25'), value: '11 a 25' },
    { label: t('signup.employeeCount.option.26To50'), value: '26 a 50' },
    { label: t('signup.employeeCount.option.51To200'), value: '51 a 200' },
    { label: t('signup.employeeCount.option.201To500'), value: '201 a 500' },
    { label: t('signup.employeeCount.option.501OrMore'), value: '501 ou mais' }
  ]

  return (
    <Stack
      direction="column"
      minHeight="100vh"
      sx={{
        background: (theme) =>
          `url(${ENDPOINTS.PUBLIC_IMAGES + '/bg_signin.svg'}),${theme.palette.background.light.contrast || '#FFF'}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
      }}
    >
      <ResponsivePaper sx={{ color: '#fff' }} elevation={5}>
        <Stack spacing={4}>
          <Typography color="inherit" variant="h3" maxWidth={400} textAlign="center">
            {t('createNewAccount.title')}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <StyledTextField required label={t('signup.form.companyName')} {...getFieldProps('companyName')} />
              <Autocomplete
                value={values['role'] as any}
                onChange={(event, newValue) => setFieldValue('role', newValue)}
                renderInput={(props) => <StyledTextField {...props} label={t('signup.form.role')} />}
                getOptionLabel={(option) => (!!option ? roleOptions.find((x) => x.value === option)?.label ?? '' : '')}
                clearIcon={<Clear fontSize="small" htmlColor="#fff" />}
                popupIcon={<ArrowDropDown fontSize="small" htmlColor="#fff" />}
                options={roleOptions.map((x) => x.value)}
              />
              <Autocomplete
                value={values['employeeCount'] as any}
                onChange={(event, newValue) => setFieldValue('employeeCount', newValue)}
                renderInput={(props) => <StyledTextField {...props} label={t('signup.form.employCount')} />}
                getOptionLabel={(option) =>
                  !!option ? employeeCountOptions.find((x) => x.value === option)?.label ?? '' : ''
                }
                clearIcon={<Clear fontSize="small" htmlColor="#fff" />}
                popupIcon={<ArrowDropDown fontSize="small" htmlColor="#fff" />}
                options={employeeCountOptions.map((x) => x.value)}
              />
              <Stack direction="row" alignItems="center">
                <Checkbox required checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />
                <Typography variant="body2" color="#fff">
                  Confirmo que li e concordo com os{' '}
                  <Link component="a" href="https://pluggerbi.com/termo-de-servico/" target="_blank">
                    Termos de Serviço
                  </Link>{' '}
                  e{' '}
                  <Link component="a" href="https://pluggerbi.com/politica-de-privacidade/" target="_blank">
                    Politica de Privacidade
                  </Link>
                </Typography>
              </Stack>
              <LoadingButton
                disabled={!termsAccepted}
                loading={loading}
                onClick={() => submitForm()}
                variant="contained"
              >
                {t('createNewAccount.btn')}
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </ResponsivePaper>
    </Stack>
  )
}

export default CreateNewAccountPage
