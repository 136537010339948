import { ChangeSet } from '@devexpress/dx-react-grid'
import { Box } from '@mui/material'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { PlugFieldSchema } from '../../../../../../schemas/PlugSchema'
import { plugService } from '../../../../../../services'
import { FlowComponentBaseProps } from '../../../types'
import FieldsGrid from '../../FieldsGrid'
import FilterView from '../../FilterView'
import GoogleAnalyticsPlugForm, { GoogleAnalyticsPlugFormValues } from './GoogleAnalyticsPlugForm'
import GoogleAnalyticsPropertiesList from './components/GoogleAnalyticsPropertiesList'

const GoogleAnalyticsFlow: React.FC<PropsWithChildren<FlowComponentBaseProps>> = ({ plug, onBackClick, onClose }) => {
  const [index, setIndex] = useState(0)
  const [plugFormValues, setPlugFormValues] = useState<GoogleAnalyticsPlugFormValues | null>(null)
  const [selectedProperties, setSelectedProperties] = useState<PlugFieldSchema[]>([])
  const [filterValue, setFilterValue] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  const goNextPage = () => {
    swiper?.slideNext()
  }

  const goBackPage = () => {
    swiper?.slidePrev()
  }

  const handleSubmitPlugForm = (values: GoogleAnalyticsPlugFormValues) => {
    setPlugFormValues(values)
    goNextPage()
  }

  const handleNextDimensionAndMetrics = (selectedDimensionAndMetrics: PlugFieldSchema[]) => {
    setSelectedProperties(selectedDimensionAndMetrics)
    goNextPage()
  }

  const handleFieldChanges = (changes: ChangeSet) => {
    const changedFields = changes.changed
    if (changedFields) {
      const prevFields = [...selectedProperties]
      for (const rowId in changedFields) {
        const changedRowIndex = prevFields.findIndex((e) => e.name === rowId)
        prevFields[changedRowIndex] = { ...prevFields[changedRowIndex], ...changedFields[rowId] }
      }
      setSelectedProperties(prevFields)
    }
  }

  const handleClose = () => {
    onClose('Exited')
  }

  const handleCreatePlug = async () => {
    const newPlug = {
      connectionId: plugFormValues!.connection.id,
      fields: selectedProperties,
      name: plugFormValues!.plugName,
      filter: filterValue,
      account: plugFormValues!.account,
      property: plugFormValues!.property
    }
    try {
      await plugService.createGoogleAnalytics(newPlug)
      onClose('EndFlow')
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createPlug', { name: newPlug.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  return (
    <Box>
      <Swiper onSwiper={setSwiper}>
        <SwiperSlide>
          <Box
            color="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <GoogleAnalyticsPlugForm
              navigationProps={{ onNextClick: goNextPage, onClose: handleClose, onBackClick: onBackClick }}
              onSubmit={handleSubmitPlugForm}
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {plugFormValues && (
              <GoogleAnalyticsPropertiesList
                connectionId={plugFormValues.connection.id}
                property={plugFormValues.property.id}
                navigationProps={{
                  onBackClick: goBackPage,
                  onNextClick: handleNextDimensionAndMetrics,
                  onClose: handleClose
                }}
              />
            )}
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <FieldsGrid
              fields={selectedProperties}
              onChange={handleFieldChanges}
              navigationProps={{
                onBackClick: goBackPage,
                onNextClick: goNextPage,
                onClose: handleClose
              }}
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            color="white"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <FilterView
              fields={selectedProperties}
              value={filterValue}
              onChange={(val) => setFilterValue(val)}
              navigationProps={{
                onBackClick: goBackPage,
                onClose: handleClose,
                onNextClick: handleCreatePlug,
                nextDisabled: true
              }}
            />
          </Box>
        </SwiperSlide>
      </Swiper>
    </Box>
  )
}

export default GoogleAnalyticsFlow
