import { Edit, SvgIconComponent } from '@mui/icons-material'
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MaterialListItem,
  ListItemProps as MaterialListItemProps,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { staticService } from '../../services'
import base64Utils from '../../utils/base64Utils'
import randomUtils from '../../utils/randomUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
      margin: theme.spacing(0.5, 0),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  container: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&:hover': {
      transform: 'translate3d(0,-6px,100px)'
    }
  },
  avatar: {
    backgroundColor: (props: { randomColor: string }) => props.randomColor
  },
  primaryText: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '120px'
    }
  }
}))

export interface ListItemProps extends Omit<MaterialListItemProps, 'button'> {
  itemId: any
  startIcon?: SvgIconComponent | JSX.Element
  endIcon?: SvgIconComponent
  primaryText: string
  button?: false | undefined
  secondaryText?: string
  endText?: string
  imageSrc?: string
  hideEditIcon?: boolean
  onEdit(itemId: any): void
  item?: any
  primaryTextString: string
}

const ListItem: React.FC<PropsWithChildren<ListItemProps>> = ({
  itemId,
  primaryText,
  primaryTextString,
  secondaryText,
  endText,
  startIcon: StartIcon,
  endIcon: EndIcon = Edit,
  imageSrc,
  className,
  button,
  onEdit,
  classes,
  hideEditIcon,
  ...others
}) => {
  const memoColorByString = useMemo<string>(() => randomUtils.getColorByString(primaryTextString), [primaryTextString])
  const classesDefault = useStyles({ randomColor: memoColorByString })
  const [imageSrcB64, setImageSrcB64] = useState<string | null>(null)

  useEffect(() => {
    if (imageSrc)
      staticService.get(imageSrc).then(async (response) => {
        const imgDataUri = await base64Utils.convertBlobToDataUri(response.data)
        setImageSrcB64(imgDataUri)
        return
      })
    setImageSrcB64(null)
  }, [imageSrc])
  return (
    <MaterialListItem
      button={button}
      classes={{ container: classesDefault.container, ...classes }}
      className={clsx(classesDefault.root, className)}
      key={itemId}
      {...others}
    >
      <ListItemAvatar>
        {StartIcon ? (
          React.cloneElement(StartIcon as any)
        ) : (
          <Avatar alt={primaryTextString} src={imageSrcB64 || '/broken-image.jpg'} className={classesDefault.avatar} />
        )}
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classesDefault.primaryText
        }}
        primary={primaryText}
        secondary={secondaryText}
      />
      <ListItemSecondaryAction hidden={hideEditIcon}>
        <IconButton onClick={() => onEdit(itemId)} size="large">
          <EndIcon />
        </IconButton>
        {endText && <Typography color="textPrimary">{endText}</Typography>}
      </ListItemSecondaryAction>
    </MaterialListItem>
  )
}

export default ListItem
