import i18n from '../../../locales/i18next'
import { ComponentExtension, PivotGridComponent, PivotGridField } from '../types'

class ExpandAllExtension implements ComponentExtension {
  name: string
  icon: string
  text: string
  onItemClick?: () => void

  constructor(component: PivotGridComponent, field: PivotGridField) {
    this.name = 'ExpandAllExtension'
    this.icon = 'expand'
    this.text = i18n.t('pivotGrid.extensions.expandAll.text')
    this.onItemClick = () => expandAll(field, component)
  }
}

const expandAll = (field: PivotGridField, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  dataSource.expandAll(field.index!)
}

export default ExpandAllExtension
