import {
  DashboardControl,
  IExtension,
  ItemWidgetOptionEventArgs,
  ViewerApiExtension
} from 'devexpress-dashboard/common'
import { CustomizeSectionsEventArgs, OptionsPanelExtension } from 'devexpress-dashboard/designer'
import * as Model from 'devexpress-dashboard/model'

const ChartXAxisFontFormattingExtension = (function () {
  // 1. Model
  const fontSizeAxisXProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'fontSizeAxisXProperty',
    defaultValue: 12,
    valueType: 'number'
  }

  const fontWeightAxisXProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'FontWeightAxisXProperty',
    defaultValue: 400,
    valueType: 'number'
  }
  const fontColorAxisXProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'FontColorAxisXProperty',
    defaultValue: '#767676',
    valueType: 'string'
  }
  const fontOpacityAxisXProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'FontOpacityAxisXProperty',
    defaultValue: 1,
    valueType: 'number'
  }

  Model.registerCustomProperty(fontSizeAxisXProperty)
  Model.registerCustomProperty(fontWeightAxisXProperty)
  Model.registerCustomProperty(fontColorAxisXProperty)
  Model.registerCustomProperty(fontOpacityAxisXProperty)

  // 2. Viewer
  function onItemWidgetOptionsPrepared(args: ItemWidgetOptionEventArgs) {
    let fontSize = args.dashboardItem.customProperties.getValue(fontSizeAxisXProperty.propertyName)
    let fontWeight = args.dashboardItem.customProperties.getValue(fontWeightAxisXProperty.propertyName)
    let fontColor = args.dashboardItem.customProperties.getValue(fontColorAxisXProperty.propertyName)
    let fontOpacity = args.dashboardItem.customProperties.getValue(fontOpacityAxisXProperty.propertyName)
    var argumentAxisOptions = (args.options as any)['argumentAxis']
    if (argumentAxisOptions) {
      argumentAxisOptions.label.font = {
        size: fontSize,
        weight: fontWeight,
        color: fontColor,
        opacity: fontOpacity
      }
    }
  }

  // 3. Designer
  function onCustomizeSections(args: CustomizeSectionsEventArgs) {
    args.addSection({
      title: 'Eixo X - Fonte',
      items: [
        {
          dataField: fontSizeAxisXProperty.propertyName,
          editorType: 'dxNumberBox',
          label: {
            text: 'Tamanho'
          },
          editorOptions: {
            showSpinButtons: true
          }
        },
        {
          dataField: fontWeightAxisXProperty.propertyName,
          editorType: 'dxSelectBox',
          label: {
            text: 'Estilo'
          },
          editorOptions: {
            items: [
              { value: 400, displayValue: 'Normal' },
              { value: 700, displayValue: 'Negrito' },
              { value: 1000, displayValue: 'Negrito Extra' }
            ],
            displayExpr: 'displayValue',
            valueExpr: 'value'
          }
        },
        {
          dataField: fontColorAxisXProperty.propertyName,
          editorType: 'dxColorBox',
          label: {
            text: 'Cor'
          },
          editorOptions: {
            opened: false
          }
        },
        {
          dataField: fontOpacityAxisXProperty.propertyName,
          editorType: 'dxNumberBox',
          label: {
            text: 'Opacidade'
          },
          editorOptions: {
            max: 1,
            min: 0,
            format: '#0%',
            step: 0.01,
            showSpinButtons: true
          }
        }
      ]
    })
  }
  // 4. Event Subscription
  function ChartXAxisFontFormattingExtension(this: IExtension, dashboardControl: DashboardControl) {
    this.name = 'ChartXAxisFontFormattingExtension'
    this.start = function () {
      var viewerApiExtension = dashboardControl.findExtension('viewer-api') as ViewerApiExtension
      if (viewerApiExtension) {
        viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
      }
      var optionsPanelExtension = dashboardControl.findExtension('item-options-panel') as OptionsPanelExtension
      if (optionsPanelExtension) {
        optionsPanelExtension.on('customizeSections', onCustomizeSections)
      }
    }
    this.stop = function () {
      var viewerApiExtension = dashboardControl.findExtension('viewer-api') as ViewerApiExtension
      if (viewerApiExtension) {
        viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared)
      }
      var optionsPanelExtension = dashboardControl.findExtension('item-options-panel') as OptionsPanelExtension
      if (optionsPanelExtension) {
        optionsPanelExtension.off('customizeSections', onCustomizeSections)
      }
    }
  }
  return ChartXAxisFontFormattingExtension
})()

export default ChartXAxisFontFormattingExtension
