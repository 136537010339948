import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useState } from 'react'

type TermsAndPolicyAcceptDialogProps = {
  open?: boolean
  onClose?: () => void
  onConfirm?: () => void
  loading?: boolean
}

export const TermsAndPolicyAcceptDialog: React.FC<TermsAndPolicyAcceptDialogProps> = ({
  open = false,
  onClose,
  onConfirm,
  loading
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={(theme) => ({ bgcolor: theme.palette.background.dark.contrast, color: theme.palette.common.white })}
      >
        Termo de Serviço e Politica de Privacidade
      </DialogTitle>
      <DialogContent sx={(theme) => ({ bgcolor: theme.palette.background.dark.contrast })}>
        <Stack direction="row" alignItems="center">
          <Checkbox required checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />
          <Typography variant="body2" color="#fff">
            Confirmo que li e concordo com os{' '}
            <Link component="a" href="https://pluggerbi.com/termo-de-servico/" target="_blank">
              Termos de Serviço
            </Link>{' '}
            e{' '}
            <Link component="a" href="https://pluggerbi.com/politica-de-privacidade/" target="_blank">
              Politica de Privacidade
            </Link>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={(theme) => ({ bgcolor: theme.palette.background.dark.contrast })}>
        <Button variant="contained" color="error" onClick={onClose}>
          cancelar
        </Button>
        <LoadingButton disabled={!termsAccepted} variant="contained" onClick={onConfirm} loading={loading}>
          Prosseguir
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
