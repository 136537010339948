import { Add } from '@mui/icons-material'
import SendingIcon from '@mui/icons-material/Send'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ContentBox from '../../components/ContentBox'
import Header from '../../components/Header'
import List from '../../components/List'
import Loading from '../../components/Loading'
import RootBox from '../../components/RootBox'
import { SendingSchema } from '../../schemas/SendingSchema'
import sendingService from '../../services/sendingService'
import { normalyzeAndLowering } from '../../utils/stringUtils'
import SendingListItem from './SendingListItem'
import SendingSendDialog from './SendingSendDialog'
import SendingDialog from './sending-dialog/SendingDialog'
import { SentHistoryDialog } from './sent-history-dialog/SentHistoryDialog'

interface DialogStateOptions {
  open: boolean
  itemData?: SendingSchema | null
}

const useStyles = makeStyles((theme) => ({
  list: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    padding: theme.spacing(1, 4)
  }
}))

const SendingPage: React.FC<PropsWithChildren> = () => {
  const [sendings, setSendings] = useState<SendingSchema[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [sendingSendDialogOpen, setSendingSendDialogOpen] = useState<boolean>(false)
  const [selectedSending, setSelectedSending] = useState<SendingSchema>()
  const { enqueueSnackbar } = useSnackbar()
  const [sentHistorySendingId, setSentHistorySendingId] = useState<string | null>(null)
  const { t } = useTranslation()
  const [dialogOptions, setDialogOptions] = useState<DialogStateOptions>({
    open: false,
    itemData: null
  })

  const classes = useStyles()

  const [searchValue, setSearchValue] = useState('')

  const getFilteredSendings = () => {
    if (!searchValue) {
      return sendings.sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
    }
    return sendings
      .filter((p) => normalyzeAndLowering(p.name).includes(normalyzeAndLowering(searchValue)))
      .sort((x, y) => x.name.trim().localeCompare(y.name.trim()))
  }

  const reloadSendings = useCallback(async () => {
    try {
      const response = await sendingService.get()
      setSendings(response.data)
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
    setLoading(false)
  }, [enqueueSnackbar, t])

  useEffect(() => {
    reloadSendings()
  }, [reloadSendings])

  const handleToggleDialog = ({ itemData = undefined, ...rest }: DialogStateOptions) => {
    setDialogOptions({ itemData, ...rest })
  }

  const handleEdit = (sending: SendingSchema) => {
    handleToggleDialog({
      open: true,
      // title: t('pageSending.modalEdit.title'),
      // description: t('pageSending.modalEdit.subTitle'),
      // buttonLabel: t('pageSending.modalEdit.btnSave')
      itemData: sending
    })
  }

  const handleCloseDialog = (refresh?: boolean) => {
    setDialogOptions({ open: false, itemData: null })
    if (refresh) {
      reloadSendings()
    }
  }

  const handleOpenSendingDialog = (sending: SendingSchema) => {
    setSendingSendDialogOpen(true)
    setSelectedSending(sending)
  }

  return (
    <RootBox>
      <Header
        icon={SendingIcon}
        title={t('pageSending.Title')}
        subtitle={t('pageSending.Subtitle')}
        onChangeSearchValue={(val) => setSearchValue(val)}
      />
      <ContentBox>
        <Stack
          alignItems="flex-end"
          paddingRight={4}
          sx={(theme) => ({
            bgcolor: theme.palette.background.light.default
          })}
        >
          <Button
            sx={{
              height: 40
            }}
            onClick={() =>
              handleToggleDialog({
                open: true
                // title: t('pageSending.modalCreate.title'),
                // description: t('pageSending.modalCreate.subTitle'),
                //buttonLabel: t('pageSending.modalCreate.buttonCreate')
              })
            }
            startIcon={<Add />}
            label="Criar"
          />
        </Stack>
        {loading ? (
          <Loading forceAlign />
        ) : (
          <List
            ItemComponent={(props) => (
              <SendingListItem
                onSendClick={handleOpenSendingDialog}
                primaryText={props.item.name}
                item={props.item}
                onEditClick={handleEdit}
                onHistoryClick={(s) => {
                  setSentHistorySendingId(s.id)
                }}
              />
            )}
            items={getFilteredSendings()}
            itemIdKey="id"
            itemLabelKey="name"
            onEdit={handleEdit}
            className={classes.list}
          />
        )}
      </ContentBox>
      <SendingDialog
        onClose={(refresh) => handleCloseDialog(refresh)}
        open={dialogOptions.open}
        sending={dialogOptions.itemData}
      />
      <SendingSendDialog
        open={sendingSendDialogOpen}
        onClose={() => setSendingSendDialogOpen(false)}
        onClosed={console.log}
        sendingId={selectedSending?.id || ''}
        onSubmitSuccess={() => setSendingSendDialogOpen(false)}
      />
      <SentHistoryDialog
        open={!!sentHistorySendingId}
        sendingId={sentHistorySendingId}
        onClose={() => setSentHistorySendingId(null)}
      />
    </RootBox>
  )
}

export default SendingPage
