import { useState } from 'react'

const userGeoPositionKey = 'userGeoPosition'

type GeoLocation = {
  lat: number
  lng: number
}

type UseUserGeoLocationReturn = [
  geoLocation: GeoLocation | null,
  setGeoLocation: (position: GeolocationPosition) => void
]

const useUserGeoPosition = (): UseUserGeoLocationReturn => {
  const storedUserGeoPosition = localStorage.getItem(userGeoPositionKey)
  const parsedGeoPosition = storedUserGeoPosition ? JSON.parse(storedUserGeoPosition) : null
  const [stateGeoPosition, setStateGeoPosition] = useState<GeoLocation>(parsedGeoPosition)

  const setUserGeoPosition = (position: GeolocationPosition) => {
    setStateGeoPosition({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    })
    localStorage.setItem(
      userGeoPositionKey,
      JSON.stringify({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
    )
  }

  return [stateGeoPosition, setUserGeoPosition]
}

export default useUserGeoPosition
