import { AxiosResponse } from "axios";
import { ENDPOINTS } from "../../constants/apiEndpoints";
import { PlugFieldSchema, PlugFieldType } from "../../schemas/PlugSchema";
import { format } from "../../utils/stringUtils";
import api from "../api";

const getTicketsCustomFields = (
    plugId: number,
    options: { getFromConnection?: boolean; fetchExternal?: boolean }
  ): Promise<AxiosResponse<{ name: string; type: PlugFieldType }[]>> => {
    let resourceUri = format(ENDPOINTS.GET_MOVIDESK_TICKETS_CUSTOM_FIELDS, plugId)
    const searchParams = new URLSearchParams()
  
    if (options.getFromConnection) {
      searchParams.set('fromConnection', options.getFromConnection.toString())
    }
    if (options.fetchExternal) {
      searchParams.set('fetchExternal', options.fetchExternal.toString())
    }
    return api.get(resourceUri + '?' + searchParams.toString())
}

const getPersonCustomFields = (
    plugId: number,
    options: { getFromConnection?: boolean; fetchExternal?: boolean }
  ): Promise<AxiosResponse<{ name: string; type: PlugFieldType }[]>> => {
    let resourceUri = format(ENDPOINTS.GET_MOVIDESK_PERSONS_CUSTOM_FIELDS, plugId)
    const searchParams = new URLSearchParams()
  
    if (options.getFromConnection) {
      searchParams.set('fromConnection', options.getFromConnection.toString())
    }
    if (options.fetchExternal) {
      searchParams.set('fetchExternal', options.fetchExternal.toString())
    }
    return api.get(resourceUri + '?' + searchParams.toString())
  }

  export type CreateMovideskPlugRequest = {
    name: string
    connectionId: number
    filter?: string
    ticketsCustomFields: PlugFieldSchema[]
    personCustomFields: PlugFieldSchema[]
    fields: PlugFieldSchema[]
    startFetchDate: Date
  }

  const createMovideskPlug = async (request : CreateMovideskPlugRequest): Promise<AxiosResponse<void>> => {
        return await api.post('/plugs/movidesk', request)
    
  }

  // eslint-disable-next-line import/no-anonymous-default-export
export default {
    getTicketsCustomFields,
    getPersonCustomFields,
    createMovideskPlug
}