import { Visibility, VisibilityOff } from '@mui/icons-material'
import React from 'react'
import i18n from '../../../locales/i18next'
import { getStringedHtmlElement } from '../../../utils/renderUtils'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

class TotalVisibilityExtension implements ComponentExtension {
  name = 'SummaryVisibilityExtension'
  icon = 'verticalalignbottom'
  text = i18n.t('pivotGrid.extensions.totalsVisibility.text')
  items: ComponentExtensionOption[]

  constructor(component: PivotGridComponent, field: PivotGridField) {
    const subOptions = [
      {
        text: i18n.t('pivotGrid.extensions.totalsVisibility.show'),
        icon: getStringedHtmlElement(<Visibility />),
        onItemClick: () => toggleTotalVisibility(field, true, component),
        selected: field.showTotals,
        disabled: field.showTotals
      },
      {
        text: i18n.t('pivotGrid.extensions.totalsVisibility.hide'),
        icon: getStringedHtmlElement(<VisibilityOff />),
        onItemClick: () => toggleTotalVisibility(field, false, component),
        selected: !field.showTotals,
        disabled: !field.showTotals
      }
    ]

    this.items = subOptions
  }
}

const toggleTotalVisibility = (field: PivotGridField, show: boolean, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  dataSource.field(field.index!, { showTotals: show })
  dataSource.load()
}

export default TotalVisibilityExtension
