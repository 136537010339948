import { ChangeSet, EditingState } from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui'
import { Paper, Tab, Tabs } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import * as Yup from 'yup'
import EditableAvatar from '../../components/EditableAvatar'
import Form, { FormProps } from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import SelectField from '../../components/Form/SelectField'
import useAuth from '../../hooks/useAuth'
import { ProfileSchema } from '../../schemas/ProfileSchema'
import { TeamSchema } from '../../schemas/TeamSchema'
import { UserSchema } from '../../schemas/UserSchema'
import { parameterService, profileService, teamService, userService } from '../../services/index'
import { Nullable } from '../../utils/typeUtils'
import AutocompleteTypeProvider from '../AutocompleteTypeProvider/AutocompleteTypeProvider'

const Root = (props: any) => <Grid.Root {...props} style={{ height: '100%' }} />
const FocusableCell = ({
  onClick,
  ...restProps
}: Table.DataCellProps & {
  [x: string]: any
  className?: string | undefined
  style?: React.CSSProperties | undefined
}) => {
  if (restProps.column.name === 'name') {
    return <VirtualTable.Cell {...restProps} tabIndex={0} />
  }
  return <VirtualTable.Cell {...restProps} tabIndex={0} onClick={onClick} />
}

export interface UserParameters {
  parameterId: string
  name: string
  value: string | string[]
  type: 'List' | 'Single'
}

type BaseUserValues = Omit<UserSchema, 'profile' | 'team'> &
  Nullable<UserSchema, 'profile' | 'team'> & { userParameters?: UserParameters[]; parametersDirty?: boolean }
type UserFormProps<T extends BaseUserValues> = FormProps<T>

export const filterParametrizedUsers = (usersParameters: UserParameters[]) => {
  const splitedUserParameters: UserParameters[] = []
  for (var userParam of usersParameters) {
    if (userParam.type === 'List') {
      ;(userParam.value as string[]).forEach((x: string) => {
        const currUser = { ...userParam }
        currUser.value = x
        splitedUserParameters.push(currUser)
      })
      continue
    }
    const singleUserParam = userParam
    if (Array.isArray(singleUserParam.value)) {
      singleUserParam.value = singleUserParam.value[0]
    }
    splitedUserParameters.push(singleUserParam)
  }
  return splitedUserParameters
}

const UserForm = <T extends BaseUserValues>({
  onSubmit,
  ...rest
}: UserFormProps<T>): React.ReactElement<UserFormProps<T>> => {
  const [cropImageState, setCropImageState] = useState<{ base64: string | undefined; fileName: string | undefined }>({
    base64: rest.initialValues.thumbImagePath,
    fileName: undefined
  })

  const [profiles, setProfiles] = useState<ProfileSchema[]>([])
  const parametersDirty = useRef(false)
  const [userParameters, setUserParameters] = useState<UserParameters[]>([])
  const [teams, setTeams] = useState<TeamSchema[]>([])
  const [activeTab, setActiveTab] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const auth = useAuth()
  const { t } = useTranslation()

  const createUserSchemaValidation = (pending: boolean) =>
    Yup.object({
      email: Yup.string().email().required(),
      firstName: Yup.string()
        .required()
        .label(t('signup.form.firstName'))
        .when('email', (_, field) => (!pending ? field.required() : field.notRequired())),
      lastName: Yup.string()
        .required()
        .label(t('signup.form.lastName'))
        .when('email', (_, field) => (!pending ? field.required() : field.notRequired())),
      profile: Yup.object().nullable().required(),
      team: Yup.object().optional().nullable()
    })

  const handleImageCrop = (base64: string | undefined, fileName: string | undefined) => {
    setCropImageState(() => {
      const newObj: { base64: string | undefined; fileName: string | undefined } = {
        base64: base64,
        fileName: undefined
      }
      if (fileName) {
        newObj.fileName = fileName
      }
      return newObj
    })
  }
  const handleSubmit = (values: T) => {
    values.thumbImagePath = cropImageState.base64
    values.userParameters = filterParametrizedUsers(userParameters)
    values.parametersDirty = parametersDirty.current
    onSubmit(values)
  }

  const loadOptions = useCallback(async () => {
    try {
      const profilesPromise = profileService.get()
      const teamsPromise = teamService.getTeams()

      const [profilesResp, teamsResp] = await Promise.all([profilesPromise, teamsPromise])
      setProfiles(profilesResp.data)
      setTeams(teamsResp.data)
    } catch (err: any) {
      console.error('error while try get profiles and teams', err)
      enqueueSnackbar('Something went wrong while get profiles and teams', { variant: 'error' })
    }
  }, [enqueueSnackbar])

  useEffect(() => {
    loadOptions()
  }, [loadOptions])

  const handleReturnName = (opt: any) => {
    return opt?.name || ''
  }
  const handleReturnSelected = (opt: any, value: any) => {
    return opt?.id === value?.id
  }

  const isSelf = auth.getUserInfo()?.userId === rest.initialValues.id
  const allowEditPermissions = auth.getUserInfo()?.profile.managingAccess

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value)
  }

  const getHeight = () => {
    const increment = 55
    const calculatedHeight = userParameters.length > 0 ? userParameters.length * 55 : 160
    return `${calculatedHeight + increment}px`
  }

  const commitChanges = ({ changed }: ChangeSet) => {
    let changedRows: UserParameters[] = []
    const rows = userParameters
    if (changed) {
      changedRows = rows.map((row) => {
        const changedValue = changed[row.name]
        if (changedValue) {
          if (row.type === 'List') {
            changedValue.value.map((x: any) => String(x).trim())
          } else {
            changedValue.value = String(changedValue.value).trim()
          }
          return { ...row, ...changedValue }
        }
        return row
      })
    }
    parametersDirty.current = true
    setUserParameters(changedRows)
  }

  const userId = rest.initialValues.id
  const loadParameters = useCallback(async () => {
    if (userId) {
      try {
        const accountParametersPromise = parameterService.getParameters()
        const userParamValuesPromise = userService.getParameters(userId)

        const [accountParamsResponse, userParamResponse] = await Promise.all([
          accountParametersPromise,
          userParamValuesPromise
        ])
        const userParametersBuilded = accountParamsResponse.data.map((p) => ({
          parameterId: p.id,
          name: p.name,
          value: userParamResponse.data.filter((e) => e.parameterId === p.id)?.map((e) => e.value || '') || '',
          type: p.type
        }))
        setUserParameters(userParametersBuilded)
      } catch (err: any) {
        enqueueSnackbar('Something went wrong while get user parameters', { variant: 'error' })
      }
    }
  }, [enqueueSnackbar, userId])

  useEffect(() => {
    let alreadyLoaded = false
    if (activeTab === 1 && !alreadyLoaded) {
      loadParameters()
      alreadyLoaded = true
    }
  }, [activeTab, loadParameters])

  return (
    <>
      <Tabs centered indicatorColor="primary" value={activeTab} onChange={handleTabChange}>
        <Tab value={0} label="Geral" />
        {allowEditPermissions && <Tab value={1} label="Parâmetros" />}
      </Tabs>

      <SwipeableViews index={activeTab}>
        <Form
          validationSchema={createUserSchemaValidation(rest.initialValues.statusInAccount === 'PENDING_ACCEPT_INVITE')}
          onSubmit={handleSubmit}
          {...rest}
        >
          <EditableAvatar
            disabled={!isSelf}
            alt="user thumb"
            preview={cropImageState.base64}
            onCrop={handleImageCrop}
          />
          <OutlinedTextField disabled={!isSelf} name="email" label="Email" required />
          <OutlinedTextField disabled={!isSelf} name="firstName" label={t('signup.form.firstName')} required />
          <OutlinedTextField disabled={!isSelf} name="lastName" label={t('signup.form.lastName')} required />
          <SelectField
            disabled={!allowEditPermissions}
            name="profile"
            label={t('pageUsers.modalCreate.tabGeneral.profile')}
            required
            options={profiles}
            getOptionLabel={handleReturnName}
            getOptionSelected={handleReturnSelected}
          />
          <SelectField
            disabled={!allowEditPermissions}
            name="team"
            label={t('pageUsers.modalCreate.tabGeneral.team')}
            options={teams}
            getOptionLabel={handleReturnName}
            getOptionSelected={handleReturnSelected}
          />
        </Form>
        {allowEditPermissions && (
          <Paper style={{ minHeight: getHeight() }}>
            <Grid
              columns={[
                {
                  name: 'name',
                  title: t('pageUsers.modal.parameterName')!
                },
                {
                  name: 'value',
                  title: t('pageParameters.modalCreate.paramValue')!
                }
              ]}
              rows={userParameters}
              getRowId={(rowData) => rowData.name}
            >
              <AutocompleteTypeProvider multiple={(e) => e.type === 'List'} for={['value']} />
              <EditingState
                onCommitChanges={commitChanges}
                columnExtensions={[
                  {
                    columnName: 'name',
                    editingEnabled: false
                  }
                ]}
              />
              <VirtualTable
                columnExtensions={[
                  {
                    columnName: 'name',
                    align: 'left',
                    width: 200
                  },
                  {
                    columnName: 'value',
                    align: 'left'
                  }
                ]}
                height="auto"
              />
              <TableHeaderRow />
              <TableInlineCellEditing selectTextOnEditStart={false} />
            </Grid>
          </Paper>
        )}
      </SwipeableViews>
    </>
  )
}

export default UserForm
