import windowUtils from '../../utils/windowUtils'
import useAuth from '../useAuth'

const useLocaleDetector = () => {
  const { getUserInfo } = useAuth()
  const userInfo = getUserInfo()

  const locale = userInfo?.locale

  locale && localStorage.setItem('preferedLocale', locale)
  const browserLocale = windowUtils.getLanguage()
  const preferedLocale = locale || localStorage.getItem('preferedLocale') || browserLocale

  return preferedLocale
}

export default useLocaleDetector
