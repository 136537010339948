/* eslint-disable no-loop-func */
import { Cached, LibraryBooks, Save, Settings, Share } from '@mui/icons-material'
import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import PivotGrid from 'devextreme-react/pivot-grid'
import { dxElement } from 'devextreme/core/element'
//import 'devextreme/dist/css/dx.material.teal.light.css'
import * as axios from 'axios'
import dxPivotGrid from 'devextreme/ui/pivot_grid'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import AddToCollectionDialog from '../../components/AddToCollectionDialog'
import AnalyzeSwipeableDrawer, { AnalyzeSwipeableDrawerOptionProps } from '../../components/AnalyzeSwipeableDrawer'
import ErrorDescriptor from '../../components/ErrorDescriptor'
import Loading from '../../components/Loading'
import ShareDialog from '../../components/ShareDialog'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { useCheckAnotherAccount } from '../../hooks/useCheckAnotherAccount'
import { useQuery } from '../../hooks/useQuery'
import { AnalyzeSchema, AnalyzeType } from '../../schemas/AnalyzeSchema'
import { PlugClaimType } from '../../schemas/UserSchema'
import { analyzeService, plugService } from '../../services'
import { instanceOfAxiosError } from '../../services/api'
import templateService from '../../services/templates/templateService'
import SaveACopyDialog from './SaveACopyDialog'
import SaveDialog from './SaveDialog'
import SettingsDialog from './SettingsDialog'
import { RemoteDataSource, createDataSource } from './dataSource'
import {
  AggregateExtension,
  CollapseAllExtension,
  CustomFieldExtension,
  EditCustomFieldExtension,
  ExpandAllExtension,
  ExtractDateExtension,
  FieldPanelExtension,
  FunctionsExtension,
  GrandTotalVisibilityExtension,
  HighestLevelSummariesVisibilityExtension,
  NumberFormatExtension,
  RemoveFieldExtension,
  RemoveFieldFromAreaExtension,
  RenameFieldExtension,
  ShowTotalsPriorExtension,
  TotalVisibilityExtension,
  WordWrapperExtension
} from './extensions'
import { CustomFieldDialog, id as CustomFieldDialogId } from './extensions/CustomFieldExtension'
import ExportToPdfFileExtension, {
  ExportToPdfDialog,
  id as ExportToPdfDialogId
} from './extensions/ExportToPdfFileExtension'
import { id as RenameDialogId, RenameFieldDialog } from './extensions/RenameFieldExtension'
import './index.css'
import {
  ComponentExtension,
  ComponentWithDialogExtension,
  ExtensionAreas,
  ExtensionOption,
  OnPrepareContextMenuEventProps,
  PivotGridConfig,
  PivotGridField,
  PivotGridFontSize,
  setPivotGridConfigOption
} from './types'

import { Buffer } from 'buffer'
import PivotGridWrapper from './PivotGridWrapper'

interface PivotGridPageProps {
  fromCollection?: boolean
  hideMenu?: boolean
  onBackButtonClick?: () => void
}

const processPivotGridConfig = (config?: PivotGridConfig) => {
  if (!config) return
  config.onCellPrepared = (e) => {
    if (e.cellElement) {
      e.cellElement.style.fontSize = config.fontSize
      if (config.fontSize === PivotGridFontSize.XLarge) {
        e.cellElement.style.padding = '10px'
      }
    }
  }
  return config
}

let currentField: PivotGridField | undefined
const dialogIds: string[] = []

type PivotGridError = {
  errorCode: string
  message: string
}

export type PivotGridPageRefProps = {
  reloadData?: () => Promise<any>
}

const PivotGridPage = forwardRef<PivotGridPageRefProps, PropsWithChildren<PivotGridPageProps>>(
  ({ fromCollection, hideMenu, onBackButtonClick }, ref) => {
    const pivotGridRef = useRef<PivotGrid | null>(null)
    const { id, collectionId } = useParams<any>()
    const [analysisOrPlugId, setAnalysisOrPlugId] = useState<string | number | undefined>(id)
    const [loading, setLoading] = useState(true)
    const [activeDialogId, setActiveDialogId] = useState<string | null>()
    const [analyze, setAnalyze] = useState<AnalyzeSchema>()
    const [saveOpen, setSaveOpen] = useState(false)
    const [settingsOpen, setSettingsOpen] = useState(false)
    const [dataSource, setDataSource] = useState<InstanceType<typeof RemoteDataSource>>()
    const { enqueueSnackbar } = useSnackbar()
    const [shareAnalyzeDialogIsOpen, setShareAnalyzeDialogIsOpen] = useState(false)
    const [saveACopyDialogOpen, setSaveACopyDialogOpen] = useState(false)
    const [addCollectionDialogOpen, setAddCollectionDialogOpen] = useState(false)
    const [errors, setErrors] = useState<PivotGridError[]>([])
    const { t } = useTranslation()
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const navigate = useNavigate()
    const query = useQuery()
    const skipLoading = useRef(false)

    const { check } = useCheckAnotherAccount()

    const auth = useAuth()
    const userInfo = auth.getUserInfo()
    const userProfile = userInfo?.profile

    useImperativeHandle(
      ref,
      () => {
        return {
          reloadData: dataSource?.reload
        }
      },
      [dataSource]
    )

    const handleOpenSaveACopyDialog = () => {
      setSaveACopyDialogOpen(true)
    }

    const handleCloseSaveACopyDialog = () => {
      setSaveACopyDialogOpen(false)
    }
    const handleOpenAddCollectionDialog = () => {
      setAddCollectionDialogOpen(true)
    }

    const handleCloseAddCollectionDialog = () => {
      setAddCollectionDialogOpen(false)
    }

    const handleOpenShareDialog = () => {
      setShareAnalyzeDialogIsOpen(true)
    }

    const handleCloseShareDialog = () => {
      setShareAnalyzeDialogIsOpen(false)
    }

    const handleCloseSettingsDialog = () => {
      setSettingsOpen(false)
      // hack for trigger repaint in next render
      setTimeout(() => pivotGridRef.current?.instance.repaint())
    }

    const handleError = (err: any) => {
      if (instanceOfAxiosError(err)) {
        if (err.status === 403 || err.response?.status === 403) {
          if (fromCollection) {
            const generatedPath = generatePath('/collection/:collectionId/unauthorized', {
              collectionId: collectionId!
            })
            navigate(generatedPath, {
              state: err.response?.data
            })
            return
          }
          navigate(PATHS.UNAUTHORIZED, {
            state: err.response?.data
          })
        }
        if (err.status === 404 || err.response?.status === 404) {
          navigate('not-found')
          return
        }
        const responseErrors = err.response?.data
        if (responseErrors && responseErrors.length > 0) {
          for (const respError of responseErrors) {
            setErrors((prev) => [...prev, { errorCode: respError.errorCode, message: respError.errorMessage }])
          }
          return
        }
        if (process.env.NODE_ENV !== 'production' && err.response?.status === 500) {
          setErrors((prev) => [
            ...prev,
            {
              errorCode: 'Internal Server Error',
              message: err.request?.responseText || err.message
            }
          ])
          return
        }
      }
      setErrors((prev) => [
        ...prev,
        {
          errorCode: 'UnexpectedError',
          message: err.message
        }
      ])
    }

    const isNew = !isNaN(Number(analysisOrPlugId))
    const isOwner = userInfo?.userId === analyze?.userOwner.id
    const canEdit = isOwner || analyze?.claimType === PlugClaimType.Analysis_RW

    const handlePrepareContextMenu = useCallback(
      (e: OnPrepareContextMenuEventProps) => {
        handleConfiguringExtensions()
        const area = e.field?.area || (e.area as any)
        const extensionsAvailable = extensions.filter(
          (ex) =>
            ((area && ex.areas.includes(area)) || ex.areas.includes(ExtensionAreasImp.ANYWHERE)) &&
            (ex.condition ? ex.condition(e.field!, e.component!) : true)
        )
        const extensionsInAnyWhere = e.items!

        if (!e.field) {
          const anywhereExts = extensionsAvailable.filter((x) => x.areas.includes(ExtensionAreasImp.ANYWHERE))
          for (const ext of anywhereExts) {
            let initializedInstance: ComponentExtension | ComponentWithDialogExtension
            if (checkInstanceOfComponentDialogExtension(ext.extension)) {
              currentField = e.field!
              initializedInstance = new ext.extension(
                (id: string) => setActiveDialogId(id),
                () => (currentField = undefined)
              )
              dialogIds.push(initializedInstance.name)
            } else {
              initializedInstance = new ext.extension(e.component!, e.field!)
            }
            e.items?.push({
              text: initializedInstance.text,
              icon: initializedInstance.icon,
              items: initializedInstance.items,
              onItemClick: () => initializedInstance.onItemClick?.(e)
            })
          }
          return
        }

        const index = e.items!.push({
          text: t('pivotGrid.menuSettings.modalSettings.FieldSettings'),
          icon: 'preferences',
          items: []
        })
        const extensionsInFieldSettings = e.items![index - 1].items

        for (const ext of extensionsAvailable) {
          let initializedInstance: ComponentExtension | ComponentWithDialogExtension
          if (checkInstanceOfComponentDialogExtension(ext.extension)) {
            currentField = e.field!
            initializedInstance = new ext.extension(
              (id: string) => setActiveDialogId(id),
              () => (currentField = undefined)
            )
            dialogIds.push(initializedInstance.name)
          } else {
            initializedInstance = new ext.extension(e.component!, e.field!)
          }
          if (ext.areas.includes(ExtensionAreasImp.ANYWHERE)) {
            extensionsInAnyWhere.push({
              text: initializedInstance.text,
              icon: initializedInstance.icon,
              items: initializedInstance.items,
              onItemClick: () => initializedInstance.onItemClick?.(e)
            })
          } else {
            extensionsInFieldSettings.push({
              text: initializedInstance.text,
              icon: initializedInstance.icon,
              items: initializedInstance.items,
              onItemClick: () => initializedInstance.onItemClick?.(e)
            })
          }
        }
        if (!e.field) {
          e.items?.splice(index - 1, 1)
        }
      },
      [t]
    )

    const loadPivotGrid = useCallback(async () => {
      if (skipLoading.current) {
        skipLoading.current = false
        return
      }
      setLoading(true)
      let dtInstance: InstanceType<typeof RemoteDataSource>
      try {
        if (!isNew) {
          // load saved pivot
          const analyzeResp = await analyzeService.getAnalyzeAndState(analysisOrPlugId as string)
          await check(analyzeResp.data)
          setAnalyze(analyzeResp.data)
          dtInstance = createDataSource(analyzeResp.data.plugs[0].id, analysisOrPlugId as string, handleError)
          await dtInstance.loadState(JSON.parse(analyzeResp.data.state))
          dtInstance.setConfigOption('export', {
            enabled: userProfile?.canExportData,
            fileName: analyzeResp.data.name as any
          })
        } else {
          dtInstance = createDataSource(analysisOrPlugId as number, undefined, handleError)
          dtInstance.setConfigOption('export', {
            enabled: userProfile?.canExportData
          })
          let state
          const templateName = query.get('template')
          if (!!templateName) {
            var { data: plug } = await plugService.getById(analysisOrPlugId as number)
            var plugType = plug.type
            const { data } = await templateService.get(templateName, plugType, AnalyzeType.PIVOTGRID)
            const base64Content = data[0].content
            if (base64Content !== null) {
              const content = Buffer.from(base64Content, 'base64').toString('utf8')
              state = JSON.parse(content)
            }
          }
          await dtInstance.loadState(state)
        }
        setDataSource(dtInstance)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.status === 403 || err.response?.status === 403) {
            if (fromCollection) {
              const generatedPath = generatePath('/collection/:collectionId/unauthorized', {
                collectionId: collectionId!
              })
              navigate(generatedPath, {
                state: err.response?.data
              })
              return
            }
            navigate(PATHS.UNAUTHORIZED, {
              state: err.response?.data
            })
            return
          }
          handleError(err)
        }
      }
      setLoading(false)
    }, [analysisOrPlugId, isNew])

    useEffect(() => {
      setAnalysisOrPlugId(id)
      loadPivotGrid()
    }, [id])

    const handleContextMenuConfig = useCallback(
      (e: any) => {
        handlePrepareContextMenu(e)
      },
      [handlePrepareContextMenu]
    )

    const options: AnalyzeSwipeableDrawerOptionProps[] = [
      {
        icon: <Save />,
        label: t('pivotGrid.sideMenu.save'),
        disabled: !isNew && !canEdit,
        action: async () => {
          if (!isNew) {
            try {
              await dataSource?.save(analysisOrPlugId as string)
              enqueueSnackbar(t('pivotGrid.toast.success.save', { name: analyze?.name }), { variant: 'success' })
            } catch (err: any) {
              console.error(err)
              enqueueSnackbar(t('pivotGrid.toast.error.save'), { variant: 'error' })
            }
            return
          }
          setSaveOpen(true)
        }
      },
      {
        icon: (
          <img
            style={{
              filter: mdDown
                ? 'invert(57%) sepia(19%) saturate(1549%) hue-rotate(125deg) brightness(93%) contrast(82%)'
                : 'invert(1)'
            }}
            src={ENDPOINTS.PUBLIC_IMAGES + '/save-as-icon.svg'}
            alt="save as icon"
          />
        ),
        disabled: !userProfile?.managingAnalyseAndCollection,
        label: t('pivotGrid.sideMenu.saveCopy'),
        action: () => handleOpenSaveACopyDialog()
      },
      {
        icon: <Share />,
        label: t('pivotGrid.sideMenu.share'),
        disabled: isNew || !canEdit,
        action: () => handleOpenShareDialog()
      },
      {
        icon: <LibraryBooks />,
        label: t('pivotGrid.sideMenu.addToCollection'),
        disabled: isNew,
        action: () => handleOpenAddCollectionDialog()
      },
      {
        icon: <Settings />,
        label: t('pivotGrid.sideMenu.settings'),
        action: () => setSettingsOpen(true)
      },
      {
        icon: <Cached />,
        label: t('pivotGrid.sideMenu.renewCache'),
        action: () => {
          dataSource?.reload()
        }
      }
    ]

    const handleOpenFieldChooserOnInitialize = useCallback(
      (e: { component?: (dxPivotGrid & { loaded?: boolean }) | undefined; element?: dxElement | undefined }) => {
        const templateName = query.get('template')
        if (e.component && !e.component.loaded && isNew && (!templateName || templateName === 'blank')) {
          e.component.loaded = true
          e.component.getFieldChooserPopup().show()
        }
      },
      [isNew, query]
    )

    const setConfigOption: setPivotGridConfigOption = (option, value) => {
      dataSource?.setConfigOption(option, value)
    }

    const getConfigOption = useCallback(
      (option: keyof PivotGridConfig): PivotGridConfig[keyof PivotGridConfig] => {
        return dataSource?.pivotGridConfig[option]
      },
      [dataSource?.pivotGridConfig]
    )

    const handleSaveNew = (name: string) => {
      dataSource
        ?.saveNew(name)
        .then((resp) => {
          enqueueSnackbar(t('pivotGrid.toast.success.save', { name: analyze?.name }), { variant: 'success' })
          window.history.replaceState(null, '', generatePath(PATHS.PIVOT_GRID, { id: resp.data.id }))
          skipLoading.current = true
          setAnalysisOrPlugId(resp.data.id)
          setAnalyze(resp.data)
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar(t('pivotGrid.toast.error.save'), { variant: 'error' })
        })
      setSaveOpen(false)
    }

    return (
      <Box display="flex" width="100vw" height="100vh">
        <AnalyzeSwipeableDrawer
          analyzeName={analyze?.name || 'New Pivot Grid'}
          analyzeTypeName="Pivot Grid"
          options={options}
          hidden={hideMenu}
          showBackButton={fromCollection}
          onBackButtonClick={onBackButtonClick}
        />
        <Box width={mdDown ? '100%' : 'calc(100% - 57px)'} height="100%">
          {dataSource?.pivotGridConfig.showTitle && (
            <Box height={60} textAlign="center">
              <Typography variant="h1">{dataSource.pivotGridConfig.title}</Typography>
            </Box>
          )}
          <Box
            display="flex"
            height={dataSource?.pivotGridConfig.showTitle ? 'calc(100% - 60px)' : '100%'}
            overflow="auto"
            maxHeight="100%"
            flexDirection="row"
          >
            <Box flex="1 1 auto" display="flex" flexDirection="column">
              {loading ? (
                <Box position="absolute" width="100vw" height="100vh">
                  <Loading forceAlign />
                </Box>
              ) : errors.length > 0 ? (
                <ErrorDescriptor
                  style={{ width: '80%', display: 'block', margin: 'auto', overflow: 'auto' }}
                  errors={errors}
                />
              ) : (
                <PivotGridWrapper
                  onContentReady={handleOpenFieldChooserOnInitialize}
                  ref={pivotGridRef}
                  onContextMenuPreparing={handleContextMenuConfig}
                  dataSource={dataSource as any}
                  pivotGridConfig={processPivotGridConfig(dataSource?.pivotGridConfig) as any}
                />
              )}
            </Box>
          </Box>
        </Box>
        <RenameFieldDialog
          pivotGrid={pivotGridRef.current}
          open={activeDialogId === RenameDialogId}
          dataSource={dataSource as any}
          field={currentField}
          onClose={() => setActiveDialogId(null)}
        />
        <CustomFieldDialog
          open={activeDialogId === CustomFieldDialogId}
          dataSource={dataSource as any}
          field={currentField}
          onClose={() => setActiveDialogId(null)}
        />
        <ExportToPdfDialog
          open={activeDialogId === ExportToPdfDialogId}
          pivotGrid={pivotGridRef.current}
          onClose={() => setActiveDialogId(null)}
        />
        <SaveDialog onSave={handleSaveNew} open={saveOpen} onClose={() => setSaveOpen(false)} />
        <SaveACopyDialog
          baseName={analyze?.name || 'New Pivot Grid'}
          dataSource={dataSource}
          open={saveACopyDialogOpen}
          onClose={handleCloseSaveACopyDialog}
        />
        <SettingsDialog
          pivotGrid={pivotGridRef.current}
          setPivotConfig={setConfigOption}
          getPivotConfig={getConfigOption}
          open={settingsOpen}
          onClose={handleCloseSettingsDialog}
        />
        <ShareDialog open={shareAnalyzeDialogIsOpen} onClose={handleCloseShareDialog} analyzeOrCollection={analyze} />
        <AddToCollectionDialog
          title={t('pivotGrid.menuSettings.modalCollection.Title')}
          onClose={handleCloseAddCollectionDialog}
          description={t('pivotGrid.menuSettings.modalCollection.Subtitle')}
          open={addCollectionDialogOpen}
        />
      </Box>
    )
  }
)

const ExtensionAreasImp = {
  COLUMN: 'column' as ExtensionAreas,
  DATA: 'data' as ExtensionAreas,
  FILTER: 'filter' as ExtensionAreas,
  ROW: 'row' as ExtensionAreas,
  ANYWHERE: 'anywhere' as ExtensionAreas
}

const checkInstanceOfComponentDialogExtension = (objType: any): objType is ComponentWithDialogExtension => {
  return objType && objType.discriminator === 'DialogExtension'
}

const handleConfiguringExtensions = () => {
  clearExtensions()
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.ANYWHERE],
    ExportToPdfFileExtension,
    (field, pivotGrid) => pivotGrid.option('export')?.enabled || false
  )
  registerCubeContextMenuExtension([ExtensionAreasImp.COLUMN, ExtensionAreasImp.ROW], WordWrapperExtension)
  registerCubeContextMenuExtension([ExtensionAreasImp.DATA], AggregateExtension, (f) => !f?.customFieldExpression)
  registerCubeContextMenuExtension([ExtensionAreasImp.DATA], FunctionsExtension)
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.DATA, ExtensionAreasImp.COLUMN, ExtensionAreasImp.ROW, ExtensionAreasImp.FILTER],
    RemoveFieldExtension,
    (f) => !!f?.canBeRemoved
  )
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.DATA, ExtensionAreasImp.COLUMN, ExtensionAreasImp.ROW, ExtensionAreasImp.FILTER],
    NumberFormatExtension,
    (f) => f?.dataType === 'number'
  )
  registerCubeContextMenuExtension([ExtensionAreasImp.ROW, ExtensionAreasImp.COLUMN], TotalVisibilityExtension)
  registerCubeContextMenuExtension([ExtensionAreasImp.DATA], GrandTotalVisibilityExtension)
  registerCubeContextMenuExtension([ExtensionAreasImp.ANYWHERE], HighestLevelSummariesVisibilityExtension)
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.ROW, ExtensionAreasImp.COLUMN, ExtensionAreasImp.FILTER],
    ExtractDateExtension,
    (f) => f?.dataType === 'date'
  )
  registerCubeContextMenuExtension([ExtensionAreasImp.ANYWHERE], ShowTotalsPriorExtension)
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.DATA, ExtensionAreasImp.ROW, ExtensionAreasImp.COLUMN, ExtensionAreasImp.FILTER],
    RenameFieldExtension
  )
  registerCubeContextMenuExtension([ExtensionAreasImp.ANYWHERE], CustomFieldExtension)
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.COLUMN, ExtensionAreasImp.DATA, ExtensionAreasImp.FILTER, ExtensionAreasImp.ROW],
    RemoveFieldFromAreaExtension
  )
  registerCubeContextMenuExtension([ExtensionAreasImp.COLUMN, ExtensionAreasImp.ROW], ExpandAllExtension)
  registerCubeContextMenuExtension([ExtensionAreasImp.COLUMN, ExtensionAreasImp.ROW], CollapseAllExtension)
  registerCubeContextMenuExtension(
    [ExtensionAreasImp.DATA, ExtensionAreasImp.ROW, ExtensionAreasImp.COLUMN, ExtensionAreasImp.FILTER],
    EditCustomFieldExtension,
    (f) => !!f?.customFieldExpression
  )
  registerCubeContextMenuExtension([ExtensionAreasImp.ANYWHERE], FieldPanelExtension)
}

const registerCubeContextMenuExtension = (
  areas: ExtensionAreas[],
  extension: new (...args: any[]) => ComponentExtension,
  condition?: (field: PivotGridField, pivotGrid: dxPivotGrid) => boolean
) => {
  extensions.push({
    areas,
    extension,
    condition
  })
}

//let currentField = null

const clearExtensions = () => {
  extensions.length = 0
}

const extensions: ExtensionOption[] = []

export default PivotGridPage
