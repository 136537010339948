import { Box, Stack, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Button from '../../components/Button'
import Form from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import useAuth from '../../hooks/useAuth'
import { userService } from '../../services/index'
import SignedUserLogins from './Logins'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: 'center'
  }
}))

const initialFormValues = {
  password: '',
  confirmPassword: ''
}

const validationSchema = yup.object({
  password: yup.string().required().min(6),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match')
})

const SecurityPage: React.FC<PropsWithChildren> = () => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const auth = useAuth()
  const userInfo = auth.getUserInfo()

  const submitForm = () => {
    setIsSubmitting(true)
    setTimeout(() => setIsSubmitting(false))
  }

  const handleUpdateUserPassword = async (values: typeof initialFormValues) => {
    setUpdateLoading(true)
    try {
      await userService.updatePassword(userInfo!.userId!, {
        password: values.password
      })
      enqueueSnackbar(t('pageUserlogged.Settings.tabSecurity.toast.success.password'), { variant: 'success' })
    } catch (err: any) {
      enqueueSnackbar(t('pageUserlogged.Settings.tabSecurity.toast.error.password'))
    }
    setUpdateLoading(false)
  }

  return (
    <Box className={classes.root}>
      <Stack p={2} spacing={2}>
        <Box>
          <Typography variant="h2">{t('pageUserlogged.Settings.tabSecurity.Title')}</Typography>
        </Box>
        <Form
          validationSchema={validationSchema}
          isSubmitting={isSubmitting}
          initialValues={initialFormValues}
          initialTouched
          onSubmit={handleUpdateUserPassword}
        >
          <OutlinedTextField name="password" label={t('pageUserlogged.Settings.tabSecurity.Password')} />
          <OutlinedTextField name="confirmPassword" label={t('pageUserlogged.Settings.tabSecurity.ConfirmPassword')} />
          <Button
            onClick={submitForm}
            label={t('pageUserlogged.Settings.tabSecurity.ButtonSave')}
            loading={updateLoading}
          />
        </Form>
        <SignedUserLogins />
      </Stack>
    </Box>
  )
}

export default SecurityPage
