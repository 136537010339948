import { AxiosResponse } from 'axios'

import api from '../api'

import { ENDPOINTS } from '../../constants/apiEndpoints'
import { TeamSchema, TeamUserAssignableSchema } from '../../schemas/TeamSchema'
import { format } from '../../utils/stringUtils'

const getTeams = (): Promise<AxiosResponse<TeamSchema[]>> => {
  return api.get(ENDPOINTS.TEAMS_GET_ALL)
}

const getAssignableUsers = (teamId?: string): Promise<AxiosResponse<TeamUserAssignableSchema[]>> => {
  return api.get(format(ENDPOINTS.TEAMS_GET_ASSIGNABLE_USERS, teamId))
}

const create = (
  team: Omit<TeamSchema, 'users' | 'id'> & { users: { id: string }[] }
): Promise<AxiosResponse<never>> => {
  return api.post(ENDPOINTS.TEAMS_CREATE, team)
}

const update = (team: Omit<TeamSchema, 'users'> & { users: { id: string }[] }): Promise<AxiosResponse<never>> => {
  return api.put(format(ENDPOINTS.TEAMS_UPDATE, team.id), team)
}

const _delete = (teamId: string): Promise<AxiosResponse<never>> => {
  return api.delete(format(ENDPOINTS.TEAMS_DELETE, teamId))
}

export default { getTeams, getAssignableUsers, update, create, _delete }
