import { useCallback } from 'react'

export const useMovideskChat = () => {
  const chatWidget = document.getElementById('md-app-widget')

  const setUser = useCallback(({ userFirstName, userEmail }: { userFirstName: string; userEmail: string }) => {
    ;(window as any).movideskLogin({
      name: userFirstName,
      email: userEmail,
      startChat: false
    })
  }, [])

  const showChat = useCallback(() => {
    if (chatWidget) {
      chatWidget.style.visibility = 'visible'
    }
  }, [chatWidget])

  const hideChat = useCallback(() => {
    if (chatWidget) {
      chatWidget.style.visibility = 'hidden'
    }
  }, [chatWidget])

  return {
    show: showChat,
    hide: hideChat,
    setUser
  }
}
