import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AnalyzeType } from '../../../schemas/AnalyzeSchema'
import FormCheckbox from './FormCheckbox'
import FormTextField from './FormTextField'

type AdvancedExportOptionsTabProps = {
  formProps: UseFormReturn<any>
  exportFormat: string
  analysisType: string
}

const ReportExcelOptions = (formProps: UseFormReturn<any>) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" p={2} spacing={1}>
      <Controller
        name="reportExcelOptions.excelFormat"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'XLSX'}
            disableClearable
            options={['XLSX', 'XLS', 'CSV']}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.excelFormatType')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <FormCheckbox
        name="fitToPrintedPageHeight"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.fitToPrintedPageHeight')}
        control={formProps.control}
      />
      <FormCheckbox
        name="fitToPrintedPageWidth"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.fitToPrintedPageWidth')}
        control={formProps.control}
      />
      <FormCheckbox
        name="ignoreErrors"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.ignoreErrors')}
        control={formProps.control}
      />
    </Stack>
  )
}
const ReportImageOptions = (formProps: UseFormReturn<any>) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" p={2} spacing={1}>
      <Controller
        name="reportImageOptions.imageFormat"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'PNG'}
            disableClearable
            options={['BMP', 'GIF', 'JPG', 'PNG', 'EMF', 'WMF', 'TIFF']}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.imageFormat')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </Stack>
  )
}
const ReportPdfOptions = (formProps: UseFormReturn<any>) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" p={2} spacing={1}>
      <Controller
        name="reportPdfExportOptions.imageQuality"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'Medium'}
            disableClearable
            options={['Lowest', 'Low', 'Medium', 'High', 'Highest']}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.imageQuality')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </Stack>
  )
}

const DashboardExcelOptions = (formProps: UseFormReturn<any>) => {
  const { t } = useTranslation()

  const includePositionTranslations = {
    BELOW: t('pageSending.modalCreate.tabAdvancedExportOptions.includePositionBelow'),
    SEPARATED_SHEET: t('pageSending.modalCreate.tabAdvancedExportOptions.includePositionSeparatedSheet')
  } as any

  return (
    <Stack direction="column" p={2} spacing={1}>
      <Controller
        name="dashboardExcelOptions.excelFormatType"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'XLSX'}
            disableClearable
            options={['XLSX', 'XLS']}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.excelFormatType')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <FormCheckbox
        name="dashboardExcelOptions.includeFilters"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.includeFilters')}
        control={formProps.control}
      />
      <FormCheckbox
        name="dashboardExcelOptions.includeParameters"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.includeParameters')}
        control={formProps.control}
      />
      <Controller
        name="dashboardExcelOptions.includePosition"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            disabled={
              !formProps.watch('dashboardExcelOptions.includeFilters') &&
              !formProps.watch('dashboardExcelOptions.includeParameters')
            }
            options={['BELOW', 'SEPARATED_SHEET']}
            getOptionLabel={(option) => includePositionTranslations[option]}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.includePosition')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </Stack>
  )
}

const DashboardImageOptions = (formProps: UseFormReturn<any>) => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" p={2} spacing={1}>
      <Controller
        name="dashboardImageOptions.imageFormat"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'PNG'}
            disableClearable
            options={['PNG', 'JPG', 'GIF']}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.imageFormat')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <FormCheckbox
        name="dashboardImageOptions.showTitle"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.showTitle')}
        control={formProps.control}
      />
      <FormTextField
        disabled={!formProps.watch('dashboardImageOptions.showTitle')}
        name="dashboardImageOptions.title"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.title')}
        control={formProps.control}
      />
      <FormCheckbox
        name="dashboardImageOptions.includeFilters"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.includeFilters')}
        control={formProps.control}
      />
      <FormCheckbox
        name="dashboardImageOptions.includeParameters"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.includeParameters')}
        control={formProps.control}
      />
    </Stack>
  )
}

const DashboardPdfOptions = (formProps: UseFormReturn<any>) => {
  const { t } = useTranslation()

  const scaleModesTranslations = {
    NONE: t('pageSending.modalCreate.tabAdvancedExportOptions.scaleModeNone'),
    USE_SCALE_FACTOR: t('pageSending.modalCreate.tabAdvancedExportOptions.scaleModeUseScaleFactor'),
    AUTO_FIT_TO_PAGE_WIDTH: t('pageSending.modalCreate.tabAdvancedExportOptions.scaleModeAutoFitToPageWidth')
  } as any

  const pageLayoutTranslations = {
    AUTO: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeAuto'),
    PORTRAIT: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizePortrait'),
    LANDSCAPE: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeLandscape')
  } as any

  const pageSizeTranslations = {
    LETTER: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeLetter'),
    LEGAL: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeLegal'),
    EXECUTIVE: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeExecutive'),
    A5: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeA5'),
    A4: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeA4'),
    A3: t('pageSending.modalCreate.tabAdvancedExportOptions.pageSizeA3')
  } as any

  const dashboardPdfIncludePositionTranslations = {
    BELOW: t('pageSending.modalCreate.tabAdvancedExportOptions.includePositionBelow'),
    SEPARATED_PAGE: t('pageSending.modalCreate.tabAdvancedExportOptions.includePositionSeparatedPage')
  } as any

  return (
    <Stack direction="column" p={2} spacing={1}>
      <Controller
        name="dashboardPdfExportOptions.pageLayout"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            disableClearable
            defaultValue={'AUTO'}
            options={['AUTO', 'PORTRAIT', 'LANDSCAPE']}
            getOptionLabel={(option) => pageLayoutTranslations[option]}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.pageLayout')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        name="dashboardPdfExportOptions.size"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'LETTER'}
            disableClearable
            options={['LETTER', 'LEGAL', 'EXECUTIVE', 'A5', 'A4', 'A3']}
            getOptionLabel={(option) => pageSizeTranslations[option]}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.size')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <FormCheckbox
        name="dashboardPdfExportOptions.showTitle"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.showTitle')}
        control={formProps.control}
      />
      <FormTextField
        name="dashboardPdfExportOptions.title"
        disabled={!formProps.watch('dashboardPdfExportOptions.showTitle')}
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.title')}
        control={formProps.control}
      />
      <Controller
        name="dashboardPdfExportOptions.scaleMode"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            defaultValue={'NONE'}
            disableClearable
            disabled={formProps.watch('dashboardPdfExportOptions.pageLayout') === 'AUTO'}
            options={['NONE', 'USE_SCALE_FACTOR', 'AUTO_FIT_TO_PAGE_WIDTH']}
            getOptionLabel={(option) => scaleModesTranslations[option]}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.scaleMode')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <FormTextField
        defaultValue={1}
        name="dashboardPdfExportOptions.scaleFactor"
        type="number"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.scaleFactor')}
        disabled={formProps.watch('dashboardPdfExportOptions.pageLayout') === 'AUTO'}
        sx={{
          display: formProps.watch('dashboardPdfExportOptions.scaleMode') === 'USE_SCALE_FACTOR' ? 'block' : 'none'
        }}
        control={formProps.control}
      />
      <FormTextField
        defaultValue={1}
        name="dashboardPdfExportOptions.fitToPageCount"
        type="number"
        disabled={formProps.watch('dashboardPdfExportOptions.pageLayout') === 'AUTO'}
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.fitToPageCount')}
        sx={{
          display:
            formProps.watch('dashboardPdfExportOptions.scaleMode') === 'AUTO_FIT_TO_PAGE_WIDTH' ? 'block' : 'none'
        }}
        control={formProps.control}
      />
      <FormCheckbox
        name="dashboardPdfExportOptions.includeFilters"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.includeFilters')}
        control={formProps.control}
      />
      <FormCheckbox
        name="dashboardPdfExportOptions.includeParameters"
        label={t('pageSending.modalCreate.tabAdvancedExportOptions.includeParameters')}
        control={formProps.control}
      />
      <Controller
        name="dashboardPdfExportOptions.includePosition"
        control={formProps.control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            disabled={
              !formProps.watch('dashboardPdfExportOptions.includeFilters') &&
              !formProps.watch('dashboardPdfExportOptions.includeParameters')
            }
            options={['BELOW', 'SEPARATED_PAGE']}
            getOptionLabel={(option) => dashboardPdfIncludePositionTranslations[option]}
            onChange={(e, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                name={field.name}
                {...params}
                label={t('pageSending.modalCreate.tabAdvancedExportOptions.includePosition')}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </Stack>
  )
}

const AdvancedExportOptionsTab: React.FC<AdvancedExportOptionsTabProps> = ({
  analysisType,
  exportFormat,
  formProps
}) => {
  const { t } = useTranslation()
  if (!analysisType || !exportFormat) {
    return <Typography>{t('pageSending.tabAdvancedExportOptions.noneSelected')}</Typography>
  }

  switch (analysisType) {
    case AnalyzeType.REPORT:
      switch (exportFormat) {
        case 'EXCEL':
          return <ReportExcelOptions {...formProps} />
        case 'IMAGE':
          return <ReportImageOptions {...formProps} />
        case 'PDF':
          return <ReportPdfOptions {...formProps} />
        default:
          return <div>Not implemented</div>
      }
    case AnalyzeType.DASHBOARD:
      switch (exportFormat) {
        case 'EXCEL':
          return <DashboardExcelOptions {...formProps} />
        case 'IMAGE':
          return <DashboardImageOptions {...formProps} />
        case 'PDF':
          return <DashboardPdfOptions {...formProps} />
        default:
          return <div>Not implemented</div>
      }
    default:
      return <div>invalid analysis type {analysisType}</div>
  }
}

export default AdvancedExportOptionsTab
