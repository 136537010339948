//import 'es6-shim'
import { ExpandLess, ExpandMore, Functions, Iso, ListAltOutlined, Search } from '@mui/icons-material'
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PivotGridDataSourceField } from 'devexpress-dashboard/viewer-parts/viewer-items/pivot-grid-item/_pivot-grid-item-helper'
import Draft, { CompositeDecorator, ContentState, DraftHandleValue, Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import React, { PropsWithChildren, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '../../components/TextField'
import '../../utils/arrayUtils'
import addHelperItem from './addHelperItem'
import LineNumberBlock from './components/LineNumberBlock'
import constants from './constants/constants'
import functions from './constants/functions'
import operators from './constants/operators'
import { keyBindingFn, keyBindingFunctionKey } from './keyBindings'
import { EditorRef, Keyword, KeywordGroup, KeywordType, TypeaheadState } from './types'
import { getSelectionCoords, getSelectionRange, getWordBounds } from './utils'

export interface ExpressionBuilderProps {
  onChange?(state: EditorState): void
  value?: EditorState
  fields?: PivotGridDataSourceField[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Helvetica, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    width: 800,
    height: 600,
    padding: 20
  },
  editor: {
    width: '100%',
    maxHeight: '25%',
    overflow: 'auto',
    flex: '1.5 0 0'
  },
  nestedList: {
    paddingLeft: theme.spacing(4)
  },
  optionsMenu: {
    width: '100%',
    flex: '1.5 0 0',
    maxHeight: '75%',
    display: 'flex',
    gap: 2
  },
  optionBlock: {
    flex: '1 0 0',
    overflow: 'auto',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(2)
  },
  focusedHelperItem: {
    backgroundColor: 'rgba(0,0,0,0.15)'
  }
}))

const entityStrategy =
  (entityType: 'Field' | 'Function') =>
  (block: Draft.ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
    block.findEntityRanges((character) => {
      const entityKey = character.getEntity()
      return entityKey !== null && contentState.getEntity(entityKey).getType() === entityType
    }, callback)
  }

const FieldEntityComponent = (props: any) => {
  return <span style={{ color: 'burlywood', fontWeight: 'bolder', fontSize: '16px' }}>{props.children}</span>
}
const FunctionEntityComponent = (props: any) => {
  return <span style={{ color: 'darkmagenta', fontWeight: 'bolder', fontSize: '16px' }}>{props.children}</span>
}
const findOperatorsStrategy = (block: Draft.ContentBlock, callback: (start: number, end: number) => void) => {
  const text = block.getText()
  const regex = /[+\-*/^%]/g
  let escapeHatch = 0
  let result: RegExpExecArray
  while ((result = regex.exec(text) as RegExpExecArray) != null) {
    const start = result.index
    const end = start + result[0].length
    // hack to vercome situations where regex.exec doesn't move the curser
    if (escapeHatch++ > 100) {
      return
    }
    callback(start, end)
  }
}

const decorators = new CompositeDecorator([
  {
    strategy: entityStrategy('Field'),
    component: FieldEntityComponent
  },
  {
    strategy: entityStrategy('Function'),
    component: FunctionEntityComponent
  },
  {
    strategy: findOperatorsStrategy,
    component: FunctionEntityComponent
  }
])

const ExpressionBuilder: React.FC<PropsWithChildren<ExpressionBuilderProps>> = ({
  value = EditorState.createEmpty(decorators),
  onChange,
  fields
}) => {
  const [typeaheadState, setTypeaheadState] = useState<TypeaheadState | null>(null)
  const classes = useStyles()
  const editorRef = useRef<EditorRef | null>(null)
  const [selectedKeywordType, setSelectedKeywordType] = useState<KeywordType>(KeywordType.Field)
  const [expandedFuncOption, setExpandedFuncOption] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<KeywordGroup | null>(null)
  const [selectedKeyword, setSelectedKeyword] = useState<Keyword | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()

  const keywords = [
    ...(fields?.map((x) => ({
      name: x.dataField!,
      label: x.caption,
      type: KeywordType.Field,
      description: 'Field of plug',
      group: KeywordGroup.NONE
    })) || []),
    ...functions
  ]
  const keywordsInfo = [...keywords, ...constants, ...operators]

  const handleTypeahead = (editorProps: EditorState) => {
    const range = getSelectionRange()
    if (range === null) return
    const selection = editorProps.getSelection()
    const currentBlockKey = selection.getAnchorKey()
    const blockText = editorProps.getCurrentContent().getBlockForKey(currentBlockKey).getText()
    const { word } = getWordBounds(blockText, selection.getAnchorOffset())
    const { offsetLeft, offsetTop } = getSelectionCoords(range)

    setTypeaheadState({
      word: word,
      offsetLeft: offsetLeft,
      offsetTop: offsetTop,
      optionIndex: 0,
      visible: true
    })
  }

  const handleChange = (editorProps: EditorState) => {
    onChange?.(editorProps)
    handleTypeahead(editorProps)
  }

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === keyBindingFunctionKey.OPEN_SUGGESTIONS) {
      handleChange(value)
      return 'handled'
    }
    if (command === keyBindingFunctionKey.CLOSE_SUGGESTIONS) {
      handleCloseTypeahed()
      return 'handled'
    }
    if (command === keyBindingFunctionKey.SUGGESTIONS_MOVE_DOWN) {
      handleDownArrow()
      return 'handled'
    }
    if (command === keyBindingFunctionKey.SUGGESTIONS_MOVE_UP) {
      handleUpArrow()
      return 'handled'
    }
    if (command === keyBindingFunctionKey.SUGGESTIONS_SELECT) {
      handleCloseTypeahed()
      handleSelectContextHelperItem(getFilteredContextItems()[typeaheadState!.optionIndex])
      return 'handled'
    }
    return 'not-handled'
  }

  const handleCloseTypeahed = () => {
    setTypeaheadState((prev) => ({ ...prev!, visible: false }))
  }

  const handleSelectContextHelperItem = (item: Keyword) => {
    const newState = addHelperItem(value, item)

    onChange?.(newState)
  }

  const getFilteredContextItems = () => {
    return keywords.filter((it) => {
      if (!typeaheadState?.word) {
        return true
      }
      return it.name!.normalize().toLowerCase().includes(typeaheadState.word.normalize().toLowerCase())
    })
  }

  const canShowTypeahead = (): boolean => {
    return Boolean(
      typeaheadState && typeaheadState.visible && typeaheadState.word && getFilteredContextItems().length > 0
    )
  }

  const handleDownArrow = () => {
    if (canShowTypeahead()) {
      setTypeaheadState((prev) => {
        if (prev!.optionIndex < getFilteredContextItems().length) {
          return { ...prev!, optionIndex: prev!.optionIndex + 1 }
        }
        return prev!
      })
    }
  }
  const handleUpArrow = () => {
    if (canShowTypeahead()) {
      setTypeaheadState((prev) => {
        if (prev!.optionIndex > 0) {
          return { ...prev!, optionIndex: prev!.optionIndex - 1 }
        }
        return prev!
      })
    }
  }

  const blockRenderFn = () => ({
    component: LineNumberBlock
  })

  return (
    <Paper className={classes.root}>
      <div className={classes.editor}>
        <Editor
          ref={editorRef}
          keyBindingFn={keyBindingFn(canShowTypeahead())}
          handleKeyCommand={handleKeyCommand}
          editorState={value}
          onChange={handleChange}
          blockRendererFn={blockRenderFn}
        />
      </div>
      <Popover
        disableEscapeKeyDown
        onClose={handleCloseTypeahed}
        disableRestoreFocus
        disableAutoFocus
        disableEnforceFocus
        anchorReference="anchorPosition"
        anchorPosition={{
          left: typeaheadState?.offsetLeft || 0,
          top: typeaheadState?.offsetTop || 0
        }}
        open={canShowTypeahead()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List>
          {getFilteredContextItems().map((it, index) => (
            <ListItem
              className={index === typeaheadState?.optionIndex ? classes.focusedHelperItem : ''}
              button
              dense
              onClick={() => handleSelectContextHelperItem(it)}
            >
              <ListItemText primary={it.name} secondary={it.type} />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Box className={classes.optionsMenu}>
        <Box className={classes.optionBlock}>
          <List>
            <ListItemButton
              selected={selectedKeywordType === KeywordType.Field}
              onClick={() => setSelectedKeywordType(KeywordType.Field)}
            >
              <ListItemIcon>
                <ListAltOutlined />
              </ListItemIcon>
              <ListItemText primary={t('pagePlugs.modalCreate.customFields.fields')} />
            </ListItemButton>
            <ListItemButton
              selected={selectedKeywordType === KeywordType.Function}
              onClick={() => {
                setExpandedFuncOption((prev) => !prev)
                setSelectedKeywordType(KeywordType.Function)
              }}
            >
              <ListItemIcon>
                <Functions />
              </ListItemIcon>
              <ListItemText primary={t('pagePlugs.modalCreate.customFields.functions')} />
              {expandedFuncOption ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expandedFuncOption} timeout="auto" unmountOnExit>
              <List component="div" className={classes.nestedList} disablePadding>
                {functions.groupBy('group').map((fc) => (
                  <ListItemButton
                    key={fc.key}
                    selected={selectedGroup === fc.key}
                    onClick={() => {
                      setSelectedKeywordType(KeywordType.Function)
                      setSelectedGroup(fc.key as KeywordGroup)
                    }}
                  >
                    <ListItemText primary={t('expressionBuilder.functions.aggregate')} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItemButton
              selected={selectedKeywordType === KeywordType.Operator}
              onClick={() => setSelectedKeywordType(KeywordType.Operator)}
            >
              <ListItemIcon>
                <Iso />
              </ListItemIcon>
              <ListItemText primary={t('pagePlugs.modalCreate.customFields.operators')} />
            </ListItemButton>
          </List>
        </Box>
        <Box className={classes.optionBlock} style={{ paddingTop: 0 }}>
          {selectedKeywordType === KeywordType.Function && !selectedGroup ? (
            <>{t('pagePlugs.modalCreate.customFields.functions.helpText')}</>
          ) : (
            <Box height="100%">
              <Paper elevation={0} style={{ display: 'block' }}>
                <TextField
                  placeholder={t('pagePlugs.modalCreate.customFields.functions.search')!}
                  type="search"
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    startAdornment: <Search />
                  }}
                />
              </Paper>
              <Box height="79%" overflow="auto">
                <List>
                  {keywordsInfo
                    .filter(
                      (e) =>
                        e.type === selectedKeywordType &&
                        (selectedKeywordType === KeywordType.Function ? e.group === selectedGroup : true) &&
                        (searchValue ? e.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) : true)
                    )
                    .map((fc) => (
                      <ListItemButton
                        key={fc.name}
                        selected={fc.name === selectedKeyword?.name}
                        onClick={() => setSelectedKeyword(fc)}
                      >
                        <ListItemText primary={fc.label ?? fc.name} />
                      </ListItemButton>
                    ))}
                </List>
              </Box>
            </Box>
          )}
        </Box>
        {selectedKeywordType !== KeywordType.Field && (
          <Box className={classes.optionBlock}>
            <Typography color="textPrimary">{selectedKeyword?.description}</Typography>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default ExpressionBuilder
