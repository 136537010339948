import Axios, { AxiosResponse } from 'axios'

import api from '../api'

import { BASE_URL, ENDPOINTS } from '../../constants/apiEndpoints'
import { Payment } from '../../hooks/usePayment/payment'


const createCheckoutSession = async (planId: number): Promise<AxiosResponse> => {
  return api.post(ENDPOINTS.BILLING_CREATE_CHECKOUT_SESSION,{
    planId
  })
}

const createCustomerPortalSession = async (): Promise<AxiosResponse<{url: string}>> => {
  return api.post(ENDPOINTS.BILLING_CREATE_CUSTOMER_PORTAL_SESSION)
}

const getSubscription = async (): Promise<AxiosResponse<Payment>> => {
  return api.get(ENDPOINTS.BILLING_SUBSCRIPTION)
}

export default {createCheckoutSession, getSubscription, createCustomerPortalSession}



