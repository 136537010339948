import { Edit, LibraryAdd, Save, Share, Visibility } from '@mui/icons-material'
import { Box, SvgIcon, Theme, useMediaQuery } from '@mui/material'
import axios from 'axios'
import { JSReportDesigner } from 'devexpress-reporting/dx-reportdesigner'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import AddToCollectionDialog from '../../components/AddToCollectionDialog'
import AnalyzeSwipeableDrawer, { AnalyzeSwipeableDrawerOptionProps } from '../../components/AnalyzeSwipeableDrawer'
import ShareDialog from '../../components/ShareDialog'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { useCheckAnotherAccount } from '../../hooks/useCheckAnotherAccount'
import SaveDialog from '../../pages/dashboard/extensions/SaveDialog'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'
import { PlugClaimType } from '../../schemas/UserSchema'
import { analyzeService } from '../../services'
import ReportDesigner, { ReportDesignerRef } from './ReportDesigner'

interface ReportPageProps {
  fromCollection?: boolean
  hideMenu?: boolean
  onBackButtonClick?: () => void
}

const ReportPage: React.FC<PropsWithChildren<ReportPageProps>> = ({ hideMenu, fromCollection, onBackButtonClick }) => {
  const { id, collectionId } = useParams<any>()
  const [analyzeInfo, setAnalyzeInfo] = useState<AnalyzeSchema | null>(null)
  const [reportId, setReportId] = useState<string | number>(id as any)
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [addCollectionDialogOpen, setAddCollectionDialogOpen] = useState(false)
  const isNew = !isNaN(Number(reportId))
  const [inEditMode, setInEditMode] = useState(isNew)
  const auth = useAuth()
  const userInfo = auth.getUserInfo()
  const isOwner = userInfo?.userId === analyzeInfo?.userOwner.id
  const canEdit = isOwner || analyzeInfo?.claimType === PlugClaimType.Analysis_RW
  const userCanManageAnalysis = userInfo?.profile.managingAnalyseAndCollection
  const navigate = useNavigate()
  const reportRef = useRef<ReportDesignerRef | null>(null)
  const { t } = useTranslation()
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [parentReportId, setParentReportId] = useState(id)
  const { check } = useCheckAnotherAccount()

  const control = reportRef.current?.designerControl

  // useEffect(() => {
  //   const emptyFunc = () => true
  //   if (control?.IsModified()) {
  //     window.addEventListener('beforeunload', emptyFunc)
  //     setIsModified(true)
  //   } else {
  //     window.removeEventListener('beforeunload', emptyFunc)
  //     setIsModified(false)
  //   }
  //   return () => {
  //     window.removeEventListener('beforeunload', emptyFunc)
  //   }
  // }, [control])

  useEffect(() => {
    if (!inEditMode && !!control) {
      reportRef.current?.showPreview()
      return
    }
    if (inEditMode && !!control) {
      reportRef.current?.showDesign()
    }
  }, [inEditMode, control])

  useEffect(() => {
    if (hideMenu && !!control) {
      setInEditMode(true)
    }
  }, [hideMenu, control])

  const getWidth = useCallback(() => {
    return hideMenu || mdDown ? '100%' : 'calc(100% - 57px)'
  }, [hideMenu, mdDown])

  const loadAnalyzeInfo = useCallback(async () => {
    try {
      const { data } = await analyzeService.getAnalyze(reportId as string)
      await check(data)
      setAnalyzeInfo(data)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.status === 403 || err.response?.status === 403) {
          if (fromCollection) {
            const generatedPath = generatePath('/collection/:collectionId/unauthorized', {
              collectionId: collectionId!
            })
            navigate(generatedPath, {
              state: err.response?.data
            })
            return
          }
          navigate(PATHS.UNAUTHORIZED, {
            state: err.response?.data
          })
        } else if (err.status === 404 || err.response?.status === 404) {
          navigate('not-found')
          return
        }
      }
    }
  }, [navigate, reportId, check])

  useEffect(() => {
    if (!isNew) {
      loadAnalyzeInfo()
    }
  }, [isNew, loadAnalyzeInfo])

  let swipableDrawerOptions: AnalyzeSwipeableDrawerOptionProps[] = [
    {
      icon: <SvgIcon component={Save} />,
      disabled: isNew ? false : !canEdit,
      label: t('report.menuSave'),
      action: () => handleSave()
    },
    {
      icon: (
        <img
          style={{
            filter: mdDown
              ? 'invert(57%) sepia(19%) saturate(1549%) hue-rotate(125deg) brightness(93%) contrast(82%)'
              : 'invert(1)'
          }}
          src={ENDPOINTS.PUBLIC_IMAGES + '/save-as-icon.svg'}
          alt="save as icon"
        />
      ),
      disabled: isNew ? true : canEdit ? false : !userCanManageAnalysis,
      label: t('report.menuSaveACopy'),
      action: () => setSaveDialogOpen(true)
    },
    {
      icon: <SvgIcon component={Share} />,
      disabled: isNew ? true : !isOwner,
      label: t('report.menuShare'),
      action: () => setShareDialogOpen(true)
    },
    {
      icon: <SvgIcon component={LibraryAdd} />,
      disabled: isNew ? true : !canEdit,
      label: t('report.menuAddToCollection'),
      action: () => setAddCollectionDialogOpen(true)
    }
  ]

  const reportOptions = [
    {
      disabled: isNew ? false : !canEdit,
      icon: <SvgIcon component={inEditMode ? Visibility : Edit} />,
      label: inEditMode ? t('report.menuViewMode') : t('report.menuEditMode'),
      action: () => setInEditMode((prev) => !prev)
    }
  ]

  swipableDrawerOptions = [...swipableDrawerOptions, ...(() => (mdDown ? [] : [...reportOptions]))()]

  const handleHomeButtonClick = () => {
    try {
      if (control?.IsModified()) {
        if (window.confirm(t('report.toast.warning.leavePage')!)) {
          navigate(PATHS.HOME)
        }
        return
      }
      navigate(PATHS.HOME)
    } catch (err) {
      navigate(PATHS.HOME)
    }
  }

  const handleSaveNew = async (reportName: string) => {
    const reportDesignerControl = reportRef.current?.designerControl as JSReportDesigner & {
      notFirstOpen: boolean
    }
    const currentReportId = reportDesignerControl.GetCurrentTab().url()
    if (currentReportId !== parentReportId) {
      // IS SUBREPORT
      const newSubReportId = await reportDesignerControl?.SaveNewReport(reportName + '-$SUBREPORT$')
      reportDesignerControl.GetCurrentTab().url(newSubReportId)
      reportDesignerControl.GetCurrentTab().report().name(reportName)
    } else {
      // IS PARENT REPORT
      const newReportId = await reportDesignerControl?.SaveNewReport(reportName)
      window.history.replaceState(null, '', generatePath(PATHS.REPORT, { id: newReportId }))
      setReportId(newReportId)
      setParentReportId(newReportId)
    }
    setSaveDialogOpen(false)
    reportDesignerControl?.ResetIsModified()
  }

  const handleSave = async () => {
    const reportDesignerControl = reportRef.current?.designerControl
    if (!isNaN(reportDesignerControl?.GetCurrentTab().url() as any)) {
      setSaveDialogOpen(true)
      return
    }
    reportDesignerControl?.SaveReport()
    reportDesignerControl?.ResetIsModified()
  }

  const handleBackToCollection = () => {
    const reportDesignerControl = reportRef.current?.designerControl
    reportDesignerControl?.ShowPreview()
    onBackButtonClick?.()
  }

  return (
    <Box display="flex" width="100vw" height="100vh">
      <AnalyzeSwipeableDrawer
        hidden={hideMenu}
        showBackButton={fromCollection}
        onBackButtonClick={handleBackToCollection}
        analyzeName={analyzeInfo?.name || 'New Report'}
        analyzeTypeName="Report"
        options={swipableDrawerOptions}
        onHomeButtonClick={handleHomeButtonClick}
      />
      <Box width={getWidth()} height="100%">
        <ReportDesigner
          ref={reportRef}
          reportUrl={reportId}
          startInPreviewMode={!isNew}
          allowExport={userInfo?.profile.canExportData || false}
        />
      </Box>
      <SaveDialog
        title={t('report.menuSaveCopy.modalTitle')!}
        open={saveDialogOpen}
        onClickSave={handleSaveNew}
        onClose={() => setSaveDialogOpen(false)}
      />
      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        analyzeOrCollection={analyzeInfo || undefined}
      />
      <AddToCollectionDialog
        title={t('report.menuCollection.modalTitle')}
        onClose={() => setAddCollectionDialogOpen(false)}
        description={t('report.menuCollection.modalSubtitle')}
        analyze={analyzeInfo || undefined}
        open={addCollectionDialogOpen}
      />
    </Box>
  )
}

export default ReportPage
