import { Alert, AlertColor } from '@mui/material'
import { SnackbarMessage, SnackbarProvider, VariantType, useSnackbar, withSnackbar } from 'notistack'
import ReactDOM from 'react-dom'

const variantToSeverity = (variant?: VariantType): AlertColor => {
  if (!variant || variant === 'default') return 'success'
  return variant
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  toast: function (msg: SnackbarMessage, variant: VariantType) {
    const Display = withSnackbar(({ enqueueSnackbar }) => {
      enqueueSnackbar(msg, { variant, preventDuplicate: true, autoHideDuration: 5000 })
      return null
    })

    const mountPoint = document.getElementById('snackbarhelper')
    ReactDOM.render(
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        content={(key, msg) => {
          const { closeSnackbar } = useSnackbar()
          return (
            <Alert variant="filled" key={key} severity={variantToSeverity(variant)} onClose={() => closeSnackbar(key)}>
              {msg as string}
            </Alert>
          )
        }}
      >
        <Display />
      </SnackbarProvider>,
      mountPoint
    )
  }
}
