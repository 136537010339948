import { Cached, Edit, Fullscreen, FullscreenExit, Home, Settings, Share, Slideshow } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  Route,
  Routes,
  generatePath,
  useMatch,
  useNavigate,
  useOutletContext,
  useParams
} from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Button from '../../components/Button'
import ShareDialog from '../../components/ShareDialog'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import useErrorState from '../../hooks/useErrorState'
import CreateOrEditCollectionDialog from '../../pages/home/CreateOrEditCollectionDialog'
import { AnalyzeType } from '../../schemas/AnalyzeSchema'
import { CollectionAnalyzeSchema, CollectionSchema } from '../../schemas/CollectionSchema'
import { PlugClaimType } from '../../schemas/UserSchema'
import collectionService from '../../services/collectionService'
import windowUtils from '../../utils/windowUtils'
import DashboardPage, { DashboardPageRefProps } from '../dashboard'
import GeoAnalysisPage from '../geoAnalysis'
import PivotGridPage, { PivotGridPageRefProps } from '../pivotGrid'
import ReportPage from '../report/ReportPage'
import { UnauthorizedPage } from '../unauthorized/UnauthorizedPage'
import AutoRotationDialog from './AutoRotationDialog'
import CollectionOverlayActions, { MenuOption } from './CollectionOverlayActions'

const TypePathMapping = {
  [AnalyzeType.PIVOTGRID]: PATHS.PIVOT_GRID,
  [AnalyzeType.DASHBOARD]: PATHS.DASHBOARD,
  [AnalyzeType.REPORT]: PATHS.REPORT,
  [AnalyzeType.GEOANALYSE]: PATHS.GEO_ANALYSIS,
  [AnalyzeType.COLLECTION]: ''
}

const CollectionViewPage: React.FC<PropsWithChildren> = () => {
  const { collectionId } = useParams<{ collectionId: string }>()
  const [collection, setCollection] = useState<CollectionSchema>()
  const [currentSelectedAnalyzeIndex, setCurrentSelectedAnalyzeIndex] = useState<number | null>(null)
  const [hideAnalyzeMenu, setHideAnalyzeMenu] = useState(true)
  const [errorState, setErrorState] = useErrorState()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)
  const [analysisIsEmpty, setAnalysisIsEmpty] = useState(false)
  const auth = useAuth()
  const { t } = useTranslation()
  const matchesUnauthorized = useMatch('/collection/:collectionId/unauthorized')
  const isOnUnauthorized = useRef(false)
  const [autoRotationInterval, setAutoRotationInterval] = useState(0) // 0 = disabled
  const [openAutoRotationDialog, setOpenAutoRotationDialog] = useState(false)
  const userInfo = auth.getUserInfo()
  const canEdit = userInfo?.userId === collection?.userOwner.id || collection?.claimType === PlugClaimType.Collection_RW
  const pivotGridPageRef = useRef<PivotGridPageRefProps>(null)
  const dashboardPageRef = useRef<DashboardPageRefProps>(null)

  const navigate = useNavigate()

  const gotoAnalyze = useCallback(
    (analyze: CollectionAnalyzeSchema) => {
      const analyzePath = TypePathMapping[analyze.type]
      const generatedPath = generatePath('/collection/:collectionId' + analyzePath, {
        collectionId: collectionId,
        id: analyze.id
      })
      navigate(generatedPath, {
        state: {
          hashCode: uuidv4()
        },
        replace: true
      })
    },
    [collectionId, navigate]
  )

  useEffect(() => {
    if (analysisIsEmpty || !collection) {
      return
    }
    const timer = setInterval(() => {
      if (!hideAnalyzeMenu) {
        // in edit mode
        return
      }
      const analysisCount = collection.analysis.length
      const isSingle = analysisCount === 1
      if (isSingle) {
        gotoAnalyze(collection.analysis[0])
        return
      }
      const isLast = currentSelectedAnalyzeIndex === analysisCount - 1
      if (isLast) {
        setCurrentSelectedAnalyzeIndex(0)
      } else {
        setCurrentSelectedAnalyzeIndex((prev) => (prev || 0) + 1)
      }
    }, autoRotationInterval)

    if (autoRotationInterval === 0) {
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [analysisIsEmpty, autoRotationInterval, collection, currentSelectedAnalyzeIndex, gotoAnalyze, hideAnalyzeMenu])

  useEffect(() => {
    if (!!matchesUnauthorized && !isOnUnauthorized.current) {
      isOnUnauthorized.current = true
      return
    } else {
      isOnUnauthorized.current = false
    }
    if (!collection || collection.analysis.length === 0) {
      return
    }
    if (currentSelectedAnalyzeIndex === null) {
      return gotoAnalyze(collection.analysis[0])
    }
    gotoAnalyze(collection.analysis[currentSelectedAnalyzeIndex])
  }, [currentSelectedAnalyzeIndex, collection, gotoAnalyze, matchesUnauthorized])

  const loadAnalysis = useCallback(async () => {
    try {
      const { data } = await collectionService.getCollection(collectionId!)
      if (data.analysis.length === 0) {
        setAnalysisIsEmpty(true)
        setCollection(data)
        return
      }
      setAnalysisIsEmpty(false)
      data.analysis = data.analysis.sortBy((e) => e.ordination)
      setCollection(data)
    } catch (err: any) {
      setErrorState({
        error: true,
        description: err.message
      })
    }
  }, [collectionId, setErrorState])

  useEffect(() => {
    loadAnalysis()
  }, [loadAnalysis])

  const menuOptions: MenuOption[] = [
    {
      label: t('collection.editAnalysis'),
      icon: <Edit />,
      disabled: !canEdit,
      onClick: () => setHideAnalyzeMenu(false)
    },
    {
      label: t('collection.menuShare'),
      icon: <Share />,
      disabled: !canEdit,
      onClick: () => setShareDialogOpen(true)
    },
    {
      label: t('collection.autoRotation'),
      icon: <Slideshow />,
      onClick: () => setOpenAutoRotationDialog(true)
    },
    {
      label: t('collection.settings'),
      icon: <Settings />,
      disabled: !canEdit,
      onClick: () => setSettingsDialogOpen(true)
    },
    {
      label: t('collection.home'),
      icon: <Home />,
      onClick: () => navigate(PATHS.HOME)
    }
  ]

  const currentAnalysis = collection?.analysis[currentSelectedAnalyzeIndex || 0]
  if (currentAnalysis?.type === AnalyzeType.PIVOTGRID || currentAnalysis?.type === AnalyzeType.DASHBOARD) {
    menuOptions.unshift({
      icon: <Cached />,
      label: t('pivotGrid.sideMenu.renewCache'),
      onClick: () => {
        pivotGridPageRef.current?.reloadData?.()
        dashboardPageRef.current?.reloadData?.()
      }
    })
  }

  if (isFullScreen) {
    menuOptions.unshift({
      label: t('collection.overlayMenu.exitFullScreen'),
      icon: <FullscreenExit />,
      onClick: () => {
        windowUtils.closeFullscreen()
        setIsFullScreen(false)
      }
    })
  } else {
    menuOptions.unshift({
      label: t('collection.overlayMenu.openFullScreen'),
      icon: <Fullscreen />,
      onClick: () => {
        windowUtils.openFullscreen()
        setIsFullScreen(true)
      }
    })
  }

  const handleSelectAnalyze = (selectedAnalyze: CollectionAnalyzeSchema) => {
    setCurrentSelectedAnalyzeIndex(collection?.analysis.indexOf(selectedAnalyze) || 0)
  }

  const handleBackToCollection = () => {
    setHideAnalyzeMenu(true)
  }

  return (
    <>
      <CollectionOverlayActions
        hidden={!hideAnalyzeMenu}
        analysis={collection?.analysis || []}
        currentSelectedAnalyzeIndex={currentSelectedAnalyzeIndex || 0}
        onAnalyzeClick={handleSelectAnalyze}
        menuOptions={menuOptions}
        onBack={() => setCurrentSelectedAnalyzeIndex((prev) => (prev || 0) - 1)}
        onForward={() => setCurrentSelectedAnalyzeIndex((prev) => (prev || 0) + 1)}
      />
      {analysisIsEmpty && (
        <Box width="100vw" height="100vh" alignItems="center" display="flex" justifyContent="center">
          <Typography>
            {t('collection.emptyAnalysis')}{' '}
            <Button variant="text" label="here" onClick={() => setSettingsDialogOpen(true)}></Button>{' '}
            {t('collection.linkAdd')}
          </Typography>
        </Box>
      )}
      {errorState?.error ? (
        <div>{errorState.description}</div>
      ) : (
        <Outlet
          context={{
            fromCollection: true,
            hideAnalyzeMenu: hideAnalyzeMenu,
            onBackButtonClick: handleBackToCollection,
            pivotGridPageRef,
            dashboardPageRef
          }}
        />
      )}
      <ShareDialog open={shareDialogOpen} analyzeOrCollection={collection} onClose={() => setShareDialogOpen(false)} />
      <CreateOrEditCollectionDialog
        title={t('collection.modalCollection.title')}
        onClose={() => {
          setSettingsDialogOpen(false)
          loadAnalysis()
        }}
        description={t('collection.modalCollection.subtitle')}
        open={settingsDialogOpen}
        collection={collection || null}
      />
      <AutoRotationDialog
        open={openAutoRotationDialog}
        onApply={(value) => {
          setAutoRotationInterval(value)
          setOpenAutoRotationDialog(false)
        }}
        onClose={() => setOpenAutoRotationDialog(false)}
        value={autoRotationInterval}
      />
    </>
  )
}

export const CollectionAnalysis = () => {
  const { hideAnalyzeMenu, onBackButtonClick, pivotGridPageRef, dashboardPageRef } = useOutletContext<{
    hideAnalyzeMenu: boolean
    onBackButtonClick: () => void
    pivotGridPageRef: React.MutableRefObject<PivotGridPageRefProps | null>
    dashboardPageRef: React.MutableRefObject<DashboardPageRefProps | null>
  }>()

  return (
    <Routes>
      <Route
        path={PATHS.PIVOT_GRID}
        element={
          <PivotGridPage
            ref={pivotGridPageRef}
            fromCollection
            hideMenu={hideAnalyzeMenu}
            onBackButtonClick={onBackButtonClick}
          />
        }
      />

      <Route
        path={PATHS.DASHBOARD}
        element={
          <DashboardPage
            ref={dashboardPageRef}
            fromCollection
            hideMenu={hideAnalyzeMenu}
            onBackButtonClick={onBackButtonClick}
          />
        }
      />

      <Route
        path={PATHS.REPORT}
        element={<ReportPage fromCollection hideMenu={hideAnalyzeMenu} onBackButtonClick={onBackButtonClick} />}
      />

      <Route
        path={PATHS.GEO_ANALYSIS}
        element={<GeoAnalysisPage fromCollection hideMenu={hideAnalyzeMenu} onBackButtonClick={onBackButtonClick} />}
      />
      <Route path={PATHS.UNAUTHORIZED} element={<UnauthorizedPage />} />
    </Routes>
  )
}

export default CollectionViewPage
