import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Collapse, List, ListItem, ListItemText, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren, useState } from 'react'

export interface ErrorDescription {
  errorCode: string
  message: string
}

interface ErrorDescriptorProps {
  title?: string
  errors: ErrorDescription[]
  style?: React.CSSProperties
  className?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  groupHeaderError: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.error.main}`
  },
  groupHeaderErrorText: {
    color: theme.palette.error.main
  }
}))

const ErrorDescriptor: React.FC<PropsWithChildren<ErrorDescriptorProps>> = ({ title, errors, className, style }) => {
  const displayTitle = title || 'Some problems occurred'

  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <Box style={style} className={clsx(classes.root, className)}>
      <Typography variant="h2" color="error">
        {displayTitle}
      </Typography>
      <List>
        <ListItem className={classes.groupHeaderError} onClick={() => setOpen((prev) => !prev)}>
          <ListItemText className={classes.groupHeaderErrorText} primary="Errors" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {errors.map((err) => (
              <ListItem>
                <ListItemText primary={`Code: ${err.errorCode}`} secondary={`Description: ${err.message}`} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </Box>
  )
}

export default ErrorDescriptor
