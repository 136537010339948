import { Add } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import clsx from 'clsx'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiConnectionType, ConnectionSchema } from '../../../schemas/ConnectionSchema'
import { connectionService } from '../../../services'
import Loading from '../../Loading'
import SelectBox from '../../SelectBox'
import TextField from '../../TextField'
import MovideskConnectionForm from './MovideskConnectionForm'

const useStyles = makeStyles((theme) => ({
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  dialogFields: {
    width: '33%',
    minWidth: 180,
    margin: theme.spacing(3, 0, 0)
  },
  fullWidth: {
    width: 'calc(100% - 48px)'
  },
  inputHelperColorWhite: {
    '& .MuiFormHelperText-root': {
      color: 'white'
    }
  }
}))

const today = moment().calendar()
const last2years = moment().subtract(2, 'years').calendar()

type MovideskPlugFormProps = {
  values: any
  onChange: (values: any) => void
}

const MovideskPlugForm: React.FC<PropsWithChildren<MovideskPlugFormProps>> = ({ onChange, values }) => {
  const classes = useStyles()
  const [connections, setConnections] = useState<ConnectionSchema[]>([])

  const [loadingFindConnection, setLoadingFindConnection] = useState(false)

  const [loadingCreateConnection, setLoadingCreateConnection] = useState(false)
  const [showCreateConnectionForm, setShowCreateConnectionForm] = useState(false)

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const handleValuesChange = (key: string, value: any) => {
    onChange({ ...values, [key]: value })
  }

  const handleCreateConnection = async (values: any) => {
    values.type = ApiConnectionType.Movidesk
    try {
      setLoadingCreateConnection(true)
      const response = await connectionService.create(values)

      setConnections((prev) => [...prev, response.data])
      handleValuesChange('selectedConnection', response.data)
      setShowCreateConnectionForm(false)

      enqueueSnackbar(t('pagePlugs.modalCreate.toast.success.createConnection', { connectionName: values.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const firstError = err.response?.data?.[0]
        console.error(firstError)
        enqueueSnackbar(`${firstError?.errorCode} : ${firstError?.errorMessage}`, { variant: 'error' })
        return
      }
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.createConnection'), { variant: 'error' })
    } finally {
      setLoadingCreateConnection(false)
    }
  }

  const handleOpenSelectConnection = () => {
    ;(async () => {
      try {
        setLoadingFindConnection(true)
        const connsResp = await connectionService.getAll()
        setConnections(connsResp.data.filter((x) => x.type === ApiConnectionType.Movidesk))
      } catch (err: any) {
        console.error(err)
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.loadingConnections'), { variant: 'error' })
      } finally {
        setLoadingFindConnection(false)
      }
    })()
  }

  useEffect(() => {
    handleValuesChange('tableOrView', 'Tickets')
  }, [])

  return (
    <>
      {showCreateConnectionForm ? (
        <>
          {loadingCreateConnection ? (
            <Loading forceAlign plugFill="#fff" loopFill="#fff" />
          ) : (
            <MovideskConnectionForm
              onCancelClick={() => setShowCreateConnectionForm(false)}
              onSubmit={handleCreateConnection}
              variant="white"
            />
          )}
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" className={classes.dialogFields}>
            <SelectBox
              className={clsx(classes.outlinedWhiteInput, classes.fullWidth)}
              options={connections}
              value={values.selectedConnection}
              onChange={(event, newValue) => handleValuesChange('selectedConnection', newValue)}
              getOptionLabel={(o) => o.name}
              loading={loadingFindConnection}
              onOpen={handleOpenSelectConnection}
              textFieldProps={{
                placeholder: t('pagePlugs.modalCreate.configureConnection')!,
                name: 'connection'
              }}
            />
            <IconButton onClick={() => setShowCreateConnectionForm(true)} color="inherit" size="large">
              <Add />
            </IconButton>
          </Box>
          <TextField
            variant="outlined"
            fullWidth={false}
            value={values.plugName}
            onChange={(event) => handleValuesChange('plugName', event.target.value)}
            required
            className={clsx(classes.outlinedWhiteInput, classes.dialogFields)}
            placeholder={t('pagePlugs.modalCreate.configureName')!}
          />
        </>
      )}
    </>
  )
}

export default MovideskPlugForm
