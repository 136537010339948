import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material'
import { compact, intersection, union, unionBy } from 'lodash'
import { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Swipeable from 'react-swipeable-views'
import { PlugFieldSchema } from '../../../../../../schemas/PlugSchema'
import NavigationHeader, { NavigationHeaderProps } from '../../NavigationHeader'
import googleAdsFields from './google_ads_fields.json'

type GoogleAdsFieldListProps = {
  navigationProps?: Partial<NavigationHeaderProps>
  hideHeader?: boolean
  selectedFields?: PlugFieldSchema[]
  onChange?: (fields: PlugFieldSchema[], resources: string[]) => void
}

type GoogleAdsField = (typeof googleAdsFields.data)[0]
const fixedSelection: GoogleAdsField[] = [
  {
    name: 'segments&date',
    incompatibleProperties: null,
    resources: [
      'ad_group_ad',
      'age_range_view',
      'gender_view',
      'geographic_view',
      'keyword_view',
      'search_term_view',
      'campaign'
    ],
    type: 'DATE',
    group: 'Segments'
  }
]

const initialResources = intersection(compact(googleAdsFields.data.flatMap((x) => x.resources)))
const GoogleAdsFieldList: React.FC<PropsWithChildren<GoogleAdsFieldListProps>> = ({
  navigationProps,
  hideHeader,
  selectedFields = [],
  onChange
}) => {
  const checkedFields = useMemo(() => {
    const selecteds = googleAdsFields.data.filter((x) => selectedFields.some((e) => e.name === x.name)) || []
    return unionBy(selecteds, fixedSelection, 'name')
  }, [selectedFields])

  const [keysOpen, setKeysOpen] = useState<string[]>([])
  const { slideUpdateHeight } = useContext<any>((Swipeable as any).SwipeableViewsContext as any)

  const { t } = useTranslation()

  const availableResources = useMemo(() => {
    const checkedFieldsWithResources = checkedFields.filter((x: any) => !!x.resources)

    return checkedFieldsWithResources.length > 0
      ? intersection(...checkedFieldsWithResources.map((x: any) => x.resources!))
      : initialResources
  }, [checkedFields])

  const incompatibleProperties = useMemo(() => {
    const checkedFieldsWithIncompatibleProperties = checkedFields.filter((x: any) => !!x.incompatibleProperties)

    return checkedFieldsWithIncompatibleProperties.length > 0
      ? union(...checkedFieldsWithIncompatibleProperties.map((x: any) => x.incompatibleProperties!))
      : []
  }, [checkedFields])

  useEffect(() => {
    setTimeout(() => slideUpdateHeight(), 400)
  }, [keysOpen, slideUpdateHeight])

  const groupedAdsFields = useMemo(() => {
    return googleAdsFields.data.groupBy('group')
  }, [])

  const handleToggle = (value: GoogleAdsField) => () => {
    const currentIndex = checkedFields.findIndex((x: any) => x.name === value.name)
    const newChecked: PlugFieldSchema[] = checkedFields.map((x: any) => ({
      name: x.name,
      label: t(x.name),
      type: x.type as any
    }))

    if (currentIndex === -1) {
      newChecked.push({ label: value.name, name: value.name, type: value.type as any })
      if (
        value.name === 'metrics&absolute_top_impression_percentage' ||
        value.name === 'metrics&top_impression_percentage'
      ) {
        if (!newChecked.some((e) => e.name === 'metrics&impressions')) {
          newChecked.push({ label: t('metrics&impressions'), name: 'metrics&impressions', type: 'NUMERIC' as any })
        }
      }
    } else {
      newChecked.splice(currentIndex, 1)
      if (value.name === 'metrics&impressions') {
        const indexOfAbsTopImpr = newChecked.findIndex((e) => e.name === 'metrics&absolute_top_impression_percentage')
        if (indexOfAbsTopImpr !== -1) {
          newChecked.splice(indexOfAbsTopImpr, 1)
        }
        const indexOfTopImpr = newChecked.findIndex((e) => e.name === 'metrics&top_impression_percentage')
        if (indexOfTopImpr !== -1) {
          newChecked.splice(indexOfTopImpr, 1)
        }
      }
    }

    onChange?.(
      newChecked.map((x) => ({
        label: t(x.name),
        name: x.name,
        type: x.type
      })),
      availableResources as any
    )
  }

  console.log('available resources', availableResources)
  console.log('checked fields', checkedFields)

  return (
    <>
      {!hideHeader && (
        <NavigationHeader
          onClose={console.log}
          {...navigationProps}
          //   onNextClick={() => navigationProps?.onNextClick?.(getCheckedFieldsToPlugField())}
        />
      )}
      <Box color="white" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {!hideHeader && (
          <>
            <Typography variant="h2" align="center" component="div" color="inherit">
              {t('pagePlugs.modalCreate.configureTitle')}
            </Typography>
            <Typography variant="subtitle1" align="center" component="p" color="inherit">
              {t('pagePlugs.modalCreate.configureSubtitle')}
            </Typography>
          </>
        )}
        <Box width="100%" color="black">
          <List
            sx={{ maxWidth: '80%', bgcolor: 'background.paper', margin: 'auto', minHeight: window.innerHeight * 0.6 }}
            subheader={<ListSubheader component="div" id="nested-list-subheader" />}
          >
            {groupedAdsFields
              .sortBy((x) => x.key)
              .map((x) => (
                <li key={x.key as any}>
                  <ul style={{ listStyle: 'none' }}>
                    <ListSubheader sx={{ color: 'GrayText', fontSize: '1.5rem', fontWeight: 'bold' }}>
                      <ListItemButton
                        onClick={() => {
                          keysOpen.includes(x.key as any)
                            ? setKeysOpen((prev) => {
                                const keyIndex = prev.findIndex((e) => e === (x.key as any))
                                prev.splice(keyIndex, 1)
                                return [...prev]
                              })
                            : setKeysOpen((prev) => [...prev, x.key as any])
                          setTimeout(() => slideUpdateHeight(), 400)
                        }}
                      >
                        {x.key} {keysOpen.includes(x.key as any) ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </ListSubheader>
                    <Collapse in={keysOpen.includes(x.key as any)} timeout={250} unmountOnExit={false}>
                      {x.items.map((s) => (
                        <li key={x.key as any}>
                          <ListItemButton
                            disabled={
                              fixedSelection.map((e) => e.name).includes(s.name) ||
                              (!!s.resources && !availableResources.some((e: any) => s.resources.includes(e))) ||
                              incompatibleProperties.includes(s.name)
                            }
                            onClick={handleToggle(s)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checkedFields.findIndex((x: any) => x.name === s.name) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': s.name }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={t(s.name)} />
                          </ListItemButton>
                        </li>
                      ))}
                    </Collapse>
                  </ul>
                </li>
              ))}
          </List>
        </Box>
      </Box>
    </>
  )
}

export default GoogleAdsFieldList
