import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Autocomplete,
  Collapse,
  IconButton,
  ListItem,
  ListItemProps,
  ListItemText,
  Stack,
  TextField
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NestedList from '../../components/NestedList'
import { AnalyzeType, ShareType } from '../../schemas/AnalyzeSchema'
import Button from '../Button'

interface GroupedListItemProps extends Omit<ListItemProps, 'button' | 'component' | 'onChange'> {
  items: any[]
  selectedItems?: { [key in string | number]: any }[]
  label: string
  itemsKey: string
  itemsLabelKey: string
  fallbackItemLabelKey: string
  onChange?(userId: string, shareType: ShareType): void
  itemType?: AnalyzeType
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'unset',
    boxShadow: 'none'
  },
  nestedList: {
    paddingLeft: theme.spacing(4)
  },
  checkBox: {
    color: theme.palette.text.primary
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'unset'
    }
  }
}))

const GroupedListItem: React.FC<PropsWithChildren<GroupedListItemProps>> = ({
  items,
  selectedItems,
  label,
  itemsLabelKey,
  itemsKey,
  onChange,
  fallbackItemLabelKey,
  itemType,
  ...props
}) => {
  const { t } = useTranslation()

  const analysisSelectOptions: { key: string; value: ShareType }[] = [
    {
      key: t('pageHome.body.modalShare.comboBox.noShared'),
      value: ShareType.NoShare
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readOnly'),
      value: ShareType.Analysis_RO
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readWrite'),
      value: ShareType.Analysis_RW
    },
    {
      key: t('pageHome.body.modalShare.comboBox.copy'),
      value: ShareType.Copy
    }
  ]
  const collectionSelectOptions: { key: string; value: ShareType }[] = [
    {
      key: t('pageHome.body.modalShare.comboBox.noShared'),
      value: ShareType.NoShare
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readOnly'),
      value: ShareType.Collection_RO
    },
    {
      key: t('pageHome.body.modalShare.comboBox.readWrite'),
      value: ShareType.Collection_RW
    }
  ]

  const classes = useStyles()
  const [expansed, setExpansed] = useState(false)
  const [selectedAllMemberAction, setSelectedAllMemberAction] = useState<(typeof analysisSelectOptions)[0] | null>(
    analysisSelectOptions[0]
  )

  const handleToggleExpanse = () => {
    setExpansed((prev) => !prev)
  }

  const getOptions = (itemType?: AnalyzeType) => {
    if (itemType === AnalyzeType.COLLECTION) return collectionSelectOptions

    return analysisSelectOptions
  }

  const options = getOptions(itemType)

  const applyToAllMembers = () => {
    if (selectedAllMemberAction) {
      items.forEach((item) => {
        onChange && onChange(item[itemsKey], selectedAllMemberAction.value)
      })
    }
  }

  return (
    <>
      <ListItem component="li" button className={classes.root} {...props}>
        <ListItemText primary={label} />
        <IconButton
          className={classes.iconButton}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={handleToggleExpanse}
          size="large"
        >
          {expansed ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Collapse in={expansed} timeout="auto">
        <Stack paddingLeft={4} direction="row" spacing={2}>
          <Autocomplete
            value={selectedAllMemberAction}
            onChange={(e, newValue) => setSelectedAllMemberAction(newValue)}
            fullWidth={false}
            options={options}
            getOptionLabel={(opt) => options.find((x) => x.value == (opt?.value || opt))?.key || ''}
            renderInput={(props) => <TextField {...props} sx={{ minWidth: '260px' }} />}
          />
          <Button label="Apply to all members" onClick={() => applyToAllMembers()} />
        </Stack>
        <NestedList
          fallbackItemLabelKey={fallbackItemLabelKey}
          items={items.sort((x, y) =>
            !!x.fullName
              ? (x.fullName as string).localeCompare(y.fullName || y.email)
              : (x.email as string).localeCompare(y.fullName || y.email)
          )}
          itemsKey={itemsKey}
          itemsLabelKey={itemsLabelKey}
          selectedItems={selectedItems}
          onChange={onChange}
          itemType={itemType}
        />
      </Collapse>
    </>
  )
}

export default GroupedListItem
