const PATHS = {
  SIGNIN: '/signin',

  FORGOT_PASSWORD: '/forgot-password',

  RESET_PASSWORD: '/reset-password',

  SIGNUP: '/signup',

  HOME: '/',

  ANALYSIS: '/analysis',

  PLUGS: '/plugs',

  USERS: '/users',

  TEAMS: '/teams',

  PROFILES: '/profiles',

  PARAMETERS: '/parameters',

  SENDING: '/sending',

  PIVOT_GRID: '/pivot-grid/:id',

  CONNECTIONS: '/connections',

  SELECT_ACCOUNT: '/select-account',

  ACCOUNT: '/account',

  ACCOUNT_GENERAL: '/account/general',

  ACCOUNT_SECURITY: '/my-user/security',

  MY_USER: '/my-user',

  ACCOUNT_BILLING_SETTINGS: '/account/billing-settings',

  ACCOUNT_BILLING_HISTORY: '/account/billing-history',
  
  ACCOUNT_PLAN: '/account/plan',

  NOTIFICATIONS: '/notifications',

  SIGNED_IN_USER: '/my-user/settings',

  SIGNED_IN_USER_LOGINS: '/my-user/logins',

  CONFIRM: '/confirm',

  COLLECTION_VIEW: '/collection/:collectionId/*',

  DASHBOARD: '/dashboard/:id',

  REPORT: '/report/:id',

  GEO_ANALYSIS: '/geo-analysis/:id',

  VIEWER: '/viewer',

  UNAUTHORIZED: '/unauthorized'
}

export default PATHS
