import { Box, LinearProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

interface ProgressInfoProps {
  value: number
  maxValue: number
  title?: string
  className?: string
  sufix?: string
  loading?: boolean
}

const useStyles = makeStyles((theme) => ({
  progress: {
    backgroundColor: `#66${theme.palette.text.primary.replace('#', '')}`,
    borderRadius: 5,
    height: 12,
    minWidth: 125
  },
  progressOk: {
    backgroundColor: theme.palette.success.main
  },
  progressWarning: {
    backgroundColor: theme.palette.warning.main
  },
  progressDanger: {
    backgroundColor: theme.palette.error.main
  }
}))

const ProgressInfo: React.FC<PropsWithChildren<ProgressInfoProps>> = ({
  value = 0,
  maxValue = 100,
  title,
  sufix,
  className,
  loading
}) => {
  const classes = useStyles()

  const percentValue = Math.round((value / maxValue) * 100)

  return (
    <Box className={className}>
      <Typography color="textPrimary" variant="h5" component="span">
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box flex="3 0 0">
          <LinearProgress
            className={classes.progress}
            classes={{
              barColorPrimary: clsx({
                [classes.progressOk]: percentValue <= 30,
                [classes.progressWarning]: percentValue > 30 && percentValue <= 70,
                [classes.progressDanger]: percentValue > 70
              })
            }}
            value={percentValue > 100 ? 100 : percentValue}
            variant={loading ? 'indeterminate' : 'determinate'}
          />
        </Box>
        <Box paddingLeft="8px">
          <Typography variant="body2" color="textPrimary">{`${value}/${maxValue} ${sufix || ''}`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ProgressInfo
