import { ArrowBack, ArrowForward, Menu as MenuIcon } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Fade,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/system'
import clsx from 'clsx'
import React, { PropsWithChildren, useEffect } from 'react'
import AnalyzeIcon, { AnalyzeIconTypes } from '../../components/AnalyzeIcon'
import { CollectionAnalyzeSchema } from '../../schemas/CollectionSchema'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 5,
    [theme.breakpoints.down('md')]: {
      right: 80,
      left: 'auto'
    },
    left: 48,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper
  },
  hide: {
    display: 'none'
  },
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '15%',
    height: '15%'
  }
}))

export interface MenuOption {
  label: string
  onClick: () => void
  icon: React.ReactNode
  disabled?: boolean
}

interface CollectionOverlayActionsProps extends ButtonGroupProps {
  currentSelectedAnalyzeIndex: number
  analysis: CollectionAnalyzeSchema[]
  onAnalyzeClick: (analyze: CollectionAnalyzeSchema) => void
  onBack?: () => void
  onForward?: () => void
  menuOptions?: MenuOption[]
}

const CollectionOverlayActions: React.FC<PropsWithChildren<CollectionOverlayActionsProps>> = ({
  menuOptions,
  onBack,
  onForward,
  analysis,
  currentSelectedAnalyzeIndex,
  onAnalyzeClick,
  hidden,
  ...rest
}) => {
  const classes = useStyles()
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null)
  const [anchorElAnalyze, setAnchorElAnalyze] = React.useState<null | HTMLElement>(null)
  const [fadeIn, setFadeIn] = React.useState(false)
  const mdDown = useMediaQuery<Theme>((th) => th.breakpoints.down('md'))

  const analysisMenu = document.getElementById('analysis-menu')

  useEffect(() => {
    let isMounted = true

    const handleMouseMouve = (e: MouseEvent) => {
      if (hidden || mdDown) return
      if (isMounted) {
        // any of menus are open
        if (Boolean(anchorElAnalyze) || Boolean(anchorElMenu)) {
          setFadeIn(true)
          return
        }
        const offsetLeftLessThan15 = e.clientX < window.visualViewport!.width * 0.25
        const offsetTopLessThan15 = e.clientY > window.visualViewport!.height * 0.85
        const inLeftBottomCorner = offsetLeftLessThan15 && offsetTopLessThan15
        if (inLeftBottomCorner) {
          setFadeIn(true)
          return
        }
        setFadeIn(false)
      }
    }

    window?.addEventListener('mousemove', handleMouseMouve)

    if (isMounted) {
      if (mdDown && !!analysisMenu) {
        analysisMenu.onclick = (e) => {
          var expanded = document.getElementById('analysis-menu')!.getAttribute('data-open')! === 'true'
          setFadeIn(!expanded)
        }
      }
    }

    return () => {
      window?.removeEventListener('mousemove', handleMouseMouve)
      isMounted = false
    }
  }, [analysisMenu, anchorElAnalyze, anchorElMenu, hidden, mdDown])

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }

  const handleAnalyzeMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAnalyze(event.currentTarget)
  }

  const handleCloseAnalyzeMenu = () => {
    setAnchorElAnalyze(null)
  }

  const handleMenuItemClick = (onOptionClick: () => void) => {
    onOptionClick()
    handleCloseMenu()
  }

  const handleAnalyzeMenuItemClick = (analyze: CollectionAnalyzeSchema) => {
    onAnalyzeClick(analyze)
    handleCloseAnalyzeMenu()
  }

  return (
    <>
      <Fade in={fadeIn}>
        <ButtonGroup className={clsx(classes.root, { [classes.hide]: hidden })} {...rest}>
          <Button disabled={currentSelectedAnalyzeIndex === 0} onClick={onBack}>
            <ArrowBack />
          </Button>
          <Button onClick={handleAnalyzeMenuClick}>{currentSelectedAnalyzeIndex + 1}</Button>
          <Button disabled={currentSelectedAnalyzeIndex === analysis.length - 1} onClick={onForward}>
            <ArrowForward />
          </Button>
          <Button sx={{ display: mdDown ? 'none' : 'block' }} onClick={handleMenuClick}>
            <MenuIcon />
          </Button>
        </ButtonGroup>
      </Fade>
      <Menu anchorEl={anchorElMenu} keepMounted disablePortal open={Boolean(anchorElMenu)} onClose={handleCloseMenu}>
        {menuOptions?.map((option) => (
          <MenuItem onClick={() => handleMenuItemClick(option.onClick)} disabled={option.disabled}>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorElAnalyze}
        keepMounted
        disablePortal
        open={Boolean(anchorElAnalyze)}
        onClose={handleCloseAnalyzeMenu}
      >
        {analysis?.map((analyze, index) => (
          <MenuItem
            disabled={currentSelectedAnalyzeIndex === index}
            onClick={() => handleAnalyzeMenuItemClick(analyze)}
          >
            {index + 1} - <AnalyzeIcon type={analyze.type as AnalyzeIconTypes} color="secondary" /> {analyze.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CollectionOverlayActions
