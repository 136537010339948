import React, { memo } from 'react'

import { Switch, SwitchProps, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { FieldProps, FieldPropValues } from './types'
import { customFieldComparable } from './utils'

interface SwitchFieldProps<TValues> extends SwitchProps, Partial<FieldProps<TValues>> {
  name: string
  label: string
  helperText: string
}

const useStyles = makeStyles((theme) => ({
  outlinedContainer: {
    fontSize: '14px',
    outline: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    padding: '1rem 0.7rem',
    position: 'relative'
  },
  outlinedContainerLabel: {
    position: 'absolute',
    left: 0,
    top: '-10px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '0 0.3rem',
    margin: '0 0.5rem',
    pointerEvents: 'none'
  },
  outlinedContainerContent: {
    display: 'flex'
  },
  outlinedContainerContentText: {
    flex: '2 0 0'
  },
  outlinedContainerContentAction: {
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const SwitchField = <Value extends FieldPropValues>({
  values,
  handleChange,
  handleBlur,
  required,
  name,
  label,
  className,
  helperText,
  ...rest
}: SwitchFieldProps<Value>): React.ReactElement => {
  const classes = useStyles()
  return (
    <div className={clsx(className, classes.outlinedContainer)}>
      <div className={classes.outlinedContainerLabel}>{label}</div>
      <div className={classes.outlinedContainerContent}>
        <div className={classes.outlinedContainerContentText}>
          <Typography>{helperText}</Typography>
        </div>
        <div className={classes.outlinedContainerContentAction}>
          <Switch
            name={name}
            checked={values?.[name] || false}
            value={values?.[name] || false}
            required={required}
            onBlur={handleBlur}
            type="checkbox"
            color="primary"
            onChange={handleChange}
            {...rest}
          />
        </div>
      </div>
    </div>
  )
}
export default memo(SwitchField, customFieldComparable)
