import {
  Cached,
  Edit,
  LibraryAdd,
  Palette,
  Power,
  Save,
  SettingsEthernet,
  Share,
  Title,
  Visibility
} from '@mui/icons-material'
import { Box, SvgIcon, Theme, useMediaQuery } from '@mui/material'
import axios from 'axios'
import { FunnelD3ItemExtension } from 'dashboard-extensions/dist/funnel-d3-item'
import { OnlineMapItemExtension } from 'dashboard-extensions/dist/online-map-item'
import { ParameterItemExtension } from 'dashboard-extensions/dist/parameter-item'
import { PolarChartItemExtension } from 'dashboard-extensions/dist/polar-chart-item'
import { SimpleTableItemExtension } from 'dashboard-extensions/dist/simple-table-item'
import { WebPageItemExtension } from 'dashboard-extensions/dist/webpage-item'
import { DashboardControlArgs, DashboardInitializedArgs, DashboardParameterDialogExtension } from 'devexpress-dashboard'
import { DashboardControl } from 'devexpress-dashboard-react'
import {
  CreateDashboardExtension,
  SaveDashboardExtension,
  ToolboxExtension,
  UndoRedoExtension
} from 'devexpress-dashboard/designer'
import { TextBoxItemEditorExtension } from 'devexpress-dashboard/designer/text-box-item-editor-extension'
import { locale } from 'devextreme/localization'
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import AddToCollectionDialog from '../../components/AddToCollectionDialog'
import AnalyzeSwipeableDrawer, { AnalyzeSwipeableDrawerOptionProps } from '../../components/AnalyzeSwipeableDrawer'
import ShareDialog from '../../components/ShareDialog'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import PATHS from '../../constants/paths'
import useAuth from '../../hooks/useAuth'
import { useCheckAnotherAccount } from '../../hooks/useCheckAnotherAccount'
import { AnalyzeSchema } from '../../schemas/AnalyzeSchema'
import { PlugClaimType } from '../../schemas/UserSchema'
import { analyzeService } from '../../services'
import DashboardControlWrapper from './DashboardControlWrapper'
import ChartLegendFormattingExtension from './extensions/ChartLegentFormattingExtension'
import ChartPointLabelFormattingExtension from './extensions/ChartPointLabelFormattingExtension'
import ChartXAxisFontFormattingExtension from './extensions/ChartXAxisFontFormattingExtension'
import { ComboBoxAutoFitWidthExtension } from './extensions/ComboBoxAutoFitWidthExtension'
import { ComboBoxPlaceholderExtension } from './extensions/ComboBoxPlaceholderExtension'
import { DashboardStateCustomPropertyExtension } from './extensions/DashboardStateCustomPropertyExtension'
import { PivotGridFillEmptyCellsWithZeroExtension } from './extensions/PivotGridFillEmptyCellsWithZeroExtension'
import SaveDialog from './extensions/SaveDialog'
import './index.css'

enum DashboardItemKey {
  DataSources = 'data-source-browser',
  Title = 'dashboard-title-editor',
  Parameters = 'dashboard-parameter-editor',
  ColorScheme = 'dashboard-color-scheme-editor'
}

interface DashboardPageProps {
  fromCollection?: boolean
  hideMenu?: boolean
  onBackButtonClick?: () => void
}

// const useStyles = makeStyles(() => ({
//   dashboardOverlayOverride: {
//     '& .dx-overlay-wrapper': {
//       zIndex: '1502!important'
//     }
//   }
// }))

export type DashboardPageRefProps = {
  reloadData?: () => void
}

const DashboardPage = forwardRef<DashboardPageRefProps, PropsWithChildren<DashboardPageProps>>(
  ({ fromCollection, hideMenu, onBackButtonClick }, ref) => {
    const { id, collectionId } = useParams<{ id: any; collectionId?: any }>()
    const [dashboardId, setDashboardId] = useState<string | null | undefined>(null)
    const [analyzeInfo, setAnalyzeInfo] = useState<AnalyzeSchema | null>(null)
    const isNew = !isNaN(Number(dashboardId))
    const auth = useAuth()
    const [saveDialogOpen, setSaveDialogOpen] = useState(false)
    const [shareDialogOpen, setShareDialogOpen] = useState(false)
    const [addCollectionDialogOpen, setAddCollectionDialogOpen] = useState(false)
    const [dashboardHeight, setDashboardHeight] = useState(window.innerHeight)

    const userInfo = auth.getUserInfo()
    const isOwner = userInfo?.userId === analyzeInfo?.userOwner.id
    const canEdit = isOwner || analyzeInfo?.claimType === PlugClaimType.Analysis_RW
    const userCanManageAnalysis = userInfo?.profile.managingAnalyseAndCollection
    const userLocale = auth.getUserInfo()?.locale
    const { t } = useTranslation()
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const navigate = useNavigate()
    const { check } = useCheckAnotherAccount()
    const location = useLocation()
    const dashboardControlRef = useRef<DashboardControl>(null)
    const [inEditState, setInEditState] = useState(!isNaN(Number(id)))

    const mounted = useRef(false)

    useEffect(() => {
      if (!!dashboardControlRef.current && !mounted.current) {
        setInEditState(!isNaN(Number(id)))
        mounted.current = true
      }
    }, [dashboardControlRef.current, isNew])

    useEffect(() => {
      if (inEditState) {
        dashboardControlRef.current?.instance?.switchToDesigner()
      } else {
        dashboardControlRef.current?.instance?.switchToViewer()
      }
    }, [inEditState])

    //const classes = useStyles()

    const setInEdit = setInEditState

    useEffect(() => {
      if (fromCollection) {
        if (hideMenu) {
          setInEdit(false)
        } else {
          setInEdit(true)
        }
      }
    }, [hideMenu, fromCollection, setInEdit])

    const stateHashCode = location?.state?.hashCode

    useLayoutEffect(() => {
      console.log('hashCode', stateHashCode)

      setDashboardId(null)

      const timerId = setTimeout(() => {
        setDashboardId(id)
      }, 350)

      return () => {
        clearTimeout(timerId)
      }
    }, [id, stateHashCode])

    useImperativeHandle(
      ref,
      () => {
        return {
          reloadData: () => dashboardControlRef.current?.instance?.reloadData()
        }
      },
      []
    )

    const handleBeforeRender = useCallback(
      (e: DashboardControlArgs) => {
        const dashboardControl = e.component
        // const toolboxExt = dashboardControl.findExtension('toolbox') as ToolboxExtension
        // toolboxExt.menuItems().forEach((menIt) => {
        //   toolboxExt.removeMenuItem(menIt.id)
        // })
        dashboardControl.unregisterExtension('open-dashboard')
        // dashboardControl.unregisterExtension('data-source-wizard')
        dashboardControl.registerExtension(new DashboardStateCustomPropertyExtension(e.component))
        dashboardControl.registerExtension(new FunnelD3ItemExtension(e.component))
        dashboardControl.registerExtension(new OnlineMapItemExtension(e.component))
        dashboardControl.registerExtension(new ParameterItemExtension(e.component))
        dashboardControl.registerExtension(new PolarChartItemExtension(e.component))
        dashboardControl.registerExtension(new SimpleTableItemExtension(e.component))
        dashboardControl.registerExtension(new WebPageItemExtension(e.component))
        dashboardControl.registerExtension(new TextBoxItemEditorExtension(e.component))
        dashboardControl.registerExtension(new PivotGridFillEmptyCellsWithZeroExtension(e.component))
        dashboardControl.registerExtension(new ComboBoxPlaceholderExtension(e.component))

        // custom extensions
        dashboardControl.registerExtension(new (ChartLegendFormattingExtension as any)(e.component))
        dashboardControl.registerExtension(new (ChartPointLabelFormattingExtension as any)(e.component))
        dashboardControl.registerExtension(new (ChartXAxisFontFormattingExtension as any)(e.component))
        dashboardControl.registerExtension(new (ComboBoxAutoFitWidthExtension as any)(e.component))

        const parameterDialogExt = dashboardControl.findExtension(
          'dashboardParameterDialog'
        ) as DashboardParameterDialogExtension
        parameterDialogExt.onShowing = (args) => {
          args.component?.option('width', mdDown ? 320 : 800)
        }
      },
      [mdDown]
    )

    const loadAnalyzeInfo = useCallback(async () => {
      if (!dashboardId) {
        return
      }
      try {
        const { data } = await analyzeService.getAnalyze(dashboardId)
        await check(data)
        setAnalyzeInfo(data)
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.status === 403 || err.response?.status === 403) {
            if (fromCollection) {
              const generatedPath = generatePath('/collection/:collectionId/unauthorized', {
                collectionId: collectionId!
              })
              navigate(generatedPath, {
                state: err.response?.data
              })
              return
            }
            navigate(PATHS.UNAUTHORIZED, {
              state: err.response?.data
            })
          } else if (err.status === 404 || err.response?.status === 404) {
            navigate('not-found')
          }
        }
      }
    }, [check, collectionId, dashboardId, fromCollection, navigate])

    useEffect(() => {
      if (!isNew) {
        loadAnalyzeInfo()
      }
    }, [isNew, loadAnalyzeInfo])

    const getUndoRedoExt = () => {
      return dashboardControlRef?.current?.instance?.findExtension('undo-redo') as UndoRedoExtension | undefined
    }

    const handleSaveNew = async (dashboardName: string) => {
      const dashboardControl = dashboardControlRef.current?.instance
      const createDashboardExt = dashboardControl?.findExtension('createDashboard') as CreateDashboardExtension
      const dashboardJSON = dashboardControl?.dashboard()?.getJSON()
      await createDashboardExt.performCreateDashboard(dashboardName, dashboardJSON || '')
      const newDashboardId = dashboardControl?.getDashboardId()
      window.history.replaceState(null, '', generatePath(PATHS.DASHBOARD, { id: newDashboardId }))
      setDashboardId(newDashboardId)
      setSaveDialogOpen(false)
      getUndoRedoExt()?.reset()
    }

    const handleSave = () => {
      const dashboardControl = dashboardControlRef.current?.instance
      if (isNew) {
        setSaveDialogOpen(true)
        return
      }
      const saveDashboardExt = dashboardControl?.findExtension('saveDashboard') as SaveDashboardExtension
      const dashboardJson = dashboardControl?.dashboard().getJSON()
      const dashboardState = dashboardControl?.getDashboardState()

      const dashboardId = dashboardControl?.getDashboardId()

      if (dashboardState) dashboardJson['DashboardState'] = JSON.parse(dashboardState)

      saveDashboardExt.performSaveDashboard(dashboardId!, dashboardJson)
      getUndoRedoExt()?.reset()
    }

    const handleSelectItem = (key: DashboardItemKey) => {
      const dashboardControl = dashboardControlRef.current?.instance

      const toolbox = dashboardControl?.findExtension('toolbox') as ToolboxExtension
      const dataSourcesItem = toolbox.menuItems().find((e) => e.id === key)
      dataSourcesItem && toolbox.selectMenuItem(dataSourcesItem)
    }
    const closeToolboxMenu = () => {
      const dashboardControl = dashboardControlRef.current?.instance

      const toolbox = dashboardControl?.findExtension('toolbox') as ToolboxExtension
      toolbox.closeMenu()
    }

    let swipableDrawerOptions: AnalyzeSwipeableDrawerOptionProps[] = [
      {
        icon: <SvgIcon component={Save} />,
        disabled: isNew ? false : !canEdit,
        label: t('dashboard.menuSave'),
        action: () => {
          closeToolboxMenu()
          handleSave()
        }
      },
      {
        icon: (
          <img
            style={{
              filter: mdDown
                ? 'invert(57%) sepia(19%) saturate(1549%) hue-rotate(125deg) brightness(93%) contrast(82%)'
                : 'invert(1)'
            }}
            src={ENDPOINTS.PUBLIC_IMAGES + '/save-as-icon.svg'}
            alt="save as icon"
          />
        ),
        disabled: isNew ? true : canEdit ? false : !userCanManageAnalysis,
        label: t('dashboard.menuSaveACopy'),
        action: () => {
          closeToolboxMenu()
          setSaveDialogOpen(true)
        }
      },
      {
        icon: <SvgIcon component={Share} />,
        disabled: isNew ? true : !isOwner,
        label: t('dashboard.menuShare'),
        action: () => {
          closeToolboxMenu()
          setShareDialogOpen(true)
        }
      },
      {
        icon: <SvgIcon component={LibraryAdd} />,
        disabled: isNew ? true : !canEdit,
        label: t('dashboard.menuAddToCollection'),
        action: () => {
          closeToolboxMenu()
          setAddCollectionDialogOpen(true)
        }
      }
    ]
    const dashboardOptions = [
      {
        icon: <SvgIcon component={inEditState ? Visibility : Edit} />,
        label: inEditState ? t('dashboard.menuViewMode') : t('dashboard.menuEditMode'),
        disabled: !isNew && !canEdit,
        action: () => {
          closeToolboxMenu()
          setInEdit((prev) => !prev)
        }
      },
      {
        icon: <SvgIcon component={Cached} />,
        label: t('dashboard.renewCache'),
        action: () => {
          dashboardControlRef.current?.instance?.reloadData()
        }
      },
      {
        icon: <SvgIcon component={Power} />,
        disabled: !inEditState,
        label: t('dashboard.menuPlugs'),
        action: () => {
          handleSelectItem(DashboardItemKey.DataSources)
        }
      },
      {
        icon: <SvgIcon component={Title} />,
        disabled: !inEditState,
        label: t('dashboard.title'),
        action: () => {
          handleSelectItem(DashboardItemKey.Title)
        }
      },
      {
        icon: <SvgIcon component={SettingsEthernet} />,
        disabled: !inEditState,
        label: t('dashboard.menuParameters'),
        action: () => {
          handleSelectItem(DashboardItemKey.Parameters)
        }
      },
      {
        icon: <SvgIcon component={Palette} />,
        disabled: !inEditState,
        label: t('dashboard.menuColors'),
        action: () => {
          handleSelectItem(DashboardItemKey.ColorScheme)
        }
      }
    ]

    swipableDrawerOptions = [...swipableDrawerOptions, ...(() => (mdDown ? [] : [...dashboardOptions]))()]

    const handleBackToCollection = () => {
      setInEdit(false)
      onBackButtonClick?.()
    }

    const getWidth = useCallback(() => {
      return hideMenu || mdDown ? '100%' : 'calc(100% - 57px)'
    }, [hideMenu, mdDown])

    const handleHomeButtonClick = () => {
      window.document.location.href = PATHS.HOME
    }

    const handleOnInitialized = useCallback(
      (args: DashboardInitializedArgs) => {
        locale(userLocale || 'en-US')
        args.dashboard.currencyCultureName(userLocale || 'en-US')
        const dashboardCustomProperties = args.dashboard.customProperties
        const dashboardState = dashboardCustomProperties.getValue('DashboardState') as string
        if (!!dashboardState) {
          args.component.setDashboardState(dashboardState)
        }
      },
      [userLocale]
    )

    useEffect(() => {
      const resizeDashboardOnWindowResize = () => {
        setDashboardHeight(window.innerHeight)
      }

      window.addEventListener('resize', resizeDashboardOnWindowResize)

      return () => window.removeEventListener('resize', resizeDashboardOnWindowResize)
    }, [])

    return (
      <Box className="dashboard-component-wrapper" display="flex" width="100%" height={dashboardHeight}>
        <AnalyzeSwipeableDrawer
          hidden={hideMenu}
          showBackButton={fromCollection}
          onBackButtonClick={handleBackToCollection}
          analyzeName={analyzeInfo?.name || 'New Dashboard'}
          analyzeTypeName="Dashboard"
          options={swipableDrawerOptions}
          onHomeButtonClick={handleHomeButtonClick}
        />
        <Box width={getWidth()} height={dashboardHeight}>
          {!!id && (
            <DashboardControlWrapper
              onDashboardInitialized={handleOnInitialized}
              ref={dashboardControlRef}
              onBeforeRender={handleBeforeRender}
              dashboardId={dashboardId}
              allowExport={userInfo?.profile.canExportData}
              defaultWorkingMode="Viewer"
            />
          )}
        </Box>
        <SaveDialog open={saveDialogOpen} onClickSave={handleSaveNew} onClose={() => setSaveDialogOpen(false)} />
        <ShareDialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          analyzeOrCollection={analyzeInfo || undefined}
        />
        <AddToCollectionDialog
          title={t('pivotGrid.menuSettings.modalCollection.Title')}
          onClose={() => setAddCollectionDialogOpen(false)}
          description={t('pivotGrid.menuSettings.modalCollection.Subtitle')}
          analyze={analyzeInfo || undefined}
          open={addCollectionDialogOpen}
        />
      </Box>
    )
  }
)

export default DashboardPage
