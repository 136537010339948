import ProfilesIcon from '@mui/icons-material/AssignmentInd'
import TeamsIcon from '@mui/icons-material/Group'
import HomeIcon from '@mui/icons-material/Home'
import UsersIcon from '@mui/icons-material/Person'
import PluggsIcon from '@mui/icons-material/Power'
import SendingIcon from '@mui/icons-material/Send'
import ParametersIcon from '@mui/icons-material/SettingsEthernet'
import ConnectionsIcon from '@mui/icons-material/SettingsInputAntenna'
import { t } from 'i18next'
import { Route } from 'react-router-dom'
import PATHS from '../constants/paths'
import NotFoundPage from '../pages/NotFound/NotFoundPage'
import PricingPage from '../pages/PricingPage/PricingPage'
import AccountPage from '../pages/account'
import General from '../pages/account/General'
import AccountPlanPage from '../pages/account/PlanInfo'
import CollectionViewPage, { CollectionAnalysis } from '../pages/collectionView'
import ConfirmPage from '../pages/confirm'
import ConfirmInvitePage from '../pages/confirmInvite'
import ConnectionsPage from '../pages/connections'
import DashboardPage from '../pages/dashboard'
import { FeedbackPage } from '../pages/feedback/FeedbackPage'
import ForgotPasswordPage from '../pages/forgotPassword'
import GeoAnalysisPage from '../pages/geoAnalysis'
import HomePage from '../pages/home'
import NotificationsPage from '../pages/notifications'
import ParametersPage from '../pages/parameters'
import PivotGridPage from '../pages/pivotGrid'
import PlugsPage from '../pages/plugs'
import ProfilesPage from '../pages/profiles'
import ReportPage from '../pages/report/ReportPage'
import ResetPasswordPage from '../pages/resetPassword'
import SelectAccountPage from '../pages/selectAccount'
import CreateNewAccountPage from '../pages/selectAccount/CreateNewAccountPage'
import SendingPage from '../pages/sending'
import SignInPage from '../pages/signIn'
import SignUpPage from '../pages/signUp'
import { SignUpPage as SignUpPage2 } from '../pages/signUp/index2'
import { default as SignedInPage } from '../pages/signedInUser'
import SecurityPage from '../pages/signedInUser/Security'
import UserSettings from '../pages/signedInUser/UserSettings'
import TeamsPage from '../pages/teams'
import UnauthorizedPage from '../pages/unauthorized'
import UsersPage from '../pages/users'
import ViewerPage from '../pages/viewer'
import ViewerPageV2 from '../pages/viewerV2'
const routes = [
  {
    component: HomePage,
    path: PATHS.HOME,
    isPrivate: true,
    layout: 'AppLayout',
    icon: HomeIcon,
    isNavigated: true,
    key: 'Home',
    exact: true,
    labelKey: 'navMenu.home'
  },
  {
    component: PlugsPage,
    path: PATHS.PLUGS,
    isPrivate: true,
    layout: 'AppLayout',
    icon: PluggsIcon,
    isNavigated: true,
    key: 'Plugs',
    labelKey: 'navMenu.plugs'
  },
  {
    component: ConnectionsPage,
    path: PATHS.CONNECTIONS,
    isPrivate: true,
    layout: 'AppLayout',
    icon: ConnectionsIcon,
    isNavigated: true,
    key: 'Connections',
    labelKey: 'navMenu.connections'
  },
  {
    component: UsersPage,
    path: PATHS.USERS,
    isPrivate: true,
    layout: 'AppLayout',
    icon: UsersIcon,
    isNavigated: true,
    key: 'Users',
    labelKey: 'navMenu.users'
  },
  {
    component: TeamsPage,
    path: PATHS.TEAMS,
    isPrivate: true,
    layout: 'AppLayout',
    icon: TeamsIcon,
    isNavigated: true,
    key: 'Teams',
    labelKey: 'navMenu.teams'
  },
  {
    component: ProfilesPage,
    path: PATHS.PROFILES,
    isPrivate: true,
    layout: 'AppLayout',
    icon: ProfilesIcon,
    isNavigated: true,
    key: 'Profiles',
    labelKey: 'navMenu.profiles'
  },
  {
    component: ParametersPage,
    path: PATHS.PARAMETERS,
    isPrivate: true,
    layout: 'AppLayout',
    icon: ParametersIcon,
    isNavigated: true,
    key: 'Parameters',
    labelKey: 'navMenu.parameters'
  },
  {
    component: SendingPage,
    path: PATHS.SENDING,
    isPrivate: true,
    layout: 'AppLayout',
    icon: SendingIcon,
    isNavigated: true,
    key: 'Sending',
    labelKey: 'navMenu.sending'
  },
  {
    component: SignedInPage,
    path: PATHS.MY_USER,
    childrens: (
      <>
        <Route path={PATHS.SIGNED_IN_USER} element={<UserSettings />} />

        <Route path={PATHS.ACCOUNT_SECURITY} element={<SecurityPage />} />
      </>
    ),
    isPrivate: true,
    layout: 'AppLayout',
    isNavigated: false
  },
  {
    component: SignedInPage,
    path: PATHS.ACCOUNT_SECURITY,
    isPrivate: true,
    layout: 'AppLayout',
    isNavigated: false
  },
  {
    component: AccountPage,
    path: PATHS.ACCOUNT,
    isPrivate: true,
    childrens: (
      <>
        <Route path={PATHS.ACCOUNT_GENERAL} element={<General />} />

        <Route path={PATHS.ACCOUNT_PLAN} element={<AccountPlanPage />} />

        <Route path={PATHS.ACCOUNT_BILLING_SETTINGS} element={<div>{t('pageUserlogged.Settings.commingSoon')}</div>} />

        <Route path={PATHS.ACCOUNT_BILLING_HISTORY} element={<div>{t('pageUserlogged.Settings.commingSoon')}</div>} />
      </>
    ),
    layout: 'AppLayout',
    isNavigated: false
  },
  {
    component: AccountPage,
    path: PATHS.ACCOUNT_BILLING_SETTINGS,
    isPrivate: true,
    layout: 'AppLayout',
    isNavigated: false
  },
  {
    component: AccountPage,
    path: PATHS.ACCOUNT_BILLING_HISTORY,
    isPrivate: true,
    layout: 'AppLayout',
    isNavigated: false
  },
  {
    component: NotificationsPage,
    path: PATHS.NOTIFICATIONS,
    isPrivate: true,
    layout: 'AppLayout',
    isNavigated: false
  },
  {
    component: SignInPage,
    path: PATHS.SIGNIN,
    isPrivate: false,
    layout: false
  },
  {
    component: ForgotPasswordPage,
    path: PATHS.FORGOT_PASSWORD,
    isPrivate: false,
    layout: false
  },
  {
    component: ResetPasswordPage,
    path: PATHS.RESET_PASSWORD,
    isPrivate: false,
    layout: false
  },
  {
    component: SelectAccountPage,
    path: PATHS.SELECT_ACCOUNT,
    isPrivate: false,
    layout: false
  },
  {
    component: SignUpPage,
    path: PATHS.SIGNUP,
    isPrivate: false,
    layout: false
  },
  {
    component: SignUpPage2,
    path: '/signup2',
    isPrivate: false,
    layout: false
  },
  {
    component: PivotGridPage,
    path: PATHS.PIVOT_GRID,
    isPrivate: true,
    layout: false,
    exact: true
  },
  {
    component: ConfirmPage,
    path: PATHS.CONFIRM,
    isPrivate: false,
    layout: false
  },
  {
    component: CollectionViewPage,
    path: PATHS.COLLECTION_VIEW,
    isPrivate: true,
    layout: false,
    childrens: <Route path="*" element={<CollectionAnalysis />} />
  },
  {
    component: DashboardPage,
    path: PATHS.DASHBOARD,
    isPrivate: true,
    layout: false
  },
  {
    component: ReportPage,
    path: PATHS.REPORT,
    isPrivate: true,
    layout: false
  },
  {
    component: GeoAnalysisPage,
    path: PATHS.GEO_ANALYSIS,
    isPrivate: true,
    layout: false
  },
  {
    component: ViewerPage,
    path: PATHS.VIEWER,
    isPrivate: false,
    layout: false
  },
  {
    component: UnauthorizedPage,
    path: PATHS.UNAUTHORIZED,
    isPrivate: true,
    layout: false
  },
  {
    component: ViewerPageV2,
    path: '/viewerv2',
    isPrivate: false,
    layout: false
  },
  {
    component: NotFoundPage,
    path: '*',
    isPrivate: false,
    layout: false
  },
  {
    component: FeedbackPage,
    path: '/feedback',
    isPrivate: false,
    layout: false
  },
  {
    component: PricingPage,
    path: '/pricing',
    isPrivate: false,
    layout: false
  },
  {
    component: CreateNewAccountPage,
    path: '/new-account',
    isPrivate: true,
    layout: false
  },
  {
    component: ConfirmInvitePage,
    path: '/confirm-invite',
    isPrivate: false,
    layout: false
  }
]

export default routes
