import i18n from '../../../locales/i18next'
import { ComponentExtension, PivotGridComponent, PivotGridField } from '../types'

class RemoveFieldFromAreaExtension implements ComponentExtension {
  name = 'RemoveFieldExtension'
  icon = 'checklist'
  text = i18n.t('pivotGrid.extensions.removeFieldFromArea.text')
  onItemClick: () => void

  constructor(component: PivotGridComponent, field: PivotGridField) {
    this.onItemClick = () => removeField(field, component)
  }
}

const removeField = (field: PivotGridField, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()
  const fields = dataSource.fields()
  delete field.area
  delete field.areaIndex
  fields.splice(field.index!, 1, field)
  dataSource.fields(fields)
  dataSource.load()
}

export default RemoveFieldFromAreaExtension
