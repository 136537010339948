import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import FilterBuilder from '../../components/FilterBuilder'
import { PlugFieldSchema } from '../../schemas/PlugSchema'
import { plugService } from '../../services'

interface GeoFilterDialogProps {
  open: boolean
  onClose: () => void
  plugId: number
  value?: any
  onConfirmChanges?: (value: any) => void
}

const GeoFilterDialog: React.FC<PropsWithChildren<GeoFilterDialogProps>> = ({
  open,
  onClose,
  plugId,
  value,
  onConfirmChanges
}) => {
  const [tempValue, setTempValue] = useState(value)
  const prevValue = useRef(value)
  const [fieldOptions, setFieldOptions] = useState<PlugFieldSchema[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    prevValue.current = value
    setTempValue(value)
  }, [value])

  const loadPlugFieldOptions = useCallback(async () => {
    try {
      const { data } = await plugService.getFields(plugId, true)
      setFieldOptions(data)
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }, [plugId, enqueueSnackbar, t])

  useEffect(() => {
    if (open && plugId) loadPlugFieldOptions()
  }, [loadPlugFieldOptions, open, plugId])

  const handleApplyFilter = () => {
    prevValue.current = tempValue
    onConfirmChanges?.(tempValue)
    handleClose()
  }

  const handleClose = () => {
    setTempValue(prevValue.current)
    onClose()
  }

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      title={t('geoAnalysis.menuFilterTitle')}
      description={t('geoAnalysis.menuFilterSubtitle')}
      onClose={handleClose}
    >
      <DialogContent dividers>
        <Box width="100%" minHeight="490px" minWidth={580} height="100%">
          <FilterBuilder
            style={{
              padding: '22px',
              zIndex: 99999
            }}
            fields={fieldOptions}
            value={tempValue}
            onValueChanged={(val) => setTempValue(val)}
            plugId={plugId}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button label={t('geoAnalysis.menuFilterButtonClose')} isDelete onClick={handleClose} />
        <Button label={t('geoAnalysis.menuFilterButtonApply')} onClick={handleApplyFilter} />
      </DialogActions>
    </Dialog>
  )
}

export default GeoFilterDialog
