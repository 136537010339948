import { dxPivotGridSummaryCell } from 'devextreme/ui/pivot_grid/data_source'
import i18n from '../../../locales/i18next'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

class FunctionsExtension implements ComponentExtension {
  name = 'FunctionsExtension'
  icon = 'formula'
  text = i18n.t('pivotGrid.extensions.functions.text')
  items: ComponentExtensionOption[]
  onItemClick?(): void

  constructor(component: PivotGridComponent, field: PivotGridField) {
    const categoryOptions = [
      {
        icon: 'columnfield',
        text: i18n.t('pivotGrid.extensions.functions.columns'),
        items: [
          {
            text: i18n.t('pivotGrid.extensions.functions.absVariation'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.ABSOLUTE_VARIATION, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.ranking'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.RANKING_OF_COLUMN, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.%variation'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_VARIATION, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.%columnTotal'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_OF_COLUMN_TOTAL, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.%columnGrandTotal'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_OF_COLUMN_GRAND_TOTAL, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.%grandTotal'), // work in both
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_OF_GRAND_TOTAL, component)
          }
        ]
      },
      {
        icon: 'rowfield',
        text: i18n.t('pivotGrid.extensions.functions.rows'),
        items: [
          {
            text: i18n.t('pivotGrid.extensions.functions.ranking'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.RANKING_OF_ROW, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.%rowTotal'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_OF_ROW_TOTAL, component)
          },

          {
            text: i18n.t('pivotGrid.extensions.functions.%rowGrandTotal'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_OF_ROW_GRAND_TOTAL, component)
          },
          {
            text: i18n.t('pivotGrid.extensions.functions.%grandTotal'),
            onItemClick: () =>
              transformDefaultSummaryDisplayTypes(field, summaryDiplayOptions.PERCENT_OF_GRAND_TOTAL, component)
          }
        ]
      }
    ]

    this.items = categoryOptions
  }
}

type SumaryDisplayOptValues =
  | 'absoluteVariation'
  | 'percentVariation'
  | 'percentOfColumnTotal'
  | 'percentOfRowTotal'
  | 'percentOfColumnGrandTotal'
  | 'percentOfRowGrandTotal'
  | 'percentOfGrandTotal'
  | CustomSummaryType

type SummaryDisplayTypeOpt = {
  [key: string]: SumaryDisplayOptValues
}

export enum CustomSummaryType {
  rankingRow = 'rankingRow',
  rankingColumn = 'rankingColumn',
  absoluteVariation = 'absoluteVariation',
  percentOfVariation = 'percentVariation'
}

let sortingRankingListHelper: Array<number> | null = null

const transformDefaultSummaryDisplayTypes = (
  field: PivotGridField,
  summaryDisplayType: SumaryDisplayOptValues,
  component: PivotGridComponent
) => {
  const dataSource = component.getDataSource()
  const fields = dataSource.fields()
  const newField = {...field}
  const summaryEnum = Object.keys(summaryDiplayOptions).find((key) => summaryDiplayOptions[key] === summaryDisplayType)
  if (isCustomSummary(summaryDisplayType)) {
    newField.calculateSummaryValue = getCustomSummaryFunction(summaryDisplayType, newField) as any
  } else {
    newField.summaryDisplayMode = summaryDisplayType as any
  }
  newField.name = `${field.name} ${summaryDisplayType}`
  newField.caption = `${field.caption} (${abreviations[summaryEnum!]})`
  newField.areaIndex = field.areaIndex! + 1
  newField.index = fields.length + 1
  newField.canBeRemoved = true
  newField.renamed = true
  if (summaryEnum!.startsWith('PERCENT')) {
    newField.format = {
      type: 'percent',
      precision: 2
    }
  }
  fields.push(newField)
  dataSource.fields(fields)
  dataSource.load()
}

const summaryDiplayOptions: SummaryDisplayTypeOpt = {
  PERCENT_OF_COLUMN_TOTAL: 'percentOfColumnTotal',
  PERCENT_OF_ROW_TOTAL: 'percentOfRowTotal',
  PERCENT_OF_COLUMN_GRAND_TOTAL: 'percentOfColumnGrandTotal',
  PERCENT_OF_ROW_GRAND_TOTAL: 'percentOfRowGrandTotal',
  PERCENT_OF_GRAND_TOTAL: 'percentOfGrandTotal',
  RANKING_OF_ROW: CustomSummaryType.rankingRow,
  RANKING_OF_COLUMN: CustomSummaryType.rankingColumn,
  ABSOLUTE_VARIATION: CustomSummaryType.absoluteVariation,
  PERCENT_VARIATION: CustomSummaryType.percentOfVariation
}

const abreviations: { [key in keyof SummaryDisplayTypeOpt]: string } = {
  ABSOLUTE_VARIATION: 'ABSVAR',
  PERCENT_VARIATION: '%VAR',
  PERCENT_OF_COLUMN_TOTAL: '%COL',
  PERCENT_OF_ROW_TOTAL: '%ROW',
  PERCENT_OF_COLUMN_GRAND_TOTAL: '%COLGT',
  PERCENT_OF_ROW_GRAND_TOTAL: '%ROWGT',
  PERCENT_OF_GRAND_TOTAL: '%GT',
  RANKING_OF_ROW: 'Row Ranking',
  RANKING_OF_COLUMN: 'Column Ranking'
}

export function isCustomSummary(summaryType: any): summaryType is CustomSummaryType {
  return Object.values(CustomSummaryType).includes(summaryType)
}

function summaryValueRankingByRow(field: PivotGridField | null) {
  if (!!field) {
    field.format = {
      type: 'fixedPoint',
      precision: 0
    }
    field.summaryDisplayMode = CustomSummaryType.rankingRow as any
  }
  return (e: dxPivotGridSummaryCell) => {
    if (!sortingRankingListHelper) {
      sortingRankingListHelper = []
      let nextRow = e
      while (nextRow) {
        sortingRankingListHelper.push(nextRow.value())
        nextRow = nextRow.next('row')
      }
      sortingRankingListHelper = sortingRankingListHelper.sort((a, b) => b - a)
    }
    const ranking = sortingRankingListHelper.indexOf(e.value()) + 1
    if (!e.next('row')) {
      sortingRankingListHelper = null
    }
    return ranking
  }
}
function summaryValueRankingByColumn(field: PivotGridField | null) {
  if (!!field) {
    field.format = {
      type: 'fixedPoint',
      precision: 0
    }
    field.summaryDisplayMode = CustomSummaryType.rankingColumn as any
  }

  return (e: dxPivotGridSummaryCell) => {
    if (!sortingRankingListHelper) {
      sortingRankingListHelper = []
      let nextColumn = e
      while (nextColumn) {
        sortingRankingListHelper.push(nextColumn.value())
        nextColumn = nextColumn.next('column')
      }
      sortingRankingListHelper = sortingRankingListHelper.sort((a, b) => b - a)
    }
    const ranking = sortingRankingListHelper.indexOf(e.value()) + 1
    if (!e.next('column')) {
      sortingRankingListHelper = null
    }
    return ranking
  }
}

function absoluteVariation(field: PivotGridField | null) {
  if (!!field) {
    field.format = {
      type: 'fixedPoint',
      precision: 0
    }
    field.summaryDisplayMode = CustomSummaryType.absoluteVariation as any
  }
  return (summaryCell: dxPivotGridSummaryCell) => {
    if (!summaryCell.prev('column')) return null
    let prevValue = summaryCell.prev('column').value() || 0
    let curValue = summaryCell.value() || 0
    return curValue - prevValue
  }
}

function percentOfVariation(field: PivotGridField | null) {
  if (!!field) {
    field.format = {
      type: 'percent',
      precision: 0
    }
    field.summaryDisplayMode = CustomSummaryType.percentOfVariation as any
  }
  return (summaryCell: dxPivotGridSummaryCell) => {
    if (!summaryCell.prev('column')) return null
    let prevValue = summaryCell.prev('column').value()
    let curValue = summaryCell.value() || 0
    if (!prevValue && !curValue) return 0
    if (!prevValue) return 1
    return (curValue - prevValue) / prevValue
  }
}

export const CustomSummaryTypeFunctionMaps = {
  [CustomSummaryType.rankingRow]: summaryValueRankingByRow,
  [CustomSummaryType.rankingColumn]: summaryValueRankingByColumn,
  [CustomSummaryType.absoluteVariation]: absoluteVariation,
  [CustomSummaryType.percentOfVariation]: percentOfVariation
}

export function getCustomSummaryFunction(summaryType: CustomSummaryType, field: PivotGridField | null) {
  return CustomSummaryTypeFunctionMaps[summaryType](field)
}

export default FunctionsExtension
