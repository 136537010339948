import i18n from '../../../locales/i18next'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

class WordWrapExtension implements ComponentExtension {
  name: string
  icon: string
  text: string
  items: ComponentExtensionOption[]

  constructor(component: PivotGridComponent, field: PivotGridField) {
    this.name = 'WordWrapExtension'
    this.icon = 'increaseindent'
    this.text = i18n.t('pivotGrid.extensions.wordWrapper.text')
    const subOptions = [
      {
        icon: 'check',
        text: i18n.t('pivotGrid.extensions.wordWrapper.enabled'),
        onItemClick: () => setWordWrapEnabled(field, true, component),
        selected: field.wordWrapEnabled,
        disabled: field.wordWrapEnabled
      },
      {
        icon: 'close',
        text: i18n.t('pivotGrid.extensions.wordWrapper.disabled'),
        onItemClick: () => setWordWrapEnabled(field, false, component),
        selected: !field.wordWrapEnabled,
        disabled: !field.wordWrapEnabled
      }
    ]
    this.items = subOptions
  }
}

const setWordWrapEnabled = (field: PivotGridField, enabled: boolean, component: PivotGridComponent) => {
  const dataSource = component.getDataSource()

  dataSource.field(field.dataField!, { wordWrapEnabled: enabled })
  dataSource.load()
}

export default WordWrapExtension
