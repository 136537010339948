import { Visibility, VisibilityOff } from '@mui/icons-material'
import i18n from '../../../locales/i18next'
import { getStringedHtmlElement } from '../../../utils/renderUtils'
import { RemoteDataSource } from '../dataSource'
import { ComponentExtension, ComponentExtensionOption, PivotGridComponent, PivotGridField } from '../types'

type FieldPanelArea = 'column' | 'row' | 'data' | 'filter'

type PivotFieldOptions = {
  showColumnFields?: boolean | undefined
  showDataFields?: boolean | undefined
  showFilterFields?: boolean | undefined
  showRowFields?: boolean | undefined
}

const hashFieldPanelOption: { [k in FieldPanelArea]: keyof PivotFieldOptions } = {
  column: 'showColumnFields',
  row: 'showRowFields',
  data: 'showDataFields',
  filter: 'showFilterFields'
}

class FieldPanelExtension implements ComponentExtension {
  name = 'FieldPanelExtension'
  icon = 'datafield'
  text = i18n.t('pivotGrid.extensions.fieldPanel.text')
  items: ComponentExtensionOption[]

  constructor(component: PivotGridComponent, field: PivotGridField) {
    const dataFieldOptionVisible = component.option('fieldPanel')?.showDataFields
    const subOptions = [
      {
        text: i18n.t('pivotGrid.extensions.fieldPanel.show'),
        icon: getStringedHtmlElement(<Visibility />),
        onItemClick: () => changePanelAreaVisibility('data', true, component),
        selected: dataFieldOptionVisible,
        disabled: dataFieldOptionVisible
      },
      {
        text: i18n.t('pivotGrid.extensions.fieldPanel.hide'),
        icon: getStringedHtmlElement(<VisibilityOff />),
        onItemClick: () => changePanelAreaVisibility('data', false, component),
        selected: !dataFieldOptionVisible,
        disabled: !dataFieldOptionVisible
      }
    ]

    this.items = subOptions
  }
}

const changePanelAreaVisibility = (area: FieldPanelArea, show: boolean, component: PivotGridComponent) => {
  let currentFieldPanelOptions = component.option('fieldPanel')
  if (!currentFieldPanelOptions) {
    currentFieldPanelOptions = {}
  }
  currentFieldPanelOptions[hashFieldPanelOption[area]] = show
  ;(component.getDataSource() as RemoteDataSource).setConfigOption('fieldPanel', currentFieldPanelOptions)
  component.option('fieldPanel.showDataFields', show)
  component.repaint()
}

export default FieldPanelExtension
