import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import Form, { FormProps } from '../../components/Form'
import OutlinedTextField from '../../components/Form/OutlinedTextField'
import { DatabaseConnectionSchema } from '../../schemas/ConnectionSchema'
import Button from '../Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  gridItemSpan2: {
    gridColumn: '1 / span 2'
  },
  outlinedWhiteInput: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#d62b2b'
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        opacity: 0.8,
        color: theme.palette.text.primary
      }
    }
  },
  whiteButton: {
    color: 'white'
  }
}))

type MySqlConnectionFormProps = Partial<FormProps<Omit<DatabaseConnectionSchema, 'type'>>> & {
  variant?: 'white' | 'default'
  usePlaceholder?: boolean
  onCancelClick: () => void
  hidden?: boolean
}

const defaultValidationSchema = yup.object({
  name: yup.string().required(),
  host: yup.string().required(),
  database: yup.string().required(),
  port: yup.number().min(0).max(65535).optional().nullable(),
  username: yup.string().required(),
  password: yup.string().required()
})

const defaultInitialValues = {
  name: '',
  host: '',
  database: '',
  port: '',
  username: '',
  password: ''
}

const MySqlConnectionForm: React.FC<PropsWithChildren<MySqlConnectionFormProps>> = ({
  className,
  variant = 'default',
  usePlaceholder,
  initialValues,
  onCancelClick,
  validationSchema,
  hidden,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Form
      initialTouched
      className={clsx(classes.root, className)}
      initialValues={initialValues || defaultInitialValues}
      validationSchema={validationSchema || defaultValidationSchema}
      isSubmitting={false}
      onSubmit={console.log}
      hidden={hidden}
      {...rest}
    >
      <OutlinedTextField
        required
        className={clsx(classes.gridItemSpan2, { [classes.outlinedWhiteInput]: variant === 'white' })}
        name="name"
        label={!usePlaceholder ? 'Name' : undefined}
        placeholder={usePlaceholder ? 'Connection Name*' : undefined}
      />
      <OutlinedTextField
        required
        className={clsx({ [classes.outlinedWhiteInput]: variant === 'white' })}
        name="host"
        label={!usePlaceholder ? 'Host' : undefined}
        placeholder={usePlaceholder ? 'Host*' : undefined}
      />
      <OutlinedTextField
        required
        className={clsx({ [classes.outlinedWhiteInput]: variant === 'white' })}
        name="database"
        label={!usePlaceholder ? 'Database' : undefined}
        placeholder={usePlaceholder ? 'Database*' : undefined}
      />
      <OutlinedTextField
        name="port"
        label={!usePlaceholder ? 'Port' : undefined}
        type="number"
        className={clsx({ [classes.outlinedWhiteInput]: variant === 'white' })}
        placeholder={usePlaceholder ? 'Port' : 'Unchanged'}
      />
      <OutlinedTextField
        required
        label={!usePlaceholder ? 'UserName' : undefined}
        className={clsx({ [classes.outlinedWhiteInput]: variant === 'white' })}
        name="username"
        placeholder={usePlaceholder ? 'User*' : undefined}
      />
      <OutlinedTextField
        required
        type="password"
        label={!usePlaceholder ? 'Password' : undefined}
        className={clsx(classes.gridItemSpan2, { [classes.outlinedWhiteInput]: variant === 'white' })}
        name="password"
        autoComplete="off"
        placeholder={usePlaceholder ? 'Password*' : '******'}
      />

      <Box display="flex" justifyContent="space-between" className={classes.gridItemSpan2}>
        <Button
          className={classes.whiteButton}
          variant="text"
          label={t('pagePlugs.modalCreate.createConnection.btnCancel')}
          onClick={onCancelClick}
        />
        <Button
          type="submit"
          className={classes.whiteButton}
          variant="text"
          label={t('pagePlugs.modalCreate.createConnection.btnSave')}
        />
      </Box>
    </Form>
  )
}

export default MySqlConnectionForm
