import { ChangeSet, EditingState } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow, TableInlineCellEditing } from '@devexpress/dx-react-grid-material-ui'
import { Paper, Tab, Tabs } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent, DialogPropsWithTitle } from '../../components/Dialog'
import WarningContainer from '../../components/WarningContainer'
import useAuth from '../../hooks/useAuth'
import { PlugSchemaWithFields } from '../../schemas/PlugSchema'
import { ProfileParameter, ProfileSchema } from '../../schemas/ProfileSchema'
import { parameterService, profileService } from '../../services'
import { instanceOfAxiosError } from '../../services/api'
import AutocompleteTypeProvider from '../AutocompleteTypeProvider/AutocompleteTypeProvider'
import ProfileForm from '../forms/ProfileForm'
import { ProfilePlugFieldDenyList } from './ProfilePlugFieldDenyList'
import { SelectedPlugField } from './ProfilePlugFieldDenyList/ListContainer'

interface ProfileDialogProps extends DialogPropsWithTitle {
  profile?: ProfileSchema
  onClose(refresh: boolean): void
}

type EditProfileParameter = {
  parameterId: string
  parameterName: string
  value: string | string[]
  type: 'List' | 'Single'
}

const ProfileDialog: React.FC<PropsWithChildren<ProfileDialogProps>> = ({ profile, onClose, ...rest }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)
  const [deleteErrorBag, setDeleteErrorBag] = useState<{ message: string; dependents: string[] } | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const auth = useAuth()
  const warningBoxRef = useRef<HTMLDivElement>(null)
  const [loadingApi, setLoadingApi] = useState(false)
  const [loadingApiDelete, setLoadingApiDelete] = useState(false)
  const [plugs, setPlugs] = useState<PlugSchemaWithFields[]>([])
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  const [selectedDenyPlugIds, setSelectedDenyPlugIds] = useState<number[]>([])
  const [selectedDenyPlugFields, setSelectedDenyPlugFields] = useState<SelectedPlugField[]>([])
  const [parameters, setParameters] = useState<EditProfileParameter[]>([])
  const deniesDirty = useRef(false)
  const allowEditPermissions = auth.getUserInfo()?.profile.managingAccess
  const [loadingParameters, setLoadingParameters] = useState(false)

  const handleCreate = async (createdProfile: ProfileSchema) => {
    try {
      setLoadingApi(true)
      const response = await profileService.create(createdProfile)
      createdProfile = response.data
      await handleUpdateParameters(createdProfile.id)
      onClose(true)
      enqueueSnackbar(t('pageProfiles.modal.toast.success.create', { name: createdProfile.name }), {
        variant: 'success'
      })
    } catch (err: any) {
      console.error('error when try persist created profile', err)
      enqueueSnackbar(t('pageProfiles.modal.toast.error.create'), { variant: 'error' })
    }
    setLoadingApi(false)
  }
  const handleDelete = async () => {
    if (!deleteConfirmed) {
      setDeleteConfirmed(true)
      return
    }
    try {
      setLoadingApiDelete(true)
      await profileService._delete(profile!.id)
      onClose(true)
      enqueueSnackbar(t('pageProfiles.modal.toast.success.delete'), { variant: 'success' })
    } catch (err: any) {
      console.error('error when try delete profile', err)
      if (instanceOfAxiosError(err)) {
        const profileDeleteError = err.response?.data as
          | {
              errors: Error[]
              dependents: string[]
            }
          | undefined

        setDeleteErrorBag({
          message: profileDeleteError!.errors[0]?.message || '',
          dependents: profileDeleteError!.dependents
        })
        warningBoxRef.current?.scrollIntoView()
      } else enqueueSnackbar(t('Something went wrong when try delete profile'), { variant: 'error' })
    }
    setLoadingApiDelete(false)
    setDeleteConfirmed(false)
    setDeleteErrorBag(null)
  }
  const handleUpdate = async (updatedProfile: ProfileSchema) => {
    try {
      setLoadingApi(true)
      await profileService.update(updatedProfile)
      if (deniesDirty) {
        await handleUpdateDenies()
      }
      await handleUpdateParameters(updatedProfile.id)
      handleClose(true)
      enqueueSnackbar(t('pageProfiles.modal.toast.success.update', { name: updatedProfile.name }), {
        variant: 'success'
      })
      auth.loadUserData()
    } catch (err: any) {
      console.error('error when try persist update profile', err)
      enqueueSnackbar(t('pageProfiles.modal.toast.error.update'), { variant: 'error' })
    }
    setLoadingApi(false)
  }

  const handleUpdateDenies = async () => {
    try {
      await Promise.all([
        profileService.updatePlugDenieds(
          profile!.id,
          selectedDenyPlugIds.map((x) => ({ plugId: x }))
        ),
        profileService.updatePlugFieldDenieds(
          profile!.id,
          selectedDenyPlugFields.map((x) => ({
            plugFieldName: x.plugFieldName,
            plugId: x.plugId
          }))
        )
      ])
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }

  const handleUpdateParameters = async (profileId: number) => {
    try {
      const splitedParameters: ProfileParameter[] = []
      parameters.forEach((p) => {
        if (p.type === 'List') {
          for (const value of p.value) {
            splitedParameters.push({
              parameterId: p.parameterId,
              value
            })
          }
        } else {
          splitedParameters.push({
            parameterId: p.parameterId,
            value: p.value as string
          })
        }
      })
      await profileService.updateParameters(profileId, splitedParameters)
    } catch (err: any) {
      console.error(err)
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }

  const handleDeferAction = (profile: ProfileSchema) => {
    if (profile?.id) {
      handleUpdate(profile)
      return
    }
    handleCreate(profile)
  }

  const handleSubmit = () => {
    setSubmitting(true)
    setTimeout(() => setSubmitting(false), 1)
  }

  const handleClose = (refresh: boolean) => {
    setDeleteErrorBag(null)
    setDeleteConfirmed(false)
    deniesDirty.current = false
    setSelectedDenyPlugIds([])
    setSelectedDenyPlugFields([])
    setParameters([])
    onClose(refresh)
    setActiveTab(0)
  }

  useEffect(() => {
    const loadParameters = async () => {
      if (!profile?.id) return
      setLoadingParameters(true)
      try {
        const [{ data: accountParameters }, { data: profileParameter }] = await Promise.all([
          parameterService.getParameters(),
          profileService.getParameters(profile!.id)
        ])

        setParameters(
          accountParameters.map((ap) => ({
            parameterId: ap.id,
            parameterName: ap.name,
            type: ap.type,
            value:
              ap.type === 'List'
                ? profileParameter.filter((pp) => pp.parameterId === ap.id)?.map((p) => p.value)
                : profileParameter.find((pp) => pp.parameterId === ap.id)?.value || ''
          }))
        )
      } catch (err: any) {
        enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
      }
      setLoadingParameters(false)
    }

    loadParameters()

    profileService.getPlugWithFields().then(({ data }) => setPlugs(data))
    if (profile?.id) {
      ;(async () => {
        try {
          const [{ data: plugDenieds }, { data: plugFieldDenieds }] = await Promise.all([
            profileService.getPlugDenieds(profile.id),
            profileService.getPlugFieldDenieds(profile.id)
          ])
          setSelectedDenyPlugIds(plugDenieds.map((x) => x.plugId))
          setSelectedDenyPlugFields(
            plugFieldDenieds.map((x) => ({
              plugFieldName: x.plugFieldName,
              plugId: x.plugId
            }))
          )
        } catch (err: any) {
          console.error(err)
          enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
        }
      })()
    }
  }, [enqueueSnackbar, profile, t])

  const getHeight = () => {
    const increment = 65
    const calculatedHeight = parameters.length > 0 ? parameters.length * 65 : 180
    return `${calculatedHeight + increment}px`
  }

  const commitChanges = ({ changed }: ChangeSet) => {
    let changedRows: EditProfileParameter[] = []
    const rows = parameters
    if (changed) {
      changedRows = rows.map((row) => {
        const changedValue = changed[row.parameterId]
        if (changedValue) {
          if (row.type === 'List') {
            changedValue.value.map((x: any) => String(x).trim())
          } else {
            changedValue.value = String(changedValue.value).trim()
          }
          return { ...row, ...changedValue }
        }
        return row
      })
    }
    setParameters(changedRows)
  }

  return (
    <Dialog {...rest} fullWidth fullScreen onClose={() => handleClose(false)}>
      <DialogContent dividers>
        <Tabs centered indicatorColor="primary" value={activeTab} onChange={(e, value) => setActiveTab(value)}>
          <Tab value={0} label={t('profileDialog.tabTitle.permissions')} />
          <Tab value={1} label={t('profileDialog.tabTitle.rules')} />
          <Tab value={2} label={t('profileDialog.tabTitle.parameters')} />
        </Tabs>
        <SwipeableViews index={activeTab} animateHeight>
          <ProfileForm initialValues={profile} isSubmitting={isSubmitting} onSubmit={handleDeferAction} />
          <ProfilePlugFieldDenyList
            plugs={plugs}
            selectedPlugIds={selectedDenyPlugIds}
            selectedPlugFields={selectedDenyPlugFields}
            onPlugFieldsSelectionChange={(newSelection) => {
              setSelectedDenyPlugFields(newSelection)
              deniesDirty.current = true
            }}
            onPlugIdsSelectionChange={(newSelection) => {
              setSelectedDenyPlugIds(newSelection)
              deniesDirty.current = true
            }}
          />
          {allowEditPermissions && (
            <Paper style={{ height: getHeight() }}>
              <Grid
                columns={[
                  {
                    name: 'parameterName',
                    title: t('profileDialog.tabParameters.parameterColumnLabel')!
                  },
                  {
                    name: 'value',
                    title: t('profileDialog.tabParameters.parameterValueColumnLabel')!
                  }
                ]}
                rows={parameters}
                getRowId={(rowData) => rowData.parameterId}
              >
                <AutocompleteTypeProvider multiple={(x) => x.type === 'List'} for={['value']} />
                <EditingState
                  onCommitChanges={commitChanges}
                  columnExtensions={[
                    {
                      columnName: 'parameterName',
                      editingEnabled: false
                    }
                  ]}
                />
                <Table
                  columnExtensions={[
                    {
                      columnName: 'parameterName',
                      width: '20%'
                    }
                  ]}
                />
                <TableHeaderRow />
                <TableInlineCellEditing selectTextOnEditStart={false} />
              </Grid>
            </Paper>
          )}
        </SwipeableViews>
        {deleteErrorBag && (
          <WarningContainer
            ref={warningBoxRef}
            message={deleteErrorBag.message}
            relatedDependencies={deleteErrorBag.dependents}
          />
        )}
      </DialogContent>
      {profile?.id ? (
        <DialogActions>
          <Button
            isDelete
            loading={loadingApiDelete}
            label={
              deleteConfirmed
                ? t('pageProfiles.modalCreate.buttonConfirmDelete')
                : t('pageProfiles.modalCreate.buttonDelete')
            }
            disabled={profile.isSystemDefault}
            onClick={handleDelete}
            hidden={!profile?.id}
          />
          <Button label={t('pageProfiles.modalCreate.buttonUpdate')} loading={loadingApi} onClick={handleSubmit} />
        </DialogActions>
      ) : (
        <DialogActions>
          <Button label={t('pageProfiles.modalCreate.buttonCreate')} loading={loadingApi} onClick={handleSubmit} />
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ProfileDialog
