import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import { GeoAnalysisConfig } from '.'
import Button from '../../components/Button'
import { Dialog, DialogActions, DialogContent } from '../../components/Dialog'
import SelectBox from '../../components/SelectBox'
import TextField from '../../components/TextField'
import { ENDPOINTS } from '../../constants/apiEndpoints'
import { PlugFieldSchema } from '../../schemas/PlugSchema'
import { plugService } from '../../services'
import GeoAnalysisCard from './GeoAnalysisCard'
import RadioOptionHeatMap from './RadioOptionHeatMap'

export enum GeoAnalysisType {
  'Markers' = 'Markers',
  'MarkersClustered' = 'MarkersClustered',
  'HeatMap' = 'HeatMap'
}

interface GeoAnalysisTypeDialogProps {
  open: boolean
  plugId: number
  onClose: () => void
  onSubmit: (value: GeoAnalysisConfig) => void
  loading?: boolean
  geoAnalysisConfig: GeoAnalysisConfig
}

type FieldValidationProps = { [key: string]: any; fieldName: string; error: boolean; msg: string }

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.down('xl')]: {
      width: 1200,
      margin: 'auto'
    }
  },
  dialogContent: {
    // overflow: 'hidden'
  }
}))

const GeoAnalysisTypeDialog: React.FC<PropsWithChildren<GeoAnalysisTypeDialogProps>> = ({
  open,
  onClose,
  plugId,
  onSubmit,
  loading,
  geoAnalysisConfig
}) => {
  const [selectedAnalysis, setSelectedAnalysis] = useState<GeoAnalysisType | null>(geoAnalysisConfig.type)
  const [currentViewIndex, setCurrentViewIndex] = useState(0)
  const [fieldsValidations, setFieldsValidations] = useState<FieldValidationProps[]>([])
  const [hasErrorInSelectType, setHasErrorInSelectType] = useState(false)
  const [tempConfiguration, setTempConfiguration] = useState<GeoAnalysisConfig>(geoAnalysisConfig)
  const { enqueueSnackbar } = useSnackbar()
  const [plugFieldOptions, setPlugFieldOptions] = useState<PlugFieldSchema[]>([])
  const { t } = useTranslation()

  const analysisTypes = [
    {
      id: GeoAnalysisType.Markers,
      image: `${ENDPOINTS.PUBLIC_IMAGES}/geoanalysis/marker.jpeg`,
      title: t('geoAnalysis.modalTypeMarkersTitleName'),
      description: t('geoAnalysis.modalTypeMarkersSubtitle')
    },
    {
      id: GeoAnalysisType.MarkersClustered,
      image: `${ENDPOINTS.PUBLIC_IMAGES}/geoanalysis/cluster_marker.jpeg`,
      title: t('geoAnalysis.modalTypeMarkersClusteredTitleName'),
      description: t('geoAnalysis.modalTypeMarkersClusteredSubtitleName')
    },
    {
      id: GeoAnalysisType.HeatMap,
      image: `${ENDPOINTS.PUBLIC_IMAGES}/geoanalysis/heatmap.jpeg`,
      title: t('geoAnalysis.modalTypeHeatMapTitleName'),
      description: t('geoAnalysis.modalTypeHeatMapSubtitle')
    }
  ]

  const classes = useStyles()

  useEffect(() => {
    setTempConfiguration(geoAnalysisConfig)
    setSelectedAnalysis(geoAnalysisConfig.type)
  }, [geoAnalysisConfig])

  const loadPlugFieldOptions = useCallback(async () => {
    try {
      const { data } = await plugService.getFields(plugId, true)
      setPlugFieldOptions(data.sortBy((x) => x.label))
    } catch (err: any) {
      enqueueSnackbar(t('pagePlugs.modalCreate.toast.error.generic', { msg: err.message }), { variant: 'error' })
    }
  }, [plugId, enqueueSnackbar, t])

  useEffect(() => {
    if (open && plugId) loadPlugFieldOptions()
  }, [open, loadPlugFieldOptions, plugId])

  const handleAnalysisSelectionValidation = () => {
    if (!selectedAnalysis) {
      setHasErrorInSelectType(true)
      return
    }
    setHasErrorInSelectType(false)
    setCurrentViewIndex(1)
  }
  const handleConfigurationValidation = () => {
    setFieldsValidations([])
    let hasErrors = false
    for (const configKey in tempConfiguration) {
      if (!tempConfiguration[configKey as keyof GeoAnalysisConfig]) {
        if (selectedAnalysis !== GeoAnalysisType.MarkersClustered && configKey === 'insideValue') {
          continue
        }
        if (
          (selectedAnalysis === GeoAnalysisType.HeatMap &&
            (configKey === 'dimensions' || configKey === 'values' || configKey === 'insideValue')) ||
          (configKey === 'weightValue' && tempConfiguration.weightBy === 'point')
        ) {
          continue
        }
        if (selectedAnalysis !== GeoAnalysisType.HeatMap && configKey === 'weightValue') {
          continue
        }
        setFieldsValidations((prev) => [
          ...prev,
          { fieldName: configKey, error: true, msg: `${configKey} is required` }
        ])
        hasErrors = true
      }
    }
    if (!hasErrors)
      onSubmit({
        type: selectedAnalysis!,
        latitude: tempConfiguration.latitude!,
        longitude: tempConfiguration.longitude!,
        dimensions: tempConfiguration.dimensions!,
        values: tempConfiguration.values!,
        insideValue: tempConfiguration.insideValue!,
        weightValue: tempConfiguration.weightValue!,
        weightBy: tempConfiguration.weightBy,
        simpleMarkerColor: tempConfiguration.simpleMarkerColor,
        aMarkerColor: tempConfiguration.aMarkerColor,
        bMarkerColor: tempConfiguration.bMarkerColor,
        cMarkerColor: tempConfiguration.cMarkerColor
        //summaryType: tempConfiguration.summaryType
      })
  }

  const handleConfigurationChange = (
    option: keyof GeoAnalysisConfig,
    value: PlugFieldSchema[] | PlugFieldSchema | null | string
  ) => {
    setTempConfiguration((prev) => ({ ...prev, [option]: value }))
  }

  const stepValidation = [handleAnalysisSelectionValidation, handleConfigurationValidation]

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="lg"
      open={open}
      title={t('geoAnalysis.modalTypeTitle')}
      description={t('geoAnalysis.modalTypeSubtitle')}
      onClose={onClose}
    >
      <DialogContent dividers className={classes.dialogContent}>
        <SwipeableViews index={currentViewIndex} animateHeight>
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            //className={classes.cardsContainer}
            overflow="auto"
          >
            {analysisTypes.map((at) => (
              <GeoAnalysisCard
                key={at.title}
                id={at.id}
                title={at.title}
                description={at.description}
                image={at.image}
                selected={at.id === selectedAnalysis}
                onSelect={(id) => {
                  setSelectedAnalysis(id)
                  setTempConfiguration((prev) => ({ ...prev, type: id }))
                }}
              />
            ))}
          </Box>
          <Box paddingTop={7} display="flex" flexDirection="column" alignItems="center" height="450px" overflow="auto">
            <Box width={400}>
              <SelectBox
                textFieldProps={{
                  name: 'latitude',
                  label: t('geoAnalysis.modalSelectFieldsLatitude'),
                  style: { margin: '10px 0' },
                  error: fieldsValidations.find((f) => f.fieldName === 'latitude')?.error,
                  helperText: fieldsValidations.find((f) => f.fieldName === 'latitude')?.msg
                }}
                getOptionLabel={(opt) => opt.label}
                value={tempConfiguration.latitude}
                multiple={false}
                onChange={(e, value) => handleConfigurationChange('latitude', value as PlugFieldSchema)}
                options={plugFieldOptions}
              />
              <SelectBox
                textFieldProps={{
                  name: 'longitude',
                  label: t('geoAnalysis.modalSelectFieldsLongitude'),
                  style: { margin: '10px 0' },
                  error: fieldsValidations.find((f) => f.fieldName === 'longitude')?.error,
                  helperText: fieldsValidations.find((f) => f.fieldName === 'longitude')?.msg
                }}
                getOptionLabel={(opt) => opt.label}
                value={tempConfiguration.longitude}
                multiple={false}
                onChange={(e, value) => handleConfigurationChange('longitude', value as PlugFieldSchema)}
                options={plugFieldOptions}
              />
              {selectedAnalysis !== GeoAnalysisType.HeatMap && (
                <SelectBox
                  textFieldProps={{
                    name: 'dimensions',
                    label: t('geoAnalysis.modalTypeClusteredGridDimension'),
                    style: { margin: '10px 0' },
                    error: fieldsValidations.find((f) => f.fieldName === 'dimensions')?.error,
                    helperText: fieldsValidations.find((f) => f.fieldName === 'dimensions')?.msg
                  }}
                  multiple
                  options={plugFieldOptions}
                  getOptionLabel={(opt) => opt.label}
                  value={tempConfiguration.dimensions as any}
                  onChange={(e, value) => handleConfigurationChange('dimensions', value)}
                />
              )}
              {selectedAnalysis === GeoAnalysisType.HeatMap && (
                <>
                  <RadioOptionHeatMap
                    value={tempConfiguration.weightBy || 'point'}
                    onChange={(ev, value) => handleConfigurationChange('weightBy', value)}
                  />
                  <SelectBox
                    textFieldProps={{
                      name: 'weightValue',
                      label: t('geoAnalysis.weightValue'),
                      style: { margin: '10px 0' },
                      error: fieldsValidations.find((f) => f.fieldName === 'weightValue')?.error,
                      helperText: fieldsValidations.find((f) => f.fieldName === 'weightValue')?.msg
                    }}
                    disabled={tempConfiguration.weightBy === 'point'}
                    getOptionLabel={(opt) => opt.label}
                    value={tempConfiguration.weightBy === 'point' ? null : (tempConfiguration.weightValue as any)}
                    onChange={(e, value) => handleConfigurationChange('weightValue', value)}
                    options={plugFieldOptions.filter((x) => x.type === 'NUMERIC')}
                  />
                </>
              )}
              {selectedAnalysis !== GeoAnalysisType.HeatMap && (
                <SelectBox
                  textFieldProps={{
                    name: 'values',
                    label: t('geoAnalysis.modalTypeClusteredGridDimensionValue'),
                    style: { margin: '10px 0' },
                    error: fieldsValidations.find((f) => f.fieldName === 'values')?.error,
                    helperText: fieldsValidations.find((f) => f.fieldName === 'values')?.msg
                  }}
                  multiple
                  getOptionLabel={(opt) => opt.label}
                  value={tempConfiguration.values as any}
                  onChange={(e, value) => handleConfigurationChange('values', value)}
                  options={plugFieldOptions.filter((x) => x.type === 'NUMERIC')}
                />
              )}
              {selectedAnalysis === GeoAnalysisType.MarkersClustered && (
                <>
                  <SelectBox
                    textFieldProps={{
                      name: 'insideValue',
                      label: t('geoAnalysis.modalTypeClusteredClusterValue'),
                      style: { margin: '10px 0' },
                      error: fieldsValidations.find((f) => f.fieldName === 'insideValue')?.error,
                      helperText: fieldsValidations.find((f) => f.fieldName === 'insideValue')?.msg
                    }}
                    getOptionLabel={(opt) => opt.label}
                    value={tempConfiguration.insideValue as any}
                    onChange={(e, value) => {
                      handleConfigurationChange('insideValue', value)
                      // if (value.type === 'NUMERIC') {
                      //   handleConfigurationChange('summaryType', 'SUM')
                      //   return
                      // }
                      // handleConfigurationChange('summaryType', 'COUNT DISTINCT')
                    }}
                    options={plugFieldOptions}
                  />
                  <TextField
                    label="A Marker Color"
                    name="aMarkerColor"
                    sx={{ margin: '10px 0' }}
                    type="color"
                    value={tempConfiguration.aMarkerColor}
                    onChange={(e) => handleConfigurationChange('aMarkerColor', e.target.value)}
                  />
                  <TextField
                    label="B Marker Color"
                    name="bMarkerColor"
                    sx={{ margin: '10px 0' }}
                    type="color"
                    value={tempConfiguration.bMarkerColor}
                    onChange={(e) => handleConfigurationChange('bMarkerColor', e.target.value)}
                  />
                  <TextField
                    label="C Marker Color"
                    name="cMarkerColor"
                    sx={{ margin: '10px 0' }}
                    type="color"
                    value={tempConfiguration.cMarkerColor}
                    onChange={(e) => handleConfigurationChange('cMarkerColor', e.target.value)}
                  />
                  {/* <SelectBox
                    textFieldProps={{
                      name: 'summaryType',
                      label: 'Summary Type',
                      style: { margin: '10px 0' },
                      error: fieldsValidations.find((f) => f.fieldName === 'summaryType')?.error,
                      helperText: fieldsValidations.find((f) => f.fieldName === 'summaryType')?.msg
                    }}
                    disableClearable
                    getOptionLabel={(opt) => opt}
                    disabled={tempConfiguration.insideValue?.type !== 'NUMERIC'}
                    value={tempConfiguration.summaryType as any}
                    onChange={(e, value) => handleConfigurationChange('summaryType', value)}
                    options={['SUM', 'COUNT DISTINCT']}
                  /> */}
                </>
              )}
              {selectedAnalysis === GeoAnalysisType.Markers && (
                <TextField
                  label="Marker Color"
                  name="markerColor"
                  type="color"
                  value={tempConfiguration.simpleMarkerColor}
                  onChange={(e) => handleConfigurationChange('simpleMarkerColor', e.target.value)}
                />
              )}
            </Box>
          </Box>
        </SwipeableViews>
        <Box textAlign="center">
          <Typography color="error">{hasErrorInSelectType && t('geoAnalysis.selectAnalysisValidation')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button label={t('geoAnalysis.modalTypeClusteredButtonBack')} isDelete onClick={() => setCurrentViewIndex(0)} />
        <Button
          label={t('geoAnalysis.modalTypeClusteredButtonNext')}
          onClick={stepValidation[currentViewIndex]}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}

export default GeoAnalysisTypeDialog
