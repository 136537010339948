import { useCallback, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import PATHS from '../../constants/paths'
import { useCurrentPath } from '../useCurrentPath'

type ReturnObj = {
  url: string
  accountId: number
}

const encodeReturnObj = (url: string, accountId: number) => {
  const stringedObj = JSON.stringify({ url, accountId })
  const base64Obj = window.btoa(stringedObj)
  return base64Obj
}

const decodeReturnObj = (base64: string) => {
  return window.atob(base64)
}

export const useReturnUrl = () => {
  const location = useLocation()
  const state = location.state as {
    returnInfo: {
      url: string
      accountId: number
    }
  }
  const currentPath = useCurrentPath()
  const [searchParams] = useSearchParams()
  const returnUrl = searchParams.get('returnUrl')

  const hasReturnUrl = useMemo(() => !!returnUrl, [returnUrl])

  const generateReturnUrl = useCallback(
    (accountId: number) => {
      return `${PATHS.SIGNIN}?returnUrl=${encodeReturnObj(currentPath, accountId)}`
    },
    [currentPath]
  )

  const getReturnObj = useCallback((): ReturnObj | null => {
    if (hasReturnUrl) {
      const stringedReturnObj = decodeReturnObj(returnUrl!)
      return JSON.parse(stringedReturnObj)
    } else if (!!state?.returnInfo) {
      return state.returnInfo
    }
    return null
  }, [hasReturnUrl, state?.returnInfo, returnUrl])

  return { generateReturnUrl, getReturnObj, hasReturnUrl }
}
